/* @flow */

import _ from "lodash"
import chroma from "chroma-js"
/**
 * Award graph color palette
 */

type SimplifiedAwardObject = $ReadOnly<{
  has_min_length: boolean,
  is_leave: boolean,
  is_ord_hours: boolean,
  multiplier: number,
}>

// should sort before non-ordinary hours.
// non-leave should sort before leave
export const awardHash = (d: SimplifiedAwardObject): string =>
  [d.is_ord_hours ? 0 : 1, d.multiplier, d.has_min_length ? 0 : 1, d.is_leave ? 1 : 0].join()

export const getAwardColor: (award: SimplifiedAwardObject) => string = _.memoize((award: SimplifiedAwardObject) => {
  const base_hue = award.is_ord_hours ? 220 : 55
  const multiplier_hue =
    base_hue -
    (award.is_ord_hours
      ? Math.min(150, (Math.max(1.0, award.multiplier || 1.0) - 1) * 120)
      : Math.min(80, (Math.max(1.0, award.multiplier || 1.0) - 1.5) * 50))
  const saturation = award.is_leave ? 0.3 : 0.6
  const value = award.has_min_length ? 0.8 : 0.95
  return chroma.hsv(multiplier_hue, saturation, value).hex()
}, awardHash)
