/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import Icon from "components/Icon"
import styles from "./styles.module.scss"

const getIconSize = (size) => {
  switch (size) {
    case "icon_xs":
      return "xs"
    case "icon_s":
      return "xs"
    case "icon_m":
      return "s"
    default:
      return size
  }
}

export default function SuccessTick(props) {
  return (
    <div
      className={cn(styles.tick, styles[`size_${props.size}`], { [styles.paused]: props.pause })}
      style={{ animationDelay: `${props.delay}ms` }}
    >
      <Icon bold color="white" size={getIconSize(props.size)} type="done" />
    </div>
  )
}

SuccessTick.propTypes = {
  pause: PropTypes.bool,
  size: PropTypes.oneOf(["icon_xs", "icon_s", "icon_m", "xs", "s", "m", "l"]),
  delay: PropTypes.number,
}

SuccessTick.defaultProps = {
  pause: false,
  size: "m",
  delay: 0,
}
