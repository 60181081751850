/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"

import Currency from "helpers/currency"
import { t as globalT } from "helpers/i18n"
import * as User from "timesheets/models/user"
import * as Timesheet from "timesheets/models/timesheet"

import { AutoHoverBubble } from "components/Bubble"
import FilterSelect from "components/Select/FilterSelect"
import Icon from "components/Icon"
import Input from "components/Input"
import styles from "./styles.module.scss"

const t = (key, values) => globalT(`js.timesheets.allowance_modal.${key}`, values)

export default class AllowanceForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleClearKeyPress = this.handleClearKeyPress.bind(this)
  }

  handleClearKeyPress(event) {
    switch (event.keyCode) {
      case 8: // backspace
      case 13: // enter
      case 46: // delete
        this.props.onClear(event)
        break
    }
  }

  renderCost() {
    return this.props.form.rate ? Currency.from(this.props.form.rate * Number(this.props.form.value || "")) : ""
  }

  render() {
    return (
      <div className={styles.AllowanceForm} tabIndex={this.props.tabIndex}>
        <div className={styles.form}>
          <div className={styles.nameInput}>
            <FilterSelect
              disabled={this.props.form.automated || Timesheet.isExported(this.props.timesheet)}
              onChange={this.props.onNameChange}
              options={this.props.options}
              placeholder={this.props.form.name || t("allowance_placeholder")}
              tabIndex={0}
            />
          </div>
          <div className={styles.unitInput}>
            <Input
              disabled={this.props.form.automated || Timesheet.isExported(this.props.timesheet)}
              onChange={this.props.onValueChange}
              tabIndex={0}
              textAlign="center"
              value={this.props.form.value || ""}
            />
          </div>
          <div className={styles.costTotal}>
            {User.canSeeCostsOnTimesheet(this.props.timesheet) ? this.renderCost() : "-"}
          </div>
        </div>
        {!Timesheet.isExported(this.props.timesheet) &&
          (this.props.form.automated ? (
            <span className={styles.autoTip}>
              <Icon size="s" type="lock" />
              <AutoHoverBubble offset={-0.125}>
                <span className={styles.autoMsg}>{t("tips.cannot_edit_auto")}</span>
              </AutoHoverBubble>
            </span>
          ) : (
            <span
              className={styles.clearButton}
              onClick={this.props.onClear}
              onKeyDown={this.handleClearKeyPress}
              role="button"
              tabIndex={0}
            >
              <Icon size="s" type="close" />
              <AutoHoverBubble offset={-0.125}>{t("tips.clear")}</AutoHoverBubble>
            </span>
          ))}
      </div>
    )
  }
}

AllowanceForm.propTypes = {
  form: PropTypes.shape({
    allowance_id: PropTypes.number,
    automated: PropTypes.bool,
    name: PropTypes.string,
    rate: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  onNameChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  timesheet: PropTypes.object.isRequired,
  onValueChange: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
}
