/* @flow */
import { t as globalT } from "helpers/i18n"
import type {
  FilterOptionsObjectType,
  LiveWageGraphEntriesType,
  LiveInsightsGraphEntriesType,
  LiveInsightsLineStyles,
  MetricOptionsType,
} from "dashboard/live_insights/main"

const t = (key: string, ...args) => globalT(`js.dashboard.widgets.live_insights.constants.${key}`, ...args)

type ExportType = {
  BUCKET_INTERVAL: number,
  DATATYPE_COOKIE_KEY: string,
  DATE_FORMAT: string,
  DATE_TIME_FORMAT: string,
  DEMAND_DATATYPE_COOKIE_KEY: string,
  GRAPH_INTERVAL: number,
  LIVE_INSIGHTS_DEMAND_DATA_OPTIONS: Array<FilterOptionsObjectType>,
  LIVE_INSIGHTS_GRAPH_ENTRIES: LiveInsightsGraphEntriesType,
  LIVE_INSIGHTS_STAFF_DATA_OPTIONS: Array<FilterOptionsObjectType>,
  LIVE_WAGE_FILTER_OPTIONS: Array<FilterOptionsObjectType>,
  LIVE_WAGE_GRAPH_ENTRIES: LiveWageGraphEntriesType,
  METRIC_OPTIONS: Array<MetricOptionsType>,
  PREDICTED_DATATYPE_COOKIE_KEY: string,
  ROSTERED_DATATYPE_COOKIE_KEY: string,
  SALES_VARIANCE_UNIT: "currency",
  TEAMS_COOKIE_KEY: string,
  TIME_FORMAT: string,
  TRACKER_DATATYPE_COOKIE_KEY: string,
}

const DATE_FORMAT = "YYYY-MM-DD"
const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm"
const TIME_FORMAT = "HH:mm"
const BUCKET_INTERVAL = 15
const GRAPH_INTERVAL = 15 // Needs to be multiple of 15
const TEAMS_COOKIE_KEY = "dashboard_live_insights_teams"
const DATATYPE_COOKIE_KEY = "dashboard_live_insights_datatype"
const DEMAND_DATATYPE_COOKIE_KEY = "dashboard_live_insights_demand_datatype"
const TRACKER_DATATYPE_COOKIE_KEY = "dashboard_live_wage_tracker_datatype"
const PREDICTED_DATATYPE_COOKIE_KEY = "dashboard_live_insights_predicted_datatype"
const ROSTERED_DATATYPE_COOKIE_KEY = "dashboard_live_wage_tracker_rostered_datatype"
const SALES_VARIANCE_UNIT = "currency"

const LIVE_WAGE_GRAPH_ENTRIES: LiveWageGraphEntriesType = {
  staff_cost: {
    actual: {
      name: t("staff_cost"),
      key: "actual_staff_cost",
      color: "rgba(234, 14, 14, 0.4)",
      unit: "currency",
      chartType: "solidBar",
      tip: "",
      default: false,
    },
    rostered: {
      name: t("rostered"),
      key: "rostered_staff_cost",
      color: "rgba(54, 183, 244, 0.4)",
      unit: "currency",
      chartType: "solidBar",
      tip: "",
      default: false,
    },
  },
  staff_count: {
    actual: {
      name: t("staff_count"),
      key: "actual_staff_count",
      color: "rgba(234, 14, 14, 0.6)",
      tip: "",
      chartType: "solidLine",
      unit: "none",
      default: false,
    },
    rostered: {
      name: t("rostered"),
      key: "rostered_staff_count",
      color: "rgba(54, 183, 244, 0.8)",
      tip: "",
      chartType: "dottedLine",
      unit: "none",
      default: true,
    },
  },
}

const METRIC_OPTIONS: Array<MetricOptionsType> = [
  {
    label: t("splh"),
    value: "splh",
  },
  {
    label: t("wage_percentage_revenue"),
    value: "wage_percentage",
  },
]

const LIVE_INSIGHTS_GRAPH_STYLES: LiveInsightsLineStyles = {
  demand_data: {
    actual: {
      color: "rgba(234, 14, 14, 0.5)",
      chartType: "solidBar",
    },
    predicted: {
      color: "rgba(54, 183, 244, 0.4)",
      chartType: "solidBar",
    },
  },
  staff_data: {
    actual: {
      color: "rgba(0, 51, 163, 0.8)",
      chartType: "solidLine",
    },
    rostered: {
      color: "rgba(0, 51, 163, 0.6)",
      chartType: "dottedLine",
    },
  },
}
const LIVE_INSIGHTS_GRAPH_ENTRIES: LiveInsightsGraphEntriesType = {
  staff_count: {
    actual: {
      name: t("staff_count"),
      key: "actual_staff_count",
      unit: "none",
      tip: "",
      default: false,
      ...LIVE_INSIGHTS_GRAPH_STYLES.staff_data.actual,
    },
    rostered: {
      name: t("rostered"),
      key: "rostered_staff_count",
      unit: "none",
      tip: "",
      default: false,
      ...LIVE_INSIGHTS_GRAPH_STYLES.staff_data.rostered,
    },
  },
  staff_cost: {
    actual: {
      name: t("staff_cost"),
      key: "actual_staff_cost",
      unit: "currency",
      tip: "",
      default: false,
      ...LIVE_INSIGHTS_GRAPH_STYLES.staff_data.actual,
    },
    rostered: {
      name: t("rostered"),
      key: "rostered_staff_cost",
      unit: "currency",
      tip: "",
      default: false,
      ...LIVE_INSIGHTS_GRAPH_STYLES.staff_data.rostered,
    },
  },
  splh: {
    actual: {
      name: t("splh"),
      key: "actual_splh",
      unit: "currencyPerHour",
      tip: "",
      default: false,
      ...LIVE_INSIGHTS_GRAPH_STYLES.staff_data.actual,
    },
    rostered: {
      name: t("rostered"),
      key: "rostered_splh",
      unit: "currencyPerHour",
      tip: "",
      default: false,
      ...LIVE_INSIGHTS_GRAPH_STYLES.staff_data.rostered,
    },
  },
  wage_percentage: {
    actual: {
      name: t("wage_percentage"),
      key: "actual_wage_percentage",
      unit: "percentage",
      tip: "",
      default: false,
      ...LIVE_INSIGHTS_GRAPH_STYLES.staff_data.actual,
    },
    rostered: {
      name: t("rostered"),
      key: "rostered_wage_percentage",
      unit: "percentage",
      tip: "",
      default: false,
      ...LIVE_INSIGHTS_GRAPH_STYLES.staff_data.rostered,
    },
  },
  sales: {
    actual: {
      name: t("sales"),
      key: "sales",
      unit: "currency",
      tip: "",
      default: false,
      ...LIVE_INSIGHTS_GRAPH_STYLES.demand_data.actual,
    },
    rostered: {
      name: t("predicted"),
      key: "predicted_sales",
      unit: "currency",
      tip: "",
      default: false,
      ...LIVE_INSIGHTS_GRAPH_STYLES.demand_data.predicted,
    },
  },
  none: {
    actual: {
      name: "",
      key: "",
      unit: "none",
      tip: "",
      default: false,
      ...LIVE_INSIGHTS_GRAPH_STYLES.demand_data.actual,
    },
    rostered: {
      name: t(""),
      key: "",
      unit: "none",
      tip: "",
      default: false,
      ...LIVE_INSIGHTS_GRAPH_STYLES.demand_data.predicted,
    },
  },
}

const LIVE_WAGE_FILTER_OPTIONS: Array<FilterOptionsObjectType> = [
  {
    label: LIVE_WAGE_GRAPH_ENTRIES.staff_count.actual.name,
    costRelated: false,
    value: "staff_count",
    data: {
      color: LIVE_WAGE_GRAPH_ENTRIES.staff_count.actual.color,
      chartType: LIVE_WAGE_GRAPH_ENTRIES.staff_count.actual.chartType,
    },
  },
  {
    label: LIVE_WAGE_GRAPH_ENTRIES.staff_cost.actual.name,
    value: "staff_cost",
    costRelated: true,
    data: {
      color: LIVE_WAGE_GRAPH_ENTRIES.staff_cost.actual.color,
      chartType: LIVE_WAGE_GRAPH_ENTRIES.staff_cost.actual.chartType,
    },
  },
]

const LIVE_INSIGHTS_STAFF_DATA_OPTIONS: Array<FilterOptionsObjectType> = [
  {
    label: LIVE_INSIGHTS_GRAPH_ENTRIES.staff_count.actual.name,
    value: "staff_count",
    costRelated: false,
    data: {
      color: LIVE_INSIGHTS_GRAPH_ENTRIES.staff_count.actual.color,
      chartType: LIVE_INSIGHTS_GRAPH_ENTRIES.staff_count.actual.chartType,
    },
  },
  {
    label: LIVE_INSIGHTS_GRAPH_ENTRIES.staff_cost.actual.name,
    value: "staff_cost",
    costRelated: true,
    data: {
      color: LIVE_INSIGHTS_GRAPH_ENTRIES.staff_cost.actual.color,
      chartType: LIVE_INSIGHTS_GRAPH_ENTRIES.staff_cost.actual.chartType,
    },
  },
  {
    label: LIVE_INSIGHTS_GRAPH_ENTRIES.wage_percentage.actual.name,
    value: "wage_percentage",
    costRelated: true,
    data: {
      color: LIVE_INSIGHTS_GRAPH_ENTRIES.wage_percentage.actual.color,
      chartType: LIVE_INSIGHTS_GRAPH_ENTRIES.wage_percentage.actual.chartType,
    },
  },
  {
    label: LIVE_INSIGHTS_GRAPH_ENTRIES.splh.actual.name,
    value: "splh",
    costRelated: false,
    data: {
      color: LIVE_INSIGHTS_GRAPH_ENTRIES.splh.actual.color,
      chartType: LIVE_INSIGHTS_GRAPH_ENTRIES.splh.actual.chartType,
    },
  },
]

const LIVE_INSIGHTS_DEMAND_DATA_OPTIONS: Array<FilterOptionsObjectType> = [
  {
    label: "None",
    value: "none",
    costRelated: false,
    data: {
      color: "",
      chartType: "none",
    },
  },
  {
    label: LIVE_INSIGHTS_GRAPH_ENTRIES.sales.actual.name,
    value: "sales",
    costRelated: false,
    data: {
      color: LIVE_INSIGHTS_GRAPH_ENTRIES.sales.actual.color,
      chartType: LIVE_INSIGHTS_GRAPH_ENTRIES.sales.actual.chartType,
    },
  },
]

const exportObject: ExportType = {
  BUCKET_INTERVAL,
  DATATYPE_COOKIE_KEY,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DEMAND_DATATYPE_COOKIE_KEY,
  GRAPH_INTERVAL,
  LIVE_INSIGHTS_DEMAND_DATA_OPTIONS,
  LIVE_INSIGHTS_GRAPH_ENTRIES,
  LIVE_INSIGHTS_STAFF_DATA_OPTIONS,
  LIVE_WAGE_FILTER_OPTIONS,
  LIVE_WAGE_GRAPH_ENTRIES,
  METRIC_OPTIONS,
  PREDICTED_DATATYPE_COOKIE_KEY,
  ROSTERED_DATATYPE_COOKIE_KEY,
  SALES_VARIANCE_UNIT,
  TEAMS_COOKIE_KEY,
  TIME_FORMAT,
  TRACKER_DATATYPE_COOKIE_KEY,
}

export default exportObject
