// @flow
import * as State from "../../models/state"
import type { Action } from "../action"

type FormTemplates = State.Partial<"formTemplates">

export default function formTemplates(state: FormTemplates, action: Action): FormTemplates {
  switch (action.type) {
    case "@patch/state":
      return action.data.formTemplates
    default:
      return state
  }
}
