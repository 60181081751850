/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

function messageStart(propName, componentName) {
  return `\`${propName}\` property of \`${componentName}\``
}

function createCustomPropType(fn) {
  return (...args) => {
    const wrapped = (props, propName, ...rest) => {
      if (props[propName] == null) {
        return
      }
      return fn(...args)(props, propName, ...rest)
    }
    wrapped.isRequired = (props, propName, componentName, ...rest) => {
      if (props[propName] == null) {
        return new Error(`Required prop \`${propName}\` was not specified in \`${componentName}\`.`)
      }
      return fn(...args)(props, propName, componentName, ...rest)
    }

    return wrapped
  }
}

export function propOfTypePropType(component) {
  return function validate(props, propName, componentName) {
    if (!component.displayName) {
      return new Error(`Validator for ${messageStart(propName, componentName)} does not have a display name.`)
    }

    const item = props[propName]
    if (!item.type || item.type.displayName !== component.displayName) {
      return new Error(`${messageStart(propName, componentName)} must be an instance of \`${component.displayName}\`.`)
    }
  }
}

export const propOfType = createCustomPropType(propOfTypePropType)

export function arrayOfLengthPropType({ max, exact = false } = {}) {
  return function validate(props, propName, componentName) {
    const prop = props[propName]
    if (!Array.isArray(prop)) {
      return
    }
    const errorGen = (type) =>
      `${messageStart(propName, componentName)} is an array of length ${prop.length},` +
      ` but must be ${type} length ${max}.`
    if (exact && prop.length !== max) {
      return new Error(errorGen("exactly"))
    }
    if (prop.length > max) {
      return new Error(errorGen("at most"))
    }
  }
}

export const arrayOfLength = createCustomPropType(arrayOfLengthPropType)

export function chainPropType(...propTypes) {
  return function validate(props, propName, componentName, ...rest) {
    const errors = propTypes.map((type) => type(props, propName, componentName, ...rest)).filter((x) => x != null)
    if (errors.length === 0) {
      return
    }
    if (errors.length === 1) {
      return errors[0]
    }
    const mapped = errors.map((e) => e.message)
    if (errors.length === 2) {
      return new Error(mapped.join(" and "))
    }
    const message = `${mapped.slice(0, errors.length - 1).join(", ")} and ${mapped[mapped.length - 1]}`
    return new Error(message)
  }
}

export const chain = createCustomPropType(chainPropType)
