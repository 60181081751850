// @flow
import * as React from "react"
import * as State from "platform/models/state"
import * as Store from "platform/store"
import Form from "platform/view/recordFormView"
import styles from "./styles.module.scss"

type Props = {|
  modelId: string,
  recordId: number,
|}

export default function PlatformRecordFields({ modelId, recordId }: Props): null | React.Element<"div"> {
  const [state] = Store.useContext()

  const record = State.recordById(state, recordId.toString())
  const model = State.modelById(state, modelId)

  if (record == null || model == null) {
    return null
  }
  return (
    <div className={styles.formWrapper}>
      <Form model={model} record={record} />
    </div>
  )
}
