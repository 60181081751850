/* @flow */
import moment from "moment"
import _ from "lodash"
import Constants from "../helpers/constants"
import type {
  LocationDataType,
  DataStreamInfoType,
  LiveInsightsDataType,
  RechartsDataType,
  CounterObjectType,
  ArrangedDataType,
} from "../main"

type ReducedArrangedDataType = {
  costs: ?CounterObjectType,
  counts: CounterObjectType,
  date: string,
  key: string,
  momentTime: moment,
  predicted_sales: ?CounterObjectType,
  sales: ?CounterObjectType,
  time: number,
}

// Finds the total for the given deparments.
const reduce_from_departments = (departments: Array<number>, counterObject: ?CounterObjectType): number => {
  if (counterObject == null) {
    return 0
  } else if (departments == null || departments.length === 0) {
    return counterObject.total
  } else {
    return _.reduce(
      _.toPairs(counterObject),
      (acc: number, [dep_id: string, value: number]): number =>
        _.includes(departments, Number(dep_id)) ? acc + value : acc,
      0
    )
  }
}

// Finds the total for the given deparments.
const reduce_from_data_streams = (data_stream_ids: Array<number>, counterObject: ?CounterObjectType): number => {
  if (counterObject == null) {
    return 0
  } else {
    return _.reduce(
      _.toPairs(counterObject),
      (acc: number, [dep_id: string, value: number]): number =>
        _.includes(data_stream_ids, Number(dep_id)) ? acc + value : acc,
      0
    )
  }
}

// adds extra fields to staff_data to make it easier to manipulate
const reduce_staff_data = (staff_data: Array<ArrangedDataType>): Array<ReducedArrangedDataType> =>
  staff_data.reduce((acc: Array<ReducedArrangedDataType>, timeObject: ArrangedDataType) => {
    const time: moment = moment(timeObject.date)
      .hours(timeObject.time)
      .minutes((timeObject.time % 1) * 60)
    acc.push({ ...timeObject, momentTime: time, key: time.format(Constants.DATE_TIME_FORMAT) })
    return acc
  }, [])

export const sumDataUpUntilCurrentTime = (key: string, time: ?moment, chartData: Array<RechartsDataType>): number => {
  const currentTime = time
  return chartData.reduce((acc: number, timeObject: RechartsDataType) => {
    if (currentTime == null || moment(timeObject.time, Constants.DATE_TIME_FORMAT).isSameOrBefore(currentTime)) {
      return acc + (timeObject[key] || 0)
    }
    return acc
  }, 0)
}

export const sumDataUp = (key: string, chartData: Array<RechartsDataType>): number =>
  chartData.reduce((acc: number, timeObject: RechartsDataType) => acc + (timeObject[key] || 0), 0)

/**
 * Figures out which datastreams should be visible, based on the current filter.
 *
 * returns an array of datastreams which should be visible
 */
export const activeDataStreams = (
  filtered_departments_ids: Array<number>,
  location_data: ?Array<LocationDataType>,
  data_stream_info: { [ds_id: string]: DataStreamInfoType }
): Array<number> => {
  if (location_data == null) {
    return []
  }
  const refined_location_data: Array<LocationDataType> = location_data
  const filtered_location_data = filterLocationData(location_data, filtered_departments_ids)
  const selected_locations = filtered_location_data
    .filter((filtered_location) => {
      const location: ?LocationDataType = _.find(refined_location_data, { id: filtered_location.id })
      if (location == null) {
        return false
      }
      // If any dept in a location is selected, show datastreams attached to that location.
      return filtered_location.departments.length > 0
    })
    .map((loc) => loc.id)
  const org_selected =
    filtered_departments_ids.length === 0 || allDepartmentIds(location_data).length === filtered_departments_ids.length
  const active_ds: Array<DataStreamInfoType> = _.values(data_stream_info).filter(
    (ds) =>
      // if you've selected any dept which this data stream is attached to, show the datastream
      (ds.department_ids.length !== 0 && _.some(ds.department_ids, (id) => _.includes(filtered_departments_ids, id))) ||
      // if you've selected any location which this data stream is attached to, show the datastream
      (ds.location_ids.length !== 0 && _.some(ds.location_ids, (id) => _.includes(selected_locations, id))) ||
      // if the datastream applies to the entire org, only show it if the whole org is selected.
      (ds.entire_org && org_selected)
  )
  return active_ds.map((ds) => ds.id)
}

export const dataForWageTrackerChart = (
  data: ?LiveInsightsDataType,
  departments: Array<number>,
  currentTimeBlockFromState: ?moment,
  canSeeCosts: boolean
): Array<RechartsDataType> => {
  if (data == null) {
    return []
  }
  const interval = Constants.GRAPH_INTERVAL
  const rostered_staff_data: Array<ReducedArrangedDataType> = reduce_staff_data(data.rostered_staff_data)
  const actual_staff_data: Array<ReducedArrangedDataType> = reduce_staff_data(data.actual_staff_data)
  const rostered_data_times = rostered_staff_data.map((timeObject) => timeObject.momentTime)
  const actual_data_times = actual_staff_data.map((timeObject) => timeObject.momentTime)
  const all_times = [...rostered_data_times, ...actual_data_times]
  const max_time = moment.max(all_times)
  const min_time = moment.min(all_times)
  const num_of_interval_increments: number =
    Math.ceil(moment.duration(max_time.diff(min_time)).asMinutes() / interval) + 1
  const interval_increments: Array<number> = _.range(0, num_of_interval_increments).map((inc) => inc * interval)
  return interval_increments.map((inc) => {
    const time: moment = min_time.clone().add(inc, "minutes")
    const key: string = time.format(Constants.DATE_TIME_FORMAT)
    const buckets_in_this_interval: number = Math.ceil(interval / Constants.BUCKET_INTERVAL)
    const graphValues: { [key: string]: ?number } = calculate_interval_data(
      time,
      buckets_in_this_interval,
      rostered_staff_data,
      actual_staff_data,
      departments,
      currentTimeBlockFromState,
      canSeeCosts,
      []
    )
    return {
      ...graphValues,
      time: key,
    }
  })
}

export const dataForInsightsChart = (
  data: ?LiveInsightsDataType,
  departments: Array<number>,
  currentTimeBlockFromState: ?moment,
  canSeeCosts: boolean,
  active_data_streams: Array<number>
): Array<RechartsDataType> => {
  if (data == null) {
    return []
  }
  const interval = Constants.GRAPH_INTERVAL
  const rostered_staff_data: Array<ReducedArrangedDataType> = reduce_staff_data(data.rostered_staff_data)
  const actual_staff_data: Array<ReducedArrangedDataType> = reduce_staff_data(data.actual_staff_data)
  let sales_data: ?Array<ReducedArrangedDataType> = null
  if (data.sales_data && data.sales_data.length > 0) {
    sales_data = reduce_staff_data(data.sales_data)
  }
  let predicted_sales_data: ?Array<ReducedArrangedDataType> = null
  if (data.predicted_sales_data && data.predicted_sales_data.length > 0) {
    predicted_sales_data = reduce_staff_data(data.predicted_sales_data)
  }
  const rostered_data_times = rostered_staff_data.map((timeObject) => timeObject.momentTime)
  const actual_data_times = actual_staff_data.map((timeObject) => timeObject.momentTime)
  let sales_times = []
  if (sales_data) {
    sales_times = sales_data.map((timeObject) => timeObject.momentTime)
  }
  let predicted_sales_times = []
  if (predicted_sales_data) {
    predicted_sales_times = predicted_sales_data.map((timeObject) => timeObject.momentTime)
  }
  const all_times = [...rostered_data_times, ...actual_data_times, ...sales_times, ...predicted_sales_times]
  const max_time = moment.max(all_times)
  const min_time = moment.min(all_times)
  const num_of_interval_increments: number =
    Math.ceil(moment.duration(max_time.diff(min_time)).asMinutes() / interval) + 1
  const interval_increments: Array<number> = _.range(0, num_of_interval_increments).map((inc) => inc * interval)
  return interval_increments.map((inc) => {
    const time: moment = min_time.clone().add(inc, "minutes")
    const key: string = time.format(Constants.DATE_TIME_FORMAT)
    const buckets_in_this_interval: number = Math.ceil(interval / Constants.BUCKET_INTERVAL)
    const graphValues: { [key: string]: ?number } = calculate_live_insights_interval_data(
      time,
      buckets_in_this_interval,
      rostered_staff_data,
      actual_staff_data,
      sales_data,
      predicted_sales_data,
      departments,
      currentTimeBlockFromState,
      canSeeCosts,
      active_data_streams
    )
    return {
      ...graphValues,
      time: key,
    }
  })
}

const calculate_live_insights_interval_data = (
  time: moment,
  buckets_in_this_interval: number,
  rostered_staff_data,
  actual_staff_data,
  sales_data,
  predicted_sales_data,
  filtered_departments,
  currentTimeBlockFromState: ?moment,
  canSeeCosts: boolean,
  active_data_streams: Array<number>
): { [key: string]: ?number } => {
  const bucketRange: Array<number> = _.range(0, buckets_in_this_interval)
  return bucketRange.reduce(
    (acc: { [key: string]: ?number }, i: number) => {
      const bucketKey: string = time
        .clone()
        .subtract(i * Constants.BUCKET_INTERVAL, "minutes")
        .format(Constants.DATE_TIME_FORMAT)
      const relevant_rostered_staff_data: ?ReducedArrangedDataType = _.find(rostered_staff_data, { key: bucketKey })
      const relevant_actual_staff_data: ?ReducedArrangedDataType = _.find(actual_staff_data, { key: bucketKey })
      let relevant_sales_data: ?ReducedArrangedDataType = null
      if (sales_data) {
        relevant_sales_data = _.find(sales_data, { key: bucketKey })
      }
      let relevant_predicted_sales_data: ?ReducedArrangedDataType = null
      if (predicted_sales_data) {
        relevant_predicted_sales_data = _.find(predicted_sales_data, { key: bucketKey })
      }
      const rostered_staff_count =
        relevant_rostered_staff_data != null
          ? reduce_from_departments(filtered_departments, relevant_rostered_staff_data.counts)
          : 0
      let actual_staff_count =
        relevant_actual_staff_data != null
          ? reduce_from_departments(filtered_departments, relevant_actual_staff_data.counts)
          : 0
      let rostered_staff_cost = null
      let actual_staff_cost = null
      let sales = null
      let predicted_sales = null

      if (canSeeCosts) {
        actual_staff_cost =
          relevant_actual_staff_data != null
            ? reduce_from_departments(filtered_departments, relevant_actual_staff_data.costs)
            : 0
        rostered_staff_cost =
          relevant_rostered_staff_data != null
            ? reduce_from_departments(filtered_departments, relevant_rostered_staff_data.costs)
            : 0
      }
      sales =
        relevant_sales_data != null ? reduce_from_data_streams(active_data_streams, relevant_sales_data.counts) : 0
      predicted_sales =
        relevant_predicted_sales_data != null
          ? reduce_from_data_streams(active_data_streams, relevant_predicted_sales_data.counts)
          : 0
      if (currentTimeBlockFromState && time.isAfter(currentTimeBlockFromState)) {
        actual_staff_count = null
        actual_staff_cost = null
        sales = null
      }
      acc.actual_staff_count = i === 0 ? actual_staff_count : acc.actual_staff_count
      acc.rostered_staff_count = i === 0 ? rostered_staff_count : acc.rostered_staff_count
      acc.actual_staff_cost = i === 0 ? actual_staff_cost : (acc.actual_staff_cost || 0) + (actual_staff_cost || 0)
      acc.rostered_staff_cost = (acc.rostered_staff_cost || 0) + (rostered_staff_cost || 0)
      if (sales && sales > 0) {
        acc.sales = i === 0 ? sales : (acc.sales || 0) + sales
      }
      if (predicted_sales && predicted_sales > 0) {
        acc.predicted_sales = i === 0 ? predicted_sales : (acc.predicted_sales || 0) + predicted_sales
      }
      acc.actual_splh = ((acc.sales || 0) * (4 / buckets_in_this_interval)) / (acc.actual_staff_count || 1)
      acc.rostered_splh =
        ((acc.predicted_sales || 0) * (4 / buckets_in_this_interval)) / (acc.rostered_staff_count || 1)
      acc.actual_wage_percentage = acc.sales ? ((acc.actual_staff_cost || 0) / acc.sales) * 100 : 0
      acc.rostered_wage_percentage = acc.predicted_sales
        ? ((acc.rostered_staff_cost || 0) / acc.predicted_sales) * 100
        : 0
      if (currentTimeBlockFromState && time.isAfter(currentTimeBlockFromState)) {
        acc.actual_splh = null
        acc.actual_wage_percentage = null
      }
      return acc
    },
    {
      actual_staff_count: null,
      rostered_staff_count: null,
      actual_staff_cost: null,
      rostered_staff_cost: null,
      actual_splh: null,
      rostered_splh: null,
      actual_wage_percentage: null,
      rostered_wage_percentage: null,
      sales: null,
      predicted_sales: null,
    }
  )
}

const calculate_interval_data = (
  time: moment,
  buckets_in_this_interval: number,
  rostered_staff_data,
  actual_staff_data,
  filtered_departments,
  currentTimeBlockFromState: ?moment,
  canSeeCosts: boolean,
  active_data_streams: Array<number>
): { [key: string]: ?number } => {
  const bucketRange: Array<number> = _.range(0, buckets_in_this_interval)
  return bucketRange.reduce(
    (acc: { [key: string]: ?number }, i: number) => {
      const bucketKey: string = time
        .clone()
        .subtract(i * Constants.BUCKET_INTERVAL, "minutes")
        .format(Constants.DATE_TIME_FORMAT)
      const relevant_rostered_staff_data: ?ReducedArrangedDataType = _.find(rostered_staff_data, { key: bucketKey })
      const relevant_actual_staff_data: ?ReducedArrangedDataType = _.find(actual_staff_data, { key: bucketKey })
      const rostered_staff_count =
        relevant_rostered_staff_data != null
          ? reduce_from_departments(filtered_departments, relevant_rostered_staff_data.counts)
          : 0
      let actual_staff_count =
        relevant_actual_staff_data != null
          ? reduce_from_departments(filtered_departments, relevant_actual_staff_data.counts)
          : 0
      let rostered_staff_cost = null
      let actual_staff_cost = null

      if (canSeeCosts) {
        actual_staff_cost =
          relevant_actual_staff_data != null
            ? reduce_from_departments(filtered_departments, relevant_actual_staff_data.costs)
            : 0
        rostered_staff_cost =
          relevant_rostered_staff_data != null
            ? reduce_from_departments(filtered_departments, relevant_rostered_staff_data.costs)
            : 0
      }
      if (currentTimeBlockFromState && time.isAfter(currentTimeBlockFromState)) {
        actual_staff_count = null
        actual_staff_cost = null
      }
      acc.actual_staff_count = i === 0 ? actual_staff_count : acc.actual_staff_count
      acc.rostered_staff_count = i === 0 ? rostered_staff_count : acc.rostered_staff_count
      acc.actual_staff_cost = i === 0 ? actual_staff_cost : (acc.actual_staff_cost || 0) + (actual_staff_cost || 0)
      acc.rostered_staff_cost = (acc.rostered_staff_cost || 0) + (rostered_staff_cost || 0)
      return acc
    },
    {
      actual_staff_count: null,
      rostered_staff_count: null,
      actual_staff_cost: null,
      rostered_staff_cost: null,
    }
  )
}

// Filters the locationData to only include selected departments / locations
export const filterLocationData = (
  locationData: ?Array<LocationDataType>,
  selectedDepartments: Array<number>
): Array<LocationDataType> => {
  if (locationData == null || selectedDepartments == null) {
    return []
  }
  if (selectedDepartments.length > 0) {
    return locationData
      .map((location: LocationDataType) => ({
        ...location,
        departments: location.departments.filter((dep) => _.includes(selectedDepartments, dep.id)),
      }))
      .filter((location: LocationDataType) => location.departments.length > 0)
  } else {
    // No departments are selected, so then everything is selected
    return [...locationData]
  }
}

// Filters the locationData to only include selected departments / locations
export const locationsFromLoadedLocations = (
  locationData: ?Array<LocationDataType>,
  loadedLocations: Array<number>
): Array<LocationDataType> => {
  if (locationData == null || loadedLocations == null) {
    return []
  }
  return locationData.filter((location) => loadedLocations.some((loc_id) => loc_id === location.id))
}

export const allDepartmentIds = (locations: ?Array<LocationDataType>): Array<number> => {
  if (locations == null) {
    return []
  }

  return locations.reduce((acc: Array<number>, loc: LocationDataType): Array<number> => {
    const departmentIds = loc.departments.reduce((acc, dep): Array<number> => acc.concat(dep.id), [])

    return acc.concat(departmentIds)
  }, [])
}

export const getSelectedLocationId = (
  department_ids: Array<number>,
  locations: ?Array<LocationDataType>,
  loadedLocations: Array<number>
): ?Array<number> => {
  const locationsEmpty = locations != null && !locations.length
  const departmentsEmpty = department_ids != null && !department_ids.length
  if (locationsEmpty || departmentsEmpty) {
    return []
  }
  // Filter to locations that contain the selected departments
  return locations != null
    ? locations
        .filter((location) => location.departments.some((department) => department_ids.includes(department.id)))
        .map((location) => location.id)
    : []
}
