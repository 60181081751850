/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import { OrderedMap, fromJS } from "immutable"

import * as Shift from "timesheets/models/shift"

export const NEW_SHIFT_ACTIONS = { approve: false, update: true, configure: true }
export const NEW_SHIFT_USER = { award_tags: [], departments: [] }

/**
 * initialises the shifts store
 */
export const shiftsInit = () => new OrderedMap()

/**
 * updates a shift value (and sets it state to dirty if clean)
 * @param {Immutable.OrderedMap} shifts
 * @param {Object} $0 update data
 * @param {number} $0.id of the shift to update
 * @param {string} $0.field to update
 * @param {any} $0.value to set on field
 * @return {Immutable.OrderedMap} next state of shifts store
 */
export const shiftsUpdate = (shifts, { id, field, value }) =>
  shifts.updateIn([id, "state"], stateUpdater).setIn([id, field], value).sort(Shift.sorter)

/**
 * updates a value on a shift's break
 * @param {Immutable.OrderedMap} shifts
 * @param {Object} $0 update data
 * @param {number} $0.id of the shift to update
 * @param {string} $0.field to update
 * @param {any} $0.value to set on field
 * @return {Immutable.OrderedMap} next state of shifts store
 */
export const shiftsBreakMergeIn = (shifts, { shift, shiftBreakId, changes }) => {
  const breakIndex = shift.get("breaks").findIndex((sb) => sb.get("id") === shiftBreakId)

  return shifts
    .updateIn([shift.get("id"), "state"], stateUpdater)
    .mergeIn([shift.get("id"), "breaks", breakIndex], changes)
    .sort(Shift.sorter)
}

/**
 * updates a shift via merge (and sets it state to dirty if clean)
 * @param {Immutable.OrderedMap} shifts
 * @param {Object} $0 update data
 * @param {number} $0.id of the shift to update
 * @param {Immutable.Map|Object} $0.shift to merge
 * @return {Immutable.OrderedMap} next state of shifts store
 */
export const shiftsMergeIn = (shifts, { id, shift }) => {
  if (shifts.get(id) === -1) {
    return shifts
  }

  return shifts.updateIn([id, "state"], stateUpdater).mergeIn([id], shift).sort(Shift.sorter)
}

/**
 * creates a shift
 * @param {Immutable.OrderedMap} shifts
 * @param {Object} $1 data for new shift
 * @return {Immutable.OrderedMap} next state of shifts store
 */
export const shiftsCreate = (shifts, data) => {
  const id = data.id || -shifts.size - 1

  return shifts
    .set(
      id,
      Shift.build({
        is_collapsed: false,
        ...data,
        id,
        department: null,
        permitted_actions: NEW_SHIFT_ACTIONS,
        user: NEW_SHIFT_USER,
      })
    )
    .sort(Shift.sorter)
}

/**
 * creates a shift break, given a shift
 * @param {Immutable.OrderedMap} shifts
 * @param {Immutable.Map} the shift to add a break for
 * @return {Immutable.OrderedMap} next state of shifts store
 */
export const shiftsBreakCreate = (shifts, { shift }) => {
  const newBreak = Shift.buildBreak({
    shift_id: shift.get("id"),
    id: -(shift.get("breaks").size + 1),
  })

  return shifts.updateIn([shift.get("id"), "breaks"], (breaks) => breaks.push(newBreak))
}

/**
 * creates a mocked shift to follow the shift passed in on the same day
 * @param {Immutable.OrderedMap} shifts
 * @param {Object} $0 update data
 * @param {Object} $0.shift to base mocked shift off
 * @return {Immutable.OrderedMap} next state of shifts store
 */
export const shiftsCopy = (shifts, { shift }) =>
  shifts
    .set(
      -shifts.size,
      Shift.build({
        date: shift.get("date"),
        id: -shifts.size,
        timesheet_id: shift.get("timesheet_id"),
        user_id: shift.get("user_id"),
        user: shift.get("user"),
        user_name: shift.get("user_name"),
        is_collapsed: false,
        department_id: shift.get("department_id"),
        department: shift.get("department"),
        permitted_actions: shift.get("permitted_actions"),
      })
    )
    .sort(Shift.sorter)

export const shiftsUpdateAllowances = (state, { id, shift_allowances }) =>
  state.updateIn([id, "state"], stateUpdater).updateIn([id, "shift_allowances"], () => fromJS(shift_allowances))

/**
 * takes id of mocked shift, removes it, inserts new shift
 * @param {Immutable.OrderedMap} shifts
 * @param {Object} $0 update data
 * @param {number} $0.id of mocked shift
 * @param {object} $0.shift to insert
 * @return {Immutable.OrderedMap} next state of shifts store
 */
export const shiftsReplace = (shifts, { id, shift }) =>
  shifts.delete(id).set(shift.get("id"), shifts.get(id).merge(shift)).sort(Shift.sorter)

/**
 * helper: which returns dirty if the curr state of a shifts is set to clean
 * @param {string} currState
 * @return {string} new shift state to set
 */
const stateUpdater = (currState) => (currState === Shift.States.Clean ? Shift.States.Dirty : currState)
