/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import "react-dates/initialize"
import * as React from "react"
import _ from "underscore" // eslint-disable-line underscore-to-lodash/prefer-import-lodash
import PropTypes from "prop-types"
import cn from "classnames"
import { SingleDatePicker } from "react-dates"
import * as Constants from "react-dates/constants"
import Icon from "components/Icon"
import styles from "./styles.module.scss"

export { Constants }

export default class TandaSingleDatePicker extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleFocusChange = this.handleFocusChange.bind(this)
    this.state = { isFocused: false }
  }

  handleFocusChange(event) {
    this.setState({ isFocused: event.focused })
    this.props.onFocusChange(event)
  }

  render() {
    const { color, controlledFocus, size, textAlign, ...props } = this.props

    return (
      <div
        className={cn(styles.datePickerWrapper, styles[color], styles[size], styles[textAlign], {
          [styles.focus]: this.state.isFocused,
        })}
      >
        {textAlign === "center" && (
          <div className={styles.calendarIcon}>
            <Icon type="insert-invitation" />
          </div>
        )}
        <SingleDatePicker
          {...props}
          focused={controlledFocus ? this.props.focused : this.state.isFocused}
          onFocusChange={this.handleFocusChange}
          showClearDate={false}
        />
        {props.date != null && props.showClearDate && (
          <div className={styles.clearDateIconContainer}>
            <div
              className={styles.clearDateIcon}
              onClick={(e) => {
                this.props.onDateChange(null)
              }}
              onFocus={_.noop}
              onKeyUp={_.noop}
              role="button"
              tabIndex={0}
            >
              <Icon type="close" />
            </div>
          </div>
        )}
      </div>
    )
  }
}

TandaSingleDatePicker.defaultProps = {
  textAlign: "start",
  color: "default",
  size: "s",
  controlledFocus: PropTypes.bool,
  focused: PropTypes.bool,
}

/**
 * rest of api here
 * https://github.com/airbnb/react-dates#singledatepicker
 */
TandaSingleDatePicker.propTypes = {
  onDateChange: PropTypes.func.isRequired,
  textAlign: PropTypes.oneOf(["center", "start"]),
  color: PropTypes.oneOf(["success", "error", "warning", "default", "overnight"]),
  onFocusChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(["s", "m", "l"]),
  controlledFocus: true,
  focused: false,
}
