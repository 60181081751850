// @flow

import * as React from "react"
import { connect, type ConnectedComponent } from "react-redux"
import _ from "lodash"
import { t as globalT } from "helpers/i18n"
import Modal, { Header, Footer, FooterRight } from "components/Modal"
import Text from "components/Text"
import Button from "components/Button"
import Icon from "components/Icon"
import OptionButton, {
  Title as OptionButtonTitle,
  Description as OptionButtonDescription,
} from "components/OptionButton"
import type { MappedPlan, Users } from "../../logic/types"
import EmployeeCard from "./EmployeeCard"
import CurateConfirmation from "./CurateConfirmation"
import BroadcastConfirmation from "./BroadcastConfirmation"

import styles from "./styles.module.scss"

const t = (key, opts) => globalT(`js.shift_swaps.landing.modal.${key}`, opts)

const INITIAL_STATE = {
  selectedProposalIds: [],
  method: null,
  employeesSelected: false,
  plan: null,
}

export type Props = {
  onConfirm: () => void,
  onExit: () => void,
  open: boolean,
  plan: MappedPlan,
  users: Users,
}

type State = {
  employeesSelected: boolean,
  method: ?("curate" | "broadcast"),
  plan: ?MappedPlan,
  selectedProposalIds: Array<number>,
}

export class NewRequest extends React.Component<Props, State> {
  state: State = INITIAL_STATE

  UNSAFE_componentWillReceiveProps({ open }: Props) {
    if (this.props.open !== open) {
      this.setState({ ...INITIAL_STATE, plan: this.props.plan })
    }
  }

  renderModalContent(): React.Node {
    if (this.state.employeesSelected) {
      return this.renderCurateConfirm()
    }
    if (this.state.method === "broadcast") {
      // Offer <Name>'s shift to all staff?
      return this.renderBroadcastConfirm()
    }
    if (this.state.method === "curate") {
      return this.renderPickEmployee()
    }
    return this.renderBroadcastMode()
  }

  onEmployeeClick(proposalId: number): () => void {
    return () => {
      const newProposalIds = this.isProposalSelected(proposalId)
        ? this.state.selectedProposalIds.filter((id) => id !== proposalId)
        : [...this.state.selectedProposalIds, proposalId]
      this.setState({ selectedProposalIds: newProposalIds })
    }
  }

  onBroadcastOptionClick(method: "curate" | "broadcast"): () => void {
    return () => {
      this.setState({ method })
    }
  }

  onSelectStaff(): () => void {
    return () => {
      this.setState({ employeesSelected: true })
    }
  }

  confirmationCancel: () => void = () => this.setState({ employeesSelected: false })

  isProposalSelected(proposalId: number): boolean {
    return _.includes(this.state.selectedProposalIds, proposalId)
  }

  updateProposal(proposalId: number): (costs: number, hours: number) => void {
    return (costs, hours) => {
      const plan = { ...this.props.plan }
      const proposal = plan.proposals.find((proposal) => proposal.id === proposalId)
      if (proposal) {
        proposal.cost = costs
        proposal.hours_in_week = hours
      }
      this.setState({ plan })
    }
  }

  renderCurateConfirm(): React.Node {
    return (
      <CurateConfirmation
        onBack={this.confirmationCancel}
        onConfirm={this.props.onConfirm}
        onExit={this.props.onExit}
        plan={this.props.plan}
        selectedProposalIds={this.state.selectedProposalIds}
        users={this.props.users}
      />
    )
  }

  renderBroadcastConfirm(): React.Node {
    return <BroadcastConfirmation onConfirm={this.props.onConfirm} onExit={this.props.onExit} plan={this.props.plan} />
  }

  renderBroadcastMode(): React.Node {
    return (
      <React.Fragment>
        <Header titleText={t("select_method")} />
        <OptionButton onClick={this.onBroadcastOptionClick("broadcast")} shape="flexible">
          <Icon color="grey" size="xl" type="people" />
          <OptionButtonTitle text={t("method.broadcast.title")} />
          <OptionButtonDescription text={t("method.broadcast.subtitle")} />
        </OptionButton>
        <OptionButton onClick={this.onBroadcastOptionClick("curate")} shape="flexible">
          <Icon color="grey" size="xl" type="person-add" />
          <OptionButtonTitle text={t("method.curate.title")} />
          <OptionButtonDescription text={t("method.curate.subtitle")} />
        </OptionButton>
      </React.Fragment>
    )
  }

  renderPickEmployee(): React.Node {
    return (
      <React.Fragment>
        <Header titleText={t("select_employees_title")} />
        <div className={styles.cardContainer}>
          {this.props.plan.proposals.length === 0 ? (
            <Text align="center">{t("no_employees")}</Text>
          ) : (
            this.props.plan.proposals
              .sort((a, b) => (a.cost > b.cost ? 1 : -1))
              .sort((a, b) => (b.cost === 0 ? -1 : 1))
              .sort((a, b) =>
                this.props.users[a.user_id.toString()].name.toUpperCase() >
                this.props.users[b.user_id.toString()].name.toUpperCase()
                  ? 1
                  : -1
              )
              .map((proposal) => (
                <EmployeeCard
                  costs={{ previous: this.props.plan.schedule_data.cost, ifSwapped: proposal.cost }}
                  hours={
                    proposal.hours_in_week != null
                      ? {
                          ifSwapped: proposal.hours_in_week,
                          previous: proposal.hours_in_week - this.props.plan.schedule_data.hours,
                        }
                      : null
                  }
                  key={proposal.id}
                  onClick={this.onEmployeeClick(proposal.id)}
                  plan={this.props.plan}
                  selected={this.isProposalSelected(proposal.id)}
                  updateProposal={this.updateProposal(proposal.id)}
                  user={this.props.users[proposal.user_id.toString()]}
                />
              ))
          )}
        </div>
        <Footer>
          <FooterRight>
            <Button
              disabled={this.state.selectedProposalIds.length < 1}
              label={t("confirm")}
              onClick={this.onSelectStaff()}
              type="theme"
            />
          </FooterRight>
        </Footer>
      </React.Fragment>
    )
  }

  render(): React.Node {
    return (
      <Modal onExit={this.props.onExit} open={this.props.open} size="s">
        {this.renderModalContent()}
      </Modal>
    )
  }
}

const mapStateToProps = (store, props) => ({ users: props.users || store.users })

// $FlowFixMe flow >0.85 requires more types for connect
// eslint-disable-next-line flowtype/no-weak-types
export default (connect(mapStateToProps)(NewRequest): Class<ConnectedComponent<any, typeof NewRequest>> &
  typeof NewRequest)
