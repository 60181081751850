// @flow
import _ from "lodash"

export type Schema = {|
  department_id: number,
  id: number,
  name: string,
|}

export const getShortName = (name: string): string => {
  const initials = name
    .split(" ")
    .map((w) => w[0])
    .join("")
  return initials.length > 3 ? initials.substring(0, 3) : initials
}

export const sort = (details: Array<Schema>): Array<Schema> => _.sortBy(details, (sd) => sd.name)

export default getShortName
