/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { t } from "helpers/i18n"
import * as Routes from "helpers/routes"
import Text from "components/Text"
import Currency from "helpers/currency"
import * as User from "timesheets/models/user"
import TipTotals from "./TipTotals"
import Metric from "./Metric"
import CostTotalsPopover from "./CostTotalsPopover"
import styles from "./styles.module.scss"

export default class Totals extends React.PureComponent {
  getRecalcLink() {
    if (this.props.isWeekly) {
      const timesheetID = this.props.timesheet.get("id")
      return Routes.timesheet_path(timesheetID, { recalc_tip_jars: true })
    } else {
      return Routes.timesheets_daily_path(this.props.date, { recalc_tip_jars: true })
    }
  }

  getStatusText(status) {
    const colour = status === "approved" ? "success" : status === "pending" ? "warning" : "default"
    return (
      <Text color={colour} type="inherit">
        {" "}
        {t(`js.timesheets.totals_bar.status_${status}`)}{" "}
      </Text>
    )
  }

  render() {
    const status = (this.props.timesheet && this.props.timesheet.get("status")) || "pending"
    const canSeeCosts = this.props.timesheet && User.canSeeCostsOnTimesheet(this.props.timesheet)
    const total_cost_popover = this.props.isWeekly ? null : (
      <CostTotalsPopover
        allowances={this.props.costs.allowances}
        leave={this.props.costs.leave}
        leaveHours={this.props.costs.leaveHours}
        offset={1.25}
        rostered={this.props.costs.schedule}
        rosteredHours={this.props.costs.scheduleHours}
        variance={this.props.costs.variance}
        worked={this.props.costs.worked}
        workedHours={this.props.costs.workedHours}
      />
    )
    return (
      <div className={cn(styles.Totals, { [styles.isSyncing]: this.props.isSyncing })}>
        {this.props.isWeekly && (
          <Metric
            icon={status === "exported" ? "lock" : "none"}
            label={t("js.timesheets.totals_bar.status")}
            rightBorder
            value={this.getStatusText(status)}
          />
        )}
        <Metric icon="schedule" label={t("js.timesheets.totals_bar.total_length")} value={this.props.totalLength} />
        <Metric
          icon="free-breakfast"
          label={t("js.timesheets.totals_bar.total_breaks")}
          value={this.props.totalBreaks}
        />
        {this.props.costs.total > 0 && canSeeCosts && (
          <Metric
            icon="monetization-on"
            label={t("js.timesheets.totals_bar.total_cost")}
            popover={total_cost_popover}
            value={Currency.from(this.props.costs.total)}
          />
        )}
        {this.props.costs.tips > 0 && canSeeCosts && (
          <TipTotals
            actions={this.props.actions}
            date={this.props.date}
            isWeekly={this.props.isWeekly}
            timesheet={this.props.timesheet}
            totalTips={this.props.costs.tips}
          />
        )}
      </div>
    )
  }
}

Totals.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  costs: PropTypes.shape({
    allowances: PropTypes.number,
    leave: PropTypes.number,
    leaveHours: PropTypes.string,
    schedule: PropTypes.number,
    scheduleHours: PropTypes.string,
    total: PropTypes.number.isRequired,
    variance: PropTypes.number,
    worked: PropTypes.number,
    workedHours: PropTypes.string,
    tips: PropTypes.number.isRequired,
  }).isRequired,
  isSyncing: PropTypes.bool,
  timesheet: PropTypes.object,
  totalBreaks: PropTypes.string.isRequired,
  totalLength: PropTypes.string.isRequired,
  date: PropTypes.string,
  isWeekly: PropTypes.bool.isRequired,
}

Totals.defaultProps = { date: "", timesheet: null, isSyncing: false }
