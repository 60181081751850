// @flow
import * as React from "react"
import { compact, without, isEqual } from "lodash"
import FileButton from "components/FileButton"
import * as M from "helpers/marshall"

import * as Tanda from "platform/clients/tanda"

import * as Assoc from "../../models/association"
import * as Record from "../../models/record"
import * as State from "../../models/state"
import * as Store from "../../store"

type Ev = SyntheticInputEvent<HTMLElement>

type Props = {|
  +assoc: Assoc.Schema,
  +loading: boolean,
  +onEdit: (Ev) => mixed,
  +onSave: () => mixed,
  +onUploadFinish: () => void,
  +onUploadStart: () => void,
  +record: Record.Schema,
  +value: Array<number | string>,
|}

export default function ManyToManyFile({
  assoc,
  loading,
  onEdit,
  onSave,
  onUploadFinish,
  onUploadStart,
  record,
  value,
}: Props): React.Node {
  const [cache, setCache] = React.useState(value)
  const [state, dispatch] = Store.useContext()

  if (!isEqual(value, cache)) {
    setCache(value)
    onSave()
  }

  const fileRecords = value.map((v) => State.recordById(state, v.toString()))
  const files = compact(fileRecords).map((f) => Record.asFile(f))

  const [inProgressFile, setInProgressFile] = React.useState(null)

  // whenever a file change happens:
  // - upload it to the backend
  // - get back platform record ID for it
  // - assign that as the value for the relevant association (call onEdit + onSave)
  const processFileUpload = React.useCallback(
    (val: ?File) => {
      setInProgressFile(val)
      onUploadStart()

      if (val) {
        Tanda.uploadFile(val).then((fileRecord: Record.Schema) => {
          // keep a local copy of the new file record. this ensures we can show it when navigating around the SPA.
          dispatch({ data: fileRecord, type: "@create/record" })

          // associate the new file record with the record we're editing.
          const newVal = [...value, parseInt(fileRecord.id)]
          // $FlowFixMe not a real synthetic event
          onEdit({ target: { name: assoc.key, value: newVal } })

          onUploadFinish()
          setInProgressFile(null)
        })
      }
    },
    [assoc.key, dispatch, onEdit, value, onUploadFinish, onUploadStart]
  )

  // removes the file from the local record
  // note that this does NOT delete the file from the backend
  const processFileRemoved = React.useCallback(
    (id: string) => {
      const newVal = without(value, parseInt(id))
      // $FlowFixMe not a real synthetic event
      onEdit({ target: { name: assoc.key, value: newVal } })
    },
    [assoc.key, onEdit, value]
  )

  const fileError = null // TODO
  const verificationTypes = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/gif",
    "application/pdf",
    "text/plain",
    "text/csv",
  ]
  const showNewFileButton = !inProgressFile && !loading

  return (
    <React.Fragment>
      {files.map((file) => (
        <FileButton
          disabled
          fileName={M.string(file.file_file_name)}
          key={file.id}
          onChange={(val: ?File) => (val ? processFileUpload(val) : processFileRemoved(file.id))}
          url={M.string(file.url)}
        />
      ))}

      {inProgressFile && (
        <FileButton
          disabled
          error={fileError}
          fileName={inProgressFile.name}
          onChange={processFileUpload}
          verificationTypes={verificationTypes}
        />
      )}

      {showNewFileButton && (
        <FileButton disabled error={null} onChange={processFileUpload} verificationTypes={verificationTypes} />
      )}
    </React.Fragment>
  )
}
