// @flow
import * as State from "../../models/state"
import type { Action } from "../action"
import * as Utils from "./utils"

type Resources = State.Partial<"resources">

export default function resources(state: Resources, action: Action): Resources {
  switch (action.type) {
    case "@patch/state":
      return Utils.merge(state, { [action.url]: action.updatedAt })
    default:
      return state
  }
}
