/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import { fromJS, Map } from "immutable"
import { createReducer, createActions } from "redux-em"

const leaveBalanceInit = () => new Map()

const leaveBalanceSet = (state, data) => fromJS(data)

const leaveBalanceMerge = (state, leaveBalance) => state.merge(leaveBalance)

export const Core = {
  leaveBalanceInit,
  leaveBalanceSet,
  leaveBalanceMerge,
}

export const leaveBalanceActions = createActions(Core)

export const leaveBalanceReducer = createReducer(Core)
