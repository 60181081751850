// @flow
import * as React from "react"
import Checkbox from "components/Checkbox"

type Ev = SyntheticInputEvent<HTMLInputElement>

type Props = {|
  disabled: boolean,
  htmlName: string,
  onEdit: (Ev) => mixed,
  onSave: () => mixed,
  value: boolean,
|}

export default function BoolInput(props: Props): React.Node {
  const [prev, setPrev] = React.useState(props.value)

  if (props.value !== prev) {
    props.onSave()
    setPrev(props.value)
  }

  return (
    <Checkbox
      autoMargin={false}
      checked={props.value}
      disabled={props.disabled}
      htmlName={props.htmlName}
      // $FlowFixMe formsave expects a 'string' but checkbox is boolean
      onClick={props.onEdit}
    />
  )
}
