/* @flow */
import * as React from "react"
import cn from "classnames"
import ErrorState from "dashboard/generic/ErrorState"
import LoadingState from "dashboard/generic/LoadingState"
import EmptyState from "dashboard/generic/EmptyState"
import { t } from "helpers/i18n"
import styles from "../styles.module.scss"
import Header from "./Header"

type PropType = {
  canSeeCosts: boolean,
  hideWidget: ?() => void,
  isEmpty: boolean,
  isError: boolean,
  isLiveInsights: boolean,
  isLoading: boolean,
  retryDataLoad: () => void,
}

export default class ProblemStates extends React.PureComponent<PropType> {
  render(): React.Element<"div"> {
    const { isError, isLoading, isEmpty, retryDataLoad } = this.props
    let problemState
    if (isError) {
      problemState = (
        <ErrorState
          messageText={t("js.dashboard.widgets.live_insights.error_state.message_text")}
          onTryAgain={retryDataLoad}
          retryText={t("js.dashboard.widgets.live_insights.error_state.retry_text")}
        />
      )
    } else if (isLoading) {
      problemState = (
        <div className={cn(styles.bodyContainer, "not-widget-draggable")}>
          <LoadingState messageText={t("js.dashboard.widgets.live_insights.fetching")} />
        </div>
      )
    } else if (isEmpty) {
      problemState = (
        <div className={cn(styles.bodyContainer, "not-widget-draggable")}>
          <EmptyState
            createRosterText={t("js.dashboard.widgets.live_insights.empty_state.create_roster_text")}
            messageText={t("js.dashboard.widgets.live_insights.empty_state.message_text")}
            viewTimesheetsText={t("js.dashboard.widgets.live_insights.empty_state.view_timesheets_text")}
          />
        </div>
      )
    }
    return (
      <div className={styles.Root}>
        <Header
          canSeeCosts={this.props.canSeeCosts}
          headerText={t(
            `js.dashboard.widgets.live_insights.header.${
              this.props.isLiveInsights ? "live_insights" : "live_wage_tracker"
            }`
          )}
          hideWidget={this.props.hideWidget}
        />
        {problemState}
      </div>
    )
  }
}
