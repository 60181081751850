/* @flow */

import * as React from "react"
import cn from "classnames"
import styles from "./styles.module.scss"
import { type Props as RostersButtonProps, DEFAULT_PROPS as BUTTON_DEFAULT_PROPS } from "./RostersButton"
import { RostersButton } from "./"

export type RequiredProps = {|
  onClick: (e: SyntheticMouseEvent<HTMLButtonElement>) => mixed,
|}

export type Props = {|
  ...RostersButtonProps,
  children: React.Node,
  popupAnchor: "top-left" | "top-right" | "bottom-left" | "bottom-right",
  popupOpen: boolean,
|}

export const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  ...BUTTON_DEFAULT_PROPS,
  popupAnchor: "bottom-left",
  popupOpen: false,
  children: null,
}

export default class ButtonWithPopup extends React.PureComponent<Props> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  render(): React.Element<"div"> {
    const { children, popupAnchor, popupOpen, ...rest } = this.props

    return (
      <div className={styles.buttonWithPopupContainer}>
        <RostersButton {...rest} />
        {popupOpen ? <div className={cn(styles.popup, styles[`popup-position-${popupAnchor}`])}>{children}</div> : null}
      </div>
    )
  }
}
