// @flow
import * as React from "react"
import { noop } from "lodash/fp"
import type { SelectEvent, Value } from "components/Select"
import { type Props as DropdownListProps } from "components/DropdownList"
import DefaultGroup from "components/DropdownList/DefaultGroup"
import DefaultOption from "./DefaultOption"
import DefaultSelected from "./DefaultSelected"
import BaseMultiSelect from "./BaseMultiSelect"

export type RequiredProps = {|
  onChange: (e: SelectEvent) => mixed,
  options: $PropertyType<DropdownListProps, "options">,
  value: Array<Value>,
|}

export type Props = {|
  ...RequiredProps,
  color: string,
  disabled: boolean,
  fixedHeight: boolean,
  forceShowTip: boolean,
  Group: $PropertyType<DropdownListProps, "Group">,
  htmlName: ?string,
  invalid: boolean,
  newStyle: boolean,
  onBlur: () => mixed,
  Option: $PropertyType<DropdownListProps, "Option">,
  options: $PropertyType<DropdownListProps, "options">,
  placeholder: string,
  Selected: $PropertyType<DropdownListProps, "Option">,
  size: "s" | "l",
  tabIndex: number,
  tipText: string,
  value: Array<Value>,
|}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  color: "default",
  disabled: false,
  fixedHeight: false,
  forceShowTip: false,
  Group: DefaultGroup,
  htmlName: null,
  newStyle: false,
  Option: DefaultOption,
  Selected: DefaultSelected,
  tabIndex: 0,
  size: "s",
  tipText: "",
  onBlur: noop,
  placeholder: "",
  invalid: false,
}

export default function MultiSelect(props: Props): React.Node {
  return <BaseMultiSelect {...props} />
}

MultiSelect.defaultProps = DEFAULT_PROPS
