/* @flow */
import * as React from "react"

import type { chartTypes } from "dashboard/live_insights/main"

import styles from "./styles.module.scss"

type PropTypes = {
  chartType: chartTypes,
  color: string,
}

export default class GraphLegendIcon extends React.PureComponent<PropTypes> {
  legendIcon: () => React.Element<"div"> | React.Element<"svg"> = () => {
    switch (this.props.chartType) {
      case "none":
        return <div className={styles.blankIcon} />
      case "solidBar":
        return (
          <div className={styles.barLegendContainer}>
            <div className={styles.barLegendIconThin} style={{ backgroundColor: this.props.color }} />
            <div className={styles.barLegendIconThicc} style={{ backgroundColor: this.props.color }} />
          </div>
        )
      case "departmentSwatch":
        return <div className={styles.departmentSwatchIcon} style={{ backgroundColor: this.props.color }} />
      case "solidLine":
        return (
          <svg height={5} width={18}>
            <line style={{ stroke: this.props.color, strokeWidth: 10 }} x1="0" x2={18} y1={5 / 2} y2={5 / 2} />
          </svg>
        )
      case "dottedLine":
        return (
          <svg height={5} width={18}>
            <line
              style={{ strokeDasharray: "6,6", stroke: this.props.color, strokeWidth: 5 }}
              x1="0"
              x2={18}
              y1={5 / 2}
              y2={5 / 2}
            />
          </svg>
        )
    }
  }
  render(): React.Element<"div"> {
    const legendIcon = this.legendIcon()

    return <div className={styles["type-" + (this.props.chartType || "")]}>{legendIcon}</div>
  }
}
