/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import { t as globalT } from "helpers/i18n"

import * as TimeFormatter from "helpers/time_formatter"
import * as Shift from "timesheets/models/shift"
import * as User from "timesheets/models/user"
import Icon from "components/Icon"
import { Input } from "components/Input"

import TimeOption from "../TimeOption"
import styles from "./styles.module.scss"

const t = (key, opts) => globalT(`js.timesheets.shift_card.${key}`, opts)

export default class TimeInput extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleOptionClick = this.handleOptionClick.bind(this)
    this.setInputRef = this.setInputRef.bind(this)
    this.conductTimeUpdate = this.conductTimeUpdate.bind(this)

    const value = TimeFormatter.shiftTimeInput(props.shiftOrBreak.get(props.field), props.currentUser)

    this.state = {
      cachedValue: value,
      currentValue: value,
      isOvernight: Shift.isOvernight(props.shiftOrBreak.get(props.field), props.date),
    }
  }

  UNSAFE_componentWillReceiveProps({ currentUser, field, shiftOrBreak }) {
    if (shiftOrBreak !== this.props.shiftOrBreak) {
      this.setState({ isOvernight: Shift.isOvernight(shiftOrBreak.get(field), this.props.date) })
    }

    if (shiftOrBreak.get(field).toString() !== this.props.shiftOrBreak.get(this.props.field).toString()) {
      const newValue = TimeFormatter.shiftTimeInput(shiftOrBreak.get(field), currentUser)
      this.setState({ cachedValue: newValue, currentValue: newValue })
    }
  }

  handleBlur() {
    if (this.state.currentValue !== this.state.cachedValue) {
      this.conductTimeUpdate(this.state.currentValue)
    }
  }

  handleChange(event) {
    this.setState({ currentValue: event.target.value })
  }

  handleFocus() {
    this.input.select()
  }

  handleKeyDown(event) {
    if (event.keyCode === 13) {
      // Enter
      this.input.blur()
    }
  }

  handleOptionClick(event) {
    this.conductTimeUpdate(event.target.value)
  }

  conductTimeUpdate(value) {
    this.props.actions.shiftsConductTimeUpdate({
      shift: this.props.shiftOrBreak,
      field: this.props.field,
      value,
    })
  }

  renderColor() {
    if (this.props.error || (this.props.required && !this.state.currentValue)) {
      return "error"
    }

    if (this.state.isOvernight) {
      return "overnight"
    }

    if (this.props.confirmed === false && User.canEditConfirmedTimes(this.props.currentUser)) {
      return "warning"
    }
  }

  renderSameDateErrorTip() {
    if (this.props.error.get("position") === Shift.ErrorPosition.AFTER) {
      return t("errors.same_date_before", {
        time: TimeFormatter.shiftTimeInput(this.props.error.get("altTime"), this.props.currentUser),
      })
    }

    if (this.props.error.get("position") === Shift.ErrorPosition.BEFORE) {
      return t("errors.same_date_after", {
        time: TimeFormatter.shiftTimeInput(this.props.error.get("altTime"), this.props.currentUser),
      })
    }
  }

  renderAltDateErrorTip() {
    if (this.props.error.get("position") === Shift.ErrorPosition.AFTER) {
      return t("errors.alt_date_before", {
        time: TimeFormatter.shiftTimeInput(this.props.error.get("altTime"), this.props.currentUser),
      })
    }

    if (this.props.error.get("position") === Shift.ErrorPosition.BEFORE) {
      return t("errors.alt_date_after", {
        time: TimeFormatter.shiftTimeInput(this.props.error.get("altTime"), this.props.currentUser),
      })
    }
  }

  renderTipText() {
    if (this.props.disabled) {
      return null
    }

    if (this.props.error && this.props.error.get("type") === Shift.ErrorType.SAME_SHIFT_OVERLAP) {
      return t(`errors.same_shift_overlap.${this.props.error.get("field")}`)
    }

    if (this.props.error && this.props.error.get("type") === Shift.ErrorType.SAME_DATE_OVERLAP) {
      return this.renderSameDateErrorTip()
    }

    if (this.props.error && this.props.error.get("type") === Shift.ErrorType.ALT_DATE_OVERLAP) {
      return this.renderAltDateErrorTip()
    }

    if (this.props.required && !this.state.currentValue) {
      return t("inputs.required_field", { field: t(`inputs.${this.props.field}`) })
    }

    if (this.state.isOvernight) {
      return t("inputs.overnight_time")
    }

    if (this.props.confirmed === false && User.canEditConfirmedTimes(this.props.currentUser)) {
      return t("inputs.unconfirmed_time")
    }
  }

  setInputRef(el) {
    this.input = el
  }

  render() {
    return (
      <div className={styles.TimeInput}>
        <Input
          color={this.renderColor()}
          disabled={this.props.disabled}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onKeyDown={this.handleKeyDown}
          textAlign="center"
          tipText={this.renderTipText()}
          updateRef={this.setInputRef}
          value={this.state.currentValue}
        />
        {this.state.isOvernight && (
          <span className={styles.overnightIcon}>
            <Icon size="xs" type="brightness-3" />
          </span>
        )}
        <div className={styles.options}>
          {this.props.clocked.isValid() ? (
            <TimeOption
              currentUser={this.props.currentUser}
              disabled={this.props.disabled}
              onClick={this.handleOptionClick}
              time={this.props.clocked}
              type="clocked"
            />
          ) : null}
          {this.props.rostered.isValid() ? (
            <TimeOption
              currentUser={this.props.currentUser}
              disabled={this.props.disabled}
              onClick={this.handleOptionClick}
              time={this.props.rostered}
              type="rostered"
            />
          ) : null}
        </div>
      </div>
    )
  }
}

TimeInput.defaultProps = {
  confirmed: false,
  disabled: false,
  error: null,
  required: false,
}

TimeInput.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  clocked: PropTypes.object.isRequired,
  confirmed: PropTypes.bool,
  currentUser: PropTypes.object.isRequired,
  error: PropTypes.object,
  disabled: PropTypes.bool,
  field: PropTypes.string.isRequired,
  required: PropTypes.bool,
  rostered: PropTypes.object.isRequired,
  shiftOrBreak: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired, // YYYY-MM-DD
}
