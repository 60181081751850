// @flow
import * as React from "react"
import cn from "classnames"

type PillSize = "sm" | "base"
export type PillType = "theme" | "action" | "warning" | "danger"

type PillProps = {|
  iconType?: string,
  label: string,
  size: PillSize,
  type: PillType,
|}

const Pill = (props: PillProps): React.Element<"div"> => (
  <div className={cn(`pill-${props.type}`, `pill-${props.size}`)} role="status">
    {props.iconType && <i className={`mi mi-${props.iconType} main-icon`} />}
    {props.label}
  </div>
)

Pill.defaultProps = {
  iconType: "",
  type: "theme",
  size: "base",
}

export default Pill
