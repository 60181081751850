// @flow

import { createActions, createReducer } from "redux-em"
import _ from "lodash"

const plansInit = () => ({})
const plansUpdate = (plans, { id, plan }) => {
  if (plans[id.toString()] == null) {
    return plans
  }
  return { ...plans, [id.toString()]: plan }
}
const plansRemove = (plans, { id }) => {
  if (plans[id.toString()] == null) {
    return plans
  }
  plans[id.toString()] = undefined
  return _.omit(plans, [id])
}

const logic = { plansInit, plansUpdate, plansRemove }

// eslint-disable-next-line flowtype/no-weak-types
export const plansReducer: any = createReducer(logic)
// eslint-disable-next-line flowtype/no-weak-types
export const plansActions: any = createActions(logic)
