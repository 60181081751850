// @flow
import * as React from "react"

import InputLabel from "components/Label/InputLabel"
import BaseFilterSelect, { type Props as BaseProps, DEFAULT_PROPS as BASE_DEFAULT_PROPS } from "./BaseFilterSelect"

export type RequiredProps = {|
  onChange: $PropertyType<BaseProps, "onChange">,
  options: $PropertyType<BaseProps, "options">,
|}

export type Props = {|
  label: ?string,
  ...RequiredProps,
  ...BaseProps,
|}

export const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  label: null,
  ...BASE_DEFAULT_PROPS,
}

const getInputLabel = (label) => {
  if (!label) {
    return null
  }

  return <InputLabel text={label} />
}

export default function FilterSelect(props: Props): React.Element<"div"> {
  const { label, ...rest } = props

  return (
    <div>
      {getInputLabel(label)}
      <BaseFilterSelect size="s" {...rest} />
    </div>
  )
}

FilterSelect.defaultProps = DEFAULT_PROPS
