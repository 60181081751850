// @flow
import * as React from "react"
import { t } from "helpers/i18n"
import { FilterSelect } from "components/Select/FilterSelect"

import TeamOption from "components/Select/TeamSelect/TeamOption"

type Props = {
  actions: *,
  disabled: boolean,
  shift: *,
}

export function mapOptions(shift: *): * {
  return [
    {
      label: t("js.timesheets.shift_card.config.default_award_tag"),
      value: "",
    },
  ].concat(
    shift
      .getIn(["user", "award_tags"])
      .toSet()
      .add(shift.get("tag"))
      .filter(Boolean)
      .toArray()
      .map((t) => ({ label: t, value: t }))
  )
}

export default function AwardTagSelect({ actions, disabled = false, shift }: Props): React.Node {
  const options = React.useMemo(() => mapOptions(shift), [shift])

  const onChange = React.useCallback(
    (e) => actions.shiftsSync({ shift, fields: { tag: e.target.value } }),
    [actions, shift]
  )

  return (
    <FilterSelect
      disabled={disabled}
      onChange={onChange}
      Option={TeamOption}
      options={options}
      placeholder={t("js.timesheets.shift_card.config.default_award_tag")}
      value={shift.get("tag")}
    />
  )
}
