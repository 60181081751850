/* @flow */
import * as React from "react"
import moment from "moment"
import Text from "components/Text"
import { t as globalT } from "helpers/i18n"
import type {
  RechartsDataType,
  LiveInsightsGraphEntriesType,
  DataFiltersType,
  MetricTypes,
} from "dashboard/live_insights/main"
import { sumDataUpUntilCurrentTime } from "dashboard/live_insights/selectors"
import Constants from "dashboard/live_insights/helpers/constants"
import DaySoFarMetric from "dashboard/live_insights/components/DaySoFarMetric"
import styles from "./styles.module.scss"

const t = (key: string, ...args) => globalT(`js.dashboard.widgets.live_insights.day_so_far.${key}`, ...args)

type PropTypes = {
  averageOfDates: boolean,
  canSeeCosts: boolean,
  data: Array<RechartsDataType>,
  dataFilters: DataFiltersType,
  dateViewing: moment,
  graphEntries: LiveInsightsGraphEntriesType,
  metricType: MetricTypes,
  time: ?moment,
}

export default class DaySoFarContainer extends React.PureComponent<PropTypes> {
  render(): React.Element<"div"> {
    const { dateViewing } = this.props
    const wageCosts = {
      actual: sumDataUpUntilCurrentTime(
        this.props.graphEntries.staff_cost.actual.key,
        this.props.time,
        this.props.data
      ),
      expected: sumDataUpUntilCurrentTime(
        this.props.graphEntries.staff_cost.rostered.key,
        this.props.time,
        this.props.data
      ),
    }
    const wageCostsVariance = wageCosts.actual - wageCosts.expected
    const wageCostsColor = wageCostsVariance > 0 ? "danger" : "success"

    const workedHours = {
      actual:
        sumDataUpUntilCurrentTime(this.props.graphEntries.staff_count.actual.key, this.props.time, this.props.data) /
        (60 / Constants.GRAPH_INTERVAL),
      expected:
        sumDataUpUntilCurrentTime(this.props.graphEntries.staff_count.rostered.key, this.props.time, this.props.data) /
        (60 / Constants.GRAPH_INTERVAL),
    }
    const workedHoursVariance = workedHours.actual - workedHours.expected
    const workedHoursColor = workedHoursVariance > 0 ? "danger" : "success"

    const sales = {
      actual:
        sumDataUpUntilCurrentTime(this.props.graphEntries.sales.actual.key, this.props.time, this.props.data) || 0,
      expected:
        sumDataUpUntilCurrentTime(this.props.graphEntries.sales.rostered.key, this.props.time, this.props.data) || 0,
    }
    const salesVariance = sales.actual - sales.expected
    const salesColor = salesVariance < 0 ? "danger" : "success"

    const wagePercent = {
      actual: (wageCosts.actual / sales.actual) * 100,
      expected: (wageCosts.expected / sales.expected) * 100,
    }
    const wagePercentVariance = wagePercent.actual - wagePercent.expected
    const wagePercentColor = wagePercentVariance > 0 ? "danger" : "success"

    const splh = {
      actual: workedHours.actual !== 0 ? sales.actual / workedHours.actual : 0,
      expected: workedHours.expected !== 0 ? sales.expected / workedHours.expected : 0,
    }
    const splhVariance = splh.actual - splh.expected
    const splhColor = splhVariance < 0 ? "danger" : "success"

    const wageCostsProps = {
      mainLabel: t("wage_costs"),
      mainValue: wageCosts.actual,
      secondaryLabel: t("rostered"),
      secondaryTooltip: "",
      secondaryValue: wageCosts.expected,
      unitType: "currency",
      varianceColor: wageCostsColor,
      varianceLabel: t("variance"),
      varianceValue: wageCostsVariance,
    }
    const workedHoursProps = {
      mainLabel: t("worked_hours"),
      mainValue: workedHours.actual,
      secondaryLabel: t("rostered"),
      secondaryTooltip: "",
      secondaryValue: workedHours.expected,
      unitType: "none",
      varianceColor: workedHoursColor,
      varianceLabel: t("variance"),
      varianceValue: workedHoursVariance,
    }
    const salesProps = {
      mainLabel: t("sales"),
      mainValue: sales.actual,
      secondaryLabel: t("predicted"),
      secondaryTooltip: this.props.averageOfDates
        ? t("predicted_tooltip", { weeks: "3", day: moment(dateViewing).format("dddd") })
        : "",
      secondaryValue: sales.expected,
      unitType: "currency",
      varianceColor: salesColor,
      varianceLabel: t("variance"),
      varianceValue: salesVariance,
    }

    const wagePercentProps = {
      mainLabel: t("wage_percent"),
      mainValue: wagePercent.actual,
      secondaryLabel: t("predicted"),
      secondaryTooltip: t("wage_predicted_tooltip"),
      secondaryValue: wagePercent.expected,
      unitType: "percentage",
      varianceColor: wagePercentColor,
      varianceLabel: t("variance"),
      varianceValue: wagePercentVariance,
    }

    const splhProps = {
      mainLabel: t("splh"),
      mainValue: splh.actual,
      secondaryLabel: t("predicted"),
      secondaryTooltip: t("splh_predicted_tooltip"),
      secondaryValue: splh.expected,
      unitType: "currencyPerHour",
      varianceColor: splhColor,
      varianceLabel: t("variance"),
      varianceValue: splhVariance,
    }
    return (
      <div>
        <div className={styles.FilterOptionsLegendText}>
          <Text type="labelHeader">{t("title")}</Text>
        </div>
        <div className={styles.daySoFarMetricContainers}>
          {this.props.metricType === "wage_percentage" ? (
            <DaySoFarMetric {...wageCostsProps} />
          ) : (
            <DaySoFarMetric {...workedHoursProps} />
          )}
          <DaySoFarMetric {...salesProps} />
          {this.props.metricType === "wage_percentage" ? (
            <DaySoFarMetric {...wagePercentProps} />
          ) : (
            <DaySoFarMetric {...splhProps} />
          )}
        </div>
      </div>
    )
  }
}
