/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import cn from "classnames"

import Currency from "helpers/currency"
import { t as globalT } from "helpers/i18n"
import { AutoHoverBubble } from "components/Bubble"

import styles from "./styles.module.scss"

export default class Row extends React.PureComponent {
  calcDuration(duration) {
    const hours = Math.floor(duration.asHours()) || 0
    const minutes = Math.round(duration.minutes()) || 0

    return globalT("js.timesheets.duration.hours_minutes", { hours, minutes })
  }

  renderTime() {
    return !Number.isNaN(this.props.time) && this.props.time !== 0
      ? `(${this.calcDuration(moment.duration(Math.ceil(Math.abs(this.props.time))))})`
      : "--"
  }

  renderCost() {
    if (!this.props.cost) {
      return "--"
    } else if (this.props.isVariance) {
      return Currency.from(this.props.cost)
    } else {
      return Currency.from(Math.abs(this.props.cost))
    }
  }

  renderLabelForSplit() {
    const { isShiftNotRostered, isWageCost, splitByRosteredDays, isMultipleShift } = this.props
    let message = ""
    if (isWageCost && splitByRosteredDays) {
      if (isShiftNotRostered) {
        message = globalT("js.timesheets.salaried_split_cost.shift_not_rostered_label")
      } else if (isMultipleShift) {
        message = globalT("js.timesheets.salaried_split_cost.multiple_shift_label")
      } else {
        message = globalT("js.timesheets.salaried_split_cost.single_shift_label")
      }

      return (
        <AutoHoverBubble position="top" width="large">
          {message}
        </AutoHoverBubble>
      )
    }
  }

  render() {
    return (
      <div className={styles.Row}>
        <div
          className={cn(
            styles.title,
            this.props.lightText ? styles.fontLight : styles.fontHeavy,
            this.props.isWageCost ? styles.wageCost : null
          )}
        >
          {`${this.props.title}:`}

          <span className={cn(styles.tooltiptext)}>{`${this.props.title}:`}</span>
        </div>
        {this.props.showCosts ? (
          <div
            className={cn(
              styles.cost,
              this.props.lightText ? styles.fontLight : styles.fontHeavy,
              !Number.isNaN(this.props.cost) &&
                this.props.cost !== 0 && {
                  [styles[this.props.cost >= 0 ? "positive" : "negative"]]:
                    this.props.isVariance && !this.props.splitByRosteredDays,
                }
            )}
          >
            {this.renderCost()}
            {this.renderLabelForSplit()}
          </div>
        ) : null}
        <div
          className={cn(
            styles.time,
            this.props.lightText ? styles.fontLight : styles.fontHeavy,
            !Number.isNaN(this.props.time) &&
              this.props.time !== 0 && {
                [styles[this.props.time >= 0 ? "positive" : "negative"]]: this.props.isVariance,
              },
            this.props.showCosts ? null : styles.noCosts
          )}
        >
          {this.renderTime()}
        </div>
      </div>
    )
  }
}

Row.defaultProps = {
  isVariance: false,
  showCosts: false,
  cost: null,
  lightText: false,
  isWageCost: false,
  isShiftNotRostered: false,
  splitByRosteredDays: false,
}

Row.propTypes = {
  cost: PropTypes.number,
  isShiftNotRostered: PropTypes.bool,
  isVariance: PropTypes.bool,
  showCosts: PropTypes.bool,
  title: PropTypes.string.isRequired,
  time: PropTypes.number.isRequired,
  lightText: PropTypes.bool,
  isMultipleShift: PropTypes.bool.isRequired,
  isWageCost: PropTypes.bool,
  splitByRosteredDays: PropTypes.bool,
}
