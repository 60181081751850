// @flow

import * as React from "react"
import { noop } from "lodash"
import cn from "classnames"
import Text from "components/Text"
import Icon from "components/Icon"
import { type Props as DefaultOptionPT, DEFAULT_PROPS as DefaultOptionDP } from "components/DropdownList/DefaultOption"
import * as HelperFunc from "rosters/overview/helpers/functions"
import { handleOnClick, handleMouseEnter, handleMouseLeave } from "components/DropdownList/helpers/handlers"
import type { SelectEvent } from "components/Select"
import styles from "./styles.module.scss"

// note that app/assets/webpack/users/teams_and_permissions/Form.jsx
// (not Timesheets) also uses this - plz test before changing <3
type RequiredProps = {|
  label: string,
|}

type Props = {|
  ...RequiredProps,
  ...DefaultOptionPT,
  data: {|
    color: string,
    disabled?: boolean,
    includeTeamCode: boolean,
    location_short_code?: string,
    name?: string,
    team_short_code?: string,
  |},
|}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  ...DefaultOptionDP,
  data: { color: "transparent", includeTeamCode: false },
}

type State = {|
  isMouseOver: boolean,
|}

export default class TeamOption extends React.Component<Props, State> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  constructor(props: Props) {
    super(props)
    this.state = { isMouseOver: false }
  }

  handleOnClick: (event: SelectEvent) => void = handleOnClick.bind(this)
  handleMouseEnter: (e: SyntheticInputEvent<HTMLElement>) => void = handleMouseEnter.bind(this)
  handleMouseLeave: (e: SyntheticInputEvent<HTMLElement>) => void = handleMouseLeave.bind(this)

  handleOnClickWithDisabledCheck: (e: SelectEvent) => void = (e: SelectEvent) => {
    if (this.props.data.disabled) {
      return
    }
    this.handleOnClick(e)
  }

  render(): React.Element<"div"> {
    return (
      <div
        className={cn(styles.TeamOption, styles[this.props.size], {
          [styles.hovered]: this.props.hovered,
          [styles.disabled]: this.props.data.disabled,
          [styles.noOverflow]: this.props.noOverflow,
          [styles.selected]: this.props.selected,
        })}
        onClick={this.handleOnClickWithDisabledCheck}
        onKeyPress={noop}
        onMouseLeave={this.handleMouseLeave}
        onMouseMove={this.props.hovered ? noop : this.handleMouseEnter}
        ref={this.props.updateRef}
        role="button"
        tabIndex={-1}
      >
        <div
          className={cn(styles.swatch, { [styles.includeTeamCode]: this.props.data.team_short_code != null })}
          style={{ backgroundColor: this.props.data.color }}
        >
          {this.props.data.location_short_code && (
            <Text bold color={HelperFunc.getBestTextColor(this.props.data.color)} type="smallest">
              {this.props.data.location_short_code}{" "}
            </Text>
          )}
          {this.props.data.team_short_code && (
            <Text bold color={HelperFunc.getBestTextColor(this.props.data.color)} type="tiny">
              {this.props.data.team_short_code}{" "}
            </Text>
          )}
        </div>
        <div className={styles.teamLabel}>
          <Text align="left" color="light" type="tiny">
            {this.props.data.name && this.props.noOverflow ? this.props.data.name : this.props.label}
          </Text>
          {this.props.selected && (
            <div className={styles.selectedTickWrapper}>
              <Icon color="primary" size={this.props.size === "l" ? "s" : "xs"} type="done" />
            </div>
          )}
        </div>
      </div>
    )
  }
}
