// @flow
import * as React from "react"

type Props = {|
  children: React.Node,
  title: string,
|}

export default function Document({ title, children }: Props): React$Node {
  React.useEffect(() => {
    if (document.title !== title) {
      document.title = title
    }
  }, [title])

  return children
}
