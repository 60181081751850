// @flow
import chroma from "chroma-js"
import _ from "lodash"
// http://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
export const hexStringToRgb = (string: string): null | Array<number> => {
  const hex = string.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => r + r + g + g + b + b)
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  if (!result) {
    return null
  }

  return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
}

export const getBestTextColor: (bg_color: string) => "white" | "text" = _.memoize(
  (bg_color: string): "white" | "text" =>
    chroma.contrast(bg_color, "#ffffff") > chroma.contrast(bg_color, "#3C4754") ? "white" : "text"
)

export const whitenColor: (color: string) => string = _.memoize((color: string) => chroma.mix("#eee", color, 0.3).css())

export const brightenColor: (color: string) => string = _.memoize((color: string) =>
  chroma.mix("#fff", color, 0.3).css()
)

export const darkenColorIfTooLight: (color: string) => string = _.memoize((color: string) =>
  chroma.contrast(color, "white") <= 2 ? chroma(color).luminance(0.7).hex() : color
)
export const backgroundIsLight: (color: string) => boolean = _.memoize(
  (color: string) => chroma.contrast(color, "white") <= 2
)

export const textColor: (color: string) => string = _.memoize((color: string) => chroma(color).luminance(0.3).css())

export const darkerColor: (color: string) => string = _.memoize((color: string) => chroma(color).luminance(0.4).css())

export const lightColor: (color: string) => string = _.memoize((color: string) => chroma(color).luminance(0.7).css())

export const lighterColor: (color: string) => string = _.memoize((color: string) => chroma(color).luminance(0.8).css())

export const lightestColor: (color: string) => string = _.memoize((color: string) =>
  chroma(color).luminance(0.85).css()
)

export const hsvToRgb = (h: number, s: number, v: number): Array<number> => {
  h = h % 360
  s = Math.max(0, Math.min(s, 1))
  v = Math.max(0, Math.min(v, 1))

  const hI = parseInt(h / 60)
  const f = h / 60 - hI
  const p = v * (1 - s)
  const q = v * (1 - f * s)
  const t = v * (1 - (1 - f) * s)
  let r = 0
  let g = 0
  let b = 0

  switch (hI) {
    case 0:
      ;[r, g, b] = [v, t, p]
      break
    case 1:
      ;[r, g, b] = [q, v, p]
      break
    case 2:
      ;[r, g, b] = [p, v, t]
      break
    case 3:
      ;[r, g, b] = [p, q, v]
      break
    case 4:
      ;[r, g, b] = [t, p, v]
      break
    case 5:
      ;[r, g, b] = [v, p, q]
      break
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]
}

const standardiseColorToArr = (color) => {
  if (typeof color === "string") {
    return hexStringToRgb(color)
  }

  if (Array.isArray(color)) {
    return color
  }

  return [color.r, color.g, color.b]
}

type IsLightOrDarkInput = string | Array<number> | { r: number, g: number, b: number } // eslint-disable-line flowtype/sort-keys

// Takes a {r: rrr, g: ggg, b: bbb} object, [rrr, ggg, bbb] array, or '#rrggbb' string
export const isLight = (rgbRaw: IsLightOrDarkInput): boolean => {
  const rgb = standardiseColorToArr(rgbRaw)

  if (!rgb) {
    return false
  }

  const [r, g, b] = rgb

  // http://alienryderflex.com/hsp.html
  // http://www.nbdtech.com/Blog/archive/2008/04/27/Calculating-the-Perceived-Brightness-of-a-Color.aspx
  return Math.round(Math.sqrt(r * r * 0.299 + g * g * 0.587 + b * b * 0.114)) >= 130
}
export const ColorSystem = {
  primary_050: "#EDFAFF",
  primary_075: "rgb(221, 242, 250)",
  primary_100: "#CAEFFC",
  primary_200: "#A7E4FA",
  primary_300: "#84D7F5",
  primary_400: "#5EC5EB",
  primary_500: "#3FAFD7",
  primary_600: "#329DC7",
  primary_700: "#288BB5",
  primary_800: "#1F77A6",
  primary_900: "#186294",
  primary_accent_300: "#5ED6EB",
  primary_accent_700: "#228CC5",

  /* --------------------
    9 Shades of SUCCESS
  -------------------- */
  success_050: "#F9FFED",
  success_100: "#ECFCCA",
  success_200: "#E0FAA7",
  success_300: "#D1F584",
  success_400: "#BEEB5E",
  success_500: "#A6D63E",
  success_600: "#93C732",
  success_700: "#81B528",
  success_800: "#70A61F",
  success_900: "#569418",

  /* --------------------
    9 Shades of WARNING
  -------------------- */
  warning_050: "#FFF3E0",
  warning_100: "#FFDFB3",
  warning_200: "#FFCA80",
  warning_300: "#FFB54C",
  warning_400: "#FFA526",
  warning_500: "#FF9500",
  warning_600: "#FB8C00",
  warning_700: "#F57C00",
  warning_800: "#EF6C00",
  warning_900: "#E65100",

  /* --------------------
    9 Shades of DANGER
  -------------------- */
  danger_050: "#FFEBEB",
  danger_100: "#FCC8C7",
  danger_200: "#FAA6A5",
  danger_300: "#F58482",
  danger_400: "#EB5C59",
  danger_500: "#D63C3A",
  danger_600: "#C42D2D",
  danger_700: "#B02328",
  danger_800: "#9E1B24",
  danger_900: "#8A1323",

  /* -----------------
    11 Shades of GREY
  ----------------- */
  grey_000: "#FFFFFF",
  grey_025: "#F7F8FA",
  grey_050: "#F0F2F5",
  grey_075: "#E6E9ED",
  grey_100: "#DAE0E8",
  grey_200: "#CAD2DE",
  grey_300: "#BAC5D4",
  grey_400: "#A1B0C2",
  grey_500: "#8A9BAE",
  grey_600: "#708093",
  grey_700: "#536171",
  grey_800: "#3C4754",
  grey_900: "#222B35",

  /* ---------------------
    9 Shades of CHARCOAL
  --------------------- */
  charcoal_000: "#FFFFFF",
  charcoal_025: "#F7F8FA",
  charcoal_050: "#F0F2F5",
  charcoal_075: "#E6E9ED",
  charcoal_100: "#DAE0E8",
  charcoal_200: "#CAD2DE",
  charcoal_300: "#BAC5D4",
  charcoal_400: "#A1B0C2",
  charcoal_500: "#8A9BAE",
  charcoal_600: "#708093",
  charcoal_700: "#536171",
  charcoal_800: "#3C4754",
  charcoal_900: "#222B35",

  purple_500: "#5F3ED6",

  dominoes_blue_500: "#68bbde",
  dominoes_blue_600: "#4baed8",

  dominoes_red_500: "#ff5f4a",
  dominoes_red_600: "#ff3f26",

  /* ---------------------
    Shades of GOLD - https://www.rapidtables.com/web/color/Gold_Color.html
  --------------------- */
  gold_1: "#FFD700", // c_gold
}
export const C = {
  lightest_text: ColorSystem.grey_500,
  lighter_text: ColorSystem.grey_600,
  light_text: ColorSystem.grey_700,
  text: ColorSystem.grey_800,
  black: ColorSystem.grey_900,

  option_box: ColorSystem.grey_400,

  white: ColorSystem.grey_000,
  almostwhite: ColorSystem.grey_025,
  almostwhite_hover: ColorSystem.grey_075,
  offwhite: ColorSystem.grey_050,
  offwhite_hover: ColorSystem.grey_075,
  border_superlight: ColorSystem.grey_100,
  border_light: ColorSystem.grey_200,
  default_team_color: ColorSystem.grey_200,
  border: ColorSystem.grey_300,
  placeholder: ColorSystem.grey_300,
  disabled: ColorSystem.grey_300,
  lightest_blue: ColorSystem.charcoal_100,
  paleblue: ColorSystem.charcoal_100,
  paleblue_medium: ColorSystem.charcoal_200,
  paleblue_dark: ColorSystem.charcoal_400,
  light_charcoal: ColorSystem.charcoal_600,
  charcoal: ColorSystem.charcoal_700,
  dark_charcoal: ColorSystem.charcoal_800,
  modal_backdrop: ColorSystem.charcoal_900,
  almostblack: ColorSystem.charcoal_900,

  primary_superlight: ColorSystem.primary_050,
  primary_border_superlight: ColorSystem.primary_075,
  primary_border_light: ColorSystem.primary_100,
  primary_border: ColorSystem.primary_200,
  primary_hover: ColorSystem.primary_400,
  primary: ColorSystem.primary_500,
  primary_shadow: ColorSystem.primary_600,
  gradient_light: ColorSystem.primary_accent_300,
  gradient_dark: ColorSystem.primary_accent_700,

  success_faint: ColorSystem.success_050,
  success_superlight: ColorSystem.success_100,
  success_hover: ColorSystem.success_500,
  success: ColorSystem.success_600,
  success_shadow: ColorSystem.success_700,
  success_darkshadow: ColorSystem.success_800,
  success_superdarkshadow: ColorSystem.success_900,

  warning_superlight: ColorSystem.warning_050,
  warning_light: ColorSystem.warning_100,
  warning: ColorSystem.warning_600,
  warning_shadow: ColorSystem.warning_700,

  danger_superlight: ColorSystem.danger_050,
  danger_light: ColorSystem.danger_100,
  danger_bright: ColorSystem.danger_400,
  danger_hover: ColorSystem.danger_500,
  danger: ColorSystem.danger_600,
  danger_shadow: ColorSystem.danger_700,

  overnight: ColorSystem.primary_800,

  good: ColorSystem.success_700,
  bad: ColorSystem.danger_700,
  neutral: ColorSystem.grey_800,

  new_request: ColorSystem.purple_500,
  needs_approval: ColorSystem.primary_500,
  in_progress: ColorSystem.warning_400,
  gold: ColorSystem.gold_1,

  // We very rarely use yellow.
  yellow: ColorSystem.warning_400,

  dominoes_blue: ColorSystem.dominoes_blue_500,
  dominoes_blue_border: ColorSystem.dominoes_blue_600,

  dominoes_red: ColorSystem.dominoes_red_500,
  dominoes_red_border: ColorSystem.dominoes_red_600,

  confetti_red: ColorSystem.danger_600,
  confetti_blue: ColorSystem.primary_400,
  confetti_yellow: ColorSystem.warning_400,

  /* ------------------------------------------------
    Deprecated in favour of action color variables
  ------------------------------------------------ */
  grey: ColorSystem.grey_800,
}

// Takes a {r: rrr, g: ggg, b: bbb} object, [rrr, ggg, bbb] array, or '#rrggbb' string
export const isDark = (rgbRaw: IsLightOrDarkInput): boolean => !isLight(rgbRaw)
