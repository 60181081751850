/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

/* eslint react/no-array-index-key: off */

import * as React from "react"
import PropTypes from "prop-types"
import { memoize } from "underscore" // eslint-disable-line underscore-to-lodash/prefer-import-lodash
import { t } from "helpers/i18n"

import * as Timesheet from "timesheets/models/timesheet"
import Button from "components/Button"

import AllowanceForm from "../AllowanceForm"
import styles from "./styles.module.scss"

export default class Body extends React.PureComponent {
  constructor(props) {
    super(props)
    this.addClearHandler = memoize(this.addClearHandler.bind(this))
    this.addNameChangeHandler = memoize(this.addNameChangeHandler.bind(this))
    this.addValueChangeHandler = memoize(this.addValueChangeHandler.bind(this))
    this.mapOptions = this.mapOptions.bind(this)
    this.NO_OPTIONS = [
      {
        label: t("js.timesheets.allowance_modal.allowances_empty"),
        options: [],
      },
    ]

    this.state = {
      options: this.mapOptions(props.allowances, props.forms),
    }
  }

  UNSAFE_componentWillReceiveProps({ allowances, forms }) {
    if (allowances !== this.props.allowances || forms !== this.props.forms) {
      this.setState({ options: this.mapOptions(allowances, forms) })
    }
  }

  mapOptions(allowances, forms) {
    const options = allowances
      .toJS()
      .filter(
        (allowance) =>
          !(allowance.automated || forms.find((shiftAllowance) => shiftAllowance.get("allowance_id") === allowance.id))
      )
      .map((allowance) => ({
        label: allowance.name,
        value: allowance.id,
      }))

    return options.length > 0 ? options : this.NO_OPTIONS
  }

  addClearHandler(index) {
    return () => this.props.clearForm(index)
  }

  addNameChangeHandler(index, event) {
    return (event) => this.props.updateFormAllowance(index, event.target.value)
  }

  addValueChangeHandler(index, event) {
    return (event) => this.props.updateFormValue(index, event.target.value)
  }

  handleAddFromKeyDown(event) {
    if (event.keyCode === 13) {
      // ENTER
      this.props.addForm()
    }
  }

  render() {
    return (
      <div className={styles.scrollWrapper}>
        <div className={styles.scrollFade} />
        <div className={styles.scrollBox}>
          <div className={styles.Body}>
            <div className={styles.header}>
              <div className={styles.nameTitle}>{t("js.timesheets.allowance_modal.column_titles.allowance")}</div>
              <div className={styles.unitTitle}>{t("js.timesheets.allowance_modal.column_titles.units")}</div>
              <div className={styles.costTitle}>{t("js.timesheets.allowance_modal.column_titles.cost")}</div>
            </div>
            <div className={styles.forms}>
              {this.props.forms.toJS().map((form, index) => (
                <AllowanceForm
                  form={form}
                  key={index}
                  onClear={this.addClearHandler(index)}
                  onNameChange={this.addNameChangeHandler(index)}
                  onValueChange={this.addValueChangeHandler(index)}
                  options={this.state.options}
                  tabIndex={index}
                  timesheet={this.props.timesheet}
                />
              ))}
            </div>
            <div className={styles.addFormButton}>
              {this.state.options !== this.NO_OPTIONS && !Timesheet.isExported(this.props.timesheet) ? (
                <Button
                  iconLeft="add"
                  label={t("js.timesheets.allowance_modal.add_allowance")}
                  onClick={this.props.addForm}
                  onKeyDown={this.handleAddFromKeyDown}
                  type="action"
                  weight="secondary"
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Body.propTypes = {
  allowances: PropTypes.object.isRequired,
  addForm: PropTypes.func.isRequired,
  clearForm: PropTypes.func.isRequired,
  forms: PropTypes.object.isRequired,
  updateFormAllowance: PropTypes.func.isRequired,
  updateFormValue: PropTypes.func.isRequired,
  timesheet: PropTypes.object.isRequired,
}
