/* @flow */

import { connect } from "react-redux"
import { createSelector, createStructuredSelector } from "reselect"
import { flowRight } from "lodash"

import Actions from "timesheets/logic/actions"

import { type Props } from "./"

const selectModalId = (store) => store.application.get("platformModalShiftId")

const selectShifts = (store) => store.shifts

const selectShift = createSelector(selectModalId, selectShifts, (id, shifts) => (id ? shifts.get(id) : undefined))

const getPropsFromStore = createStructuredSelector({
  shift: selectShift,
  platform_model_id: (store) => store.currentOrganisation.getIn(["platform_model_id"]),
})

const bindActionsToDispatch = (dispatch) => ({ onClose: flowRight(dispatch, Actions.appClosePlatformModal) })

// Please remove this if you work on this file
// eslint-disable-next-line flowtype/no-weak-types
export default (connect<Props, $Exact<{}>, _, _, _, _>(getPropsFromStore, bindActionsToDispatch): any)
