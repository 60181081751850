/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import { t } from "helpers/i18n"
import * as Shift from "timesheets/models/shift"
import * as Timesheet from "timesheets/models/timesheet"

import ControlButton from "timesheets/components/ControlButton"

export default class CopyButton extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleCopyShift = this.handleCopyShift.bind(this)
  }

  handleCopyShift() {
    this.props.actions.shiftsCopy({ shift: this.props.shift })
  }

  isDisabled() {
    return (
      Timesheet.isExported(this.props.timesheet) ||
      Shift.hasMockedId(this.props.shift.get("id")) ||
      Shift.limberWorker(this.props.shift)
    )
  }

  render() {
    return (
      <ControlButton
        disabled={this.isDisabled()}
        icon="add"
        onClick={this.handleCopyShift}
        tip={t("js.timesheets.shift_card.controls.new_shift_same_day")}
        tipPosition={this.props.tipPosition}
      />
    )
  }
}

CopyButton.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  shift: PropTypes.object.isRequired,
  timesheet: PropTypes.object.isRequired,
  tipPosition: PropTypes.string.isRequired,
}
