// @flow
import * as React from "react"
import absurd from "helpers/absurd"
import * as M from "helpers/marshall"
import * as Field from "../../models/field"
import BoolInput from "./boolInput"
import DateInput from "./dateInput"
import FloatInput from "./floatInput"
import TextInput from "./textInput"
import LongTextInput from "./longTextInput"
import CurrencyInput from "./currencyInput"

type Ev = SyntheticInputEvent<HTMLInputElement>

type Props = {|
  +field: Field.Schema,
  +onEdit: (Ev) => mixed,
  +onSave: () => mixed,
  +value: mixed,
|}

export default function FieldInput({ field, onEdit, onSave, value }: Props): React.Node {
  switch (field.type) {
    case "boolean":
      return (
        <BoolInput
          disabled={!field.editable}
          htmlName={field.key}
          onEdit={onEdit}
          onSave={onSave}
          value={M.maybeBoolean(value) || false}
        />
      )
    case "date":
      return (
        <DateInput
          disabled={!field.editable}
          htmlName={field.key}
          onEdit={onEdit}
          onSave={onSave}
          value={M.maybeString(value)}
        />
      )
    case "float":
      return (
        <FloatInput
          disabled={!field.editable}
          htmlName={field.key}
          onEdit={onEdit}
          onSave={onSave}
          value={M.maybeNumber(value)}
        />
      )
    case "text":
      return (
        <TextInput
          disabled={!field.editable}
          htmlName={field.key}
          onEdit={onEdit}
          onSave={onSave}
          value={M.maybeString(value) || ""}
        />
      )
    case "linked_field":
      return null
    case "long_text":
      return (
        <LongTextInput
          disabled={!field.editable}
          htmlName={field.key}
          onEdit={onEdit}
          onSave={onSave}
          value={M.maybeString(value) || ""}
        />
      )
    case "currency":
      return (
        <CurrencyInput
          disabled={!field.editable}
          htmlName={field.key}
          onEdit={onEdit}
          onSave={onSave}
          value={M.maybeNumber(value)}
        />
      )
    default:
      // $FlowFixMe type checking this route is a waste of time
      return absurd(field.type)
  }
}
