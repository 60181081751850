// @flow

import * as React from "react"
import { noop } from "lodash"
import cn from "classnames"
import Input, { type Props as InputProps, DEFAULT_PROPS as InputDefaultProps } from "components/Input/Input"
import styles from "./styles.module.scss"
import BaseButton from "./BaseButton"

type RequiredProps = {||}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  ...InputDefaultProps,
  controlled: false,
  edit_icon: "edit",
  onChange: noop,
  onBlur: noop,
  formatter: (v) => String(v),
}

type Props = {|
  ...RequiredProps,
  ...InputProps,
  controlled: boolean,
  edit_icon: string,
  formatter: (value: string | number) => string, // (value: string | number) => string
|}

type State = {|
  focused: boolean,
  tempValue: ?string,
|}

export default class ButtonInput extends React.PureComponent<Props, State> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  constructor(props: Props) {
    super(props)
    this.state = { focused: false, tempValue: null }
  }

  inputRef: ?HTMLInputElement = null

  handleButtonClick: () => void = () => {
    this.setState({ focused: true }, () => {
      this.inputRef && this.inputRef.focus()
    })
  }

  handleOnBlur: (e: SyntheticFocusEvent<HTMLInputElement>) => void = (e: SyntheticFocusEvent<HTMLInputElement>) => {
    this.setState({ focused: false, tempValue: null })
    this.props.onBlur(e)
    if (!this.props.controlled) {
      // $FlowFixMe fake event
      this.props.onChange({ target: { value: this.state.tempValue } })
    }
  }

  handleUpdateRef: (node: ?HTMLInputElement) => void = (node: ?HTMLInputElement) => {
    this.inputRef = node
  }

  handleOnChange: (e: SyntheticInputEvent<HTMLInputElement>) => mixed = (e: SyntheticInputEvent<HTMLInputElement>) => {
    if (this.props.controlled) {
      return this.props.onChange(e)
    } else {
      this.setState({ tempValue: e.target.value })
    }
  }

  render(): React.Element<"div"> {
    // eslint-disable-next-line no-unused-vars
    const { controlled, edit_icon, formatter, ...inputProps } = this.props
    return (
      <div className={styles.buttonInputWrapper}>
        <BaseButton
          edit_icon={this.props.edit_icon}
          focused={this.state.focused}
          formatter={this.props.formatter}
          handleClick={this.handleButtonClick}
          value={this.props.value}
        />
        <div className={cn(styles.inputWrappeer, { [styles.inputIsHidden]: !this.state.focused })}>
          <Input
            {...inputProps}
            onBlur={this.handleOnBlur}
            onChange={this.handleOnChange}
            updateRef={this.handleUpdateRef}
            value={
              this.props.controlled
                ? this.props.value
                : this.state.tempValue == null
                ? this.props.value
                : this.state.tempValue
            }
          />
        </div>
      </div>
    )
  }
}
