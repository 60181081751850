// @flow
import * as React from "react"
import MultiSelect from "components/Select/MultiSelect"
import * as I18n from "helpers/i18n"
import * as Assoc from "../../models/association"
import * as Model from "../../models/model"
import * as OptionList from "../../models/optionList"
import * as Record from "../../models/record"
import styles from "./styles.module.scss"

type Ev = SyntheticInputEvent<HTMLElement>

type Props = {|
  +assoc: Assoc.Schema,
  +associatedModel: Model.Schema,
  +onEdit: (Ev) => mixed,
  +onSave: () => mixed,
  +options: OptionList.OptionListType,
  +record: Record.Schema,
  +value: Array<number | string>,
|}

const t = (k: string, opts: {}): string => I18n.t(`js.platform.records.form.${k}`, opts)

export default function ManyToMany({
  assoc,
  associatedModel,
  onEdit,
  onSave,
  options,
  record,
  value,
}: Props): React.Element<"div"> {
  const allOptions = React.useMemo(
    () =>
      [
        {
          label: t("defaults.no_association", { type: assoc.name }),
          value: [],
        },
      ].concat(options),
    [assoc.name, options]
  )

  return (
    <div className={styles.fullWidth}>
      <MultiSelect
        disabled={!assoc.editable}
        htmlName={assoc.key}
        onBlur={onSave}
        // $FlowFixMe All of these are sharing the same event / function even though theyre different inputs
        onChange={onEdit}
        options={allOptions}
        value={value}
      />
    </div>
  )
}
