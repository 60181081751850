/**
 * @flow
 */
import * as React from "react"
import moment from "moment"
import cn from "classnames"
import { noop, range } from "lodash"
import { DayPickerSingleDateController } from "react-dates"
import ReactDOM from "react-dom"
import Icon from "components/Icon"
import Select from "components/Select"
import * as I18n from "helpers/i18n"
import styles from "./styles.module.scss"

type ValidYears = {| from: number, to: number |}
type SelectOption = $ReadOnly<{| label: string, value: number |}>

type Props = {
  disabled: ?boolean,
  enableMonthAndYearPicker?: boolean,
  i18nFormat: string,
  minDate?: moment,
  onBlur: () => void,
  onChange: (date: moment) => void,
  onFocus: () => void,
  validYears?: ValidYears,
  value: moment,
}

type State = { focus: boolean, left: string, top: string }

const t = (k: string): string => I18n.t(`js.generic_components.date_picker.${k}`)

type RenderMonthElementProps = {|
  month: moment,
  onMonthSelect: (currentMonth: moment, value: number) => void,
  onYearSelect: (currentMonth: moment, value: number) => void,
|}

export default class DatePicker extends React.PureComponent<Props, State> {
  static defaultProps: {|
    enableMonthAndYearPicker: boolean,
    minDate: void,
    onBlur: (...args: $ReadOnlyArray<mixed>) => void,
    onFocus: (...args: $ReadOnlyArray<mixed>) => void,
    validYears: void,
  |} = {
    enableMonthAndYearPicker: false,
    onBlur: noop,
    onFocus: noop,
    minDate: undefined,
    validYears: undefined,
  }

  state: State = {
    focus: false,
    top: "",
    left: "",
  }

  datePickerParent: {| current: null | HTMLElement |} = React.createRef<HTMLElement>()

  handleBlur: () => void = () => {
    this.setState({ focus: false })
    this.props.onBlur()
  }

  handleDateChange: (date: moment$Moment) => void = (date: moment) => {
    this.setState({ focus: false })
    this.props.onChange(date)
    this.props.onBlur()
  }

  getControllerStyles: () => void = () => {
    const { current } = this.datePickerParent

    if (current == null) {
      return this.setState({ top: "", left: "" })
    }
    const bounds = current.getBoundingClientRect()
    const parentPageOffset = window.pageYOffset + bounds.top
    // positioning datePicker under button
    const parentHeight = current.offsetHeight
    const coords = {
      top: [parentPageOffset + parentHeight + 2, "px"].join(""),
      left: [bounds.left - 1, "px"].join(""),
    }
    this.setState({ top: coords.top, left: coords.left })
  }

  // get parent
  handleFocus: () => void = () => {
    if (!this.props.disabled) {
      this.getControllerStyles()
      window.addEventListener("scroll", this.getControllerStyles, true)
      this.setState({ focus: true })
      this.props.onFocus()
    }
  }

  handleKeyPress: (event: SyntheticKeyboardEvent<HTMLDivElement>) => void = (
    event: SyntheticKeyboardEvent<HTMLDivElement>
  ) => {
    if (this.props.disabled) {
      return
    }
    if (
      event.key === "Enter" ||
      event.key === " " // spacebar
    ) {
      this.setState({ focus: true })
    }
  }

  handleOutsideRange: (
    day?: moment$Moment
  ) => ((...args: $ReadOnlyArray<mixed>) => void) | ((day: moment$Moment) => boolean) = (day?: moment) => {
    const { minDate } = this.props
    return minDate ? (day: moment) => moment(minDate).isAfter(day) : noop
  }

  setInitialVisibleMonth: () => moment$Moment = () => {
    const { value } = this.props
    return value.isValid() ? value : moment()
  }

  monthsForSelectElement: () => Array<SelectOption> = (): Array<SelectOption> =>
    moment.months().map((label, value) => ({ label, value }))

  yearsForSelectElement: (validYears: ValidYears) => Array<SelectOption> = (
    validYears: ValidYears
  ): Array<SelectOption> =>
    range(validYears.from, validYears.to).map((year) => ({ label: year.toString(), value: year }))

  shouldRenderMonthElement: () => void | boolean = () => this.props.validYears && this.props.enableMonthAndYearPicker

  renderMonthElement: (RenderMonthElementProps) => null | React.Element<"div"> = ({
    month,
    onMonthSelect,
    onYearSelect,
  }: RenderMonthElementProps) => {
    const validYears = this.props.validYears
    if (!validYears) {
      return null
    }

    return (
      <div className={styles.monthElementWrapper}>
        <div className={styles.selectWrapper}>
          <Select
            onChange={(e) => {
              onMonthSelect(month, e.target.value)
            }}
            options={this.monthsForSelectElement()}
            value={month.month()}
          />
        </div>
        <div className={styles.selectWrapper}>
          <Select
            onChange={(e) => {
              onYearSelect(month, e.target.value)
            }}
            options={this.yearsForSelectElement(validYears)}
            value={month.year()}
          />
        </div>
      </div>
    )
  }

  render(): React.Element<"div"> {
    const { body } = document
    return (
      <div
        className={cn(styles.datePicker, this.props.disabled ? styles.disabled : null)}
        onClick={this.handleFocus}
        onFocus={this.handleFocus}
        onKeyUp={this.handleKeyPress}
        ref={this.datePickerParent}
        role="button"
        tabIndex={this.props.disabled ? -1 : 0}
      >
        <Icon color="primary" size="s" type="calendar-today" />
        <div className={cn(styles.date, this.props.disabled ? styles.disabled : null)}>
          {this.props.value.isValid() ? I18n.l(this.props.i18nFormat, this.props.value.toDate()) : t("no_date")}
        </div>
        {this.state.focus &&
          body !== null &&
          ReactDOM.createPortal(
            <div className={styles.dateController} style={this.state}>
              <DayPickerSingleDateController
                focused
                hideKeyboardShortcutsPanel
                initialVisibleMonth={this.setInitialVisibleMonth}
                isOutsideRange={this.handleOutsideRange()}
                onDateChange={this.handleDateChange}
                onOutsideClick={this.handleBlur}
                renderMonthElement={this.shouldRenderMonthElement() ? this.renderMonthElement : null}
              />
            </div>,
            body
          )}
      </div>
    )
  }
}
