/**
 * @flow
 */
import * as React from "react"
import cn from "classnames"
import _ from "lodash"
import Icon from "components/Icon"
import styles from "./styles.module.scss"

type RequiredProps = {|
  onClick: (applied: boolean, name?: string) => void,
|}
type Props = {|
  ...RequiredProps,
  applied: boolean,
  color: ?"blue" | "grey", // more colours are defined in CSS - uncomment if you add them here
  disabled: boolean,
  id?: string,
  // Purpose of id: When you use the toggle you should ideally accopany it with a <label> with the
  // htmlFor attribute matching the id. This links the label and the toggle so that clicking the label will toggle the toggle.
  // See https://github.com/TandaHQ/payaus/pull/15615
  locked: boolean,
  name?: string,
  size: "s" | "m",
|}

export default class ToggleStandard extends React.PureComponent<Props> {
  static defaultProps: $Diff<Props, RequiredProps> = {
    applied: false,
    name: undefined,
    color: null,
    locked: false,
    disabled: false,
    size: "m",
    id: "",
  }

  handleClick: (name: ?string) => void = (name: ?string) => {
    // Previously not all instances of this component used the name property
    // To prevent regressions, we handle whether or not name is passed as a prop
    if (!name) {
      this.props.onClick(!this.props.applied)
    } else {
      this.props.onClick(!this.props.applied, name)
    }
  }

  render(): React.Node {
    return (
      <React.Fragment>
        <button
          aria-pressed={this.props.applied ? "true" : "false"}
          className={cn(styles.ToggleStandard, styles[this.props.size], {
            [styles.applied]: this.props.applied,
            [styles[this.props.disabled ? "grey" : this.props.color || "blue"]]: this.props.color != null,
          })}
          disabled={this.props.disabled || this.props.locked}
          id={this.props.id}
          onClick={() => this.handleClick(this.props.name)}
          onKeyUp={_.noop}
        />
        {this.props.locked && (
          <div className={styles.lockWrapper}>
            <Icon color="lightest" size="s" type="lock" />
          </div>
        )}
      </React.Fragment>
    )
  }
}
