// @flow

import Input from "./Input"
import NumberInput from "./NumberInput"
import LargeInput from "./LargeInput"
import NumberMaxMinInput from "./NumberMaxMinInput"

export type { InputColor } from "./BaseInput"
// BaseInput is not exported as it should not be used
// directly, but abstracted with a higher level input.

export { Input, NumberInput, LargeInput, NumberMaxMinInput }

export default Input
