/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import { propOfType } from "helpers/prop-types"
import styles from "./styles.module.scss"

export default class PageToolbar extends React.PureComponent {
  render() {
    const left = []
    const right = []
    React.Children.forEach(this.props.children, (el) => {
      switch (el.type.displayName) {
        case ToolsLeft.displayName:
          left.push(el)
          break
        case ToolsRight.displayName:
          right.push(el)
          break
        default:
          break
      }
    })
    if (right.length > 1) {
      throw new Error("`PageToolbar` cannot contain more than 1 `ToolsRight`")
    }
    if (left.length > 1) {
      throw new Error("`PageToolbar` cannot contain more than 1 `ToolsLeft`")
    }
    if (right.length + left.length < 1) {
      throw new Error("`PageToolbar` must contain at least 1 `ToolsRight` or `ToolsLeft`")
    }

    return (
      <div className={styles.pageToolbar}>
        <div className={styles.toolsLeft}>{left}</div>
        <div className={styles.toolsRight}>{right}</div>
      </div>
    )
  }
}

PageToolbar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([propOfType(ToolsRight), propOfType(ToolsLeft)])),
    propOfType(ToolsRight),
    propOfType(ToolsLeft),
  ]).isRequired,
}

/* ====== TOOLBAR CHILDREN ======
 * This is done in this way because ToolsLeft, and ToolsRight need
 * to be separately defined and named functions.
 * If you modify the toolbar children, please check they are used correctly because
 * prop-type validation had to be disabled in the render.
 */

/* eslint-disable react/prop-types */
const toolbarChildRender = (props) => <div className={styles.toolbarChild}>{props.children}</div>
/* eslint-enable react/prop-types */

const toolbarChildPropTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element, PropTypes.string]).isRequired,
}

export function ToolsLeft(props) {
  return toolbarChildRender(props)
}
export function ToolsRight(props) {
  return toolbarChildRender(props)
}

// ====== SETUP TOOLBAR CHILDREN ======

const setupToolbarChild = ([toolbarChildFunction, name]) => {
  toolbarChildFunction.displayName = name
  toolbarChildFunction.propTypes = toolbarChildPropTypes
}

;[
  [ToolsLeft, "ToolsLeft"],
  [ToolsRight, "ToolsRight"],
].forEach(setupToolbarChild)
