/* @flow */
import * as React from "react"
import _ from "lodash"
import { t as globalT } from "helpers/i18n"
import Select from "components/Select"
import Text from "components/Text"
import Constants from "dashboard/live_insights/helpers/constants"
import Icon from "components/Icon"
import type { FilterOptionsObjectType } from "dashboard/live_insights/main"
import LineDisplayContainer from "dashboard/live_insights/components/LineDisplayContainer"
import styles from "./styles.module.scss"

const t = (key: string) => globalT(`js.dashboard.widgets.live_insights.chart_legend.${key}`)

type PropType = {
  canSeeCosts: boolean,
  filterOptions: Array<FilterOptionsObjectType>,
  handleFilterSelectedChange: (event: { +target: { value: "staff_count" | "staff_cost" } }) => void,
  handleToggleRosterLine: () => () => void,
  toggleRosteredLine: boolean,
  value: "staff_count" | "staff_cost",
}

export default class TrackerChartController extends React.PureComponent<PropType> {
  render(): React.Element<"div"> {
    return (
      this.props.filterOptions &&
      this.props.handleFilterSelectedChange && (
        <div className={styles.FilterOptionsContainer}>
          <div className={styles.FilterOptionsLegendText}>
            <Text type="labelHeader">{t("chart_legend_title")}</Text>
          </div>
          {this.props.canSeeCosts && (
            <Select
              icon={this.props.value === "staff_count" ? "solidLine" : "solidBar"}
              iconCustomColor={Constants.LIVE_WAGE_GRAPH_ENTRIES[this.props.value].actual.color}
              newStyle
              onChange={this.props.handleFilterSelectedChange}
              options={this.props.filterOptions}
              placeholder={Constants.LIVE_WAGE_GRAPH_ENTRIES[this.props.value].actual.name}
              tabIndex={0}
              value={this.props.value}
            />
          )}
          {!this.props.canSeeCosts && (
            <LineDisplayContainer
              chartType={Constants.LIVE_WAGE_GRAPH_ENTRIES[this.props.value].actual.chartType}
              color={Constants.LIVE_WAGE_GRAPH_ENTRIES[this.props.value].actual.color}
              paddingLeft={this.props.value === "staff_cost" ? "0.065rem" : "0.125rem"}
              text={t("staff_count")}
            />
          )}
          <div
            className={styles.RosterLineToggle}
            onClick={this.props.handleToggleRosterLine()}
            onKeyPress={_.noop}
            role="button"
            style={this.props.value === "staff_cost" ? { paddingLeft: "0.065rem" } : { paddingLeft: "0.125rem" }}
            tabIndex={-1}
          >
            <LineDisplayContainer
              chartType={Constants.LIVE_WAGE_GRAPH_ENTRIES[this.props.value].rostered.chartType}
              color={Constants.LIVE_WAGE_GRAPH_ENTRIES[this.props.value].rostered.color}
              opacity={this.props.toggleRosteredLine ? "1" : "0.7"}
              padding={""}
              text={t("rostered")}
            />
            {this.props.toggleRosteredLine ? <Icon color="grey" type={"visibility-off"} /> : <a>{t("show")}</a>}
          </div>
        </div>
      )
    )
  }
}
