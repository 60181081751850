/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import { fromJS } from "immutable"
import { Status } from "./helpers"

export const build = (json) => fromJS(Object.assign({}, TIMESHEET_DEFAULTS, json))

const TIMESHEET_DEFAULTS = {
  id: null,
  status: Status.Pending,
}
