// @flow

import * as React from "react"
import moment from "moment"
import * as I18n from "helpers/i18n"
import type { UserContract } from "user_contracts/types"
import type { UserSignedOnboardingForm } from "client/onboarding/types"
import * as Routes from "helpers/routes"
import styles from "./styles.module.scss"

export type VersionType = {
  audit_trail_name: string | null,
  created_at: string,
  event: string,
  id: number,
  impersonation: boolean,
  item_id: number,
  item_type: string,
  object_changes: string,
  save_reason: string | null,
  whodunnit: string | null,
}

type SortedAndPartitionedDataType = {
  userContracts: {
    pending: Array<UserContract>,
    signed: Array<UserContract>,
    withdrawn: Array<UserContract>,
  },
  userSignedOnboardingForms: {
    pending: Array<UserSignedOnboardingForm>,
    signed: Array<UserSignedOnboardingForm>,
    withdrawn: Array<UserSignedOnboardingForm>,
  },
}

type Props = {
  userContracts?: Array<UserContract>,
  userSignedOnboardingForms?: Array<UserSignedOnboardingForm>,
  versions: Array<VersionType>,
}

VersionList.defaultProps = {
  userContracts: null,
  userSignedOnboardingForms: null,
}

type ChangeValue = string | null | boolean

const versionsT = (key: string) => I18n.t(`js.roles.versions.${key}`)
const userContractsVersionsT = (key: string, opts: ?{ ... }) =>
  I18n.t(`js.employment_contracts.user_contract_versions.${key}`, opts)
const userContractDocumentsT = (key: string, opts: ?{ ... }) =>
  I18n.t(`js.employment_contracts.user_contract_documents.${key}`, opts)

const formatTime = (time: string) => moment(time, "YYYY-MM-DD HH:mm:ss").format("hh:mm:ss A, DD MMMM YYYY")

const EMPTY_SORTED_AND_PARTITIONED: SortedAndPartitionedDataType = {
  userContracts: {
    pending: [],
    signed: [],
    withdrawn: [],
  },
  userSignedOnboardingForms: {
    pending: [],
    signed: [],
    withdrawn: [],
  },
}

export default function VersionList(props: Props): React.Node {
  const renderNone = <span>{userContractsVersionsT("none")}</span>

  const renderChangeText = (text: ChangeValue, isTime: boolean) => {
    if (text === null) {
      return "null"
    }
    if (text === false) {
      return "false"
    }
    if (text === true) {
      return "true"
    }
    return isTime ? formatTime(text.toString()) : text
  }

  const renderChangeTable = (changes: string) => {
    const parsedChanges = JSON.parse(changes)
    return Object.keys(parsedChanges).map((change: string) => {
      const isTime = ["updated_at", "created_at"].includes(change)
      const previous = parsedChanges[change][0]
      const changedTo = parsedChanges[change][1]
      return (
        <tr key={`ct-row-${change}`}>
          <td className="field-heading">{change}</td>
          <td className="from">{renderChangeText(previous, isTime)}</td>
          <td className="to" style={{ width: "100%" }}>
            {renderChangeText(changedTo, isTime)}
          </td>
        </tr>
      )
    })
  }

  const associatedFormsAndContracts: Map<number, SortedAndPartitionedDataType> = React.useMemo(() => {
    const userContracts = props.userContracts || Array<UserContract>([])
    const userSignedOnboardingForms = props.userSignedOnboardingForms || Array<UserSignedOnboardingForm>([])

    const associated = new Map<number, SortedAndPartitionedDataType>()
    props.versions.forEach((version: VersionType, index: number) => {
      const nextVersion = index + 1 < props.versions.length ? props.versions[index + 1] : null

      const contractChunk = userContracts.filter(({ id, created_at }) => {
        const afterVersion = moment(created_at).isSameOrAfter(moment(version.created_at))
        const beforeNextVersion = nextVersion == null || moment(created_at).isBefore(moment(nextVersion.created_at))
        return afterVersion && beforeNextVersion
      })
      const signedContractChunk = contractChunk.filter(({ signed_at }) => signed_at)
      const withdrawnContractChunk = contractChunk.filter(({ withdrawn_at }) => withdrawn_at)
      const pendingContractChunk = contractChunk.filter(({ signed_at, withdrawn_at }) => !signed_at && !withdrawn_at)

      const formChunk = userSignedOnboardingForms.filter(({ id, created_at }) => {
        const afterVersion = moment(created_at).isSameOrAfter(moment(version.created_at))
        const beforeNextVersion = nextVersion == null || moment(created_at).isBefore(moment(nextVersion.created_at))
        return afterVersion && beforeNextVersion
      })
      const signedFormChunk = formChunk.filter(({ signed_at }) => signed_at)
      const withdrawnFormChunk = formChunk.filter(({ withdrawn_at }) => withdrawn_at)
      const pendingFormChunk = formChunk.filter(({ signed_at, withdrawn_at }) => !signed_at && !withdrawn_at)

      associated.set(version.id, {
        userContracts: {
          pending: pendingContractChunk,
          signed: signedContractChunk,
          withdrawn: withdrawnContractChunk,
        },
        userSignedOnboardingForms: {
          pending: pendingFormChunk,
          signed: signedFormChunk,
          withdrawn: withdrawnFormChunk,
        },
      })
    })
    return associated
  }, [props.versions, props.userContracts, props.userSignedOnboardingForms])

  const renderUserContracts = (version_id: number) => {
    const getUrl = (userContract: UserContract): string => Routes.user_contract_path({ id: String(userContract.id) })
    const renderName = (userContract: UserContract): string => `${userContract.name || ""}`

    const versionData: SortedAndPartitionedDataType =
      associatedFormsAndContracts.get(version_id) || EMPTY_SORTED_AND_PARTITIONED
    const { signed, withdrawn, pending } = versionData.userContracts

    return (
      <div>
        <h4>{userContractsVersionsT("subheading_signed")}</h4>
        {signed.length
          ? signed.map((userContract: UserContract) => (
              <span key={userContract.id}>
                <a href={getUrl(userContract)} rel="noopener noreferrer" target="_blank">
                  {`${renderName(userContract)} - ${userContractsVersionsT("signed_on", {
                    signed_on: userContract.signed_at,
                  })}`}
                </a>
                <br />
              </span>
            ))
          : renderNone}
        <br />
        <br />
        <h4>{userContractsVersionsT("subheading_withdrawn")}</h4>
        {withdrawn.length
          ? withdrawn.map((userContract: UserContract) => (
              <span key={userContract.id}>
                <a href={getUrl(userContract)} rel="noopener noreferrer" target="_blank">
                  {`${renderName(userContract)} - ${userContractsVersionsT("withdrawn_at", {
                    withdrawn_at: userContract.withdrawn_at,
                  })}`}
                </a>
                <br />
              </span>
            ))
          : renderNone}
        <br />
        <br />
        <h4>{userContractsVersionsT("subheading_pending")}</h4>
        {pending.length
          ? pending.map((userContract: UserContract) => (
              <span key={userContract.id}>
                <a href={getUrl(userContract)} rel="noopener noreferrer" target="_blank">
                  {renderName(userContract)}
                </a>
                <br />
              </span>
            ))
          : renderNone}
      </div>
    )
  }

  const renderUserSignedDocuments = (version_id: number) => {
    const getUrl = (userSignedOnboardingForm: UserSignedOnboardingForm): string =>
      Routes.user_signed_onboarding_forms_path({
        user_id: String(userSignedOnboardingForm.user_id || ""),
        onboarding_form_id: String(userSignedOnboardingForm.onboarding_form?.id || ""),
      })
    const renderName = (userSignedOnboardingForm: UserSignedOnboardingForm): string =>
      `${userSignedOnboardingForm.onboarding_form?.name || ""}`

    const versionData: SortedAndPartitionedDataType =
      associatedFormsAndContracts.get(version_id) || EMPTY_SORTED_AND_PARTITIONED
    const { pending, signed, withdrawn } = versionData.userSignedOnboardingForms

    return (
      <div>
        <h4>{userContractDocumentsT("subheading_signed")}</h4>
        {signed.length > 0
          ? signed.map((userSignedOnboardingForm: UserSignedOnboardingForm) => (
              <span key={userSignedOnboardingForm.id}>
                <a href={getUrl(userSignedOnboardingForm)} rel="noopener noreferrer" target="_blank">
                  {`${renderName(userSignedOnboardingForm)} - ${userContractDocumentsT("signed_on", {
                    signed_on: userSignedOnboardingForm.signed_at,
                  })}`}
                </a>
                <br />
              </span>
            ))
          : renderNone}
        <br />
        <br />
        <h4>{userContractDocumentsT("subheading_withdrawn")}</h4>
        {withdrawn.length > 0
          ? withdrawn.map((userSignedOnboardingForm: UserSignedOnboardingForm) => (
              <span key={userSignedOnboardingForm.id}>
                <a href={getUrl(userSignedOnboardingForm)} rel="noopener noreferrer" target="_blank">
                  {`${renderName(userSignedOnboardingForm)} - ${userContractDocumentsT("withdrawn_at", {
                    withdrawn_at: userSignedOnboardingForm.withdrawn_at,
                  })}`}
                </a>
                <br />
              </span>
            ))
          : renderNone}
        <br />
        <br />
        <h4>{userContractDocumentsT("subheading_pending")}</h4>
        {pending.length > 0
          ? pending.map((userSignedOnboardingForm: UserSignedOnboardingForm) => (
              <div key={userSignedOnboardingForm.id}>
                <a href={getUrl(userSignedOnboardingForm)} rel="noopener noreferrer" target="_blank">
                  {renderName(userSignedOnboardingForm)}
                </a>
                <br />
              </div>
            ))
          : renderNone}
      </div>
    )
  }

  return (
    <table className="table table-bordered table-condensed VersionsTable">
      <thead>
        <tr>
          <th>{versionsT("headings.action")}</th>
          <th>{versionsT("headings.user")}</th>
          <th>{versionsT("headings.time")}</th>
          <th>{versionsT("headings.reason")}</th>
          <th>{versionsT("headings.item_id")}</th>
          <th>{versionsT("headings.changes")}</th>
          {props.userContracts && <th>{userContractsVersionsT("heading")}</th>}
          {props.userSignedOnboardingForms && <th>{userContractDocumentsT("heading")}</th>}
        </tr>
      </thead>
      <tbody>
        {props.versions.map((version, index) => (
          <tr key={version.id}>
            <td> {version.event} </td>
            <td> {version.audit_trail_name} </td>
            <td> {formatTime(version.created_at)} </td>
            <td> {version.save_reason || versionsT("state.no_reason")} </td>
            <td> {version.item_id} </td>
            <td className="changes-log-table-td">
              <table className={`${styles.changeTable} changes-log-table VersionsTable`}>
                {renderChangeTable(version.object_changes)}
              </table>
            </td>
            {props.userContracts && (
              <td className={styles.userContractsChangeTable}>{renderUserContracts(version.id)}</td>
            )}
            {props.userSignedOnboardingForms && (
              <td className={styles.userContractsChangeTable}> {renderUserSignedDocuments(version.id)} </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
