// @flow
import * as React from "react"
import absurd from "helpers/absurd"
import * as M from "helpers/marshall"
import * as Routes from "helpers/routes"
import * as Assoc from "../../models/association"
import * as Model from "../../models/model"
import * as OptionList from "../../models/optionList"
import * as Record from "../../models/record"
import * as ReqCache from "../../cache"
import ManyToMany from "./ManyToMany"
import HasOne from "./HasOne"

type Ev = SyntheticInputEvent<HTMLElement>

type Props = {|
  +assoc: Assoc.Schema,
  +loading: boolean,
  +model: Model.Schema,
  +onEdit: (Ev) => mixed,
  +onSave: () => mixed,
  +record: Record.Schema,
  +subject: Model.Schema,
  +value: mixed,
|}

// we need this because [] !== [] in JS
const EMPTY_ARRAY = []

export default function PickAssocInput(props: Props): React.Node {
  const path = React.useMemo(() => {
    const throughAssoc = props.model.associations.find((a) => a.id === props.assoc.throughId)
    const throughValue = throughAssoc && props.record[throughAssoc.key]

    return Routes.options_platform_model_records_path(props.assoc.subjectId, {
      format: "json",
      query:
        throughAssoc != null
          ? {
              [throughAssoc.key]: throughAssoc.native ? { native: throughValue } : { record: throughValue },
            }
          : null,
    })
  }, [props.assoc, props.model, props.record])

  const options =
    ReqCache.useResource<OptionList.OptionListType>(
      path,
      (data) => M.withDefault([], () => OptionList.marshal(data)),
      ReqCache.MINUTES_5
    ) || []

  switch (props.assoc.type) {
    case "has_one":
      return (
        <HasOne
          assoc={props.assoc}
          associatedModel={props.subject}
          onEdit={props.onEdit}
          onSave={props.onSave}
          options={options}
          record={props.record}
          value={props.value != null ? M.numberOrString(props.value).toString() : null}
        />
      )
    case "many_to_many":
      return (
        <ManyToMany
          assoc={props.assoc}
          associatedModel={props.subject}
          onEdit={props.onEdit}
          onSave={props.onSave}
          options={options}
          record={props.record}
          value={
            props.value != null
              ? M.array(props.value)
                  .map(M.numberOrString)
                  .map((n) => n.toString())
              : EMPTY_ARRAY
          }
        />
      )
    default:
      // $FlowFixMe type checking this as a valid route is a waste of time
      return absurd(props.assoc.type)
  }
}
