/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"

import { t as globalT } from "helpers/i18n"
import * as TimeFormatter from "helpers/time_formatter"
import * as Shift from "timesheets/models/shift"

import Icon from "components/Icon"
import { Input } from "components/Input"

import TimeOption from "../../../TimeOption"
import styles from "./styles.module.scss"

const t = (key, opts) => globalT(`js.timesheets.shift_card.time_form.break_time_input.${key}`, opts)

export default class BreakTimeInput extends React.PureComponent {
  constructor(props) {
    super(props)

    const value = TimeFormatter.shiftTimeInput(props.shiftBreak.get(props.field), props.currentUser)

    this.translationField = props.fieldForTranslation || props.field

    this.state = {
      cachedValue: value,
      currentValue: value,
      isOvernight: Shift.isOvernight(props.shiftBreak.get(props.field), props.date),
    }
  }

  UNSAFE_componentWillReceiveProps = ({ currentUser, field, shiftBreak }) => {
    if (shiftBreak !== this.props.shiftBreak) {
      this.setState({ isOvernight: Shift.isOvernight(shiftBreak.get(field), this.props.date) })
    }

    if (shiftBreak.get(field).toString() !== this.props.shiftBreak.get(this.props.field).toString()) {
      const newValue = TimeFormatter.shiftTimeInput(shiftBreak.get(field), currentUser)
      this.setState({ cachedValue: newValue, currentValue: newValue })
    }
  }

  handleBlur = () => {
    if (this.props.disabled) {
      return
    }

    if (this.state.currentValue !== this.state.cachedValue) {
      this.conductTimeUpdate(this.state.currentValue)
    } else {
      this.setState({ currentValue: this.state.cachedValue })
    }
  }

  handleChange = (event) => {
    if (this.props.disabled) {
      return
    }

    this.setState({ currentValue: event.target.value })
  }

  handleFocus = () => {
    this.input.select()
  }

  handleOptionClick = (event) => {
    this.conductTimeUpdate(event.target.value)
  }

  conductTimeUpdate = (value) => {
    if (this.props.disabled) {
      return
    }

    this.props.actions.shiftsBreakConductTimeUpdate({
      shiftBreak: this.props.shiftBreak,
      field: this.props.field,
      value,
    })
  }

  renderColor = () => {
    if (this.props.showError && !this.props.disabled) {
      return "error"
    }

    if (!this.props.disabled && this.state.isOvernight) {
      return "overnight"
    }
  }

  renderTipText = () => {
    if (this.props.disabled) {
      return null
    }

    if (this.props.showError && !this.props.disabled) {
      return globalT("js.timesheets.shift_card.inputs.required_field", {
        field: globalT(`js.timesheets.shift_card.inputs.${this.translationField}`),
      })
    }

    if (this.state.isOvernight) {
      return globalT("js.timesheets.shift_card.inputs.overnight_time")
    }
  }

  placeholder = () => (this.props.isAutoBreak ? t(`placeholder.auto_break`) : t(`placeholder.${this.props.field}`))

  setInputRef = (el) => {
    this.input = el
  }

  render() {
    return (
      <div className={styles.BreakTimeInput}>
        <Input
          color={this.renderColor()}
          disabled={this.props.isAutoBreak || this.props.disabled}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          placeholder={this.placeholder()}
          textAlign="center"
          tipText={this.renderTipText()}
          updateRef={this.setInputRef}
          value={this.state.currentValue}
        />
        {this.state.isOvernight && (
          <span className={styles.overnightIcon}>
            <Icon size="xs" type="brightness-3" />
          </span>
        )}
        <div className={styles.options}>
          {this.props.clocked.isValid() ? (
            <TimeOption
              currentUser={this.props.currentUser}
              disabled={this.props.disabled}
              onClick={this.handleOptionClick}
              time={this.props.clocked}
              type="clocked"
            />
          ) : null}
          {this.props.rostered.isValid() ? (
            <TimeOption
              currentUser={this.props.currentUser}
              disabled={this.props.disabled}
              onClick={this.handleOptionClick}
              time={this.props.rostered}
              type="rostered"
            />
          ) : null}
        </div>
      </div>
    )
  }
}

BreakTimeInput.defaultProps = { fieldForTranslation: null }

BreakTimeInput.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  clocked: PropTypes.object.isRequired,
  rostered: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  showError: PropTypes.bool.isRequired,
  field: PropTypes.string.isRequired,
  isAutoBreak: PropTypes.bool.isRequired,
  shiftBreak: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired, // YYYY-MM-DD
  fieldForTranslation: PropTypes.string,
}
