/* @flow */

import * as React from "react"
import styles from "./styles.module.scss"

type RequiredProps = {|
  titleText: string,
|}
type Props = {|
  ...RequiredProps,
  subtitleText: string,
|}

export default function Header(props: Props): React.Element<"div"> {
  return (
    <div className={styles.header}>
      <h3 className={styles.title}>{props.titleText}</h3>
      {props.subtitleText ? <h4 className={styles.subtitle}>{props.subtitleText}</h4> : null}
    </div>
  )
}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  subtitleText: "",
}

Header.defaultProps = DEFAULT_PROPS
