/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import { defer, compose } from "underscore" // eslint-disable-line underscore-to-lodash/prefer-import-lodash

// Builds a function returning a promise.
// The wrapped function is deferred, and the promise resolves
// after it is called.
const promiser =
  (fn) =>
  (...args) =>
    new Promise((resolve) => defer(compose(resolve, fn), args))

// Run a function in batches, with each batch pushed to the next
// event loop. Useful for insertion of bulk DOM nodes.
// Returns a promise which resolves when all batches are processed.
export const inBatches = {
  of: (batch_size) => (fn) => (arr) => {
    const promises = []
    const first_batch = arr.splice(arr, batch_size)

    fn(first_batch) // Run the first batch syncronously

    while (arr.length) {
      promises.push(promiser(fn)(arr.splice(arr, batch_size)))
    }

    return Promise.all(promises)
  },
}

export const GUID = {
  // See http://stackoverflow.com/a/2117523/5709433 for explanation
  new: function () {
    const d = Date.now()
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      const r = (d + Math.random() * 16) % 16 | 0 // eslint-disable-line no-bitwise
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16) // eslint-disable-line no-bitwise
    })
  },
}
