// @flow
import Fuse, { type FuseResult } from "fuse.js"
import _ from "lodash"
import { type Option } from "components/Select"
/**
 * takes an array of options|groups and filters them if the group or option label
 * includes the filter.
 */
export type FilterOptionFunction = (
  options: $ReadOnlyArray<Option>,
  filter: string,
  callback?: (newOptions: Array<Option>) => mixed
) => $ReadOnlyArray<Option>

export const filterOptions: FilterOptionFunction = (
  options: $ReadOnlyArray<Option>,
  filter: string
): $ReadOnlyArray<Option> => {
  if (filter === "" || filter == null) {
    return options
  }
  // through self testing, this threshold seems the most reasonable
  const THRESHOLD = 0.36
  const fuse = new Fuse(options, {
    keys: ["label"],
    shouldSort: true,
    includeScore: true,
    threshold: THRESHOLD,
  })
  // Checks the top level for any group or options that match the filter
  const matchedGroupOrOptions = fuse.search(filter)
  // Goes one layer deeper and checks whether any groups have any child options that match.
  // If they do, include that group in the results.
  const groupsWithMatchedOptions: Array<FuseResult<Option>> = options
    .map((group) => {
      if (!Array.isArray(group.options)) {
        return { item: { ...group, options: [] }, score: undefined, refIndex: null }
      }

      const fuse = new Fuse(group.options, {
        keys: ["label"],
        shouldSort: true,
        includeScore: true,
        threshold: THRESHOLD,
      })

      const optionsWithScore = fuse.search(filter)

      return {
        item: { ...group, options: optionsWithScore.map((opt) => opt.item) },
        refIndex: null,
        score: _.min(optionsWithScore.map((o) => o.score)),
      }
    })
    .filter((group) => group.item.options?.length !== 0)

  const uniqGroupsOrOptions = _.uniqBy(
    [...groupsWithMatchedOptions, ...matchedGroupOrOptions],
    (opt) => String(opt.item.label) + "~" + String(opt.item.value)
  )
  return _.sortBy(uniqGroupsOrOptions, (opt) => opt.score).map<Option, _>((opt) => opt.item)
}

export function filterObjects<ObjectToSearch>(
  objects: Array<ObjectToSearch>,
  keys: Array<string>,
  filter: ?string
): Array<ObjectToSearch> {
  if (filter === "" || filter == null) {
    return objects
  }
  // through self testing, this threshold seems the most reasonable
  const THRESHOLD = 0.36
  const fuse = new Fuse(objects, {
    keys: keys,
    shouldSort: true,
    includeScore: true,
    threshold: THRESHOLD,
  })
  return fuse.search(filter).map((r) => r.item)
}

export default filterOptions

export function sortOptions<Opt: Option>(options: $ReadOnlyArray<Opt>): $ReadOnlyArray<Opt> {
  const mutableOptions: Array<Opt> = [...options]
  const allTeamsOption = mutableOptions.shift()

  mutableOptions.sort((a, b) => {
    if (a.label < b.label) {
      return -1
    }
    if (a.label > b.label) {
      return 1
    }
    return 0
  })
  if (allTeamsOption) {
    mutableOptions.unshift(allTeamsOption)
  }
  return [...mutableOptions]
}

export const sortOptionsWithDefaults = (
  options: $ReadOnlyArray<Option>,
  defaults: number = 0
): $ReadOnlyArray<Option> => {
  const mutableOptions: Array<Option> = [...options]
  const defaultOptions: Array<Option> = _.range(0, defaults).map((n) => mutableOptions.shift())

  mutableOptions.sort((a, b) => {
    if (a.label < b.label) {
      return -1
    }
    if (a.label > b.label) {
      return 1
    }
    return 0
  })

  defaultOptions.reverse().forEach((option) => {
    mutableOptions.unshift(option)
  })

  return [...mutableOptions]
}
