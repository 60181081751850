// @flow
import * as React from "react"
import { noop } from "lodash"
import * as State from "../models/state"
import * as Cache from "../cache"
import reducer from "./reducer"
import type { Action } from "./action"

type Store = [State.Schema, (Action) => void]

const Context: React.Context<Store> = React.createContext([State.create(), noop])

type Props = {|
  +children: React.Node,
  +state: State.Schema,
|}

export function Provider({ children, state }: Props): React.Node {
  return (
    <Cache.Store>
      <Context.Provider value={React.useReducer(reducer, state)}>{children}</Context.Provider>
    </Cache.Store>
  )
}

export function useContext(): Store {
  return React.useContext(Context)
}
