/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import Actions from "../actions"

// Attempt to split a leave request into its fallback leave type.
// Does nothing if
//   - The leave balance associated with the request is not negative OR
//   - There is no leave balance associated with the request OR
//   - leaveRequest.fallback_leave_type is nil
export const splitLeave =
  ({ leaveRequest }) =>
  (dispatch, getState) =>
    // Optimistically remove the leave request from the list. Since it's probably gonna get
    // split and therefore does not belong there anymore
    dispatch(Actions.requestSplitLeave({ leaveRequest }))

export default splitLeave
