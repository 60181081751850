// @flow

import * as React from "react"
import { useCan, Provider } from "helpers/gql/client"

type Props = {|
  +action: string,
  +children: React.Node,
  +id?: ?(number | string),
  +subject: string,
|}

function ConditionalEvaluator(props: Props) {
  const can = useCan(props.action, props.subject, props.id)

  if (can) {
    return props.children
  } else {
    return null
  }
}

// sugar to let you use `useCan` style stuff from non-functional components
function Can(props: Props): React.Node {
  return (
    <Provider>
      <ConditionalEvaluator {...props} />
    </Provider>
  )
}

Can.defaultProps = { id: null }

export { Can }
