// @flow
import * as React from "react"

import Bubble from "components/Bubble"
import Loader from "../Loader"

const buttonTypes = {
  theme: "btn-theme",
  action: "btn-action",
  danger: "btn-danger",
  ghost: "btn-ghost",
  magic: "btn-magic",
  warning: "btn-warning",
}

const buttonWeights = {
  primary: "primary",
  secondary: "secondary",
}

const buttonToSpinnerSizeMap = {
  xs: "xs",
  sm: "s",
  md: "m",
  lg: "l",
}

export type Props = {|
  disabled?: boolean,
  htmlType: "button" | "reset" | "submit",
  iconLeft: string,
  iconRight: string,
  id: string,
  label: string,
  link: string,
  loading: boolean,
  newTab: boolean,
  onClick: (e: SyntheticMouseEvent<HTMLButtonElement>) => mixed,
  size: "xs" | "sm" | "md" | "lg",
  testId: ?string,
  tooltip: {
    content: string,
    position: "top" | "bottom" | "left" | "right" | "auto",
    width: "auto" | "medium" | "large" | "max-content",
  } | null,
  type: "theme" | "action" | "ghost" | "magic" | "danger" | "warning",
  weight: "primary" | "secondary",
|}

const Button = (props: Props): React$Element<"a"> | React$Element<"button"> => {
  const typeClass = buttonTypes[props.type]
  const weightClass = buttonWeights[props.weight]
  const buttonType = props.type === "ghost" || props.type === "magic" ? typeClass : `${typeClass}-${weightClass}`

  const [hovered, setHovered] = React.useState(false)

  const handleMouseOver = (): void => {
    setHovered(true)
  }

  const handleMouseLeave = (): void => {
    setHovered(false)
  }

  const getTip = (): React.Node => {
    if (props.tooltip === null || !props.tooltip.content) {
      return null
    }
    return (
      <Bubble hovered={hovered} position={props.tooltip.position} width={props.tooltip.width}>
        {props.tooltip.content}
      </Bubble>
    )
  }

  const getLoader = (): React.Element<"div"> => (
    <div className="loader-parent">
      <Loader color={`${props.type}-${props.weight}`} size={buttonToSpinnerSizeMap[props.size]} />
    </div>
  )

  if (props.link) {
    return (
      <a
        aria-disabled={props.disabled || props.loading}
        className={`btn-${props.size} ${buttonType}`}
        data-testid={props.testId}
        disabled={props.disabled || props.loading}
        href={props.link}
        onClick={props.onClick}
        onFocus={() => {}}
        onMouseLeave={handleMouseLeave}
        onMouseOver={handleMouseOver}
        tabIndex="0"
        target={props.newTab ? "_blank" : ""}
      >
        {props.iconLeft && <i className={`mi mi-${props.iconLeft}`} />}
        {props.tooltip && props.tooltip.content && getTip()}
        {props.label}
        {props.loading && getLoader()}
        {props.iconRight && <i className={`mi mi-${props.iconRight}`} />}
      </a>
    )
  }
  return (
    <button
      aria-disabled={props.disabled || props.loading}
      className={`btn-${props.size} ${buttonType}`}
      data-testid={props.testId}
      disabled={props.disabled || props.loading}
      id={props.id}
      onClick={props.onClick}
      onFocus={() => {}}
      onMouseLeave={handleMouseLeave}
      onMouseOver={handleMouseOver}
      type={props.htmlType}
    >
      {props.iconLeft && <i className={`mi mi-${props.iconLeft}`} />}
      {props.tooltip && getTip()}
      {props.label}
      {props.loading && getLoader()}
      {props.iconRight && <i className={`mi mi-${props.iconRight}`} />}
    </button>
  )
}

Button.defaultProps = {
  disabled: false,
  type: "theme",
  weight: "primary",
  size: "md",
  iconLeft: "",
  label: "",
  iconRight: "",
  id: "",
  link: "",
  loading: false,
  onClick: () => {},
  htmlType: "button",
  testId: null,
  tooltip: null,
  newTab: false,
}

export default Button
