// @flow
import * as React from "react"

type DimensionsHook = {|
  +height: number,
  +width: number,
|}

const getDimensions = (): DimensionsHook => ({
  height: window.innerHeight,
  width: window.innerWidth,
})

export default function useDimensions(): DimensionsHook {
  const [screen, setScreen] = React.useState(() => getDimensions())

  const onResize = React.useCallback(() => {
    setScreen(getDimensions)
  }, [])

  React.useLayoutEffect(() => {
    window.addEventListener("resize", onResize, { passive: true })
    return () => {
      window.removeEventListener("resize", onResize, { passive: true })
    }
  }, [onResize])

  return screen
}
