/* @flow */
import _ from "lodash"

import chroma from "chroma-js"
import moment from "moment"
import * as DemandDataHelpers from "rosters/WebpackRosters/DemandData/helpers"
import type { BusinessHoursType } from "../types"

const addLeadingZero: (time: string) => string = (time: string) => (time.length === 1 ? "0" + time : time)
const correctTimeFormat: (time: string) => string = (time: string) => {
  const hrMin = time.split(":")
  return addLeadingZero(hrMin[0]) + ":" + addLeadingZero(hrMin[1])
}

export const formatToUsersTime: (time: string) => string = (time) => {
  const timeParts = time.split(":")
  const hour = Number(timeParts[0])
  const min = Number(timeParts[1])
  return window.time_formatter.rosters_time(moment().hours(hour).minutes(min))
}
const formatDuration: (time: moment$MomentDuration) => string = (time: moment$MomentDuration) =>
  correctTimeFormat(String(Math.floor(time.asHours())) + ":" + String(time.minutes()))

export const userEnteredTimeToMinutes = (time: ?string): ?number => {
  if (time == null) {
    return null
  }
  const time_formats = ["HH:mm", "hh:mmA", "hh:mm a"]
  if (moment(time, time_formats).isValid()) {
    return moment.duration(moment(time, time_formats).format("HH:mm")).asMinutes()
  } else {
    return null
  }
}

export const minutesToTime = (minutes: number): string => {
  const min = minutes < 0 ? 0 : minutes
  return formatDuration(moment.duration(min, "minutes"))
}
export const minutesToUsersTime: (minutes: number) => string = _.memoize((minutes: number): string => {
  const mins = minutes < 0 ? 0 : minutes
  const min = mins % 60
  const hours = Math.floor(mins / 60)
  return window.time_formatter.rosters_time(moment().hours(hours).minutes(min))
})
export const minutesToMoment = (minutes: number): moment => {
  const dur = moment.duration(minutes, "minutes")
  return moment().hours(dur.hours()).minutes(dur.minutes())
}

export const addMinutesToTime: (time: string, minutes: number) => string = (time, minutes) =>
  formatDuration(moment.duration(time, "HH:mm").add(minutes, "minutes"))

export const merge_and_add_stats = (
  a: { [minute: string]: number },
  b: { [minute: string]: number }
): { [minute: string]: number } => {
  if (!(a && b)) {
    return a || b
  }
  const new_obj = {}
  const objs = [a, b]
  objs.forEach((obj) => {
    _.toPairs(obj).forEach(([k, v]) => {
      new_obj[k] = (new_obj[k] || 0) + obj[k]
    })
  })
  return new_obj
}

// Adjusts buisness hours so they include open and closed times
export const include_open_and_close_in_bh = (
  bhs: Array<BusinessHoursType>,
  time_to_open: ?number,
  time_to_close: ?number
): Array<BusinessHoursType> => {
  if (time_to_open == null || time_to_close == null) {
    return bhs
  }
  if (time_to_open === 0 && time_to_close === 0) {
    return bhs
  }
  const clone: Array<BusinessHoursType> = bhs.map((bh) => ({ ...bh }))
  const earliest_bh: BusinessHoursType = _.sortBy(clone, (bh) => bh.start)[0]
  const new_start = earliest_bh.start - time_to_open
  earliest_bh.start = new_start < 0 ? 0 : new_start
  const latest_bh: BusinessHoursType = _.sortBy(clone, (bh) => -1 * bh.finish)[0]
  const new_finish = latest_bh.finish + time_to_close
  latest_bh.finish = new_finish < 0 ? 0 : new_finish
  const grouped: { [id: string]: BusinessHoursType } = _.mapValues(
    _.groupBy(bhs, (bh) => bh.id),
    (bh) => bh[0]
  )
  // This will replace the old bh with the adjusted bh
  const new_grouped: { [id: string]: BusinessHoursType } = {
    ...grouped,
    [earliest_bh.id]: earliest_bh,
    [latest_bh.id]: latest_bh,
  }
  return _.values(new_grouped)
}

export const time_inside_bh = (bhs: Array<BusinessHoursType>, min: number): boolean =>
  _.some(bhs.map((b) => b.start <= min && min < b.finish))

export const getBestTextColor: (bg_color: string) => "white" | "light" = _.memoize(
  (bg_color: string): "white" | "light" =>
    chroma.contrast(bg_color, "#ffffff") > chroma.contrast(bg_color, "#536171") ? "white" : "light"
)

export const getBorderColor: (color: string, dep_bg: string) => string = _.memoize(
  (color: string, dep_bg: string): string =>
    chroma.contrast("#ffffff", color) > 1.3 ? chroma(color).darken(0.3).css() : chroma(dep_bg).luminance(0.8).css(),
  (color: string, dep_bg: string) => String(color) + "~" + String(dep_bg)
)

const UNDER_STAFFED_COLOR = "#ef4747"
const OVER_STAFFED_COLOR = "#68bbde"

export const getDiffColor: (diff: number, diff_percent: number) => string = _.memoize(
  (diff: number, diff_percent: number): string =>
    diff < 0
      ? chroma.mix("#fff", UNDER_STAFFED_COLOR, diff_percent).hex("rgb")
      : diff > 0
      ? chroma.mix("#fff", OVER_STAFFED_COLOR, diff_percent).hex("rgb")
      : "#fff",
  (diff: number, diff_percent: number) => String(diff) + "~" + String(diff_percent)
)

export const sum_ds_stats_for_time_period = DemandDataHelpers.sum_ds_stats_for_time_period
