// @flow
import * as React from "react"
import Loader from "components/Loader"
import SuccessTick from "components/SuccessTick"
import * as I18n from "helpers/i18n"
import styles from "./styles.module.scss"

type Props = {|
  +synced: boolean,
|}

const t = (key: string): string => I18n.t(`js.generic_components.sync_status.${key}`)

export default function SyncStatus(props: Props): React.Element<"div"> {
  return (
    <div className={styles.syncStatus}>
      {props.synced ? <SuccessTick size="xs" /> : <Loader size="m" />}
      <div className={styles.message}>{t(props.synced.toString())}</div>
    </div>
  )
}
