/**
 * @flow
 */
import * as React from "react"
import * as Routes from "helpers/routes"
import ModalView from "components/Modal"
import SafeTransition from "timesheets/components/SafeTransition"
import * as C from "rosters/WebpackRosters/consts"
import type { Defaults, Time } from "../types"
import styles from "./styles.module.scss"

type Props = {|
  +defaults: Defaults,
  +onCancel: () => void,
  +onSuccess: () => void,
  +open: boolean,
|}

type State = {|
  iframeHeight: string,
|}

export default class Modal extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      iframeHeight: "auto",
    }
  }

  componentDidMount() {
    window.addEventListener("message", this.handleMessage)
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleMessage)
  }

  // eslint-disable-next-line flowtype/no-weak-types
  handleMessage: (event: { data: any }) => void = (event: { data: any }) => {
    if (event.data.height) {
      this.setState({ iframeHeight: `${event.data.height}px` })
    }
    if (event.data.dismissIframe) {
      this.props.onSuccess()
    }
  }

  src(): string {
    if (this.props.defaults.leaveRequestId) {
      return Routes.edit_iframe_leave_requests_path({
        leave_request_id: this.props.defaults.leaveRequestId,
      })
    } else {
      const formatTime = (time: Time) => `${time.hour}:${time.minute}`

      return Routes.new_iframe_leave_requests_path({
        user_id: this.props.defaults.userId,
        start: this.props.defaults.startDate ? this.props.defaults.startDate.format(C.DATE_FMT) : "",
        finish: this.props.defaults.endDate ? this.props.defaults.endDate.format(C.DATE_FMT) : "",
        start_time: this.props.defaults.startTime ? formatTime(this.props.defaults.startTime) : "",
        finish_time: this.props.defaults.endTime ? formatTime(this.props.defaults.endTime) : "",
        duration: this.props.defaults.startTime && this.props.defaults.endTime ? "part_day" : "all_day",
        hours: this.props.defaults.leaveLength,
      })
    }
  }

  render(): React.Node {
    return (
      <ModalView fixedMaxHeight onExit={this.props.onCancel} open={this.props.open} size="xl" yOverflow>
        <SafeTransition
          className={styles.body}
          component="div"
          style={{ height: this.state.iframeHeight }}
          transitionAppear
          transitionAppearTimeout={150}
          transitionEnterTimeout={300}
          transitionLeaveTimeout={150}
          transitionName={STATE_TRANSITION}
        >
          <iframe
            height={this.state.iframeHeight}
            src={this.src()}
            style={{ border: "0", maxHeight: "100vh" }}
            title={"Leave form"}
            width="100%"
          />
        </SafeTransition>
      </ModalView>
    )
  }
}

const STATE_TRANSITION = {
  enterActive: styles.enterActive,
  enter: styles.enter,
  appearActive: styles.appearActive,
  appear: styles.appear,
  leaveActive: styles.leaveActive,
  leave: styles.leave,
}
