// @flow

import * as React from "react"
import { noop } from "lodash"
import { CSSTransitionGroup } from "react-transition-group-legacy"
import AccordionBody from "components/Accordion/AccordionBody"
import StandardHeader from "components/Accordion/StandardHeader"
import styles from "../styles.module.scss"

const transitionStyles = {
  enter: styles.bodyEnter,
  enterActive: styles.bodyEnterActive,
  leave: styles.bodyLeave,
  leaveActive: styles.bodyLeaveActive,
}

type RequiredProps = {|
  children: React.Node,
  title: string,
|}

type Props = {|
  ...RequiredProps,
  onClick: () => mixed,
  open: boolean,
  selection: ?string,
|}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  open: false,
  onClick: noop,
  selection: null,
}

export default class StandardAccordion extends React.PureComponent<Props> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  getBody(): null | React.Node {
    if (!this.props.open) {
      return null
    }

    return <AccordionBody>{this.props.children}</AccordionBody>
  }

  render(): React.Element<"div"> {
    return (
      <div className={styles.standardAccordion}>
        <StandardHeader
          onClick={this.props.onClick}
          open={this.props.open}
          selection={this.props.selection}
          title={this.props.title}
        />
        <CSSTransitionGroup transitionEnterTimeout={350} transitionLeaveTimeout={300} transitionName={transitionStyles}>
          {this.getBody()}
        </CSSTransitionGroup>
      </div>
    )
  }
}

StandardAccordion.displayName = "StandardAccordion"

StandardAccordion.defaultProps = DEFAULT_PROPS
