/* @flow */
import * as React from "react"
import moment from "moment"
import Text from "components/Text"
import { t as globalT } from "helpers/i18n"
import type { RechartsDataType } from "dashboard/live_insights/main"
import Constants from "dashboard/live_insights/helpers/constants"
import { sumDataUpUntilCurrentTime } from "dashboard/live_insights/selectors"

import DaySoFarMetric from "dashboard/live_insights/components/DaySoFarMetric"
import styles from "./styles.module.scss"

const t = (key: string, ...args) => globalT(`js.dashboard.widgets.live_insights.day_so_far.${key}`, ...args)

type PropTypes = {
  canSeeCosts: boolean,
  data: Array<RechartsDataType>,
  time: ?moment,
}

export default class DaySoFarContainer extends React.PureComponent<PropTypes> {
  render(): React.Element<"div"> {
    const wageCosts = {
      actual: sumDataUpUntilCurrentTime(
        Constants.LIVE_WAGE_GRAPH_ENTRIES.staff_cost.actual.key,
        this.props.time,
        this.props.data
      ),
      expected: sumDataUpUntilCurrentTime(
        Constants.LIVE_WAGE_GRAPH_ENTRIES.staff_cost.rostered.key,
        this.props.time,
        this.props.data
      ),
    }
    const wageCostsVariance = wageCosts.actual - wageCosts.expected
    const wageCostsColor = wageCostsVariance > 0 ? "danger" : "success"

    const workedHours = {
      actual:
        sumDataUpUntilCurrentTime(
          Constants.LIVE_WAGE_GRAPH_ENTRIES.staff_count.actual.key,
          this.props.time,
          this.props.data
        ) /
        (60 / Constants.GRAPH_INTERVAL),
      expected:
        sumDataUpUntilCurrentTime(
          Constants.LIVE_WAGE_GRAPH_ENTRIES.staff_count.rostered.key,
          this.props.time,
          this.props.data
        ) /
        (60 / Constants.GRAPH_INTERVAL),
    }
    const workedHoursVariance = workedHours.actual - workedHours.expected
    const workedHoursColor = workedHoursVariance > 0 ? "danger" : "success"

    const wageCostsProps = {
      mainLabel: t("wage_costs"),
      mainValue: wageCosts.actual,
      secondaryLabel: t("rostered"),
      secondaryTooltip: "",
      secondaryValue: wageCosts.expected,
      unitType: "currency",
      varianceColor: wageCostsColor,
      varianceLabel: t("variance"),
      varianceValue: wageCostsVariance,
    }
    const workedHoursProps = {
      mainLabel: t("worked_hours"),
      mainValue: workedHours.actual,
      secondaryLabel: t("rostered"),
      secondaryTooltip: "",
      secondaryValue: workedHours.expected,
      unitType: "none",
      varianceColor: workedHoursColor,
      varianceLabel: t("variance"),
      varianceValue: workedHoursVariance,
    }
    return (
      <div>
        <div className={styles.FilterOptionsLegendText}>
          <Text type="labelHeader">{t("title")}</Text>
        </div>
        <div className={styles.daySoFarMetricContainers}>
          {this.props.canSeeCosts && <DaySoFarMetric {...wageCostsProps} />}
          <DaySoFarMetric {...workedHoursProps} />
        </div>
      </div>
    )
  }
}
