// @flow
import nanoid from "nanoid"
import * as M from "helpers/marshall"
import * as LinkConfig from "./linkConfig"

export type Schema = {|
  +config: LinkConfig.Schema,
  +configurable: boolean,
  +destroyed?: true,
  +editable: ?boolean,
  +editableBy: Array<Permission>,
  +id: string,
  +key: string,
  +modelId: string,
  +name: string,
  +native: boolean,
  +primary: boolean,
  +required: boolean,
  +sortOrder: number,
  +type: Type,
  +uuid: string,
  +visibleTo: Array<Permission>,
|}

export type Type = "boolean" | "currency" | "date" | "float" | "long_text" | "text" | "linked_field"

export type Permission = "admin" | "manager" | "employee"

export const TYPES: Array<Type> = ["boolean", "currency", "date", "float", "long_text", "text", "linked_field"]

export const PERMISSIONS: Array<Permission> = ["admin", "manager", "employee"]
// should match <root>/app/models/platform/field.rb
export const RESERVED_KEYS = ["created_at", "id", "model_id", "updated_at", "native_id"]

const CONFIG_ATTRS = ["assocId", "subjectFieldId"]

const DEFAULTS = {
  id: "",
  name: "",
  modelId: "",
  type: "text",
  visibleTo: [],
  editableBy: [],
  configurable: true,
  config: {
    id: "",
    fieldId: "",
    assocId: "",
    subjectFieldId: "",
  },
}

export function isReservedKey(field: Schema): boolean {
  return RESERVED_KEYS.includes(getKey(field))
}

// should match <root>/app/models/platform/field.rb Platform::Field#confirm_or_create_key
export function getKey(field: Schema): string {
  return field.name.toLowerCase().replace(/\W/, " ").trim().replace(/\s+/, "_")
}

export function isDestroyed(f: Schema): boolean {
  return Boolean(f.destroyed)
}

export function setDestroyed(f: Schema): Schema {
  return { ...f, destroyed: true }
}

export function create(f: $Shape<Schema> = {}): Schema {
  return {
    ...DEFAULTS,
    ...f,
    uuid: nanoid(),
  }
}

export function patch(current: Schema, incoming: Schema): Schema {
  // fields we extract from the server response and merge back in
  const { id, visibleTo, editableBy } = incoming

  const config = incoming.config == null ? {} : LinkConfig.patch(current.config, incoming.config)

  return {
    ...current,
    config,
    id,
    visibleTo,
    editableBy,
  }
}

export function updateFromEvent(f: Schema, e: SyntheticInputEvent<HTMLElement>): Schema {
  if (e.target.type === "checkbox") {
    return {
      ...f,
      [e.target.name]: e.target.checked,
    }
  } else if (CONFIG_ATTRS.includes(e.target.name)) {
    if (!f.config) {
      return { ...f }
    }
    return {
      ...f,
      config: {
        ...f.config,
        [e.target.name]: e.target.value,
      },
    }
  } else {
    return {
      ...f,
      [e.target.name]: e.target.value,
    }
  }
}

export function validateField(value: mixed): boolean {
  return value == null || value === ""
}

export function fromJson(data: mixed): Schema {
  const f = M.object(data)
  const fieldConfig = f.type === "linked_field" ? LinkConfig.fromJson(f.config) : {}

  return {
    id: M.number(f.id).toString(),
    key: M.string(f.key),
    name: M.string(f.name),
    native: M.boolean(f.native),
    modelId: M.number(f.model_id).toString(),
    primary: M.boolean(f.primary),
    required: M.boolean(f.required),
    config: fieldConfig,
    type: M.enumerated(TYPES, M.string(f.type)),
    uuid: M.string(f.uuid),
    visibleTo: M.array(f.visible_to).map((p) => M.enumerated(PERMISSIONS, M.string(p))),
    editableBy: M.array(f.editable_by).map((p) => M.enumerated(PERMISSIONS, M.string(p))),
    editable: M.boolean(f.editable),
    sortOrder: M.number(f.sort_order),
    configurable: M.boolean(f.configurable),
  }
}

export function toPayload(f: Schema): mixed {
  const configAttributes =
    f.type === "linked_field" && f.config
      ? {
          config_attributes: LinkConfig.toPayload(f.config),
        }
      : {}
  return {
    _destroy: f.destroyed,
    id: f.id,
    key: f.key,
    name: f.name,
    ...configAttributes,
    native: f.native,
    model_id: f.modelId,
    primary: f.primary,
    required: f.required,
    type: f.type,
    uuid: f.uuid,
    visible_to: f.visibleTo,
    editable_by: f.editableBy,
    sort_order: f.sortOrder,
  }
}
