/**
 * @flow
 */

import moment from "moment"
import * as Time from "helpers/time"
import * as Types from "timesheets/views/types"
import * as Consts from "./constants"

// eslint-disable-next-line flowtype/no-weak-types
export const fieldToString = (fieldKey: string): any => Consts.FieldNames[fieldKey]

// shift IDs are mocked in:
// - app/assets/webpack/timesheets/logic/index.js (buildShiftTuple)
// - app/assets/webpack/timesheets/logic/shifts/core.js (shiftsCopy)
//
// shift break IDs are mocked in:
// app/assets/webpack/timesheets/models/shift/build.js (addMockIDsAndShiftIDsToBreaks)
export const hasMockedId = (shiftId: number): boolean => shiftId <= 0

export const isOvernight = (time: moment, date: string): boolean => time.isValid() && date !== time.format("YYYY-MM-DD")

/**
 * required params:
 * @param {*} value of the input
 * @param {*} date of the shift
 *
 * optional params:
 * @param {*} field of the input
 * @param {*} start_time of the shift
 */
export const momentFromInput = (value: string, date: string, field: string, start_time: string): moment$Moment => {
  const [hours, mins] = Time.interpret(value) || []

  if (!hours || !mins) {
    return moment.invalid()
  }

  const user_entered_time = moment(date).set({ h: parseFloat(hours) % 24, m: parseFloat(mins) % 60 })

  // overnight if we enter hours of 24 or higher (eg. "12am", or "25:00")
  // shift start time can never be overnight, but all other fields can

  // Checking if user wants to force am by checking if string contains "a", if prefixed with 0, eg 0300
  // moment treats midnight as 00:00 so need to check finish > 0
  const overrideExceptions = value.includes("a") || value.startsWith("0")

  if (
    field !== "start" &&
    (parseInt(hours, 10) >= 24 ||
      (start_time && moment(start_time).isValid() && user_entered_time.isBefore(moment(start_time))))
  ) {
    if (parseInt(moment(start_time).format("H")) < 12 && parseInt(hours, 10) < 12 && !overrideExceptions) {
      return user_entered_time.add({ h: 12 })
    } else {
      return user_entered_time.add({ d: 1 })
    }
  }

  return user_entered_time
}

export const splitByRosteredDays = (shiftUser: ?Types.User, org: Types.Organisation): boolean =>
  org.get("salary_allocation") === "rostered_days" &&
  Boolean(shiftUser ? shiftUser.get("appears_to_be_salaried") : false)
