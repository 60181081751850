/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"

import { CSSTransitionGroup } from "react-transition-group-legacy"

const IS_IE = Boolean(document.documentMode)

const SafeTransition = (props) => React.createElement(IS_IE ? props.component : CSSTransitionGroup, props)

SafeTransition.defaultProps = { component: "span" }
SafeTransition.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
}

export default SafeTransition
