// @flow

import { t } from "helpers/i18n"

export const SLIDER = { MIN: 0, MAX: 72, START_MAX: 48 }
export const DATA_DAYS_AVERAGE = 0
export const ORIGINAL_SCHEDULE_ID = "original-schedule-id"
export const POPOVER_TARGET = "popover-target"
export const BACKGROUND_COLOR = ".background-color"
export const NAME = ".name"
export const DATE_READABLE = "ddd, MMM, Do"
export const DATE_FMT = "YYYY-MM-DD"
export const DATE_TIME_FMT = "YYYY-MM-DD HH:mm:ss"
export const TIME_FMT = "HH:mm:ss"
export const OVERNIGHT = ".overnight"
export const START = ".start"
export const FINISH = ".finish"
export const USER_NAME = ".name-txt"
export const PLUS_CONTAINER = ".plus-holder"
export const ADD_USER_PARENT = ".add-user-wrap"
export const ADD_USER_INPUT = ".add-user-input"
export const DOM_SCHEDULE_DATA = "schedule"
export const DOM_D3_SCHEDULE_DATA = "schedule-d3"
export const DOM_SCHEDULE_CLASS = ".schedule"
export const DOM_D3_SCHEDULE_CLASS = ".schedule-d3"
export const DOM_STAFF_SCHEDULES_DATA = "staff-view-schedules"
export const DOM_SCHEDULES_DATA = "schedules"
export const DOM_SCHEDULES_CLASS = ".schedules"
export const DOM_LEAVE_DIV_CLASS = ".leave-div-js"
export const DOM_ERROR_SCHEDULE = "schedule-with-error"
export const DOM_ERROR_SCHEDULE_CLASS = ".schedule-with-error"
export const FIFTEEN_MINUTES = "900"
export const TEXTBOX_PREVIOUS_VALUE_KEY = "previous-value"
export const AUTOFILL_NAME = (): string => t("js.rosters.consts.auto_fill")
export const VACANT_SHIFT_NAME = (): string => t("js.rosters.consts.vacant")
export const VACANT_USER_ID = -1
export const AUTOFILL_USER_ID = -2
export const AUTOFILL_USER = (): {|
  active: boolean,
  // eslint-disable-next-line flowtype/no-weak-types
  award_tags: Array<any>,
  // eslint-disable-next-line flowtype/no-weak-types
  departments: Array<any>,
  id: number,
  name: string,
  // eslint-disable-next-line flowtype/no-weak-types
  tags: Array<any>,
|} => ({ id: AUTOFILL_USER_ID, name: AUTOFILL_NAME(), active: true, tags: [], award_tags: [], departments: [] })
export const VACANT_USER = (): {|
  active: boolean,
  // eslint-disable-next-line flowtype/no-weak-types
  award_tags: Array<any>,
  // eslint-disable-next-line flowtype/no-weak-types
  departments: Array<any>,
  id: number,
  name: string,
  // eslint-disable-next-line flowtype/no-weak-types
  tags: Array<any>,
|} => ({ id: VACANT_USER_ID, name: VACANT_SHIFT_NAME(), active: true, tags: [], award_tags: [], departments: [] })
export const POPOVER_CLASS = ".popover"
export const NO_POPOVER_ELEMENTS = [
  ".mi-edit",
  ".edit-button",
  ".colorPicker-picker",
  ".textPicker-picker",
  ".boxed-time",
  ".employee-picker",
  ".employee-name",
  ".schedule-tools",
  ".schedule-tools-left",
]
export const GENERATE_BUTTON = ".btn-generate"
export const PUBLISH_BUTTON = ".publish-btn-js"
export const SCHEDULE_BACKGROUND_OPACITY = "0.1"
export const TrafficLights = {
  Statuses: {
    // grouped based on severity. ie. first digit = 0 if green, 1 if orange, 2 if red
    AVAILABLE_DURING_SCHEDULE: 0,
    UNAVAILABLE_DURING_SCHEDULE: 10,
    ROSTER_VALIDATION_ERROR: 11,
    PENDING_LEAVE_DURING_SCHEDULE: 12,
    CLASHING_SCHEDULE: 20,
    APPROVED_LEAVE_DURING_SCHEDULE: 21,
  },
  Messages: {
    LEAVE: (options: { [opts: string]: string | number }): string => t("js.rosters.traffic_lights.leave_2", options),
    UNAVAILABILITY: (options: { [opts: string]: string | number }): string =>
      t("js.rosters.traffic_lights.unavailability", options),
    RED_CLASH: (): string => t("js.rosters.traffic_lights.clash"),
    ORANGE_VALIDATION: (): string => t("js.rosters.traffic_lights.orange_validation"),
    ORANGE_UNAVAILABLE: (): string => t("js.rosters.traffic_lights.not_available"),
    GREEN: (): string => t("js.rosters.traffic_lights.can_work"),
  },
}
export const LOCATION_SHORTCODE_DISPLAYS = {
  TEAM_ONLY: ".team-shortcode-only",
  COMBINED_TEAM: ".team-shortcode-combined",
  COMBINED_LOCATION: ".location-shortcode",
}
export const NO_TEAM = (): string => t("js.rosters.consts.no_team")
export const NO_SHIFT_DETAIL = (): string => t("js.rosters.consts.no_shift_detail")
export const PICKER_ID = "picker-id"
export const SHIFT_DETAIL_PICKER = ".shift-detail-picker"
export const TEAM_PICKER = ".team-picker"
export const NO_SHIFT_DETAIL_CLASS = ".no-shift-detail"
export const TAGGING_AREA = ".shift-ident"
export const TOOLTIP_OPTS = {
  OVERNIGHT_SHIFT: {
    title: (): string => t("js.rosters.tooltips.overnight_shift"),
    trigger: "hover",
    container: "body",
    placement: "top",
    class: "dismiss-js",
  },
}
export const MS_PER_HOUR = 3600000
