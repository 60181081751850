/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import cn from "classnames"

import Icon from "components/Icon"
import styles from "./styles.module.scss"

const REMOVE_KEY_CODES = [8, 13, 46] // backspace, enter, delete

export default class DefaultSelected extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
  }

  handleClick(event) {
    event.target.value = this.props.value
    this.props.onRemove(event)
  }

  onKeyDown(event) {
    if (REMOVE_KEY_CODES.includes(event.keyCode)) {
      event.target.value = this.props.value
      this.props.onRemove(event)
      event.preventDefault()
      event.stopPropagation()
    }
  }

  render() {
    return (
      <div className={cn(styles.Selected, { [styles.l]: this.props.size === "l" })}>
        <div className={styles.label}>{this.props.label}</div>
        <div
          className={styles.removeButton}
          onClick={this.handleClick}
          onKeyDown={this.onKeyDown}
          role="button"
          style={{ display: this.props.disabled ? "none" : "block" }}
          tabIndex={this.props.tabIndex}
        >
          <Icon size={this.props.size === "l" ? "s" : "xs"} type="close" />
        </div>
      </div>
    )
  }
}

DefaultSelected.propTypes = {
  label: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  size: PropTypes.oneOf(["s", "l"]),
  value: PropTypes.any.isRequired,
  tabIndex: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
}

DefaultSelected.defaultProps = { size: "s" }
