// @flow

import * as React from "react"
import { noop, flatten } from "lodash"
import { t as globalT } from "helpers/i18n"
import Text, { type MarginType, type AlignType } from "components/Text"
import styles from "./styles.module.scss"

const t = (key, opts) => globalT(`js.generic_components.list_truncator.${key}`, opts)

type RequiredProps = {|
  children: Array<React.Node>,
  maxVisible: number,
|}

type Props = {|
  ...RequiredProps,
  customLabel?: React.Node,
  itemName?: string,
  textAlign: AlignType,
  textMargin: MarginType,
  textPadding: string,
|}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  customLabel: null,
  textMargin: null,
  textPadding: "",
  textAlign: null,
  itemName: "",
}

/**
 * HOC that automatically truncates a long list of elements
 * and provides it with a button to show the hidden elements
 */
export default function ListTruncator(props: Props): React.Element<"div"> {
  const [show_anyway, setShowAnyway] = React.useState(false)
  const children = flatten(props.children)
  const first_to_show = children.slice(0, props.maxVisible)
  const rest = children.slice(props.maxVisible)

  return (
    <div>
      {first_to_show}
      {!show_anyway && rest.length > 0 && (
        <div
          className={styles.restButtonContainer}
          onClick={() => setShowAnyway(true)}
          onKeyUp={noop}
          role="button"
          tabIndex={0}
        >
          {props.customLabel == null && (
            <Text
              align={props.textAlign}
              color="primary"
              margin={props.textMargin}
              padding={props.textPadding}
              type="small"
            >
              {t("rest_hidden", { hidden: rest.length, item_name: props.itemName || t("default_item_name") })}
            </Text>
          )}
          {props.customLabel}
        </div>
      )}
      {show_anyway && rest}
    </div>
  )
}

ListTruncator.defaultProps = DEFAULT_PROPS
