/* @flow */

import * as React from "react"
import cn from "classnames"
import { noop } from "lodash"
import { t } from "helpers/i18n"
import Bubble from "components/Bubble"
import Icon, { type IconColor } from "components/Icon"

import Loader from "../Loader"
import styles from "./styles.module.scss"
// Loader Colours
const WHITE_LOADER: Array<ButtonType> = [
  "keyAction",
  "action",
  "highRiskAction",
  "magic",
  "roundedKeyAction",
  "roundedSecondaryAction",
]
const DANGER_LOADER: Array<ButtonType> = ["highRisk", "roundedRisk"]
const PRIMARY_LOADER: Array<ButtonType> = ["minorAction", "roundedAction"]
const SUCCESS_LOADER: Array<ButtonType> = ["roundedNoAction"]

export type ButtonType =
  | "default" // white background, grey text, "info" in bootstrap
  | "keyAction" // green background, white text, "success" in bootstrap
  | "action" // blue background, white text, "primary" in bootstrap
  | "minorAction"
  | "highRisk"
  | "highRiskAction"
  | "magic"
  | "roundedAction"
  | "roundedMinor"
  | "roundedTransparent"
  | "roundedRisk"
  | "roundedKeyAction"
  | "roundedSecondaryAction"
  | "roundedNoAction"
  | "savingsButton"
  | "textButton"

export type RequiredProps = {|
  onClick: (e: SyntheticMouseEvent<HTMLButtonElement>) => mixed,
|}

export type Props = {|
  ...RequiredProps,
  autoMargin: boolean,
  disabled: boolean,
  dropShadow: null | "s" | "l",
  flex: ?string,
  handleMouseLeave: () => mixed,
  handleMouseOver: () => mixed,
  htmlType: string,
  icon: string,
  iconSize: "xs" | "s" | "m" | "l" | "xl",
  label: string,
  loading: boolean,
  noBorder: boolean,
  noWrap: boolean,
  size: "s" | "m" | "l" | "xl",
  testId: ?string,
  tip: ?string,
  tipPosition: "top" | "bottom" | "left" | "right" | "auto",
  tipWidth: "auto" | "medium" | "large" | "max-content",
  type: ButtonType,
  updateRef: (*) => void,
|}

export const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  autoMargin: true,
  flex: null,
  icon: "",
  iconSize: "s",
  handleMouseLeave: noop,
  handleMouseOver: noop,
  size: "m",
  type: "default",
  disabled: false,
  dropShadow: null,
  label: "",
  loading: false,
  noWrap: false,
  noBorder: false,
  htmlType: "button",
  updateRef: noop,
  tip: null,
  testId: null,
  tipWidth: Bubble.defaultProps.width,
  tipPosition: Bubble.defaultProps.position,
}

export type StateType = {
  hovered: boolean,
}

export default class RostersButton extends React.PureComponent<Props, StateType> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  constructor(props: Props) {
    super(props)
    this.state = { hovered: false }
  }

  getSpinnerColor(): string {
    if (WHITE_LOADER.includes(this.props.type)) {
      return "white"
    }

    if (DANGER_LOADER.includes(this.props.type)) {
      return "danger"
    }

    if (PRIMARY_LOADER.includes(this.props.type)) {
      return "primary"
    }

    if (SUCCESS_LOADER.includes(this.props.type)) {
      return "success"
    }

    return "black"
  }

  getLoader(): null | React.Element<"div"> {
    if (!this.props.loading) {
      return null
    }
    return (
      <div className={styles.loader}>
        <Loader color={this.getSpinnerColor()} size={this.props.size === "s" ? "s" : "m"} />
      </div>
    )
  }

  getIcon(): null | React.Element<"div"> {
    if (!this.props.icon) {
      return null
    }
    return (
      <div className={cn(styles.icon, { [styles.iconWithLabel]: this.props.label !== "" })}>
        <Icon color={this.getIconColour()} size={this.props.iconSize} type={this.props.icon} />
      </div>
    )
  }

  getTip(): void | React.Node {
    if (this.props.tip == null || this.props.tip === "") {
      return
    }
    return (
      <Bubble hovered={this.state.hovered} position={this.props.tipPosition} width={this.props.tipWidth}>
        {this.props.tip}
      </Bubble>
    )
  }

  getIconColour(): IconColor {
    if (this.props.type === "roundedNoAction") {
      return "success"
    }

    if (
      this.props.type === "default" ||
      this.props.type === "roundedMinor" ||
      this.props.type === "roundedTransparent"
    ) {
      return "black"
    }

    if (this.props.type === "minorAction" || this.props.type === "roundedAction") {
      return "primary"
    }

    if (this.props.type === "highRisk" || this.props.type === "roundedRisk") {
      return "danger"
    }

    return "white"
  }

  handleMouseOver: () => void = () => {
    this.props.handleMouseOver()
    this.setState({ hovered: true })
  }

  handleMouseLeave: () => void = () => {
    this.props.handleMouseLeave()
    this.setState({ hovered: false })
  }

  render(): React.Element<"button"> {
    return (
      <button
        aria-label={this.props.loading ? t("js.generic_components.loading") : false}
        className={cn(styles.button, styles[this.props.size], styles[this.props.type], {
          [styles.disabled]: this.props.disabled,
          [styles.loading]: this.props.loading,
          [styles.autoMargin]: this.props.autoMargin,
          [styles.noBorder]: this.props.noBorder,
          [styles.onlyIcon]: this.props.icon && !this.props.label,
          [styles["dropShadow_" + String(this.props.dropShadow)]]: this.props.dropShadow != null,
        })}
        data-testid={this.props.testId}
        disabled={this.props.disabled || this.props.loading}
        onClick={this.props.onClick}
        onFocus={noop}
        onMouseLeave={this.handleMouseLeave}
        onMouseOver={this.handleMouseOver}
        ref={this.props.updateRef}
        style={{ flex: this.props.flex }}
        type={this.props.htmlType}
      >
        {/* Icon and label are always rendered (even when loading), to maintain the size of the button */}
        {this.getLoader()}
        {this.getIcon()}
        {this.getTip()}
        <div className={cn(styles.label, styles[this.props.size], { [styles.noWrap]: this.props.noWrap })}>
          {this.props.label}
        </div>
      </button>
    )
  }
}
