// @flow

import { createActions, createReducer } from "redux-em"

import * as Core from "./core"

// eslint-disable-next-line flowtype/no-weak-types
export const shiftReducer: any = createReducer(Core)

// eslint-disable-next-line flowtype/no-weak-types
export const shiftActions: any = createActions(Core)
