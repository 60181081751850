// @flow
import * as React from "react"
import Input, { type Props as BaseInputProps, DEFAULT_PROPS as InputProps } from "./Input"

function clamp(num: number, min?: number = -Infinity, max?: number = Infinity) {
  return Math.max(Math.min(num, max), min)
}

type RequiredProps = {|
  onChange: $PropertyType<BaseInputProps, "onChange">,
|}

type Props = {|
  ...RequiredProps,
  ...BaseInputProps,
  integerOnly: boolean,
  max: ?number,
  min: ?number,
  positiveOnly: boolean,
|}

type State = {| validating: null | TimeoutID |}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  max: null,
  min: null,
  integerOnly: false,
  positiveOnly: false,
  ...InputProps,
}

export default class NumberMaxMinInput extends React.PureComponent<Props, State> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS
  constructor(props: Props) {
    super(props)
    this.state = { validating: null }
  }

  handleOnChange: (e: SyntheticInputEvent<HTMLInputElement>) => mixed = (e: SyntheticInputEvent<HTMLInputElement>) => {
    if (this.state.validating !== null) {
      clearTimeout(this.state.validating)
    }
    const { value } = e.target
    const { max, min } = this.props
    const startsWithNegative = /^-/.test(value)
    const positiveValueString = startsWithNegative ? value.substr(1) : value

    if ((min && Number(value) < min) || (max && Number(value) > max)) {
      this.setState({
        validating: setTimeout(() => {
          // $FlowFixMe Flow doesn't like that we're mutating SyntheticInputEvent which is readonly
          this.props.onChange({ ...e, target: { ...e.target, value: clamp(value, min, max) } })
        }, 800),
      })
    }

    if (isNaN(positiveValueString)) {
      return
    }

    if (this.props.positiveOnly && startsWithNegative) {
      return
    }

    if (this.props.integerOnly && value && (parseFloat(value) !== parseInt(value) || /\.$/.test(value))) {
      return
    }

    this.props.onChange(e)
  }

  render(): React.Node {
    // eslint-disable-next-line no-unused-vars
    const { max, min, integerOnly, positiveOnly, ...rest } = this.props
    return <Input {...rest} onChange={this.handleOnChange} />
  }
}
