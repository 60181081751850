/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

/**
 * flattens a set of groups and finds an
 * options
 */
export const find = (options, value) => flatten(options).find((option) => option.value === value)

/**
 * takes a set of groups, and flattens all their options
 */
export const flatten = (options) =>
  options.reduce((options, item) => {
    // selectable group
    if (item.options && item.value) {
      return options.concat([{ value: item.value }]).concat(flatten(item.options))
    }

    // regular group
    if (item.options) {
      return options.concat(flatten(item.options))
    }

    // regular item
    return options.concat(item)
  }, [])

/**
 * takes an offset and creates a handler for shift
 * the focused value of an option list
 * @param {number} offset to offset the focused value by
 */
export const offsetFocusedValue = (offset) => (options, focusedValue) => {
  // convert nested data structure to a flat list
  // containing only focusble items
  const focusables = flatten(options)

  const i = offset + focusables.findIndex((option) => option.value === focusedValue)

  const safeIndex = i < 0 ? focusables.length - 1 : i >= focusables.length ? 0 : i

  return focusables[safeIndex].value
}
