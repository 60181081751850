// @flow
import * as React from "react"
import moment from "moment"
import { t } from "helpers/i18n"
import { ButtonSingleDatePicker } from "components/DatePicker"
import styles from "./styles.module.scss"

type Props = {
  dateViewing: moment,
  disabled: boolean,
  handleDateChange: (newDate: ?moment) => void,
}

type State = {
  isFocused: boolean,
}

export default class DayPicker extends React.PureComponent<Props, State> {
  static defaultProps: {| dateViewing: moment$Moment, disabled: boolean, focused: boolean |} = {
    dateViewing: moment(),
    disabled: false,
    focused: false,
  }

  constructor(props: Props) {
    super(props)
    this.state = { isFocused: false }
  }

  // essentially we are allowing the user to pick any date
  handleIsOutsideRange: () => boolean = (): boolean => false

  handleNavFocusChange: (focused: boolean) => void = (focused: boolean) => {
    this.setState({ isFocused: focused })
  }

  getLabelMessage(): string {
    const date = this.props.dateViewing
    if (date.isSame(moment(), "day")) {
      return t("js.datepick.today")
    } else if (date.isSame(moment().add(1, "days"), "day")) {
      return t("js.datepick.tomorrow")
    } else if (date.isSame(moment().subtract(1, "days"), "day")) {
      return t("js.datepick.yesterday")
    } else {
      return this.props.dateViewing.format("ddd, Do MMM")
    }
  }

  render(): React.Element<"div"> {
    return (
      <div className={styles.DayPicker}>
        <div className={styles.picker}>
          <ButtonSingleDatePicker
            autoMargin={false}
            date={this.props.dateViewing}
            daySize={30}
            disabled={this.props.disabled}
            focused={this.state.isFocused}
            hideKeyboardShortcutsPanel
            isOutsideRange={this.handleIsOutsideRange}
            label={this.getLabelMessage()}
            numberOfMonths={1}
            onDateChange={this.props.handleDateChange}
            onFocusChange={this.handleNavFocusChange}
            showDefaultInputIcon
            size="s"
            startDate={Date.now()}
            textAlign="center"
          />
        </div>
      </div>
    )
  }
}
