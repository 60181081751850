/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { t } from "helpers/i18n"

import * as Shift from "timesheets/models/shift"

import Row from "./Row"
import styles from "./styles.module.scss"

export default class Breakdown extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = this.deriveStateFromShiftProp(props.shift)
  }

  UNSAFE_componentWillReceiveProps({ shift }) {
    if (Shift.States.Syncing === shift.get("state")) {
      return this.setState({ isSyncing: true })
    }

    if (shift !== this.props.shift) {
      return this.setState(this.deriveStateFromShiftProp(shift))
    }
  }

  deriveStateFromShiftProp(shift) {
    return {
      isSyncing: false,
      shiftCost: shift.get("awarded_cost") + shift.get("auto_allowance_cost"),
      shiftLength: shift.get("shift_length") ? shift.get("shift_length") * 60 * 60 * 1000 : 0,
      rosteredLength: shift.get("rostered_length") ? shift.get("rostered_length") * 60 * 60 * 1000 : 0,
    }
  }

  calcTimeVariance() {
    if (Number.isNaN(this.state.shiftLength)) {
      return NaN
    }

    return this.state.rosteredLength - this.state.shiftLength
  }

  calcCostVariance() {
    if (Number.isNaN(this.state.shiftCost)) {
      return NaN
    }

    if (Shift.splitByRosteredDays(this.props.shift.get("user"), this.props.currentOrganisation)) {
      return 0
    }

    return (this.props.shift.get("rostered_cost") || 0) - this.state.shiftCost
  }

  renderVarianceCost() {
    return (
      <Row
        cost={this.calcCostVariance()}
        isMultipleShift={this.props.isMultipleShift}
        isVariance
        lightText
        showCosts
        splitByRosteredDays={Shift.splitByRosteredDays(this.props.shift.get("user"), this.props.currentOrganisation)}
        time={this.calcTimeVariance()}
        title={t("js.timesheets.shift_card.breakdown.variance_title")}
      />
    )
  }

  renderNoVarianceCost() {
    return (
      <Row
        isVariance
        lightText
        time={this.calcTimeVariance()}
        title={t("js.timesheets.shift_card.breakdown.variance_title")}
      />
    )
  }

  render() {
    return (
      <div className={cn(styles.Breakdown, { [styles.isSyncing]: this.state.isSyncing })}>
        <Row
          cost={this.state.shiftCost}
          isMultipleShift={this.props.isMultipleShift}
          isShiftNotRostered={!this.props.shift.get("rostered_cost")}
          isWageCost
          showCosts={this.props.shift.getIn(["permitted_actions", "see_costs"])}
          splitByRosteredDays={Shift.splitByRosteredDays(this.props.shift.get("user"), this.props.currentOrganisation)}
          time={this.state.shiftLength}
          title={t("js.timesheets.shift_card.breakdown.wage_cost_title")}
        />
        <Row
          cost={this.props.shift.get("rostered_cost")}
          isMultipleShift={this.props.isMultipleShift}
          lightText
          showCosts={this.props.shift.getIn(["permitted_actions", "see_costs"])}
          splitByRosteredDays={Shift.splitByRosteredDays(this.props.shift.get("user"), this.props.currentOrganisation)}
          time={this.state.rosteredLength}
          title={t("js.timesheets.shift_card.breakdown.rostered_title")}
        />
        {this.props.shift.getIn(["permitted_actions", "see_costs"])
          ? this.renderVarianceCost()
          : this.renderNoVarianceCost()}
      </div>
    )
  }
}

Breakdown.propTypes = {
  isMultipleShift: PropTypes.bool.isRequired,
  shift: PropTypes.object.isRequired,
  currentOrganisation: PropTypes.object.isRequired,
}
