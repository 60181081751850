/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import Text from "components/Text"
import styles from "./styles.module.scss"

export default class PageHeader extends React.PureComponent {
  renderChildren() {
    if (!this.props.children) {
      return null
    }

    return <div className={styles.buttonContainer}>{this.props.children}</div>
  }

  renderSubTitle() {
    if (!this.props.subTitle) {
      return null
    }

    return (
      <div className={styles.subtitleWrapper}>
        <Text color="lightest" type="small">
          {this.props.subTitle}
        </Text>
      </div>
    )
  }

  render() {
    return (
      <div className={styles.pageHeader}>
        <div className={styles.titleArea}>
          <Text bold color="text" padding="0 0 0.5rem 0" type="pageHeader">
            {this.props.title}
          </Text>
          {this.renderSubTitle()}
        </div>
        {this.renderChildren()}
      </div>
    )
  }
}

PageHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element, PropTypes.string]),
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
}

PageHeader.defaultProps = {
  children: null,
  subTitle: "",
}
