// @flow

import * as React from "react"
import Button from "components/Button"
import { t as globalT } from "helpers/i18n"
import downloadCSV from "helpers/downloadToCSV"

const t = (key, ...args) => globalT(`js.generic_components.react_data_table.${key}`, ...args)

type Props = {|
  +data: Array<$ReadOnly<{ [string]: mixed }>>,
  +filename: string,
  +options?: { header?: Array<string>, separator?: string },
|}

const DownloadCSVButton = ({ filename, data }: Props): React.Node => {
  const handleCSVDownload = () => {
    downloadCSV(data, filename)
  }

  return (
    <Button
      iconLeft="cloud-download"
      label={t("export_this_to_csv")}
      onClick={handleCSVDownload}
      size="md"
      type="action"
      weight="primary"
    />
  )
}

DownloadCSVButton.defaultProps = {
  options: { header: [""], separator: "," },
}

export default DownloadCSVButton
