/* @flow */
import * as React from "react"
import _ from "lodash"
import { t as globalT } from "helpers/i18n"
import Icon from "components/Icon"
import Text from "components/Text"
import type { LocationDataType, DepartmentType } from "dashboard/live_insights/main"
import { filterLocationData, locationsFromLoadedLocations } from "../selectors"
import GraphLegendIcon from "./GraphLegendIcon"
import styles from "./styles.module.scss"

const t = (key: string, args) => globalT(`js.dashboard.widgets.live_insights.header.${key}`, args)

type PropType = {
  allDepartmentIds: Array<number>,
  isLargeOrg: boolean,
  loadedLocations: Array<number>,
  locationData: Array<LocationDataType>,
  selectedDepartments: Array<number>,
}

export default class SelectedDepartments extends React.PureComponent<PropType> {
  static defaultProps: {| departments: null, selectedDepartments: Array<number> |} = {
    selectedDepartments: [],
    departments: null,
  }

  showTeamsInHeader: (
    allLocationData: ?Array<LocationDataType>,
    visibleLocationData: Array<LocationDataType>
  ) => boolean = (allLocationData: ?Array<LocationDataType>, visibleLocationData: Array<LocationDataType>): boolean => {
    if (allLocationData == null || visibleLocationData.length !== 1) {
      return false
    } else {
      const selectedLocation = visibleLocationData[0]
      const locationData = _.find(allLocationData, { id: selectedLocation.id })
      return locationData == null || locationData.departments.length !== selectedLocation.departments.length
    }
  }

  render(): React.Element<"div"> {
    const hasLoadedLocations = this.props.loadedLocations != null && this.props.loadedLocations.length >= 1
    const noSelectedLocations = this.props.selectedDepartments.length === 0
    const largeOrgWithLoadedLocations = this.props.isLargeOrg && hasLoadedLocations && noSelectedLocations
    if (
      (noSelectedLocations || this.props.selectedDepartments.length === this.props.allDepartmentIds.length) &&
      !largeOrgWithLoadedLocations
    ) {
      return (
        <div className={styles.locationFilterContainer}>
          <Text type="tiny">{t("all_stores_selected")}</Text>
        </div>
      )
    }
    let visibleLocationData = largeOrgWithLoadedLocations
      ? locationsFromLoadedLocations(this.props.locationData, this.props.loadedLocations)
      : filterLocationData(this.props.locationData, this.props.selectedDepartments)
    const showTeamsInstead = this.showTeamsInHeader(this.props.locationData, visibleLocationData)
    const locationToUse: LocationDataType = visibleLocationData[0]
    let visibleTeamData = showTeamsInstead && visibleLocationData[0] != null ? visibleLocationData[0].departments : []
    let hiddenFilterItems = 0
    const MAX_DEPS_TO_SHOW = 1
    const depCount = showTeamsInstead ? visibleTeamData.length : visibleLocationData.length
    if (depCount > MAX_DEPS_TO_SHOW) {
      visibleTeamData = visibleTeamData.slice(0, MAX_DEPS_TO_SHOW)
      visibleLocationData = visibleLocationData.slice(0, MAX_DEPS_TO_SHOW)
      hiddenFilterItems = depCount - MAX_DEPS_TO_SHOW
    }
    return (
      <div className={styles.SelectedDepartmentContainer}>
        {showTeamsInstead && (
          <div className={styles.locationFilterItemContainer}>
            <Icon color="grey" type="place" />
            <div className="pl05">
              {" "}
              <Text type="tiny">{locationToUse.short_name}</Text>{" "}
            </div>
          </div>
        )}
        {showTeamsInstead
          ? visibleTeamData.map((depData: DepartmentType) => (
              <div className={styles.locationFilterItemContainer} key={depData.name}>
                <GraphLegendIcon chartType={"departmentSwatch"} color={depData.color} />
                <div className="pl05">
                  <Text type="tiny">{depData.name}</Text>
                </div>
              </div>
            ))
          : visibleLocationData.map((depData: LocationDataType) => (
              <div className={styles.locationFilterItemContainer} key={depData.name}>
                <Icon color="grey" type="place" />
                <div className="pl05">
                  <Text type="tiny">{depData.name}</Text>
                </div>
              </div>
            ))}
        {hiddenFilterItems > 0 && (
          <div className={styles.locationFilterContainer}>
            <Text type="tiny">{t("more_hidden_items", { hidden: hiddenFilterItems })}</Text>
          </div>
        )}
      </div>
    )
  }
}
