/**
 * @flow
 */

import * as React from "react"
import { t as globalT } from "helpers/i18n"
import Icon from "components/Icon"
import Button from "components/Button"
import FileItem from "./FileItem"
import styles from "./styles.module.scss"

const t = (key, opts) => globalT(`js.generic_components.file.${key}`, opts)

type RequiredProps = {|
  onChange: (verification: ?File) => ?mixed,
|}
type Props = {|
  ...RequiredProps,
  buttonText?: ?string,
  disabled?: boolean,
  error: ?string,
  fileName: ?string,
  id?: string,
  tooltip?: ?string,
  tooltipPosition?: "top" | "bottom" | "left" | "right" | "auto",
  url: ?string,
  verificationTypes: Array<string>,
|}

export default class FileButton extends React.PureComponent<Props> {
  form: ?HTMLFormElement

  static defaultProps: $Diff<Props, RequiredProps> = {
    buttonText: null,
    disabled: false,
    error: null,
    fileName: null,
    id: "",
    tooltip: null,
    tooltipPosition: "bottom",
    url: null,
    verificationTypes: [],
  }

  handleFileChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    this.props.onChange(event.target.files[0])
  }

  handleFileRemoved: () => void = () => {
    const isConfirmed = window.confirm(t("confirm_delete", { file_name: this.props.fileName }))
    if (this.form && isConfirmed) {
      this.form.reset()
      this.props.onChange(null)
    }
  }

  handleClickAddFile: () => void = () => {
    if (this.props.disabled) {
      return
    }

    if (this.form) {
      this.form.reset()
    }

    const input = this.form && this.form.elements.namedItem("fileInput")

    input && input.click()
  }

  setForm: (node: ?HTMLFormElement) => void = (node: ?HTMLFormElement) => {
    this.form = node
  }

  render(): React.Element<"div"> {
    return (
      <div className={styles.fileSection}>
        {this.props.fileName != null || this.props.disabled ? (
          <React.Fragment>
            {this.props.url && this.props.fileName !== this.props.url && (
              <a className={styles.linkItem} href={this.props.url} rel="noopener noreferrer" target="_blank">
                <Icon color="primary" size="s" type="cloud-download" />
              </a>
            )}
            <FileItem
              disabled={this.props.disabled}
              fileName={this.props.fileName}
              onFileRemoved={this.handleFileRemoved}
              url={this.props.url}
            />
          </React.Fragment>
        ) : (
          <Button
            disabled={this.props.disabled}
            iconLeft="add"
            id={this.props.id}
            label={this.props.buttonText || t("add_file")}
            onClick={this.handleClickAddFile}
            size="sm"
            tooltip={
              this.props.tooltip
                ? {
                    content: this.props.tooltip,
                    position: this.props.tooltipPosition || "bottom",
                    width: "large",
                  }
                : null
            }
            type="theme"
            weight="secondary"
          />
        )}
        {this.props.error != null && (
          <div className={styles.error} data-testid="fileAttachmentError">
            {this.props.error}
          </div>
        )}
        <form hidden ref={this.setForm}>
          <input
            accept={this.props.verificationTypes.join(", ")}
            id="fileInput"
            onChange={this.handleFileChange}
            type="file"
          />
        </form>
      </div>
    )
  }
}
