/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

/* eslint react/no-array-index-key: off */
import * as React from "react"
import PropTypes from "prop-types"
import { Map } from "immutable"
import moment from "moment"
import cn from "classnames"
import { t } from "helpers/i18n"
import * as Shift from "timesheets/models/shift"
import Segment from "./Segment"
import styles from "./styles.module.scss"

export default class Chart extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isSyncing: false,
      segments: this.buildChartSegments(props.shift.get("breakdown")),
    }
  }
  UNSAFE_componentWillReceiveProps({ shift }) {
    if (Shift.States.Syncing === shift.get("state")) {
      this.props.setIsSyncing(true)
      return this.setState({ isSyncing: true })
    }
    if (shift !== this.props.shift) {
      this.props.setIsSyncing(false)
      return this.setState({
        isSyncing: false,
        segments: this.buildChartSegments(shift.get("breakdown")),
      })
    }
  }
  msToMins(ms) {
    return ms / 60000
  }
  hrsToMs(hrs) {
    return hrs * 3600000
  }
  toPercent(part, total) {
    return (part / total) * 100
  }
  buildChartSegments(awardCaches) {
    return awardCaches.toArray().reduce((segments, nextCache) => {
      const lastCache = segments[segments.length - 1]
      const breakLength = lastCache ? moment(nextCache.get("start")).diff(moment(lastCache.get("finish"))) : 0
      if (!breakLength) {
        return [...segments, nextCache]
      }
      const breakCache = new Map({
        name: t("js.timesheets.shift_card.chart.break_name"),
        start: lastCache.get("finish"),
        finish: nextCache.get("start"),
        breakLength: t("js.timesheets.shift_card.chart.break_length", {
          length: this.msToMins(breakLength),
        }),
      })
      return [...segments, breakCache, nextCache]
    }, [])
  }
  getAwardCacheWidth(awardCache) {
    return this.toPercent(
      moment(awardCache.get("finish")).diff(moment(awardCache.get("start"))),
      this.hrsToMs(this.props.shift.get("shift_length"))
    )
  }

  render() {
    return (
      <div className={cn(styles.Chart, { [styles.isSyncing]: this.state.isSyncing })}>
        {this.state.segments.length ? (
          this.state.segments.map(
            (
              awardCache,
              index //
            ) => (
              <Segment // While it is normally bad practice
                awardCache={awardCache} // to use the index as a key in
                color={this.props.colors[awardCache.get("name")]} // react, in this case it is
                currentUser={this.props.currentUser} // useful for animation.
                key={index} //
                width={`${this.getAwardCacheWidth(awardCache)}%`}
              />
            )
          )
        ) : (
          <div className={styles.emptyAwardCache}>
            <div className={styles.emptyBar} />
          </div>
        )}
      </div>
    )
  }
}

Chart.propTypes = {
  colors: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  setIsSyncing: PropTypes.func,
  shift: PropTypes.object.isRequired,
}

Chart.defaultProps = {
  setIsSyncing: () => {},
}
