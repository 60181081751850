// @flow

import _ from "lodash"
import * as Constants from "../../overview/helpers/constants"
import type { AllDataStreamData } from "./index"

export function merge_and_add_stats(
  a: { [minute: string]: number },
  b: { [minute: string]: number }
): { [minute: string]: number } {
  if (!(a && b)) {
    return a || b
  }

  const new_obj = {}
  const objs = [a, b]

  objs.forEach((obj) => {
    _.toPairs(obj).forEach(([k, v]) => {
      new_obj[k] = (new_obj[k] || 0) + obj[k]
    })
  })
  return new_obj
}

export const sum_ds_stats_for_time_period = (
  data: ?AllDataStreamData,
  ds_id: string,
  start_min: ?number,
  end_min: ?number
): number => {
  const stats_over_time_period: Array<Array<number>> =
    data == null
      ? []
      : _.values(data)
          .filter((ds) => ds.data_stream_id === ds_id)
          .map((ds) => {
            if (ds.stat_type === Constants.ELANDO_STAT_TYPE) {
              // We grab 48hrs worth of data, but only want the first 24hrs for Elando here
              return [_.max(_.values(ds.stat_by_15).slice(0, Math.ceil(Object.keys(ds.stat_by_15).length / 2))) || 0]
            }
            if (ds.data_type === Constants.STATIC_STAT_TYPE) {
              return [_.max(_.values(ds.stat_by_15)) || 0]
            }
            return _.map(ds.stat_by_15, (v, k: string) => {
              const time = Number(k)
              return (start_min != null && time < start_min) || (end_min != null && time >= end_min) ? 0 : v
            })
          })
  return _.flatten(stats_over_time_period).reduce((a, n) => a + n, 0)
}

// DATETIME MUST BE OF FORM "YYYY-MM-DD HH:MM:SSS"
// Safari requires 'T' prefix for time https://stackoverflow.com/questions/21883699/safari-javascript-date-nan-issue-yyyy-mm-dd-hhmmss/21884244
export const dateTimeToMin: (date: string) => number = _.memoize(
  (date: string) => new Date(date.split(" ").join("T")).getTime() / 1000 / 60
)

export const dateTimeToMinWithOffset: (date: string, ref_date: string) => number = _.memoize(
  (date: string, ref_date: string) => {
    const unix_timestamp = new Date(date.split(" ").join("T")).getTime() / 1000 / 60
    const todays_offset = new Date(date).getTimezoneOffset()
    const start_of_day_offset = new Date(ref_date).getTimezoneOffset()
    const difference = todays_offset - start_of_day_offset

    return Math.round(unix_timestamp - difference)
  }
)
