// @flow
import * as State from "../../models/state"
import type { Action } from "../action"

type Forms = State.Partial<"forms">

export default function forms(state: Forms, action: Action): Forms {
  switch (action.type) {
    case "@patch/state":
      return action.data.forms
    default:
      return state
  }
}
