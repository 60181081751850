/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import { noop } from "lodash"
import SimpleLayout from "components/SimpleLayout"
import PageHeader from "components/PageHeader"
import BodyContainer from "components/BodyContainer"
import Button from "components/Button"
import { s3ImagePath } from "helpers/image"
import { t } from "helpers/i18n"
import * as Routes from "helpers/routes"
import PlanDetail from "../shared/PlanDetail"
import styles from "./styles.module.scss"

export default class Confirmation extends React.PureComponent {
  renderReceiptText() {
    if (!this.props.usesChargebee && this.props.annualBilling) {
      return <p>{t("js.billing.confirmation.receipt_invoice")}</p>
    }
    return <p>{t("js.billing.confirmation.receipt_cc")}</p>
  }
  // ^^ this needs to know whether the user sees a receipt detail explaining the invoice or credit card

  renderSuccessContainer() {
    return (
      <div className={styles.successContainer}>
        <div className={styles.greeting}>
          <img alt="happy panda" className={styles.panda} src={s3ImagePath("confirmation_panda.png")} />
          <div className={styles.greetingText}>
            <h3>{t("js.billing.confirmation.welcome_header", { org_name: this.props.organisationName })}</h3>
            <p>{t("js.billing.confirmation.welcome_body")}</p>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <a href={Routes.dashboard_path()}>
            <Button label={t("js.billing.confirmation.dashboard_button")} onClick={noop} type="theme" />
          </a>
        </div>
      </div>
    )
  }

  render() {
    return (
      <SimpleLayout>
        <PageHeader title={t("js.billing.confirmation.title")} />
        <BodyContainer>
          <div className={styles.confirmationContainer}>
            {this.renderSuccessContainer()}
            <PlanDetail {...this.props} />
          </div>
        </BodyContainer>
      </SimpleLayout>
    )
  }
}

Confirmation.propTypes = {
  organisationName: PropTypes.string.isRequired,
  annualBilling: PropTypes.bool,
  usesChargebee: PropTypes.bool,
}

Confirmation.defaultProps = {
  annualBilling: false,
  usesChargebee: true,
}
