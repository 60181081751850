// @flow
// Icon from https://heroicons.com/
// Icon is from the 'Solid' set and can be used freely. It's called 'check-circle'

import * as React from "react"

export default function CheckCircle(): React.Element<"svg"> {
  return (
    <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        fillRule="evenodd"
      />
    </svg>
  )
}
