// @flow

export type Schema = TemplateRuby

export type TemplateRuby = {|
  id: number,
  length: number,
  location_ids: Array<number>,
  name: string,
  start_date: string,
  template_type: "RosterTemplate" | "RosterPattern" | "RegularHours",
|}

export const TEMPLATE_TYPE = {
  RosterTemplate: "RosterTemplate",
  RosterPattern: "RosterPattern",
  RegularHours: "RegularHours",
}

export default TEMPLATE_TYPE

export type TemplateEditableParams = {|
  length?: number,
  location_ids?: Array<number>,
  name?: string,
  start_date?: string,
|}

export type TemplateUnmarshalled = {|
  id: number,
  length: number,
  location_ids: Array<number>,
  name: string,
  organisation_id: number,
  original_roster_id: number,
  sort_order: null,
  start_date: string,
|}

export type TemplateRequestResponseType = {|
  error_message: string,
  success: boolean,
  template: TemplateRuby,
|}

export const marshallTemplate = (unmarshelled: TemplateUnmarshalled): TemplateRuby => ({
  id: unmarshelled.id,
  length: unmarshelled.length,
  location_ids: unmarshelled.location_ids,
  name: unmarshelled.name,
  start_date: unmarshelled.start_date,
  template_type: TEMPLATE_TYPE.RosterTemplate,
})
