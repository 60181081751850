/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import { t as globalT } from "helpers/i18n"
import Text from "components/Text"
import Icon from "components/Icon"
import Loader from "components/Loader"
import SuccessTick from "components/SuccessTick"
import styles from "./styles.module.scss"

const t = (key, ...args) => globalT(`js.generic_components.state_notifier.${key}`, ...args)

export default class StateNotifier extends React.PureComponent {
  getStateIcon() {
    switch (this.props.state) {
      case "saving":
      case "creating":
        return <Loader color="primary" />
      case "loading":
      case "loading_actual_demand":
      case "loading_predicted_demand":
      case "recosting":
        return <Loader color="black" />
      case "invalid":
        return <Icon color={"warning"} size="m" type="remove-circle" />
      case "error":
        return <Icon color={"danger"} size="m" type="error" />
      case "unsaved":
        return <Icon color={"grey"} size="m" type="radio-button-unchecked" />
      case "success":
        return <SuccessTick size="xs" />
      default:
        return null
    }
  }
  render() {
    if (this.props.state == null || this.props.state === "default") {
      return <div className={styles.defaultContainer} />
    }
    return (
      <div className={styles.container}>
        <div className={styles.textWrapper}>
          <Text color="light" type="small">
            {t(this.props.state)}
          </Text>
        </div>
        <div className={styles.iconWrapper}>{this.getStateIcon()}</div>
      </div>
    )
  }
}

StateNotifier.propTypes = {
  state: PropTypes.oneOf([
    "default",
    "loading",
    "saving",
    "error",
    "unsaved",
    "success",
    "invalid",
    "recosting",
    "loading_actual_demand",
    "loading_predicted_demand",
  ]),
}

StateNotifier.defaultProps = { state: "default" }
