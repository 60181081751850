/* @flow */

import * as React from "react"
import { CSSTransitionGroup } from "react-transition-group-legacy"
import _ from "lodash"

import styles from "./styles.module.scss"
import Modal from "./"

/**
 * External API
 * currentPage - The page to show
 * onNextClick - The function to fire when the next page is requested
 * onPrevClick - The function to fire when the previous page is requested
 *
 */
type RequiredProps = {|
  children: React.Node,
  currentPage: number,
  onExit: () => void,
  // callbacks to allow the next and previous functions to fire
  onNextClick: () => void,
  onPrevClick: () => void,
  open: boolean,
|}

type Props = {|
  ...RequiredProps,
  exitable: boolean,
  // some hooks to allow the parent to enable/disable functionality during the transition
  onAnimationEnd: () => void,
  onAnimationStart: () => void,
  size: "s" | "m" | "l" | "xl",
|}

type State = {|
  animating: boolean,
  animationTimeout: ?TimeoutID,
  currentPage: number,
  next: boolean,
|}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  exitable: true,
  onAnimationStart: _.noop,
  onAnimationEnd: _.noop,
  size: "l",
}

export default class PagedModal extends React.Component<Props, State> {
  animationDuration: number
  static defaultProps: {|
    exitable: boolean,
    onAnimationEnd: () => void,
    onAnimationStart: () => void,
    size: "s" | "m" | "l" | "xl",
  |} = DEFAULT_PROPS

  constructor(props: Props) {
    super(props)

    // if you update this number you need to make CSS changes too
    // ask this guy for help: https://github.com/TandaHQ/payaus/pull/2623#discussion_r153408336
    this.animationDuration = 500

    this.state = {
      currentPage: 0,
      next: true,
      animating: false,
      animationTimeout: null,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const current = this.props.currentPage
    const next = nextProps.currentPage
    const forward = current < next
    if (this.props.currentPage !== nextProps.currentPage) {
      this.setState({ next: forward })
      this.props.onAnimationStart()
      this.setState({ animationTimeout: setTimeout(() => this.props.onAnimationEnd(), this.animationDuration) })
    }
  }

  componentWillUnmount() {
    clearTimeout(this.state.animationTimeout)
  }

  // eslint-disable-next-line flowtype/no-weak-types
  getTransition(): {| enter: any, leaveActive: any |} {
    const dir = this.state.next ? "next" : "prev"
    return {
      enter: styles[`paged_modal_enter_${dir}`],
      leaveActive: styles[`paged_modal_leave_active_${dir}`],
    }
  }

  nextPageHandler(prop: () => void): () => void {
    return () => {
      if (!this.state.animating) {
        this.setState({ animating: true })
        setTimeout(() => this.setState({ animating: false }), this.animationDuration)
        prop()
      }
    }
  }

  render(): React.Node {
    const children = React.Children.toArray(this.props.children)

    // Ensure that page number does not exceed number of pages.
    // Using a ternary here was a bit confusing and hard to understand.
    let page
    if (this.props.currentPage < React.Children.count(this.props.children)) {
      // Page number does not exceed number of pages
      page = this.props.currentPage
    } else {
      // Page number is larger than number of pages, set currentPage to 0
      page = 0
    }

    const childWithProps =
      children.length === 0
        ? null
        : React.cloneElement(children[page], {
            exit: this.props.onExit,
            nextPage: this.nextPageHandler(this.props.onNextClick),
            prevPage: this.nextPageHandler(this.props.onPrevClick),
          })

    return (
      <Modal exitable={this.props.exitable} onExit={this.props.onExit} open={this.props.open} size={this.props.size}>
        <CSSTransitionGroup
          className={styles.transition_wrapper}
          transitionEnterTimeout={this.animationDuration}
          transitionLeaveTimeout={this.animationDuration}
          transitionName={{
            enterActive: styles.paged_modal_enter_active,
            leave: styles.paged_modal_leave,
            ...this.getTransition(),
          }}
        >
          {childWithProps}
        </CSSTransitionGroup>
      </Modal>
    )
  }
}

const ModalPage = (props: { children: React.Node }): React.Element<"div"> => (
  <div className={styles.modal_content}>{props.children}</div>
)

export { ModalPage }
