// @flow

/*
note that if you are testing GQL functionality you might want these mocks:
app/assets/webpack/helpers/gql/__mocks__/client.jsx
*/

import * as React from "react"
import { ApolloClient, ApolloProvider, createHttpLink, useMutation, useQuery, useLazyQuery, gql } from "@apollo/client"
import { InMemoryCache } from "@apollo/client/cache"
import { Response, Headers } from "node-fetch"
import axios from "axios"
import { mapKeys } from "lodash"
import addInterceptor from "helpers/axiosIntercepter"
import * as Routes from "helpers/routes"
import * as TYPES from "./types"
import possibleTypes from "./client_schema.json"

// eslint-disable-next-line flowtype/no-weak-types
type DocumentNode = any

addInterceptor(axios)

// from https://github.com/lifeomic/axios-fetch/blob/master/src/index.js
async function axiosFetch(axios, input, init = {}) {
  // for some reason if you use the library directly it breaks teapsoon tests
  // suspect the FormData import is the problem
  const lowerCasedHeaders = mapKeys(init.headers, function (value, key) {
    return key.toLowerCase()
  })

  if (!("content-type" in lowerCasedHeaders)) {
    lowerCasedHeaders["content-type"] = "text/plain;charset=UTF-8"
  }

  const config = {
    url: input,
    method: init.method || "GET",
    data: init.body instanceof FormData ? init.body : String(init.body),
    headers: lowerCasedHeaders,
    validateStatus: () => true,
  }

  const result = await axios.request(config)

  // Convert the Axios style response into a `fetch` style response
  const responseBody = typeof result.data === `object` ? JSON.stringify(result.data) : result.data

  const headers = new Headers()
  Object.entries(result.headers).forEach(function ([key, value]) {
    if (value != null) {
      headers.append(key, String(value))
    }
  })

  return new Response(responseBody, {
    status: result.status,
    statusText: result.statusText,
    headers,
  })
}

function buildAxiosFetch(axios) {
  return axiosFetch.bind(undefined, axios)
}

const httpLink = createHttpLink({
  uri: Routes.internal_graphql_path(),
  credentials: "same-origin",
  fetch: buildAxiosFetch(axios),
})

const client: typeof ApolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({ possibleTypes }),
})

export const PERMISSION_CHECK_QUERY: DocumentNode = gql`
  query permissionCheck($subject: String!, $action: String!, $id: ID) {
    can(subjectClass: $subject, action: $action, id: $id)
  }
`

export function useCan(action: string, subject: string, id: ?(number | string)): boolean {
  const q = useQuery<TYPES.permissionCheck, {}>(PERMISSION_CHECK_QUERY, {
    variables: {
      action,
      id,
      subject,
    },
  })

  return q.data == null ? false : q.data.can
}

type Props = {|
  +children: React.Node,
|}

export function Provider({ children }: Props): React.Node {
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export { client, useMutation, useQuery, useLazyQuery }
