// @flow

import * as React from "react"
import _ from "lodash"
import Popover from "components/Popover/BasePopover"
import type { RawJoinData } from "rosters/WebpackRosters/DemandData"
import Icon from "components/Icon"
import Text from "components/Text"
import * as HelpFunc from "rosters/overview/helpers/functions"
import * as Cognitive from "rosters/overview/models/cognitive"
import { t as globalT } from "helpers/i18n"
import Input from "components/Input"
import styles from "./styles.module.scss"

const t = (key: string, ...args) =>
  globalT(`js.rosters.rosters_overview.predictive_workforce.advanced_head_counts.${key}`, ...args)

type Props = {|
  dsj: RawJoinData,
  onChange: (headCountMap: ?string) => mixed,
  onClose: () => mixed,
  stat_type: string,
  team_name: string,
|}
type Node = {| current: mixed |}
function AdvancedSetupPopover(props: Props): React.Node {
  const dsj = props.dsj
  const [temp, setTemp] = React.useState(null)
  const hcm = temp || dsj.head_count_map
  const head_count_map = Cognitive.get_head_count_map_array(hcm)
  const head_count_map_valid = head_count_map && head_count_map.length > 0
  const node: Node = React.useRef()
  const handleClose = React.useCallback(() => {
    props.onChange((head_count_map && head_count_map.join(",")) || null)
    props.onClose()
  }, [props, head_count_map])
  React.useEffect(() => {
    const outsideClick = (e: SyntheticEvent<HTMLDivElement>) => {
      // $FlowFixMe
      if (node.current != null && !node.current.contains(e.target)) {
        handleClose()
      }
    }

    window.document.addEventListener("mousedown", outsideClick)

    return () => {
      window.document.removeEventListener("mousedown", outsideClick)
    }
  }, [handleClose])
  return (
    <Popover hovered onClose={handleClose} position="right" setRef={node} width="large">
      {head_count_map &&
        head_count_map_valid &&
        head_count_map.map((total, n) => (
          <div className={styles.rosterRatioExplanationWrapper} key={total}>
            <Text bold type="small">
              {HelpFunc.formatStat(total, props.stat_type, false)}
            </Text>
            <Text color="light" margin="right" type="small">
              /15m
            </Text>
            <Text margin="right" type="small">
              {" "}
              =
            </Text>
            <Text bold margin="right" type="small">
              {n + 1}
            </Text>
            <Icon type="person" />
            <Text margin="left" type="small">
              {props.team_name}
            </Text>
          </div>
        ))}
      {head_count_map &&
        head_count_map_valid &&
        _.range(0, 2).map((n) => {
          const count = (_.last(head_count_map) || 0) + (n + 1) * dsj.rostering_ratio
          const staff_count = head_count_map.length + n + 1
          return (
            <div className={styles.rosterRatioExplanationWrapper} key={n}>
              <Text color="light" type="small">
                {HelpFunc.formatStat(count, props.stat_type, false)}
              </Text>
              <Text color="light" margin="right" type="small">
                /15m
              </Text>
              <Text margin="right" type="small">
                {" "}
                =
              </Text>
              <Text margin="right" type="small">
                {staff_count + "*"}
              </Text>
              <Icon type="person" />
              <Text margin="left" type="small">
                {props.team_name}
              </Text>
            </div>
          )
        })}
      {head_count_map_valid && (
        <Text color="light" margin="left" padding="0.25rem 0 1rem 0" type="tiny">
          {"* " + t("subsequent", { rostering_ratio: dsj.rostering_ratio })}
        </Text>
      )}
      <Text align="left" padding="0 0 0.5rem 0" type="small">
        {t("write_your_own")}
      </Text>
      <Text align="left" color="light" padding="0 0 0.5rem 0" type="tiny">
        {t("write_your_own_eg")}
      </Text>
      <Input
        onBlur={() => {
          setTemp(null)
          temp === "" ? props.onChange(null) : props.onChange((head_count_map && head_count_map.join(",")) || null)
        }}
        onChange={(e: SyntheticInputEvent<HTMLInputElement>) => setTemp(e.target.value)}
        placeholder={t("eg")}
        value={temp != null ? temp : dsj.head_count_map || ""}
      />
    </Popover>
  )
}

export default AdvancedSetupPopover
