// @flow

import * as React from "react"
import BasePopover, { type Props as BasePopoverProps } from "./BasePopover"
import styles from "./styles.module.scss"

type RequiredProps = {|
  children: React.Node,
  title: React.Node,
|}

type Props = {|
  ...RequiredProps,
  ...BasePopoverProps,
|}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  ...BasePopover.defaultProps,
  position: "bottom",
  offset: 0.75,
  width: "auto",
  hovered: false,
  padding: null,
}

export default function Popover(props: Props): React.Element<"div"> {
  // $FlowFixMe ignore title prop
  const basePopoverProps: BasePopoverProps = props
  return (
    <div className={styles.HoverWrapper}>
      <BasePopover {...basePopoverProps} noPadding>
        <div className={styles.title}>{props.title}</div>
        <div className={styles.content} style={{ padding: props.padding }}>
          {props.children}
        </div>
      </BasePopover>
    </div>
  )
}

Popover.defaultProps = DEFAULT_PROPS
