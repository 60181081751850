// @flow

import * as React from "react"
import { AutoHoverBubble, type AHBProps } from "components/Bubble"
import Icon, { type Props as IconProps } from "components/Icon"

type RequiredProps = {|
  type: $PropertyType<IconProps, "type">,
|}

type Props = {|
  ...RequiredProps,
  color: $PropertyType<IconProps, "color">,
  size: $PropertyType<IconProps, "size">,
  testId: $PropertyType<IconProps, "testId">,
  tip?: React.Node,
  tipPosition: $PropertyType<AHBProps, "position">,
  width: $PropertyType<AHBProps, "width">,
|}

export default class HoverIcon extends React.PureComponent<Props> {
  static defaultProps: $Diff<Props, RequiredProps> = {
    color: Icon.defaultProps.color,
    size: Icon.defaultProps.size,
    testId: Icon.defaultProps.testId,
    tip: null,
    tipPosition: AutoHoverBubble.defaultProps.position,
    width: AutoHoverBubble.defaultProps.width,
  }

  renderTooltip(): null | React.Node {
    if (!this.props.tip) {
      return null
    }

    return (
      <AutoHoverBubble position={this.props.tipPosition} width={this.props.width}>
        {this.props.tip}
      </AutoHoverBubble>
    )
  }

  render(): React.Node {
    return (
      <Icon
        color={this.props.color}
        forceOverflowVisible
        size={this.props.size}
        testId={this.props.testId}
        type={this.props.type}
      >
        {this.renderTooltip()}
      </Icon>
    )
  }
}
