// @flow

import * as React from "react"
import cn from "classnames"
import _ from "lodash"
import { t as globalT } from "helpers/i18n"
import Currency from "helpers/currency"
import Avatar from "components/Avatar"
import Text from "components/Text"
import LoadingState from "components/States/LoadingState"
import Request from "helpers/request"
import * as Routes from "helpers/routes"
import type { MappedPlan } from "../../logic/types"

import styles from "./styles.module.scss"

const t = (key, opts) => globalT(`js.shift_swaps.landing.modal.employee_card.${key}`, opts)

export type Props = {
  costs: {
    ifSwapped: ?number,
    previous: ?number,
  },
  hours: ?{
    ifSwapped: number,
    previous: number,
  },
  onClick: () => ?mixed,
  plan: MappedPlan,
  selected: ?boolean,
  updateProposal: (costs: number, hours: number) => ?mixed,
  user: {
    avatar: string,
    id: number,
    name: string,
  },
}

function getCostDifference(previous: number, ifSwapped: number): string {
  const difference = ifSwapped - previous
  if (difference === 0) {
    return "Same"
  }
  return Currency.from(difference)
}

function getCostColour(previous: number, ifSwapped: number): string {
  if (previous >= ifSwapped) {
    return "varianceGood"
  }
  return "varianceBad"
}

export default function EmployeeCard(props: Props): React.Element<"div"> {
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)

  const [costs, setCosts] = React.useState(props.costs)
  const [hours, setHours] = React.useState({
    ifSwapped: props.hours != null ? props.hours.ifSwapped : null,
    previous: props.hours != null ? props.hours.previous : null,
  })

  const noProposalData = (hours.ifSwapped === 0 || hours.ifSwapped === null) && costs.ifSwapped === 0

  const fetchProposalData = () => {
    setLoading(true)
    setError(false)
    Request.put(Routes.shift_swap_plan_update_swap_proposal_path({ plan_id: props.plan.id, user_id: props.user.id }))
      .then((response) => {
        setCosts({ ...costs, ifSwapped: response.data.cost })
        setHours({
          ifSwapped: response.data.hours_in_week,
          previous: response.data.hours_in_week - props.plan.schedule_data.hours,
        })
        props.updateProposal(response.data.cost, response.data.hours_in_week)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        setError(true)
      })
  }

  const handleClick = () => {
    props.onClick()
    if (noProposalData) {
      fetchProposalData()
    }
  }

  return (
    <div
      aria-label={props.user.name}
      className={cn(styles.cardWrapper, { [styles.cardSelected]: props.selected })}
      onClick={handleClick}
      onKeyPress={_.noop}
      role="button"
      tabIndex={0}
    >
      <div className={styles.spaceBetween}>
        <div className={styles.userDetails}>
          <Avatar alt={props.user.name} size="s" source={props.user.avatar} type="round" />
          <div className={styles.name}>{props.user.name}</div>
        </div>
        {loading && (
          <div className={styles.loading}>
            <LoadingState />
          </div>
        )}
      </div>
      {error && (
        <div className={styles.details}>
          <Text color="lighter" type="tiny">
            {t("swap_proposal_data_load_fail")}
          </Text>
        </div>
      )}
      {!noProposalData && costs.ifSwapped != null && costs.ifSwapped ? (
        <div className={styles.details}>
          <div className="txt1">{t("cost_title")}</div>
          <div className="txt1">
            {/* Should never be null, we check above but flow is being anal */}
            {Currency.from(costs.ifSwapped != null ? costs.ifSwapped : 0)}
          </div>
        </div>
      ) : null}
      {!noProposalData && costs.previous != null && costs.ifSwapped != null && costs.previous && costs.ifSwapped ? (
        <div className={cn(styles.details, "mb2")}>
          <div className={cn(styles.varianceLeft, "txt0750")}>{t("cost_variance")}</div>
          <div
            // previous and ifSwapped should never be null, we check above but flow is being anal
            className={cn(
              styles[
                getCostColour(
                  costs.previous != null ? costs.previous : 0.0,
                  costs.ifSwapped != null ? costs.ifSwapped : 0.0
                )
              ],
              "txt0750"
            )}
          >
            {costs.previous &&
              costs.ifSwapped &&
              getCostDifference(
                costs.previous != null ? costs.previous : 0.0,
                costs.ifSwapped != null ? costs.ifSwapped : 0.0
              )}
          </div>
        </div>
      ) : null}
      {!noProposalData && hours.ifSwapped != null && hours.previous != null ? (
        <React.Fragment>
          <div className={styles.details}>
            <div className="txt1">{t("hours_title")}</div>
            <div className="txt1">{hours.previous}</div>
          </div>
          <div className={cn(styles.details, "mb2")}>
            <div className={cn(styles.varianceLeft, "txt0750")}>{t("hours_variance")}</div>
            <div className="txt0750">{hours.ifSwapped}</div>
          </div>
        </React.Fragment>
      ) : null}
    </div>
  )
}
