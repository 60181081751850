/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import { noop } from "lodash"
import Icon from "components/Icon"
import styles from "./styles.module.scss"

export default class SearchBar extends React.PureComponent {
  render() {
    return (
      <div className={styles.searchBar}>
        <Icon color="grey" type="search" />
        <input
          className={styles.input}
          onChange={this.props.onChange}
          placeholder={this.props.placeholder}
          value={this.props.value}
        />
      </div>
    )
  }
}

SearchBar.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
}

SearchBar.defaultProps = {
  value: "",
  onChange: noop,
  placeholder: "",
}
