/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import styles from "./styles.module.scss"

export default function Loader(props) {
  return (
    <div className={cn(styles.container, styles[`size_${props.size}`])}>
      <div className={cn(styles.spinner, styles[`color_${props.color}`])} />
    </div>
  )
}

Loader.propTypes = {
  // Match icon sizes
  size: PropTypes.oneOf(["xs", "s", "m", "l", "xl"]),
  color: PropTypes.oneOf([
    "black",
    "grey",
    "white",
    "success",
    "primary",
    "danger",
    "theme-primary ",
    "theme-secondary ",
    "action-primary ",
    "action-secondary ",
    "danger-primary ",
    "danger-secondary ",
    "warning-primary ",
    "warning-secondary ",
    "ghost ",
    "ghost-primary ",
    "ghost-secondary",
    "magic",
    "magic-primary ",
    "magic-secondary ",
  ]),
}

Loader.defaultProps = {
  size: "m",
  color: "primary",
}
