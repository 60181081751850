// @flow
import * as Reselect from "reselect"
import * as M from "helpers/marshall"
import * as Model from "./model"
import * as Field from "./field"
import * as Record from "./record"
import * as Form from "./form"
import * as FormTemplate from "./formTemplate"

export type Partial<K: string> = $ElementType<Schema, K>

export type Schema = {|
  +forms: { +[id: string]: Form.Schema },
  +formTemplates: { +[id: string]: FormTemplate.Schema },
  +models: { +[id: string]: Model.Schema },
  +records: { +[id: string]: Record.Schema },
  +resources: { +[url: string]: number },
|}

const DEFAULTS = {
  models: {},
  records: {},
  forms: {},
  formTemplates: {},
  resources: {},
}

export function create(state: $Shape<Schema> = {}): Schema {
  return {
    ...DEFAULTS,
    ...state,
  }
}

export function fromJson(data: mixed): Schema {
  const s = M.object(data)

  return {
    models: M.withDefault({}, () =>
      M.array(s.models)
        .map(Model.fromJson)
        .reduce((acc, m: Model.Schema) => ({ ...acc, [m.id]: m }), ({}: $Shape<{||}>))
    ),
    records: M.withDefault({}, () =>
      M.array(s.records)
        .map(Record.fromJson)
        .reduce((acc, r: Record.Schema) => ({ ...acc, [r.id]: r }), ({}: $Shape<{||}>))
    ),
    forms: M.withDefault({}, () =>
      M.array(s.forms)
        .map(Form.fromJson)
        .reduce((acc, f: Form.Schema) => ({ ...acc, [f.id]: f }), ({}: $Shape<{||}>))
    ),
    formTemplates: M.withDefault({}, () =>
      M.array(s.form_templates)
        .map(FormTemplate.fromJson)
        .reduce((acc, ft: FormTemplate.Schema) => ({ ...acc, [ft.id]: ft }), ({}: $Shape<{||}>))
    ),
    resources: {},
  }
}

export function modelById(state: Schema, id: string): ?Model.Schema {
  return state.models[id]
}

export function modelByAssoc(state: Schema, id: string): ?Model.Schema {
  const modelIds = Object.keys(state.models)
  for (let i = 0; i < modelIds.length; i++) {
    if (state.models[modelIds[i]].associations.find((a) => a.id === id)) {
      return state.models[modelIds[i]]
    }
  }
}

export function recordById(state: Schema, id: string): ?Record.Schema {
  return state.records[id]
}

export function formTemplateById(state: Schema, id: string): FormTemplate.Schema {
  return state.formTemplates[id]
}

export function formById(state: Schema, id: string): Form.Schema {
  return state.forms[id]
}

export function resourceAge(state: Schema, url: string): number {
  return state.resources[url] || Number.NEGATIVE_INFINITY
}

export const modelList: (state: Schema) => Array<Model.Schema> = Reselect.createSelector(
  (state) => state.models,
  (models) => Object.keys(models).map((k: string) => models[k])
)

export const fieldList: (state: Schema) => Array<Field.Schema> = Reselect.createSelector(
  (state) => state.models,
  (fields) =>
    Object.keys(fields)
      .map((k: string) => fields[k].fields)
      .reduce((acc, fields) => acc.concat(fields), [])
)

export const recordsByModelId: (state: Schema, modelId: string) => Array<Record.Schema> = Reselect.createSelector(
  (state, _) => state.records,
  (_, modelId) => modelId,
  (records, modelId) =>
    Object.keys(records)
      .map((k: string) => records[k])
      .filter((r: Record.Schema) => r.modelId === modelId)
)
