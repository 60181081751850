/* @flow */
import * as React from "react"
import Modal, { Header, Footer, FooterLeft, FooterRight } from "components/Modal"
import { t as globalT } from "helpers/i18n"
import { s3ImagePath } from "helpers/image"
import Button from "components/Button"
import Text from "components/Text"
import type { MetricTypes } from "dashboard/live_insights/main"
import Select from "components/Select"
import ModalSelectOption from "./ModalSelectOption"
import styles from "./styles.module.scss"

const t: typeof globalT = (key, args) => globalT(`js.dashboard.widgets.live_insights.header.modal.${key}`, args)

type PropType = {
  canSeeCosts: ?boolean,
  isLiveInsights: boolean,
  isOpen: boolean,
  metricType: MetricTypes,
  onExit: () => void,
  saveConfigHandler: (isLiveInsights: boolean, metricType: ?MetricTypes) => void,
}

type StateType = {
  isLiveInsights: boolean,
  metricType: MetricTypes,
}

export default class LiveInsightsConfigureModal extends React.PureComponent<PropType, StateType> {
  constructor(props: PropType) {
    super(props)
    this.state = { isLiveInsights: this.props.isLiveInsights, metricType: this.props.metricType }
  }

  handleSelectComponent: (event: { +target: { value: boolean, ... }, ... }) => void = (event: {
    +target: { value: boolean },
  }) => {
    this.setState({ isLiveInsights: event.target.value })
  }

  handleChangeMetricType: (event: { +target: { value: MetricTypes, ... }, ... }) => void = (event: {
    +target: { value: MetricTypes },
  }) => {
    this.setState({ metricType: event.target.value })
  }

  handleSaveSelectedComponent: () => void = (): void => {
    this.props.saveConfigHandler(this.state.isLiveInsights, this.state.metricType)
    this.props.onExit()
  }

  handleCloseModal: () => void = (): void => {
    this.setState({ isLiveInsights: this.props.isLiveInsights })
    this.props.onExit()
  }

  render(): React.Node {
    const { isOpen, canSeeCosts } = this.props
    const { isLiveInsights } = this.state
    return (
      <Modal fixedTop onExit={this.handleCloseModal} open={isOpen}>
        <Header titleText={t("configure_widget")} />
        <div className={styles.modalBody}>
          <Text type="formHeader">{t("widget_type")}</Text>
          <div className={styles.ModalSelectContainer}>
            <ModalSelectOption
              featureItems={[t("wages"), t("staff_count_hours")]}
              imageSrc={s3ImagePath("live-wt-optionbox.png")}
              onClick={this.handleSelectComponent}
              selected={!isLiveInsights}
              subtitle={t("live_and_acrued")}
              title={t("live_wage_tracker")}
              value={false}
            />
            <ModalSelectOption
              featureItems={[t("wages"), t("staff_count_hours"), t("sales_demand"), t("wage_or_splh")]}
              imageSrc={s3ImagePath("live-insights-optionbox.png")}
              onClick={this.handleSelectComponent}
              selected={isLiveInsights}
              subtitle={t("live_and_acrued")}
              title={t("live_insights")}
              value
            />
          </div>
          {this.state.isLiveInsights && canSeeCosts && (
            <div>
              <Text align="center" type="formHeader">
                {t("metric_choice")}
              </Text>
              <div className={styles.modalSelectContainer}>
                <Select
                  newStyle
                  onChange={this.handleChangeMetricType}
                  options={[
                    {
                      value: "wage_percentage",
                      label: t("wage_metric"),
                    },
                    {
                      value: "splh",
                      label: t("splh_metric"),
                    },
                  ]}
                  placeholder={t("select_an_option")}
                  tabIndex={0}
                  value={this.state.metricType}
                />
              </div>
            </div>
          )}
        </div>
        <Footer>
          <FooterLeft>
            <Button label={t("cancel_button")} onClick={this.handleCloseModal} type="ghost" />
          </FooterLeft>
          <FooterRight>
            <Button label={t("save_button")} onClick={this.handleSaveSelectedComponent} type="action" />
          </FooterRight>
        </Footer>
      </Modal>
    )
  }
}
