// @flow
import * as M from "helpers/marshall"
import type { Timesheet } from "../../views/types"

export const isExported = (t: Timesheet): boolean => t.get("status") === Status.Exported

export const isApproved = (t: Timesheet): boolean => t.get("status") === Status.Approved

export const isPending = (t: Timesheet): boolean => t.get("status") === Status.Pending

export const isUnapproving = (t: Timesheet): boolean => t.get("state") === States.Unapproving

export const isApproving = (t: Timesheet): boolean => t.get("state") === States.Approving

export const isSyncing = (t: Timesheet): boolean => t.get("state") === States.Syncing

export const isLimberWorker = (t: Timesheet): boolean => M.maybeBoolean(t.get("limber_worker")) || false

export const Status: {| Approved: string, Exported: string, Pending: string |} = {
  Approved: "approved",
  Exported: "exported",
  Pending: "pending",
}

export const States: {| Approving: string, Clean: string, Syncing: string, Unapproving: string |} = {
  Approving: "APPROVING",
  Unapproving: "UNAPPROVING",
  Syncing: "SYNCING",
  Clean: "CLEAN",
}

// this is a const Tanda-wide (separate problem tbh)
export const UNCATEGORISED_SHIFTS = "Uncategorised Shifts"
