// @flow

import * as React from "react"
import { gql } from "@apollo/client"
import { map, keyBy, compact } from "lodash"
import { t } from "helpers/i18n"
import * as TandaGQL from "helpers/gql"
import ShiftAssociationPreview from "./ShiftAssociationPreview"
import styles from "./styles.module.scss"

type Props = {|
  platformModalShiftId: ?number, // this isn't used for anything, but we need it so we can re-render whenever then modal opens/closes
  shiftId: number,
|}

const QUERY = gql`
  query shiftPlatformRecordPreview($id: ID!) {
    shift(id: $id) {
      record {
        values
        model {
          fields(types: ALL, filter: CUSTOM_ONLY) {
            name
            key
          }
          associations(types: ALL, filter: CUSTOM_ONLY) {
            name
            key
          }
        }
      }
    }
  }
`

export default function PlatformRecordPreview({ platformModalShiftId, shiftId }: Props): null | React.Element<"p"> {
  // $FlowFixMe[method-unbinding] https://github.com/facebook/flow/issues/8689
  const query = TandaGQL.useQuery<TandaGQL.shiftPlatformRecordPreview, TandaGQL.shiftPlatformRecordPreviewVariables>(
    QUERY,
    {
      variables: {
        id: shiftId.toString(),
      },
    }
  )

  // apollo useQuery doesn't work properly with fetch-policy (it defaults to `cache-first` and it's not clear how to override)
  // this manually works around that... https://www.apollographql.com/docs/react/data/queries/#updating-cached-query-results
  // we only want to render the preview when the modal for editing platform values closes, ie when it goes from having a value to being undefined
  const [cachedPlatformModalShiftId, setCachedPlatformModalShiftId] = React.useState(platformModalShiftId)
  if (cachedPlatformModalShiftId !== platformModalShiftId) {
    if (!platformModalShiftId) {
      // fetch if incoming value is undefined (modal being closed)
      query.refetch()
    }
    setCachedPlatformModalShiftId(platformModalShiftId)
  }

  if (!query.data || !query.data.shift) {
    return null
  }

  const record = query.data.shift.record
  const fields = keyBy(record.model.fields, "key")
  const assocs = keyBy(record.model.associations, "key")
  const values: { +[key: string]: mixed } = record.values

  const output = compact(
    map(values, (value, key) => {
      if (key in fields && value) {
        // display fields + values, unless value is null
        return (
          <span className={styles.keyValuePair} key={key}>
            {String(fields[key].name)} ({String(value)})
          </span>
        )
      } else if (key && assocs[key] && value) {
        return (
          <span className={styles.keyValuePair} key={assocs[key].name}>
            <ShiftAssociationPreview name={assocs[key].name} recordId={Number(value)} />
          </span>
        )
      }
    })
  )

  return (
    <p className={styles.description}>
      {output.length ? output : t("js.timesheets.shift_card.platform_fields.preview_empty")}
    </p>
  )
}
