// @flow

import moment from "moment"
import * as React from "react"
import { connect, type ConnectedComponent } from "react-redux"
import * as Routes from "helpers/routes"
import Request from "helpers/request"
import { t as globalT } from "helpers/i18n"
import { getFirstName } from "helpers/string"
import { Footer, FooterRight, FooterLeft } from "components/Modal"
import Button from "components/Button"
import Icon from "components/Icon"
import type { MappedPlan, ScheduleSwapPlan } from "../../logic/types"
import Actions from "../../logic/actions"
import styles from "./styles.module.scss"

const t = (key, opts) => globalT(`js.shift_swaps.landing.modal.${key}`, opts)

export type Props = {
  onConfirm: () => void,
  onExit: () => void,
  plan: MappedPlan,
  updatePlans: ({ id: number, plan: ScheduleSwapPlan }) => Promise<*>,
}

export type State = {
  loading: boolean,
}

export class BroadcastConfirmation extends React.Component<Props, State> {
  state: State = { loading: false }

  broadcast: () => void = () => {
    this.setState({ loading: true }, () => {
      Request.put(Routes.shift_swap_plan_broadcast_path({ plan_id: this.props.plan.id }))
        .then((res) => {
          this.props.onConfirm()
          this.props.onExit()
          return res
        })
        .then((res) => {
          this.props.updatePlans({
            id: res.data.id,
            plan: res.data,
          })
        })
    })
  }

  render(): React.Node {
    const startMoment = moment(this.props.plan.schedule_data.start)
    const finishMoment = moment(this.props.plan.schedule_data.finish)
    return (
      <React.Fragment>
        <div className={styles.confirmContent}>
          <Icon color="success" size="l" type="check-circle" />
          <div className={styles.confirmTitle}>
            {this.props.plan.is_vacant
              ? t("broadcast_confirmation_vacant")
              : t("broadcast_confirmation_title", { previousEmployee: getFirstName(this.props.plan.user.name) })}
          </div>
          <div className={styles.confirmShiftDetail}>
            {`${startMoment.format("D MMMM, h:mma")} - ${
              startMoment.day() !== finishMoment.day()
                ? finishMoment.format("D MMMM, h:mma")
                : finishMoment.format("h:mma")
            }`}
          </div>
        </div>
        <Footer>
          <FooterRight>
            <Button label={t("confirm")} loading={this.state.loading} onClick={this.broadcast} type="theme" />
          </FooterRight>
          <FooterLeft>
            <Button
              disabled={this.state.loading}
              label={globalT(`js.generic_components.modal.cancel_text`)}
              onClick={this.props.onExit}
              type="ghost"
            />
          </FooterLeft>
        </Footer>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = { updatePlans: Actions.plansUpdate }

// $FlowFixMe flow >0.85 requires more types for connect
/* eslint-disable flowtype/no-weak-types */
export default (connect(null, mapDispatchToProps)(BroadcastConfirmation): Class<
  ConnectedComponent<any, typeof BroadcastConfirmation>
> &
  typeof BroadcastConfirmation)
/* eslint-enable flowtype/no-weak-types */
