/* @flow */
import * as React from "react"
import moment from "moment"
import cn from "classnames"
import { dataForWageTrackerChart, getSelectedLocationId } from "dashboard/live_insights/selectors"
import Constants from "dashboard/live_insights/helpers/constants"
import Filter from "dashboard/live_insights/helpers/filter"
import { t } from "helpers/i18n"
import Header from "dashboard/live_insights/components/Header"
import DaySoFarContainer from "dashboard/live_insights/views/LiveWageTracker/TrackerDaySoFar"
import type { LiveInsightsDataType, LocationDataType, FilterOptionsObjectType, MetricTypes } from "../../main"
import LargeOrgState from "../../../generic/LargeOrgState"
import LiveWageTrackerChart from "./LiveWageTrackerChart"
import TrackerChartController from "./TrackerChartController"
import styles from "./styles.module.scss"

export type PropType = {
  allDepartmentIds: Array<number>,
  canSeeCosts: boolean,
  data: ?LiveInsightsDataType,
  dateViewing: moment,
  emptyLargeOrg: boolean,
  getDataByLocation: (?Array<number>) => void,
  handleDateChange: () => void,
  hideWidget: ?() => void,
  isBackgroundLoading: boolean,
  isLargeOrg: boolean,
  isLiveInsights: boolean,
  isLoading: boolean,
  loadedLocations: Array<number>,
  locationData: ?Array<LocationDataType>,
  metricType: MetricTypes,
  saveConfigHandler: (isLiveInsights: boolean, metricType: ?MetricTypes) => void,
  showSettingToggle: boolean,
  time: ?moment,
  timeChangeHandler: (timeString: string) => void,
}

export type DataFiltersType = {
  departments: Array<number>,
  filterOptions: Array<FilterOptionsObjectType>,
  selected: "staff_count" | "staff_cost",
  showSales: boolean,
  toggleRosteredLine: boolean,
}

export type StateType = {
  dataFilters: DataFiltersType,
}

export default class LiveWageTracker extends React.PureComponent<PropType, StateType> {
  constructor(props: PropType) {
    super(props)

    const selectedDepartmentsCookie = window.$.cookie(Constants.TEAMS_COOKIE_KEY + window.current_user.id)
    const selectedDepartmentsFromCookie = selectedDepartmentsCookie != null ? JSON.parse(selectedDepartmentsCookie) : []

    const selectedCookie = window.$.cookie(Constants.TRACKER_DATATYPE_COOKIE_KEY + window.current_user.id)
    const selectedDefault = selectedCookie || "staff_count"

    const toggleRosteredLineCookie = window.$.cookie(Constants.ROSTERED_DATATYPE_COOKIE_KEY + window.current_user.id)
    const toggleRosteredLineDefault = toggleRosteredLineCookie ? JSON.parse(toggleRosteredLineCookie) : true

    this.state = {
      dataFilters: {
        departments: selectedDepartmentsFromCookie,
        showSales: false,
        selected: selectedDefault,
        toggleRosteredLine: toggleRosteredLineDefault,
        filterOptions: Constants.LIVE_WAGE_FILTER_OPTIONS,
      },
    }
  }

  handleFilterSelectedChange: (event: { +target: { value: "staff_count" | "staff_cost", ... }, ... }) => void =
    (event: { +target: { value: "staff_count" | "staff_cost" } }) => {
      this.setState({ dataFilters: { ...this.state.dataFilters, selected: event.target.value } })
      this.props.data &&
        window.$.cookie(Constants.TRACKER_DATATYPE_COOKIE_KEY + window.current_user.id, event.target.value)
    }

  handleToggleRosterLine: () => () => void = () => () => {
    const toggleRosteredLine = !this.state.dataFilters.toggleRosteredLine

    this.setState({ dataFilters: { ...this.state.dataFilters, toggleRosteredLine: toggleRosteredLine } })
    window.$.cookie(Constants.ROSTERED_DATATYPE_COOKIE_KEY + window.current_user.id, toggleRosteredLine)
  }

  handleToggleSalesLine: () => () => void = () => () => {
    this.setState({ dataFilters: { ...this.state.dataFilters, showSales: !this.state.dataFilters.showSales } })
  }

  handleTeamFilterOnChange: (event: { +target: { value: Array<number>, ... }, ... }) => void = (event: {
    +target: { value: Array<number> },
  }) => {
    const departments = event.target.value
    const locationIds = getSelectedLocationId(departments, this.props.locationData, this.props.loadedLocations)
    const departmentsIsEmpty = departments != null && !departments.length
    const locationIdsIsEmpty = locationIds != null && !locationIds.length
    const handleFilterAsLargeOrg = this.props.isLargeOrg && !departmentsIsEmpty && !locationIdsIsEmpty
    const selectedLocationsNotLoaded =
      // $FlowFixMe flow complains locationId's might be null, !locationIdsIsEmpty check handles this.
      locationIdsIsEmpty || (!locationIdsIsEmpty && !locationIds.every((l) => this.props.loadedLocations.includes(l)))

    // Only load new locations if its a large org and we havent already loaded this location.
    if (handleFilterAsLargeOrg && selectedLocationsNotLoaded) {
      this.props.getDataByLocation(locationIds)
    }

    const time = Filter.getUserFilteredTime(
      this.props.data,
      this.props.allDepartmentIds,
      this.props.locationData,
      departments
    )
    this.props.timeChangeHandler(time)
    this.setState({ dataFilters: { ...this.state.dataFilters, departments } })
  }

  render(): React.Element<"div"> {
    const chartData = dataForWageTrackerChart(
      this.props.data,
      this.state.dataFilters.departments,
      this.props.time,
      this.props.canSeeCosts
    )
    return (
      <div className={styles.Root}>
        <Header
          allDepartmentIds={this.props.allDepartmentIds}
          canSeeCosts={this.props.canSeeCosts}
          dataFilters={this.state.dataFilters}
          dateViewing={this.props.dateViewing}
          handleDateChange={this.props.handleDateChange}
          handleTeamFilterOnChange={this.handleTeamFilterOnChange}
          headerText={t("js.dashboard.widgets.live_insights.header.live_wage_tracker")}
          hideWidget={this.props.hideWidget}
          isLargeOrg={this.props.isLargeOrg}
          isLiveInsights={this.props.isLiveInsights}
          isLoading={this.props.isLoading || this.props.isBackgroundLoading}
          loadedLocations={this.props.loadedLocations}
          locationData={this.props.locationData}
          metricType={this.props.metricType}
          saveConfigHandler={this.props.saveConfigHandler}
          selectedDepartments={this.state.dataFilters.departments}
          showSettingToggle={this.props.showSettingToggle}
        />
        {this.props.emptyLargeOrg && (
          <LargeOrgState messageText={t("js.dashboard.widgets.live_insights.large_org_state.message_text")} />
        )}
        {!this.props.emptyLargeOrg && (
          <div className={cn(styles.bodyContainer, "not-widget-draggable")}>
            <div className={styles.leftHandSideContainer}>
              <DaySoFarContainer canSeeCosts={this.props.canSeeCosts} data={chartData} time={this.props.time} />
              <TrackerChartController
                canSeeCosts={this.props.canSeeCosts}
                filterOptions={this.state.dataFilters.filterOptions}
                handleFilterSelectedChange={this.handleFilterSelectedChange}
                handleToggleRosterLine={this.handleToggleRosterLine}
                handleToggleSalesLine={this.handleToggleSalesLine}
                toggleRosteredLine={this.state.dataFilters.toggleRosteredLine}
                value={this.state.dataFilters.selected}
              />
            </div>
            <div className={styles.rightHandSideContainer}>
              <LiveWageTrackerChart
                bucketData={this.props.data}
                data={chartData}
                dataFilters={this.state.dataFilters}
                locationData={this.props.locationData}
                time={this.props.time}
                toggleRosteredLine={this.state.dataFilters.toggleRosteredLine}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}
