/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import { compose, createStore, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import Immutable from "immutable"

// should we add dev tools?
const applyDevTools =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  process.env.NODE_ENV !== "production" &&
  process.env.NODE_ENV !== "staging"

const composeEnhancers = applyDevTools
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ serialize: { immutable: Immutable } })
  : compose

export default (reducer, initialState, ...middleware) =>
  createStore(reducer, initialState, composeEnhancers(applyMiddleware(thunk, ...middleware)))
