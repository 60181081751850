// @flow
import * as Model from "../../models/model"
import * as State from "../../models/state"
import type { Action } from "../action"
import * as Utils from "./utils"

type Models = State.Partial<"models">

export default function models(state: Models, action: Action): Models {
  switch (action.type) {
    case "@patch/state":
      return Utils.merge(state, action.data.models)
    case "@create/model":
      return Utils.assoc(state, action.data)
    case "@update/model":
      return Utils.update(state, action.data)
    case "@patch/model":
      return Utils.patch(Model.patch, state, action.data)
    case "@delete/model":
      return Utils.dissoc(state, action.data)
    default:
      return state
  }
}
