// @flow
import * as React from "react"
import TextArea from "components/TextArea"

type Ev = SyntheticInputEvent<HTMLInputElement>

type Props = {|
  disabled: boolean,
  htmlName: string,
  onEdit: (Ev) => mixed,
  onSave: () => mixed,
  value: string,
|}

export default function LongTextInput(props: Props): React.Node {
  return (
    <TextArea
      disabled={props.disabled}
      htmlName={props.htmlName}
      minRows={1}
      onBlur={props.onSave}
      onChange={props.onEdit}
      value={props.value}
    />
  )
}
