/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { noop } from "lodash"
import * as Shift from "timesheets/models/shift"
import { t } from "helpers/i18n"

import styles from "./styles.module.scss"

export default class Placeholder extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleOnClick = this.handleOnClick.bind(this)
  }

  handleOnClick() {
    this.props.actions.shiftsUpdate({
      id: this.props.shift.get("id"),
      field: "is_collapsed",
      value: false,
    })
  }

  render() {
    // either the user's name (daily timesheets), or the weekday (weekly timesheets)
    const context =
      this.props.type === "USER_CARD"
        ? this.props.shift.get("user_name")
        : moment(this.props.shift.get("date")).format("dddd")

    // if not a new record, assume the user collapsed it
    const shiftWasCollapsedByUser = !Shift.hasMockedId(this.props.shift.get("id"))

    const msg = shiftWasCollapsedByUser
      ? t("js.timesheets.shift_card.collapsed.message_when_collapsed_by_user", { context })
      : t("js.timesheets.shift_card.collapsed.message", { context })

    return (
      <div className={styles.Placeholder} onClick={this.handleOnClick} onKeyPress={noop} role="button" tabIndex={-1}>
        {msg}
      </div>
    )
  }
}

Placeholder.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  shift: PropTypes.object.isRequired,
  type: PropTypes.oneOf(["DATE_CARD", "USER_CARD"]).isRequired,
}
