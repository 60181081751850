// @flow

// Weak any typing - I can't find precedent for a typed input event that uses .target.data
// .target.data doesn't exist on HTMLInputElement
// eslint-disable-next-line flowtype/no-weak-types
export function handleOnClick(event: any) {
  event.target.value = this.props.value
  event.target.data = this.props.data

  this.props.onClick(event)
}

/**
 * When Adding this handler to a custom option
 * component, please apply it to onMouseMove,
 * and only if it is not already hovered. It
 * avoids a bug where if the list is scrolled during
 * keyboard usage.
 */
export function handleMouseEnter(e: SyntheticInputEvent<HTMLElement>) {
  e.target.value = this.props.value
  this.setState({ isMouseOver: true })
  this.props.onMouseEnter(e)
}

export function handleMouseLeave(e: SyntheticInputEvent<HTMLElement>) {
  e.target.value = this.props.value

  this.setState({ isMouseOver: false })
  this.props.onMouseLeave(e)
}
