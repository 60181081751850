/* @flow */

import * as React from "react"
// $FlowFixMe immutable is definitely a module
import { Map } from "immutable"
import { t } from "helpers/i18n"
import * as TandaGQL from "helpers/gql"

import * as Shift from "timesheets/models/shift"
import * as Timesheet from "timesheets/models/timesheet"

import Button from "components/Button"
import PlatformRecordPreview from "./PlatformRecordPreview"

import styles from "./styles.module.scss"

type Props = {|
  appOpenPlatformModal: (shift_id: number) => void,
  platformModalShiftId: ?number,
  shift: typeof Map,
  showPlatform: boolean,
  timesheet: typeof Map,
|}

export default class PlatformFields extends React.PureComponent<Props> {
  handleOpenModal: () => void = () => {
    if (!this.isDisabled()) {
      this.props.appOpenPlatformModal(this.props.shift.get("id"))
    }
  }

  isNewRecord: () => boolean = () => Shift.hasMockedId(this.props.shift.get("id"))

  modelHasFieldsOrAssociations: () => boolean = () => this.props.showPlatform

  isDisabled: () => boolean = () =>
    !this.props.shift.get("platform_record_id") ||
    !this.props.shift.getIn(["permitted_actions", "configure"]) ||
    Shift.hasMockedId(this.props.shift.get("id"))

  renderOpenButton: () => React.Element<typeof Button> = () => (
    <Button
      iconLeft={Timesheet.isExported(this.props.timesheet) ? "visibility" : "add"}
      label={
        Timesheet.isExported(this.props.timesheet)
          ? t("js.timesheets.shift_card.platform_fields.view_button")
          : t("js.timesheets.shift_card.platform_fields.add_button")
      }
      onClick={this.handleOpenModal}
      size="xs"
      type="ghost"
    />
  )

  renderFields: () => React.Node = () => (
    <TandaGQL.Provider>
      <PlatformRecordPreview
        platformModalShiftId={this.props.platformModalShiftId}
        shiftId={this.props.shift.get("id")}
      />
    </TandaGQL.Provider>
  )

  render(): null | React.Element<"div"> {
    if (!this.modelHasFieldsOrAssociations()) {
      return null
    }
    if (this.isNewRecord()) {
      return null
    }
    if (this.isDisabled()) {
      return null
    }

    return (
      <div className={styles.PlatformFields}>
        <div className={styles.content}>
          <span className={styles.title}>{t("js.timesheets.shift_card.platform_fields.additional")}</span>
          <div className={styles.fields}>{this.renderFields()}</div>
          {this.isDisabled() ? null : <div className="ml1">{this.renderOpenButton()}</div>}
        </div>
      </div>
    )
  }
}
