// @flow

import * as React from "react"
import LargeInputLabel from "components/Label/LargeInputLabel"
import type { PillType } from "components/Pill"
import BaseInput, { type Props as BaseInputProps, DEFAULT_PROPS as BaseDefaultProps } from "./BaseInput"
import styles from "./styles.module.scss"

type RequiredProps = {|
  onChange: $PropertyType<BaseInputProps, "onChange">,
|}

type Props = {|
  ...RequiredProps,
  label: React.Node,
  pillText?: string,
  pillType?: PillType,
  ...BaseInputProps,
|}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  label: "",
  pillText: undefined,
  pillType: undefined,
  ...BaseDefaultProps,
}

export default class LargeInput extends React.PureComponent<Props> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  getLargeInputLabel(label: React.Node, pillText?: string, pillType?: PillType): null | React.Node {
    if (!label) {
      return null
    }

    return <LargeInputLabel pillText={pillText} pillType={pillType} text={label} />
  }

  render(): React.Element<"div"> {
    const { pillText, pillType, label, ...rest } = this.props
    return (
      <div className={styles.largeInput}>
        {this.getLargeInputLabel(label, pillText, pillType)}
        <BaseInput size="l" {...rest} />
      </div>
    )
  }
}
