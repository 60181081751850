/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

/* eslint react/prefer-stateless-function: off */
import * as React from "react"
import { t } from "helpers/i18n"

import styles from "./styles.module.scss"

export default class Header extends React.PureComponent {
  /**
   * Apparantly inputs behave differently to divs under flex. thus
   * we have to style inputs as labels to match the form.
   */
  render() {
    return (
      <div className={styles.Header}>
        <div className={styles.timeForm}>
          <input className={styles.clockTimeLabel} disabled value={t("js.timesheets.shift_list_header.start")} />
          <input className={styles.clockTimeLabel} disabled value={t("js.timesheets.shift_list_header.finish")} />
        </div>

        <input className={styles.clockBreaksLabel} disabled value={t("js.timesheets.shift_list_header.break_start")} />

        <input className={styles.clockBreaksLabel} disabled value={t("js.timesheets.shift_list_header.break_finish")} />

        <input className={styles.breaksLabel} disabled value={t("js.timesheets.shift_list_header.breaks")} />
      </div>
    )
  }
}
