// @flow

import * as React from "react"
import { flatten } from "lodash"
import cn from "classnames"
import Icon from "components/Icon/"
import { t as globalT } from "helpers/i18n"
import Loader from "../Loader"
import type { IconColor, IconSize } from "../Icon/index"
import styles from "./styles.module.scss"

const t = (key, opts) => globalT(`js.generic_components.expand_list.${key}`, opts)

type RequiredProps = {|
  children: Array<React.Node> | React.Node,
|}

type Props = {|
  ...RequiredProps,
  colour: IconColor,
  iconOnLeft?: boolean,
  iconSize: IconSize,
  iconTypeClosed: string,
  iconTypeOpen: string,
  includeHideView?: boolean,
  irreversible?: boolean,
  label?: string,
  loading?: boolean,
  noUnderline?: boolean,
  setIsOpen?: (boolean) => void,
  size: "s" | "l",
  startOpen?: boolean,
  submitted?: boolean,
  triggerOpen?: boolean,
|}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  colour: "primary",
  iconOnLeft: false,
  iconSize: "s",
  iconTypeOpen: "expand-less",
  iconTypeClosed: "expand-more",
  includeHideView: true,
  irreversible: false,
  label: "",
  loading: false,
  setIsOpen: undefined,
  noUnderline: false,
  size: "s",
  startOpen: false,
  submitted: false,
  triggerOpen: false,
}

export default function ExpandList(props: Props): React.Element<"div"> {
  const [open, setOpen] = React.useState(Boolean(props.startOpen))
  const children = Array.isArray(props.children) ? flatten(props.children) : props.children

  React.useEffect(() => {
    if (props.triggerOpen) {
      setOpen(true)
      props.setIsOpen ? props.setIsOpen(true) : null
    }
  }, [props.triggerOpen, props])

  const handleKeyUp = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setOpen(!open)
      props.setIsOpen ? props.setIsOpen(!open) : null
    }
  }

  const handleOpen = () => {
    setOpen(!open)
    props.setIsOpen ? props.setIsOpen(!open) : null
  }

  const renderChevron = () => {
    if (props.irreversible) {
      return <Icon color="invisible" size={props.iconSize} type={props.iconTypeOpen} />
    } else {
      return (
        <React.Fragment>
          {open ? (
            <Icon color={props.colour} size={props.iconSize} type={props.iconTypeOpen} />
          ) : (
            <Icon color={props.colour} size={props.iconSize} type={props.iconTypeClosed} />
          )}
        </React.Fragment>
      )
    }
  }

  const renderLoader = () => {
    if (!props.loading) {
      return null
    } else {
      return (
        <div className={styles.loader}>
          <Loader size={props.size} />
        </div>
      )
    }
  }

  return (
    <div>
      <div
        className={cn(
          styles.label,
          styles[props.colour],
          {
            [styles.no_underline]: props.noUnderline,
            [styles.reverse]: props.iconOnLeft,
            [styles.submitted]: props.submitted,
          },
          styles[props.size]
        )}
        onClick={() => !props.irreversible && handleOpen()}
        onKeyUp={handleKeyUp}
        role="button"
        tabIndex={0}
      >
        <span>{`${props.includeHideView ? (open ? t("hide") : t("view")) : ""}  ${props.label || ""}`}</span>
        {renderChevron()}
        {renderLoader()}
      </div>
      <div className={styles.list}>{open && children}</div>
    </div>
  )
}

ExpandList.defaultProps = DEFAULT_PROPS
