/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import { createActions, createReducer } from "redux-em"
import { List } from "immutable"

/**
 * requestRecord, will be used to keep a track of every request belonging
 * to a shift.
 *
 * shift requests are tracked by their corresponding shift id
 */

/**
 * @returns {Immutable.List} initial state
 */
const reqRecordInit = () => new List()

/**
 * @param {Store}  state of requestRecord
 * @param {number} id of a shift to be pushed onto the record
 * @returns {Immutable.List} next state
 */
const reqRecordPush = (state, { id }) => state.push(id)

/**
 * @param {Store}  state of requestRecord
 * @param {number} id of a shift to be removed from record
 * @returns {Immutable.List} next state
 */
const reqRecordDelete = (state, { id }) => {
  const i = state.lastIndexOf(id)

  if (i === -1) {
    return state
  }

  return state.delete(i)
}

export const Core = {
  reqRecordInit,
  reqRecordPush,
  reqRecordDelete,
}

export const reqRecordActions = createActions(Core)

export const reqRecordReducer = createReducer(Core)
