/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"

import { t as globalT } from "helpers/i18n"
import * as Timesheet from "timesheets/models/timesheet"
import * as Shift from "timesheets/models/shift"
import { AutoHoverBubble } from "components/Bubble"
import Icon from "components/Icon"

import AwardTagSelect from "./AwardTagSelect"
import DepartmentSelect from "./DepartmentSelect"
import styles from "./styles.module.scss"

const t = (key, opts) => globalT(`js.timesheets.shift_card.${key}`, opts)

export default class Config extends React.PureComponent {
  isDisabled() {
    return (
      !this.props.shift.getIn(["permitted_actions", "configure"]) ||
      Timesheet.isExported(this.props.timesheet) ||
      Shift.isApproved(this.props.shift)
    )
  }

  isMultiLocation() {
    return this.props.currentOrganisation.get("multi_location")
  }

  hasDepartments() {
    return this.props.shift.getIn(["user", "departments"]).size > 0
  }

  hasAwardTags() {
    return this.props.shift.getIn(["user", "award_tags"]).size > 0
  }

  showAssociatedTagNotification() {
    return (
      this.props.shift.get("start").isValid() &&
      this.props.shift.get("finish").isValid() &&
      this.props.shift.get("associated_tag") &&
      !this.props.shift.get("tag")
    )
  }

  usesAwardTags() {
    return this.props.currentOrganisation.get("uses_award_tags")
  }

  renderDepartmentTip() {
    return (
      <div className="relative">
        <AutoHoverBubble hovered={this.props.hovered} position="top" width="large">
          <span>
            {t("config.scheduled_department", {
              department: this.props.shift.get("scheduled_department"),
            })}
          </span>
        </AutoHoverBubble>
      </div>
    )
  }

  render() {
    return (
      <div className={styles.Config}>
        <div>
          {this.props.shift.get("department_conflict") ? this.renderDepartmentTip() : null}
          <DepartmentSelect
            actions={this.props.actions}
            departments={this.props.departments}
            disabled={this.isDisabled() || !this.hasDepartments()}
            multiLocation={this.isMultiLocation()}
            shift={this.props.shift}
            value={this.props.shift.get("department_id")}
          />
        </div>

        {this.usesAwardTags() && (
          <AwardTagSelect
            actions={this.props.actions}
            awardTags={this.props.awardTags}
            disabled={this.isDisabled() || !this.hasAwardTags()}
            shift={this.props.shift}
            value={this.props.shift.get("tag")}
          />
        )}
        {this.showAssociatedTagNotification() && (
          <div>
            <Icon color="black" size="s" type="info">
              <AutoHoverBubble offset={0.5} position="top" width="large">
                {t("associated_tag.applies", { tag_name: this.props.shift.get("associated_tag") })}
              </AutoHoverBubble>
            </Icon>
          </div>
        )}
      </div>
    )
  }
}

Config.defaultProps = { hovered: false }

Config.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  awardTags: PropTypes.object.isRequired,
  currentOrganisation: PropTypes.object.isRequired,
  departments: PropTypes.object.isRequired,
  shift: PropTypes.object.isRequired,
  timesheet: PropTypes.object.isRequired,
  hovered: PropTypes.bool,
}
