/* @flow */
import * as React from "react"
import moment from "moment"
import { allDepartmentIds } from "dashboard/live_insights/selectors"
import Constants from "./helpers/constants"
import LiveInsights from "./views/LiveInsights"
import type { StateType } from "./main"

export type MetricTypes = "splh" | "wage_percentage"

type PropType = {}

export default class MainDemo extends React.PureComponent<PropType, StateType> {
  constructor(props: PropType) {
    super(props)
    this.state = {
      averageOfDates: true,
      skipLiveRecache: false,
      canSeeCosts: true,
      isLiveInsights: true,
      metricType: "wage_percentage",
      date: moment(),
      dataStreamInfo: {},
      data: {
        actual_staff_data: [],
        org_current_time: "",
        rostered_staff_data: [],
        sales_data: [],
        predicted_sales_data: [],
      },
      locationData: null,
      loadedLocations: [],
      islargeOrg: false,
      interval: null,
      time: null,
      loading: true,
      recacheTimerRunning: false,
      recacheJobOngoing: false,
      backgroundLoading: false,
      error: false,
    }
  }

  componentDidMount() {
    new Promise((resolve) => resolve(import("./dummy"))).then((dummy) => {
      this.getDataAndDepartments(dummy.data, dummy.departments)
    })
  }

  getDataAndDepartments: (data: { [k: string]: * }, departments: Array<*>) => void = (data, departments): void => {
    this.setState({
      time: this.getBucketedMomentFromString(data.org_current_time),
      isLiveInsights: true,
      metricType: "wage_percentage",
      canSeeCosts: data.can_see_costs,
      dataStreamInfo: data.data_stream_info,
      data: {
        ...this.state.data,
        actual_staff_data: data.actual_staff_data,
        rostered_staff_data: data.rostered_staff_data,
        org_current_time: data.org_current_time,
        sales_data: data.sales_data,
        predicted_sales_data: data.predicted_sales_data,
      },
      error: false,
      loading: false,
      backgroundLoading: false,
      locationData: departments,
    })
  }

  getBucketedMomentFromString: (timeString: string) => moment$Moment = (timeString: string): moment => {
    const interval = Constants.BUCKET_INTERVAL // We want to break the time into 15 minute blocks
    const now = moment(timeString, Constants.DATE_TIME_FORMAT)
    const roundedMinutes = Math.floor(now.minute() / interval) * interval
    return now.minute(roundedMinutes).second(0)
  }

  saveConfigHandler: (isLiveInsights: boolean, metricType: ?MetricTypes) => void = (
    isLiveInsights: boolean,
    metricType: ?MetricTypes
  ): void => {
    if (metricType != null && metricType !== this.state.metricType) {
      this.setState({ metricType: metricType })
    }
    if (isLiveInsights !== this.state.isLiveInsights) {
      this.setState({ loading: true })
    }
  }

  timeChangeHandler: (timeString: string) => void = (timeString: string) =>
    this.setState({ time: this.getBucketedMomentFromString(timeString) })

  getDataByLocation: () => void = () => {}

  handleDateChange: () => void = () => {}

  render(): React.Node {
    return (
      <LiveInsights
        allDepartmentIds={allDepartmentIds(this.state.locationData)}
        averageOfDates={this.state.averageOfDates}
        canSeeCosts={this.state.canSeeCosts}
        data={this.state.data}
        dataStreamInfo={this.state.dataStreamInfo}
        dateViewing={this.state.date || moment()}
        emptyLargeOrg={false}
        getDataByLocation={this.getDataByLocation}
        handleDateChange={this.handleDateChange}
        hideWidget={null}
        interval={this.state.interval}
        isBackgroundLoading={this.state.backgroundLoading}
        isLargeOrg={false}
        isLiveInsights={this.state.isLiveInsights}
        isLoading={this.state.backgroundLoading}
        loadedLocations={this.state.loadedLocations}
        locationData={this.state.locationData}
        metricType={this.state.metricType}
        saveConfigHandler={this.saveConfigHandler}
        showSettingToggle={false}
        time={this.state.time}
        timeChangeHandler={this.timeChangeHandler}
      />
    )
  }
}
