/* @flow */
import moment from "moment"
import Currency from "helpers/currency"
import { t as globalT } from "helpers/i18n"
import type { unitTypes } from "dashboard/live_insights/main"
import type { Props } from "components/Text"

const t = (key: string, ...args) => globalT(`js.dashboard.widgets.live_insights.day_so_far.${key}`, ...args)

type ExportType = {
  formatToUnit: (value: number, unit: unitTypes, precision: ?number) => string,
  momentToBucketTime: (time: moment) => number,
  salesVarianceColor: (value: number, defaultColor: ?string) => string,
  varianceColor: (value: number, defaultColor: ?string) => string,
}

const formatToUnit = (value: number, unit: unitTypes, precision: ?number): string => {
  if (Number.isFinite(value)) {
    switch (unit) {
      case "currency":
        const default_precision = Math.abs(value) > 100 ? 0 : 2
        return precision != null ? Currency.from(value, precision) : Currency.from(value, default_precision)
      case "percentage":
        return precision != null
          ? Math.round(Math.pow(value, precision)) / Math.pow(10, precision - 1) + t("percent")
          : Math.round(value * 10) / 10 + t("percent")
      case "currencyPerHour":
        const currencyPerHourPrecision = Math.abs(value) > 10 ? 0 : 2
        const currency =
          precision != null ? Currency.from(value, precision) : Currency.from(value, currencyPerHourPrecision)
        return currency + t("hour")
      case "none":
      default:
        return String(value)
    }
  } else {
    return t("not_avail")
  }
}

const varianceColor = (value: number, defaultColor: ?$PropertyType<Props, "color">): $PropertyType<Props, "color"> => {
  if (value === 0) {
    return defaultColor != null ? defaultColor : "light"
  } else {
    return value < 0 ? "success" : "danger"
  }
}

const salesVarianceColor = (value: number, defaultColor: ?string): string => {
  if (value === 0) {
    return defaultColor != null ? defaultColor : "light"
  } else {
    return value < 0 ? "danger" : "success"
  }
}

const momentToBucketTime = (time: moment) => Math.floor(moment.duration(time.format("HH:mm")).asHours() * 4) / 4

const exportObject: ExportType = { formatToUnit, momentToBucketTime, varianceColor, salesVarianceColor }
export default exportObject
