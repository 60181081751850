/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import _ from "underscore" // eslint-disable-line underscore-to-lodash/prefer-import-lodash
import { CSSTransitionGroup } from "react-transition-group-legacy"
import cn from "classnames"
import Header from "./Header"
import styles from "./styles.module.scss"

const transitionStyle = {
  enter: styles.bodyEnter,
  enterActive: styles.bodyEnterActive,
  leave: styles.bodyLeave,
  leaveActive: styles.bodyLeaveActive,
}

export default class InfoAccordion extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: !props.start_hidden }
    this.onClick = this.onClick.bind(this)
  }

  onClick(e) {
    this.setState((prevState) => ({ open: !prevState.open }))
  }

  getBody() {
    if (this.state.open) {
      return this.props.children
    }
  }
  getChevron() {
    if (!this.props.showChevron) {
      return null
    }
    return this.state.open ? "chevron-up" : "chevron-down"
  }

  render() {
    return (
      <div className={cn(styles.infoAccordion, styles[this.props.color], styles[this.props.size])}>
        <Header
          buttonText={this.props.buttonText}
          icon={this.props.icon}
          onButtonClick={this.props.onButtonClick}
          onClick={this.onClick}
          rightSideIcon={this.getChevron()}
          subtitle={this.props.subtitle}
          title={this.props.title}
        />
        <CSSTransitionGroup transitionEnterTimeout={350} transitionLeaveTimeout={300} transitionName={transitionStyle}>
          {this.getBody()}
        </CSSTransitionGroup>
      </div>
    )
  }
}

InfoAccordion.propTypes = {
  buttonText: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string,
  onButtonClick: PropTypes.func,
  title: PropTypes.oneOfType([
    PropTypes.string, // title with plain text
    PropTypes.element, // custom title
  ]).isRequired,
  subtitle: PropTypes.string,
  showChevron: PropTypes.bool,
  start_hidden: PropTypes.bool,
  color: PropTypes.oneOf(["primary", "grey"]),
  size: PropTypes.oneOf(["s", "m"]),
}

InfoAccordion.defaultProps = {
  buttonText: null,
  children: null,
  icon: null,
  rightSideIcon: null,
  onButtonClick: _.noop,
  subtitle: null,
  showChevron: false,
  start_hidden: false,
  color: "grey",
  size: "m",
}
