// @flow

import * as React from "react"
import * as _ from "lodash"
import cn from "classnames"
import type { Option, SelectEvent, SimpleOptionType, Value, Options } from "components/Select"
import DefaultGroup from "./DefaultGroup/index"
import DefaultOption from "./DefaultOption/index"
import styles from "./styles.module.scss"

type RequiredProps = {|
  options: Options,
  selectedValues: Array<Value>,
|}

export type Props = {|
  ...RequiredProps,
  children?: React.Node | null,
  contextIcon: ?string,
  disabled: boolean,
  focusedValue: ?number | string,
  // eslint-disable-next-line flowtype/no-weak-types
  Group: React.ComponentType<any>,
  length: "xs" | "s" | "m" | "l",
  listAutoWidth: boolean,
  newStyle: boolean,
  onClick: (e: SelectEvent) => mixed,
  onOptionEnter: (e: SelectEvent) => mixed,
  onOptionLeave: (e: SelectEvent) => mixed,
  open: boolean,
  // eslint-disable-next-line flowtype/no-weak-types
  Option: React.ComponentType<any>,
  size: "s" | "m" | "l",
  testId: ?string,
|}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  newStyle: false,
  children: null,
  contextIcon: null,
  focusedValue: undefined,
  Group: DefaultGroup,
  length: "s",
  Option: DefaultOption,
  onOptionEnter: _.noop,
  onOptionLeave: _.noop,
  onClick: _.noop,
  open: false,
  size: "s",
  testId: null,
  disabled: false,
  listAutoWidth: false,
}

export default class DropdownList extends React.Component<Props> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  node: ?HTMLDivElement = null
  focused: ?HTMLDivElement = null

  handleWheel: (event: SyntheticWheelEvent<HTMLDivElement>) => void = (event: SyntheticWheelEvent<HTMLDivElement>) => {
    if (this.node) {
      const node = this.node
      const isAtTop = node.scrollTop === 0

      // prevent scroll above top
      if (event.deltaY < 0 && isAtTop) {
        event.preventDefault()
      }

      const isAtBottom = node.scrollHeight - node.offsetHeight - node.scrollTop <= 0

      // prevent scroll below bottom
      if (event.deltaY > 0 && isAtBottom) {
        event.preventDefault()
      }
    }
  }

  setFocusedReference: (node: HTMLDivElement) => void = (node: HTMLDivElement) => {
    this.focused = node
  }

  setReference: (node: HTMLDivElement | null) => void = (node: HTMLDivElement | null) => {
    this.node = node
  }

  renderOption(key: string, { label, value, data, icon }: Option | SimpleOptionType): React.Node {
    const values = Array.isArray(value) ? value : [value]
    const Option = this.props.Option

    return (
      <Option
        data={data}
        hovered={value === this.props.focusedValue}
        icon={icon || this.props.contextIcon}
        key={key}
        label={label}
        newStyle={this.props.newStyle}
        onClick={this.props.onClick}
        onMouseEnter={this.props.onOptionEnter}
        onMouseLeave={this.props.onOptionLeave}
        selected={values.length > 0 && values.every((v) => this.props.selectedValues.includes(v))}
        size={this.props.size}
        updateRef={value === this.props.focusedValue ? this.setFocusedReference : _.noop}
        value={value}
      />
    )
  }

  renderOptions(): Array<React.Node> {
    const Group = this.props.Group

    return this.props.options.map<React.Node, _>((group: Option) => {
      if (!Array.isArray(group.options)) {
        // group is actually an option
        return this.renderOption(`${group.label}-${String(group.value)}-option`, group)
      }
      const opts: $ReadOnlyArray<SimpleOptionType> = group.options
      const allChildrenSelected = (children) =>
        Array.isArray(children) && children.every((child) => this.props.selectedValues.includes(child))
      return (
        <Group
          data={group.data}
          hovered={group.value && group.value === this.props.focusedValue}
          key={`${group.label}-group`}
          label={group.label}
          onClick={this.props.onClick}
          onMouseEnter={this.props.onOptionEnter}
          onMouseLeave={this.props.onOptionLeave}
          selected={opts != null && allChildrenSelected(group.value)}
          size={this.props.size}
          updateRef={group.value && group.value === this.props.focusedValue ? this.setFocusedReference : _.noop}
          value={group.value}
        >
          {opts.map((option) => this.renderOption(`${group.label}-${option.label}-${String(option.value)}`, option))}
        </Group>
      )
    })
  }

  render(): React.Element<"div"> {
    return (
      <div
        className={cn(styles.dropdownList, styles[this.props.length], {
          [styles.dropdownClosed]: !this.props.open,
          [styles.newStyle]: this.props.newStyle,
          [styles.autoListWidth]: this.props.listAutoWidth,
        })}
        data-testid={this.props.testId}
        disabled={this.props.disabled}
        onWheel={this.handleWheel}
        ref={this.setReference}
      >
        {this.props.open && (
          <React.Fragment>
            {this.props.children}
            {this.renderOptions()}
          </React.Fragment>
        )}
      </div>
    )
  }
}
