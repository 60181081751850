/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import { t } from "helpers/i18n"

import ControlButton from "timesheets/components/ControlButton"

export default class HideButton extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleOnClick = this.handleOnClick.bind(this)
  }

  handleOnClick() {
    this.props.actions.shiftsUpdate({
      id: this.props.shift.get("id"),
      field: "is_collapsed",
      value: true,
    })
  }

  render() {
    return (
      <ControlButton
        icon="collapse"
        onClick={this.handleOnClick}
        tip={t("js.timesheets.shift_card.controls.collapse_shift")}
        tipPosition={this.props.tipPosition}
      />
    )
  }
}

HideButton.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  shift: PropTypes.object.isRequired,
  tipPosition: PropTypes.string.isRequired,
}
