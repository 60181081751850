/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import { createActions, createReducer } from "redux-em"
import { Map } from "immutable"

const reqBreakCacheInit = () => new Map()

/**
 * @param {Immutable.Map} state
 * @param {number} id of cache to update
 * @param {Object} params to add to cache
 * @returns {Immutble.Map} next state
 */
const reqBreakCachePush = (state, { shiftBreak, params }) =>
  state.update(shiftBreak.get("id"), new Map(), (map) =>
    map.merge({ shift_id: shiftBreak.get("shift_id") }).merge(params)
  )

/**
 * @param {Immutable.Map} state
 * @param {number} mockedId currently used on cache
 * @param {number} id to add to cache
 * @returns {Immutble.Map} next state
 */
const reqBreakCacheUpdate = (state, { mockedId, id }) => {
  const cached = state.get(mockedId)

  if (!cached) {
    return state
  }

  return state.delete(mockedId).set(id, cached)
}

/**
 * @param {Immutable.Map} state
 * @param {number} id of cache to delete
 * @returns {Immutble.Map} next state
 */
const reqBreakCacheDelete = (state, { id }) => state.delete(id)

export const Core = {
  reqBreakCacheInit,
  reqBreakCachePush,
  reqBreakCacheUpdate,
  reqBreakCacheDelete,
}

export const reqBreakCacheActions = createActions(Core)

export const reqBreakCacheReducer = createReducer(Core)
