/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

/**
 * Application Store
 *
 * holds the state of the app that should
 * not be tied to the URL
 */
import { createActions, createReducer } from "redux-em"
import { Map } from "immutable"

const appInit = () => INITIAL_STATE

const appStateAwaitingRequest = (currState) => currState.update("request_count", (i) => i + 1)

const appStateRequestResolved = (currState) => currState.update("request_count", (i) => i - 1)

const appOpenAllowanceModal = (currState, shiftId) => currState.set("allowanceModalId", shiftId)

const appCloseAllowanceModal = (currState) => currState.set("allowanceModalId", null)

const appOpenPlatformModal = (currState, shiftId) => currState.set("platformModalShiftId", shiftId)

const appClosePlatformModal = (currState) => currState.set("platformModalShiftId", null)

const appOpenLeaveModal = (currState, defaults) => currState.set("leaveModalDefaults", defaults)

const appCloseLeaveModal = (currState) => currState.set("leaveModalDefaults", null)

export const Core = {
  appInit,
  appStateAwaitingRequest,
  appStateRequestResolved,
  appOpenAllowanceModal,
  appOpenLeaveModal,
  appCloseAllowanceModal,
  appCloseLeaveModal,
  appOpenPlatformModal,
  appClosePlatformModal,
}

export const appReducer = createReducer(Core)

export const appActions = createActions(Core)

// -------------------------------- Helpers -------------------------------- //

export const INITIAL_STATE = new Map({
  allowanceModalId: null,
  platformModalShiftId: null,
  leaveModalDefaults: null,
  request_count: 0,
})
