/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import { t } from "helpers/i18n"

import * as Shift from "timesheets/models/shift"
import * as Timesheet from "timesheets/models/timesheet"
import * as User from "timesheets/models/user"
import * as TimeFormatter from "helpers/time_formatter"

import ApproveToggle from "timesheets/components/ApproveToggle"

export default class StatusToggle extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleStatusChange = this.handleStatusChange.bind(this)
  }

  handleStatusChange(isApproved) {
    this.props.handleStatusChange(isApproved, Shift.shiftVariance(this.props.shift))
  }

  shiftHasBlockingValidations() {
    const validations = this.props.timesheet.get("validations")
    if (!validations) return false

    const shiftValidation = validations.get(this.props.shift.get("id").toString())
    if (!shiftValidation) return false

    const shiftValidationResults = shiftValidation.get("results")
    return shiftValidationResults.some((validation) => validation.get("blocking") && !validation.get("passed"))
  }

  isDisabled() {
    return (
      this.props.shiftSyncing ||
      Timesheet.isExported(this.props.timesheet) ||
      !this.props.shift.getIn(["permitted_actions", "approve"]) ||
      (this.shiftHasBlockingValidations() && !Shift.isApproved(this.props.shift)) ||
      (!Shift.isApprovable(this.props.shift) && !Shift.isApproved(this.props.shift))
    )
  }

  renderStatus() {
    if (Shift.isApprovedAndAutoApproved(this.props.shift) && this.props.autoApproveShiftsEnabled) {
      return t("js.timesheets.shift_card.approve_toggle.auto_approved")
    }

    if (Shift.isApproved(this.props.shift)) {
      return t("js.timesheets.shift_card.approve_toggle.approved")
    }

    if (this.props.shiftVarianceApprovalRequired && Shift.shiftVariance(this.props.shift).hasVariance) {
      return t("js.timesheets.shift_card.approve_toggle.approve_variance")
    }

    return t("js.timesheets.shift_card.approve_toggle.approve")
  }

  renderTip() {
    if (this.props.shiftSyncing) {
      return t("js.timesheets.shift_card.syncing")
    }

    if (Timesheet.isExported(this.props.timesheet)) {
      return User.canUnlockTimesheet(this.props.currentUser)
        ? t("js.timesheets.shift_card.please_unlock")
        : t("js.timesheets.shift_card.is_locked")
    }

    if (this.props.shift.get("start_error") && !Shift.isApproved(this.props.shift)) {
      return t("js.timesheets.shift_card.errors.same_date_before", {
        time: TimeFormatter.shiftTimeInput(this.props.shift.getIn(["start_error", "altTime"]), this.props.currentUser),
      })
    }

    if (this.props.shift.get("finish_error") && !Shift.isApproved(this.props.shift)) {
      return t("js.timesheets.shift_card.errors.same_date_after", {
        time: TimeFormatter.shiftTimeInput(this.props.shift.getIn(["finish_error", "altTime"]), this.props.currentUser),
      })
    }

    if (this.props.shift.get("conflicting_approved_shift") && !Shift.isApproved(this.props.shift)) {
      return t("js.timesheets.shift_card.conflicting_approved_shift")
    }

    if (!Shift.isApprovable(this.props.shift) && !Shift.isApproved(this.props.shift)) {
      return t("js.timesheets.shift_card.must_have_times_including_breaks")
    }

    return ""
  }

  render() {
    if (!this.props.shift.getIn(["permitted_actions", "approve"])) {
      return null
    }

    return (
      <ApproveToggle
        applied={Shift.isApproved(this.props.shift)}
        autoApplied={Shift.isApprovedAndAutoApproved(this.props.shift) && this.props.autoApproveShiftsEnabled}
        disabled={this.isDisabled()}
        hovered={this.props.showTip}
        label={this.renderStatus()}
        onClick={this.handleStatusChange}
        tip={this.renderTip()}
        tipPosition="bottom"
      />
    )
  }
}

StatusToggle.defaultProps = { showTip: false }

StatusToggle.propTypes = {
  autoApproveShiftsEnabled: PropTypes.bool.isRequired,
  shiftSyncing: PropTypes.bool.isRequired,
  shiftVarianceApprovalRequired: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  shift: PropTypes.object.isRequired,
  showTip: PropTypes.bool,
  timesheet: PropTypes.object.isRequired,
  handleStatusChange: PropTypes.func.isRequired,
}
