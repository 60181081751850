/* @flow */
import * as React from "react"
import moment from "moment"
import _ from "lodash"
import { t as globalT } from "helpers/i18n"
import Text from "components/Text"
import type { StaffDataTypes } from "dashboard/live_insights/main"
import Constants from "dashboard/live_insights/helpers/constants"
import Formatter from "dashboard/live_insights/helpers/formatter"
import GraphLegendIcon from "dashboard/live_insights/components/GraphLegendIcon"
import styles from "./styles.module.scss"

const t: typeof globalT = (key, args) => globalT(`js.dashboard.widgets.live_insights.graph.toolTip.${key}`, args)

type PropType = {
  active: boolean,
  label: string,
  payload: Array<{
    color: string,
    dataKey: string,
    fill: string,
    name: string,
    payload: {
      name: ?string,
      [ket: string]: number,
    },
    stroke: ?string,
    strokeDasharray: ?string,
    strokeOpacity: ?number,
    strokeWidth: ?number,
    unit: ?string,
    value: number,
  }>,
  referenceTime: ?moment,
  selected: StaffDataTypes,
}

export default class CustomizedLabel extends React.PureComponent<PropType> {
  static defaultProps: {|
    active: boolean,
    label: string,
    payload: Array<{
      color: string,
      dataKey: string,
      fill: string,
      name: string,
      payload: {
        name: ?string,
        [ket: string]: number,
      },
      stroke: ?string,
      strokeDasharray: ?string,
      strokeOpacity: ?number,
      strokeWidth: ?number,
      unit: ?string,
      value: number,
    }>,
  |} = {
    payload: [],
    label: "",
    active: false,
  }

  renderColourBall: (color: string, isDotted?: boolean) => React.Element<"div"> = (
    color: string,
    isDotted: boolean = false
  ) => {
    const size = 5
    const width = size * 3
    const height = size
    const containerStyles = isDotted ? { paddingLeft: String(size) + "px" } : {}
    const lineStyles = isDotted
      ? { strokeDasharray: String(size) + "," + size, stroke: color, strokeWidth: height, opacity: "0.5" }
      : { stroke: color, strokeWidth: height }
    return (
      <div style={containerStyles}>
        <svg height={height} width={width}>
          <line style={lineStyles} x1="0" x2={width} y1={height / 2} y2={height / 2} />
        </svg>
      </div>
    )
  }

  render(): null | React.Element<"div"> {
    const unit = Constants.LIVE_INSIGHTS_GRAPH_ENTRIES[this.props.selected].actual.unit
    if (this.props.active && this.props.payload) {
      const momentTime: moment = moment(window.time_formatter.live_insights_date_time(this.props.label), [
        "YYYY-MM-DD h:mm A",
        "YYYY-MM-DD h:mm",
      ])
      const isAfterReferenceLine: boolean =
        this.props.referenceTime != null && momentTime.isAfter(this.props.referenceTime)
      const currentTimeBucket = window.time_formatter.live_feed_time(momentTime)
      const nextTimeBucket = window.time_formatter.live_feed_time(
        momentTime.clone().add(Constants.GRAPH_INTERVAL, "minutes")
      )
      const actualLine = _.find(this.props.payload, {
        name: Constants.LIVE_INSIGHTS_GRAPH_ENTRIES[this.props.selected].actual.key,
      })
      const rosteredLine = _.find(this.props.payload, {
        name: Constants.LIVE_INSIGHTS_GRAPH_ENTRIES[this.props.selected].rostered.key,
      })
      const salesLine = this.props.payload.find(
        (payload) => payload.name === Constants.LIVE_INSIGHTS_GRAPH_ENTRIES.sales.actual.key
      )
      const predictedSalesLine = this.props.payload.find(
        (payload) => payload.name === Constants.LIVE_INSIGHTS_GRAPH_ENTRIES.sales.rostered.key
      )
      const salesLinesToRender = _.compact([salesLine, predictedSalesLine])
      const linesToRender = _.compact([actualLine, rosteredLine])
      const totalVariance: ?number =
        actualLine != null && rosteredLine != null ? actualLine.value - rosteredLine.value : null
      const totalSalesVariance: ?number =
        salesLine != null && predictedSalesLine != null ? salesLine.value - predictedSalesLine.value : null
      return (
        <div className={styles.tooltipWrapper}>
          <div className={styles.tooltipContainer}>
            <div className={styles.tooltipTimeTextContainer}>
              <p>{`${currentTimeBucket} - ${nextTimeBucket}`}</p>
            </div>
            <div className={styles.tooltipLineLabelsContainer}>
              {linesToRender.map((line) => {
                const isActuals = line.name === Constants.LIVE_INSIGHTS_GRAPH_ENTRIES[this.props.selected].actual.key
                const graphInfo = isActuals
                  ? Constants.LIVE_INSIGHTS_GRAPH_ENTRIES[this.props.selected].actual
                  : Constants.LIVE_INSIGHTS_GRAPH_ENTRIES[this.props.selected].rostered
                const textColor = isActuals ? "light" : "lighter"
                return (
                  <div className={styles.tooltipLineContainer} key={line.name}>
                    <GraphLegendIcon chartType={graphInfo.chartType} color={graphInfo.color} />
                    <span className={styles.tooltipLineTextContainer}>
                      <Text color={textColor} type="small">{`${graphInfo.name}:\u00A0\u00A0`}</Text>
                      <Text color={textColor} type="small">
                        {Formatter.formatToUnit(line.value, graphInfo.unit)}
                      </Text>
                    </span>
                  </div>
                )
              })}
              {totalVariance != null && !isAfterReferenceLine && (
                <div className={styles.tooltipLineContainer}>
                  <GraphLegendIcon chartType={"none"} color={""} />
                  <span className={styles.tooltipLineTextContainer}>
                    <Text color="lightest" type="small">
                      {t("variance")}
                    </Text>
                    <Text color={Formatter.varianceColor(totalVariance, "lightest")} type="small">
                      {(totalVariance > 0 ? "+" : "") + Formatter.formatToUnit(totalVariance, unit)}
                    </Text>
                  </span>
                </div>
              )}
            </div>
            <div className={styles.tooltipLineSpacer} />
            <div className={styles.tooltipLineLabelsContainer}>
              {salesLinesToRender.map((line) => {
                const isActuals = line.name === Constants.LIVE_INSIGHTS_GRAPH_ENTRIES.sales.actual.key
                const graphInfo = isActuals
                  ? Constants.LIVE_INSIGHTS_GRAPH_ENTRIES.sales.actual
                  : Constants.LIVE_INSIGHTS_GRAPH_ENTRIES.sales.rostered
                const textColor = isActuals ? "light" : "lighter"
                return (
                  <div className={styles.tooltipLineContainer} key={line.name}>
                    <GraphLegendIcon chartType={graphInfo.chartType} color={graphInfo.color} />
                    <span className={styles.tooltipLineTextContainer}>
                      <Text color={textColor} type="small">{`${graphInfo.name}:\u00A0\u00A0`}</Text>
                      <Text color={textColor} type="small">
                        {Formatter.formatToUnit(line.value, graphInfo.unit)}
                      </Text>
                    </span>
                  </div>
                )
              })}
              {totalSalesVariance != null && !isAfterReferenceLine && (
                <div className={styles.tooltipLineContainer}>
                  <GraphLegendIcon chartType={"none"} color={""} />
                  <span className={styles.tooltipLineTextContainer}>
                    <Text color="lightest" type="small">
                      {t("variance")}
                    </Text>
                    <Text color={Formatter.salesVarianceColor(totalSalesVariance, "lightest")} type="small">
                      {(totalSalesVariance > 0 ? "+" : "") +
                        Formatter.formatToUnit(totalSalesVariance, Constants.SALES_VARIANCE_UNIT)}
                    </Text>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      )
    }
    return null
  }
}
