// @flow

import * as React from "react"
import cn from "classnames"
import Icon from "../Icon"
import Label from "../Label"
import styles from "./styles.module.scss"

type RequiredProps = {|
  checked: boolean,
  onClick: (e: { target: { value: boolean } }) => mixed,
|}

type Props = {|
  ...RequiredProps,
  autoMargin: boolean,
  disabled: boolean,
  hovered: boolean,
  htmlName: ?string,
  id: ?string,
  inverted: boolean,
  label: ?string,
  size: "l" | "m",
  tabIndex: number,
  testId?: ?string,
|}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  disabled: false,
  hovered: false,
  htmlName: null,
  inverted: false,
  label: null,
  size: "m",
  tabIndex: 0,
  id: null,
  autoMargin: true,
  testId: undefined,
}

export default class Checkbox extends React.PureComponent<Props> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  onClick: (e: { target: { name: ?string, value: boolean, ... }, ... }) => void = (e: {
    target: { name: ?string, value: boolean },
  }) => {
    // don't do anything if the component is disabled
    if (this.props.disabled) {
      return
    }
    // invert the current checked status, because that's the 'going to' status
    this.props.onClick({
      ...e,
      target: {
        ...e.target,
        name: this.props.htmlName,
        value: !this.props.checked,
      },
    })
  }

  onKeyUp: (e: SyntheticKeyboardEvent<HTMLDivElement>) => void = (e: SyntheticKeyboardEvent<HTMLDivElement>) => {
    if (e.key === " " || e.key === "Enter") {
      this.onClick({ target: { name: null, value: !this.props.checked } })
    }
  }

  wrapperClasses(): string {
    return cn(styles.wrapper, {
      [styles.hover]: this.props.hovered && !this.props.disabled,
      [styles.disabled]: this.props.disabled,
      [styles.inverted]: this.props.inverted && !this.props.disabled,
    })
  }

  tickClasses(): string {
    return cn(styles.tick, {
      [styles.large]: this.props.size === "l",
      [styles.checked]: this.props.checked,
      [styles.autoMargin]: this.props.autoMargin,
    })
  }

  getIcon(): React.Node {
    const hoverColor = this.props.hovered && this.props.inverted ? "primary" : "white"
    return <Icon color={hoverColor} size={this.props.size === "l" ? "s" : "xs"} type="done" />
  }

  render(): React.Element<"div"> {
    return (
      <div className={styles.labelContainer}>
        {this.props.label != null && <Label text={this.props.label} />}
        <div
          aria-checked={this.props.checked ? "true" : "false"}
          className={this.wrapperClasses()}
          data-testid={this.props.testId}
          id={this.props.id}
          onClick={this.onClick}
          onKeyUp={this.onKeyUp}
          role="checkbox"
          tabIndex={this.props.tabIndex}
        >
          <div className={this.tickClasses()}>{this.props.checked ? this.getIcon() : null}</div>
        </div>
      </div>
    )
  }
}
