/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { s3ImagePath } from "helpers/image"
import styles from "./styles.module.scss"

export default class LargeOrgState extends React.PureComponent {
  renderMessage(message) {
    if (!message) {
      return null
    }
    return message
  }

  render() {
    return (
      <div className={styles.State}>
        <div className={styles.LargeOrgState}>
          <div className={styles.StateContainer}>
            <img alt="empty" className={styles.Empty} src={s3ImagePath("dashboard_apply_filter.png")} />
          </div>
          <div className={cn(styles.MessageContainer, "m1")}>
            <span className={styles.EmptyMessage}>{this.renderMessage(this.props.messageText)}</span>
          </div>
        </div>
      </div>
    )
  }
}

LargeOrgState.propTypes = { messageText: PropTypes.string }
LargeOrgState.defaultProps = { messageText: "" }
