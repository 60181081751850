// @flow
import * as React from "react"

type E = { target: { value: number | string } }

export type IndexHook = [number, (E) => void]

export default function useIndex(initialIndex: number): IndexHook {
  const [index, set] = React.useState(initialIndex)

  const setIndex = React.useCallback((e: E) => {
    set(parseInt(e.target.value, 10))
  }, [])

  return [index, setIndex]
}
