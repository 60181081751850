/**
 * @flow
 */

import * as React from "react"
import styles from "./styles.module.scss"

type Props = {
  latitude: number,
  longitude: number,
  mapUrl: string,
}

class Map extends React.PureComponent<Props> {
  mapLinkUrl(): string {
    return "http://maps.google.com/maps?q=loc:" + this.props.latitude + "," + this.props.longitude
  }

  render(): React.Element<"div"> {
    return (
      <div className={styles.map}>
        <a href={this.mapLinkUrl()} rel="noopener noreferrer" target="_blank">
          <img alt="" src={this.props.mapUrl} />
        </a>
      </div>
    )
  }
}

export default Map
