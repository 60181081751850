// @flow
import * as React from "react"
import Input from "components/Input"

type Ev = SyntheticInputEvent<HTMLInputElement>

type Props = {|
  disabled: boolean,
  htmlName: string,
  onEdit: (Ev) => mixed,
  onSave: () => mixed,
  value: ?number,
|}

function formatFloat(v: string): ?number {
  return v !== "" ? parseFloat(v.replace(/[^\d.-]/, "")) : null
}

function formatValue(v: ?number): string {
  return v != null ? v.toString() : ""
}

export default function FloatInput(props: Props): React.Node {
  const [prev, setPrev] = React.useState(props.value)
  const [val, setVal] = React.useState(formatValue(props.value))

  if (props.value !== prev) {
    props.onSave()
    setVal(formatValue(props.value))
    setPrev(props.value)
  }

  const onChange = React.useCallback((e: Ev) => {
    // rules: should have only one period, one hyphen, and only digits
    const isValid = (e.target.value.match(/-/g) || []).length < 2 && (e.target.value.match(/\./g) || []).length < 2

    if (isValid) {
      setVal(e.target.value.replace(/[^\d.-]/, ""))
    }
  }, [])

  const onBlur = React.useCallback(
    (e) => {
      const v = formatFloat(val)
      // $FlowFixMe not a real synthetic event
      props.onEdit({
        target: {
          name: props.htmlName,
          value: isNaN(v) ? prev : v,
        },
      })
    },
    [prev, props, val]
  )

  return <Input disabled={props.disabled} htmlName={props.htmlName} onBlur={onBlur} onChange={onChange} value={val} />
}
