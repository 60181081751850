// @flow
import * as React from "react"
import absurd from "helpers/absurd"
import * as M from "helpers/marshall"
import * as Assoc from "../../models/association"
import * as Record from "../../models/record"
import ManyToManyFile from "./ManyToManyFile"
import HasOneFile from "./HasOneFile"

type Ev = SyntheticInputEvent<HTMLElement>

type Props = {|
  +assoc: Assoc.Schema,
  +loading: boolean,
  +onEdit: (Ev) => mixed,
  +onSave: () => mixed,
  +onUploadFinish: () => void,
  +onUploadStart: () => void,
  +record: Record.Schema,
  +value: mixed,
|}

// we need this because [] !== [] in JS
const EMPTY_ARRAY = []

export default function FileAssocInput(props: Props): React.Node {
  switch (props.assoc.type) {
    case "has_one":
      return (
        <HasOneFile
          assoc={props.assoc}
          loading={props.loading}
          onEdit={props.onEdit}
          onSave={props.onSave}
          onUploadFinish={props.onUploadFinish}
          onUploadStart={props.onUploadStart}
          record={props.record}
          value={M.maybeNumber(props.value)}
        />
      )
    case "many_to_many":
      return (
        <ManyToManyFile
          assoc={props.assoc}
          loading={props.loading}
          onEdit={props.onEdit}
          onSave={props.onSave}
          onUploadFinish={props.onUploadFinish}
          onUploadStart={props.onUploadStart}
          record={props.record}
          value={props.value ? M.array(props.value).map(M.numberOrString) : EMPTY_ARRAY}
        />
      )
    default:
      // $FlowFixMe type checking this as a valid route is a waste of time
      return absurd(props.assoc.type)
  }
}
