/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import Icon from "components/Icon"
import styles from "./styles.module.scss"

const Metric = ({ icon, label, popover, value, noMargin, rightBorder }) => (
  <div className={cn(styles.Metric, { [styles.noMargin]: noMargin }, { [styles.rightBorder]: rightBorder })}>
    <span className={styles.label}>{label}</span>
    <div className={styles.total}>
      <Icon color="grey" type={icon} />
      <span className={cn(styles.value, { [styles.noIcon]: icon === "none" })}>{value}</span>
    </div>
    {popover}
  </div>
)

Metric.defaultProps = { popover: null, noMargin: false, rightBorder: false }

Metric.propTypes = {
  rightBorder: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  popover: PropTypes.element,
  noMargin: PropTypes.bool,
}

export default Metric
