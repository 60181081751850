/* @flow */

/* the following files all must match:
/config/initializers/03_post_config_patches/tanda_asset_url.rb
/app/assets/webpack/helpers/image.js
/app/assets/javascripts/extras/s3_image_paths.js
/app/helpers/s3_image_helper.rb
*/

export const s3ImagePath = (filename: string): string =>
  `https://payauspics.s3-ap-southeast-2.amazonaws.com/web/${filename}`

export const s3SvgPath = (filename: string): string =>
  `https://payauspics.s3-ap-southeast-2.amazonaws.com/web/svg/${filename}`
