/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { map } from "lodash"
import cn from "classnames"
import * as Routes from "helpers/routes"

import HoverIcon from "components/HoverIcon"
import HolidayIcon from "components/HolidayIcon"
import * as User from "timesheets/models/user"
import * as Shift from "timesheets/models/shift"
import Currency from "helpers/currency"
import * as Time from "helpers/time/interpret"
import { t as globalT } from "helpers/i18n"

import ControlButton from "timesheets/components/ControlButton"
import Chart from "../ShiftCard/Chart"

import styles from "./styles.module.scss"

const t = (key, values) => globalT(`js.timesheets.leave_card.${key}`, values)

const validAutofillOrigins = [
  "roster",
  "regular_hours",
  "unpublished_roster",
  "roster_dirty",
  "unpublished_roster_dirty",
  "regular_hours_dirty",
]

const rosterAutofillOrigins = ["roster", "unpublished_roster", "roster_dirty", "unpublished_roster_dirty"]

const getAutofillOriginTranslationKey = {
  roster: "roster",
  unpublished_roster: "roster",
  roster_dirty: "roster",
  unpublished_roster_dirty: "roster",
  regular_hours: "regular_hours",
  regular_hours_dirty: "regular_hours",
}

export default class LeaveCard extends React.PureComponent {
  allLeaveShiftsRostersAreGone(leaveRequestId) {
    // If all leave shifts's rosters have been "vacated" or "deleted" (or reallocated/deleted manually)
    return this.props.shifts.every(
      (shift) =>
        shift.get("leave_request_id") !== leaveRequestId ||
        (!shift.get("rostered_start").isValid() && !shift.get("rostered_end").isValid())
    )
  }

  manuallyEditedWarning(filled_from) {
    return (
      <div className={styles.mismatch}>
        <div className={styles.manuallyEditedWarning}>
          {t("manually_edited_warning", {
            autofill: t(`autofill_origin.${getAutofillOriginTranslationKey[filled_from]}`),
          })}
        </div>
        <div className={styles.mismatchContent}>
          {t("manually_edited_content", {
            autofill: t(`autofill_origin.${getAutofillOriginTranslationKey[filled_from]}`),
            parent: t(`autofill_origin_container.${getAutofillOriginTranslationKey[filled_from]}`),
          })}
        </div>
      </div>
    )
  }

  mismatchWarning(filled_from) {
    return (
      <div className={styles.mismatch}>
        <div className={styles.mismatchWarning}>
          {t("mismatch_warning", { autofill: t(`autofill_origin.${getAutofillOriginTranslationKey[filled_from]}`) })}
        </div>
        <div className={styles.mismatchContent}>
          {t("mismatch_content", {
            autofill: t(`autofill_origin.${getAutofillOriginTranslationKey[filled_from]}`),
            parent: t(`autofill_origin_container.${getAutofillOriginTranslationKey[filled_from]}`),
          })}
        </div>
      </div>
    )
  }

  renderAutofillMismatch() {
    const filled_from = this.props.shift.get("leave_filled_from")
    if (validAutofillOrigins.includes(filled_from)) {
      if (
        rosterAutofillOrigins.includes(filled_from) &&
        this.allLeaveShiftsRostersAreGone(this.props.shift.get("leave_request_id"))
      ) {
        return null
      }

      let regularStart
      let regularFinish
      // Shifts may be all day leave shift summaries, so may need the date instead
      const leave_start = this.props.shift.get("start").isValid()
        ? this.props.shift.get("start")
        : moment(this.props.shift.get("date"))
      const leave_finish = this.props.shift.get("finish").isValid()
        ? this.props.shift.get("finish")
        : moment(this.props.shift.get("date"))

      // The regular hours here are only the regular hours for the day this shift is on.
      const regular_hours = this.props.shift.get("regular_hours")
      if (["regular_hours", "regular_hours_dirty"].includes(filled_from)) {
        if (
          // If no regular hours on this day which was filled from regular hours,
          // and the shift is all-day, 0 hours long, then it matches despite having
          // "bad" data. Short circuit here for null rhows
          regular_hours == null &&
          !this.props.shift.get("start").isValid() &&
          !this.props.shift.get("finish").isValid() &&
          this.props.shift.get("shift_length") === 0
        ) {
          return null
        } else if (regular_hours == null) {
          // Rhow was null, but the shift actually has data on it. Show the warning.
          return filled_from === "regular_hours"
            ? this.mismatchWarning(filled_from)
            : this.manuallyEditedWarning(filled_from)
        }
        const rhows = map([...regular_hours], (rhw) => ({
          start: rhw.get("start"),
          finish: rhw.get("finish"),
        }))

        rhows.forEach((rhow) => {
          const startTime = moment(rhow.start, "HH:mm")
          const finishTime = moment(rhow.finish, "HH:mm")

          const rhowStart = leave_start.clone()
          const rhowFinish = leave_finish.clone()

          // Gross moment mutations which set rhowStart and Finish to the dates of the shift,
          // but the times of the RHOW
          rhowStart.hour(startTime.get("hour"))
          rhowStart.minute(startTime.get("minute"))

          rhowFinish.hour(finishTime.get("hour"))
          rhowFinish.minute(finishTime.get("minute"))

          // If there's one rhow shift that matches this one, we don't have a mismatch
          // so assign the matching one to the origin times for comparing below - we know it will be ok
          if (rhowFinish.isSame(leave_finish) && rhowStart.isSame(leave_start)) {
            regularStart = rhowStart
            regularFinish = rhowFinish
          }
        })

        // None matched, so this is a mismatch. Use the first rhow shift for comparisons below
        if (regularStart == null && regularFinish == null) {
          const startTime = moment(rhows[0].start, "HH:mm")
          const finishTime = moment(rhows[0].finish, "HH:mm")

          regularStart = leave_start.clone()
          regularFinish = leave_finish.clone()

          regularStart.hour(startTime.get("hour"))
          regularStart.minute(startTime.get("minute"))

          regularFinish.hour(finishTime.get("hour"))
          regularFinish.minute(finishTime.get("minute"))
        }
      } else {
        // Wasn't regular hours, so myst have been filled from a roster
        if (
          // If no hours on this day which was filled from roster,
          // and the shift is all-day, 0 hours long, then it matches despite having
          // "bad" data. Short circuit here
          !this.props.shift.get("start").isValid() &&
          !this.props.shift.get("finish").isValid() &&
          (this.props.shift.get("rostered_start") == null || !this.props.shift.get("rostered_start").isValid()) &&
          (this.props.shift.get("rostered_finish") == null || !this.props.shift.get("rostered_finish").isValid()) &&
          this.props.shift.get("shift_length") === 0
        ) {
          return null
        }
      }

      const origin_start = regularStart == null ? this.props.shift.get("rostered_start") : regularStart
      const origin_finish = regularFinish == null ? this.props.shift.get("rostered_end") : regularFinish

      if (
        this.props.timesheet.get("status") !== "exported" &&
        origin_start != null &&
        origin_finish != null &&
        (leave_start.isValid() || origin_start.isValid()) &&
        (leave_finish.isValid() || origin_finish.isValid()) &&
        (!origin_finish.isSame(leave_finish) || !origin_start.isSame(leave_start))
      ) {
        return filled_from.includes("dirty")
          ? this.manuallyEditedWarning(filled_from)
          : this.mismatchWarning(filled_from)
      }
    }
    return null
  }

  renderMessage() {
    if (!User.canCreateLeave(this.props.currentUser)) {
      return (
        <div className={styles.descriptionContainer}>
          <span className={cn(styles.descriptionTitle, styles[this.props.shift.get("status")])}>
            {this.props.context} - {this.props.shift.getIn(["leave_request", "leave_type"]) || t("no_leave_type")}
          </span>
          <HolidayIcon
            date={moment(this.props.shift.get("date"))}
            size={"s"}
            userId={this.props.shift.get("user_id")}
          />
        </div>
      )
    }

    const link_params = {
      finish_date: this.props.shift.getIn(["leave_request", "finish"]),
      user_id: this.props.shift.get("user_id"),
      start_date: this.props.shift.getIn(["leave_request", "start"]),
      date_range: "custom",
    }

    const link =
      this.props.shift.get("status").toLowerCase() === "approved"
        ? Routes.approved_leave_requests_path(link_params)
        : Routes.leave_requests_path(link_params)

    return (
      <div className={styles.descriptionContainer}>
        <a
          className={cn(styles.descriptionTitle, styles[this.props.shift.get("status")])}
          href={link}
          rel="noopener noreferrer"
          target="_blank"
        >
          {this.props.context} - {this.props.shift.getIn(["leave_request", "leave_type"]) || t("no_leave_type")}
        </a>
        <HolidayIcon date={moment(this.props.shift.get("date"))} size={"s"} userId={this.props.shift.get("user_id")} />
      </div>
    )
  }

  shouldRenderCost(costType) {
    return this.props.shift.get(costType) && User.canSeeCostsOnTimesheet(this.props.timesheet)
  }

  shouldRenderPendingCost() {
    return (
      Shift.isApproved(this.props.shift) &&
      Shift.isNewRecord(this.props.shift) &&
      !this.shouldRenderCost(CostType.SHIFT_COST) &&
      !this.shouldRenderCost(CostType.ROSTER_COST)
    )
  }

  shouldRenderHours() {
    // Even if the shift itself is 0 hours, we still want to display its hours as long as the entire LR has some hours
    return Shift.isApproved(this.props.shift) && this.props.shift.getIn(["leave_request", "hours"], 0)
  }

  shouldRenderDays() {
    return (
      Shift.isApproved(this.props.shift) &&
      this.props.shift.getIn(["leave_request", "hours"], 0) &&
      this.props.shift.getIn(["leave_in_days"], false)
    )
  }

  renderHours() {
    return Time.toHoursAndMins(this.props.shift.getIn(["shift_length"], 0) * 60, true)
  }

  renderDays() {
    return this.props.shift.getIn(["shift_length"], 0) / this.props.shift.getIn(["average_day_of_leave"], 0)
  }

  addShiftHandler = () => {
    const {
      actions: { shiftsCreate },
      timesheet,
      shift,
      users,
    } = this.props
    const userId = timesheet.get("user_id")
    const userName = users.get(userId).get("name")
    const shiftDate = shift.get("date")
    const rosteredEnd = shift.get("rostered_end")
    const rosteredStart = shift.get("rostered_start")

    shiftsCreate({
      date: shiftDate,
      rostered_end: rosteredEnd,
      rostered_start: rosteredStart,
      user_id: userId,
      user_name: userName,
    })
  }

  renderChart = () => {
    const { shift, colors, currentUser } = this.props
    const penaltyLeave = shift.get("start") && Shift.hasValidTimes(shift)

    return (
      penaltyLeave && (
        <span className={styles.chart}>
          <Chart colors={colors} currentUser={currentUser} shift={shift} />
        </span>
      )
    )
  }

  renderAveragingTip() {
    return (
      <div>
        <div>{t("requires_averaging")}</div>
      </div>
    )
  }

  render() {
    return (
      <div className={styles.LeaveCard}>
        <div className={cn(styles.highlight, styles[this.props.shift.get("status")])} />
        <div className={styles.description}>
          {this.renderMessage()}

          <span className={styles.descriptionInfo}>
            {this.props.shift.get("status") !== "APPROVED" ? (
              <p className={cn(styles.descriptionInfo, styles[this.props.shift.get("status")])}>
                {this.props.shift.get("status")}: {t("pending_leave")}
              </p>
            ) : (
              <p>{this.props.shift.getIn(["leave_request", "reason"])}</p>
            )}
          </span>
          {this.renderChart()}

          <div className={styles.breakdown}>
            {this.shouldRenderHours() ? (
              <BreakdownItem title={t("leave_hours_label")} value={this.renderHours()} />
            ) : null}
            {this.shouldRenderDays() ? <BreakdownItem title={t("leave_days_label")} value={this.renderDays()} /> : null}
            {this.shouldRenderCost(CostType.SHIFT_COST) ? (
              <BreakdownItem
                title={t("leave_cost_label")}
                value={Currency.from(
                  this.props.shift.get("awarded_cost") + this.props.shift.get("auto_allowance_cost"),
                  2
                )}
              />
            ) : null}
            {this.shouldRenderCost(CostType.ROSTER_COST) ? (
              <BreakdownItem
                title={t("rostered_cost_label")}
                value={Currency.from(this.props.shift.get("rostered_cost"), 2)}
              />
            ) : null}
            {this.shouldRenderPendingCost() ? (
              <div className={styles.breakdownItem}>
                <span className={styles.breakdownItemIsEmpty}>{t("pending_cost_label")}</span>
              </div>
            ) : null}
            {this.props.requiresAveraging && <HoverIcon tip={this.renderAveragingTip()} type="warning" width="large" />}
          </div>

          {this.renderAutofillMismatch()}
        </div>
        <div className={styles.actions}>
          <ControlButton icon="add" onClick={this.addShiftHandler} tip={t("add_shift_label")} tipPosition="top" />
        </div>
      </div>
    )
  }
}

LeaveCard.propTypes = {
  context: PropTypes.string.isRequired,
  shift: PropTypes.object.isRequired,
  shifts: PropTypes.object.isRequired,
  colors: PropTypes.object.isRequired,
  timesheet: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  requiresAveraging: PropTypes.bool,
}

LeaveCard.defaultProps = {
  requiresAveraging: false,
}

/**
 * helpers
 */

const CostType = {
  ROSTER_COST: "rostered_cost",
  SHIFT_COST: "awarded_cost",
}

const BreakdownItem = ({ title, value }) => (
  <div className={styles.breakdownItem}>
    <span className={styles.breakdownItemTitle}>{title}</span>
    <span className={styles.breakdownItemValue}>{value}</span>
  </div>
)

BreakdownItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}
