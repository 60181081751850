/* @flow */
import * as React from "react"
import * as State from "../platform/models/state"
import * as Store from "../platform/store"
import Empty from "../platform/view/empty"
import Gate from "../platform/view/gate"
import PlatformRecordFields from "./PlatformRecordFields"

export type PropsType = {
  model_id: string,
  record_id: string,
}

export default function UserQualificationPlatformRecord({
  model_id,
  record_id,
}: PropsType): null | React.Element<"div"> {
  if (!model_id || !record_id) {
    return null
  }

  return (
    <div>
      <Store.Provider state={State.create()}>
        <Gate.Record
          failure={<Empty.Failure />}
          loading={<Empty.Loading />}
          modelId={model_id.toString()}
          recordId={record_id.toString()}
        >
          <PlatformRecordFields modelId={model_id.toString()} recordId={record_id.toString()} />
        </Gate.Record>
      </Store.Provider>
    </div>
  )
}
