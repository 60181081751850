/* @flow */

import _ from "lodash"

export const getInitials = (string: string, num?: number): string => {
  const split = string.split(/\s+/)
  const initials = _.compact(
    split.map((w) => {
      const match = w.match(/[\w]/)

      if (!match) {
        return null
      }
      return match[0].toUpperCase()
    })
  )
    .slice(0, num || split.length)
    .join("")

  if (initials.length) {
    return initials
  } else {
    return split
      .slice(0, num || split.length)
      .map((w) => w[0])
      .join("")
  }
}

export const capitalise = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1)

export const trimWhite = (string: string): string => string.split(" ").join("")

// Taken from employee/src/util/users.js
export const getFirstName = (fullName: string): string => {
  if (fullName.includes(", ")) {
    const split = _.compact(fullName.split(", "))

    // LASTNAME, Firstname
    if (split.length > 1) {
      return split[1] // Separate where comma and keep text after comma
        .split(" ")[0] // Account for middle names being there
    }
    return split[0]
  }
  // Firstname Lastname and all other combos
  return _.compact(fullName.split(" "))[0]
}
