// @flow

import * as React from "react"
import cn from "classnames"
import _ from "lodash"
import styles from "./style.module.scss"

// Please use the listed types. Miscellaneous string will fallback to "theme"
export type NoticeType = "theme" | "primary" | "success" | "action" | "warning" | "danger" | "magic" | "info" | string

type Props = {|
  button: React.Node,
  children: React.Node,
  dismissAfter: number,
  iconType: string,
  isDismissable: boolean,
  isFullWidth: boolean,
  text: string,
  title: string,
  type: NoticeType,
|}

export const DEFAULT_PROPS = {
  iconType: "",
  type: "theme",
  text: "",
  children: null,
  button: null,
  isDismissable: false,
  dismissAfter: -1,
  isFullWidth: true,
}

// Used to migrate old types to new types
const typeMap = {
  primary: "theme",
  theme: "theme",
  info: "theme",
  success: "action",
  action: "action",
  warning: "warning",
  danger: "danger",
  magic: "magic",
}

const Notice = ({
  type = "theme",
  text,
  title,
  iconType,
  button = null,
  children,
  isDismissable = false,
  isFullWidth = true,
  dismissAfter,
}: Props): React.Node => {
  const [applyAnimation, setApplyAnimation] = React.useState(false)
  const [isDismissed, setIsDismissed] = React.useState(false)
  const dismissNotice = () => setIsDismissed(true)
  const hasChildren = !!children
  const adjustedType = typeMap[type]

  React.useEffect(() => {
    let timerTilFadeOut
    let timerDuringFadeOut
    if (dismissAfter > 0) {
      timerTilFadeOut = setTimeout(() => {
        setApplyAnimation(true)
      }, dismissAfter)
      timerDuringFadeOut = setTimeout(() => {
        setIsDismissed(true)
      }, dismissAfter + 2000)
    }

    return () => {
      clearTimeout(timerTilFadeOut)
      clearTimeout(timerDuringFadeOut)
    }
  }, [dismissAfter])

  if (isDismissed) {
    return null
  }

  const titleId = _.uniqueId("notice")

  const severity = adjustedType === "danger" ? "alert" : "status"

  return (
    <div
      aria-labelledby={titleId}
      className={cn(
        `notice-${adjustedType}`,
        { w100: isFullWidth },
        { [styles.fadeOut]: applyAnimation && dismissAfter > 0 },
        "ds"
      )}
      role={severity}
      tabIndex="-1"
    >
      <div className={cn("notice-wrapper", { "with-content": hasChildren })}>
        {iconType && <i className={`mi mi-${iconType} main-icon`} />}
        <div className="text-and-button-wrapper">
          <div className="notice-content">
            {title && (
              <strong className="inline-block" id={titleId}>
                {title}
              </strong>
            )}
            {text && <p className="inline-block">{text}</p>}
            {hasChildren && <div className="notice-child-content">{children}</div>}
          </div>
          {isDismissable ? (
            <button className="btn-dismiss" onClick={dismissNotice}>
              <i className="mi mi-close" />
            </button>
          ) : (
            button
          )}
        </div>
      </div>
    </div>
  )
}

Notice.defaultProps = DEFAULT_PROPS

export default Notice
