/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import { fromJS } from "immutable"
import moment from "moment"
import { compose, pairs } from "underscore" // eslint-disable-line underscore-to-lodash/prefer-import-lodash
import { GUID } from "helpers/function"

import * as Shift from "timesheets/models/shift"

/**
 * applies defaults where missing
 * @param {Object} shift date to parse
 */
export const applyDefaults = (shift) => {
  const addDefaultsTo = (shift, [key, val]) => {
    // must have at least one break with default values
    if (key === Shift.BreaksFieldName) {
      if (!shift.breaks || !shift.breaks.length) {
        shift.breaks = [{}]
      }
      return {
        ...shift,
        [Shift.BreaksFieldName]: shift.breaks.map(applyBreakDefaults),
      }
    } else {
      return {
        ...shift,
        [key]: key in shift ? shift[key] : val,
      }
    }
  }

  return pairs(Shift.DefaultValues).reduce(addDefaultsTo, shift)
}

export const applyBreakDefaults = (sb) => {
  const addDefaultsTo = (sb, [key, val]) => ({
    ...sb,
    [key]: key in sb ? sb[key] : val,
  })

  return pairs(Shift.BreakDefaultValues).reduce(addDefaultsTo, sb)
}

export const sortBreaks = (shift) =>
  shift.set(Shift.BreaksFieldName, shift.get(Shift.BreaksFieldName).sort(Shift.breakSorter))

/**
 * parses time fields to moments
 * @param {Object} shift date to parse
 */
export const momentize = (shift) => {
  const momentizeFieldsOf = (shift, [key, val]) => {
    // recursively momentize each break
    if (key === Shift.BreaksFieldName) {
      return {
        ...shift,
        [Shift.BreaksFieldName]: val.map(momentize),
      }
    } else {
      return {
        ...shift,
        [key]: Shift.MomentFields.indexOf(key) !== -1 ? moment(val || null) : val,
      }
    }
  }

  return pairs(shift).reduce(momentizeFieldsOf, {})
}

/**
 * sets errors fields
 * @param {Object} shift date to parse
 */
export const setErrorFields = (shift) => {
  Shift.TimeFields.forEach((field) => {
    shift[`${field}_error`] = shift[`${field}_error`] || null
    shift[`break_${field}_error`] = shift[`break_${field}_error`] || null
  })

  return shift
}

export const setGUID = (shift) => {
  if (!shift.guid) {
    shift.guid = GUID.new()
  }

  return shift
}

export const addMockIDsAndShiftIDsToBreaks = (shift) => {
  shift.breaks.forEach((sb, index) => {
    sb.shift_id = sb.shift_id || shift.id
    sb.id = sb.id || -(index + 1)
    sb.guid = sb.guid || GUID.new()
  })
  return shift
}

export const setIsCollapsed = (shift) =>
  shift.get("is_collapsed") === undefined ? shift.set("is_collapsed", Shift.shouldShowCollapsedOnLoad(shift)) : shift

export const build = (shift = {}) => {
  if (isNaN(shift.id) || !shift.date) {
    return console.error("a shift must have at minimum a date and id (can be mocked)")
  }

  return compose(
    setIsCollapsed,
    sortBreaks,
    fromJS,
    addMockIDsAndShiftIDsToBreaks,
    setGUID,
    setErrorFields,
    momentize,
    applyDefaults
  )(shift)
}

export const buildBreak = (sb) => {
  if (isNaN(sb.id) || isNaN(sb.shift_id)) {
    return console.error("a shiftBreak must have at minimum an ID and shift ID (can be mocked)")
  }

  return compose(fromJS, setGUID, momentize, applyBreakDefaults)(sb)
}
