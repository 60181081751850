// @flow

type Hash<T: Item> = { +[id: string]: T }
type Item = { +id: string }

export function assoc<T: Item>(state: Hash<T>, item: T): Hash<T> {
  return { ...state, [item.id]: item }
}

export function merge<T>(curr: T, next: T): T {
  return { ...curr, ...next }
}

export function update<T: Item>(state: Hash<T>, item: T): Hash<T> {
  return { ...state, [item.id]: item }
}

export function dissoc<T: Item>(state: Hash<T>, item: T): Hash<T> {
  // eslint-disable-next-line no-unused-vars
  const { [item.id]: _, ...next } = state

  // $FlowFixMe this is fine
  return (next: Hash<T>)
}

export function patch<T: Item>(mergeFn: (a: T, b: T) => T, state: Hash<T>, item: T): Hash<T> {
  return { ...state, [item.id]: mergeFn(state[item.id], item) }
}
