// @flow
import * as React from "react"

type E = SyntheticInputEvent<Element>

export type FormHook<T> = [T, (E | customObject) => void, (T) => void]

type customObject = {
  target: {
    checked?: mixed,
    name: string,
    type?: mixed,
    value: mixed,
  },
}

export default function useForm<T>(entity: T): FormHook<T> {
  const [prev, setPrev] = React.useState(entity)
  const [curr, setCurr] = React.useState(entity)

  if (prev !== entity) {
    setPrev(entity)
    setCurr(entity)
  }

  const setField = React.useCallback(
    (e: E | customObject): void => {
      setCurr({ ...curr, [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value })
    },
    [curr, setCurr]
  )

  const setForm = React.useCallback(
    (form: T): void => {
      setCurr({ ...curr, ...form })
    },
    [curr, setCurr]
  )

  return [curr, setField, setForm]
}
