/* @flow */

/**
 * `type` prop should match https://material.io/tools/icons/ names
 * if the icon doesn't show you'll have to add it to app/assets/stylesheets/atoms/material_icons.scss
 **/

import * as React from "react"
import cn from "classnames"
import styles from "./styles.module.scss"
import SVGIcon from "./SVGIcons"

// 'small' 'tiny' and 'body' mirror the sizes of our <Text> component
// this lets you do <Text type="body" /> <Icon size="body" /> and know with certainty they will be the same size.
export type IconSize = "xs" | "s" | "m" | "ml" | "l" | "xl" | "small" | "tiny" | "body"
export type IconColor =
  | "white"
  | "grey"
  | "text"
  | "light"
  | "lighter"
  | "lightest"
  | "black"
  | "primary"
  | "success"
  | "warning"
  | "danger"
  | "disabled"
  | "transparent_black"
  | "invisible"
  | "overnight"
  | "gold"
  | "charcoal"
  | "inherit"
  | "primary_dark"
  | "success_dark"
type RequiredProps = {|
  type: string,
|}
export type Props = {|
  ...RequiredProps,
  bold?: boolean,
  children: React.Node,
  color: IconColor,
  customColor: ?string,
  forceOverflowVisible?: boolean,
  padding: string,
  size: IconSize,
  testId?: ?string,
|}

export const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  children: null,
  bold: false,
  customColor: null,
  padding: "0 0 0 0",
  size: "s",
  color: "black",
  testId: null,
  forceOverflowVisible: false,
}

export type IconConfig = React$Config<Props, typeof DEFAULT_PROPS>

export default function Icon({
  type,
  color,
  size,
  children,
  padding,
  testId,
  bold,
  forceOverflowVisible,
  customColor,
}: Props): React.Element<"i"> {
  return (
    <i
      aria-hidden="true"
      className={cn(
        "mi",
        `mi-${type.split("_").join("-")}`,
        styles.Icon,
        { [styles.bold]: bold },
        styles[color],
        styles["size_" + size]
      )}
      data-testid={testId}
      style={{
        padding,
        overflow: type === "star-rate" && !forceOverflowVisible ? "hidden" : null, // Star rate has a weird bug with ::after
      }}
    >
      <SVGIcon customColor={customColor} type={type} />
      {children}
    </i>
  )
}

Icon.defaultProps = DEFAULT_PROPS
