/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import "react-dates/initialize"
import * as React from "react"
import PropTypes from "prop-types"

import { DateRangePicker } from "react-dates"

export default class TandaDateRangePicker extends React.PureComponent {
  constructor(props) {
    super(props)
    this.onFocusChange = this.onFocusChange.bind(this)
    this.state = { focusedInput: null }
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput })
  }

  render() {
    return <DateRangePicker {...this.props} focusedInput={this.state.focusedInput} onFocusChange={this.onFocusChange} />
  }
}

TandaDateRangePicker.defaultProps = {
  startDate: null,
  endDate: null,
}

/**
 * rest of api here
 * https://github.com/airbnb/react-dates#singledatepicker
 */
TandaDateRangePicker.propTypes = {
  onDatesChange: PropTypes.func.isRequired,
  startDate: PropTypes.object,
  startDateId: PropTypes.string.isRequired,
  endDate: PropTypes.object,
  endDateId: PropTypes.string.isRequired,
}
