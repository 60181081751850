/* @flow */
import * as React from "react"
import _ from "lodash"
import MultiFilter from "components/Select/MultiFilter"
import { t as globalT } from "helpers/i18n"
import { sortOptions } from "helpers/filter"
import TeamGroup from "../TeamSelect/TeamGroup"
import TeamOption from "../TeamSelect/TeamOption"
import type {
  LocationType,
  TeamType,
  RequiredProps,
  Props,
  StateType,
  FilterOptionsType,
  FilterTeamOptionsType,
} from "./types"

const t = (key) => globalT(`js.generic_components.team_select.${key}`)

const DEFAULT_TEAM_COLOR = "#ccc"

export default class TeamsFilter extends React.PureComponent<Props, StateType> {
  static defaultProps: $Diff<Props, RequiredProps> = {
    only_show_these_teams: undefined,
    color: "default",
    label: undefined,
    newStyle: false,
    mediumSize: false,
    roundedBorder: false,
  }

  constructor(props: Props) {
    super(props)
    // $FlowFixMe this doesnt unrefine
    this.state = { options: sortOptions(this.mapOptions(this.props.value)) }
  }

  mapOptions: (currValue: Array<number>) => Array<FilterOptionsType> = (
    currValue: Array<number>
  ): Array<FilterOptionsType> => [
    { label: t("all_teams"), value: this.props.teams.map((t) => t.id), options: [] },
    ...this.props.locations.map((location: LocationType): FilterOptionsType => {
      const teams = this.props.teams.filter((t) => t.location_id === location.id)
      const teamIds = teams.map((t) => t.id)
      return {
        label: location.name,
        options: teams.map((team: TeamType): FilterTeamOptionsType => this.mapToOption(team)),
        value: teamIds.every((id) => currValue.includes(id))
          ? teamIds
          : teamIds.filter((id) => !currValue.includes(id)),
      }
    }),
  ]

  mapToOption: (team: TeamType) => {| data: { color: string, name: string, ... }, label: string, value: number |} = (
    team: TeamType
  ) => ({
    data: {
      color: team.colour || DEFAULT_TEAM_COLOR,
      name: team.name,
    },
    label: team.name,
    value: team.id,
  })

  render(): React.Node {
    const only_show_these_teams = this.props.only_show_these_teams
    const options =
      only_show_these_teams == null
        ? this.state.options
        : this.state.options
            .map((fo) => ({
              ...fo,
              options: fo.options && fo.options.filter((o) => _.includes(only_show_these_teams, o.value)),
            }))
            .filter((fo) => fo.options != null && fo.options.length > 0)
    return (
      <MultiFilter
        color={this.props.color}
        Group={TeamGroup}
        label={this.props.label || t("select_teams")}
        newStyle={this.props.newStyle}
        onChange={this.props.handleTeamFilterOnChange}
        Option={TeamOption}
        options={options}
        roundedBorder={this.props.roundedBorder}
        searchOn
        size={this.props.mediumSize ? "m" : "s"}
        tabIndex={0}
        value={this.props.value}
      />
    )
  }
}
