/* @flow */
import _ from "lodash"
import Constants from "dashboard/live_insights/helpers/constants"
import { filterLocationData } from "dashboard/live_insights/selectors"
import type { LiveInsightsDataType, LocationDataType } from "../main"

type ExportType = {
  getUserFilteredTime: (
    data: ?LiveInsightsDataType,
    departmentIds: Array<number>,
    locationData: ?Array<LocationDataType>,
    departments: Array<number>
  ) => string,
}

const getUserFilteredTime = (
  data: ?LiveInsightsDataType,
  departmentIds: Array<number>,
  locationData: ?Array<LocationDataType>,
  departments: Array<number>
): string => {
  window.$.cookie(Constants.TEAMS_COOKIE_KEY + window.current_user.id, JSON.stringify(departments))

  if (data && departments.length === departmentIds.length) {
    if (locationData) {
      const selectedLocations = filterLocationData(locationData, departments)
      if (selectedLocations.length === 1) {
        return selectedLocations[0].loc_current_time
      } else if (selectedLocations.length > 1) {
        const timeGroups = selectedLocations.reduce((acc, location) => {
          if (!_.includes(acc, location.loc_current_time)) {
            acc.push(location.loc_current_time)
          }
          return acc
        }, [])
        if (timeGroups.length === 1) {
          return timeGroups[0]
        }
      }
    }
  }

  return data ? data.org_current_time : "" // default
}

const exportObject: ExportType = { getUserFilteredTime }
export default exportObject
