/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"

import Button from "components/Button"
import { AutoHoverBubble } from "components/Bubble"
import styles from "./styles.module.scss"

export default class ApproveToggle extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleOnClick = this.handleOnClick.bind(this)
  }

  handleOnClick() {
    this.props.onClick(!this.props.applied)
  }

  render() {
    const { applied, autoApplied, label, disabled, hovered, tip, tipPosition } = this.props
    const iconType = autoApplied ? "mi mi-flash-on" : "mi mi-check-circle"
    const getButtonType = () => {
      if (autoApplied) {
        return "magic"
      }
      if (applied) {
        return "action"
      }
      return "ghost"
    }

    return (
      <div className="w100 relative stack">
        {disabled && tip ? (
          <AutoHoverBubble hovered={hovered} position={tipPosition}>
            <span className={styles.tip}>{tip}</span>
          </AutoHoverBubble>
        ) : null}
        <Button
          disabled={disabled}
          iconLeft={iconType}
          label={label}
          onClick={this.handleOnClick}
          type={getButtonType()}
        />
      </div>
    )
  }
}

ApproveToggle.defaultProps = { hovered: false }

ApproveToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  hovered: PropTypes.bool,
  applied: PropTypes.bool.isRequired,
  autoApplied: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  tip: PropTypes.string.isRequired,
  tipPosition: PropTypes.string.isRequired,
}
