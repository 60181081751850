// @flow
import * as React from "react"
import { FilterSelect } from "components/Select/FilterSelect"
import Button from "components/Button"
import * as Routes from "helpers/routes"
import * as I18n from "helpers/i18n"
import * as Assoc from "../../models/association"
import * as Model from "../../models/model"
import * as OptionList from "../../models/optionList"
import * as Record from "../../models/record"
import styles from "./styles.module.scss"

type Ev = SyntheticInputEvent<HTMLElement>

type Props = {|
  +assoc: Assoc.Schema,
  +associatedModel: Model.Schema,
  +onEdit: (Ev) => mixed,
  +onSave: () => mixed,
  +options: OptionList.OptionListType,
  +record: Record.Schema,
  +value: ?(number | string),
|}

const t = (k: string, opts: {}): string => I18n.t(`js.platform.records.form.${k}`, opts)

export default function HasOne({
  assoc,
  associatedModel,
  onEdit,
  onSave,
  options,
  record,
  value,
}: Props): React.Element<"div"> {
  const [cache, setCache] = React.useState(value)

  const allOptions = React.useMemo(
    () =>
      [
        {
          label: t("defaults.no_association", { type: assoc.name }),
          value: null,
        },
      ].concat(options),
    [assoc.name, options]
  )

  if (value !== cache) {
    setCache(value)
    onSave()
  }

  return (
    <div className={styles.fullWidth}>
      <FilterSelect
        disabled={!assoc.editable}
        htmlName={assoc.key}
        // $FlowFixMe All of these are sharing the same event / function even though theyre different inputs
        onChange={onEdit}
        options={allOptions}
        value={value}
      />
      {value && !associatedModel.nativeType && (
        <Button
          iconLeft="chevron-right"
          label={t("view_record", { name: assoc.name })}
          link={Routes.edit_platform_model_record_path(associatedModel.id, value)}
          newTab
          size="sm"
          type="ghost"
        />
      )}
    </div>
  )
}
