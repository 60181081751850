/* @flow */

import type { ConnectedComponent } from "react-redux"
import * as React from "react"
// $FlowFixMe immutable is definitely a module
import { Map } from "immutable"
import { t } from "helpers/i18n"
import Button from "components/Button"
import Modal, { Footer, FooterLeft, FooterRight, Header } from "components/Modal"

import { Provider as PlatformProvider } from "platform/store/context"
import { create as createPlatformState } from "platform/models/state"
import Empty from "platform/view/empty"
import Gate from "platform/view/gate"

import Connect from "./Connect"
import PlatformRecordFields from "./PlatformRecordFields"

export type Props = {|
  onClose: () => void,
  platform_model_id: number,
  shift: typeof Map,
|}

export class PlatformModal extends React.PureComponent<Props> {
  isOpen: () => boolean = () => !!this.props.shift

  handleClose: () => void = () => {
    this.props.onClose()
  }

  render(): null | React.Node {
    if (!this.props.shift) {
      return null
    }
    const platform_record_id = this.props.shift.get("platform_record_id")
    if (!platform_record_id) {
      return null
    }

    return (
      <Modal exitable onExit={this.handleClose} open={this.isOpen()}>
        <Header titleText={t("js.timesheets.platform_modal.title")} />

        <PlatformProvider state={createPlatformState()}>
          <Gate.Record
            failure={<Empty.Failure />}
            loading={<Empty.Loading />}
            modelId={this.props.platform_model_id.toString()}
            recordId={platform_record_id.toString()}
          >
            <PlatformRecordFields modelId={this.props.platform_model_id.toString()} recordId={platform_record_id} />
          </Gate.Record>
        </PlatformProvider>

        <Footer>
          <FooterLeft>
            <Button label={t("js.timesheets.platform_modal.close")} onClick={this.handleClose} type="ghost" />
          </FooterLeft>
          <FooterRight>
            <React.Fragment></React.Fragment>
          </FooterRight>
        </Footer>
      </Modal>
    )
  }
}

export default (Connect(PlatformModal): Class<ConnectedComponent<{||}, typeof PlatformModal>> & typeof PlatformModal)
