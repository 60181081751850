// @flow
import * as React from "react"
import cn from "classnames"
import moment from "moment"
import DatePicker from "components/DatePicker/DateTimePicker/DatePicker"
import styles from "./styles.module.scss"

type Ev = SyntheticInputEvent<HTMLInputElement>

type Props = {|
  disabled: boolean,
  htmlName: string,
  onEdit: (Ev) => mixed,
  onSave: () => mixed,
  value: ?string,
|}

export default function DateInput(props: Props): React.Element<"div"> {
  const [prev, setPrev] = React.useState(props.value)
  const [focused, setFocused] = React.useState(false)
  const value = moment(props.value, "YYYY-MM-DD")

  if (props.value !== prev) {
    props.onSave()
    setPrev(props.value)
  }

  const onBlur = React.useCallback(() => {
    setFocused(false)
  }, [])

  const onFocus = React.useCallback(() => {
    setFocused(true)
  }, [])

  const onChange = React.useCallback(
    (date: moment) => {
      // $FlowFixMe not a real synthetic event
      props.onEdit({
        target: {
          name: props.htmlName,
          value: date.format("YYYY-MM-DD"),
        },
      })
    },
    [props]
  )

  // in future we may add more hints (in the form of field types?) to allow users to customise this further
  // or we could just make it a free text field instead
  const validYears = { from: new Date().getFullYear() - 100, to: new Date().getFullYear() + 10 }

  return (
    <div className={cn(styles.dateInput, { [styles.dateFocused]: focused })}>
      <DatePicker
        disabled={props.disabled}
        enableMonthAndYearPicker
        i18nFormat="date.formats.long_ordinal"
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        validYears={validYears}
        value={value}
      />
    </div>
  )
}
