/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

/* eslint react/prefer-stateless-function: off */

import * as React from "react"
import PropTypes from "prop-types"
import { t as globalT } from "helpers/i18n"

import Button from "components/Button"
import Loader from "components/Loader"

import styles from "./styles.module.scss"

// enter and spacebar
const KEYCODES = [13, 32]

const t = (key) => globalT(`js.timesheets.error_splash.${key}`)

export default class ErrorSplash extends React.PureComponent {
  constructor(props) {
    super(props)
    this.setReference = this.setReference.bind(this)
  }

  componentDidMount() {
    if (this.action) {
      this.action.focus()
    }
  }

  handleKeyDown(event) {
    event.preventDefault()

    if (KEYCODES.includes(event.keyCode)) {
      window.location.reload()
    }
  }

  reloadPage() {
    window.location.reload()
  }

  setReference(node) {
    this.action = node
  }

  getErrorDetails() {
    const isOffline = this.props.errorType === "offline"
    return {
      message: t(this.props.errorType),
      action: isOffline ? t("reconnect") : t("reload"),
      spinner: isOffline ? <Loader /> : null,
    }
  }

  render() {
    const { message, action, spinner } = this.getErrorDetails()

    return (
      <div className={styles.ErrorSplash}>
        <span className={styles.message}>{message}</span>
        {spinner}
        <Button
          className={styles.action}
          label={action}
          onClick={this.reloadPage}
          onKeyDown={this.handleKeyDown}
          tabIndex={0}
          type="theme"
          updateRef={this.setReference}
        />
      </div>
    )
  }
}

ErrorSplash.defaultProps = { errorType: "sync" }

ErrorSplash.propTypes = { errorType: PropTypes.oneOf(["sync", "offline"]) }
