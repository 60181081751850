/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import { t as globalT } from "helpers/i18n"
import * as Timesheet from "timesheets/models/timesheet"
import styles from "./styles.module.scss"

const t = (key) => globalT(`js.timesheets.allowance_modal.empty_state.${key}`)

export default class Description extends React.PureComponent {
  render() {
    if (this.props.timesheet && Timesheet.isExported(this.props.timesheet)) {
      return <p className={styles.description}>{t("no_allowances")}</p>
    }

    return (
      <p className={styles.description}>
        {this.props.type === Types.EMPTY ? t("no_user_allowances") : t("no_org_allowances")}
      </p>
    )
  }
}

export const Types = {
  EMPTY: "EMPTY",
  INFO: "INFO",
}

Description.defaultProps = { timesheet: undefined }

Description.propTypes = {
  timesheet: PropTypes.object,
  type: PropTypes.oneOf([Types.EMPTY, Types.INFO]).isRequired,
}
