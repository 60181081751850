/* @flow */

import * as React from "react"
import _ from "lodash"
import RadioButton from "./RadioButton"

type RequiredProps = {|
  children: React.ChildrenArray<React.Element<typeof RadioButton>>,
|}
type Props = {|
  ...RequiredProps,
  disabled: boolean,
  labelPos: "left" | "right",
  onClick: (any) => void, // eslint-disable-line flowtype/no-weak-types
  selectedValue: null | string | boolean | number,
|}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  disabled: false,
  labelPos: "right",
  onClick: _.noop,
  selectedValue: null,
}

export default function RadioList(props: Props): React.Element<"div"> {
  const radioOptions = React.Children.map(props.children, (child) =>
    React.cloneElement(child, {
      disabled: props.disabled || child.props.disabled,
      labelPos: props.labelPos,
      onClick: props.onClick,
      selected: props.selectedValue === child.props.value,
      value: child.props.value,
    })
  )

  return <div>{radioOptions}</div>
}

RadioList.defaultProps = DEFAULT_PROPS
