/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import { fromJS } from "immutable"
import { contains, pick, find } from "underscore" // eslint-disable-line underscore-to-lodash/prefer-import-lodash
import Req from "helpers/request"
import * as ApiRequest from "timesheets/models/api_request"
import * as Shift from "timesheets/models/shift"
import Actions from "../actions"

/**
 * processes a shift request, or cache it. process is:
 *
 *   - if current shift request in transit or errors, cache it and exit
 *   - add request details to transit record
 *   - build && send request
 *
 * @param {Immutable.Map}  $0.shift to process request for
 * @param {Object} $0.params to send to tanda
 * @param {boolean} $0.isCachable (some requests arent or dont ever need to be)
 */
export const requestProcess =
  ({ shift, shiftBreak, params, isCachable = true }) =>
  (dispatch, getState) => {
    const id = shift.get("id")

    if (
      isCachable && //
      (getState().requestRecord.includes(id) || // cache params if shift has any errors
        (Shift.hasErrors(shift) && shift.get("status") === "PENDING")) // or a request in transit
    ) {
      //
      if (shiftBreak) {
        return dispatch(Actions.reqBreakCachePush({ shiftBreak, params }))
      } else {
        return dispatch(Actions.reqCachePush({ id, params }))
      }
    }

    const { url, data, method } = ApiRequest.build({ shift, shiftBreak, params })

    updateDataFromCache({ dispatch, getState, shift, shiftBreak, data })

    dispatch(Actions.reqRecordPush({ id })) //
    dispatch(
      Actions.shiftsMergeIn({
        // signal state of the update
        id, // in progress
        shift: { state: Shift.States.Syncing }, //
      })
    )

    return Req[method](url, data)
      .then(
        (
          response //
        ) =>
          Shift.hasMockedId(id) // process update with api
            ? dispatch(Actions.requestCreated({ shift, response })) // then handle follow up tasks.
            : dispatch(Actions.requestUpdated({ shift, shiftBreak, response })) //
      )
      .catch(() => dispatch(Actions.userMerge({ error: "sync" })))
  }

// update the payload from the cache
const updateDataFromCache = ({ dispatch, getState, shift, shiftBreak, data }) => {
  if (data.shift) {
    const cached = getState().requestCache.get(shift.get("id"))
    if (cached) {
      data.shift = Object.assign({}, cached.toJS(), data.shift)
      dispatch(Actions.reqCacheDelete({ id: shift.get("id") }))
    }
  } else if (data.shift_break) {
    const cachedBreak = getState().requestBreakCache.get(shiftBreak.get("id"))
    if (cachedBreak) {
      data.shift_break = Object.assign({}, cachedBreak.toJS(), data.shift_break)
      dispatch(Actions.reqBreakCacheDelete({ id: shiftBreak.get("id") }))
    }
  }
}

/**
 * process the response of a update request. this involves:
 *
 *   - merge whitelisted response shift members
 *   - merge new export summary
 *   - remove record of request
 *   - if no related requests in transit signal clean state of shift
 *
 * @param {Immutable.Map} $0.shift shift request was for
 * @param {Object} $0.params of the request
 * @param {Object} $0.response of the request
 */
export const requestUpdated =
  ({ shift, shiftBreak, response }) =>
  (dispatch, getState) => {
    const id = shift.get("id")
    const requestPurgeCacheOptions = { shiftIdForBreak: id }

    if (shiftBreak) {
      const shiftBreakId = shiftBreak.get("id")

      // if the server response contains breaks, extract them from the response data
      // so that they aren't merged in as part of shiftsMergeIn
      // THEN, merge them separately by calling shiftsBreakMergeIn
      let breakToMerge
      if (Shift.hasMockedId(shiftBreakId)) {
        const usedBreakIds = shift
          .get("breaks")
          .map((sb) => sb.get("id"))
          .toJS()
        breakToMerge = find(response.data.shift.breaks || [], (sb) => !contains(usedBreakIds, sb.id))
      } else {
        breakToMerge = find(response.data.shift.breaks || [], (sb) => sb.id === shiftBreakId)
      }
      if (Shift.hasMockedId(shiftBreakId)) {
        dispatch(Actions.reqBreakCacheUpdate({ mockedId: shiftBreakId, id: breakToMerge.id }))
      }
      requestPurgeCacheOptions.shiftBreakId = breakToMerge.id

      const mergeableBreakProps = pick(breakToMerge, Shift.validEffects(breakToMerge, getState().requestBreakCache))
      delete response.data.shift.breaks

      dispatch(
        Actions.shiftsBreakMergeIn({
          shift: shift,
          shiftBreakId: shiftBreak.get("id"),
          changes: fromJS(Shift.momentize(mergeableBreakProps)),
        })
      )
    }

    const mergeableShiftProps = pick(
      response.data.shift,
      Shift.validEffects(response.data.shift, getState().requestCache)
    )
    const mergeableShift = fromJS(Shift.momentize(mergeableShiftProps))

    dispatch(
      Actions.shiftsMergeIn({
        id: shift.get("id"),
        shift: mergeableShift,
      })
    )

    dispatch(Actions.summaryUpdate(response.data.export_summary))
    dispatch(
      Actions.timesheetsSet({
        id: response.data.timesheet.id,
        data: response.data.timesheet,
      })
    )
    dispatch(Actions.reqRecordDelete({ id }))
    dispatch(Actions.requestPurgeCache(requestPurgeCacheOptions))

    // if shift !in request record, set to clean
    if (!getState().requestRecord.includes(id)) {
      dispatch(
        Actions.shiftsMergeIn({
          id: shift.get("id"),
          shift: { state: Shift.States.Clean },
        })
      )
    }
  }

/**
 * process the response of a create request. this involves:
 *
 *   - replace mocked shift with current state merged with whitelisted fields of response
 *   - merge new export summary
 *   - update any cache requests related to mocked id with real id
 *   - remove record of request
 *   - if no related requests in transit signal clean state of shift
 *
 * @param {Immutable.Map} $0.shift shift request was for
 * @param {Object} $0.params of the request
 * @param {Object} $0.response of the request
 */
export const requestCreated =
  ({ shift, response }) =>
  (dispatch, getState) => {
    const { id } = response.data.shift
    const mockedId = shift.get("id")
    dispatch(Actions.reqCacheUpdate({ mockedId, id }))

    dispatch(
      Actions.shiftsReplace({
        id: mockedId,
        shift: fromJS(
          Shift.momentize({
            // get valid effects from a lazy accesed version of shift
            ...pick(response.data.shift, Shift.validEffects(response.data.shift, getState().requestCache)),
            state: Shift.States.Syncing,
          })
        ),
      })
    )

    dispatch(Actions.summaryUpdate(response.data.export_summary))
    dispatch(
      Actions.timesheetsSet({
        id: response.data.timesheet.id,
        data: response.data.timesheet,
      })
    )
    dispatch(Actions.reqRecordDelete({ id: mockedId }))
    dispatch(Actions.requestPurgeCache({}))

    // if shift !in request record, set to clean
    if (!getState().requestRecord.includes(id)) {
      dispatch(
        Actions.shiftsMergeIn({
          id,
          shift: { state: Shift.States.Clean },
        })
      )
    }
  }

/**
 * purges cache of any requests it can. process is:
 *
 *   - if any cached updates dont have related transit record and are error free, process.
 */
export const requestPurgeCache =
  (
    { shiftIdForBreak, shiftBreakId } // TODO: move to taking an id of cache to purge
  ) =>
  (dispatch, getState) => {
    // process the shifts request queue
    getState().requestCache.mapEntries(([id, params]) => {
      const shift = getState().shifts.get(id)
      if (!getState().requestRecord.includes(id) && !Shift.hasErrors(shift)) {
        dispatch(Actions.reqCacheDelete({ id }))
        dispatch(
          Actions.requestProcess({
            shift,
            params: params.toJS(),
          })
        )
      }
    })

    // if we have completed a request on a specific break, we go back and look for cached entries for the same break
    if (shiftBreakId) {
      purgeBreakCache({ shiftIdForBreak, shiftBreakId, dispatch, getState })
    }

    // if we have completed a shift request, and while doing it, we cache a break request
    // then after the shift request is done, we need to look in the break cache for cached requests for that shift
    if (shiftIdForBreak) {
      const shift = getState().shifts.get(shiftIdForBreak)
      shift
        .get("breaks")
        .forEach((sb) => purgeBreakCache({ shiftIdForBreak, shiftBreakId: sb.get("id"), dispatch, getState }))
    }
  }

const purgeBreakCache = ({ shiftIdForBreak, shiftBreakId, dispatch, getState }) => {
  // process the breaks request queue, but only for the given break
  getState()
    .requestBreakCache.filter((params, cacheShiftBreakId) => cacheShiftBreakId === shiftBreakId)
    .mapEntries(([shiftBreakId, params]) => {
      const shift = getState().shifts.get(shiftIdForBreak)
      const shiftBreak = shift.get("breaks").find((sb) => sb.get("id") === shiftBreakId)

      if (!getState().requestRecord.includes(shiftIdForBreak) && !Shift.hasErrors(shift)) {
        dispatch(Actions.reqBreakCacheDelete({ id: shiftBreakId }))
        dispatch(
          Actions.requestProcess({
            shift,
            shiftBreak,
            params: params.toJS(),
          })
        )
      }
    })
}
