// @flow

import * as React from "react"
import Input, { type Props as BaseInputProps, DEFAULT_PROPS as InputProps } from "./Input"

export type RequiredProps = {|
  onChange: $PropertyType<BaseInputProps, "onChange">,
|}

export type Props = {|
  ...RequiredProps,
  ...BaseInputProps,
  integerOnly: boolean,
  positiveOnly: boolean,
|}

export const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  ...InputProps,
  integerOnly: false,
  positiveOnly: false,
}

export default class NumberInput extends React.PureComponent<Props> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  handleOnChange: (e: SyntheticInputEvent<HTMLInputElement>) => mixed = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const startsWithNegative = /^-/.test(e.target.value)
    const positiveValueString = startsWithNegative ? e.target.value.substr(1) : e.target.value

    if (isNaN(positiveValueString)) {
      return
    }

    if (this.props.positiveOnly && startsWithNegative) {
      return
    }

    if (
      this.props.integerOnly &&
      e.target.value &&
      (parseFloat(e.target.value) !== parseInt(e.target.value) || /\.$/.test(e.target.value))
    ) {
      return
    }

    this.props.onChange(e)
  }

  render(): React.Node {
    // eslint-disable-next-line no-unused-vars
    const { integerOnly, positiveOnly, ...rest } = this.props
    return <Input {...rest} onChange={this.handleOnChange} />
  }
}
