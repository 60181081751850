// @flow

import * as React from "react"
import _ from "lodash"
import cn from "classnames"
import Icon from "components/Icon"
import Text from "components/Text"
import { type Value } from "components/Select"
import styles from "./styles.module.scss"

type RequiredProps = {|
  focused: boolean,
  handleClick: () => void,
  value: Value,
|}

type Props = {|
  ...RequiredProps,
  bold: boolean,
  color: "grey" | "white",
  context_icon: ?string,
  edit_icon: string,
  formatter: (v: Value) => string,
  updateRef: (el: ?HTMLDivElement) => void,
|}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  formatter: (v: Value) => String(v),
  edit_icon: "edit",
  context_icon: null,
  bold: false,
  updateRef: _.noop,
  color: "grey",
}

export default class ButtonNumberInput extends React.PureComponent<Props> {
  static defaultProps: {|
    bold: boolean,
    color: "grey" | "white",
    context_icon: ?string,
    edit_icon: string,
    formatter: (v: Value) => string,
    updateRef: (el: ?HTMLDivElement) => void,
  |} = DEFAULT_PROPS

  render(): React.Element<"div"> {
    return (
      <div
        className={cn(styles.button, styles["color-" + this.props.color], {
          [styles.hiddenButton]: this.props.focused,
        })}
        onClick={this.props.handleClick}
        onFocus={_.noop}
        onKeyUp={_.noop}
        ref={this.props.updateRef}
        role="button"
        styles={styles.button}
        tabIndex={0}
      >
        {this.props.context_icon != null && <Icon color="grey" size="s" type={this.props.context_icon} />}
        <Text bold={this.props.bold} color={this.props.focused ? "invisible" : "light"} margin="right" type="small">
          {" "}
          {this.props.formatter(this.props.value == null ? "" : this.props.value)}{" "}
        </Text>
        <Icon color={this.props.focused ? "invisible" : "grey"} size="s" type={this.props.edit_icon} />
      </div>
    )
  }
}
