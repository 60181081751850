/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import { fromJS } from "immutable"

/**
 * @param {{[key: string]: any}} state - to hydrate
 * @param {{[key: string]: Function}} options - optional state hydrators
 * @returns {{[key: string]: any}} hydrated state
 */
export default (state, options = {}) => {
  const hydrateStore = storeHydrater(options)

  return Object.entries(state).reduce(
    (acc, [key, store]) => ({
      ...acc,
      [key]: hydrateStore([key, store]),
    }),
    {}
  )
}

/**
 * create a store hydrating fn
 *
 * @param {{[key: string]: Function}} optional custom hydrators
 * @returns {Function} a function that takes a [key, store] pair
 * and hydrates with the default or optional hydrator if
 * exists
 */
export const storeHydrater =
  (options) =>
  /**
   * @param {[string, any]} $0 store entity
   * @param {string} $0.key the key of the store
   * @param {any} $0.store
   * @returns {any} hydrated store
   */
  ([key, store]) =>
    options[key] ? options[key](store) : fromJS(store)
