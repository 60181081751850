// @flow
import * as React from "react"
import cn from "classnames"
import { noop } from "lodash"
import Checkbox from "components/Checkbox"
import Icon, { type IconColor } from "components/Icon"
import RadioButton from "components/Radio/RadioButton"
import BoxBody from "./BoxBody"
import styles from "./styles.module.scss"

type RequiredProps = {|
  title: string,
|}

type Props = {|
  ...RequiredProps,
  autoWidth: boolean,
  checked?: boolean,
  disabled: boolean,
  exampleText: ?string,
  icon: ?string,
  locked: boolean,
  newStyle?: boolean,
  onClick: (e: { target: { value: mixed } }) => mixed,
  selected?: boolean,
  size: "l" | "m" | "s" | "xs",
  subtitle: ?string,
  type: "checkbox" | "radio",
  value: string | boolean | ?number,
|}

type State = {
  checked: boolean,
  hover: boolean,
}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  checked: false,
  newStyle: false,
  autoWidth: false,
  disabled: false,
  locked: false,
  exampleText: null,
  icon: null,
  onClick: noop,
  selected: false,
  size: "s",
  subtitle: null,
  type: "checkbox",
  value: null,
}

export default class OptionBox extends React.PureComponent<Props, State> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  constructor(props: Props) {
    super(props)
    this.state = {
      checked: false,
      hover: false,
    }
  }

  onClick: (e: { target: { value: mixed, ... }, ... }) => mixed = (e: { target: { value: mixed } }) => {
    if (this.props.disabled) {
      return
    }
    if (this.props.type === "checkbox") {
      e.target.value = !this.props.checked
    } else {
      e.target.value = this.props.value
    }
    return this.props.onClick(e)
  }

  onKeyUp: (e: SyntheticKeyboardEvent<HTMLDivElement>) => void = (e: SyntheticKeyboardEvent<HTMLDivElement>) => {
    if (e.key === " " || e.key === "Enter") {
      this.onClick({ target: { value: null } })
    }
  }

  onMouseEnter: () => void = () => {
    this.setState({ hover: true })
  }

  onMouseLeave: () => void = () => {
    this.setState({ hover: false })
  }

  getSizeStyle: () => string = () => {
    if (this.props.size === "l") {
      return styles.large
    } else if (this.props.size === "m") {
      return styles.med
    }
    return styles.small
  }

  wrapperClasses: () => string = () => {
    const condition = this.props.type === "checkbox" ? this.props.checked : this.props.selected
    return cn(styles.container, this.getSizeStyle(), {
      [styles.newStyle]: this.props.newStyle,
      [styles.selected]: condition,
      [styles.autoWidth]: this.props.autoWidth,
      [styles.hover]: this.state.hover && !this.props.disabled,
      [styles.disabled]: this.props.disabled,
    })
  }

  getCheckbox: () => React.Element<"div"> = () => (
    <div className={styles.box}>
      <Checkbox
        autoMargin={false}
        checked={Boolean(this.props.checked)}
        disabled={this.props.disabled}
        hovered={this.state.hover}
        onClick={noop}
      />
    </div>
  )

  getRadio: () => React.Element<"div"> = () => (
    <div className={styles.box}>
      <RadioButton
        disabled={this.props.disabled}
        disabledMargin
        hovered={this.state.hover}
        selected={this.props.selected}
        value={this.props.value}
      />
    </div>
  )

  getIconColor: () => IconColor = () => {
    if (this.props.disabled) {
      return "disabled"
    } else if (this.props.type === "checkbox") {
      if (this.props.checked) {
        return "primary"
      }
    } else {
      if (this.props.selected) {
        return "primary"
      }
    }
    if (this.state.hover) {
      return "primary"
    }
    return "grey"
  }

  getLock: () => React.Element<"div"> = () => (
    <div className={styles.box}>
      <Icon color={this.getIconColor()} size="m" type="lock" />
    </div>
  )

  render: () => React.Element<"div"> = () => (
    <div
      className={this.wrapperClasses()}
      onClick={this.onClick}
      onKeyUp={noop}
      onMouseEnter={this.onMouseEnter}
      onMouseLeave={this.onMouseLeave}
      role="button"
      tabIndex={0}
    >
      <BoxBody
        checked={Boolean(this.props.checked)}
        disabled={this.props.disabled}
        exampleText={this.props.exampleText}
        icon={this.props.icon}
        iconColor={this.getIconColor()}
        size={this.props.size}
        subtitle={this.props.subtitle}
        title={this.props.title}
      />
      {this.props.locked ? this.getLock() : this.props.type === "checkbox" ? this.getCheckbox() : this.getRadio()}
    </div>
  )
}

OptionBox.displayName = "OptionBox"
