// @flow
import * as React from "react"
import * as Tanda from "platform/clients/tanda"
import * as Store from "./store"

type Props<D> = {|
  +children: (?D) => React.Node,
  +duration?: number,
  +marshal: (mixed) => ?D,
  +path: string,
  +updatedAt?: number,
|}

export function Resource<D>({ children, marshal, duration, path, updatedAt }: Props<D>): React.Node {
  return children(useResource<D>(path, marshal, duration, updatedAt))
}

// never cached results default to a timestamp of negative infinity
// aka impossibly old
export const NEVER_CACHED = Number.NEGATIVE_INFINITY

export const SECONDS_15 = 1000 * 15
export const MINUTES_5 = 1000 * 60 * 5
export const HOURS_1 = 1000 * 60 * 60

export function useResource<D>(
  path: string,
  marshal: (mixed) => ?D,
  duration: number = SECONDS_15,
  updatedAt: number = NEVER_CACHED
): ?D {
  const [state, dispatch] = React.useContext(Store.Context)

  const resource = state[path]
  const lastCachedAt = resource?.cachedAt || NEVER_CACHED
  const maxCachedAt = Date.now() - duration
  const isLoaded = updatedAt <= lastCachedAt && lastCachedAt >= maxCachedAt

  React.useEffect(() => {
    if (!isLoaded) {
      Tanda.getUrl(path).then((data) => {
        dispatch({
          type: "@cache/stash",
          payload: {
            data,
            path,
            cachedAt: updatedAt !== NEVER_CACHED ? updatedAt : Date.now(),
          },
        })
      })
    }
  }, [dispatch, isLoaded, path, updatedAt])

  return React.useMemo(() => (resource != null ? marshal(resource.data) : null), [marshal, resource])
}
