// @flow

import PageToolbar, { ToolsLeft, ToolsRight } from "./PageToolbar"
import SearchBar from "./SearchBar"

export default PageToolbar

PageToolbar.ToolsLeft = ToolsLeft
PageToolbar.ToolsRight = ToolsRight

export { SearchBar, ToolsLeft, ToolsRight }
