// @flow

import { t as globalT } from "helpers/i18n"

const t = (key, ...args) => globalT(`js.notifications_and_tasks.modal.${key}`, ...args)

const key_alerts_config = {
  tabs: [
    {
      title: (t("key_alerts.not_at_work.title"): string),
      description: (t("key_alerts.not_at_work.description"): string),
      pathname: "/",
    },
    {
      title: (t("key_alerts.still_at_work.title"): string),
      description: (t("key_alerts.still_at_work.description"): string),
      pathname: "/still_at_work",
    },
    {
      title: (t("key_alerts.break_not_started.title"): string),
      description: (t("key_alerts.break_not_started.description"): string),
      pathname: "/break_not_started",
    },
    {
      title: (t("key_alerts.overtime_risk.title"): string),
      description: (t("key_alerts.overtime_risk.description"): string),
      pathname: "/overtime_risk",
    },
    {
      title: (t("key_alerts.qualification_max_hours_risk.title"): string),
      description: (t("key_alerts.qualification_max_hours_risk.description"): string),
      pathname: "/qualification_max_hours_risk",
    },
  ],
  body: [
    {
      title: (t("key_alerts.alerts.employee.title"): string),
      description: (t("key_alerts.alerts.employee.description"): string),
      setting_key: "alert_staff",
    },
    {
      title: (t("key_alerts.alerts.manager.title"): string),
      description: (t("key_alerts.alerts.manager.description"): string),
      setting_key: "alert_manager",
    },
    {
      title: (t("key_alerts.alerts.admins.title"): string),
      description: (t("key_alerts.alerts.admins.description"): string),
      setting_key: "alert_admin",
    },
    {
      title: (t("key_alerts.lenience.heading"): string),
      description: (t("key_alerts.lenience.desc"): string),
      setting_key: "lenience",
    },
  ],
}

const scheduled_tasks_config = {
  sectionName: (t("scheduled_tasks.section_name.features"): string),
  sectionFeatures: [
    {
      name: (t("scheduled_tasks.auto_deactivate_employees.title"): string),
      subtitle: (t("scheduled_tasks.auto_deactivate_employees.description"): string),
      setting_key: "auto_deactivate_users",
    },
    {
      name: (t("scheduled_tasks.weekly_timesheet_reminder.title"): string),
      subtitle: (t("scheduled_tasks.weekly_timesheet_reminder.subtitle"): string),
      setting_key: "timesheet_reminders",
    },
    {
      name: (t("scheduled_tasks.daily_timesheet_reminder.title"): string),
      subtitle: (t("scheduled_tasks.daily_timesheet_reminder.subtitle"): string),
      setting_key: "timesheet_reminders_daily",
    },
  ],
  conditionalFeatures: [
    {
      name: (t("scheduled_tasks.auto_deactivate_unused_temporary_staff.title"): string),
      subtitle: (t("scheduled_tasks.auto_deactivate_unused_temporary_staff.description"): string),
      setting_key: "auto_deactivate_unused_temporary_staff",
    },
  ],
}

export { scheduled_tasks_config, key_alerts_config }
