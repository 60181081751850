/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

/**
 * ABSTRACT COMPONENT
 *
 * DESC: This component does not render anything visible, however
 * it does add an invisible child to its parent, covering the
 * whole layout and shows this elements children when the
 * parent is being hovered. This component will also eventually
 * handle correctly placing the children in the best visual
 * location.
 *
 * USAGE: This component should be used to compose styled hover views
 * !!!NOTE!!! the parent needs to be positioned relative.
 */

import * as React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { noop, isNumber } from "underscore" // eslint-disable-line underscore-to-lodash/prefer-import-lodash
import styles from "./styles.module.scss"

const onClick = (e) => e.stopPropagation()

export default function Hover(props) {
  const { hovered, children, position, offset, rightAligned, noPointerEvents } = props

  const positionMap = {
    // converts the position for abs positioning.
    top: "bottom",
    right: "left",
    bottom: "top",
    left: "right",
  }

  const classes = cn(styles.Hover, { [styles.hovered]: hovered })

  const presDivStyle = {}

  if (position && isNumber(offset)) {
    if (offset === 0) {
      presDivStyle[positionMap[position]] = "100%"
    } else if (offset > 0) {
      presDivStyle[positionMap[position]] = `calc(100% + ${offset}rem)`
    } else {
      presDivStyle[positionMap[position]] = `calc(100% - ${-offset}rem)`
    }
  }
  if (noPointerEvents) {
    presDivStyle.pointerEvents = "none"
  }

  return (
    <div className={classes} ref={props.setRef}>
      <div
        className={cn(styles.contents, styles[`${position}`], {
          [styles.rightAligned]: rightAligned,
          [styles.fade]: props.fade,
          [styles.delayed]: props.delayed,
          [styles.delayedExit]: props.delayedExit,
        })}
        onClick={onClick}
        onKeyPress={noop}
        role="presentation"
        style={presDivStyle}
      >
        {children}
      </div>
    </div>
  )
}

Hover.propTypes = {
  fade: PropTypes.bool,
  delayed: PropTypes.bool,
  delayedExit: PropTypes.bool,
  noPointerEvents: PropTypes.bool,
  hovered: PropTypes.bool,
  setRef: PropTypes.any,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  position: PropTypes.oneOf(["top", "bottom", "left", "right"]),
  rightAligned: PropTypes.bool,
  offset: PropTypes.number,
}

Hover.defaultProps = {
  fade: true,
  delayed: false,
  delayedExit: false,
  hovered: false,
  setRef: noop,
  noPointerEvents: false,
  position: "bottom",
  offset: null,
  rightAligned: false,
}
