/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

/**
 * A reducer enhancer that can be used to replace
 * the whole state of the application.
 */

const REPLACE_STATE = "@@app/REPLACE_STATE"

/**
 * The action creator to replace the app state.
 * @param {any} data next application state.
 * @returns {Object} a replace state action.
 */
export const replaceState = (data) => ({ type: REPLACE_STATE, data })

/**
 * takes an application reducer and adds functionality
 * for replacing whole app data stores.
 *
 * @param {Function} the application reducer to enhance
 * @returns {Function} the enhanced reducer with state replacing
 * functionality.
 */
export default (reducer) => (state, action) => {
  if (action.type === REPLACE_STATE) {
    return Object.keys(action.data).reduce((acc, key) => ({ ...acc, [key]: action.data[key] }), state)
  }

  return reducer(state, action)
}
