// @flow

import * as React from "react"
import cn from "classnames"
import { noop } from "lodash"
import Bubble, { type BubbleProps } from "../Bubble"

import styles from "./styles.module.scss"

// This component should not be used directly,
// but abstracted with a higher level input.
export type RequiredProps = {|
  onChange: (e: SyntheticInputEvent<HTMLInputElement>) => mixed,
|}

export type InputColor = "success" | "primary" | "error" | "warning" | "default" | "overnight" | "transparent"

export type Props = {|
  ...RequiredProps,
  bold: boolean,
  color: InputColor,
  disabled: boolean,
  forceShowTip: boolean,
  htmlName: ?string,
  onBlur: (e: SyntheticFocusEvent<HTMLInputElement>) => mixed,
  onFocus: (e: SyntheticFocusEvent<HTMLInputElement>) => mixed,
  onKeyDown: (e: SyntheticKeyboardEvent<HTMLInputElement>) => mixed,
  onKeyUp: (e: SyntheticKeyboardEvent<HTMLInputElement>) => mixed,
  onMouseEnter: (e: SyntheticMouseEvent<HTMLInputElement>) => mixed,
  onMouseLeave: (e: SyntheticMouseEvent<HTMLInputElement>) => mixed,
  placeholder: string,
  showFS: boolean,
  size: "xs" | "s" | "l" | "plain_xs",
  step: number,
  testId: ?string,
  textAlign: "start" | "center",
  tipPosition: $PropertyType<BubbleProps, "position">,
  tipText: ?string,
  tipWidth: "auto" | "medium" | "large",
  type: ?"text" | "number",
  updateRef: (element: ?HTMLInputElement) => mixed,
  value: ?string | number,
  width: "auto" | "medium" | "large",
|}

export const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  bold: false,
  color: "default",
  disabled: false,
  forceShowTip: false,
  htmlName: undefined,
  onBlur: noop,
  onFocus: noop,
  onKeyDown: noop,
  onKeyUp: noop,
  onMouseEnter: noop,
  onMouseLeave: noop,
  placeholder: "",
  showFS: true,
  size: "s",
  step: -1,
  testId: null,
  textAlign: "start",
  tipPosition: "bottom",
  tipText: null,
  tipWidth: "auto",
  type: undefined,
  updateRef: noop,
  value: "",
  width: "auto",
}

type State = {|
  hovered: boolean,
|}

export default class BaseInput extends React.PureComponent<Props, State> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  constructor(props: Props) {
    super(props)

    this.state = { hovered: false }
  }

  handleMouseEnter: (e: SyntheticMouseEvent<HTMLInputElement>) => void = (e: SyntheticMouseEvent<HTMLInputElement>) => {
    this.setState({ hovered: true })
    this.props.onMouseEnter(e)
  }

  handleMouseLeave: (e: SyntheticMouseEvent<HTMLInputElement>) => void = (e: SyntheticMouseEvent<HTMLInputElement>) => {
    this.setState({ hovered: false })
    this.props.onMouseLeave(e)
  }

  handleKeyDown: (e: SyntheticKeyboardEvent<HTMLInputElement>) => void = (
    e: SyntheticKeyboardEvent<HTMLInputElement>
  ) => {
    switch (e.keyCode) {
      case 13: // enter
        e.preventDefault()
        break
    }
    this.props.onKeyDown(e)
  }

  renderTooltip(): null | React.Node {
    if (!this.props.tipText) {
      return null
    }

    return (
      <Bubble
        hovered={this.props.forceShowTip || this.state.hovered}
        position={this.props.tipPosition}
        width={this.props.tipWidth}
      >
        <span className={styles.message}>{this.props.tipText}</span>
      </Bubble>
    )
  }

  render(): React.Element<"div"> {
    return (
      <div className={cn(styles.inputWrap, styles[`width_${this.props.width}`])}>
        <input
          className={cn(
            styles.input,
            styles[`size_${this.props.size}`],
            styles[`color_${this.props.color}`],
            styles[`align_${this.props.textAlign}`],
            {
              [styles.disabled]: !!this.props.disabled,
              [styles.bold]: this.props.bold,
              [styles.size_l]: this.props.size === "l",
              "fs-hide": !this.props.showFS,
            }
          )}
          data-testid={this.props.testId}
          disabled={this.props.disabled}
          name={this.props.htmlName}
          onBlur={this.props.onBlur}
          onChange={this.props.onChange}
          onFocus={this.props.onFocus}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.props.onKeyUp}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          placeholder={this.props.placeholder}
          ref={this.props.updateRef}
          step={this.props.step}
          type={this.props.type}
          value={this.props.value}
        />
        {this.renderTooltip()}
      </div>
    )
  }
}
