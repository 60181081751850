// @flow

import * as React from "react"

import InputLabel from "components/Label/InputLabel"
import BaseInput, { type Props as BaseInputPropTypes, DEFAULT_PROPS as BaseDefaultProps } from "./BaseInput"
import styles from "./styles.module.scss"

type RequiredProps = {|
  onChange: $PropertyType<BaseInputPropTypes, "onChange">,
|}

export type Props = {|
  ...RequiredProps,
  label: React.Node,
  ...BaseInputPropTypes,
|}

export const DEFAULT_PROPS: $Diff<Props, RequiredProps> = { label: "", ...BaseDefaultProps }

export default class Input extends React.PureComponent<Props> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  getInputLabel(label: React.Node): null | React.Node {
    if (!label) {
      return null
    }

    return <InputLabel text={label} />
  }

  render(): React.Element<"div"> {
    const { label, ...rest } = this.props
    return (
      <div className={styles.smallInput}>
        {this.getInputLabel(label)}
        <BaseInput {...rest} />
      </div>
    )
  }
}
