/**
 * @flow
 */

import * as React from "react"
import cn from "classnames"
import Icon from "components/Icon"
import HoverIcon from "components/HoverIcon"
import { s3ImagePath } from "helpers/image"
import { t } from "helpers/i18n"
import styles from "./styles.module.scss"

type RequiredProps = {|
  alt: string,
|}

type Props = {|
  ...RequiredProps,
  bordered: boolean,
  geofenceStatus: ?string,
  hidden: boolean,
  mobileClockIn: boolean,
  size: "xs" | "s" | "m" | "l",
  source: ?string,
  type: "portrait" | "round",
|}

type State = {
  image: HTMLImageElement,
  isLoaded: boolean,
  source: string,
}

const DEFAULT_AVATAR = s3ImagePath("timesheet_no_image.png")

export default class Avatar extends React.PureComponent<Props, State> {
  static defaultProps: $Diff<Props, RequiredProps> = {
    hidden: false,
    source: DEFAULT_AVATAR,
    size: "m",
    type: "portrait",
    bordered: false,
    mobileClockIn: false,
    geofenceStatus: "unavailable",
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      image: this.fetchAvatar(props.source || DEFAULT_AVATAR),
      isLoaded: false,
      source: props.source || DEFAULT_AVATAR,
    }
  }

  UNSAFE_componentWillReceiveProps({ source }: Props) {
    if (source !== this.props.source) {
      this.setState({
        image: this.fetchAvatar(source || DEFAULT_AVATAR),
        isLoaded: false,
        source: source || DEFAULT_AVATAR,
      })
    }
  }

  componentWillUnmount() {
    this.removeImageListeners()
  }

  removeImageListeners() {
    this.state.image.removeEventListener("error", this.onError)
    this.state.image.removeEventListener("load", this.onLoad)
  }

  fetchAvatar(source: string): Image {
    const image = new Image()

    image.addEventListener("error", this.onError)
    image.addEventListener("load", this.onLoad)
    image.src = source

    return image
  }

  onLoad: () => void = () => {
    this.removeImageListeners()
    this.setState({ isLoaded: true })
  }

  onError: () => void = () => {
    this.removeImageListeners()

    if (this.state.source !== DEFAULT_AVATAR) {
      this.setState({
        image: this.fetchAvatar(DEFAULT_AVATAR),
        source: DEFAULT_AVATAR,
      })
    }
  }

  renderMobileClockIn: () => null | React.Element<"span"> = () => {
    if (!this.props.mobileClockIn) {
      return null
    }
    switch (this.props.geofenceStatus) {
      case "onsite":
        return (
          <span className={styles.mobileClockInIcon}>
            <span className={styles.whitePinBackground} />
            <Icon color="success" size="m" type="place" />
          </span>
        )
      case "offsite":
        return (
          <span className={styles.mobileClockInIcon}>
            <span className={styles.whitePinBackground} />
            <HoverIcon
              color="danger"
              size="m"
              tip={t("js.timesheets.shift_card.errors.offsite")}
              tipPosition="right"
              type="marker-error"
              width="medium"
            />
          </span>
        )
      case "unavailable":
      default:
        return (
          <span className={styles.mobileClockInIcon}>
            <span className={styles.whitePinBackground} />
            <HoverIcon color="primary" size="m" tipPosition="right" type="place" width="medium" />
          </span>
        )
    }
  }

  renderImage: () => React.Element<"div"> = () => (
    <div className={styles.avatar}>
      <img
        alt={this.props.alt}
        className={cn(styles.image, styles[this.props.size], styles[this.props.type], {
          [styles.border]: this.props.bordered,
        })}
        hidden={this.props.hidden}
        key={this.state.source}
        src={this.state.source}
      />
      <div className={styles.avatarIconContainer}>{this.renderMobileClockIn()}</div>
    </div>
  )

  render(): React.Element<"div"> {
    return this.state.isLoaded ? (
      this.renderImage()
    ) : (
      <div
        className={cn(styles.placeholder, styles[this.props.size], styles[this.props.type], {
          [styles.border]: this.props.bordered,
        })}
        hidden={this.props.hidden}
      />
    )
  }
}
