// @flow
import * as React from "react"
import { noop } from "lodash"
import cn from "classnames"
import Text from "components/Text"
import Icon from "components/Icon"

import styles from "./styles.module.scss"

type RequiredProps = {|
  children: React.Node,
  label: string,
  onClick: (e: { target: { value: Array<number> } }) => mixed,
  onMouseEnter: (e: { target: { value: Array<number> } }) => mixed,
  onMouseLeave: (e: { target: { value: Array<number> } }) => mixed,
  size: "s" | "m" | "l",
  updateRef: () => mixed,
|}

type Props = {|
  ...RequiredProps,
  data: ?{|
    selectable: boolean,
  |},
  hovered: boolean,
  selected: boolean,
  value: ?Array<number>,
|}

// note that app/assets/webpack/users/teams_and_permissions/Form.jsx
// (not Timesheets) also uses this - plz test before changing <3
export default class TeamGroup extends React.Component<Props> {
  static defaultProps: $Diff<Props, RequiredProps> = {
    hovered: false,
    value: null,
    selected: false,
    data: {
      selectable: true,
    },
  }
  handleClick: (event: MouseEvent) => void = (event: MouseEvent) => {
    if (this.props.value) {
      this.props.data?.selectable && this.props.onClick({ ...event, target: { value: this.props.value } })
    }
  }

  handleMouseEnter: (event: MouseEvent) => void = (event: MouseEvent) => {
    if (this.props.value) {
      this.props.onMouseEnter({ ...event, target: { value: this.props.value } })
    }
  }

  handleMouseLeave: (event: MouseEvent) => void = (event: MouseEvent) => {
    if (this.props.value) {
      this.props.onMouseLeave({ ...event, target: { value: this.props.value } })
    }
  }

  render(): React.Element<"div"> {
    return (
      <div>
        <div
          aria-hidden
          className={cn(styles.TeamGroup, styles[this.props.size], {
            [styles.hovered]: this.props.hovered,
            [styles.notSelectable]: !this.props.data?.selectable,
          })}
          onClick={this.handleClick}
          onMouseLeave={this.handleMouseLeave}
          onMouseMove={this.props.hovered ? noop : this.handleMouseEnter}
          ref={this.props.updateRef}
          role="button"
        >
          <div className={styles.TeamGroupLHSWrapper}>
            <span className={styles.pin}>
              <Icon color={this.props.selected ? "primary" : "grey"} size="xs" type="place" />
            </span>
            <Text align="left" bold color={this.props.selected ? "primary" : "light"} type="tiny">
              {this.props.label}
            </Text>
          </div>
          {this.props.selected && (
            <span className={styles.selected}>
              <Icon color="primary" size={this.props.size === "l" ? "s" : "xs"} type="done" />
            </span>
          )}
        </div>
        {this.props.children}
      </div>
    )
  }
}
