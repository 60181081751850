// @flow

import * as React from "react"
import cn from "classnames"
import { noop } from "lodash"
import AccordionHeader from "components/Accordion/AccordionHeader"
import styles from "./styles.module.scss"

type RequiredProps = {|
  open: boolean,
  title: string,
|}

type Props = {|
  ...RequiredProps,
  onClick: () => mixed,
  selection: ?string,
|}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  onClick: noop,
  selection: null,
}

export default class StandardHeader extends React.PureComponent<Props> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  getSelection(): ?string {
    return this.props.selection
  }

  render(): React.Node {
    return (
      <AccordionHeader>
        <span
          className={cn(styles.accordionToggle, { [styles.collapse]: !this.props.open })}
          onClick={this.props.onClick}
          onKeyPress={this.props.onClick}
          role="button"
          tabIndex="0"
        >
          <div className={styles.stepContainer}>
            <div className={styles.title}>
              {this.props.title}
              {this.props.selection !== null && <span />}
            </div>
            <div>{this.getSelection()}</div>
          </div>
        </span>
      </AccordionHeader>
    )
  }
}
