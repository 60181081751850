/**
 * @flow
 */
import * as React from "react"
import Icon from "components/Icon"
import styles from "./styles.module.scss"

type RequiredProps = {|
  +fileName: ?string,
  +onFileRemoved: () => void,
|}

type Props = {|
  ...RequiredProps,
  +disabled: boolean,
  +url?: ?string,
|}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = { disabled: false, url: null }

export default class FileItem extends React.PureComponent<Props> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  handleClick: () => void = () => {
    if (this.props.disabled) {
      return
    }
    this.props.onFileRemoved()
  }

  handleKeyUp: (event: SyntheticKeyboardEvent<HTMLDivElement>) => void = (
    event: SyntheticKeyboardEvent<HTMLDivElement>
  ) => {
    if (this.props.disabled) {
      return
    }

    switch (event.key) {
      case "Backspace":
      case "Delete":
      case "Enter":
        this.props.onFileRemoved()
    }
  }

  fileNameLink: () => ?(React.Element<"a"> | string) = () => {
    const { url, fileName } = this.props
    return url && url !== fileName ? (
      <a href={url} rel="noopener noreferrer" target="_blank">
        {fileName}
      </a>
    ) : (
      this.props.fileName
    )
  }

  stateIcon: () => null | React.Node | React.Element<"div"> = () => {
    if (this.props.disabled) {
      return null
    }

    return (
      <div className={styles.remove} onClick={this.handleClick} onKeyUp={this.handleKeyUp} role="button" tabIndex={0}>
        <Icon color="primary" type="close" />
      </div>
    )
  }

  render(): React.Element<"div"> {
    return (
      <div className={styles.value}>
        <div className={styles.name}>{this.fileNameLink()}</div>
        {this.stateIcon()}
      </div>
    )
  }
}
