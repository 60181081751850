/* @flow */
import * as React from "react"
import moment from "moment"
import _ from "lodash"
import { t as globalT } from "helpers/i18n"
import Text from "components/Text"
import Icon from "components/Icon"
import type {
  LocationDataType,
  LiveInsightsDataType,
  DepartmentType,
  ArrangedDataType,
} from "dashboard/live_insights/main"
import Constants from "dashboard/live_insights/helpers/constants"
import { filterLocationData } from "dashboard/live_insights/selectors/index"
import Formatter from "dashboard/live_insights/helpers/formatter"
import GraphLegendIcon from "dashboard/live_insights/components/GraphLegendIcon"
import styles from "./styles.module.scss"

const t: typeof globalT = (key, args) => globalT(`js.dashboard.widgets.live_insights.graph.toolTip.${key}`, args)

type PropType = {
  active: boolean,
  bucketData: ?LiveInsightsDataType,
  label: string,
  locationData: ?Array<LocationDataType>,
  payload: Array<{
    color: string,
    dataKey: string,
    fill: string,
    name: string,
    payload: {
      name: ?string,
      [ket: string]: number,
    },
    stroke: ?string,
    strokeDasharray: ?string,
    strokeOpacity: ?number,
    strokeWidth: ?number,
    unit: ?string,
    value: number,
  }>,
  referenceTime: ?moment,
  selected: "staff_count" | "staff_cost",
  selectedDepartments: Array<number>,
}

type VarianceDataType = {
  color: string,
  isLocation: boolean,
  name: string,
  variance: number,
}

export default class TrackerChartTooltip extends React.PureComponent<PropType> {
  static defaultProps: {|
    active: boolean,
    label: string,
    payload: Array<{
      color: string,
      dataKey: string,
      fill: string,
      name: string,
      payload: {
        name: ?string,
        [ket: string]: number,
      },
      stroke: ?string,
      strokeDasharray: ?string,
      strokeOpacity: ?number,
      strokeWidth: ?number,
      unit: ?string,
      value: number,
    }>,
  |} = {
    payload: [],
    label: "",
    active: false,
  }

  renderColourBall: (color: string, isDotted?: boolean) => React.Element<"div"> = (
    color: string,
    isDotted: boolean = false
  ) => {
    const size = 5
    const width = size * 3
    const height = size
    const containerStyles = isDotted ? { paddingLeft: String(size) + "px" } : {}
    const lineStyles = isDotted
      ? { strokeDasharray: String(size) + "," + size, stroke: color, strokeWidth: height, opacity: "0.5" }
      : { stroke: color, strokeWidth: height }
    return (
      <div style={containerStyles}>
        <svg height={height} width={width}>
          <line style={lineStyles} x1="0" x2={width} y1={height / 2} y2={height / 2} />
        </svg>
      </div>
    )
  }

  departmentVarianceData: (departmentData: DepartmentType, bucketData: LiveInsightsDataType) => VarianceDataType = (
    departmentData: DepartmentType,
    bucketData: LiveInsightsDataType
  ): VarianceDataType => {
    const costSelected = this.props.selected === "staff_cost"
    const data = {
      color: departmentData.color,
      name: departmentData.name,
      isLocation: false,
    }
    const momentTime: moment = moment(window.time_formatter.live_insights_date_time(this.props.label), [
      "YYYY-MM-DD h:mm A",
      "YYYY-MM-DD h:mm",
    ])
    const date = momentTime.format(Constants.DATE_FORMAT)
    const time = Formatter.momentToBucketTime(momentTime)
    const relevant_actual_staff_data: ?ArrangedDataType = _.find(bucketData.actual_staff_data, {
      date: date,
      time: time,
    })
    const relevant_rostered_staff_data: ?ArrangedDataType = _.find(bucketData.rostered_staff_data, {
      date: date,
      time: time,
    })
    if (relevant_actual_staff_data == null || relevant_rostered_staff_data == null) {
      // If there is no actual data, then the variance is just going to be the rostered amount
      if (relevant_actual_staff_data == null && relevant_rostered_staff_data != null) {
        const selected_rostered_staff_data = costSelected
          ? relevant_rostered_staff_data.costs
          : relevant_rostered_staff_data.counts
        const rostered =
          selected_rostered_staff_data[String(departmentData.id)] == null
            ? 0
            : selected_rostered_staff_data[String(departmentData.id)]
        const variance = 0 - rostered
        return {
          ...data,
          variance: variance,
        }
      }
      return {
        ...data,
        variance: 0,
      }
    }
    const selected_actual_staff_data = costSelected
      ? relevant_actual_staff_data.costs
      : relevant_actual_staff_data.counts
    const selected_rostered_staff_data = costSelected
      ? relevant_rostered_staff_data.costs
      : relevant_rostered_staff_data.counts

    const actual =
      selected_actual_staff_data[String(departmentData.id)] == null
        ? 0
        : selected_actual_staff_data[String(departmentData.id)]
    const rostered =
      selected_rostered_staff_data[String(departmentData.id)] == null
        ? 0
        : selected_rostered_staff_data[String(departmentData.id)]
    const variance = actual - rostered
    return {
      ...data,
      variance: variance,
    }
  }

  locationVarianceData: (locationData: LocationDataType, bucketData: LiveInsightsDataType) => VarianceDataType = (
    locationData: LocationDataType,
    bucketData: LiveInsightsDataType
  ): VarianceDataType =>
    locationData.departments.reduce(
      (acc, dep) => {
        acc.variance = acc.variance + this.departmentVarianceData(dep, bucketData).variance
        return acc
      },
      {
        variance: 0,
        isLocation: true,
        name: locationData.name,
        color: "",
      }
    )

  visibleDepartmentVarianceData: (
    visibleLocationData: Array<LocationDataType>,
    bucketData: LiveInsightsDataType
  ) => Array<VarianceDataType> = (
    visibleLocationData: Array<LocationDataType>,
    bucketData: LiveInsightsDataType
  ): Array<VarianceDataType> => {
    if (visibleLocationData.length > 1) {
      return visibleLocationData.map((locationData) => this.locationVarianceData(locationData, bucketData))
    } else {
      const location = visibleLocationData[0]
      if (location == null) {
        return []
      }
      return location.departments.map((departmentData) => this.departmentVarianceData(departmentData, bucketData))
    }
  }

  render(): null | React.Element<"div"> {
    const costSelected = this.props.selected === "staff_cost"
    const countSelected = this.props.selected === "staff_count"
    const unit = Constants.LIVE_WAGE_GRAPH_ENTRIES[this.props.selected].actual.unit
    let visibleDepartmentVarianceData = null
    let hiddenVarianceDatas = 0
    if (this.props.bucketData != null && this.props.locationData != null) {
      const bucketData = this.props.bucketData
      const visibleLocationData: Array<LocationDataType> = filterLocationData(
        this.props.locationData,
        this.props.selectedDepartments
      )
      visibleDepartmentVarianceData = this.visibleDepartmentVarianceData(visibleLocationData, bucketData)
      visibleDepartmentVarianceData = _.filter(visibleDepartmentVarianceData, (varData) => varData.variance !== 0)
      visibleDepartmentVarianceData = _.sortBy(visibleDepartmentVarianceData, (varData) =>
        Math.abs(varData.variance)
      ).reverse()
      const MAX_DEPS_TO_SHOW = 4
      const depCount = visibleDepartmentVarianceData.length
      if (depCount > MAX_DEPS_TO_SHOW) {
        visibleDepartmentVarianceData = visibleDepartmentVarianceData.slice(0, MAX_DEPS_TO_SHOW)
        hiddenVarianceDatas = depCount - MAX_DEPS_TO_SHOW
      }
    }
    if (this.props.active && this.props.payload) {
      const momentTime: moment = moment(window.time_formatter.live_insights_date_time(this.props.label), [
        "YYYY-MM-DD h:mm A",
        "YYYY-MM-DD h:mm",
      ])
      const isAfterReferenceLine: boolean =
        this.props.referenceTime != null && momentTime.isAfter(this.props.referenceTime)
      const currentTimeBucket = window.time_formatter.live_feed_time(momentTime)
      const prevTimeBucket = window.time_formatter.live_feed_time(
        momentTime.clone().subtract(Constants.GRAPH_INTERVAL, "minutes")
      )
      const actualLine = _.find(this.props.payload, {
        name: Constants.LIVE_WAGE_GRAPH_ENTRIES[this.props.selected].actual.key,
      })
      const rosteredLine = _.find(this.props.payload, {
        name: Constants.LIVE_WAGE_GRAPH_ENTRIES[this.props.selected].rostered.key,
      })
      const linesToRender = _.compact([actualLine, rosteredLine])
      const totalVariance: ?number =
        actualLine != null && rosteredLine != null ? actualLine.value - rosteredLine.value : null
      return (
        <div className={styles.tooltipWrapper}>
          <div className={styles.tooltipContainer}>
            {costSelected && (
              <div className={styles.tooltipTimeTextContainer}>
                <p>{`${prevTimeBucket} - ${currentTimeBucket}`}</p>
              </div>
            )}
            {countSelected && (
              <div className={styles.tooltipTimeTextContainer}>
                <p>{currentTimeBucket}</p>
              </div>
            )}
            <div className={styles.tooltipLineLabelsContainer}>
              {linesToRender.map((line) => {
                const isActuals = line.name === Constants.LIVE_WAGE_GRAPH_ENTRIES[this.props.selected].actual.key
                const graphInfo = isActuals
                  ? Constants.LIVE_WAGE_GRAPH_ENTRIES[this.props.selected].actual
                  : Constants.LIVE_WAGE_GRAPH_ENTRIES[this.props.selected].rostered
                const textColor = isActuals ? "light" : "lighter"
                return (
                  <div className={styles.tooltipLineContainer} key={line.name}>
                    <GraphLegendIcon chartType={graphInfo.chartType} color={graphInfo.color} />
                    <span className={styles.tooltipLineTextContainer}>
                      <Text color={textColor} type="small">{`${graphInfo.name}:\u00A0\u00A0`}</Text>
                      <Text color={textColor} type="small">
                        {Formatter.formatToUnit(line.value, graphInfo.unit)}
                      </Text>
                    </span>
                  </div>
                )
              })}
              {totalVariance != null && !isAfterReferenceLine && (
                <div className={styles.tooltipLineContainer}>
                  <GraphLegendIcon chartType={"none"} color={""} />
                  <span className={styles.tooltipLineTextContainer}>
                    <Text color="lightest" type="small">
                      {t("variance")}
                    </Text>
                    <Text color={Formatter.varianceColor(totalVariance, "lightest")} type="small">
                      {(totalVariance > 0 ? "+" : "") + Formatter.formatToUnit(totalVariance, unit)}
                    </Text>
                  </span>
                </div>
              )}
              {visibleDepartmentVarianceData != null &&
                visibleDepartmentVarianceData.length !== 0 &&
                !isAfterReferenceLine && (
                  <div className={styles.varianceBreakdownContainer}>
                    <Text bold color="light" type="tiny">{`${t("variance_breakdown")}:\u00A0\u00A0`}</Text>
                    {visibleDepartmentVarianceData.map((varianceData) => (
                      <div className={styles.tooltipLineContainer} key={varianceData.name}>
                        {varianceData.isLocation ? (
                          <Icon color="grey" type="place" />
                        ) : (
                          <GraphLegendIcon chartType="departmentSwatch" color={varianceData.color} />
                        )}
                        <span className={styles.tooltipLineTextContainer}>
                          <Text type="tiny">{varianceData.name}</Text>
                          <Text color={varianceData.variance > 0 ? "danger" : "success"} type="tiny">
                            {(varianceData.variance > 0 ? "+" : "") +
                              Formatter.formatToUnit(varianceData.variance, unit)}
                          </Text>
                        </span>
                      </div>
                    ))}
                    {hiddenVarianceDatas > 0 && (
                      <Text type="tiny">{t("more_hidden_items", { hidden: hiddenVarianceDatas })}</Text>
                    )}
                  </div>
                )}
            </div>
          </div>
        </div>
      )
    }
    return null
  }
}
