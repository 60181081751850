/* @flow */
import * as React from "react"
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  Label,
  Bar,
  ComposedChart,
  Line,
} from "recharts"
import moment from "moment"
import { t as globalT } from "helpers/i18n"
import InsightsChartTooltip from "dashboard/live_insights/views/LiveInsights/InsightsChartTooltip"
import type { RechartsDataType, LocationDataType, DataFiltersType } from "dashboard/live_insights/main"
import Constants from "dashboard/live_insights/helpers/constants"
import styles from "./styles.module.scss"

const t = (key: string, ...args) => globalT(`js.dashboard.widgets.live_insights.graph.${key}`, ...args)

type PropType = {
  data: Array<RechartsDataType>,
  dataFilters: DataFiltersType,
  locationData: ?Array<LocationDataType>,
  time: ?moment,
  togglePredictedLine: boolean,
  toggleRosteredLine: boolean,
}

export default class LiveInsightsChart extends React.PureComponent<PropType> {
  // eslint-disable-next-line flowtype/no-weak-types
  customizedTick: (time: string) => any = (time: string) => window.time_formatter.live_feed_time(time)

  render(): React.Element<"div"> {
    const labelStyles = {
      fill: "#8A9BAE",
      textAnchor: "middle",
    }

    const costSelected = this.props.dataFilters.staff_data_selected === "staff_cost"
    const countSelected = this.props.dataFilters.staff_data_selected === "staff_count"
    const splhSelected = this.props.dataFilters.staff_data_selected === "splh"
    const wagePercentageSelected = this.props.dataFilters.staff_data_selected === "wage_percentage"

    const showSales = this.props.dataFilters.demand_data_selected === "sales"
    const toggleRosteredData = this.props.toggleRosteredLine
    const togglePredictedData = this.props.togglePredictedLine

    return (
      <div className={styles.ChartContainer}>
        <ResponsiveContainer height="100%" width="100%">
          <ComposedChart data={this.props.data} height={150} margin={{ right: 30, top: 20 }} width={730}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              axisLine={{ stroke: "#A1B0C2" }}
              dataKey="time"
              interval={Math.round(this.props.data.length / 7)}
              tick={{ fill: "#708093" }}
              tickFormatter={this.customizedTick}
              tickLine={{ stroke: "#A1B0C2" }}
              xAxisId={0}
            />
            <XAxis dataKey="time" hide interval={Math.round(this.props.data.length / 7)} xAxisId={1} />
            {costSelected && (
              <YAxis
                allowDecimals={false}
                axisLine={{ stroke: "#A1B0C2" }}
                interval={0}
                minTickGap={10}
                tick={{ fill: "#708093" }}
                tickLine={{ stroke: "#A1B0C2" }}
                yAxisId="left"
              >
                <Label angle={-90} offset={20} position="insideLeft" style={labelStyles} value={t("wages")} />
              </YAxis>
            )}
            {countSelected && (
              <YAxis
                allowDecimals={false}
                axisLine={{ stroke: "#A1B0C2" }}
                interval={0}
                minTickGap={10}
                tick={{ fill: "#708093" }}
                tickLine={{ stroke: "#A1B0C2" }}
                yAxisId="left"
              >
                <Label angle={-90} position="center" style={labelStyles} value={t("staff_count")} />
              </YAxis>
            )}
            {splhSelected && (
              <YAxis
                allowDataOverflow
                allowDecimals={false}
                axisLine={{ stroke: "#A1B0C2" }}
                interval={0}
                minTickGap={10}
                tick={{ fill: "#708093" }}
                tickLine={{ stroke: "#A1B0C2" }}
                yAxisId="left"
              >
                <Label angle={-90} position="center" style={labelStyles} value={t("splh")} />
              </YAxis>
            )}
            {wagePercentageSelected && (
              <YAxis
                allowDataOverflow
                allowDecimals={false}
                axisLine={{ stroke: "#A1B0C2" }}
                domain={[0, (dataMax) => (dataMax > 100 ? Math.round(1.1 * dataMax) : 100)]}
                interval={0}
                minTickGap={10}
                tick={{ fill: "#708093" }}
                tickLine={{ stroke: "#A1B0C2" }}
                yAxisId="left"
              >
                <Label angle={-90} position="center" style={labelStyles} value={t("wage_percentage")} />
              </YAxis>
            )}
            {showSales && (
              <YAxis
                allowDataOverflow
                allowDecimals={false}
                axisLine={{ stroke: "#A1B0C2" }}
                interval={0}
                minTickGap={10}
                orientation="right"
                tick={{ fill: "#708093" }}
                tickLine={{ stroke: "#A1B0C2" }}
                yAxisId="right"
              >
                <Label
                  angle={-90}
                  offset={-20}
                  position="right"
                  style={labelStyles}
                  value={Constants.LIVE_INSIGHTS_GRAPH_ENTRIES.sales.actual.name}
                />
              </YAxis>
            )}
            <Tooltip
              content={
                <InsightsChartTooltip
                  locationData={this.props.locationData}
                  referenceTime={this.props.time}
                  selected={this.props.dataFilters.staff_data_selected}
                  selectedDepartments={this.props.dataFilters.departments}
                />
              }
            />
            {showSales && (
              <Bar
                barSize={22 * (Constants.GRAPH_INTERVAL / Constants.BUCKET_INTERVAL)}
                dataKey={Constants.LIVE_INSIGHTS_GRAPH_ENTRIES.sales.actual.key}
                fill={Constants.LIVE_INSIGHTS_GRAPH_ENTRIES.sales.actual.color}
                isAnimationActive={false}
                radius={[
                  2 * (Constants.GRAPH_INTERVAL / Constants.BUCKET_INTERVAL),
                  2 * (Constants.GRAPH_INTERVAL / Constants.BUCKET_INTERVAL),
                  0,
                  0,
                ]}
                xAxisId={0}
                yAxisId="right"
              />
            )}
            {showSales && togglePredictedData && (
              <Bar
                barSize={22 * (Constants.GRAPH_INTERVAL / Constants.BUCKET_INTERVAL)}
                dataKey={Constants.LIVE_INSIGHTS_GRAPH_ENTRIES.sales.rostered.key}
                fill={Constants.LIVE_INSIGHTS_GRAPH_ENTRIES.sales.rostered.color}
                isAnimationActive={false}
                radius={[
                  2 * (Constants.GRAPH_INTERVAL / Constants.BUCKET_INTERVAL),
                  2 * (Constants.GRAPH_INTERVAL / Constants.BUCKET_INTERVAL),
                  0,
                  0,
                ]}
                xAxisId={1}
                yAxisId="right"
              />
            )}
            {toggleRosteredData && (
              <Line
                dataKey={Constants.LIVE_INSIGHTS_GRAPH_ENTRIES[this.props.dataFilters.staff_data_selected].rostered.key}
                dot={false}
                isAnimationActive={false}
                stroke={
                  Constants.LIVE_INSIGHTS_GRAPH_ENTRIES[this.props.dataFilters.staff_data_selected].rostered.color
                }
                strokeDasharray={"3,3"}
                strokeWidth={3}
                type="stepAfter"
                yAxisId="left"
              />
            )}
            <Line
              dataKey={Constants.LIVE_INSIGHTS_GRAPH_ENTRIES[this.props.dataFilters.staff_data_selected].actual.key}
              dot={false}
              isAnimationActive={false}
              stroke={Constants.LIVE_INSIGHTS_GRAPH_ENTRIES[this.props.dataFilters.staff_data_selected].actual.color}
              strokeWidth={3}
              type="stepAfter"
              yAxisId="left"
            />
            {this.props.time && (
              <ReferenceLine
                label={
                  this.props.time && {
                    position: "top",
                    value: `${window.time_formatter.live_feed_time(this.props.time)}`,
                    fill: `${styles.c_lightest_text}`,
                    fontSize: 14,
                  }
                }
                stroke="red"
                strokeWidth={2}
                x={this.props.time && this.props.time.format(Constants.DATE_TIME_FORMAT)}
                yAxisId="left"
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    )
  }
}
