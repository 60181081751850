// @flow
import * as React from "react"
import { CSSTransitionGroup } from "react-transition-group-legacy"
import cn from "classnames"
import _ from "lodash"
import * as OptionHelpers from "components/DropdownList/helpers/option"

import Bubble from "components/Bubble"
import DropdownList from "components/DropdownList"
import Icon from "components/Icon"
import DefaultGroup from "components/DropdownList/DefaultGroup"
import type { SelectEvent, Value } from "components/Select"

import DefaultOption from "./DefaultOption"
import DefaultSelected from "./DefaultSelected"

import styles from "./styles.module.scss"
import type { Props as MainProps, RequiredProps } from "./index"

type Props = {|
  ...MainProps,
  children?: React.Node, // Appears at the top of the dropdown when open
|}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  children: null,
  color: "default",
  disabled: false,
  fixedHeight: false,
  forceShowTip: false,
  Group: DefaultGroup,
  htmlName: null,
  newStyle: false,
  Option: DefaultOption,
  Selected: DefaultSelected,
  tabIndex: 0,
  size: "s",
  tipText: "",
  onBlur: _.noop,
  placeholder: "",
  invalid: false,
}

const ANIMATION_CLASSES = {
  leaveActive: styles.selectedLeaveActive,
  leave: styles.selectedLeave,
  enterActive: styles.selectedEnterActive,
  enter: styles.selectedEnter,
  appearActive: styles.selectedEnterActive,
  appear: styles.selectedEnter,
}

type State = {|
  focusedValue: ?Value,
  isHovered: boolean,
  isNumHovered: boolean,
  isOpen: boolean,
  numHidden: number,
  // this doesnt have good typing
  optionsHidden: Array<any>, // eslint-disable-line flowtype/no-weak-types
|}

const ANIMATION_DURATION = 150

export default class MultiSelect extends React.PureComponent<Props, State> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  constructor(props: Props) {
    super(props)

    this.state = {
      focusedValue: null,
      isOpen: false,
      isHovered: false,
      numHidden: 0,
      optionsHidden: [],
      isNumHovered: false,
    }
  }

  UNSAFE_componentWillMount: () => void = () => {
    // $FlowFixMe flow doesnt recognise this but it exists
    addEventListener("resize", this.handleResize, false)
  }

  componentDidMount: () => void = () => {
    this.setState(this.calcSelectedShowing())
  }

  componentDidUpdate: () => void = () => {
    const newHiddenState = this.calcSelectedShowing()
    if (!_.isEqual(newHiddenState.optionsHidden, this.state.optionsHidden)) {
      this.setState(newHiddenState)
    }
  }

  componentWillUnmount: () => void = () => {
    // $FlowFixMe flow doesnt recognise this but it exists
    removeEventListener("resize", this.handleResize)
  }

  // eslint-disable-next-line flowtype/no-weak-types
  handleFocusNextOption: any = OptionHelpers.offsetFocusedValue(1)
  // eslint-disable-next-line flowtype/no-weak-types
  handleFocusPrevOption: any = OptionHelpers.offsetFocusedValue(-1)
  container: ?HTMLDivElement = null
  handleResize: () => void = () => {
    this.forceUpdate()
  }

  handleMouseEnter: (event: SyntheticMouseEvent<HTMLDivElement>) => void = (
    event: SyntheticMouseEvent<HTMLDivElement>
  ) => {
    this.setState({ isHovered: true })
  }

  handleMouseLeave: (event: SyntheticMouseEvent<HTMLDivElement>) => void = (
    event: SyntheticMouseEvent<HTMLDivElement>
  ) => {
    this.setState({ isHovered: false })
  }

  handleNumMouseEnter: (event: SyntheticMouseEvent<HTMLDivElement>) => void = (
    event: SyntheticMouseEvent<HTMLDivElement>
  ) => {
    this.setState({ isNumHovered: true })
    event.stopPropagation()
  }

  handleNumMouseLeave: (event: SyntheticMouseEvent<HTMLDivElement>) => void = (
    event: SyntheticMouseEvent<HTMLDivElement>
  ) => {
    this.setState({ isNumHovered: false })
    event.stopPropagation()
  }

  handleBlur: (event: SyntheticFocusEvent<HTMLDivElement>) => void = (event: SyntheticFocusEvent<HTMLDivElement>) => {
    const nextTarget = event.relatedTarget || document.activeElement

    // $FlowFixMe flow thinks this is impossible but it appears to work?
    if (!nextTarget || !this.container?.contains(nextTarget)) {
      this.handleClose()
      this.props.onBlur()
    }
  }

  handleKeyDown: (event: SyntheticKeyboardEvent<HTMLDivElement>) => void = (
    event: SyntheticKeyboardEvent<HTMLDivElement>
  ) => {
    switch (event.keyCode) {
      case 13: // enter
        event.preventDefault()
        if (this.state.isOpen && this.state.focusedValue !== null) {
          // $FlowFixMe dirty coerce between event types but its too hard to fix
          const newEvent: SelectEvent = {
            ...event,
            // $FlowFixMe dirty coerce between event types but its too hard to fix
            target: {
              ...event.target,
              value: this.state.focusedValue,
            },
          }
          this.handleItemToggled(newEvent)
        }
        break
      case 27: // escape
        event.preventDefault()
        if (this.state.isOpen) {
          this.container?.focus()
          this.handleClose()
        }
        break
      case 38: // up
        event.preventDefault()
        return this.setState({
          focusedValue: this.handleFocusPrevOption(this.props.options, this.state.focusedValue),
        })
      case 40: // down
        event.preventDefault()
        return this.setState({
          focusedValue: this.handleFocusNextOption(this.props.options, this.state.focusedValue),
        })
    }
  }

  handleClose: () => void = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false })
    }
  }

  handleOpen: () => void = () => {
    if (!this.state.isOpen) {
      this.setState({ isOpen: true })
    }
  }

  handleItemToggled: (event: SelectEvent) => void = (event: SelectEvent) => {
    this.container?.focus()

    const toggleables = event.target.value instanceof Array ? event.target.value : [event.target.value]
    this.props.onChange({
      ...event,
      target: {
        ...event.target,
        name: this.props.htmlName,
        value: toggleables.reduce(
          (acc, toggleable) =>
            acc.includes(toggleable) ? acc.filter((option) => option !== toggleable) : acc.concat(toggleable),
          this.props.value
        ),
      },
    })
  }

  handleOptionClick: (event: SelectEvent) => void = (event: SelectEvent) => {
    this.handleItemToggled(event)
  }

  handleOptionEnter: (event: SelectEvent) => void = (event: SelectEvent) => {
    this.setState({ focusedValue: event.target.value })
  }

  handleOptionLeave: (event: SelectEvent) => void = (event: SelectEvent) => {
    this.setState({ focusedValue: null })
  }

  setReference: (node: null | HTMLDivElement) => void = (node: null | HTMLDivElement) => {
    this.container = node
  }

  calcSelectedShowing: () => {| numHidden?: number, optionsHidden?: Array<string> |} = () => {
    if (!this.container || !this.container.getElementsByClassName(styles.selected)[0]) {
      return Object.freeze({})
    }

    const selectedWidth = this.container.getElementsByClassName(styles.selected)[0].clientWidth

    const childnodes: Array<Node> = Array.from(
      this.container?.getElementsByClassName(styles.selected)[0].childNodes || []
    )

    const extraWidth = this.props.size === "l" ? 9 : 4 // border 2px + marginLeft 2px

    const optionsHidden: Array<string> = []

    childnodes.reduce((accWidth, el) => {
      // $FlowFixMe this is all just a mess
      accWidth + el.clientWidth + extraWidth > selectedWidth ? optionsHidden.push(el.textContent) : null

      // $FlowFixMe this is all just a mess
      return accWidth + el.clientWidth + extraWidth
    }, 0)

    return {
      numHidden: optionsHidden.length,
      optionsHidden: optionsHidden,
    }
  }

  renderNumTooltip: () => React.Node = () => {
    const optionsHidden = this.state.optionsHidden
    return (
      <Bubble hovered={this.state.isNumHovered} position="right" width="auto">
        <div className={styles.hiddenOptionsContainer}>
          {optionsHidden.map((option) => (
            <div className={styles.hiddenOption} key={option}>
              {option}
            </div>
          ))}
        </div>
      </Bubble>
    )
  }

  renderNumHidden: () => React.Element<"div"> = () => (
    <div
      className={cn(styles.numHidden, { [styles.l]: this.props.size === "l" })}
      onMouseEnter={this.handleNumMouseEnter}
      onMouseLeave={this.handleNumMouseLeave}
    >
      +{this.state.numHidden}
      {this.renderNumTooltip()}
    </div>
  )

  renderSelected: () => React.Node | React.Element<"div"> = () => {
    const { Selected } = this.props
    const selectedOptions = this.props.value
      .map((value) => OptionHelpers.find(this.props.options, value))
      .filter((value) => value !== undefined)
    if (this.props.fixedHeight) {
      return (
        <div className={cn(styles.fixedHeight, { [styles.l]: this.props.size === "l" })}>
          <div className={styles.selected}>
            {selectedOptions.map((option, index) => (
              <Selected
                data={option.data}
                disabled={this.props.disabled}
                key={option.value}
                label={option.label}
                onRemove={this.handleOptionClick}
                size={this.props.size}
                tabIndex={this.props.fixedHeight ? -1 : index + 1}
                value={option.value}
              />
            ))}
          </div>
          {this.state.numHidden === 0 ? null : this.renderNumHidden()}
        </div>
      )
    } else {
      return (
        <CSSTransitionGroup
          className={styles.selected}
          transitionAppear
          transitionAppearTimeout={ANIMATION_DURATION}
          transitionEnterTimeout={ANIMATION_DURATION}
          transitionLeaveTimeout={ANIMATION_DURATION}
          transitionName={ANIMATION_CLASSES}
        >
          {selectedOptions.map((option, index) => (
            <Selected
              data={option.data}
              disabled={this.props.disabled}
              key={option.value}
              label={option.label}
              onRemove={this.handleOptionClick}
              size={this.props.size}
              tabIndex={index + 1}
              value={option.value}
            />
          ))}
        </CSSTransitionGroup>
      )
    }
  }

  renderOption: () => React.Element<"div"> | React.Node = () => {
    const { Option } = this.props

    return this.props.value.length ? (
      this.renderSelected()
    ) : (
      <Option label={this.props.placeholder} size={this.props.size} />
    )
  }

  renderInput: () => React.Element<"div"> = () => (
    <div
      className={cn(
        styles.inputContainer,
        styles[this.props.color],
        styles[this.props.size],
        { [styles.open]: this.state.isOpen && !this.props.disabled },
        { [styles.invalid]: this.props.invalid },
        { [styles.newStyle]: this.props.newStyle }
      )}
      disabled={this.props.disabled}
    >
      {this.renderOption()}
      <div className={styles.caret}>
        <Icon color="grey" size={this.props.size === "l" ? "s" : "xs"} type="arrow-drop-down" />
      </div>
      {this.renderTooltip()}
    </div>
  )

  renderTooltip: () => void | React.Node = () => {
    if (this.props.tipText && !this.state.isOpen) {
      return (
        <Bubble hovered={this.props.forceShowTip || (this.state.isHovered && !this.state.isNumHovered)}>
          {this.props.tipText}
        </Bubble>
      )
    }
  }

  render: () => React.Element<"div"> = () => (
    <div
      className={styles.Select}
      disabled={this.props.disabled}
      onBlur={this.handleBlur}
      onClick={this.handleOpen}
      onFocus={this.handleOpen}
      onKeyDown={this.handleKeyDown}
      onMouseEnter={this.handleMouseEnter}
      onMouseLeave={this.handleMouseLeave}
      ref={this.setReference}
      role="button"
      tabIndex={this.state.isOpen ? -1 : this.props.tabIndex}
    >
      {this.renderInput()}
      <DropdownList
        focusedValue={this.state.focusedValue}
        Group={this.props.Group}
        newStyle={this.props.newStyle}
        onClick={this.handleOptionClick}
        onOptionEnter={this.handleOptionEnter}
        onOptionLeave={this.handleOptionLeave}
        open={this.props.disabled ? false : this.state.isOpen}
        Option={this.props.Option}
        options={this.props.options}
        selectedValues={this.props.value}
        size={this.props.size}
      >
        {this.props.children}
      </DropdownList>
    </div>
  )
}
