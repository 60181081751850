// @flow

import convertArrayToCSV from "convert-array-to-csv"
import testId from "helpers/testId"

export default function downloadCSV(
  data: Array<$ReadOnly<{ [string]: mixed }>>,
  filename: string,
  options: { header?: Array<string>, separator?: string } = {}
) {
  const csv = convertArrayToCSV(data, options)
  if (csv == null) {
    return
  }

  // a lot of this was just copied and pasted from stack overflow
  // https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
  const filename_with_ext = filename + ".csv"
  const encoded_csv = encodeURI("data:text/csv;charset=utf-8," + csv)

  const link = document.createElement("a")
  link.setAttribute("href", encoded_csv)
  link.setAttribute("download", filename_with_ext)

  const id = testId("downloadCSV")
  if (id != null) {
    link.setAttribute("data-testid", id)
  }

  // $FlowFixMe - This function will always be run after the document has loaded so document.body will never be null
  document.body.appendChild(link)
  link.click()
}
