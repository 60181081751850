/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { t } from "helpers/i18n"
import * as TimeFormatter from "helpers/time_formatter"

import { AutoHoverBubble } from "components/Bubble"
import styles from "./styles.module.scss"

export default class Segment extends React.PureComponent {
  // https://stackoverflow.com/questions/1912501/unescape-html-entities-in-javascript
  htmlDecode(input) {
    const doc = new DOMParser().parseFromString(input, "text/html")
    return doc.documentElement.textContent
  }

  renderMessage() {
    return t("js.timesheets.shift_card.chart.message", {
      start: TimeFormatter.shiftTimeInput(moment(this.props.awardCache.get("start")), this.props.currentUser),
      end: TimeFormatter.shiftTimeInput(moment(this.props.awardCache.get("finish")), this.props.currentUser),
    })
  }

  render() {
    return (
      <div className={styles.awardCache} style={{ width: this.props.width }}>
        <div className={styles.bar} style={{ backgroundColor: this.props.color }} />
        <AutoHoverBubble offset={0} position="top">
          <div className={styles.message}>
            <div className={styles.noWrap}>{this.htmlDecode(this.props.awardCache.get("name"))}</div>
          </div>
          <div className={styles.units}>
            <div className={styles.noWrap}>{this.props.awardCache.get("breakLength")}</div>
          </div>
          <div className={styles.times}>
            <div className={styles.noWrap}>{this.renderMessage()}</div>
          </div>
        </AutoHoverBubble>
      </div>
    )
  }
}

Segment.defaultProps = { color: "#e8e8e8" }

Segment.propTypes = {
  awardCache: PropTypes.object.isRequired,
  color: PropTypes.string,
  currentUser: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
}
