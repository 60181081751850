/* @flow */
import * as React from "react"
import moment from "moment"
import cn from "classnames"
import _ from "lodash"
import { dataForInsightsChart, activeDataStreams, getSelectedLocationId } from "dashboard/live_insights/selectors"
import Constants from "dashboard/live_insights/helpers/constants"
import Filter from "dashboard/live_insights/helpers/filter"
import { t } from "helpers/i18n"
import Loader from "components/Loader"
import Header from "dashboard/live_insights/components/Header"
import DaySoFarContainer from "dashboard/live_insights/views/LiveInsights/InsightsDaySoFar"
import type {
  LiveInsightsDataType,
  LocationDataType,
  DataFiltersType,
  DataStreamInfoType,
  StaffDataTypes,
  DemandDataTypes,
  MetricTypes,
  FilterOptionsObjectType,
} from "../../main"
import LargeOrgState from "../../../generic/LargeOrgState"
import InsightsChartController from "./InsightsChartController"
import LiveInsightsChart from "./LiveInsightsChart"
import styles from "./styles.module.scss"

export type PropType = {
  allDepartmentIds: Array<number>,
  averageOfDates: boolean,
  canSeeCosts: boolean,
  data: ?LiveInsightsDataType,
  dataStreamInfo: { [ds_id: string]: DataStreamInfoType },
  dateViewing: moment,
  emptyLargeOrg: boolean,
  getDataByLocation: (?Array<number>) => void,
  handleDateChange: () => void,
  hideWidget: ?() => void,
  isBackgroundLoading: boolean,
  isLargeOrg: boolean,
  isLiveInsights: boolean,
  isLoading: boolean,
  loadedLocations: Array<number>,
  locationData: ?Array<LocationDataType>,
  metricType: MetricTypes,
  saveConfigHandler: (isLiveInsights: boolean, metricType: ?MetricTypes) => void,
  showSettingToggle: boolean,
  time: ?moment,
  timeChangeHandler: (timeString: string) => void,
}

export type StateType = {
  dataFilters: DataFiltersType,
}

export default class LiveInsights extends React.PureComponent<PropType, StateType> {
  constructor(props: PropType) {
    super(props)

    const selectedDepartmentsCookie = window.$.cookie(Constants.TEAMS_COOKIE_KEY + window.current_user.id)
    const selectedDepartmentsFromCookie = selectedDepartmentsCookie != null ? JSON.parse(selectedDepartmentsCookie) : []

    const staffDataSelectedCookie = window.$.cookie(Constants.DATATYPE_COOKIE_KEY + window.current_user.id)
    const staffDataSelectedDefault = staffDataSelectedCookie || "staff_count"

    const demandDataSelectedCookie = window.$.cookie(Constants.DEMAND_DATATYPE_COOKIE_KEY + window.current_user.id)
    const demandDataSelectionDefault = demandDataSelectedCookie || "sales"

    const toggleRosteredLineCookie = window.$.cookie(Constants.ROSTERED_DATATYPE_COOKIE_KEY + window.current_user.id)
    const toggleRosteredLineDefault = toggleRosteredLineCookie ? JSON.parse(toggleRosteredLineCookie) : true

    const togglePredictedLineCookie = window.$.cookie(Constants.PREDICTED_DATATYPE_COOKIE_KEY + window.current_user.id)
    const togglePredictedLineDefault = togglePredictedLineCookie ? JSON.parse(togglePredictedLineCookie) : true

    this.state = {
      dataFilters: {
        departments: selectedDepartmentsFromCookie,
        showSales: true,
        staff_data_selected: staffDataSelectedDefault,
        demand_data_selected: demandDataSelectionDefault,
        metricOptions: Constants.METRIC_OPTIONS,
        toggleRosteredLine: toggleRosteredLineDefault,
        togglePredictedLine: togglePredictedLineDefault,
        filterOptions: {
          demand_data: Constants.LIVE_INSIGHTS_DEMAND_DATA_OPTIONS,
          staff_data: this.props.canSeeCosts
            ? Constants.LIVE_INSIGHTS_STAFF_DATA_OPTIONS
            : _.filter<FilterOptionsObjectType>(Constants.LIVE_INSIGHTS_STAFF_DATA_OPTIONS, (opt) => !opt.costRelated),
        },
      },
    }
  }

  handleStaffDataFilterSelectedChange: (event: { +target: { value: StaffDataTypes, ... }, ... }) => void = (event: {
    +target: { value: StaffDataTypes },
  }) => {
    this.setState({ dataFilters: { ...this.state.dataFilters, staff_data_selected: event.target.value } })
    window.$.cookie(Constants.DATATYPE_COOKIE_KEY + window.current_user.id, event.target.value)
  }

  handleDemandDataFilterSelectedChange: (event: { +target: { value: DemandDataTypes, ... }, ... }) => void = (event: {
    +target: { value: DemandDataTypes },
  }) => {
    this.setState({ dataFilters: { ...this.state.dataFilters, demand_data_selected: event.target.value } })
    window.$.cookie(Constants.DEMAND_DATATYPE_COOKIE_KEY + window.current_user.id, event.target.value)
  }

  handleToggleRosterLine: () => () => void = () => () => {
    const toggleRosteredLine = !this.state.dataFilters.toggleRosteredLine

    this.setState({ dataFilters: { ...this.state.dataFilters, toggleRosteredLine: toggleRosteredLine } })
    window.$.cookie(Constants.ROSTERED_DATATYPE_COOKIE_KEY + window.current_user.id, toggleRosteredLine)
  }

  handleTogglePredictedLine: () => () => void = () => () => {
    const togglePredictedLine = !this.state.dataFilters.togglePredictedLine

    this.setState({ dataFilters: { ...this.state.dataFilters, togglePredictedLine: togglePredictedLine } })
    window.$.cookie(Constants.PREDICTED_DATATYPE_COOKIE_KEY + window.current_user.id, togglePredictedLine)
  }

  handleToggleSalesLine: () => () => void = () => () => {
    this.setState({ dataFilters: { ...this.state.dataFilters, showSales: !this.state.dataFilters.showSales } })
  }

  handleTeamFilterOnChange: (event: { +target: { value: Array<number>, ... }, ... }) => void = (event: {
    +target: { value: Array<number> },
  }) => {
    const departments = event.target.value
    const data_streams = activeDataStreams(departments, this.props.locationData, this.props.dataStreamInfo)
    const locationIds = getSelectedLocationId(departments, this.props.locationData, this.props.loadedLocations)
    const departmentsIsEmpty = departments != null && !departments.length
    const locationIdsIsEmpty = locationIds != null && !locationIds.length
    const handleFilterAsLargeOrg = this.props.isLargeOrg && !departmentsIsEmpty && !locationIdsIsEmpty
    const selectedLocationsNotLoaded =
      // $FlowFixMe flow complains locationId's might be null, !locationIdsIsEmpty check handles this.
      locationIdsIsEmpty || (!locationIdsIsEmpty && !locationIds.every((l) => this.props.loadedLocations.includes(l)))

    // Only load new locations if its a large org and we havent already loaded this location.
    if (handleFilterAsLargeOrg && selectedLocationsNotLoaded) {
      this.props.getDataByLocation(locationIds)
    }

    const time = Filter.getUserFilteredTime(
      this.props.data,
      this.props.allDepartmentIds,
      this.props.locationData,
      departments
    )

    this.props.timeChangeHandler(time)
    this.setState({ dataFilters: { ...this.state.dataFilters, departments, data_streams } })
  }

  render(): React.Element<"div"> {
    const graphEntries = Constants.LIVE_INSIGHTS_GRAPH_ENTRIES
    const data_streams = activeDataStreams(
      this.state.dataFilters.departments,
      this.props.locationData,
      this.props.dataStreamInfo
    )
    const chartData = dataForInsightsChart(
      this.props.data,
      this.state.dataFilters.departments,
      this.props.time,
      this.props.canSeeCosts,
      data_streams
    )

    return (
      <div className={styles.Root}>
        <Header
          allDepartmentIds={this.props.allDepartmentIds}
          canSeeCosts={this.props.canSeeCosts}
          dateViewing={this.props.dateViewing}
          handleDateChange={this.props.handleDateChange}
          handleTeamFilterOnChange={this.handleTeamFilterOnChange}
          headerText={t("js.dashboard.widgets.live_insights.header.live_insights")}
          hideWidget={this.props.hideWidget}
          isLargeOrg={this.props.isLargeOrg}
          isLiveInsights={this.props.isLiveInsights}
          isLoading={this.props.isLoading || this.props.isBackgroundLoading}
          loadedLocations={this.props.loadedLocations}
          locationData={this.props.locationData}
          metricType={this.props.metricType}
          saveConfigHandler={this.props.saveConfigHandler}
          selectedDepartments={this.state.dataFilters.departments}
          showSettingToggle={this.props.showSettingToggle}
        />
        {this.props.emptyLargeOrg && (
          <LargeOrgState messageText={t("js.dashboard.widgets.live_insights.large_org_state.message_text")} />
        )}
        {this.props.isLoading && (
          <div className={styles.loadingWrapper}>
            <Loader size="l" />
          </div>
        )}
        {!this.props.emptyLargeOrg && (
          <div
            className={cn(styles.bodyContainer, "not-widget-draggable", { [styles.isLoading]: this.props.isLoading })}
          >
            <div className={styles.leftHandSideContainer}>
              <DaySoFarContainer
                averageOfDates={this.props.averageOfDates}
                canSeeCosts={this.props.canSeeCosts}
                data={chartData}
                dataFilters={this.state.dataFilters}
                dateViewing={this.props.dateViewing}
                graphEntries={graphEntries}
                metricType={this.props.metricType}
                time={this.props.time}
              />
            </div>
            <div className={styles.rightHandSideContainer}>
              <InsightsChartController
                canSeeCosts={this.props.canSeeCosts}
                dataFilters={this.state.dataFilters}
                graphEntries={graphEntries}
                handleDemandDataFilterSelectedChange={this.handleDemandDataFilterSelectedChange}
                handleStaffDataFilterSelectedChange={this.handleStaffDataFilterSelectedChange}
                handleTogglePredictedLine={this.handleTogglePredictedLine}
                handleToggleRosterLine={this.handleToggleRosterLine}
                handleToggleSalesLine={this.handleToggleSalesLine}
              />
              <LiveInsightsChart
                data={chartData}
                dataFilters={this.state.dataFilters}
                locationData={this.props.locationData}
                time={this.props.time}
                togglePredictedLine={this.state.dataFilters.togglePredictedLine}
                toggleRosteredLine={this.state.dataFilters.toggleRosteredLine}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}
