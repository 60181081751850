/* @flow */
import * as React from "react"
import _ from "lodash"
import cn from "classnames"
import moment from "moment"
import Text from "components/Text"
import Icon from "components/Icon"
import Button from "components/Button"
import Loader from "components/Loader"
import { t } from "helpers/i18n"
import type { LocationDataType, MetricTypes } from "dashboard/live_insights/main"
import { AutoHoverBubble } from "../../../components/Bubble"
import LiveInsightsConfigureModal from "./LiveInsightsConfigureModal"
import DepartmentsFilter from "./DepartmentsFilter"
import styles from "./styles.module.scss"
import SelectedDepartments from "./SelectedDepartments"
import DayPicker from "./DayPicker"

type PropType = {
  allDepartmentIds: Array<number>,
  canSeeCosts: ?boolean,
  dateViewing: moment,
  handleDateChange: () => void,
  handleTeamFilterOnChange: ?(event: { +target: { value: Array<number> } }) => void,
  headerText: string,
  hideWidget: ?() => void,
  isLargeOrg: boolean,
  isLiveInsights: boolean,
  isLoading: boolean,
  loadedLocations: Array<number>,
  locationData: ?Array<LocationDataType>,
  metricType: MetricTypes,
  saveConfigHandler: (isLiveInsights: boolean, metricType: ?MetricTypes) => void,
  selectedDepartments: ?Array<number>,
  showPredictionModifier: boolean,
  showSettingToggle: boolean,
}

type StateType = {
  modalState: boolean,
}

export default class Header extends React.PureComponent<PropType, StateType> {
  static defaultProps: PropType = {
    allDepartmentIds: [],
    canSeeCosts: false,
    dateViewing: moment(),
    handleDateChange: _.noop,
    headerText: "live_insights",
    hideWidget: null,
    selectedDepartments: [],
    isLargeOrg: false,
    isLoading: false,
    isLiveInsights: false,
    metricType: "splh",
    loadedLocations: [],
    locationData: null,
    handleTeamFilterOnChange: _.noop,
    saveConfigHandler: _.noop,
    showPredictionModifier: true,
    showSettingToggle: true,
  }
  constructor(props: PropType) {
    super(props)
    this.state = {
      modalState: false,
    }
  }

  handleOpenModal: () => void = () => {
    this.setState({ modalState: true })
  }

  handleCloseModal: () => void = () => {
    this.setState({ modalState: false })
  }

  widgetWrapper: HTMLElement | null = document.querySelector("[data-id='live_insights']")

  toggleFullScreen: () => void = () => {
    if (this.widgetWrapper) {
      if (!document.fullscreenElement) {
        this.widgetWrapper.requestFullscreen()
      } else {
        document.exitFullscreen()
      }
    }
  }

  render(): React.Element<"div"> {
    const { modalState } = this.state
    const selectedDepartments = this.props.selectedDepartments != null ? this.props.selectedDepartments : []

    return (
      <div className={styles.HeaderContainer}>
        <div className={styles.TitleContainer}>
          <div className={styles.LiveOrb} />
          <Text bold type="body">
            {this.props.headerText}
          </Text>
          {this.props.isLoading && (
            <div className={styles.loadingWrapper}>
              <Loader color="grey" size="s" />
            </div>
          )}
        </div>
        {this.props.locationData && this.props.handleTeamFilterOnChange && this.props.selectedDepartments && (
          <div className={cn(styles.HeaderToolsContainer, "not-widget-draggable")}>
            <SelectedDepartments
              allDepartmentIds={this.props.allDepartmentIds}
              isLargeOrg={this.props.isLargeOrg}
              loadedLocations={this.props.loadedLocations != null ? this.props.loadedLocations : []}
              locationData={this.props.locationData != null ? this.props.locationData : []}
              selectedDepartments={selectedDepartments}
            />
            <div className={styles.Actions}>
              <div className={cn(styles.DropdownListContainer, "not-widget-draggable")}>
                <DepartmentsFilter
                  allDepartmentIds={this.props.allDepartmentIds}
                  handleTeamFilterOnChange={this.props.handleTeamFilterOnChange || _.noop}
                  locationData={this.props.locationData || []}
                  value={selectedDepartments}
                />
              </div>
              {this.props.showPredictionModifier && (
                <div className={cn(styles.HistoricalCalendarContainer, "not-widget-draggable")}>
                  <DayPicker dateViewing={this.props.dateViewing} handleDateChange={this.props.handleDateChange} />
                </div>
              )}
              {
                // Flow doesn't like me checking browser compatibility for fullScreen: https://github.com/facebook/flow/issues/8705
                // $FlowFixMe
                this.widgetWrapper && this.widgetWrapper.requestFullscreen && (
                  <div className="mr1">
                    <Button
                      label={t("js.dashboard.widgets.inc.toggle_full_screen")}
                      onClick={this.toggleFullScreen}
                      size="sm"
                      type="ghost"
                    />
                  </div>
                )
              }
              {this.props.showSettingToggle && (
                <div
                  className={styles.SettingsCogContainer}
                  onClick={this.handleOpenModal}
                  onKeyPress={_.noop}
                  role="button"
                  tabIndex={-1}
                >
                  <Icon color="grey" type="settings" />
                </div>
              )}
            </div>
            <LiveInsightsConfigureModal
              canSeeCosts={this.props.canSeeCosts}
              isLiveInsights={this.props.isLiveInsights}
              isOpen={modalState}
              metricType={this.props.metricType}
              onExit={this.handleCloseModal}
              saveConfigHandler={this.props.saveConfigHandler}
            />
          </div>
        )}
        {this.props.hideWidget && (
          <div
            className={styles.visibilityContainer}
            onClick={this.props.hideWidget}
            onKeyPress={_.noop}
            role="button"
            tabIndex={-1}
          >
            <AutoHoverBubble noPointerEvents position="left">
              {t("js.dashboard.widgets.inc.hide_widget")}
            </AutoHoverBubble>
            <Icon color="grey" type="visibility-off" />
          </div>
        )}
      </div>
    )
  }
}
