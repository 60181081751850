/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import styles from "./styles.module.scss"

export default class OptionsContainer extends React.PureComponent {
  render() {
    return <div className={styles.optionsContainer}>{this.props.children}</div>
  }
}

OptionsContainer.propTypes = { children: PropTypes.node.isRequired }
