// @flow

import React, { useState, useMemo } from "react"
import ReactDOM from "react-dom"
import cn from "classnames"
import { t } from "helpers/i18n"

import Button from "components/Button"
import Input from "components/Input"
import Modal, { Header, Footer, FooterRight } from "components/Modal"
import Currency from "helpers/currency"
import styles from "./styles.module.scss"

type Props = {
  canSeeCosts: boolean,
  closeModal: () => void,
  costVariance: number,
  open: boolean,
  submit: (string, boolean) => mixed,
  timeVariance: {
    hours: number,
    minutes: number,
  },
}

// eslint-disable-next-line flowtype/no-weak-types
const ShiftVarianceModal = (props: Props): any => {
  const [comment, setComment] = useState("")

  // Clean up our raw values once for visualisation.
  const timeVariance = useMemo(
    () => ({ hours: Math.abs(props.timeVariance.hours), minutes: Math.abs(props.timeVariance.minutes) }),
    [props.timeVariance]
  )
  const costVariance = useMemo(() => Currency.from(props.costVariance), [props.costVariance])
  const canSeeCosts = props.canSeeCosts

  const handleSubmit = () =>
    comment &&
    props.submit(
      `${t("js.timesheets.shift_variance_modal.comment_prefix", {
        hours: timeVariance.hours,
        minutes: timeVariance.minutes,
        cost: costVariance,
        comment,
      })}`,
      true
    )
  if (document.body == null) {
    return null
  }
  const body = document.body
  return ReactDOM.createPortal(
    <Modal exitable onExit={props.closeModal} open={props.open} size={"s"}>
      <Header titleText={t("js.timesheets.shift_variance_modal.modal_title")} />
      <ol className={styles.orderedList}>
        {canSeeCosts && (
          <li className={styles.listItem}>
            <span className={styles.listItemValue}>{t("js.timesheets.shift_variance_modal.cost_variance")}</span>
            <span
              className={cn(
                styles.listItemValue,
                props.costVariance > 0
                  ? styles.positiveText
                  : props.costVariance < 0
                  ? styles.negativeText
                  : styles.neutralText
              )}
            >
              {costVariance}
            </span>
          </li>
        )}
        <li className={styles.listItem} style={{ borderTop: "1px solid gray" }}>
          <span className={styles.listItemValue}>{t("js.timesheets.shift_variance_modal.time_variance")}</span>
          <span
            className={cn(
              styles.listItemValue,
              props.timeVariance.minutes < 0 || props.timeVariance.hours < 0
                ? styles.positiveText
                : props.timeVariance.minutes > 0 || props.timeVariance.hours > 0
                ? styles.negativeText
                : styles.neutralText
            )}
          >
            {t("js.timesheets.shift_variance_modal.time_variance_in_modal", {
              hours: Math.abs(timeVariance.hours),
              minutes: Math.abs(timeVariance.minutes),
            })}
          </span>
        </li>
      </ol>
      <div className={styles.inputContainer}>
        <Input
          onChange={(e) => setComment(e.target.value)}
          placeholder={t("js.timesheets.shift_variance_modal.comment_placeholder")}
          value={comment}
        />
      </div>

      <Footer>
        <FooterRight>
          <Button label={t("js.timesheets.shift_variance_modal.approve")} onClick={handleSubmit} type="theme" />
        </FooterRight>
      </Footer>
    </Modal>,
    body
  )
}

export default ShiftVarianceModal
