/**
 * @flow
 */

import * as NumberHelpers from "helpers/number_helpers"

/**
 * takes a number and converts it to a local currency
 *
 * note: there is also `negative_format` in rails. if it is defined then it is
 * used for negative numbers. if it isn't defined then it defaults to prepending
 * "-". none of our locales have a different negative format at the moment so we
 * haven't implemented `negative_format` here yet.
 */
export const from = (
  value: string | number,
  precision: number = localPrecision(),
  symbol: string = localSymbol()
): string => {
  if (typeof value === "string") {
    return formatString(value)
  }

  return formatCurrency(getParts(value, localHasSubunits() ? precision : 0), symbol || localSymbol())
}

/**
 * takes a number and precision and returns the unit and subunit parts
 * @returns {{units: string, subunits: string, isNegative: boolean}}
 */
const getParts = (value, precision) => {
  const rounded = NumberHelpers.roundWithDecimals(value, precision)
  const parts = {}
  parts.isNegative = rounded < 0
  if (precision > 0) {
    const [units, subunits] = Math.abs(rounded).toFixed(precision).split(".")
    parts.units = units
    parts.subunits = subunits
  } else {
    parts.units = Math.abs(rounded).toString()
    parts.subunits = null
  }
  return parts
}

/**
 * takes a string and adds delimiters between every three digits
 */
const makeDelimited = (units) => units.split(/(?=(?:.{3})+$)/).join(localDelimiter())

/**
 * returns the correct number format to use
 */
const getFormat = (isNegative) => {
  if (isNegative) {
    return `-${localFormat()}`
  }
  return localFormat()
}

/**
 * takes any string, a format (with %n and %u), and a symbol, and returns the
 * formatted string
 */
const formatString = (string, format = localFormat(), symbol = localSymbol()) =>
  format.replace("%n", string).replace("%u", symbol)

/**
 * takes a string number and puts the currency symbol in the correct position
 */
const formatCurrency = (parts, symbol) => {
  let strValue = makeDelimited(parts.units)
  const subUnits = parts.subunits
  if (subUnits) {
    strValue += localSeparator() + subUnits
  }
  return formatString(strValue, getFormat(parts.isNegative), symbol)
}

/**
 * takes a property name and returns the value on window.i18n_props.currency
 * eslint disable due to what looks like a false positive, caused by the flow generic type
 */
const getCurrencyProp = <T>(prop: string, defaultValue: T): T => {
  const i18n = window.i18n_props
  if (i18n && i18n.currency && i18n.currency.hasOwnProperty(prop)) {
    return i18n.currency[prop]
  }
  return defaultValue
}

/**
 * returns the local precision (defaults to 2)
 * this determines the number of decimal places shown after the separator. a
 * value of 0 will yield no separator or decimal places.
 * like all currency props it's sourced from `I18n.t('number.currency.format')` on the serverside
 */
const localPrecision = () => getCurrencyProp("precision", 2)

/*
 * returns the precision you should use for displaying high-precision costs
 * use this on the award table at the bottom of timesheets
 * don't change this without considering the side effects and getting everyone to agree on it
 * in Australia pay rates can be specified with up to 4dp/hour so we should honour that
 */
const highPrecisionForCosts = (): number => 4

/**
 * returns the local symbol (defaults to "$")
 */
const localSymbol = (): string => getCurrencyProp("unit", "$")

/**
 * returns the local format (defaults to "%u%n")
 * %u is the currency symbol
 * %n is the currency value
 */
const localFormat = () => getCurrencyProp("format", "%u%n")

/**
 * returns the local delimiter (defaults to ",")
 * it is inserted between every group of three digits going right to left.
 * e.g. $1,234,567.89
 *        ^   ^
 */
const localDelimiter = () => getCurrencyProp("delimiter", ",")

/**
 * returns the local separator (defaults to ".")
 * the separator comes immediately after the units and before the subunits.
 * e.g. $12.99
 *         ^
 */
const localSeparator = () => getCurrencyProp("separator", ".")

/**
 * returns the local subunits boolean (defaults to true)
 * if this is false then we never show a separator or subunits regardless of
 * specified precision.
 * e.g. Australian dollars have subunits but the yen does not.
 */
const localHasSubunits = () => (window.hasOwnProperty("locale_has_subunits") ? window.locale_has_subunits : true)

export default { from, localSymbol, highPrecisionForCosts }
