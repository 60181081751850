// @flow

import * as React from "react"
import { noop } from "lodash"
import styles from "./styles.module.scss"
import Bubble, { type Props as BubbleProps } from "./Bubble"

/*
 * PLEASE NOTE:
 * When using this component, elements at the same level/lower in the dom won't get pointer events.
 * If you want pointer events for these elements, use the `Bubble` component and hold the `hovered` state yourself.
 */

type RequiredProps = {|
  children: React.Node,
|}

export type Props = BubbleProps
type State = {|
  hovered: boolean,
|}

export default class AutoHoverBubble extends React.Component<Props, State> {
  onMouseMove: () => void
  onMouseLeave: () => void

  static defaultProps: $Diff<Props, RequiredProps> = Bubble.defaultProps

  constructor(props: Props) {
    super(props)
    /**
     * onMouseMove is the selected event used to detect hover because
     * it does not fire when scrolling. This is not usually expected
     * by the user, and also can be a performance burden while scrolling
     * intensive views.
     */
    this.onMouseMove = () => this.setState({ hovered: true })
    this.onMouseLeave = () => this.setState({ hovered: false })

    this.state = { hovered: props.hovered }
  }

  render(): React.Element<"div"> {
    return (
      <div
        className={styles.AutoHoverBubble}
        onFocus={noop}
        onMouseLeave={this.onMouseLeave}
        onMouseMove={this.props.hovered || this.state.hovered ? noop : this.onMouseMove}
      >
        <Bubble {...this.props} hovered={this.props.hovered || this.state.hovered}>
          {this.props.children}
        </Bubble>
      </div>
    )
  }
}
