/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import cn from "classnames"

import * as Routes from "helpers/routes"
import { t as globalT } from "helpers/i18n"

import { AutoHoverBubble } from "components/Bubble"
import styles from "./styles.module.scss"

const t = (key, props) => globalT(`js.rosters.${key}`, props)

export default class HolidayIcon extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.loadHolidayData(this.props.date)
  }

  componentDidUpdate(prevProps) {
    if (this.props.date.format() !== prevProps.date.format()) {
      this.setState({ isHoliday: undefined })
      this.loadHolidayData(this.props.date)
    }
  }

  loadHolidayData(date) {
    return axios
      .post(Routes.is_holiday_path(), {
        date: date.format(),
        user_id: this.props.userId,
      })
      .then((res) => {
        this.setState({ isHoliday: res.data })
      })
  }

  render() {
    return (
      <div>
        {this.state.isHoliday && (
          <div className={styles.holidayDiv}>
            <span
              className={cn({
                mi: true,
                "mi-event": true,
                [styles[this.props.size]]: true,
                [styles.icon]: true,
              })}
              style={{ padding: this.props.padding, margin: this.props.margin }}
            />
            <AutoHoverBubble>
              <span className={styles.tip}>{t("public_holiday")}</span>
            </AutoHoverBubble>
          </div>
        )}
      </div>
    )
  }
}

HolidayIcon.propTypes = {
  date: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  size: PropTypes.oneOf(["s", "m", "l"]),
  padding: PropTypes.string,
  margin: PropTypes.string,
}

HolidayIcon.defaultProps = { size: "m", padding: "0", margin: "0" }
