// @flow

import moment from "moment"
import * as React from "react"
import { connect, type ConnectedComponent } from "react-redux"
import * as Routes from "helpers/routes"
import Request from "helpers/request"
import Currency from "helpers/currency"
import { t as globalT } from "helpers/i18n"
import { getFirstName } from "helpers/string"
import { Footer, FooterRight, FooterLeft } from "components/Modal"
import Button from "components/Button"
import Icon from "components/Icon"
import Avatar from "components/Avatar"
import type { MappedPlan, Users, ScheduleSwapPlan } from "../../logic/types"
import Actions from "../../logic/actions"
import styles from "./styles.module.scss"

const t = (key, opts) => globalT(`js.shift_swaps.landing.modal.${key}`, opts)

export type Props = {
  onBack: () => void,
  onConfirm: () => void,
  onExit: () => void,
  plan: MappedPlan,
  selectedProposalIds: Array<number>,
  updatePlans: ({ id: number, plan: ScheduleSwapPlan }) => Promise<*>,
  users: Users,
}

type State = {
  loading: boolean,
}

function getCostDifference(previous: number, ifSwapped: number): string {
  const difference = ifSwapped - previous
  if (difference === 0) {
    return "Same"
  }
  return Currency.from(difference)
}

function getCostColour(previous: number, ifSwapped: number): string {
  if (previous >= ifSwapped) {
    return "varianceGood"
  }
  return "varianceBad"
}

export class CurateConfirmation extends React.Component<Props, State> {
  state: State = { loading: false }

  curate: () => void = () => {
    const plan_id = this.props.plan.id
    const proposal_ids = this.props.selectedProposalIds

    this.setState({ loading: true }, () => {
      Request.put(
        Routes.shift_swap_plan_curate_path({
          plan_id,
          proposal_ids,
        })
      )
        .then((res) => {
          this.props.onConfirm()
          this.props.onExit()
          return res
        })
        .then((res) => {
          this.props.updatePlans({
            id: res.data.id,
            plan: res.data,
          })
        })
    })
  }

  render(): React.Node {
    const selectedProposals = this.props.plan.proposals.filter((p) => this.props.selectedProposalIds.includes(p.id))
    const startMoment = moment(this.props.plan.schedule_data.start)
    const finishMoment = moment(this.props.plan.schedule_data.finish)
    return (
      <React.Fragment>
        <div className={styles.confirmContent}>
          <Icon color="success" size="l" type="check-circle" />
          <div className={styles.confirmTitle}>
            {this.props.plan.is_vacant
              ? t("curate_confirmation_vacant", {
                  newEmployee: getFirstName(this.props.users[selectedProposals[0].user_id.toString()].name),
                })
              : this.props.plan.proposals === 1
              ? t("curate_confirmation_title_one", {
                  previousEmployee: getFirstName(this.props.plan.user.name),
                  newEmployee: getFirstName(this.props.users[selectedProposals[0].user_id.toString()].name),
                })
              : t("curate_confirmation_title_other", {
                  previousEmployee: getFirstName(this.props.plan.user.name),
                  number: selectedProposals.length,
                })}
          </div>
          <div className={styles.confirmShiftDetail}>
            {`${startMoment.format("D MMMM, h:mma")} - ${
              startMoment.day() !== finishMoment.day()
                ? finishMoment.format("D MMMM, h:mma")
                : finishMoment.format("h:mma")
            }`}
          </div>
          <div className={styles.confirmCurated}>
            {selectedProposals.map((p) => {
              const user = this.props.users[p.user_id.toString()]
              return (
                <div className={styles.confirmCuratedEmployee} key={p.user_id}>
                  <div className={styles.nameAndAvatar}>
                    <Avatar alt={user.name} size="s" source={user.avatar} type="round" />
                    <div className={styles.curateName}>{user.name}</div>
                  </div>
                  {p.cost != null ? (
                    <div className={styles[getCostColour(this.props.plan.schedule_data.cost, p.cost)]}>
                      {getCostDifference(this.props.plan.schedule_data.cost, p.cost)}
                    </div>
                  ) : null}
                </div>
              )
            })}
          </div>
        </div>
        <Footer>
          <FooterRight>
            <Button label={t("confirm")} loading={this.state.loading} onClick={this.curate} type="theme" />
          </FooterRight>
          <FooterLeft>
            <Button
              disabled={this.state.loading}
              label={globalT(`js.generic_components.modal.back_aria`)}
              onClick={this.props.onBack}
              type="ghost"
            />
          </FooterLeft>
        </Footer>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (store, props) => ({ users: props.users || store.users })
const mapDispatchToProps = { updatePlans: Actions.plansUpdate }

// $FlowFixMe flow >0.85 requires more types for connect
/* eslint-disable flowtype/no-weak-types */
export default (connect(mapStateToProps, mapDispatchToProps)(CurateConfirmation): Class<
  ConnectedComponent<any, typeof CurateConfirmation>
> &
  typeof CurateConfirmation)
/* eslint-enable flowtype/no-weak-types */
