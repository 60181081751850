/* @flow */

import * as React from "react"
import styles from "./styles.module.scss"

type Props = {|
  children: React.Node,
|}

export default function AccordionBody(props: Props): React.Element<"div"> {
  return (
    <div className={styles.accordionBody}>
      <div className={styles.contents}>{props.children}</div>
    </div>
  )
}
