// @flow

import * as React from "react"
import Notice from "components/Notice"

type Props = {|
  +message: string,
  +type: "notice" | "alert",
|}

export default function Flash({ message, type }: Props): React.Element<typeof Notice> {
  const alertType = type === "notice" ? "success" : "danger"
  return <Notice isDismissable title={message} type={alertType} />
}

Flash.defaultProps = {
  message: "Not Authorized!",
  type: "alert",
}
