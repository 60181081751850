// @flow
import nanoid from "nanoid"
import _, { sortBy, max } from "lodash"

import * as M from "helpers/marshall"
import * as Assoc from "./association"
import * as Field from "./field"
import * as Record from "./record"

export type Schema = {|
  +associations: Array<Assoc.Schema>,
  +createable: boolean,
  +fields: Array<Field.Schema>,
  +hidden: boolean,
  +id: string,
  +name: string,
  +native: boolean,
  +nativeType: ?string,
  +pluralName: string,
  +uuid: string,
|}

const DEFAULTS = {
  associations: [],
  createable: false,
  fields: [],
  hidden: false,
  id: "",
  name: "",
  native: false,
  nativeType: null,
  pluralName: "",
}

export function nameExists(model: Schema, field: Field.Schema): boolean {
  return model.fields
    .filter((f) => f.uuid !== field.uuid)
    .map((f) => f.name)
    .includes(field.name)
}

export function primaryKey(model: Schema): string {
  const primaryField = model.fields.find((f) => f.primary)

  return primaryField != null ? primaryField.key : ""
}

export function validateRequiredFields(model: Schema, record: Record.Schema): Array<Field.Schema> {
  return model.fields.filter((f) => f.required).filter((f) => record[f.key] === "" || record[f.key] == null)
}

export function validateRequiredAssociations(model: Schema, record: Record.Schema): Array<Assoc.Schema> {
  return model.associations.filter((f) => f.required).filter((f) => Assoc.isRequired(f, record[f.key]))
}

export function appendField(m: Schema, f: Field.Schema): Schema {
  const maxSortOrder = max(m.fields.map((f) => f.sortOrder)) || 0
  return {
    ...m,
    fields: m.fields.concat({ ...f, sortOrder: maxSortOrder + 1 }),
  }
}

export function updateField(m: Schema, f: Field.Schema): Schema {
  return {
    ...m,
    fields: m.fields.map((curr) => (curr.uuid === f.uuid ? f : curr)),
  }
}

export const prune: (Schema) => Schema = _.flowRight(pruneFields, pruneOwnedAssocs)

export function pruneFields(m: Schema): Schema {
  return {
    ...m,
    fields: m.fields.filter((f) => !Field.isDestroyed(f)),
  }
}

export function appendOwnedAssoc(model: Schema, assoc: Assoc.Schema): Schema {
  const maxSortOrder = max(model.associations.map((a) => a.sortOrder)) || 0
  return {
    ...model,
    associations: model.associations.concat({ ...assoc, sortOrder: maxSortOrder + 1 }),
  }
}

export function updateOwnedAssoc(model: Schema, assoc: Assoc.Schema): Schema {
  return {
    ...model,
    associations: model.associations.map((curr) => (curr.uuid === assoc.uuid ? assoc : curr)),
  }
}

export function pruneOwnedAssocs(model: Schema): Schema {
  return {
    ...model,
    associations: model.associations.filter((f) => !Assoc.isDestroyed(f)),
  }
}

export function updatePrimaryField(model: Schema, uuid: string): Schema {
  return {
    ...model,
    fields: model.fields.map((f) => ({ ...f, primary: f.uuid === uuid })),
  }
}

export function updateFieldSortOrder(model: Schema, fields: Array<Field.Schema>): Schema {
  const sortedFields = fields.map((f, i) => ({
    ...f,
    sortOrder: i + 1, // add 1 so that sort orders are 1-indexed, not 0-indexed
  }))

  return {
    ...model,
    fields: sortedFields,
  }
}

export function updateAssocSortOrder(model: Schema, associations: Array<Assoc.Schema>): Schema {
  const sortedAssocs = associations.map((a, i) => ({
    ...a,
    sortOrder: i + 1, // add 1 so that sort orders are 1-indexed, not 0-indexed
  }))

  return {
    ...model,
    associations: sortedAssocs,
  }
}

export function updateFromEvent(m: Schema, e: SyntheticInputEvent<HTMLElement>): Schema {
  return {
    ...m,
    [e.target.name]: e.target.value,
  }
}

export function create(m: $Shape<Schema> = {}): Schema {
  return {
    ...DEFAULTS,
    ...m,
    uuid: nanoid(),
  }
}

export function patch(curr: Schema, next: Schema): Schema {
  return {
    ...curr,
    id: next.id,
    associations: curr.associations.map((aa) => {
      const bb = next.associations.find((f) => f.uuid === aa.uuid)
      return bb == null ? aa : Assoc.patch(aa, bb)
    }),
    fields: curr.fields.map((aa) => {
      const bb = next.fields.find((f) => f.uuid === aa.uuid)
      return bb == null ? aa : Field.patch(aa, bb)
    }),
  }
}

export function fromJson(data: mixed): Schema {
  const m = M.object(data)
  const fields = M.withDefault([], () => M.array(m.fields).map(Field.fromJson))
  const associations = M.withDefault([], () => M.array(m.associations).map(Assoc.fromJson))

  return {
    associations: sortBy(associations, (a) => a.sortOrder),
    createable: M.boolean(m.createable),
    fields: sortBy(fields, (f) => f.sortOrder),
    hidden: M.boolean(m.hidden),
    id: M.number(m.id).toString(),
    name: M.string(m.name),
    native: M.boolean(m.native),
    nativeType: M.maybeString(m.native_type),
    pluralName: M.string(m.plural_name),
    uuid: M.string(m.uuid),
  }
}

export function toPayload(m: Schema): mixed {
  return {
    hidden: m.hidden,
    fields_attributes: m.fields.map(Field.toPayload),
    id: m.id,
    name: m.name,
    native_type: m.nativeType,
    associations_attributes: m.associations.map(Assoc.toPayload),
    plural_name: m.pluralName,
    uuid: m.uuid,
  }
}

export function isFile(m: Schema): boolean %checks {
  return m.native && m.nativeType === "file"
}
