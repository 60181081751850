/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import styles from "./styles.module.scss"

export default function Description(props) {
  return <div className={styles.description}>{props.text}</div>
}

Description.propTypes = { text: PropTypes.string.isRequired }
