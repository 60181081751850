// @flow
import * as M from "helpers/marshall"

export type OptionListType = $ReadOnlyArray<{|
  +label: string,
  +value: string,
|}>

export function marshal(value: mixed): OptionListType {
  return M.array(value)
    .map(M.object)
    .map((v: M.MixedObject) => ({ label: M.string(v.label), value: M.string(v.value) }))
}
