/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import cn from "classnames"

import * as Shift from "timesheets/models/shift"
import * as User from "timesheets/models/user"

import ClearButton from "./ClearButton"
import CopyButton from "./CopyButton"
import HideButton from "./HideButton"
import LeaveButton from "./LeaveButton"
import NotesButton from "./NotesButton"

import styles from "./styles.module.scss"

export default class Controls extends React.Component {
  constructor(props) {
    super(props)
    this.setTipPlacement = this.setTipPlacement.bind(this)
    this.state = { tipPosition: window.innerWidth < 1600 ? "left" : "right" }
  }

  UNSAFE_componentWillMount() {
    addEventListener("resize", this.setTipPlacement, false)
  }

  shouldComponentUpdate({ shift, timesheet }, nextState) {
    return shift !== this.props.shift || timesheet !== this.props.timesheet || nextState !== this.state
  }

  componentWillUnmount() {
    removeEventListener("resize", this.setTipPlacement)
  }

  setTipPlacement() {
    const nextTipPosition = window.innerWidth < 1600 ? "left" : "right"

    if (this.state.tipPosition !== nextTipPosition) {
      this.setState({ tipPosition: nextTipPosition })
    }
  }

  render() {
    const canUpdate = this.props.shift.getIn(["permitted_actions", "update"])

    return (
      <div className={cn(styles.Controls, "do-not-print")}>
        {!canUpdate || Shift.isEmpty(this.props.shift) ? (
          <HideButton actions={this.props.actions} shift={this.props.shift} tipPosition={this.state.tipPosition} />
        ) : (
          <ClearButton
            actions={this.props.actions}
            shift={this.props.shift}
            timesheet={this.props.timesheet}
            tipPosition={this.state.tipPosition}
          />
        )}
        {canUpdate ? (
          <CopyButton
            actions={this.props.actions}
            shift={this.props.shift}
            timesheet={this.props.timesheet}
            tipPosition={this.state.tipPosition}
          />
        ) : null}
        {User.canCreateLeave(this.props.currentUser) ? (
          <LeaveButton
            actions={this.props.actions}
            shift={this.props.shift}
            timesheet={this.props.timesheet}
            tipPosition={this.state.tipPosition}
          />
        ) : null}

        <NotesButton
          actions={this.props.actions}
          currentUser={this.props.currentUser}
          shift={this.props.shift}
          tipPosition={this.state.tipPosition}
        />
      </div>
    )
  }
}

Controls.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  currentUser: PropTypes.object.isRequired,
  shift: PropTypes.object.isRequired,
  timesheet: PropTypes.object.isRequired,
}
