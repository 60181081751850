// @flow

import * as React from "react"
import styles from "./styles.module.scss"

type RequiredProps = {|
  children: React.Node,
|}

type Props = {|
  ...RequiredProps,
|}

export default class BodyContainer extends React.PureComponent<Props> {
  render(): React.Element<"div"> {
    return <div className={styles.bodyContainer}>{this.props.children}</div>
  }
}
