/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

// based on app/views/timesheets_workflow/_cost_totals_tooltip.html.haml

import * as React from "react"
import PropTypes from "prop-types"
import cn from "classnames"

import { t as globalT } from "helpers/i18n"

import Currency from "helpers/currency"
import { AutoHoverPopover } from "components/Popover"

import styles from "./styles.module.scss"

const t = (key) => globalT(`js.timesheets.totals_bar.total_cost_popover.${key}`)

export default class CostTotalsPopover extends React.PureComponent {
  render() {
    const showAllowances = this.props.allowances > 0
    const showLeave = this.props.leave > 0

    return (
      <AutoHoverPopover offset={this.props.offset} onClick={this.props.onClick} title={t("title")}>
        <div className={styles.costPopover}>
          {this.props.showWorked ? (
            <div className={styles.detail}>
              <span className={styles.detailTitle}>{t("worked")}</span>
              <span className={styles.detailValue}>
                {Currency.from(this.props.worked)}
                <span className={styles.detailValueAdditional}>{this.props.workedHours}</span>
              </span>
            </div>
          ) : null}

          <div className={styles.detail}>
            <span className={styles.detailTitle}>{t("rostered")}</span>
            <span className={styles.detailValue}>
              {Currency.from(this.props.rostered)}
              <span className={styles.detailValueAdditional}>{this.props.rosteredHours}</span>
            </span>
          </div>

          {this.props.showWorked ? (
            <div className={styles.detail}>
              <span className={styles.detailTitle}>{t("variance")}</span>
              <span
                className={cn(
                  styles.detailValue,
                  this.props.variance > 0 ? styles.positiveValue : this.props.variance < 0 ? styles.negativeValue : null
                )}
              >
                {Currency.from(this.props.variance)}
              </span>
            </div>
          ) : null}

          {showLeave ? (
            <div className={styles.message}>
              {this.props.showWorked ? t("excludes_leave_and_allowances") : t("excludes_leave")}
            </div>
          ) : null}

          {showAllowances || showLeave ? <hr className={styles.divider} /> : null}

          {showAllowances ? (
            <div className={styles.detail}>
              <span className={styles.detailTitle}>{t("allowances")}</span>
              <span className={styles.detailValue}>{Currency.from(this.props.allowances)}</span>
            </div>
          ) : null}

          {showLeave ? (
            <div className={styles.detail}>
              <span className={styles.detailTitle}>{t("leave")}</span>
              <span className={styles.detailValue}>
                {Currency.from(this.props.leave)}
                <span className={styles.detailValueAdditional}>{this.props.leaveHours}</span>
              </span>
            </div>
          ) : null}

          {showAllowances || showLeave ? (
            <div className={styles.message}>{t(this.props.showWorked ? "message" : "message_roster_only")}</div>
          ) : null}
        </div>
      </AutoHoverPopover>
    )
  }
}

CostTotalsPopover.defaultProps = {
  allowances: null,
  leave: null,
  leaveHours: null,
  rostered: null,
  rosteredHours: null,
  variance: null,
  worked: null,
  workedHours: null,
  showWorked: true,
  onClick: null,
}

CostTotalsPopover.propTypes = {
  allowances: PropTypes.number,
  leave: PropTypes.number,
  leaveHours: PropTypes.string,
  rostered: PropTypes.number,
  rosteredHours: PropTypes.string,
  variance: PropTypes.number,
  worked: PropTypes.number,
  workedHours: PropTypes.string,
  showWorked: PropTypes.bool,
  offset: PropTypes.number.isRequired,
  onClick: PropTypes.func,
}
