/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import Icon from "../Icon"
import styles from "./styles.module.scss"

export default function Title(props) {
  return (
    <div className={cn(styles.title, { [styles.withIcon]: props.icon })}>
      {props.icon ? (
        <div className={cn(styles.titleIcon, styles[`iconColor_${props.iconColor}`])}>
          <Icon color="white" size="xs" type={props.icon} />
        </div>
      ) : null}
      {props.text}
    </div>
  )
}

Title.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconColor: PropTypes.oneOf(["information", "success", "warning"]),
}

Title.defaultProps = {
  iconColor: "information",
  icon: null,
}
