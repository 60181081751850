// @flow
import * as React from "react"
import { noop } from "lodash"
import cn from "classnames"

import Bubble from "components/Bubble"
import DropdownList, { type Props as DropdownListProps } from "components/DropdownList"
import Icon from "components/Icon"
import type { Value } from "components/Select"
import styles from "./styles.module.scss"

export type RequiredProps = {|
  Group: $PropertyType<DropdownListProps, "Group">,
  input: React.Node,
  onClick: () => mixed,
  onClose: () => mixed,
  onKeyDown: (event: SyntheticKeyboardEvent<HTMLInputElement>) => mixed,
  onOptionClick: $PropertyType<DropdownListProps, "onClick">,
  onOptionEnter: $PropertyType<DropdownListProps, "onOptionEnter">,
  onOptionLeave: $PropertyType<DropdownListProps, "onOptionLeave">,
  open: boolean,
  Option: $PropertyType<DropdownListProps, "Option">,
  options: $PropertyType<DropdownListProps, "options">,
  size: $PropertyType<DropdownListProps, "size">,
  tabIndex: number,
|}

export type Props = {|
  ...RequiredProps,
  color: string,
  customButton?: React.Node,
  disabled: boolean,
  focusedValue: Value,
  forceShowTip: boolean,
  highlightColour: ?string,
  listAutoWidth: $PropertyType<DropdownListProps, "listAutoWidth">,
  newStyle: boolean,
  onFocus: () => mixed,
  onMouseEnter: (event: MouseEvent) => mixed,
  onMouseLeave: (event: MouseEvent) => mixed,
  selectedValues: $PropertyType<DropdownListProps, "selectedValues">,
  testId: ?string,
  tipText: ?string,
  updateRef: (node: HTMLDivElement | null) => mixed,
  width: string,
|}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  color: "default",
  customButton: null,
  disabled: false,
  focusedValue: undefined,
  forceShowTip: false,
  highlightColour: null,
  onFocus: noop,
  onMouseEnter: noop,
  onMouseLeave: noop,
  width: "auto",
  tipText: null,
  selectedValues: [],
  updateRef: noop,
  newStyle: false,
  testId: null,
  listAutoWidth: false,
}

const ESC_KEYCODE = 27

type State = {|
  isHovered: boolean,
|}

export default class BaseSelect extends React.PureComponent<Props, State> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  constructor(props: Props) {
    super(props)
    this.state = { isHovered: false }
  }

  handleBlur: (event: SyntheticFocusEvent<HTMLDivElement>) => void = (event: SyntheticFocusEvent<HTMLDivElement>) => {
    const nextTarget = event.relatedTarget || document.activeElement // Seems like React's blur doesn't have event.relatedTarget // for IE11 (even though IE11 support it), fallback
    // to document.activeElement
    // $FlowFixMe relatedTarget should be a node
    if (!nextTarget || !this.container?.contains(nextTarget)) {
      // https://github.com/facebook/react/issues/3751
      this.props.onClose()
    }
  }

  handleKeyDown: (event: SyntheticKeyboardEvent<HTMLInputElement>) => void = (
    event: SyntheticKeyboardEvent<HTMLInputElement>
  ) => {
    if (event.keyCode === ESC_KEYCODE && this.props.open) {
      event.preventDefault()
      this.container?.focus()
      this.props.onClose()
    } else {
      this.props.onKeyDown(event)
    }
  }
  container: HTMLDivElement | null = null

  handleMouseEnter: (event: MouseEvent) => void = (event: MouseEvent) => {
    this.setState({ isHovered: true })
    this.props.onMouseEnter(event)
  }

  handleMouseLeave: (event: MouseEvent) => void = (event: MouseEvent) => {
    this.setState({ isHovered: false })
    this.props.onMouseLeave(event)
  }

  setReference: (node: HTMLDivElement | null) => void = (node: HTMLDivElement | null) => {
    this.container = node
    this.props.updateRef(node)
  }

  renderInput(): React.Element<"div"> {
    return (
      <div
        className={cn(
          styles.inputContainer,
          styles[this.props.highlightColour || this.props.color],
          styles[this.props.size],
          {
            [styles.open]: this.props.open && !this.props.disabled,
            [styles.newStyle]: this.props.newStyle,
          }
        )}
        disabled={this.props.disabled}
      >
        <div className={styles.input}>{this.props.input}</div>
        <div className={styles.caret}>
          {this.props.open ? (
            <Icon color="grey" size={this.props.size} type="arrow-drop-up" />
          ) : (
            <Icon color="grey" size={this.props.size} type="arrow-drop-down" />
          )}
        </div>
        {this.props.customButton && (
          <div className={cn(styles.customButton, { [styles.open]: this.props.open })}>{this.props.customButton}</div>
        )}
        {this.renderTooltip()}
      </div>
    )
  }

  renderTooltip(): void | React.Node {
    if (this.props.tipText && !this.props.open) {
      return <Bubble hovered={this.props.forceShowTip || this.state.isHovered}>{this.props.tipText}</Bubble>
    }
  }

  render(): React.Element<"div"> {
    return (
      <div
        className={styles.Select}
        data-testid={this.props.testId}
        disabled={this.props.disabled}
        onBlur={this.handleBlur}
        onClick={this.props.onClick}
        onFocus={this.props.onFocus}
        onKeyDown={this.handleKeyDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        ref={this.setReference}
        role="button"
        style={{ width: this.props.width }}
        tabIndex={this.props.open ? -1 : this.props.tabIndex}
      >
        {this.renderInput()}
        <DropdownList
          disabled={this.props.disabled}
          focusedValue={this.props.focusedValue}
          Group={this.props.Group}
          listAutoWidth={this.props.listAutoWidth}
          newStyle={this.props.newStyle}
          onClick={this.props.onOptionClick}
          onOptionEnter={this.props.onOptionEnter}
          onOptionLeave={this.props.onOptionLeave}
          open={this.props.disabled ? false : this.props.open}
          Option={this.props.Option}
          options={this.props.options}
          selectedValues={this.props.selectedValues}
          size={this.props.size}
        />
      </div>
    )
  }
}
