// @flow
import * as React from "react"
import * as L from "lodash"

type Resource = {
  cachedAt: number,
  data: mixed,
  path: string,
}

type Action =
  | $Exact<{ +payload: Resource, +type: "@cache/stash" }>
  | $Exact<{ +payload: $ReadOnlyArray<string>, +type: "@cache/trash" }>

type State = { +[path: string]: Resource }

export const Context: React.Context<[State, (Action) => void]> = React.createContext([{}, L.noop])

type Props = {|
  +children: React.Node,
|}

export function Store({ children }: Props): React.Node {
  return <Context.Provider value={React.useReducer(reducer, {})}>{children}</Context.Provider>
}

function reducer(state: State, action: Action) {
  switch (action.type) {
    case "@cache/stash":
      return L.assign({}, state, { [action.payload.path]: action.payload })
    case "@cache/trash":
      return L.omit(state, action.payload)
    default:
      return state
  }
}
