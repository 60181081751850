/* @flow */

import * as React from "react"
import cn from "classnames"
import Icon, { type IconSize } from "components/Icon"
import styles from "./styles.module.scss"

export type RequiredProps = {|
  +onChange: (event: { +target: { value: string } }) => void,
  +searchInput: string,
  +setInputRef: ?(element: HTMLInputElement | null) => void,
  +size: "l" | "m" | "s",
|}

export type Props = {|
  ...RequiredProps,
  +disabled: boolean,
|}

export const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  disabled: false,
}

export default class FilterInput extends React.PureComponent<Props> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS
  static iconSizeMap: {| [key: string]: IconSize |} = {
    l: "s",
    m: "s",
    s: "xs",
  }

  render(): React.Element<"div"> {
    return (
      <div className={cn(styles.filter, styles[this.props.size])}>
        <div className={styles.filterIcon}>
          <Icon color="grey" size={this.constructor.iconSizeMap[this.props.size]} type="search" />
        </div>
        <input
          className={styles.filterInput}
          data-testid="filter-input"
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          ref={this.props.setInputRef}
          value={this.props.searchInput}
        />
      </div>
    )
  }
}
