// @flow
import * as React from "react"
import * as Routes from "helpers/routes"
import Resource, { type GateProps } from "./resource"

type Props = {| ...GateProps, +modelId: string |}

export default function Records({ modelId, ...props }: Props): React.Node {
  return <Resource {...props} url={Routes.platform_model_records_path(modelId, { format: "json" })} />
}
