/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import moment from "moment"
import { chain, identity } from "underscore" // eslint-disable-line underscore-to-lodash/prefer-import-lodash

import * as Time from "helpers/time"

import { hasAllowances, isApprovedAndAutoApproved, hasNoCosts, isExported, hasTimes, isPending } from "./helpers"

import { hasMockedId } from "./flow_typed_helpers"

/**
 * @param {Immutable.OrderedMap} shifts to get award caches of
 * @returns {Object[]} of award caches
 */
export const getAllAwardCaches = (shifts) =>
  chain(shifts.toJS())
    .values()
    .map((s) => s.breakdown)
    .flatten(true)
    .compact()
    .value()

/**
 * @param {Immutable.OrderedMap} shifts to validate for creation
 * @returns {boolean} whether some shifts are created
 */
export const someCreated = (shifts) => shifts.valueSeq().some((s) => !hasMockedId(s.get("id")))

export const somePending = (shifts) =>
  shifts.some((shift) => isPending(shift) && (hasAllowances(shift) || hasTimes(shift)))

export const someAutoApproved = (shifts) => shifts.some((shift) => isApprovedAndAutoApproved(shift))

/**
 * accepts a date and returns a filterer for
 * a collection of shifts. the filterer will filter
 * to shifts on the date or adjacent dates
 * @param {string} date string in ISO format
 */
export const filterByNearDate = (date) => {
  const dates = [
    moment(date, Time.Formats.DateTime).subtract({ d: 1 }).format(Time.Formats.Date),
    date,
    moment(date, Time.Formats.DateTime).add({ d: 1 }).format(Time.Formats.Date),
  ]

  return (shifts) => shifts.filter((s) => dates.indexOf(s.get("date")) !== -1)
}

/**
 * creates a filterer bound to the user id given.
 *
 * @param {number} userId to filter shifts by
 * @returns {Function} to filter shifts with
 */
export const filterByUserId = (userId) => (shifts) => shifts.filter((s) => s.get("user_id") === userId)

/**
 * creates a filterer bound to the array of statuses provided.
 *
 * @param {string[]} validStatuses to filter shifts by
 * @returns {Function} to filter shifts with
 */
export const filterByStatus = (status) =>
  status == null ? identity : (shifts) => shifts.filter((s) => s.get("status") === status)

export const filterExportedWithNoCosts = (shifts) => shifts.filter((shift) => !isExported(shift) || !hasNoCosts(shift))

/**
 * takes an immutable map shift and sorts them
 * @param {Immutable.Map} a
 * @param {Immutable.Map} b
 * @returns {-1|0|1} symbolising sort order
 */
export const sorter = (a, b) =>
  sortByName(a, b) || sortByDate(a, b) || sortByShiftStart(a, b) || sortByRosterStart(a, b)

export const breakSorter = (a, b) =>
  sortByWhichShiftsHaveAStart(a, b) || sortByShiftStart(a, b) || sortByLengthSmallestFirst(a, b)

// if both objects have a start time, they are "equal" (sort by that separately)
// otherwise, objects with start times are ranked higher
export const sortByWhichShiftsHaveAStart = (a, b) =>
  a.get("start").isValid() && b.get("start").isValid()
    ? 0
    : a.get("start").isValid()
    ? -1
    : b.get("start").isValid()
    ? +1
    : 0

export const sortByLengthSmallestFirst = (a, b) => a.get("length") - b.get("length")

export const sortByName = (a, b) => a.get("user_name").localeCompare(b.get("user_name"))

export const sortByDate = (a, b) => new Date(a.get("date")).valueOf() - new Date(b.get("date")).valueOf()

export const sortByShiftStart = (a, b) =>
  a.get("start").isValid() && b.get("start").isValid() ? a.get("start").valueOf() - b.get("start").valueOf() : 0

export const sortByRosterStart = (a, b) =>
  a.get("rostered_start").isValid() && b.get("rostered_start").isValid()
    ? a.get("rostered_start").valueOf() - b.get("rostered_start").valueOf()
    : 0
