// @flow

import nanoid from "nanoid"
import { compact } from "lodash"
import * as M from "helpers/marshall"
import * as Assoc from "./association"
import * as Field from "./field"

export type ModelAttribute =
  | {| attr: Field.Schema, key: string, name: string, type: "field" |}
  | {| attr: Assoc.Schema, key: string, name: string, type: "assoc" |}

export type FieldFormItem = {|
  +_id: string, // not a DB id. only used as a React iterator key. see Platform::FormItems::FormField
  +fieldId: string,
  +type: "field",
|}

export type AssocFormItem = {|
  +_id: string, // not a DB id. only used as a React iterator key. see Platform::FormItems::FormAssociation
  +assocId: string,
  +type: "assoc",
|}

type SeperatorFormItem = {|
  +_id: string, // not a DB id. only used as a React iterator key.
  +type: "seperator",
|}

type TextFormItem = {|
  +_id: string, // not a DB id. only used as a React iterator key.
  +text: string,
  +type: "text",
|}

export type FormItem = FieldFormItem | SeperatorFormItem | TextFormItem | AssocFormItem
const FORM_ITEM_TYPES = ["field", "seperator", "text", "has_one", "many_to_many"]

export type Schema = {|
  +id: string,
  +items: Array<FormItem>,
  +modelId: string,
  +name: string,
|}

const DEFAULTS = {
  id: "",
  modelId: "",
  items: [],
  name: "",
}

export function fromJson(data: mixed): Schema {
  const m = M.object(data)

  return {
    id: M.number(m.id).toString(),
    modelId: M.number(m.model_id).toString(),
    name: M.string(m.name),
    items: compact(M.array(m.items).map(formItemFromJson)),
  }
}

export function create(r: $Shape<Schema> = {}): Schema {
  return {
    ...DEFAULTS,
    ...r,
  }
}

function formItemFromJson(data: mixed): ?FormItem {
  const f = M.object(data)
  const type = M.enumerated(FORM_ITEM_TYPES, M.string(f.type))

  switch (type) {
    case "field":
      return {
        _id: nanoid(),
        type: "field",
        fieldId: M.number(f.field_id).toString(),
      }
    case "seperator":
      return {
        _id: nanoid(),
        type: "seperator",
      }
    case "text":
      return {
        _id: nanoid(),
        type: "text",
        text: M.string(f.text),
      }
    case "many_to_many":
    case "has_one":
      return {
        _id: nanoid(),
        type: "assoc",
        assocId: M.number(f.assoc_id).toString(),
      }
  }

  return null // if no matches
}
