/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import { connect } from "react-redux"
import { createSelector, createStructuredSelector } from "reselect"
import { compose } from "underscore" // eslint-disable-line underscore-to-lodash/prefer-import-lodash

import Actions from "timesheets/logic/actions"

const selectAllowanceModalId = (store) => store.application.get("allowanceModalId")

const selectShifts = (store) => store.shifts

const selectTimesheets = (store) => store.timesheets

const selectShift = createSelector(selectAllowanceModalId, selectShifts, (id, shifts) =>
  id ? shifts.get(id) : undefined
)

const selectTimesheet = createSelector(selectTimesheets, selectShift, (timesheets, shift) =>
  shift ? timesheets.get(shift.get("timesheet_id")) : undefined
)

const getPropsFromStore = createStructuredSelector({
  shift: selectShift,
  timesheet: selectTimesheet,
  user: (store) => store.currentUser,
})

const bindActionsToDispatch = (dispatch) => ({
  onClose: compose(dispatch, Actions.appCloseAllowanceModal),
  onSave: compose(dispatch, Actions.shiftsSyncAllowances),
})

export default connect(getPropsFromStore, bindActionsToDispatch)
