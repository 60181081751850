/* @flow */

type PriceHash = {
  annual: number,
  minAnnualEmployeeCount: number,
  minMonthlyEmployeeCount: number,
  monthly: number,
}

type computePriceArgs = { annualBilling: boolean, country: string, employeeCount: number, prices: PriceHash }
export const computePriceToCharge = ({ annualBilling, employeeCount, prices, country }: computePriceArgs): number => {
  if (country === "Australia") {
    if (annualBilling && employeeCount < prices.minAnnualEmployeeCount) {
      return prices.minAnnualEmployeeCount * (prices.annual * 12)
    } else if (!annualBilling && employeeCount < prices.minMonthlyEmployeeCount) {
      return prices.minMonthlyEmployeeCount * prices.monthly
    }
  }

  const empPrice = annualBilling ? prices.annual * 12 : prices.monthly
  return employeeCount * empPrice
}
