/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import cn from "classnames"
import HolidayIcon from "components/HolidayIcon"
import { t as globalT } from "helpers/i18n"
import Allowances from "timesheets/views/ShiftCard/Allowances"
import ControlButton from "timesheets/components/ControlButton"

import styles from "./styles.module.scss"

const t = (key, values) => globalT(`js.timesheets.standby_schedule_card.${key}`, values)

export default class StandbyScheduleCard extends React.PureComponent {
  formatTime(value) {
    return moment(value).format("hh:mma")
  }

  renderMessage() {
    const timeNotWorkedSchedule = this.props.shift.get("time_not_worked_schedule")
    const scheduleType = timeNotWorkedSchedule.get("type")
    const scheduleDescription = scheduleType === "StandbySchedules::OnCall" ? t("on_call") : t("sleepover")

    return (
      <div className={styles.descriptionContainer}>
        <div className={cn(styles.descriptionTitle, styles[this.props.shift.get("status")])}>
          {this.props.context} - {scheduleDescription}
        </div>
        <HolidayIcon date={moment(this.props.shift.get("date"))} size={"s"} userId={this.props.shift.get("user_id")} />
      </div>
    )
  }

  renderTimes() {
    const timeNotWorkedSchedule = this.props.shift.get("time_not_worked_schedule")
    const startValue = timeNotWorkedSchedule.get("start")
    const finishValue = timeNotWorkedSchedule.get("finish")

    return (
      <div className={styles.descriptionContainer}>
        <p>
          {this.formatTime(startValue)} - {this.formatTime(finishValue)}
        </p>
      </div>
    )
  }

  addShiftHandler = () => {
    const {
      actions: { shiftsCreate },
      timesheet,
      shift,
      users,
    } = this.props
    const userId = timesheet.get("user_id")
    const userName = users.get(userId).get("name")
    const shiftDate = shift.get("date")
    const rosteredEnd = shift.get("rostered_end")
    const rosteredStart = shift.get("rostered_start")

    shiftsCreate({
      date: shiftDate,
      rostered_end: rosteredEnd,
      rostered_start: rosteredStart,
      user_id: userId,
      user_name: userName,
    })
  }

  render() {
    const id = this.props.shift.get("time_not_worked_schedule_id")
    const key = `time-not-worked-schedule-${id}`
    return (
      <div className={styles.standbyScheduleCard} key={key}>
        <div className={cn(styles.highlight, styles[this.props.shift.get("status")])} />
        <div className={styles.description}>
          {this.renderMessage()}
          {this.renderTimes()}
          <Allowances
            actions={{
              appOpenAllowanceModal: this.props.actions.appOpenAllowanceModal,
            }}
            requiresAveraging={this.props.requiresAveraging}
            shift={this.props.shift}
            timesheet={this.props.timesheet}
          />
        </div>
        <div className={styles.actions}>
          <ControlButton icon="add" onClick={this.addShiftHandler} tip={t("add_shift_label")} tipPosition="top" />
        </div>
      </div>
    )
  }
}

StandbyScheduleCard.propTypes = {
  actions: PropTypes.shape({
    appOpenAllowanceModal: PropTypes.func.isRequired,
    shiftsCreate: PropTypes.func.isRequired,
  }).isRequired,
  context: PropTypes.string.isRequired,
  requiresAveraging: PropTypes.bool.isRequired,
  shift: PropTypes.object.isRequired,
  timesheet: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
}

StandbyScheduleCard.defaultProps = {}
