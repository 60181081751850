// @flow
import * as React from "react"
import _, { isNull, noop } from "lodash"

import styles from "./styles.module.scss"
import Tab from "./Tab"

/**
 * This component wraps a list of children Tab components and passes down relevant props.
 */

// this can be any type, but a number | string is recommended.
type TabID = any // eslint-disable-line flowtype/no-weak-types

type RequiredProps = {|
  children: Array<?React.Node>,
|}

type Props = {|
  ...RequiredProps,
  active?: TabID,
  disabled?: boolean,
  onTabClick: (e: { target: { value: TabID } }) => void,
  vertical: boolean,
|}

export default class Tabs extends React.PureComponent<Props> {
  static defaultProps: $Diff<Props, RequiredProps> = {
    disabled: false,
    onTabClick: noop,
    vertical: false,
    active: 0,
  }

  constructor(props: Props) {
    super(props)
    this.createClickHandler = _.memoize(this.createClickHandler)
  }

  createClickHandler: (tabID: TabID) => (e: { target: { value: TabID, ... }, ... }) => void =
    (tabID: TabID) => (e: { target: { value: TabID } }) => {
      e.target.value = tabID
      this.props.onTabClick(e)
    }

  render(): React.Element<"div"> {
    const tabs = React.Children.map(this.props.children.filter(Boolean), (child, index) => {
      const id = isNull(child.props.id) ? index : child.props.id

      return React.cloneElement(child, {
        onClick: child.props.onClick || this.createClickHandler(id),
        disabled: child.props.disabled || this.props.disabled,
        active: child.props.active || id === this.props.active,
        id,
      })
    })

    return <div className={this.props.vertical ? styles.verticalContainer : styles.horizontalContainer}>{tabs}</div>
  }
}

export { Tab }
