/* @flow */

import * as React from "react"
import styles from "./styles.module.scss"

type RequiredProps = {|
  children: React.Node,
|}

type Props = {|
  ...RequiredProps,
|}

export default function Footer(props: Props): React.Element<"div"> {
  const left = []
  const right = []
  const center = []
  const whole = []
  React.Children.forEach(props.children, (el) => {
    if (!el) {
      return // `el` can be null if, for example, you conditionally render a section
    }

    switch (el.type.displayName) {
      case FooterLeft.displayName:
        left.push(el)
        break
      case FooterRight.displayName:
        right.push(el)
        break
      case FooterCenter.displayName:
        center.push(el)
        break
      case FooterWhole.displayName:
        whole.push(el)
        break
      default:
        break
    }
  })
  if (right.length !== 1 && whole.length === 0) {
    throw new Error("Modal `Footer` must contain exactly 1 `FooterRight`")
  }
  if (left.length > 1) {
    throw new Error("Modal `Footer` cannot contain more than 1 `FooterLeft`")
  }
  if (center.length > 1) {
    throw new Error("Modal `Footer` cannot contain more than 1 `FooterCenter`")
  }
  if (whole.length > 0 && (left.length > 0 || right.length > 0 || center.length > 0)) {
    throw new Error("Modal `Footer` cannot contain a FooterWhole as well as another FooterChild")
  }

  if (whole.length > 0) {
    return (
      <div className={styles.footer}>
        <div className={styles.footer_whole}>{whole}</div>
      </div>
    )
  }
  // Footer is added Right -> Center -> Left so that the main
  // action(Right) comes first on Screenreader, TabIndex, etc
  return (
    <div className={styles.footer}>
      <div className={styles.footer_right}>{right}</div>
      <div className={styles.footer_center}>{center}</div>
      <div className={styles.footer_left}>{left}</div>
    </div>
  )
}

/* ====== FOOTER CHILDREN ======
 * This is done in this way because FooterLeft, FooterCenter and FooterRight need
 * to be separately defined and named functions.
 */

const footerChildRender = (props: Props) => <div className={styles.footer_child}>{props.children}</div>

const footerChildDefaultProps = { children: null }

export function FooterLeft(props: Props): React.Element<"div"> {
  return footerChildRender(props)
}
export function FooterCenter(props: Props): React.Element<"div"> {
  return footerChildRender(props)
}
export function FooterRight(props: Props): React.Element<"div"> {
  return footerChildRender(props)
}
export function FooterWhole(props: Props): React.Element<"div"> {
  return footerChildRender(props)
}

// ====== SETUP FOOTER CHILDREN ======

const setupFooterChild = ([footerChildFunction, name]) => {
  footerChildFunction.displayName = name
  footerChildFunction.defaultProps = footerChildDefaultProps
}

;[
  [FooterLeft, "FooterLeft"],
  [FooterCenter, "FooterCenter"],
  [FooterRight, "FooterRight"],
  [FooterWhole, "FooterWhole"],
].forEach(setupFooterChild)
