// @flow
import nanoid from "nanoid"
import * as M from "helpers/marshall"

export type Type = "many_to_many" | "has_one"

export type Permission = "admin" | "manager" | "employee"

export type Schema = {|
  +configurable: boolean,
  +destroyed?: true,
  +editable: ?boolean,
  +editableBy: Array<Permission>,
  +id: string,
  +key: string,
  +name: string,
  +native: boolean,
  +ownerId: string,
  +required: boolean,
  +sortOrder: number,
  +subjectId: string,
  +throughId: string,
  +type: Type,
  +uuid: string,
  +visibleTo: Array<Permission>,
|}

const TYPES = ["many_to_many", "has_one"]

export const PERMISSIONS: Array<Permission> = ["admin", "manager", "employee"]

const DEFAULTS = {
  id: "",
  name: "",
  native: false,
  subjectId: "",
  ownerId: "",
  throughId: "",
  key: "",
  type: "has_one",
  visibleTo: [],
  editableBy: [],
  required: false,
  configurable: true,
}

export function isDestroyed(assoc: Schema): boolean {
  return Boolean(assoc.destroyed)
}

export function setDestroyed(assoc: Schema): Schema {
  return { ...assoc, destroyed: true }
}

export function create(assoc: $Shape<Schema> = {}): Schema {
  return {
    ...DEFAULTS,
    ...assoc,
    id: "",
    uuid: nanoid(),
  }
}

export function isRequired(assoc: Schema, value: mixed): boolean {
  switch (assoc.type) {
    case "has_one":
      return value === "" || value == null
    case "many_to_many":
      return (Array.isArray(value) && value.length < 1) || value == null
    default:
      return false
  }
}

export function patch(current: Schema, incoming: Schema): Schema {
  // fields we extract from the server response and merge back in
  const { id, visibleTo, editableBy } = incoming

  return {
    ...current,
    id,
    visibleTo,
    editableBy,
  }
}

export function updateFromEvent(assoc: Schema, event: SyntheticInputEvent<HTMLElement>): Schema {
  return {
    ...assoc,
    [event.target.name]: event.target.value,
  }
}

export function updateField(assoc: Schema, field: string, value: mixed): Schema {
  return {
    ...assoc,
    [field]: value,
  }
}

export function fromJson(data: mixed): Schema {
  const assoc = M.object(data)
  const throughId = M.maybeNumber(assoc.through_id)

  return {
    id: M.number(assoc.id).toString(),
    key: M.string(assoc.key),
    name: M.string(assoc.name),
    native: M.boolean(assoc.native),
    ownerId: M.number(assoc.owner_id).toString(),
    subjectId: M.number(assoc.subject_id).toString(),
    throughId: throughId ? throughId.toString() : "",
    type: M.enumerated(TYPES, M.string(assoc.type)),
    uuid: M.string(assoc.uuid),
    visibleTo: M.array(assoc.visible_to).map((p) => M.enumerated(PERMISSIONS, M.string(p))),
    editableBy: M.array(assoc.editable_by).map((p) => M.enumerated(PERMISSIONS, M.string(p))),
    editable: M.boolean(assoc.editable),
    required: M.boolean(assoc.required),
    sortOrder: M.number(assoc.sort_order),
    configurable: M.boolean(assoc.configurable),
  }
}

export function toPayload(assoc: Schema): mixed {
  return {
    _destroy: assoc.destroyed,
    id: assoc.id,
    key: assoc.key,
    name: assoc.name,
    native: assoc.native,
    owner_id: assoc.ownerId,
    subject_id: assoc.subjectId,
    through_id: assoc.throughId,
    type: assoc.type,
    uuid: assoc.uuid,
    visible_to: assoc.visibleTo,
    editable_by: assoc.editableBy,
    required: assoc.required,
    sort_order: assoc.sortOrder,
  }
}
