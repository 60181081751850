/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import { noop } from "lodash"
import cn from "classnames"

import { t } from "helpers/i18n"
import { AutoHoverBubble } from "components/Bubble"
import * as TimeFormatter from "helpers/time_formatter"
import styles from "./styles.module.scss"

export default class TimeOption extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  isClocked() {
    return this.props.type === Types.CLOCKED
  }

  handleClick(event) {
    if (!this.props.disabled) {
      event.target.value = TimeFormatter.shiftTimeInput(this.props.time, this.props.currentUser)

      this.props.onClick(event)
    }
  }

  render() {
    return (
      <div
        className={cn(styles.TimeOption, { disabled: this.props.disabled })}
        disabled={this.props.disabled}
        onClick={this.handleClick}
        onKeyPress={noop}
        role="button"
        tabIndex="-1"
      >
        <span className={styles.label}>
          {this.isClocked()
            ? t("js.timesheets.shift_card.inputs.clocked_option_symbol")
            : t("js.timesheets.shift_card.inputs.rostered_option_symbol")}
        </span>
        <span className={styles.time}>{TimeFormatter.shiftTimeInput(this.props.time, this.props.currentUser)}</span>
        {this.props.disabled ? null : (
          <AutoHoverBubble>
            <span className={styles.tip}>
              {this.isClocked()
                ? t("js.timesheets.shift_card.inputs.clocked_option_tip")
                : t("js.timesheets.shift_card.inputs.rostered_option_tip")}
            </span>
          </AutoHoverBubble>
        )}
      </div>
    )
  }
}

TimeOption.defaultProps = { disabled: false }

const Types = {
  CLOCKED: "clocked",
  ROSTERED: "rostered",
}

TimeOption.propTypes = {
  currentUser: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  time: PropTypes.object.isRequired,
  type: PropTypes.oneOf(Object.values(Types)).isRequired,
}
