/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as Shift from "timesheets/models/shift"
import * as Routes from "helpers/routes"

export const build = ({ shift, shiftBreak, params }) => {
  if (shiftBreak) {
    return buildBreakAction(shift, shiftBreak, params)
  }

  if (Shift.hasMockedId(shift.get("id"))) {
    return buildCreate(shift, params)
  }

  if (Array.isArray(params)) {
    // allowance update params is an array
    return buildAllowanceUpdate(shift, params)
  }

  return buildUpdate(shift, params)
}

export const Method = {
  POST: "post",
  PUT: "put",
}

const buildCreate = (shift, params) => ({
  url: Routes.shifts_path(),
  method: Method.POST,
  data: {
    shift: {
      ...params,
      date: shift.get("date"),
      user_id: shift.get("user_id"),
    },
  },
})

const buildUpdate = (shift, params) => ({
  url: Routes.shift_path(shift.get("id")),
  method: Method.PUT,
  data: { shift: params },
})

const buildAllowanceUpdate = (shift, params) => ({
  url: Routes.shift_update_allowances_path(shift.get("id")),
  method: Method.PUT,
  data: { update_allowances: params },
})

const buildBreakAction = (shift, shiftBreak, params) => {
  if (Shift.hasMockedId(shiftBreak.get("id"))) {
    return buildBreakCreate(shift, params)
  } else {
    return buildBreakUpdate(shift, shiftBreak, params)
  }
}

const buildBreakUpdate = (shift, shiftBreak, params) => ({
  url: Routes.shift_break_update_path(shift.get("id"), shiftBreak.get("id")),
  method: Method.PUT,
  data: { shift_break: params },
})

const buildBreakCreate = (shift, params) => ({
  url: Routes.shift_break_create_path(shift.get("id")),
  method: Method.POST,
  data: { shift_break: params },
})
