// @flow
import * as React from "react"

export type LoadStateHook<S> = [State<S>, (S) => void, () => void]

export type State<S> =
  | $Exact<{ +status: "failure", +value: null }>
  | $Exact<{ +status: "loading", +value: null }>
  | $Exact<{ +status: "success", +value: S }>

export default function useLoadState<S>(): LoadStateHook<S> {
  const [state, setState] = React.useState({
    status: "loading",
    value: null,
  })

  const onLoad = React.useCallback((value: S) => {
    setState({ status: "success", value })
  }, [])

  const onFail = React.useCallback(() => {
    setState({ status: "failure", value: null })
  }, [])

  return [state, onLoad, onFail]
}
