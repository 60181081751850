/**
 * @flow
 */

import moment from "moment"

export const fromString = (time: string): {| hour: ?number, minute: ?number |} => {
  const [hour, minute] = interpret(time) || []

  return {
    hour: hour != null ? parseInt(hour, 10) : null,
    minute: minute != null ? parseInt(minute, 10) : null,
  }
}

/**
 * accepts a string interpretation of the hours and minutes
 * of a time and converts it to hours and\
 * minutes time parts.
 *
 * @param {string} time to interpret
 */
export const interpret = (
  rawTime: string,
  formatters: Array<TimeMatcher> = DEFAULT_FORMATTERS
): null | [string, string] => {
  const time = sanitize(rawTime)

  const format = formatters.find(({ matcher }) => matcher.test(time))

  if (!format) {
    return null
  }

  const [, h, m] = format.matcher.exec(time) || []

  const [hours, minutes] = format.adjust ? format.adjust([h, m]) : [h, m]

  return [(hours || "").padStart(2, "0"), (minutes || "").padStart(2, "0")]
}

const sanitize = (time) =>
  time
    .replace(/^[^0-9]+/, "") // must start with a numeric
    .replace(/[^0-9AMP]+$/i, "") // must end with a numeric or AM/PM

/**
 * @param {[number, number]} $0 hours and minutes
 */
const addTwelve = ([h, m]) => [(parseInt(h, 10) + 12).toString(), m]

/**
 * @param {[number, number]} $0 hours and minutes
 */
const minusTwelve = ([h, m]) => [(parseInt(h, 10) - 12).toString(), m]

export const isDifferentDay = (date1: moment, date2: moment): boolean =>
  date1.format("YYYY-MM-DD") !== date2.format("YYYY-MM-DD")

// Use for determining lock toggle
export const isGreaterThanTwoDays = (startDate: moment, finishDate: moment): boolean => {
  const nextDay = moment(startDate).add(1, "days").startOf("day")
  return finishDate.clone().startOf("day").isAfter(nextDay)
}

export const toHoursAndMins = (minutes: number, alwaysDisplay: boolean = false): string => {
  const hours = Math.floor(Math.round(minutes) / 60)
  const mins = Math.round(minutes) % 60

  if ((hours && mins) || alwaysDisplay) {
    return `${hours}h ${mins}m`
  }

  if (!hours && !mins) {
    return `0h`
  }

  return hours ? `${hours}h` : `${mins}m`
}

/**
 * valid delimiters ":", ".", "-", ";", " ", ","
 *
 * delimiter match = [:.;,-\s]
 */

type TimeMatcher = {|
  +adjust?: ([string, string]) => [string, string],
  +matcher: RegExp,
|}

const HOUR_AND_MINUTE_FORMATTERS = [
  { matcher: /^(12)[:.;,-\s]*?(\d\d)\s*?(?:p|pm)/i }, // 12:mm pm
  { matcher: /^(12)[:.;,-\s]*?(\d\d)\s*?(?:a|am)/i, adjust: minusTwelve }, // 12:mm am
  { matcher: /^(12)[:.;,-\s]*?(00)\s*?(?:a|am)/i, adjust: addTwelve }, // 12:00 am
  { matcher: /^(\d\d)[:.;,-\s]*?(\d\d)\s*?(?:p|pm)/i, adjust: addTwelve }, // hh:mm pm
  { matcher: /^(\d\d)[:.;,-\s]*?(\d\d)\s*?(?:a|am)/i }, // hh:mm am
  { matcher: /^(\d\d)[:.;,-\s]*?(\d\d)/ }, // hh:mm
  { matcher: /^(\d)[:.;,-\s]*?(\d\d)\s*?(?:p|pm)/i, adjust: addTwelve }, // h:mm pm
  { matcher: /^(\d)[:.;,-\s]*?(\d\d)\s*?(?:a|am)/i }, // h:mm am
  { matcher: /^(\d)[:.;,-\s]*?(\d\d)/ }, // h:mm
]

const HOUR_FORMATTERS = [
  { matcher: /^(12)\s*?(?:p|pm)$/i }, // 12 pm
  { matcher: /^(12)\s*?(?:a|am)$/i, adjust: addTwelve }, // 12 am
  { matcher: /^(\d\d)\s*?(?:p|pm)$/i, adjust: addTwelve }, // hh pm
  { matcher: /^(\d\d)\s*?(?:a|am)$/i }, // hh am
  { matcher: /^(\d\d)$/ }, // hh
  { matcher: /^(\d)\s*?(?:p|pm)$/i, adjust: addTwelve }, // h pm
  { matcher: /^(\d)\s*?(?:a|am)$/i }, // h am
  { matcher: /^(\d)$/ }, // h
]

const DEFAULT_FORMATTERS: Array<TimeMatcher> = [...HOUR_AND_MINUTE_FORMATTERS, ...HOUR_FORMATTERS]

export default interpret
