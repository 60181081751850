// @flow
import * as React from "react"
import FileButton from "components/FileButton"
import Label from "components/Label"
import * as M from "helpers/marshall"

import * as Tanda from "platform/clients/tanda"

import * as Assoc from "../../models/association"
import * as Record from "../../models/record"
import * as State from "../../models/state"
import * as Store from "../../store"

type Ev = SyntheticInputEvent<HTMLElement>

type Props = {|
  +assoc: Assoc.Schema,
  +loading: boolean,
  +onEdit: (Ev) => mixed,
  +onSave: () => mixed,
  +onUploadFinish: () => void,
  +onUploadStart: () => void,
  +record: Record.Schema,
  +value: ?(number | string),
|}

export default function HasOneFile({
  assoc,
  loading,
  onEdit,
  onSave,
  onUploadFinish,
  onUploadStart,
  record,
  value,
}: Props): null | React.Node {
  const [cache, setCache] = React.useState(value)
  const [state, dispatch] = Store.useContext()

  if (value !== cache) {
    setCache(value)
    onSave()
  }

  const fileRecord = value ? State.recordById(state, value.toString()) : null
  const file = fileRecord ? Record.asFile(fileRecord) : null

  const [inProgressFile, setInProgressFile] = React.useState(null)

  const processFileUpload = React.useCallback(
    (val: ?File) => {
      setInProgressFile(val)
      onUploadStart()
      // whenever a file change happens:
      // - upload it to the backend
      // - get back platform record ID for it
      // - assign that as the value for the relevant association (call onEdit; onSave is then called from next render)
      if (val) {
        Tanda.uploadFile(val).then((fileRecord: Record.Schema) => {
          // keep a local copy of the new file record. this ensures we can show it when navigating around the SPA.
          dispatch({ data: fileRecord, type: "@create/record" })

          // associate the new file record with the record we're editing.
          // $FlowFixMe not a real synthetic event
          onEdit({ target: { name: assoc.key, value: parseInt(fileRecord.id) } })

          setInProgressFile(null)
          onUploadFinish()
        })
      } else {
        // removes the file from the local record
        // note that this does NOT delete the file from the backend
        // $FlowFixMe not a real synthetic event
        onEdit({ target: { name: assoc.key, value: null } })
        onUploadFinish()
      }
    },
    [assoc.key, dispatch, onEdit, onUploadFinish, onUploadStart]
  )

  const fileError = null // TODO
  const verificationTypes = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/gif",
    "application/pdf",
    "text/plain",
    "text/csv",
  ]

  if (inProgressFile) {
    // file has been uploaded, we now have a file object in memory.
    return (
      <FileButton
        disabled
        error={fileError}
        fileName={inProgressFile.name}
        onChange={processFileUpload}
        verificationTypes={verificationTypes}
      />
    )
  }

  if (!file) {
    // no file exists
    return <Label text={"-"} />
  } else {
    return (
      <FileButton
        disabled
        fileName={M.string(file.file_file_name)}
        onChange={processFileUpload}
        url={M.string(file.url)}
      />
    )
  }
}
