// @flow

import * as React from "react"
import { omit, noop } from "lodash"
import moment from "moment"
import { DayPickerSingleDateController } from "react-dates"
import Text from "components/Text"
import { RostersButton, type RostersButtonType } from "components/Button"
import styles from "./styles.module.scss"

type SuggestedDate = {|
  date: moment,
  label: string,
|}

type RequiredProps = {||}
type Props = {
  autoMargin: boolean,
  disabled: boolean,
  isDayHighlighted: ?(date: moment) => boolean,
  keepOpenOnDateSelect: boolean,
  label: string,
  onDateChange: ?(date: moment) => void,
  size: "s" | "m" | "l" | "xl",
  suggestedDates: Array<SuggestedDate>,
  type: RostersButtonType,
}

type State = {|
  open: boolean,
|}

/**
 * date picker API here:
 * https://github.com/airbnb/react-dates#singledatepicker
 */

export default class TandaButtonSingleDatePicker extends React.PureComponent<Props, State> {
  isDayHighlighted: ?(date: moment) => boolean

  container: ?Node

  static defaultProps: $Diff<Props, RequiredProps> = {
    disabled: false,
    type: "roundedAction",
    label: "",
    size: "m",
    autoMargin: true,
    suggestedDates: [],
    isDayHighlighted: null,
    keepOpenOnDateSelect: false,
    onDateChange: null,
  }

  constructor(props: Props) {
    super(props)
    this.state = { open: false }
    this.isDayHighlighted = this.isDayHighlightedFactory()
  }
  closeDatePicker: () => void = () => {
    this.setState({ open: false })
  }

  openDatePicker: () => void = () => {
    this.setState({ open: true })
  }

  refreshDatePicker: () => void = () => {
    this.setState({ open: false }, () => this.setState({ open: true }))
  }

  handleDateChange: (d: moment$Moment) => void = (d: moment) => {
    !this.props.keepOpenOnDateSelect && this.closeDatePicker()
    // Need to 'refresh' this function as otherwise it won't recompute inside react-dates
    this.isDayHighlighted = this.isDayHighlightedFactory()
    this.props.onDateChange && this.props.onDateChange(d)
  }

  handleSuggestedDate: (d: moment$Moment) => () => void = (d: moment) => () => this.handleDateChange(d)

  // eslint-disable-next-line flowtype/no-weak-types
  handleBlur: (event: any) => void = (event: any) => {
    const nextTarget = event.relatedTarget || document.activeElement // Seems like React's blur doesn't have event.relatedTarget // for IE11 (even though IE11 support it), fallback
    // to document.activeElement
    if (!nextTarget || (this.container && !this.container.contains(nextTarget))) {
      // https://github.com/facebook/react/issues/3751
      this.closeDatePicker()
    }
  }

  // reasoning for why we do this https://github.com/airbnb/react-dates/issues/1093
  isDayHighlightedFactory: () => (day: moment$Moment) => boolean = () => (day: moment) =>
    this.props.isDayHighlighted ? this.props.isDayHighlighted(day) : false

  setReference: (node: ?Node) => void = (node: ?Node) => {
    this.container = node
  }

  render(): React.Element<"div"> {
    return (
      <div ref={this.setReference}>
        <RostersButton
          autoMargin={this.props.autoMargin}
          disabled={this.props.disabled}
          icon="today"
          label={this.props.label}
          onClick={this.openDatePicker}
          size={this.props.size}
          type={this.props.type}
        />
        {this.state.open && (
          <div className={styles.pickerContainer}>
            <DayPickerSingleDateController
              {...omit(this.props, [
                "type",
                "label",
                "suggestedDates",
                "disabled",
                "enableMonthAndYearPicker",
                "value",
                "size",
                "autoMargin",
              ])}
              focused
              isDayHighlighted={this.props.isDayHighlighted != null ? this.isDayHighlighted : () => null}
              onDateChange={this.handleDateChange}
              onOutsideClick={this.handleBlur}
            />
            {this.props.suggestedDates.length > 0 && (
              <div className={styles.suggestedDatesContainer}>
                <Text bold color="lighter" type="small">
                  {" "}
                  Suggested Dates{" "}
                </Text>
                {this.props.suggestedDates.map((o) => (
                  <div
                    className={styles.suggestedDateContainer}
                    key={o.label}
                    onClick={this.handleSuggestedDate(o.date)}
                    onKeyUp={noop}
                    role="button"
                    tabIndex={0}
                  >
                    <Text color="primary" type="small">
                      {" "}
                      {o.label}{" "}
                    </Text>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}
