// @flow
import moment from "moment"
import * as Currency from "helpers/currency"
import absurd from "helpers/absurd"
import * as I18n from "helpers/i18n"
import * as Time from "helpers/time"
import * as M from "helpers/marshall"
import * as Field from "../../models/field"

type Props = {|
  type: Field.Type,
  value: mixed,
|}

const t = (k: string): string => I18n.t(`js.platform.records.value.${k}`)

export default function FieldValue(props: Props): string {
  switch (props.type) {
    case "boolean":
      return t(props.value ? "yes" : "no")
    case "currency":
      return props.value != null ? Currency.from(M.number(props.value)) : ""
    case "date":
      return props.value != null
        ? I18n.l(
            "date.formats.short_with_year",
            // please dont use date directly to interpret YYYY-MM-DD
            // it will interpret as UTC where are with YYYY-MM-DDT00:00:00
            // interprets as local time. WTF JS
            moment(M.string(props.value), Time.Formats.Date).toDate()
          )
        : ""
    case "float":
      return props.value != null ? M.number(props.value).toString() : ""
    case "text":
      return props.value != null ? M.string(props.value) : ""
    case "long_text":
      return props.value != null ? M.string(props.value) : ""
    case "linked_field":
      return props.value != null ? M.string(props.value) : ""
    default:
      // $FlowFixMe type checking this route is a waste of time
      return absurd(props.type)
  }
}
