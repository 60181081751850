// @flow
import * as React from "react"
import * as Routes from "helpers/routes"
import Resource, { type GateProps } from "./resource"

type Props = {|
  ...GateProps,
  +modelId: string,
  +recordId: string,
|}

export default function Records({ modelId, recordId, ...props }: Props): React.Node {
  return <Resource {...props} url={Routes.edit_platform_model_record_path(modelId, recordId, { format: "json" })} />
}
