// @flow
import moment from "moment"

export type Schema = {|
  day_range: number | "monthly",
  finish_date: moment,
  previous_finish_date: ?moment,
  previous_start_date: ?moment,
  print_mode: boolean,
  selected_award_tags: Array<string>,
  selected_location_ids: Array<number>,
  selected_shift_details: Array<number>,
  selected_staff_types: Array<string>,
  selected_team_ids: Array<number>,
  start_date: moment,
  template_id: ?number,
  user_sort_order: {
    [user_id: string]: ?number,
  },
  view: ViewType,
  visible_hours: VisibleHoursType,
|}

export type VisibleHoursType = {
  finish_half_hour: number,
  start_half_hour: number,
}

export type ViewType = "reporting" | "rosters" | "template"

export const rangeToDays = (unrefined: ?string): number => {
  switch (unrefined) {
    case "fortnight":
      return 13
    case "day":
      return 0
    case "week":
    default:
      return 6
  }
}

export default rangeToDays
