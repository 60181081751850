// @flow
import * as React from "react"
import * as Hooks from "hooks"
import AssocInput from "platform/view/assocInput"
import AssocValue from "platform/view/assocValue"
import FieldInput from "platform/view/fieldInput"
import LinkFieldInput from "platform/view/linkFieldInput"
import FieldValue from "platform/view/fieldValue"
import InputLabel from "components/Label/InputLabel"
import * as Tanda from "platform/clients/tanda"
import * as Model from "platform/models/model"
import * as Record from "platform/models/record"
import * as Store from "platform/store"

/* eslint-disable react/no-unused-prop-types */
type Props = {|
  +classes: {
    +fieldWrapper?: string,
    +inputWrapper?: string,
    +labelWrapper?: string,
    +valueWrapper?: string,
  },
  +editable: boolean,
  +emptyState: React.Node,
  +labelRenderer: (label: string) => React.Node,
  +model: Model.Schema,
  +record: Record.Schema,
  +valueRenderer: (value: React.Node) => React.Node,
|}
/* eslint-enable react/no-unused-prop-types */

type FieldProps = {|
  ...Props,
  input: React.Node,
  label: string,
  value: React.Node,
|}

const Field = (props: FieldProps) => {
  if (!props.value && !props.editable) {
    return null
  }
  return (
    <div className={props.classes.fieldWrapper}>
      <div className={props.classes.labelWrapper}>{props.labelRenderer(props.label)}</div>
      {props.editable && <div className={props.classes.inputWrapper}>{props.input}</div>}
      {!props.editable && <div className={props.classes.valueWrapper}>{props.valueRenderer(props.value)}</div>}
    </div>
  )
}

/* renders associations and fields with **no extra CSS**. add your own styles to it with the classes prop. */
export default function UnstyledRecordForm(props: Props): React.Node {
  const [f, setForm] = Hooks.useForm(props.record)
  const [, dispatch] = Store.useContext()

  const [loading, setLoading] = React.useState(false)
  const handleUploadStart = () => setLoading(true)
  const handleUploadFinish = () => setLoading(false)

  const handleSave = React.useCallback(() => {
    dispatch({ data: f, type: "@update/record" })
    Tanda.updateRecord(props.model, f)
  }, [dispatch, f, props.model])
  const customFields = React.useMemo(
    () => props.model.fields.filter((f) => !f.native && f.type !== "linked_field"),
    [props.model.fields]
  )
  const customLinkedFields = React.useMemo(
    () => props.model.fields.filter((f) => !f.native && f.type === "linked_field"),
    [props.model.fields]
  )

  return (
    <React.Fragment>
      {props.model.associations.length === 0 && props.model.fields.length === 0 && props.emptyState}
      {props.model.associations
        .filter((a) => !a.native)
        .map((assoc) => (
          <Field
            key={assoc.key}
            {...props}
            input={
              <AssocInput
                assoc={assoc}
                loading={loading}
                model={props.model}
                onEdit={setForm}
                onSave={handleSave}
                onUploadFinish={handleUploadFinish}
                onUploadStart={handleUploadStart}
                record={f}
                value={Record.refByKey(f, assoc.key)}
              />
            }
            label={assoc.name}
            value={<AssocValue assoc={assoc} value={Record.refByKey(f, assoc.key)} />}
          />
        ))}
      {customFields.map((field) => (
        <Field
          {...props}
          input={
            <FieldInput field={field} onEdit={setForm} onSave={handleSave} value={Record.valByKey(f, field.key)} />
          }
          key={field.key}
          label={field.name}
          value={<FieldValue type={field.type} value={Record.valByKey(f, field.key)} />}
        />
      ))}

      {customLinkedFields.map((field) => (
        <Field
          {...props}
          input={
            <LinkFieldInput field={field} onEdit={setForm} onSave={handleSave} value={Record.valByKey(f, field.key)} />
          }
          key={field.key}
          label={field.name}
          value={null}
        />
      ))}
    </React.Fragment>
  )
}

const defaultLabelRenderer = (label: string): React.Node => <InputLabel text={label} />
const defaultValueRenderer = (value: mixed): mixed => value

UnstyledRecordForm.defaultProps = {
  editable: true,
  classes: ({}: { ... }),
  labelRenderer: defaultLabelRenderer,
  valueRenderer: defaultValueRenderer,
}
