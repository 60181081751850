/* @flow */

import * as React from "react"
import Text from "components/Text"
import Icon from "components/Icon"
import styles from "./styles.module.scss"

type RequiredProps = {|
  items: Array<{
    icon: string,
    label: string,
  }>,
|}

type Props = {|
  ...RequiredProps,
|}

export default function Header(props: Props): React.Element<"div"> {
  return (
    <div className={styles.header}>
      <div className={styles.modalHeaderContainer}>
        {props.items.map((item) => (
          <div className={styles.contextItem} key={item.label}>
            <Icon color="grey" size="m" type={item.icon} />
            <Text color="lightest" margin="left" textOverflow="ellipsis" type="body">
              {item.label}
            </Text>
          </div>
        ))}
      </div>
    </div>
  )
}
