/* @flow */

import * as React from "react"
import { noop } from "lodash"
import styles from "./styles.module.scss"
import { type Props as ButtonProps, DEFAULT_PROPS as BUTTON_DEFAULT_PROPS } from "./RostersButton"
import { RostersButton } from "./"

export type RequiredProps = {|
  linkTo: string,
|}

export type Props = {|
  ...RequiredProps,
  ...ButtonProps,
  newTab: boolean,
  popover: React.Node,
|}

export const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  ...BUTTON_DEFAULT_PROPS,
  popover: null,
  newTab: false,
  onClick: noop,
}

export default class LinkButton extends React.PureComponent<Props> {
  static defaultProps: $Diff<Props, RequiredProps> = {
    ...BUTTON_DEFAULT_PROPS,
    newTab: false,
    onClick: noop,
    popover: null,
  }

  render(): React.Element<"a"> {
    const { linkTo, newTab, popover, ...rest } = this.props
    return (
      <a
        className={styles.link}
        disabled={this.props.disabled}
        href={!this.props.disabled ? linkTo : undefined}
        onClick={this.props.onClick}
        rel={newTab ? "noopener noreferrer" : ""}
        target={newTab ? "_blank" : ""}
      >
        <RostersButton onClick={noop} {...rest} />
        {popover}
      </a>
    )
  }
}
