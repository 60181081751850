/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import { noop } from "lodash"
import Button from "components/Button"
import Icon from "components/Icon"
import styles from "./styles.module.scss"

export default class Header extends React.Component {
  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this)
    this.onKeyUp = this.onKeyUp.bind(this)
  }

  onClick(event) {
    event.stopPropagation()
    this.props.onButtonClick(event)
  }

  onKeyUp(e) {
    if (e.key === " " || e.key === "Enter") {
      this.onClick()
    }
  }

  getButton() {
    if (this.props.buttonText) {
      return (
        <div className={styles.button}>
          <Button label={this.props.buttonText} onClick={this.onClick} />
        </div>
      )
    }
  }

  getIcon() {
    if (this.props.icon) {
      return <Icon size="m" type={this.props.icon} />
    }
  }

  getRightSideIcon() {
    if (this.props.rightSideIcon && !this.props.buttonText) {
      return (
        <div className={styles.rightSideIcon}>
          <Icon size="m" type={this.props.rightSideIcon} />
        </div>
      )
    }
  }

  getSubtitle() {
    if (this.props.subtitle) {
      return <div className={styles.subtitle}>{this.props.subtitle}</div>
    }
  }

  render() {
    return (
      <div className={styles.header} onClick={this.props.onClick} onKeyUp={this.onKeyUp} role="button" tabIndex="-1">
        <span className={styles.toggle}>
          <div className={styles.title}>
            {this.getIcon()}
            <div className={styles.titleWrapper}>{this.props.title}</div>
            {this.getSubtitle()}
          </div>
        </span>
        {this.getButton()}
        {this.getRightSideIcon()}
      </div>
    )
  }
}

Header.propTypes = {
  buttonText: PropTypes.string,
  icon: PropTypes.string,
  rightSideIcon: PropTypes.string,
  onButtonClick: PropTypes.func,
  onClick: PropTypes.func,
  subtitle: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string, // title with plain text
    PropTypes.element, // custom title
  ]).isRequired,
}

Header.defaultProps = {
  buttonText: null,
  icon: null,
  rightSideIcon: null,
  onButtonClick: noop,
  onClick: noop,
  subtitle: null,
}
