// @flow
//
// use this method to stub test ids outside
// development environment
//
export default function testId(id: string): ?string {
  if (process.env.NODE_ENV !== "production") {
    return id
  }
}
