// @flow
import * as React from "react"
import absurd from "helpers/absurd"
import * as M from "helpers/marshall"
import * as Field from "../../models/field"
import * as Store from "../../store"
import * as State from "../../models/state"
import BoolInput from "../fieldInput/boolInput"
import DateInput from "../fieldInput/dateInput"
import FloatInput from "../fieldInput/floatInput"
import TextInput from "../fieldInput/textInput"
import LongTextInput from "../fieldInput/longTextInput"
import CurrencyInput from "../fieldInput/currencyInput"

type Ev = SyntheticInputEvent<HTMLInputElement>

type Props = {|
  +field: Field.Schema,
  +onEdit: (Ev) => mixed,
  +onSave: () => mixed,
  +value: mixed,
|}

function getFieldType(linkFields, fields, linkedField) {
  if (!linkedField) {
    return null
  }
  const associatedField = fields.find((f) => f.id === linkedField.subjectFieldId)
  if (associatedField && associatedField.type === "linked_field") {
    const newLinkedField = linkFields.find((lf) =>
      lf?.fieldId && lf.fieldId != null ? associatedField.id === lf.fieldId : null
    )
    if (newLinkedField == null) {
      return null
    }
    return getFieldType(linkFields, fields, newLinkedField)
  } else {
    return associatedField
  }
}

export default function LinkFieldInput({ field, onEdit, onSave, value }: Props): React.Node {
  const [state] = Store.useContext()
  const fields = State.fieldList(state)
  const linkFields = fields.filter((f) => f.type === "linked_field").map((f) => f.config)
  const linkedField = linkFields.find((lf) => (lf?.fieldId && lf.fieldId != null ? lf.fieldId === field.id : null))
  if (linkedField == null) {
    return null
  }
  const unLinkedField = getFieldType(linkFields, fields, linkedField)
  if (!unLinkedField) {
    return null
  }

  switch (unLinkedField.type) {
    case "boolean":
      return (
        <BoolInput
          disabled
          htmlName={field.key}
          onEdit={onEdit}
          onSave={onSave}
          value={M.maybeBoolean(value) || false}
        />
      )
    case "date":
      return <DateInput disabled htmlName={field.key} onEdit={onEdit} onSave={onSave} value={M.maybeString(value)} />
    case "float":
      return <FloatInput disabled htmlName={field.key} onEdit={onEdit} onSave={onSave} value={M.maybeNumber(value)} />
    case "text":
      return (
        <TextInput disabled htmlName={field.key} onEdit={onEdit} onSave={onSave} value={M.maybeString(value) || ""} />
      )
    case "long_text":
      return (
        <LongTextInput
          disabled
          htmlName={field.key}
          onEdit={onEdit}
          onSave={onSave}
          value={M.maybeString(value) || ""}
        />
      )
    case "linked_field":
      return null
    case "currency":
      return (
        <CurrencyInput disabled htmlName={field.key} onEdit={onEdit} onSave={onSave} value={M.maybeNumber(value)} />
      )
    default:
      // $FlowFixMe type checking this route is a waste of time
      return absurd(unLinkedField.type)
  }
}
