// @flow

import * as React from "react"
import cn from "classnames"
import { outOfViewport, getNewPosition } from "components/Hover/util"
import Hover from "../Hover"
import styles from "./styles.module.scss"

type RequiredProps = {|
  children: React.Node,
|}

export type Props = {|
  ...RequiredProps,
  defaultPosition: "top" | "bottom" | "left" | "right" | null,
  fade: boolean,
  hovered: boolean,
  noPointerEvents: boolean,
  offset: number,
  opaque: boolean,
  position: "top" | "bottom" | "left" | "right" | "auto",
  rightAligned: boolean,
  width: "auto" | "medium" | "large" | "max-content",
|}

export const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  position: "bottom",
  offset: 0.75,
  width: "auto",
  hovered: false,
  opaque: false,
  fade: true,
  rightAligned: false,
  noPointerEvents: false,
  defaultPosition: null,
}

export default function Bubble(props: Props): React.Element<"div"> {
  const contents_ref: {| current: null | HTMLDivElement |} = React.createRef()
  const target_ref = React.createRef()

  const [position, setPosition] = React.useState(props.defaultPosition || "right")
  const [position_set, setPositionSet] = React.useState(props.position !== "auto")

  React.useEffect(() => {
    if (
      props.position === "auto" &&
      contents_ref.current != null &&
      target_ref.current != null &&
      position_set === false
    ) {
      const target_el = target_ref.current
      const contents_el = contents_ref.current
      const contents_bounds = contents_el.getBoundingClientRect()
      const target_bounds = target_el.getBoundingClientRect()
      // $FlowFixMe bounding error but its fine
      if (props.defaultPosition == null || outOfViewport(contents_bounds)) {
        setPosition(getNewPosition(contents_bounds, target_bounds))
      }
      setPositionSet(true)
    }
  }, [position, position_set, props, contents_ref, target_ref])

  const final_position = props.position === "auto" ? position : props.position

  const classes = cn(
    styles.Bubble,
    styles[`Bubble__${final_position}`],
    { [styles.Bubble__rightAligned]: props.rightAligned },
    { [styles.opaque]: props.opaque },
    { [styles[`Bubble__${props.width}`]]: props.width !== "auto" }
  )

  return (
    <div className={styles.HoverWrapper}>
      <Hover
        fade={props.fade}
        hovered={props.hovered}
        noPointerEvents={props.noPointerEvents}
        offset={props.offset}
        position={final_position}
        rightAligned={props.rightAligned}
        setRef={target_ref}
      >
        <div className={classes} ref={contents_ref} style={{ visibility: position_set ? null : "hidden" }}>
          {props.children}
        </div>
      </Hover>
    </div>
  )
}

Bubble.defaultProps = DEFAULT_PROPS
