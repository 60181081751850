// @flow
import * as React from "react"

import LargeInputLabel from "components/Label/LargeInputLabel"
import BaseFilterSelect, {
  type Props as BaseFilterProps,
  DEFAULT_PROPS as BASE_DEFAULT_PROPS,
  type RequiredProps as BaseFilterRequiredProps,
} from "./BaseFilterSelect"

const getLargeInputLabel = (label) => {
  if (!label) {
    return null
  }

  return <LargeInputLabel text={label} />
}

type RequiredProps = {|
  ...BaseFilterRequiredProps,
|}

type Props = {|
  ...BaseFilterProps,
  ...RequiredProps,
  label: string,
  size?: "s" | "m" | "l",
|}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  ...BASE_DEFAULT_PROPS,
  label: "",
  size: "l",
}

export default function LargeFilterSelect(props: Props): React.Element<"div"> {
  const { label, size, ...rest } = props

  return (
    <div>
      {getLargeInputLabel(label)}
      <BaseFilterSelect {...rest} size={size} />
    </div>
  )
}

LargeFilterSelect.defaultProps = DEFAULT_PROPS
