// @flow

import * as React from "react"
import cn from "classnames"
import Icon, { type IconColor } from "components/Icon"
import Text from "components/Text"
import styles from "./styles.module.scss"

type RequiredProps = {|
  checked: boolean,
  title: string,
|}

type Props = {|
  ...RequiredProps,
  disabled: boolean,
  exampleText: ?string,
  icon: ?string,
  iconColor: IconColor,
  size: "s" | "m" | "l" | "xs",
  subtitle: ?string,
|}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  disabled: false,
  exampleText: null,
  icon: null,
  iconColor: "grey",
  size: "s",
  subtitle: null,
}

export default class BoxBody extends React.PureComponent<Props> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  getIcon(): null | React.Node {
    if (!this.props.icon) {
      return null
    }
    return <Icon color={this.props.iconColor} size="l" type={this.props.icon} />
  }

  getHr(): null | React.Element<"hr"> {
    if (this.props.exampleText) {
      if (this.props.disabled) {
        return <hr className={cn(styles.hr, styles.disabled)} />
      } else {
        return <hr className={styles.hr} />
      }
    }
    return null
  }

  getSmallBody(): React.Element<"div"> {
    return (
      <div className={cn({ [styles.disabled]: this.props.disabled })}>
        <div className={styles.smallContainer}>
          {this.props.icon && (
            <Icon color={this.props.iconColor} padding="0 0.25rem 0 0" size="s" type={this.props.icon} />
          )}
          <div>
            <Text align="center" bold color={this.props.checked ? "primary" : "lighter"} type="small">
              {this.props.title}
            </Text>
            {this.props.subtitle && (
              <Text
                align="center"
                color={this.props.checked ? "primary" : "lighter"}
                padding="0.25rem 0 0 0"
                type="tiny"
              >
                {this.props.subtitle}
              </Text>
            )}
          </div>
        </div>
      </div>
    )
  }

  getLargeBody(): React.Element<"div"> {
    return (
      <div className={this.props.disabled ? styles.disabled : null}>
        <div className={styles.iconWrapper}>{this.getIcon()}</div>
        <div className={styles.title}>{this.props.title}</div>
        <div className={styles.subtitle}>{this.props.subtitle}</div>
        {this.getHr()}
        <div className={styles.example}>{this.props.exampleText}</div>
      </div>
    )
  }

  render(): React.Element<"div"> {
    return (
      <div className={styles.body}>
        {this.props.size === "s" || this.props.size === "xs" ? this.getSmallBody() : this.getLargeBody()}
      </div>
    )
  }
}
