// @flow

import * as React from "react"
import { noop, omit } from "lodash"
import styles from "./styles.module.scss"
import { type Props as BasePopoverProps, DEFAULT_PROPS as BasePopoverDefaultProps } from "./BasePopover"
import Popover from "./Popover"

/*
 * PLEASE NOTE:
 * When using this component, elements at the same level/lower in the DOM won't get pointer events.
 * If you want pointer events for these elements, use the `Popover` component and hold the `hovered` state yourself.
 */

type RequiredProps = {|
  children: React.Node,
  title: string,
|}

type Props = {|
  ...RequiredProps,
  ...BasePopoverProps,
  hover?: boolean,
  onClick?: ?() => void,
|}

type State = {|
  hovered: boolean,
|}

export default class AutoHoverPopover extends React.Component<Props, State> {
  onMouseOver: () => void

  onMouseLeave: () => void

  static defaultProps: $Diff<Props, RequiredProps> = {
    position: "bottom",
    ...BasePopoverDefaultProps,
    onClick: null,
  }

  constructor(props: Props) {
    super(props)
    /**
     * We use mouseOver here because mouseEnter unfortunately has been
     * known not to fire
     */
    this.onMouseOver = () => this.setState({ hovered: true })
    this.onMouseLeave = () => this.setState({ hovered: false })

    this.state = { hovered: props.hovered }
  }

  render(): React.Element<"div"> {
    return (
      <div
        className={styles.AutoHoverPopover}
        onClick={this.props.onClick || noop}
        onFocus={noop}
        onKeyPress={this.props.onClick || noop}
        onMouseLeave={this.props.hover ? noop : this.onMouseLeave}
        onMouseOver={this.props.hover ? noop : this.onMouseOver}
        role="presentation"
      >
        <Popover {...omit(this.props, "onClick")} hovered={this.props.hovered || this.state.hovered}>
          {this.props.children}
        </Popover>
      </div>
    )
  }
}
