/* @flow */

import * as React from "react"
import Pill, { type PillType } from "components/Pill"
import styles from "./styles.module.scss"
import Label from "./index"

type Props = {|
  htmlFor?: string,
  pillText?: string,
  pillType?: PillType,
  text: React.Node,
|}

export default function LargeInputLabel(props: Props): React.Node {
  return (
    <div className={styles.largeInputLabel}>
      <Label htmlFor={props.htmlFor} text={props.text} />
      {props.pillText && <Pill label={props.pillText} type={props.pillType} />}
    </div>
  )
}

LargeInputLabel.defaultProps = {
  htmlFor: undefined,
  pillText: undefined,
  pillType: "theme",
}
