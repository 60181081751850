// @flow

import FilterSelect from "./FilterSelect"
import LargeFilterSelect from "./LargeFilterSelect"

// BaseFilterSelect is not exported as it should not be used
// directly, but abstracted with a higher level component.

export { FilterSelect, LargeFilterSelect }

export default FilterSelect
