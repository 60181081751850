/* @flow */

import * as React from "react"
import { s3ImagePath } from "helpers/image"
import { t as globalT } from "helpers/i18n"
import WidgetBoxBase from "./Base"

const t = (key, ...args) => globalT(`js.dashboard.widgets.${key}`, ...args)

export type Props = {
  codeName: string,
  description?: ?string, // eslint-disable-line react/require-default-props
  name?: ?string, // eslint-disable-line react/require-default-props
  onClick: () => void,
}

export default function WidgetBox({ codeName, description = null, name = null, onClick }: Props): React.Node {
  return (
    <WidgetBoxBase
      buttonText={t("empty.button_text")}
      descriptionText={description || t(`${codeName}.description`)}
      headerText={name || t(`${codeName}.widget_name`)}
      imageUrl={s3ImagePath(`dashboard/add_new_widget/textless/${codeName}.png`)}
      onClick={onClick}
    />
  )
}
