/* @flow */

import * as React from "react"
import ReactDOM from "react-dom"
import { defaultWidgetActionDelegator, type WidgetActionDelegator } from "../widgets"
import Main from "./main"
import MainDemo from "./mainDemo"

export function run(delegator: WidgetActionDelegator = defaultWidgetActionDelegator()) {
  const mount = document.getElementById("LiveInsightsWidgetMount")
  if (mount) {
    const initialState = { ...(window.__INITIAL_STATE__ || {}), delegator }
    ReactDOM.unmountComponentAtNode(mount)
    if (initialState.demo) {
      ReactDOM.render(<MainDemo delegator={initialState.delegator} />, mount)
    } else {
      ReactDOM.render(<Main delegator={initialState.delegator} />, mount)
    }
  }
}

window.SPA = { ...window.SPA, LiveInsights: { run } }
