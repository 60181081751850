/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import { noop } from "lodash"
import cn from "classnames"

import { handleOnClick, handleMouseEnter, handleMouseLeave } from "components/DropdownList/helpers/handlers"

import Icon from "components/Icon"

import styles from "./styles.module.scss"

export default class DefaultOption extends React.Component {
  constructor(props) {
    super(props)
    this.handleOnClick = handleOnClick.bind(this)
    this.handleMouseEnter = handleMouseEnter.bind(this)
    this.handleMouseLeave = handleMouseLeave.bind(this)
  }

  render() {
    return (
      <div
        className={cn(styles.Option, styles[this.props.size], {
          [styles.selected]: this.props.selected,
          [styles.hovered]: this.props.hovered,
        })}
        onClick={this.handleOnClick}
        onKeyPress={noop}
        onMouseLeave={this.handleMouseLeave}
        onMouseMove={this.props.hovered ? noop : this.handleMouseEnter}
        ref={this.props.updateRef}
        role="button"
        tabIndex={-1}
      >
        <span className={styles.optionLabel}>{this.props.label}</span>
        <div className={styles.selectedTick}>
          {this.props.selected ? (
            <Icon
              color={this.props.hovered ? "white" : "primary"}
              size={this.props.size === "l" ? "s" : "xs"}
              type="done"
            />
          ) : null}
        </div>
      </div>
    )
  }
}

DefaultOption.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  hovered: PropTypes.bool,
  size: PropTypes.oneOf(["s", "m", "l"]).isRequired,
  updateRef: PropTypes.func,

  // ** Used in helpers/defaultOptionHandlers.js **
  /* eslint-disable react/no-unused-prop-types */
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  value: PropTypes.any,
  /* eslint-enable react/no-unused-prop-types */
  // ** Used in helpers/defaultOptionHandlers.js **
}

DefaultOption.defaultProps = {
  onClick: noop,
  onMouseEnter: noop,
  onMouseLeave: noop,
  selected: false,
  hovered: false,
  value: undefined,
  updateRef: noop,
}
