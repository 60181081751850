/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import cn from "classnames"
import PropTypes from "prop-types"
import { noop } from "lodash"
import { t } from "helpers/i18n"
import Loader from "../Loader"
import styles from "./styles.module.scss"

export default class OptionButton extends React.PureComponent {
  constructor(props) {
    super(props)
    this.onKeyUp = this.onKeyUp.bind(this)
  }

  onKeyUp(e) {
    if (e.key === " " || e.key === "Enter") {
      this.props.onClick()
    }
  }

  getLoader() {
    if (!this.props.loading) {
      return null
    }
    return (
      <div className={styles.loader}>
        <Loader color="primary" size="xl" />
      </div>
    )
  }

  render() {
    return (
      <div
        aria-label={this.props.loading ? t("js.generic_components.loading") : false}
        className={cn(
          styles.optionButtonWrapper,
          styles[this.props.shape],
          { [styles.disabled]: this.props.disabled },
          { [styles.cursorPointer]: this.props.cursorPointer }
        )}
        disabled={this.props.disabled || this.props.loading}
        onClick={this.props.disabled || this.props.loading ? noop : this.props.onClick}
        onKeyUp={this.onKeyUp}
        role="button"
        tabIndex={0}
      >
        <div className={cn(styles.optionButton, { [styles.loading]: this.props.loading })}>
          {this.getLoader()}
          {this.props.children}
        </div>
      </div>
    )
  }
}

OptionButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  shape: PropTypes.string,
  cursorPointer: PropTypes.bool,
}

OptionButton.defaultProps = {
  shape: "square",
  onClick: noop,
  loading: false,
  disabled: false,
  cursorPointer: true,
}
