/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import _ from "underscore" // eslint-disable-line underscore-to-lodash/prefer-import-lodash

import * as Shift from "timesheets/models/shift"

import Icon from "components/Icon/"

import styles from "./styles.module.scss"

export default class ShiftRating extends React.PureComponent {
  constructor(props) {
    super(props)

    this.handleMouseLeave = () => this.setState({ isHovered: false })
    this.handleMouseOver = () => this.setState({ isHovered: true })

    this.previewRating = _.memoize(this.previewRating)
    this.setRating = _.memoize(this.setRating)

    this.state = { isHovered: false, previewRating: props.shift.get("rating") }
  }

  previewRating(rating) {
    return () => {
      if (this.props.readonly) {
        return
      }
      this.setState({ previewRating: rating })
    }
  }

  setRating(rating) {
    return () => {
      if (this.props.readonly) {
        return
      }
      this.setState({ previewRating: rating })

      this.props.actions.shiftsSync({
        shift: this.props.shift,
        fields: { rating },
      })
    }
  }

  star(threshold) {
    const ratingForType = this.state.isHovered ? this.state.previewRating : this.props.shift.get("rating")

    return (
      <div
        className={styles.star}
        onClick={this.setRating(threshold)}
        onFocus={this.previewRating(threshold)}
        onKeyPress={this.setRating(threshold)}
        onMouseOver={this.previewRating(threshold)}
        role="presentation"
      >
        <Icon color="gold" size="m" type={ratingForType >= threshold ? "star" : "star-border"} />
      </div>
    )
  }

  render() {
    // only show on approvable shifts
    if (!Shift.isApprovable(this.props.shift)) {
      return null
    }

    return (
      <div
        className={styles.parent}
        onBlur={this.handleMouseLeave}
        onFocus={this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}
        onMouseOver={this.handleMouseOver}
      >
        {this.star(1)}
        {this.star(2)}
        {this.star(3)}
        {this.star(4)}
        {this.star(5)}
      </div>
    )
  }
}

ShiftRating.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  shift: PropTypes.object.isRequired,
  readonly: PropTypes.bool.isRequired,
}
