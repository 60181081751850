/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import cn from "classnames"
import PropTypes from "prop-types"
import { noop } from "lodash"
import Icon from "components/Icon/"
import Loader from "../Loader"
import styles from "./styles.module.scss"

/**
 * This component is a simple tab, it is designed to be wrapped in a tabcontainer.
 * EG:
 * <TabContainer >
 *   <Tab label="this is selected" active=true />
 *   <Tab label="tab two" />
 *   <Tab label="tab three" />
 * </TabContainer >
 * @export
 * @class Tab
 * @extends {Component}
 */
export default class Tab extends React.PureComponent {
  constructor(props) {
    super(props)
    this.onKeyUp = this.onKeyUp.bind(this)
  }
  onKeyUp(e) {
    if (!this.props.onClick) {
      return
    }

    if (e.key === " " || e.key === "Enter") {
      this.props.onClick(e)
    }
  }
  getLoader() {
    return (
      <div className={styles.loader}>
        <Loader color={"primary"} />
      </div>
    )
  }
  getError() {
    return (
      <span>
        <div className={styles.invalid}>
          <Icon color={"danger"} size="s" type="warning" />
        </div>
      </span>
    )
  }
  render() {
    const classes = cn(styles.tab, {
      [styles.active]: this.props.active,
      [styles.disabled]: this.props.disabled,
      [styles.bold]: this.props.bold,
      [styles.blue]: this.props.blue,
    })

    return (
      <div
        className={classes}
        data-testid={this.props.testId}
        onClick={this.props.disabled ? null : this.props.onClick || noop}
        onKeyUp={this.onKeyUp}
        role="button"
        tabIndex={this.props.disabled ? -1 : 0}
      >
        <div className={this.props.draggable ? styles.draggableTabContent : styles.tabContent}>
          {this.props.draggable ? <Icon color="lightest" size="m" type="drag-indicator" /> : null}
          {this.props.invalid ? this.getError() : null}
          {this.props.loading ? this.getLoader() : this.props.label}
        </div>
      </div>
    )
  }
}

Tab.displayName = "Tab"

Tab.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  draggable: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  loading: PropTypes.bool,
  // ID has no value on its own in Tab, but is pulled off each Tab in the Tabs component, so it must be defined here
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // eslint-disable-line react/no-unused-prop-types
  bold: PropTypes.bool,
  blue: PropTypes.bool,
  invalid: PropTypes.bool,
  testId: PropTypes.string,
}

Tab.defaultProps = {
  draggable: false,
  disabled: false,
  onClick: null,
  label: "",
  testId: null,
  active: false,
  loading: false,
  id: null,
  bold: false,
  blue: false,
  invalid: false,
}
