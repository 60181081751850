// @flow
import * as React from "react"
import * as Hooks from "hooks"
import SyncStatus from "components/SyncStatus"
import Form from "platform/view/recordForm/form"
import * as Tanda from "../../clients/tanda"
import * as Model from "../../models/model"
import * as Record from "../../models/record"
import styles from "./styles.module.scss"

type Props = {|
  model: Model.Schema,
  record: Record.Schema,
|}

export default function View({ model, record }: Props): React.Element<"div"> {
  const [f, setField] = Hooks.useForm(record)
  const [count, setCount] = React.useState(0)

  const onSave = React.useCallback(() => {
    setCount((c) => c + 1)
    Tanda.updateRecord(model, Record.createPatch(record, f))
      .then(() => {
        setCount((c) => c - 1)
      })
      .catch(() => {
        setCount((c) => c - 1)
      })
  }, [f, model, record])

  return (
    <div className={styles.form}>
      <div className={styles.fields}>
        <Form form={f} model={model} onEdit={setField} onSave={onSave} />
      </div>
      <div>
        <SyncStatus synced={count === 0} />
      </div>
    </div>
  )
}
