// @flow
import * as React from "react"
import Text from "components/Text"
import UnstyledRecordForm from "../../platform/view/unstyledRecordForm"
import * as State from "../../platform/models/state"
import * as Store from "../../platform/store"
import styles from "./styles.module.scss"

type Props = {|
  editable: boolean,
  emptyState: React.Node,
  modelId: string,
  recordId: string,
|}

export default function PlatformRecordFields({ modelId, editable, recordId, emptyState }: Props): null | React.Node {
  const [state] = Store.useContext()

  const record = State.recordById(state, recordId.toString())
  const model = State.modelById(state, modelId)

  if (record == null || model == null) {
    return null
  }

  return (
    <UnstyledRecordForm
      classes={{
        fieldWrapper: styles.fieldWrapper,
        labelWrapper: styles.labelWrapper,
      }}
      editable={editable}
      emptyState={emptyState}
      labelRenderer={(node: string) => (
        <Text color="black" margin="r025" type="inherit">
          {node}
        </Text>
      )}
      model={model}
      record={record}
      valueRenderer={(node: React.Node) => (
        <Text bold color={"black"} type="inherit">
          {node}
        </Text>
      )}
    />
  )
}

PlatformRecordFields.defaultProps = {
  editable: true,
  emptyState: null,
}
