// @flow
import * as React from "react"
import cn from "classnames"

import styles from "./styles.module.scss"

type RequiredProps = {|
  children: Array<React.Node>,
  label: string,
  size: "s" | "l",
|}

type Props = {|
  ...RequiredProps,
|}

export type GroupNodeType = React.ComponentType<Props>

export default function DefaultGroup({ children, label, size }: Props): React.Element<"div"> {
  return (
    <div className={cn(styles.Group, styles[size])}>
      <div className={styles.groupLabel}>{label}</div>
      {children}
    </div>
  )
}
