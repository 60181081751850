/* @flow */
import * as React from "react"
import _ from "lodash"
import moment from "moment"
import * as Routes from "helpers/routes"
import Request from "helpers/request"
import { allDepartmentIds } from "dashboard/live_insights/selectors"
import type { WidgetActionDelegator } from "../widgets"
import ProblemStates from "./components/ProblemStates"
import Constants from "./helpers/constants"
import LiveWageTracker from "./views/LiveWageTracker"
import LiveInsights from "./views/LiveInsights"

export type chartTypes = "solidLine" | "dottedLine" | "solidBar" | "departmentSwatch" | "none"
export type DemandDataTypes = "none" | "sales"
export type MetricTypes = "splh" | "wage_percentage"
export type StaffDataTypes = "staff_count" | "staff_cost" | "splh" | "wage_percentage"
export type unitTypes = "none" | "currency" | "percentage" | "currencyPerHour"
export type FilterOptionsObjectType = $ReadOnly<{|
  costRelated: boolean,
  data: { chartType: chartTypes, color: string },
  label: string,
  value: StaffDataTypes | DemandDataTypes,
|}>

export type LiveInsightsDataType = {|
  actual_staff_data: Array<ArrangedDataType>,
  org_current_time: string,
  predicted_sales_data: ?Array<ArrangedDataType>,
  rostered_staff_data: Array<ArrangedDataType>,
  sales_data: ?Array<ArrangedDataType>,
|}

export type ArrangedDataType = {
  costs: CounterObjectType,
  counts: CounterObjectType,
  date: string,
  predicted_sales: ?CounterObjectType,
  sales: ?CounterObjectType,
  time: number,
}

export type RechartsDataType = {
  [id: string]: ?number,
  time: string,
}

export type EntryType = {
  chartType: chartTypes,
  color: string,
  default: boolean,
  key: string,
  name: string,
  tip: string,
  unit: unitTypes,
}

export type FilterOptionsType = {
  demand_data: Array<FilterOptionsObjectType>,
  staff_data: Array<FilterOptionsObjectType>,
}

export type DataFiltersType = {
  demand_data_selected: DemandDataTypes,
  departments: Array<number>,
  filterOptions: FilterOptionsType,
  metricOptions: Array<MetricOptionsType>,
  showSales: boolean,
  staff_data_selected: StaffDataTypes,
  togglePredictedLine: boolean,
  toggleRosteredLine: boolean,
}

export type MetricOptionsType = {
  label: string,
  value: MetricTypes,
}

export type LiveInsightsLineStyles = {|
  demand_data: {|
    actual: {|
      chartType: chartTypes,
      color: string,
    |},
    predicted: {|
      chartType: chartTypes,
      color: string,
    |},
  |},
  staff_data: {|
    actual: {|
      chartType: chartTypes,
      color: string,
    |},
    rostered: {|
      chartType: chartTypes,
      color: string,
    |},
  |},
|}

export type ActualRosteredType = {
  actual: EntryType,
  rostered: EntryType,
}

export type LiveInsightsGraphEntriesType = {|
  none: ActualRosteredType,
  sales: ActualRosteredType,
  splh: ActualRosteredType,
  staff_cost: ActualRosteredType,
  staff_count: ActualRosteredType,
  wage_percentage: ActualRosteredType,
|}

export type LiveWageGraphEntriesType = {
  staff_cost: ActualRosteredType,
  staff_count: ActualRosteredType,
}

export type CounterObjectType = {
  [dep_id: string]: number,
  total: number,
}

type PropType = {
  delegator: WidgetActionDelegator,
}

export type LocationDataType = {
  departments: Array<DepartmentType>,
  id: number,
  loc_current_time: string,
  name: string,
  short_name: string,
}

export type DepartmentType = {
  color: string,
  id: number,
  name: string,
}

export type DataStreamInfoType = {
  department_ids: Array<number>,
  entire_org: boolean,
  id: number,
  location_ids: Array<number>,
}

export type GetDataResponse = {
  actual_staff_data: Array<ArrangedDataType>,
  can_see_costs: boolean,
  data_stream_info: { [ds_id: string]: DataStreamInfoType },
  is_live_insights: boolean,
  large_org: boolean,
  org_current_time: string,
  predicted_sales_data: Array<ArrangedDataType>,
  recache_job_ongoing: boolean,
  rostered_staff_data: Array<ArrangedDataType>,
  sales_data: Array<ArrangedDataType>,
}

export type CheckOngoingJobResponse = {
  recache_job_ongoing: boolean,
}

export type GetDataResponsePerLocation = {
  actual_staff_data: Array<ArrangedDataType>,
  data_stream_info: { [ds_id: string]: DataStreamInfoType },
  org_current_time: string,
  predicted_sales_data: Array<ArrangedDataType>,
  rostered_staff_data: Array<ArrangedDataType>,
  sales_data: Array<ArrangedDataType>,
}

export type GetDepartmentsResponse = Array<LocationDataType>

export type StateType = {|
  averageOfDates: boolean,
  backgroundLoading: boolean,
  canSeeCosts: boolean,
  data: ?LiveInsightsDataType,
  dataStreamInfo: { [ds_id: string]: DataStreamInfoType },
  date: moment,
  error: boolean,
  interval: ?IntervalID,
  islargeOrg: boolean,
  isLiveInsights: boolean,
  loadedLocations: Array<number>,
  loading: boolean,
  locationData: ?Array<LocationDataType>,
  metricType: MetricTypes,
  recacheJobOngoing: boolean,
  recacheTimerRunning: boolean,
  skipLiveRecache: boolean,
  time: ?moment,
|}

export default class Main extends React.PureComponent<PropType, StateType> {
  constructor(props: PropType) {
    super(props)

    props.delegator.showWidget = this.showWidget.bind(this)
    props.delegator.isWidgetVisible = this.isWidgetVisible.bind(this)

    this.state = {
      averageOfDates: false,
      skipLiveRecache: false,
      recacheJobOngoing: false,
      recacheTimerRunning: false,
      canSeeCosts: false,
      date: moment(),
      isLiveInsights: false,
      metricType: "wage_percentage",
      dataStreamInfo: {},
      data: {
        actual_staff_data: [],
        org_current_time: "",
        rostered_staff_data: [],
        sales_data: [],
        predicted_sales_data: [],
      },
      locationData: null,
      loadedLocations: [],
      islargeOrg: false,
      interval: null,
      time: null,
      loading: true,
      backgroundLoading: false,
      error: false,
    }
  }

  componentDidMount() {
    if (this.isWidgetVisible()) {
      this.getDepartments()
      this.getData()
    }
  }

  componentWillUnmount() {
    this.clearRefreshInterval()
  }

  getData: () => void = () => {
    this.setState({ backgroundLoading: true })

    const selectedDepartmentsCookie = window.$.cookie(Constants.TEAMS_COOKIE_KEY + window.current_user.id)
    const selectedDepartmentsFromCookie = selectedDepartmentsCookie != null ? JSON.parse(selectedDepartmentsCookie) : []
    const params = {
      department_id: selectedDepartmentsFromCookie,
      live_insights_date: this.state.date.format("YYYY-MM-DD"),
      skipLiveRecache: this.state.skipLiveRecache,
    }

    Request.get(Routes.dashboard_live_insights_get_data_path(), { params: params })
      .then((response: { data: GetDataResponse }) => {
        if (response.data.recache_job_ongoing) {
          this.setState({
            recacheJobOngoing: response.data.recache_job_ongoing,
          })
        } else {
          this.setState({
            time: this.getBucketedMomentFromString(response.data.org_current_time),
            isLiveInsights: response.data.is_live_insights,
            metricType: window.$.cookie(Constants.DATATYPE_COOKIE_KEY + window.current_user.id) || "wage_percentage",
            canSeeCosts: response.data.can_see_costs,
            dataStreamInfo: response.data.data_stream_info,
            data: {
              ...this.state.data,
              actual_staff_data: response.data.actual_staff_data,
              rostered_staff_data: response.data.rostered_staff_data,
              org_current_time: response.data.org_current_time,
              sales_data: response.data.sales_data,
              predicted_sales_data: response.data.predicted_sales_data,
            },
            error: false,
            loading: false,
            islargeOrg: response.data.large_org,
            backgroundLoading: false,
            recacheJobOngoing: false,
          })
        }
      })
      .then(() => {
        if (this.state.recacheJobOngoing && !this.state.recacheTimerRunning) {
          // If the job is ongoing, and the timer isn't already running, start it
          this.startRecacheJobTimer()
          this.setState({
            skipLiveRecache: true,
          })
        } else {
          // If the refesh interval isn't already running, start it.
          if (!this.state.interval) {
            this.startRefreshInterval()
            this.setState({
              skipLiveRecache: false,
            })
          }
        }
      })
      .catch(() => {
        this.setState({ error: true, loading: false, backgroundLoading: false })
      })
  }

  getDepartments: () => void = (): void => {
    Request.get(Routes.dashboard_live_insights_get_departments_path())
      .then((response: { data: GetDepartmentsResponse }) =>
        this.setState({ locationData: response.data, error: false })
      )
      .catch((e) => {
        this.clearRefreshInterval()
        this.setState({ error: true })
      })
  }

  clearRefreshInterval: () => void = (): void => {
    if (this.state.interval) {
      clearInterval(this.state.interval)
    }
  }

  getBucketedMomentFromString: (timeString: string) => ?moment$Moment = (timeString: string): ?moment => {
    const interval = Constants.BUCKET_INTERVAL // We want to break the time into 15 minute blocks
    const now = moment(timeString, Constants.DATE_TIME_FORMAT)
    const roundedMinutes = Math.floor(now.minute() / interval) * interval

    // If it's the current date. Return null - Allows the red line on live insights will render correctly.)
    if (!this.state.date.isSame(now, "date")) {
      return null
    }
    return now.minute(roundedMinutes).second(0)
  }

  timeChangeHandler: (timeString: string) => void = (timeString: string) =>
    this.setState({ time: this.getBucketedMomentFromString(timeString) })

  handleDateChange: (newDate: ?moment$Moment) => void = (newDate: ?moment): void => {
    if (newDate) {
      this.setState({ date: newDate, loading: true }, this.refreshData)
    }
  }

  refreshData: () => void = (): void => {
    if (!this.isWidgetVisible()) {
      return
    }

    if (this.state.islargeOrg && this.state.loadedLocations != null && !!this.state.loadedLocations.length) {
      this.getDataByLocation(this.state.loadedLocations)
    } else {
      this.getData()
    }
  }

  retryDataLoad: () => void = (): void => {
    this.setState({ loading: true, error: false })
    this.refreshData()
  }

  checkRecacheJob: () => void = (): void => {
    this.setState({ recacheTimerRunning: false })

    // Check if the recache job is still ongoing
    Request.get(Routes.dashboard_live_insights_check_ongoing_job_path())
      .then((response: { data: { recache_job_ongoing: boolean } }) => {
        this.setState({
          recacheJobOngoing: response.data.recache_job_ongoing,
        })
      })
      .then(() => {
        if (this.state.recacheJobOngoing) {
          // If the job is ongoing, restart the timer and we will come back through here in 15 seconds
          this.startRecacheJobTimer()
        } else {
          // If the job is not ongoing, refresh the data
          this.refreshData()
        }
      })
      .catch(() => {
        this.setState({ error: true, loading: false, backgroundLoading: false })
      })
  }

  startRecacheJobTimer: () => void = (): void => {
    this.setState({ recacheTimerRunning: true })

    setTimeout(() => {
      if (!this.state.error && this.state.recacheJobOngoing) {
        this.checkRecacheJob()
      }
    }, 10000) // 10 seconds
  }

  startRefreshInterval: () => void = (): void => {
    const interval = setInterval(() => {
      if (!this.state.error && !this.state.recacheJobOngoing) {
        this.refreshData()
      }
    }, Constants.BUCKET_INTERVAL * 60 * 1000) // Refresh every 15 minutes
    this.setState({ interval })
  }

  saveConfigHandler: (isLiveInsights: boolean, metricType: ?MetricTypes) => void = (
    isLiveInsights: boolean,
    metricType: ?MetricTypes
  ): void => {
    window.$.removeCookie(Constants.TEAMS_COOKIE_KEY + window.current_user.id)
    if (metricType != null && metricType !== this.state.metricType) {
      this.state.data && window.$.cookie(Constants.DATATYPE_COOKIE_KEY + window.current_user.id, metricType)
      this.setState({ metricType: metricType })
    }
    if (isLiveInsights !== this.state.isLiveInsights) {
      this.setState({
        data: {
          org_current_time: "",
          ...this.state.data,
          actual_staff_data: [],
          predicted_sales_data: [],
          rostered_staff_data: [],
          sales_data: [],
        },
        metricType: metricType != null ? metricType : "wage_percentage",
        loadedLocations: [],
      })
      this.setWidgetSetting(isLiveInsights)
    }
  }

  locationResponseSetState: (data: GetDataResponsePerLocation, locationIds: ?Array<number>) => void = (
    data: GetDataResponsePerLocation,
    locationIds: ?Array<number>
  ) => {
    this.setState({
      data: {
        org_current_time: "",
        ...this.state.data,
        actual_staff_data: data.actual_staff_data,
        predicted_sales_data: data.predicted_sales_data,
        rostered_staff_data: data.rostered_staff_data,
        sales_data: data.sales_data,
      },
      dataStreamInfo: data.data_stream_info,
      loadedLocations: locationIds != null ? locationIds : [],
      loading: false,
      error: false,
    })
  }

  getDataByLocation: (locationIds: ?Array<number>) => void = (locationIds: ?Array<number>) => {
    const locationIdsIsEmpty = locationIds == null || !locationIds.length
    if (locationIdsIsEmpty) {
      return
    }

    this.setState({ loading: true })

    const params = {
      location_id: locationIds,
      live_insights_date: this.state.date,
    }

    Request.get(Routes.dashboard_live_insights_get_data_by_location_path(), { params: params })
      .then((response: { data: GetDataResponsePerLocation }) => {
        !locationIdsIsEmpty && this.locationResponseSetState(response.data, locationIds)
      })
      .catch((e) => {
        this.clearRefreshInterval()
        this.setState({ error: true, loading: false })
      })
  }

  setWidgetSetting: (isLiveInsights: boolean) => void = (isLiveInsights: boolean): void => {
    Request.post(Routes.dashboard_live_insights_set_widget_setting_path(), { is_live_insights: isLiveInsights })
      .then(this.setState({ loading: true }))
      .then(() => this.getData())
      .catch()
  }

  hideWidget: () => void = () => {
    window.$("#LiveInsightsWidgetMount").parents(".widget").addClass("widget-hidden")
    _.defer(() => window.$(".dashboard").gridLayout("reflow_and_save"))
  }

  showWidget: () => void = () => {
    window.$("#LiveInsightsWidgetMount").parents(".widget").removeClass("widget-hidden")
    this.retryDataLoad()
    _.defer(() => window.$(".dashboard").gridLayout("reflow_and_save"))
  }

  // eslint-disable-next-line flowtype/no-weak-types
  isWidgetVisible: () => any = () => !window.$("#LiveInsightsWidgetMount").parents(".widget").hasClass("widget-hidden")

  render(): React.Node {
    const missingRosteredStaffData =
      this.state.data != null &&
      this.state.data.rostered_staff_data != null &&
      !this.state.data.rostered_staff_data.length
    const missingActualStaffData =
      this.state.data != null && this.state.data.actual_staff_data != null && !this.state.data.actual_staff_data.length
    const missingStaffData = missingRosteredStaffData && missingActualStaffData

    const isCurrentDate = this.state.date.isSame(moment(), "day")
    const isLargeOrg = !this.state.loading && this.state.islargeOrg
    const isEmpty = !!(this.state.data && missingStaffData && !this.state.islargeOrg)
    const isLoadingWithNoData = this.state.loading && isEmpty
    const isError = this.state.error

    const emptyLargeOrg =
      !this.state.loading &&
      this.state.islargeOrg &&
      this.state.loadedLocations != null &&
      !this.state.loadedLocations.length

    if (isError || isLoadingWithNoData || (isEmpty && isCurrentDate)) {
      return (
        <ProblemStates
          canSeeCosts={this.state.canSeeCosts}
          hideWidget={this.hideWidget}
          isEmpty={isEmpty}
          isError={isError}
          isLiveInsights={this.state.isLiveInsights}
          isLoading={isLoadingWithNoData}
          retryDataLoad={this.retryDataLoad}
        />
      )
    }

    return this.state.isLiveInsights ? (
      <LiveInsights
        allDepartmentIds={allDepartmentIds(this.state.locationData)}
        averageOfDates={this.state.averageOfDates}
        canSeeCosts={this.state.canSeeCosts}
        data={this.state.data}
        dataStreamInfo={this.state.dataStreamInfo}
        dateViewing={this.state.date}
        emptyLargeOrg={emptyLargeOrg}
        getDataByLocation={this.getDataByLocation}
        handleDateChange={this.handleDateChange}
        hideWidget={this.hideWidget}
        interval={this.state.interval}
        isBackgroundLoading={this.state.backgroundLoading}
        isLargeOrg={isLargeOrg}
        isLiveInsights={this.state.isLiveInsights}
        isLoading={this.state.loading}
        loadedLocations={this.state.loadedLocations}
        locationData={this.state.locationData}
        metricType={this.state.metricType}
        saveConfigHandler={this.saveConfigHandler}
        showSettingToggle
        time={this.state.time}
        timeChangeHandler={this.timeChangeHandler}
      />
    ) : (
      <LiveWageTracker
        allDepartmentIds={allDepartmentIds(this.state.locationData)}
        canSeeCosts={this.state.canSeeCosts}
        data={this.state.data}
        dateViewing={this.state.date}
        emptyLargeOrg={emptyLargeOrg}
        getDataByLocation={this.getDataByLocation}
        handleDateChange={this.handleDateChange}
        hideWidget={this.hideWidget}
        interval={this.state.interval}
        isBackgroundLoading={this.state.backgroundLoading}
        isLargeOrg={isLargeOrg}
        isLiveInsights={this.state.isLiveInsights}
        isLoading={this.state.loading}
        loadedLocations={this.state.loadedLocations}
        locationData={this.state.locationData}
        metricType={this.state.metricType}
        saveConfigHandler={this.saveConfigHandler}
        showSettingToggle
        time={this.state.time}
        timeChangeHandler={this.timeChangeHandler}
      />
    )
  }
}
