/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import { createActions, createReducer } from "redux-em"
import { Map } from "immutable"

const reqCacheInit = () => new Map()

/**
 * @param {Immutable.Map} state
 * @param {number} id of cache to update
 * @param {Object} params to add to cache
 * @returns {Immutble.Map} next state
 */
const reqCachePush = (state, { id, params }) => state.update(id, new Map(), (map) => map.merge(params))

/**
 * @param {Immutable.Map} state
 * @param {number} mockedId currently used on cache
 * @param {number} id to add to cache
 * @returns {Immutble.Map} next state
 */
const reqCacheUpdate = (state, { mockedId, id }) => {
  const cached = state.get(mockedId)

  if (!cached) {
    return state
  }

  return state.delete(mockedId).set(id, cached)
}

/**
 * @param {Immutable.Map} state
 * @param {number} id of cache to delete
 * @returns {Immutble.Map} next state
 */
const reqCacheDelete = (state, { id }) => state.delete(id)

export const Core = {
  reqCacheInit,
  reqCachePush,
  reqCacheUpdate,
  reqCacheDelete,
}

export const reqCacheActions = createActions(Core)

export const reqCacheReducer = createReducer(Core)
