// @flow
import type { DailyBreakdown, LeaveInfo } from "time_off/Modal/types"
import type { ShiftType } from "../../../../rosters/overview/types"

export type Status = "pending" | "approved" | "rejected"

export type Schema = {|
  all_day: boolean,
  approved_at: ?string,
  approved_by: ?number,
  created_at: string,
  daily_breakdown: DailyBreakdown,
  finish: string,
  finish_time: ?string,
  hours: ?number,
  id: number,
  leave_type: string,
  reason: string,
  rejected_at: ?string,
  rejected_by: ?number,
  start: string,
  start_time: ?string,
  status: Status,
  updated_at: string,
  user_id: number,
|}

export const getStatsFromLeaveRequests = (lrs: Array<Schema>): {| total_hours: number, total_paid_hours: number |} => ({
  total_hours: lrs.reduce((acc, lr) => acc + (lr.hours || 0), 0),
  // TODO: matching on untranslated english word is dumb - need more sophisticated type checking in the model
  total_paid_hours: lrs
    .filter((lr) => !lr.leave_type.toLowerCase().includes("unpaid"))
    .reduce((acc, lr) => acc + (lr.hours || 0), 0),
})

export const getStatsFromVisibleLeaveRequests = (
  leave_requests: Array<Schema>,
  leave_shifts: Array<ShiftType>,
  user_id: number
): {| total_hours: number, total_paid_hours: number |} => {
  const leaveShiftsForUser = leave_shifts.filter((shift) => shift.user_id === user_id)

  const paid_leave_request_ids = leave_requests
    .filter((lr) => getStatsFromLeaveRequests([lr]).total_paid_hours > 0)
    .map((lr) => lr.id)

  return {
    total_hours: leaveShiftsForUser.reduce((acc, shift) => acc + shift.shift_length, 0),
    total_paid_hours: leaveShiftsForUser
      .filter((shift) => paid_leave_request_ids.includes(shift.leave_request_id))
      .reduce((acc, shift) => acc + shift.shift_length, 0),
  }
}

export const marshall = (leave_info: LeaveInfo): Schema => ({
  all_day: leave_info.all_day,
  approved_at: leave_info.approved_at,
  approved_by: leave_info.approved_by,
  created_at: leave_info.created_at,
  daily_breakdown: leave_info.daily_breakdown,
  finish: leave_info.finish,
  finish_time: leave_info.finish_time,
  hours: leave_info.hours == null ? null : Number(leave_info.hours),
  id: leave_info.id,
  leave_type: leave_info.leave_type,
  reason: leave_info.reason || "",
  rejected_at: leave_info.rejected_at,
  rejected_by: leave_info.rejected_by,
  start: leave_info.start,
  start_time: leave_info.start_time,
  status: leave_info.status,
  updated_at: leave_info.updated_at,
  user_id: Number(leave_info.user_id),
})

export default getStatsFromLeaveRequests
