// @flow
import * as State from "../../models/state"
import type { Action } from "../action"
import * as Utils from "./utils"

type Records = State.Partial<"records">

export default function records(state: Records, action: Action): Records {
  switch (action.type) {
    case "@patch/state":
      return Utils.merge(state, action.data.records)
    case "@create/record":
      return Utils.assoc(state, action.data)
    case "@update/record":
      return Utils.update(state, action.data)
    case "@delete/record":
      return Utils.dissoc(state, action.data)
    default:
      return state
  }
}
