/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as Shift from "timesheets/models/shift"

export * from "./collections"

export const isSynced = (app) => app.get("request_count", 0) < 1

/**
 * pending shifts can have a status of null sadly
 */
export const FilterStatus = {
  ALL: null,
  APPROVED: Shift.Status.Approved,
  PENDING: Shift.Status.Pending,
}
