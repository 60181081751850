/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import { createActions, createReducer } from "redux-em"

/**
 * @returns {Object} an empty initial state
 */
const summaryInit = () => ({})

/**
 * a simple swap of state with a fallback to current state
 * @param {Object} currState
 * @param {Object} nextState
 */
const summaryUpdate = (currState, nextState) => nextState || currState

export const Core = {
  summaryInit,
  summaryUpdate,
}

export const summaryActions = createActions(Core)

export const summaryReducer = createReducer(Core)
