/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import { t } from "helpers/i18n"
import LinkButton from "components/Button/LinkButton"
import Button from "components/Button"
import AutoHoverPopover from "components/Popover/AutoHoverPopover"
import Text from "components/Text"
import * as Routes from "helpers/routes"
import Currency from "helpers/currency"
import Metric from "../Metric"
import styles from "./styles.module.scss"

export default class TipTotals extends React.PureComponent {
  getRecalcLink() {
    if (this.props.isWeekly) {
      const timesheetID = this.props.timesheet.get("id")
      return Routes.timesheet_path(timesheetID, { recalc_tip_jars: true })
    } else {
      return Routes.timesheets_daily_path(this.props.date, { recalc_tip_jars: true })
    }
  }

  recalcTipsClick = () => {
    this.props.actions.appStateAwaitingRequest()
  }

  render() {
    return (
      <div className={styles.metricRecalcContainer}>
        <div className={styles.popoverContainer}>
          <Metric
            icon="monetization-on"
            label={t("js.timesheets.totals_bar.total_tips")}
            noMargin
            popover={
              <AutoHoverPopover title={t("js.timesheets.totals_bar.tip_totals.popover_title")} width="large">
                <Text margin="paragraph" type="inherit">
                  {t("js.timesheets.totals_bar.tip_totals.tips_explanation")}
                </Text>
                <Button
                  label={t("js.timesheets.totals_bar.tip_totals.configure_tips_button")}
                  link={Routes.tip_jars_path()}
                  type="theme"
                />
                <Text color="lightest" type="inherit">
                  {t("js.timesheets.totals_bar.tip_totals.tips_explanation_note")}
                </Text>
              </AutoHoverPopover>
            }
            value={Currency.from(this.props.totalTips)}
          />
        </div>
        <div className={styles.popoverContainer}>
          <LinkButton
            label={t("js.timesheets.totals_bar.recalculate_tips")}
            linkTo={this.getRecalcLink()}
            onClick={this.recalcTipsClick}
            popover={
              <AutoHoverPopover
                title={t("js.timesheets.totals_bar.tip_totals.recalculate_popover_title")}
                width="large"
              >
                <Text type="inherit">{t("js.timesheets.totals_bar.tip_totals.recalc_explanation")}</Text>
                <Text margin="paragraph" type="inherit">
                  {t("js.timesheets.totals_bar.tip_totals.recalc_explanation_refresh")}
                </Text>
                <Text color="lightest" type="inherit">
                  {t("js.timesheets.totals_bar.tip_totals.recalc_explanation_note")}
                </Text>
              </AutoHoverPopover>
            }
            type="minorAction"
          />
        </div>
      </div>
    )
  }
}

TipTotals.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  totalTips: PropTypes.number,
  timesheet: PropTypes.object,
  date: PropTypes.string,
  isWeekly: PropTypes.bool.isRequired,
}

TipTotals.defaultProps = { date: "", timesheet: null, totalTips: 0 }
