// @flow
import * as React from "react"
import * as Hooks from "hooks"
import * as Tanda from "../../clients/tanda"
import * as State from "../../models/state"
import * as Store from "../../store"

const FIVE_MINUTES = 1000 * 60 * 5

export type GateProps = {|
  +children: React.Node,
  +failure: React.Node,
  +loading: React.Node,
  +updatedAt?: number,
|}

type Props = {| ...GateProps, +url: string |}

export default function Resource({
  children,
  failure,
  loading,
  updatedAt = Number.NEGATIVE_INFINITY,
  url,
}: Props): React.Node {
  const [load, onLoad, onFail] = Hooks.useLoadState()
  const [state, dispatch] = Store.useContext()

  const mounted = React.useRef(true)
  const maxResourceAge = Date.now() - FIVE_MINUTES
  const resourceAge = State.resourceAge(state, url)
  const requireResource = resourceAge < updatedAt || resourceAge < maxResourceAge

  React.useEffect(() => {
    if (requireResource) {
      Tanda.getResource(url)
        .then((data) => {
          dispatch({
            data,
            type: "@patch/state",
            url,
            updatedAt: updatedAt === Number.NEGATIVE_INFINITY ? Date.now() : updatedAt,
          })
        })
        .then(() => {
          if (mounted.current) {
            onLoad()
          }
        })
        .catch(() => {
          if (mounted.current) {
            onFail()
          }
        })
    }

    return () => {
      mounted.current = false
    }
  }, [dispatch, requireResource, onFail, onLoad, updatedAt, url])

  if (!requireResource && load.status === "loading") {
    onLoad()
  }

  switch (load.status) {
    case "success":
      return <React.Fragment>{children}</React.Fragment>
    case "loading":
      return <React.Fragment>{loading}</React.Fragment>
    case "failure":
    default:
      return <React.Fragment>{failure}</React.Fragment>
  }
}
