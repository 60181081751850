/* @flow */
import * as React from "react"
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  Label,
  Bar,
  ComposedChart,
  Line,
} from "recharts"
import moment from "moment"
import { t as globalT } from "helpers/i18n"
import type { RechartsDataType, LocationDataType, LiveInsightsDataType } from "dashboard/live_insights/main"
import type { DataFiltersType } from "dashboard/live_insights/views/LiveWageTracker"
import Constants from "dashboard/live_insights/helpers/constants"
import TrackerChartTooltip from "../TrackerChartTooltip"
import styles from "./styles.module.scss"

const t = (key: string, ...args) => globalT(`js.dashboard.widgets.live_insights.graph.${key}`, ...args)

type PropType = {
  bucketData: ?LiveInsightsDataType,
  data: Array<RechartsDataType>,
  dataFilters: DataFiltersType,
  locationData: ?Array<LocationDataType>,
  time: ?moment,
  toggleRosteredLine: boolean,
}

export default class LiveWageTrackerChart extends React.PureComponent<PropType> {
  // eslint-disable-next-line flowtype/no-weak-types
  customizedTick: (time: string) => any = (time: string) => window.time_formatter.live_feed_time(time)

  render(): React.Element<"div"> {
    const labelStyles = {
      fill: "#888",
      textAnchor: "middle",
    }

    const costSelected = this.props.dataFilters.selected === "staff_cost"
    const countSelected = this.props.dataFilters.selected === "staff_count"
    const toggleRosteredData = this.props.toggleRosteredLine

    return (
      <div className={styles.ChartContainer}>
        <ResponsiveContainer height="100%" width="100%">
          <ComposedChart data={this.props.data} height={150} margin={{ right: 30, top: 20 }} width={730}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              axisLine={{ fill: "#A1B0C2" }}
              dataKey="time"
              interval={Math.round(this.props.data.length / 7)}
              tick={{ stroke: "#8A9BAE" }}
              tickFormatter={this.customizedTick}
              tickLine={{ fill: "#A1B0C2" }}
              xAxisId={0}
            />
            <XAxis dataKey="time" hide interval={Math.round(this.props.data.length / 7)} xAxisId={1} />
            {costSelected && (
              <YAxis
                allowDecimals={false}
                axisLine={{ fill: "#A1B0C2" }}
                interval={0}
                minTickGap={10}
                tick={{ stroke: "#8A9BAE" }}
                tickLine={{ fill: "#A1B0C2" }}
              >
                <Label angle={-90} offset={20} position="insideLeft" style={labelStyles} value={t("wages")} />
              </YAxis>
            )}
            {countSelected && (
              <YAxis
                allowDecimals={false}
                axisLine={{ fill: "#A1B0C2" }}
                interval={0}
                minTickGap={10}
                tick={{ stroke: "#8A9BAE" }}
                tickLine={{ fill: "#A1B0C2" }}
              >
                <Label angle={-90} position="center" style={labelStyles} value={t("staff_count")} />
              </YAxis>
            )}
            <Tooltip
              content={
                <TrackerChartTooltip
                  bucketData={this.props.bucketData}
                  locationData={this.props.locationData}
                  referenceTime={this.props.time}
                  selected={this.props.dataFilters.selected}
                  selectedDepartments={this.props.dataFilters.departments}
                />
              }
            />
            {costSelected && toggleRosteredData && (
              <Bar
                barSize={22 * (Constants.GRAPH_INTERVAL / Constants.BUCKET_INTERVAL)}
                dataKey={Constants.LIVE_WAGE_GRAPH_ENTRIES[this.props.dataFilters.selected].rostered.key}
                fill={Constants.LIVE_WAGE_GRAPH_ENTRIES[this.props.dataFilters.selected].rostered.color}
                isAnimationActive={false}
                radius={[
                  2 * (Constants.GRAPH_INTERVAL / Constants.BUCKET_INTERVAL),
                  2 * (Constants.GRAPH_INTERVAL / Constants.BUCKET_INTERVAL),
                  0,
                  0,
                ]}
                xAxisId={0}
              />
            )}
            {costSelected && (
              <Bar
                barSize={22 * (Constants.GRAPH_INTERVAL / Constants.BUCKET_INTERVAL)}
                dataKey={Constants.LIVE_WAGE_GRAPH_ENTRIES[this.props.dataFilters.selected].actual.key}
                fill={Constants.LIVE_WAGE_GRAPH_ENTRIES[this.props.dataFilters.selected].actual.color}
                isAnimationActive={false}
                radius={[
                  2 * (Constants.GRAPH_INTERVAL / Constants.BUCKET_INTERVAL),
                  2 * (Constants.GRAPH_INTERVAL / Constants.BUCKET_INTERVAL),
                  0,
                  0,
                ]}
                xAxisId={1}
              />
            )}
            {countSelected && toggleRosteredData && (
              <Line
                dataKey={Constants.LIVE_WAGE_GRAPH_ENTRIES[this.props.dataFilters.selected].rostered.key}
                dot={false}
                isAnimationActive={false}
                stroke={Constants.LIVE_WAGE_GRAPH_ENTRIES[this.props.dataFilters.selected].rostered.color}
                strokeDasharray={"8,6"}
                strokeWidth={3}
                type="stepAfter"
              />
            )}
            {countSelected && (
              <Line
                dataKey={Constants.LIVE_WAGE_GRAPH_ENTRIES[this.props.dataFilters.selected].actual.key}
                dot={false}
                isAnimationActive={false}
                stroke={Constants.LIVE_WAGE_GRAPH_ENTRIES[this.props.dataFilters.selected].actual.color}
                strokeWidth={3}
                type="stepAfter"
              />
            )}
            {this.props.time && (
              <ReferenceLine
                label={
                  this.props.time && {
                    position: "top",
                    value: `${window.time_formatter.live_feed_time(this.props.time)}`,
                    fill: `${styles.c_lightest_text}`,
                    fontSize: 14,
                  }
                }
                stroke="red"
                strokeWidth={2}
                x={this.props.time && this.props.time.format(Constants.DATE_TIME_FORMAT)}
                yAxisId={0}
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    )
  }
}
