/* @flow */
import * as React from "react"
import MultiFilter from "components/Select/MultiFilter"
import { t as globalT } from "helpers/i18n"
import { sortOptions } from "helpers/filter"
import Icon from "components/Icon"
import TeamGroup from "components/Select/TeamSelect/TeamGroup"
import TeamOption from "components/Select/TeamSelect/TeamOption"
import type { LocationDataType, DepartmentType } from "dashboard/live_insights/main"
import styles from "./styles.module.scss"

const t = (key) => globalT(`js.dashboard.widgets.live_insights.header.${key}`)

type DefaultPropType = {|
  departmentLimit: number,
|}

type PropType = {
  ...DefaultPropType,
  allDepartmentIds: Array<number>,
  handleTeamFilterOnChange: (event: { +target: { value: Array<number> } }) => void,
  locationData: Array<LocationDataType>,
  value: Array<number>,
}

type StateType = {
  options: $ReadOnlyArray<FilterOptionsType>,
}

type FilterOptionsType = {|
  label: string,
  options?: $ReadOnlyArray<FilterTeamOptionsType>,
  value: ?Array<number>,
|}

type FilterTeamOptionsType = {|
  data: { color: string, name: string },
  label: string,
  value: number,
|}

export default class DepartmentsFilter extends React.PureComponent<PropType, StateType> {
  static defaultProps: DefaultPropType = {
    departmentLimit: 500,
  }
  constructor(props: PropType) {
    super(props)
    // $FlowFixMe this doesnt unrefine
    this.state = { options: sortOptions(this.mapOptions(this.props.value)) }
  }

  mapOptions: (currValue: Array<number>) => Array<FilterOptionsType> = (
    currValue: Array<number>
  ): Array<FilterOptionsType> => {
    const listItems = []

    if (this.props.allDepartmentIds.length < this.props.departmentLimit) {
      listItems.push({ label: t("department_filter_default"), value: this.props.allDepartmentIds, options: [] })
    }
    return listItems.concat(
      this.props.locationData.map((location: LocationDataType): FilterOptionsType => {
        const departmentIds = location.departments.map((department) => department.id)
        return {
          label: location.name,
          options: location.departments.map((department: DepartmentType): FilterTeamOptionsType =>
            this.mapToOption(department)
          ),
          value: departmentIds.every((id) => currValue.includes(id))
            ? departmentIds
            : departmentIds.filter((id) => !currValue.includes(id)),
        }
      })
    )
  }

  mapToOption: (department: DepartmentType) => {|
    data: { color: string, name: string, ... },
    label: string,
    value: number,
  |} = (department: DepartmentType) => ({
    data: {
      color: department.color,
      name: department.name,
    },
    label: department.name,
    value: department.id,
  })

  render(): React.Node {
    return (
      <MultiFilter
        Group={TeamGroup}
        label={
          <div className={styles.DepartmentsFilterLabel}>
            <div className={"pr05"}>
              <Icon color="grey" type="filter-list" />
            </div>
            {t("department_filter_label")}
          </div>
        }
        newStyle
        onChange={this.props.handleTeamFilterOnChange}
        Option={TeamOption}
        options={this.state.options}
        searchOn
        size="s"
        tabIndex={0}
        value={this.props.value}
      />
    )
  }
}
