/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import LeaveModal from "time_off/Modal/leave_request"
import Actions from "timesheets/logic/actions"

class LeaveContainer extends React.PureComponent {
  static defaultProps = { defaults: {} }

  handleSuccess = () => {
    window.location.reload()
  }

  handleCancel = () => {
    this.props.dispatch(Actions.appCloseLeaveModal())
  }

  render() {
    return (
      <LeaveModal
        defaults={this.props.defaults || {}}
        onCancel={this.handleCancel}
        onSuccess={this.handleSuccess}
        open={this.props.defaults != null}
      />
    )
  }
}

LeaveContainer.defaultProps = { userId: null }
LeaveContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  defaults: PropTypes.object,
}

export default connect((state) => ({
  defaults: state.application.get("leaveModalDefaults"),
}))(LeaveContainer)
