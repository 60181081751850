// @flow
import * as M from "helpers/marshall"

export type Schema = {
  +assocId: ?string,
  +fieldId: ?string,
  +id: ?string,
  +subjectFieldId: ?string,
}

export function patch(curr: Schema, next: Schema): Schema {
  return {
    ...curr,
    id: next.id,
    fieldId: next.fieldId,
  }
}

export function fromJson(data: mixed): Schema {
  const f = M.object(data)

  return {
    id: M.number(f.id).toString(),
    fieldId: M.number(f.field_id).toString(),
    assocId: M.number(f.association_id).toString(),
    subjectFieldId: M.number(f.subject_field_id).toString(),
  }
}

export function toPayload(f: Schema): mixed {
  return {
    id: f.id,
    field_id: f.fieldId,
    association_id: f.assocId,
    subject_field_id: f.subjectFieldId,
  }
}
