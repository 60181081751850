// @flow
import nanoid from "nanoid"
import * as M from "helpers/marshall"
import * as Assoc from "./association"

export type Schema = {
  +[customKeys: string]: mixed,
  // records have some known keys
  +id: string,
  +modelId: string,
  +uuid: string,
}

export function createPatch(curr: Schema, next: $Shape<Schema>): $Shape<Schema> {
  const { id, modelId, uuid } = curr

  return Object.assign(
    { id, modelId, uuid },
    Object.keys(curr)
      .filter((k) => k in next && curr[k] !== next[k])
      .reduce((acc, k: string) => ({ ...acc, [k]: next[k] }), {})
  )
}

export function updateRecordAssocs(r: Schema, assocs: Array<Assoc.Schema>): Schema {
  if (!assocs || assocs.length < 1 || !assocs.find((i) => i.throughId)) {
    return r
  }
  const throughAssoc = assocs.find((x) => x.throughId)
  const parentAssoc = throughAssoc ? assocs.find((x) => x.id === throughAssoc.throughId) : null
  if (throughAssoc && parentAssoc) {
    const parentAssocValue = r[parentAssoc.key]
    if (parentAssocValue == null || (Array.isArray(parentAssocValue) && parentAssocValue.length === 0)) {
      return r
    }
    return {
      ...r,
      [throughAssoc.key]: null,
    }
  }
  return r
}

export function labelByKey(record: Schema, key: string): string {
  return M.maybeString(record[key]) || ""
}

export function refByKey(record: Schema, key: string): mixed {
  return record[key]
}

export function valByKey(record: Schema, key: string): mixed {
  return record[key]
}

export function updateAttrsFromEvent(r: Schema, e: SyntheticInputEvent<HTMLElement>): Schema {
  return {
    ...r,
    [e.target.name]: e.target.value,
  }
}

export function create(r: $Shape<Schema> = {}): Schema {
  return {
    ...r,
    id: r.id || "",
    modelId: r.modelId || "",
    uuid: nanoid(),
  }
}

export function fromJson(data: mixed): Schema {
  const { id, model_id, uuid, ...fields } = M.object(data)

  return {
    ...fields,
    id: M.number(id).toString(),
    modelId: M.number(model_id).toString(),
    uuid: M.string(uuid),
  }
}

export function toPayload(record: Schema): mixed {
  const { id, modelId, uuid, ...fields } = record
  return {
    ...fields,
    id: id,
    model_id: modelId,
    uuid: uuid,
  }
}

export type FileSchema = {|
  +file_content_type: ?string,
  +file_file_name: ?string,
  +file_file_size: ?number,
  +id: string,
  +modelId: string,
  +url: ?string,
  +uuid: string,
|}

export function asFile(m: Schema): FileSchema {
  return {
    id: m.id,
    modelId: m.modelId,
    uuid: m.uuid,
    file_file_name: M.maybeString(m.file_file_name),
    file_file_size: M.maybeNumber(m.file_file_size),
    file_content_type: M.maybeString(m.file_content_type),
    url: M.maybeString(m.url),
  }
}
