/* @flow */

import * as React from "react"
import cn from "classnames"
import styles from "./styles.module.scss"

type Props = {|
  +color: "black" | "white",
  +disabled: boolean,
  +htmlFor?: string, // Should correspond to the id of the input tag this is linked to (no # needed)
  +text: React.Node,
|}

export default function Label(props: Props): React.Node {
  const getColor = () => {
    if (!props.disabled) {
      return props.color === "white" ? styles.white : styles.black
    }
  }

  return (
    <label
      className={cn({ [styles.disabled]: props.disabled, [styles.noFor]: props.htmlFor == null }, getColor())}
      htmlFor={props.htmlFor}
    >
      {props.text}
    </label>
  )
}

Label.defaultProps = {
  color: "black",
  disabled: false,
  htmlFor: undefined,
}
