// @flow

import * as React from "react"
import _ from "lodash"
import cn from "classnames"
import Text from "components/Text"
import Icon from "components/Icon"
import { t as globalT } from "helpers/i18n"
import Select, { type SelectEvent, type Option } from "components/Select"
import DownloadCSVButton from "components/DownloadCSVButton"
import styles from "./styles.module.scss"

const t = (key, ...args) => globalT(`js.generic_components.react_data_table.${key}`, ...args)

type StringNumberType = string | number

type RequiredProps = {|
  columns: Array<string>,
  data: Array<{
    // The value in data could be any custom type
    // eslint-disable-next-line flowtype/no-weak-types
    +[key: string]: any,
  }>,
  keyProp: string,
|}

type Props = {|
  ...RequiredProps,
  borderStyle: boolean,
  card: boolean,
  columnFlexGrow: (column: string) => number,
  columnFormatter: (column: string) => string,
  contractsStyle: boolean,
  dataStreamStyle: boolean,
  defaultFirstRow?: {
    // The value in data could be any custom type
    // eslint-disable-next-line flowtype/no-weak-types
    +[key: string]: any,
  } | null,
  deletable: boolean,
  downloadable: boolean,
  filename: string,
  growStyle: boolean,
  handleDeleteRow: (id: ?StringNumberType) => void,
  initial_page_amount: number,
  onRowClick: (key: string) => void,
  rowClickable: boolean,
  sortFunction: (column: string) => (value: ?StringNumberType) => StringNumberType,
  // The value in data could be any custom type
  // eslint-disable-next-line flowtype/no-weak-types
  valueFormatter: (column: string) => (value: any) => React.Node,
|}

const DEFAULT_ACTIVE_HEADER: ?StringNumberType = null

const PAGINATION_OPTIONS: Array<Option> = [5, 10, 15, 20, 30].map((p) => ({ value: p, label: String(p) }))

const DEFAULT_ASCEND: boolean = true

const DEFAULT_HEADER_FORAMTTER = (v: string): string => v

const DEFAULT_FLEX_GROW = (v: string): number => 1

const DEFAULT_VALUE_FORAMTTER = (v: ?StringNumberType) => String(v)

const DEFAULT_SORT_FUNCTION = (v: ?StringNumberType) => (v != null ? v : "")

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  borderStyle: false,
  growStyle: false,
  contractsStyle: false,
  dataStreamStyle: false,
  card: false,
  columnFlexGrow: DEFAULT_FLEX_GROW,
  columnFormatter: DEFAULT_HEADER_FORAMTTER,
  defaultFirstRow: null,
  deletable: false,
  downloadable: false,
  filename: "TandaData",
  handleDeleteRow: _.noop,
  initial_page_amount: 10,
  onRowClick: _.noop,
  rowClickable: false,
  sortFunction: (column: string): ((v: ?StringNumberType) => number | string) => DEFAULT_SORT_FUNCTION,
  valueFormatter: (column: string): ((v: ?StringNumberType) => string) => DEFAULT_VALUE_FORAMTTER,
}

const isStringOrNumber = (v) => _.isString(v) || _.isNumber(v)

export default function DataTable(props: Props): React.Element<"div"> {
  const [active_column, setHeaderToSortBy] = React.useState(DEFAULT_ACTIVE_HEADER)
  const [ascend, setAscend] = React.useState(DEFAULT_ASCEND)
  const [pagination_amount, setPaginationAmount] = React.useState(props.initial_page_amount)
  const [current_page, setCurrentPage] = React.useState(0)

  const dataLength = props.data?.length || 0
  React.useEffect(() => {
    if (dataLength < current_page * pagination_amount) {
      setCurrentPage(0)
    }
  }, [dataLength, current_page, pagination_amount])

  function handleHeaderClick(column: string) {
    return () => {
      if (active_column !== column) {
        // Select active column
        setHeaderToSortBy(column)
        setAscend(true)
      } else if (active_column === column && !ascend) {
        // Deselect active column
        setHeaderToSortBy(null)
        setAscend(true)
      } else {
        // Toggle Ascend
        setAscend(!ascend)
      }
    }
  }

  function handlePaginationAmountChange(e: SelectEvent) {
    setPaginationAmount(e.target.value)
    setCurrentPage(0)
  }

  function handlePreviousPage() {
    current_page <= 0 ? setCurrentPage(0) : setCurrentPage(current_page - 1)
  }

  function handleNextPage() {
    setCurrentPage(current_page + 1)
  }

  function formatForCSV(data: Array<{ [key: string]: ?StringNumberType }>) {
    return data.map((d) =>
      _.fromPairs(_.toPairs(d).map(([k, v]) => [props.columnFormatter(k), props.valueFormatter(k)(v)]))
    )
  }

  const pre_sorted_data: Array<{ +[key: string]: ?StringNumberType }> = _.sortBy(props.data, (d) => {
    const val: ?StringNumberType = d[String(active_column)]
    const sort_func = props.sortFunction(String(active_column) || "")
    return sort_func(val)
  })
  const handleDeleteRow = props.handleDeleteRow
  const valueFormatter = props.valueFormatter
  const onRowClick = props.onRowClick
  const sorted_data = ascend ? pre_sorted_data : pre_sorted_data.reverse()
  const total_data_entries = sorted_data.length
  const start_idx = current_page * pagination_amount
  const next_page_idx = (current_page + 1) * pagination_amount
  const finish_idx = next_page_idx > total_data_entries ? total_data_entries : next_page_idx
  const data = sorted_data.slice(start_idx, finish_idx)
  const paginates = sorted_data.length >= props.initial_page_amount

  return (
    <div
      className={cn(styles.dataTableContainer, { [styles.card]: props.card, [styles.borderStyle]: props.borderStyle })}
    >
      <div
        className={cn(styles.row, styles.columnRow, {
          [styles.borderStyle]: props.borderStyle,
        })}
      >
        {props.columns.map((column) => (
          <div
            className={cn(
              styles.columnCell,
              { [styles.borderStyle]: props.borderStyle },
              props.contractsStyle && column === "actions" ? styles.actionsColumn : "",
              props.contractsStyle && column === "name" ? styles.nameColumn : ""
            )}
            key={column}
            onClick={handleHeaderClick(column)}
            onKeyUp={_.noop}
            role="button"
            style={{ flexGrow: props.columnFlexGrow(column) }}
            tabIndex={0}
          >
            <Text color={active_column === column ? "primary" : "lightest"} textOverflow="ellipsis" type="small">
              {props.columnFormatter(column)}
            </Text>
            {active_column === column && !ascend && <Icon color="primary" size="s" type="arrow-downward" />}
            {active_column === column && ascend && <Icon color="primary" size="s" type="arrow-upward" />}
          </div>
        ))}
      </div>
      {props.defaultFirstRow && (
        <div
          className={cn(styles.row, styles.bodyRow, styles.firstRow, {
            [styles.rowClickable]: props.rowClickable,
            [styles.growStyle]: props.growStyle,
            [styles.onlyFirstRow]: !props.data.length,
            [styles.contractsStyle]: props.contractsStyle,
          })}
          // $FlowFixMe We have already checked that defaultFirstRow prop isn't null
          key={props.defaultFirstRow[props.keyProp]}
          // $FlowFixMe We have already checked that defaultFirstRow prop isn't null
          onClick={() => onRowClick(String(props.defaultFirstRow[props.keyProp]))}
          onKeyUp={_.noop}
          role="button"
          tabIndex={0}
        >
          {props.columns.map((column) => {
            // $FlowFixMe We have already checked that defaultFirstRow prop isn't null or undefined
            const formattedValue = valueFormatter(column)(props.defaultFirstRow[column])
            return (
              <div
                className={cn(
                  styles.dataCell,
                  { [styles.borderStyle]: props.borderStyle },
                  props.contractsStyle && column === "name" ? styles.nameColumn : "",
                  props.contractsStyle && column === "actions" ? styles.actionsColumn : ""
                )}
                key={column}
                style={{ flexGrow: props.columnFlexGrow(column) }}
              >
                {isStringOrNumber(formattedValue) ? (
                  <Text color="light" textOverflow="ellipsis" type="small">
                    {formattedValue}
                  </Text>
                ) : (
                  formattedValue
                )}
              </div>
            )
          })}
        </div>
      )}
      {data.map((d, i) => (
        <div
          className={cn(styles.row, styles.bodyRow, {
            [styles.rowClickable]: props.rowClickable,
            [styles.growStyle]: props.growStyle,
            [styles.contractsStyle]: props.contractsStyle,
            [styles.dataStreamStyle]: props.dataStreamStyle,
          })}
          key={d[props.keyProp] || i}
          onClick={() => onRowClick(String(d[props.keyProp]))}
          onKeyUp={_.noop}
          role="button"
          tabIndex={0}
        >
          {props.columns.map((column) => {
            const formattedValue = valueFormatter(column)(d[column])
            return (
              <div
                className={cn(
                  styles.dataCell,
                  { [styles.borderStyle]: props.borderStyle },
                  props.contractsStyle && column === "name" ? styles.nameColumn : "",
                  props.contractsStyle && column === "actions" ? styles.actionsColumn : "",
                  props.dataStreamStyle && column === "name" ? styles.dataStreamNameColumn : "",
                  props.dataStreamStyle && column === "dataStreamJoins" ? styles.dataStreamDataJoinColumn : ""
                )}
                key={column}
                style={{ flexGrow: props.columnFlexGrow(column) }}
              >
                {isStringOrNumber(formattedValue) ? (
                  <Text color="light" textOverflow="ellipsis" type="small">
                    {formattedValue}
                  </Text>
                ) : (
                  formattedValue
                )}
              </div>
            )
          })}
          {props.deletable && (
            <div
              className={styles.deleteButtonContainer}
              onClick={(e) => {
                e.stopPropagation()
                handleDeleteRow(d[props.keyProp])
              }}
              onKeyUp={_.noop}
              role="button"
              tabIndex={0}
            >
              <Icon color="black" size="s" type="delete" />
            </div>
          )}
        </div>
      ))}
      {(props.downloadable || paginates) && (
        <div className={cn(styles.row, styles.footerRow)}>
          {props.downloadable && (
            <div className={styles.exportButton}>
              <DownloadCSVButton data={formatForCSV(sorted_data)} filename={props.filename} />
            </div>
          )}
          {paginates && (
            <div className={styles.footerControlsContainer}>
              <Text color="lightest" margin="r025" type="small">
                {" "}
                {t("rows_per_page")}
              </Text>
              <Select
                onChange={handlePaginationAmountChange}
                options={PAGINATION_OPTIONS}
                placeholder={globalT("js.rosters.rosters_overview.select_a_stat")}
                tabIndex={0}
                value={pagination_amount}
              />
              <Text color="lightest" margin="l05" type="small">
                {" "}
                {t("num_so_far", { start_idx: start_idx + 1, finish_idx: finish_idx, total: total_data_entries })}
              </Text>
              <div
                className={cn(styles.nextPreviousButton, { [styles.disabled]: current_page === 0 })}
                data-testid="prev-page-button"
                onClick={handlePreviousPage}
                onKeyUp={_.noop}
                role="button"
                tabIndex={0}
              >
                <Icon color="grey" size="l" type="chevron-left" />
              </div>
              <div
                className={cn(styles.nextPreviousButton, { [styles.disabled]: finish_idx === total_data_entries })}
                data-testid="next-page-button"
                onClick={handleNextPage}
                onKeyUp={_.noop}
                role="button"
                tabIndex={0}
              >
                <Icon color="grey" size="l" type="chevron-right" />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

DataTable.defaultProps = DEFAULT_PROPS
