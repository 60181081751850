/**
 * @flow
 */
import * as React from "react"
import moment from "moment"
import _ from "lodash"
import * as Time from "helpers/time"
import * as TimeFormatter from "helpers/time_formatter"
import type { Time as TimeType } from "../types"
import styles from "./styles.module.scss"

type Props = {
  disabled: ?boolean,
  format: 12 | 24,
  onBlur: () => void,
  onChange: (time: TimeType) => void,
  onFocus: () => void,
  value: TimeType,
}

type State = {
  focus: boolean,
  value: string,
}

export default class TimeInput extends React.PureComponent<Props, State> {
  node: ?HTMLInputElement

  static defaultProps: {| onBlur: () => void, onFocus: () => void |} = { onBlur: _.noop, onFocus: _.noop }

  static formatTimeString: (format: 12 | 24, TimeType) => string = (format: 12 | 24, { hour, minute }: TimeType) =>
    TimeFormatter.shortTime(moment({ hour, minute }), format)

  constructor(props: Props) {
    super(props)
    this.state = {
      focus: false,
      value: TimeInput.formatTimeString(props.format, props.value),
    }
  }

  UNSAFE_componentWillReceiveProps({ value }: Props) {
    if (value !== this.props.value) {
      this.setState({ value: TimeInput.formatTimeString(this.props.format, value) })
    }
  }

  handleChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    this.setState({ value: event.target.value })
  }

  handleBlur: () => void = () => {
    const time = Time.fromString(this.state.value)

    if (time.hour == null || time.minute == null) {
      this.setState({
        value: TimeInput.formatTimeString(this.props.format, {
          hour: 0,
          minute: 0,
        }),
      })

      return this.props.onChange({ hour: 0, minute: 0 })
    }

    if (time.hour === this.props.value.hour && time.minute === this.props.value.minute) {
      this.setState({ value: TimeInput.formatTimeString(this.props.format, { hour: time.hour, minute: time.minute }) })
    } else {
      this.props.onChange({ hour: time.hour, minute: time.minute })
    }

    this.setState({ focus: false })
    this.props.onBlur()
  }

  handleFocus: () => void = () => {
    this.setState({ focus: true })

    if (this.node != null) {
      this.node.select()
    }

    this.props.onFocus()
  }

  handleKeyUp: (event: SyntheticKeyboardEvent<HTMLInputElement>) => void = (
    event: SyntheticKeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key !== "Enter") {
      return
    }

    if (this.node != null) {
      this.node.blur()
    }
  }

  setReference: (node: ?HTMLInputElement) => void = (node: ?HTMLInputElement) => {
    this.node = node
  }

  render(): React.Element<"div"> {
    return (
      <div className={styles.timeInput}>
        <input
          className={styles.input}
          disabled={this.props.disabled}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onKeyUp={this.handleKeyUp}
          ref={this.setReference}
          value={this.state.value}
        />
      </div>
    )
  }
}
