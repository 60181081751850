// @flow
import Currency from "helpers/currency"

// Aka Team Code
export const getTeamShortName = (team_name: string): string => {
  // Remove parenthesis and ampersands then get initials
  const initials = (((team_name.replace("(", "") || "").replace(")", "") || "").replace(" & ", " ") || "")
    .split(" ")
    .map((w) => w[0])
    .join("")
  return initials.length > 2 ? initials.substring(0, 2) : initials
}

export const getLocationShortName = (location_name: string): string => {
  // Remove parenthesis and ampersands `()&` then get initials
  const initials = (((location_name.replace("(", "") || "").replace(")", "") || "").replace(" & ", " ") || "")
    .split(" ")
    .map((w) => w[0])
    .join("")
  return initials.length > 2 ? initials.substring(0, 2) : initials
}

export const formatStat = (value: number | string, format: string, round: ?boolean): string => {
  switch (format) {
    case "sales":
      return Currency.from(round === false ? value : Math.round(Number(value) * 100) / 100, round === false ? 2 : 0)
    case "currency":
      return Currency.from(round === false ? value : Math.round(Number(value) * 100) / 100, round === false ? 2 : 0)
    case "splh":
      return Currency.from(round === false ? value : Math.round(Number(value) * 100) / 100, 0) + "/h"
    case "percentage":
      return String(round === false ? value : Math.round(Number(value) * 10) / 10) + "%"
    case "hours":
      return String(round === false ? value : Math.round(Number(value) * 100) / 100).toString() + "h"
    case "small_hours":
      return String(round === false ? value : Math.round(Number(value) * 10) / 10).toString() + "h"
    case "people":
      return String(Math.round(Number(value))) + " 👤"
    case "whole_number":
      return String(Math.round(Number(value)))
    case "decimal_number":
      return String(Math.round(Number(value) * 100) / 100)
    case "Pizza Count":
      return String(round === false ? value : Math.round(Number(value))) + " 🍕"
    case "Sides Count":
      return String(round === false ? value : Math.round(Number(value))) + " 🥖"
    case "Deliveries":
      return String(round === false ? value : Math.round(Number(value))) + " 🚚"
    default: {
      const maybe_expression = format.match(/%{([a-zA-Z_]*)}/)
      if (maybe_expression == null) {
        return formatStat(value, "decimal_number")
      }
      const expression: RegExp$matchResult = maybe_expression
      const inner_format = expression[1]
      const innerFormat = formatStat(value, inner_format)
      return format.replace(expression[0], innerFormat)
    }
  }
}
