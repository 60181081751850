/* @flow */
import * as React from "react"
import Text from "components/Text"
import type { unitTypes } from "dashboard/live_insights/main"
import HoverIcon from "components/HoverIcon"
import Formatter from "../helpers/formatter"
import styles from "./styles.module.scss"

type PropTypes = {
  mainLabel: string,
  mainValue: number,
  secondaryLabel: string,
  secondaryTooltip: ?string,
  secondaryValue: number,
  unitType: unitTypes,
  varianceColor: "danger" | "success" | "default" | "primary",
  varianceLabel: string,
  varianceValue: number,
}

export default class DaySoFarMetric extends React.PureComponent<PropTypes> {
  render(): React.Element<"div"> {
    const { secondaryTooltip } = this.props
    const mainValue = Formatter.formatToUnit(this.props.mainValue, this.props.unitType)
    const secondaryValue = Formatter.formatToUnit(this.props.secondaryValue, this.props.unitType)
    const varianceValue = Formatter.formatToUnit(this.props.varianceValue, this.props.unitType)
    const variancePercentage: string =
      this.props.secondaryValue !== 0 && this.props.varianceValue !== 0
        ? " (" +
          Formatter.formatToUnit((this.props.varianceValue / this.props.secondaryValue) * 100, "percentage") +
          ")"
        : ""
    const varianceDisplay = this.props.unitType !== "percentage" ? varianceValue + variancePercentage : varianceValue
    return (
      <div className={styles.DaySoFarContainer}>
        <div className={styles.DSFLabelValueContainer}>
          <Text bold color="light" type="tiny">
            {this.props.mainLabel}
          </Text>
          <Text align="right" bold color="light" type="tiny">
            {mainValue}
          </Text>
        </div>
        <div className={styles.DSFLabelValueContainer}>
          <div className={styles.DSFLabelWithTooltipContainer}>
            <Text color="lightest" type="tiny">
              {this.props.secondaryLabel}
            </Text>
            {this.props.secondaryTooltip && (
              <div className={styles.DSFTooltipIconContainer}>
                <HoverIcon color="grey" tip={secondaryTooltip} tipPosition="bottom" type="info" width="medium" />
              </div>
            )}
          </div>
          <Text align="right" color="lightest" type="tiny">
            {secondaryValue}
          </Text>
        </div>
        <div className={styles.DSFLabelValueContainer}>
          <Text color="lightest" type="tiny">
            {this.props.varianceLabel}
          </Text>
          <Text align="right" bold color={this.props.varianceColor} type="tiny">
            {varianceDisplay}
          </Text>
        </div>
      </div>
    )
  }
}
