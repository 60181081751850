/* @flow */

import * as React from "react"
import cn from "classnames"
import _ from "lodash"
import Icon, { type IconColor } from "components/Icon"
import Label from "components/Label"
import styles from "./styles.module.scss"

type RequiredProps = {|
  value: ?string | boolean | number,
|}

type Props = {|
  ...RequiredProps,
  disabled: boolean,
  disabledMargin: boolean,
  hovered: boolean,
  label: React.Node,
  labelPos: "left" | "right",
  onClick: (any) => void, // eslint-disable-line flowtype/no-weak-types
  padding: ?string,
  selected: boolean,
|}

type State = {
  focus: boolean,
  iconColor: IconColor,
}
const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  disabled: false,
  disabledMargin: false,
  hovered: false,
  label: "",
  labelPos: "right",
  onClick: _.noop,
  selected: false,
  padding: null,
}

export default class RadioButton extends React.PureComponent<Props, State> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  static displayName: ?string = "RadioButton"

  state: State = {
    iconColor: "grey",
    focus: false,
  }

  handleActivation: (value: ?(string | boolean | number)) => void = (value: ?string | boolean | number) => {
    if (this.props.disabled) {
      return
    }

    this.props.onClick(value)
  }

  onKeyUp: (e: SyntheticKeyboardEvent<HTMLDivElement>) => void = (e: SyntheticKeyboardEvent<HTMLDivElement>) => {
    if (e.key === " " || e.key === "Enter") {
      this.handleActivation(this.props.value)
    }
  }

  onClick: (e: SyntheticMouseEvent<HTMLDivElement>) => void = (e: SyntheticMouseEvent<HTMLDivElement>) => {
    this.handleActivation(this.props.value)
  }

  onHover: () => void = () => {
    this.setState({ iconColor: "primary" })
  }

  offHover: () => void = () => {
    this.setState({ iconColor: "grey" })
  }

  getIconColor(): IconColor {
    if (this.props.disabled) {
      return "disabled"
    } else if (this.props.selected || this.props.hovered) {
      return "primary"
    } else {
      return this.state.iconColor
    }
  }

  getLabel(): React.Node {
    if (!this.props.label) {
      return null
    }
    if (typeof this.props.label === "string") {
      return <Label disabled={this.props.disabled} text={this.props.label} />
    } else {
      return this.props.label
    }
  }

  getTabIndex(): null | number {
    if (this.props.disabled) {
      return null
    }
    return 0
  }

  render(): React.Element<"div"> {
    return (
      <div
        className={cn(styles.radioOption, {
          [styles.disabled]: this.props.disabled,
          [styles.disabledMargin]: this.props.disabledMargin,
        })}
        onMouseEnter={this.onHover}
        onMouseLeave={this.offHover}
        style={{ padding: this.props.padding }}
      >
        <div
          className={cn(styles.pos, styles[this.props.labelPos])}
          onClick={this.onClick}
          onKeyUp={this.onKeyUp}
          role="button"
          tabIndex={this.getTabIndex()}
        >
          <div
            className={cn(styles.circle, {
              [styles.disabled]: this.props.disabled,
              [styles.disabledMargin]: this.props.disabledMargin,
            })}
          >
            <Icon
              color={this.getIconColor()}
              type={this.props.selected ? "radio-button-checked" : "radio-button-unchecked"}
            />
          </div>
          {this.getLabel()}
        </div>
      </div>
    )
  }
}
