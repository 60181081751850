/* @flow */

import * as React from "react"
import { s3ImagePath } from "helpers/image"
import Text from "../Text"
import Button from "../Button"
import styles from "./styles.module.scss"

type PropsType = {
  buttonLabel: string,
  buttonLink: string,
  image: string,
  message: string,
}

const EmptyState = (props: PropsType): React.Element<"div"> => (
  <div>
    <div className={styles.stateImg}>
      <img alt={props.message} src={s3ImagePath(props.image)} />
    </div>
    <div className={styles.messageContainer}>
      <Text>{props.message}</Text>
      {props.buttonLabel && props.buttonLink && (
        <Button iconRight="open-in-new" label={props.buttonLabel} link={props.buttonLink} />
      )}
    </div>
  </div>
)

EmptyState.defaultProps = { image: "no_changes.png", buttonLabel: "", buttonLink: "" }

export default EmptyState
