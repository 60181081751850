// @flow
import * as React from "react"
import { map } from "lodash"
import { t } from "helpers/i18n"

import { FilterSelect } from "components/Select/FilterSelect"

import TeamGroup from "components/Select/TeamSelect/TeamGroup"
import TeamOption from "components/Select/TeamSelect/TeamOption"

import * as OptionHelpers from "components/DropdownList/helpers/option"

import { UNCATEGORISED_SHIFTS } from "timesheets/models/timesheet/helpers"

type Props = {
  actions: *,
  disabled: boolean,
  multiLocation: boolean,
  shift: *,
}

export function mapOptions(shift: *, multiLocation: boolean): * {
  const locations = shift
    .getIn(["user", "departments"])
    .toSet()
    .add(shift.get("department"))
    .filter(Boolean)
    .groupBy((d) => d.getIn(["location", "name"]))

  return [
    {
      label: t("js.timesheets.shift_card.config.default_team"),
      value: null,
    },
  ].concat(
    map(locations.toJS(), (departments, locationName: string) => {
      const shortName = departments[0].location.short_name

      return {
        data: { selectable: false },
        label: multiLocation && shortName ? `${shortName} : ${locationName}` : locationName,
        options: departments.map((department) => ({
          data: {
            color: department.colour,
            name: multiLocation && shortName ? `${shortName} : ${department.name}` : department.name,
          },
          label: department.name,
          value: department.id,
        })),
      }
    })
  )
}

export function isError(shift: *, options: *): "error" | "warning" | "default" {
  if (options.length <= 1) {
    return "default"
  } else if (
    shift.get("department_id") == null ||
    OptionHelpers.find(options, shift.get("department_id"))?.label === UNCATEGORISED_SHIFTS
  ) {
    return "error"
  } else if (shift.get("department_conflict")) {
    return "warning"
  } else {
    return "default"
  }
}

export default function DepartmentSelect({
  actions,
  disabled = false,
  multiLocation = false,
  shift,
}: Props): React.Node {
  const options = React.useMemo(() => mapOptions(shift, multiLocation), [multiLocation, shift])
  const error = React.useMemo(() => isError(shift, options), [options, shift])

  const onChange = React.useCallback(
    (e) => actions.shiftsSync({ shift, fields: { department_id: e.target.value } }),
    [actions, shift]
  )

  return (
    <FilterSelect
      color={error}
      disabled={disabled}
      Group={TeamGroup}
      onChange={onChange}
      Option={TeamOption}
      options={options}
      placeholder={t("js.timesheets.shift_card.config.default_team")}
      value={shift.get("department_id")}
    />
  )
}
