// @flow

import * as React from "react"
import _ from "lodash"
import cn from "classnames"

import * as OptionHelpers from "components/DropdownList/helpers/option"

import Bubble from "components/Bubble"
import DropdownList, { type Props as DropdownListProps } from "components/DropdownList"
import DefaultGroup from "components/DropdownList/DefaultGroup"
import Icon from "components/Icon"
import type { SelectEvent, Options, Value } from "components/Select"
import filterOptions from "helpers/filter"

import FilterInput from "../FilterInput"
import DefaultOption from "../MultiSelect/DefaultOption"

import Badges from "./Badges"

import styles from "./styles.module.scss"

type RequiredProps = {|
  onChange: (e: SelectEvent) => mixed,
  options: $PropertyType<DropdownListProps, "options">,
|}

type Props = {|
  ...RequiredProps,
  allowEnter: boolean,
  badges: number,
  children: ?React.Node,
  color: string,
  disabled: boolean,
  dropdownSize: "xs" | "s" | "m" | "l",
  forceShowTip: boolean,
  Group: $PropertyType<DropdownListProps, "Group">,
  htmlName: ?string,
  label: ?string | React.Node,
  newStyle: boolean,
  onClose: () => mixed,
  Option: $PropertyType<DropdownListProps, "Option">,
  roundedBorder: boolean,
  searchOn: boolean,
  size: "s" | "m" | "l",
  tabIndex: ?number,
  tipText: string,
  value: Array<Value>,
|}

type State = {|
  focusedValue: null,
  isHovered: boolean,
  isOpen: boolean,
  options: Options,
  searchInput: string,
|}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  allowEnter: true,
  newStyle: false,
  label: null,
  color: "default",
  disabled: false,
  dropdownSize: "m",
  forceShowTip: false,
  Group: DefaultGroup,
  htmlName: null,
  Option: DefaultOption,
  roundedBorder: false,
  searchOn: false,
  onClose: _.noop,
  size: "m",
  tabIndex: null,
  tipText: "",
  value: [],
  badges: 0,
  children: null,
}

export default class MultiFilter extends React.PureComponent<Props, State> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  static getDerivedStateFromProps(props: Props, state: State): State {
    if (props.options === state.options) {
      return state
    }

    if (state.searchInput) {
      return {
        ...state,
        options: filterOptions(props.options, state.searchInput),
      }
    }

    return { ...state, options: props.options }
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      focusedValue: null,
      isOpen: false,
      isHovered: false,
      options: this.props.options,
      searchInput: "",
    }

    this.handleOutsideClick = this.handleOutsideClick.bind(this)
  }
  componentDidMount() {
    document.addEventListener("click", this.handleOutsideClick)
  }

  componentDidUpdate: (_: Props, prevState: State) => void = (_: Props, prevState: State) => {
    // focus the input when dropdown opens
    if (this.input && this.state.isOpen && !prevState.isOpen) {
      this.input.focus()
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick)
  }

  // eslint-disable-next-line flowtype/no-weak-types
  handleFocusNextOption: any = OptionHelpers.offsetFocusedValue(1)

  // eslint-disable-next-line flowtype/no-weak-types
  handleFocusPrevOption: any = OptionHelpers.offsetFocusedValue(-1)

  container: ?HTMLDivElement = null
  input: ?HTMLInputElement = null
  dropdown: ?HTMLDivElement = null

  handleMouseEnter: () => void = () => {
    this.setState({ isHovered: true })
  }

  handleMouseLeave: () => void = () => {
    this.setState({ isHovered: false })
  }

  handleBlur: (event: SyntheticFocusEvent<HTMLDivElement>) => void = (event: SyntheticFocusEvent<HTMLDivElement>) => {
    const nextTarget = event.relatedTarget || document.activeElement
    if (!nextTarget || !(nextTarget instanceof Node) || !this.container?.contains(nextTarget)) {
      this.handleClose()
    }
  }

  handleFilterInputChange: (event: { +target: { value: string, ... }, ... }) => void = (event: {
    +target: { value: string },
  }) => {
    this.setState({
      searchInput: event.target.value,
      options: filterOptions(this.props.options, event.target.value),
    })
  }

  handleKeyDown: (event: SyntheticKeyboardEvent<HTMLInputElement>) => void = (
    event: SyntheticKeyboardEvent<HTMLInputElement>
  ) => {
    switch (event.keyCode) {
      case 13: // enter
        event.preventDefault()
        if (this.state.isOpen && this.state.focusedValue !== null && this.props.allowEnter) {
          // $FlowFixMe keyboard event is not a input event but its fine
          event = {
            ...event,
            target: {
              ...event.target,
              value: this.state.focusedValue,
            },
          }
          // $FlowFixMe keyboard event is not a input event but its fine
          this.handleItemToggled(event)
        }
        break
      case 27: // escape
        event.preventDefault()
        if (this.state.isOpen) {
          this.container?.focus()
          this.handleClose()
        }
        break
      case 38: // up
        event.preventDefault()
        return this.setState({
          focusedValue: this.handleFocusPrevOption(this.state.options, this.state.focusedValue),
        })
      case 40: // down
        event.preventDefault()
        return this.setState({
          focusedValue: this.handleFocusNextOption(this.state.options, this.state.focusedValue),
        })
    }
  }

  handleClose: () => void = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false })
      this.props.onClose && this.props.onClose()
    }
  }

  handleOpen: (event: MouseEvent) => void = (event) => {
    const target = event.target
    if (target && target instanceof Node && this.dropdown?.contains(target)) {
      return
    }

    if (!this.props.disabled) {
      this.setState({ isOpen: !this.state.isOpen })
    }
  }

  handleItemToggled: (event: SelectEvent) => mixed = (event: SelectEvent) => {
    this.input?.focus()

    // $FlowFixMe we are reusing badges type here
    if (event.target.data && event.target.data.replace) {
      return this.props.onChange(event)
    }

    const propValue = this.props.value
    if (event.target.value instanceof Array) {
      // group
      const eventValue = event.target.value
      if (eventValue.length === 0) {
        // special case of selecting an "all teams" style option
        this.props.onChange({
          ...event,
          target: { ...event.target, value: [], name: this.props.htmlName || "" },
        })
      } else {
        const toggleables = eventValue
        const allValuesOn = toggleables.every((t) => propValue.includes(t))
        const newValue = toggleables.reduce(
          (acc, toggleable) =>
            allValuesOn
              ? acc.filter((option) => option !== toggleable) // unselect all options in the given group
              : _.uniq(acc.concat(toggleable)), // select all options in the given group
          propValue
        )
        this.props.onChange({
          ...event,
          target: { ...event.target, value: newValue, name: this.props.htmlName || "" },
        })
      }
    } else {
      const eventValue = event.target.value
      this.props.onChange({
        // individual option
        ...event,
        target: {
          ...event.target,
          name: this.props.htmlName || "",
          value: propValue.includes(eventValue)
            ? propValue.filter((option) => String(option) !== String(eventValue)) // unselecting an option
            : propValue.concat(eventValue), // selecting a new option
        },
      })
    }
  }

  handleOptionClick: (event: SelectEvent) => void = (event: SelectEvent) => {
    this.handleItemToggled(event)
  }

  handleOptionEnter: (event: SelectEvent) => void = (event: SelectEvent) => {
    this.setState({ focusedValue: event.target.value })
  }

  handleOptionLeave: () => void = () => {
    this.setState({ focusedValue: null })
  }

  setReference: (node: HTMLDivElement | null) => void = (node) => {
    this.container = node
  }

  setInputRef: (node: HTMLInputElement | null) => void = (node) => {
    this.input = node
  }

  setDropDownRef: (node: HTMLDivElement | null) => void = (node) => {
    this.dropdown = node
  }

  handleOutsideClick: (event: MouseEvent) => void = (event: MouseEvent) => {
    if (!this.container) {
      return
    }

    const target = event.target
    const nextTarget = event.relatedTarget || document.activeElement
    if (!nextTarget || !(target instanceof Node) || !(nextTarget instanceof Node)) {
      return
    }

    if (this.state.isOpen && !this.container.contains(target) && !this.container.contains(nextTarget)) {
      this.handleClose()
    }
  }

  renderTooltip: () => void | React.Node = () => {
    if (this.props.tipText && !this.state.isOpen) {
      return <Bubble hovered={this.props.forceShowTip || this.state.isHovered}>{this.props.tipText}</Bubble>
    }
  }

  render(): React.Element<"div"> {
    return (
      <div
        className={cn(styles.Select, styles[this.props.size], styles[this.props.color], {
          [styles.open]: this.state.isOpen,
          [styles.newStyle]: this.props.newStyle,
          [styles.badges]: this.props.badges,
          [styles.roundedBorder]: this.props.roundedBorder,
          [styles.noValues]: this.props.badges && this.props.value.length === 0,
        })}
        data-testid="multi-filter"
        disabled={this.props.disabled}
        onBlur={this.handleBlur}
        onClick={this.handleOpen}
        onKeyDown={this.handleKeyDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={_.noop}
        ref={this.setReference}
        role="button"
        tabIndex={this.state.isOpen ? -1 : this.props.tabIndex}
      >
        {Boolean(this.props.badges) && this.props.value.length > 0 && (
          <Badges
            disabled={this.props.disabled}
            isOpen={this.state.isOpen}
            numberOfBadges={this.props.badges}
            // $FlowFixMe different events but should be fine
            onClick={this.handleItemToggled}
            // $FlowFixMe todo, this is too tricky to fix atm
            options={this.props.options}
            selected={this.props.value}
            size={this.props.size}
          />
        )}
        {this.props.searchOn && this.state.isOpen && (Boolean(!this.props.badges) || this.props.value.length === 0) ? (
          <FilterInput
            onChange={this.handleFilterInputChange}
            searchInput={this.state.searchInput}
            setInputRef={this.setInputRef}
            size={this.props.size}
          />
        ) : (
          Boolean(!this.props.badges) && <span className={styles.label}>{this.props.label}</span>
        )}
        <Icon color="grey" size={this.props.size} type={`arrow-drop-${this.state.isOpen ? "up" : "down"}`} />
        <div className={styles.list}>
          {/* render filter search inside dropdown list if badges selected */}
          {this.props.searchOn && this.state.isOpen && Boolean(this.props.badges) && this.props.value.length > 0 && (
            <FilterInput
              onChange={this.handleFilterInputChange}
              searchInput={this.state.searchInput}
              setInputRef={this.setInputRef}
              size={this.props.size}
            />
          )}
          <div ref={this.setDropDownRef}>
            <DropdownList
              focusedValue={this.state.focusedValue}
              Group={this.props.Group}
              length={this.props.dropdownSize}
              newStyle={this.props.newStyle}
              onClick={this.handleOptionClick}
              onOptionEnter={this.handleOptionEnter}
              onOptionLeave={this.handleOptionLeave}
              open={this.props.disabled ? false : this.state.isOpen}
              Option={this.props.Option}
              options={this.state.options}
              selectedValues={this.props.value}
              size={this.props.size}
            >
              {this.props.children}
            </DropdownList>
          </div>
        </div>
      </div>
    )
  }
}
