/* @flow */

import * as React from "react"
import cn from "classnames"
import styles from "./styles.module.scss"

type Props = {|
  children: React.Node,
|}

export default function AccordionHeader(props: Props): React.Element<"div"> {
  return <div className={cn(styles.accordionHeader)}>{props.children}</div>
}
