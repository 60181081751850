// @flow
import * as State from "../../models/state"
import type { Action } from "../action"
import models from "./models"
import records from "./records"
import resources from "./resources"
import forms from "./forms"
import formTemplates from "./formTemplates"

export default function reducer(state: State.Schema, action: Action): State.Schema {
  return {
    models: models(state.models, action),
    records: records(state.records, action),
    resources: resources(state.resources, action),
    forms: forms(state.forms, action),
    formTemplates: formTemplates(state.formTemplates, action),
  }
}
