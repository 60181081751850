/* @flow */

import * as React from "react"
import _ from "lodash"
import { s3ImagePath } from "helpers/image"
import styles from "./styles.module.scss"

type Props = {|
  messageText: string,
  onTryAgain: () => void,
  retryText: string,
|}

export default class ErrorState extends React.PureComponent<Props> {
  render(): React.Element<"div"> {
    return (
      <div className={styles.State}>
        <div className={styles.ErrorState}>
          <div className={styles.StateContainer}>
            <img
              alt="Error state cloud x 300px"
              className={styles.Error}
              src={s3ImagePath("empty-state-cloud-x-300px.png")}
            />
          </div>
          <div className={styles.MessageContainer}>
            <span className={styles.ErrorMessage}>{this.props.messageText}</span>
          </div>
          <div className={styles.ButtonContainer}>
            <a onClick={this.props.onTryAgain} onKeyUp={_.noop} role="button" tabIndex={0}>
              <span>{this.props.retryText}</span>
            </a>
          </div>
        </div>
      </div>
    )
  }
}
