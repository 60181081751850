// @flow

import * as React from "react"
import _ from "lodash"
import cn from "classnames"
import { outOfViewport, getNewPosition, getViewPort, type PositionType } from "components/Hover/util"
import Icon from "components/Icon"
import Hover from "../Hover"
import styles from "./styles.module.scss"

export type RequiredProps = {|
  children: React.Node,
|}

export type Props = {|
  ...RequiredProps,
  customHeight: ?string,
  customWidth: ?string,
  defaultPosition: ?PositionType,
  delayed: boolean,
  delayedExit: boolean,
  excludedPositions: Array<PositionType>,
  hovered: boolean,
  noPadding: boolean,
  noPointerEvents: boolean,
  offset: number,
  onClose: ?() => mixed,
  onOutsideClick: ?() => mixed,
  padding: ?string,
  position: PositionType | "auto",
  setRef: ((null | HTMLDivElement) => mixed) | {| current: mixed |},
  // Unstick will prevent the popover staying 'hovered' even though the mouse has moved off the parent that triggered it
  // It only works if your <BasePopover> is one node below your hovertarget
  // It creates an event handler that runs every mouse move so may lag if you abuse it
  unstick: boolean,
  width: "auto" | "medium" | "large",
|}

export const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  position: "bottom",
  defaultPosition: null,
  offset: 0.75,
  onClose: null,
  delayed: false,
  delayedExit: false,
  noPointerEvents: false,
  width: "auto",
  customWidth: null,
  customHeight: null,
  noPadding: false,
  padding: null,
  setRef: _.noop,
  onOutsideClick: null,
  hovered: false,
  unstick: false,
  excludedPositions: [],
}

type RefNode = {|
  current: mixed,
|}

export default function Popover(props: Props): React.Element<"div"> {
  const contents_ref: RefNode = React.createRef()
  const target_ref = React.createRef()

  const [position, setPosition] = React.useState(props.defaultPosition || "right")
  const [position_set, setPositionSet] = React.useState(props.position !== "auto")
  const [parentHovered, setParentHovered] = React.useState(true)
  const customStyles = {
    width: props.customWidth,
    maxWidth: props.customWidth,
    minWidth: props.customWidth,
    maxHeight: props.customHeight,
    visibility: position_set ? null : "hidden",
  }
  React.useEffect(() => {
    if (
      props.position === "auto" &&
      contents_ref.current != null &&
      target_ref.current != null &&
      position_set === false
    ) {
      const target_el = target_ref.current
      const contents_el = contents_ref.current
      // $FlowFixMe its assumed this el is a valid node
      const contents_bounds = contents_el.getBoundingClientRect()
      const target_bounds = target_el.getBoundingClientRect()
      if (props.defaultPosition == null || outOfViewport(contents_bounds, getViewPort(target_el))) {
        setPosition(getNewPosition(contents_bounds, target_bounds, target_el, props.excludedPositions))
      }
      setPositionSet(true)
    }
  }, [position, position_set, props, contents_ref, target_ref])
  const onOutsideClick = props.onOutsideClick
  React.useEffect(() => {
    const outsideClick = (e: SyntheticEvent<HTMLDivElement>) => {
      // $FlowFixMe we know this node is a valid HTML node, so ignore the possibility that it isnt.
      if (contents_ref.current != null && !contents_ref.current.contains(e.target)) {
        onOutsideClick && onOutsideClick()
      }
    }
    onOutsideClick && window.document.addEventListener("click", outsideClick)
    return () => {
      onOutsideClick && window.document.removeEventListener("click", outsideClick)
    }
  }, [contents_ref, onOutsideClick])

  const mouseMoveListener = React.useCallback(
    (e: EventTarget) => {
      const parentNode = target_ref.current && target_ref.current.parentNode.parentNode
      // $FlowFixMe this is still untypable
      if (parentNode != null && parentNode.contains(e.target)) {
        parentHovered === false && setParentHovered(true)
      }
      // $FlowFixMe this is still untypable
      if (parentNode != null && !parentNode.contains(e.target)) {
        parentHovered === true && setParentHovered(false)
      }
    },
    [parentHovered, setParentHovered, target_ref]
  )
  React.useEffect(() => {
    if (props.unstick) {
      window.addEventListener("mouseover", mouseMoveListener)

      return () => {
        window.removeEventListener("mouseover", mouseMoveListener)
      }
    }
  }, [props.unstick, mouseMoveListener])
  const final_position = props.position === "auto" ? position : props.position
  const classes = cn(styles.Popover, styles[final_position], { [styles[props.width]]: props.width !== "auto" })
  const hovered = parentHovered && props.hovered
  return (
    <div className={styles.HoverWrapper} ref={props.setRef}>
      <Hover
        delayed={props.delayed}
        delayedExit={props.delayedExit}
        hovered={hovered}
        noPointerEvents={props.noPointerEvents}
        offset={props.offset}
        position={final_position}
        setRef={target_ref}
      >
        <div className={classes} ref={contents_ref} style={customStyles}>
          <div
            className={cn(styles.content, {
              [styles.noPadding]: props.noPadding,
              [styles.scrollable]: props.customHeight != null,
            })}
            style={{ padding: props.padding }}
          >
            {props.onClose && (
              <div className={styles.popoverClose} onClick={props.onClose} onKeyUp={_.noop} role="button" tabIndex={0}>
                <Icon color="grey" size="small" type="close" />
              </div>
            )}
            {props.children}
          </div>
        </div>
      </Hover>
    </div>
  )
}

Popover.defaultProps = DEFAULT_PROPS
