/* @flow */

import * as React from "react"
import cn from "classnames"
import { CSSTransitionGroup } from "react-transition-group-legacy"

import styles from "./styles.module.scss"
import ModalClose from "./ModalClose"

const transition = {
  appear: styles.modal_enter,
  appearActive: styles.modal_enter_active,
  enter: styles.modal_enter,
  enterActive: styles.modal_enter_active,
  leave: styles.modal_leave,
  leaveActive: styles.modal_leave_active,
}

type RequiredProps = {|
  children: React.Node,
  onExit: () => void,
  open: boolean,
|}

type Props = {|
  ...RequiredProps,
  exitable: boolean,
  fixedMaxHeight: boolean, // Forces the modal to never exceed the size of the viewport
  fixedTop: boolean,
  size: "s" | "m" | "l" | "xl",
  topBorder?: boolean,
  yOverflow: boolean,
|}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  size: "l",
  exitable: true,
  fixedTop: false,
  topBorder: false,
  fixedMaxHeight: false,
  yOverflow: false,
}

export default function Modal(props: Props): React.Node {
  const performExit = (e: KeyboardEvent) => {
    if (props.exitable && ((e.key && e.key === "Escape") || e.type === "click")) {
      props.onExit()
    }
  }

  React.useEffect(() => {
    if (props.exitable) {
      document.addEventListener("keyup", performExit)
    }
    return () => document.removeEventListener("keyup", performExit)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  // We only want the above effect to run on mount/unmount

  React.useEffect(() => {
    const body = document.body
    if (body) {
      body.classList.toggle(styles.noScroll, props.open)
    }
    return () => {
      const body = document.body
      if (body) {
        body.classList.toggle(styles.noScroll, false)
      }
    }
  }, [props.open])

  return (
    <CSSTransitionGroup
      transitionAppear
      transitionAppearTimeout={350}
      transitionEnterTimeout={350}
      transitionLeaveTimeout={300}
      transitionName={transition}
    >
      {props.open ? (
        <div className={cn(styles.modal_position, { [styles.fixed_top]: props.fixedTop })} key="modal">
          <div aria-hidden className={styles.full_page_cover} onClick={performExit} />
          <div
            className={cn(styles.modal, styles[props.size], {
              [styles.fixed_max_height]: props.fixedMaxHeight,
              [styles.top_border]: props.topBorder,
              [styles.y_overflow]: props.yOverflow,
            })}
          >
            {props.exitable ? <ModalClose onClick={props.onExit} /> : null}
            {props.children}
          </div>
        </div>
      ) : null}
    </CSSTransitionGroup>
  )
}

Modal.defaultProps = DEFAULT_PROPS
