/* @flow */
import * as React from "react"
import Text from "components/Text"
import Icon from "components/Icon"
import type { chartTypes } from "dashboard/live_insights/main"
import styles from "./styles.module.scss"

type PropType = {
  chartType: chartTypes,
  color: string,
  isSelected: boolean,
  opacity: ?string,
  paddingLeft: ?string,
  paddingTop: ?string,
  text: string,
}

export default class LineDisplayContainer extends React.PureComponent<PropType> {
  static defaultProps: PropType = {
    chartType: "solidLine",
    color: "",
    isSelected: false,
    opacity: "",
    paddingLeft: "",
    paddingTop: "",
    text: "",
  }

  render(): React.Element<"div"> {
    const { chartType, color, isSelected, opacity, paddingLeft, paddingTop, text } = this.props
    return (
      <div className={styles.LineDisplayContainer} style={{ opacity, paddingLeft }}>
        <div className={styles.tooltipDeptColourBallWrapper}>
          <Icon customColor={color} size="s" type={chartType} />
        </div>
        <div style={{ paddingTop }}>
          <Text color={isSelected ? "light" : "lightest"} type="tiny">
            {text}
          </Text>
        </div>
      </div>
    )
  }
}
