/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import { OrderedMap } from "immutable"
import { createReducer, createActions } from "redux-em"

import * as Timesheet from "timesheets/models/timesheet"

const timesheetsInit = () => new OrderedMap()

const timesheetsSet = (state, { id, data }) => state.set(id, Timesheet.build(data))

const timesheetsMerge = (state, { id, data }) => state.mergeIn([id], data)

export const Core = {
  timesheetsInit,
  timesheetsSet,
  timesheetsMerge,
}

export const timesheetsActions = createActions(Core)
export const timesheetsReducer = createReducer(Core)
