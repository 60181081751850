/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import { compose } from "underscore" // eslint-disable-line underscore-to-lodash/prefer-import-lodash
import {
  ErrorPosition,
  ErrorType,
  TimeField,
  TimeFields,
  BreaksFieldName,
  BreakTimeField,
  ShiftBreakType,
} from "./constants"
import { breakSorter } from "./collections"

//
// validation should operate on a series of chronological shifts
// which require determination of whether they contain any overlaps.
//
// validation will return a series of time contexts, with their
// corresponding field and id as identifiers, and possibly an error
// depending on the state of the shift series.
//
// type TimeContext =
//   { time: Moment
//   , date: string
//   , id: number
//   , field: string
//   , error?: ErrorContext
//   }
//
// type ErrorContext =
//   { type: SAME_SHIFT_OVERLAP
//   , position: BEFORE | AFTER
//   , field: string
//   } |
//   { type: SAME_DATE_OVERLAP | ALT_DATE_OVERLAP
//   , position: BEFORE | AFTER
//   , time: Moment
//   }
//

// ------------------------- Validation Program -------------------------- //

/**
 * validates time contexts, mapping error contexts onto fields with overlaps.
 *
 * @param  {TimeContext[]} $0 times to validate
 * @param  {TimeContext[]} validated times validated already
 * @return {TimeContext[]} the time contexts with mapped errors
 */
export const validateTimeContexts = ([curr, ...rest], validated = []) => {
  if (!curr) {
    // #1
    return validated
  }

  if (!rest) {
    // #2
    return [...validated, curr]
  }

  const [emptyContexts, [next, ...restOfContexts]] = Core.takeTillValidTime(rest)

  if (!next) {
    // #3
    return [...validated, curr, ...emptyContexts]
  }

  if (
    // #4, #5, #6, #7, #8
    isStartAfterNextTime(curr, next) ||
    isBreakStartAfterBreakEnd(curr, next) ||
    isBreakEndAfterNextBreakStart(curr, next) ||
    isBreakStartAfterNextTime(curr, next) || // ...only matters if next time is on a different shift
    isBreakEndAfterNextTime(curr, next) || // ...only matters if next time is on a different shift
    isEndAfterNextTime(curr, next)
  ) {
    const [currError, nextError] = Core.setErrors([curr, next])

    return validateTimeContexts(restOfContexts, [...validated, currError, ...emptyContexts, nextError])
  }

  return validateTimeContexts(
    // #9
    [next, ...restOfContexts],
    [...validated, curr, ...emptyContexts]
  )
}

// ------------------------ Validation Predicates -------------------------- //

const isStartAfterNextTime = (curr, next) => curr.field === TimeField.START && curr.time.isAfter(next.time)

const isBreakStartAfterBreakEnd = (curr, next) =>
  curr.field === BreakTimeField.START && next.field === BreakTimeField.FINISH && curr.time.isAfter(next.time)

const isBreakEndAfterNextBreakStart = (curr, next) =>
  curr.field === BreakTimeField.FINISH && next.field === BreakTimeField.START && curr.time.isAfter(next.time)

// only matters if next time is on a different shift
const isBreakStartAfterNextTime = (curr, next) =>
  curr.field === BreakTimeField.START && curr.id !== next.id && curr.time.isAfter(next.time)

// only matters if next time is on a different shift
const isBreakEndAfterNextTime = (curr, next) =>
  curr.field === BreakTimeField.FINISH && curr.id !== next.id && curr.time.isAfter(next.time)

const isEndAfterNextTime = (curr, next) => curr.field === TimeField.FINISH && curr.time.isAfter(next.time)

// ------------------------- Validation Utilities -------------------------- //

/**
 * takes a list of time contexts and partitions them until it finds a valid
 * time contexts.
 *
 * @returns {[TimeContext[], TimeContext[]]} [Invalid[], Valid[]]
 */
const takeTillValidTime = ([curr, ...rest], invalid = []) => {
  if (!curr) {
    return [invalid, rest]
  }

  if (curr.time.isValid()) {
    return [invalid, [curr, ...rest]]
  }

  return takeTillValidTime(rest, [...invalid, curr])
}

/**
 * sets the correct error types on time contexts
 *
 * @param {[TimeContext, TimeContext]} $0 a pair of overlapping time contexts
 * @returns {[TimeContext, TimeContext]} a pair of overlapping time contexts
 * with mapped errors
 */
export const setErrors = ([before, after]) => {
  if (before.id === after.id) {
    return Core.setSameShiftErrors([before, after])
  }

  if (before.date === after.date) {
    return Core.setSameDateErrors([before, after])
  }

  return Core.setAltDateErrors([before, after])
}

export const setSameShiftErrors = ([before, after]) => [
  {
    ...before,
    error: {
      type: ErrorType.SAME_SHIFT_OVERLAP,
      position: ErrorPosition.BEFORE,
      field: after.field,
    },
  },
  {
    ...after,
    error: {
      type: ErrorType.SAME_SHIFT_OVERLAP,
      position: ErrorPosition.AFTER,
      field: before.field,
    },
  },
]

export const setSameDateErrors = ([before, after]) => [
  {
    ...before,
    error: {
      type: ErrorType.SAME_DATE_OVERLAP,
      position: ErrorPosition.BEFORE,
      altTime: after.time,
    },
  },
  {
    ...after,
    error: {
      type: ErrorType.SAME_DATE_OVERLAP,
      position: ErrorPosition.AFTER,
      altTime: before.time,
    },
  },
]

export const setAltDateErrors = ([before, after]) => [
  {
    ...before,
    error: {
      type: ErrorType.ALT_DATE_OVERLAP,
      position: ErrorPosition.BEFORE,
      altTime: after.time,
    },
  },
  {
    ...after,
    error: {
      type: ErrorType.ALT_DATE_OVERLAP,
      position: ErrorPosition.AFTER,
      altTime: before.time,
    },
  },
]

/**
 * takes a list of shifts and converts it to a list of time contexts
 * with empty time contexts filtered out
 * @param {Shift[]} shifts to reduce to time contexts
 * @return {TimeContext[]} time contexts of shifts filtered to contexts with times
 */
const shiftsToTimeContexts = (shifts) => {
  const mapper = (shift, field, time) => ({
    date: shift.get("date"),
    error: null,
    field: field,
    id: shift.get("id"),
    time: time,
  })

  return shifts.reduce((ctxs, shift) => {
    const start = mapper(shift, "start", shift.get("start"))
    const breakTimes = shift
      .get(BreaksFieldName)
      .sort(breakSorter)
      .filter((sb) => sb.get("break_type") !== ShiftBreakType["AUTOMATIC_BREAK_RULE"]) // Remove auto breaks as they will reposition
      .flatMap((sb) => TimeFields.map((f) => mapper(shift, `break_${f}`, sb.get(f))))
    const finish = mapper(shift, "finish", shift.get("finish"))

    return ctxs.concat([start]).concat(breakTimes.toArray()).concat([finish])
  }, [])
}

/**
 * For Testing
 */
export const Core = {
  validateTimeContexts,
  takeTillValidTime,
  setErrors,
  setSameShiftErrors,
  setSameDateErrors,
  setAltDateErrors,
  shiftsToTimeContexts,
}

export const validateTimes = compose(validateTimeContexts, shiftsToTimeContexts)
