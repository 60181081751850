/**
 * @flow
 */
import * as React from "react"
import moment from "moment"
import cn from "classnames"
import DatePicker from "./DatePicker"
import TimeInput from "./TimeInput"
import styles from "./styles.module.scss"
import type { Time } from "./types"

type Props = {|
  +allDay?: boolean,
  +date: moment,
  +disabled?: boolean,
  +i18nDateFormat: string,
  +minDate?: moment,
  +onDateChange: (date: moment) => void,
  +onTimeChange: (time: Time) => void,
  +repeatsForever?: boolean,
  +time: Time,
  +timeFormat: 12 | 24,
|}

type State = {|
  focus: boolean,
|}

export default class DateTimePicker extends React.PureComponent<Props, State> {
  static defaultProps: {|
    allDay: boolean,
    disabled: void,
    i18nDateFormat: string,
    minDate: void,
    repeatsForever: void,
  |} = {
    allDay: false,
    i18nDateFormat: "date.formats.long_ordinal",
    disabled: undefined,
    minDate: undefined,
    repeatsForever: undefined,
  }

  state: State = { focus: false }

  handleFocus: () => void = () => {
    this.setState({ focus: true })
  }

  handleBlur: () => void = () => {
    this.setState({ focus: false })
  }

  render(): React.Element<"div"> {
    return (
      <div
        className={cn(
          styles.dateTimePicker,
          this.props.disabled ? styles.disabled : null,
          this.state.focus ? styles.focused : null
        )}
      >
        <DatePicker
          disabled={this.props.disabled || this.props.repeatsForever}
          i18nFormat={this.props.i18nDateFormat}
          minDate={this.props.minDate}
          onBlur={this.handleBlur}
          onChange={this.props.onDateChange}
          onFocus={this.handleFocus}
          value={this.props.date}
        />
        {this.props.allDay ? (
          <div />
        ) : (
          <TimeInput
            disabled={this.props.disabled}
            format={this.props.timeFormat}
            onBlur={this.handleBlur}
            onChange={this.props.onTimeChange}
            onFocus={this.handleFocus}
            value={this.props.time}
          />
        )}
      </div>
    )
  }
}
