// @flow
import * as React from "react"
import * as Store from "platform/store"
import * as State from "platform/models/state"
import * as Model from "platform/models/model"
import * as Assoc from "../../models/association"
import * as Record from "../../models/record"
import FileAssocInput from "./fileAssocInput"
import PickAssocInput from "./pickAssocInput"

type Ev = SyntheticInputEvent<HTMLElement>

type Props = {|
  +assoc: Assoc.Schema,
  +loading: boolean,
  +model: Model.Schema,
  +onEdit: (Ev) => mixed,
  +onSave: () => mixed,
  +onUploadFinish: () => void,
  +onUploadStart: () => void,
  +record: Record.Schema,
  +value: mixed,
|}

export default function AssocInput(props: Props): React.Node {
  const [state] = Store.useContext()

  const subject = State.modelById(state, props.assoc.subjectId)

  if (!subject) {
    return null
  }

  if (Model.isFile(subject)) {
    return (
      <FileAssocInput
        assoc={props.assoc}
        loading={props.loading}
        onEdit={props.onEdit}
        onSave={props.onSave}
        onUploadFinish={props.onUploadFinish}
        onUploadStart={props.onUploadStart}
        record={props.record}
        value={props.value}
      />
    )
  } else {
    return (
      <PickAssocInput
        assoc={props.assoc}
        loading={props.loading}
        model={props.model}
        onEdit={props.onEdit}
        onSave={props.onSave}
        record={props.record}
        subject={subject}
        value={props.value}
      />
    )
  }
}
