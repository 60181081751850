/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import { fromJS, Map } from "immutable"
import { createReducer, createActions } from "redux-em"

const userInit = () => new Map()

const userSet = (state, data) => fromJS(data)

const userMerge = (state, user) => state.merge(user)

export const Core = {
  userInit,
  userSet,
  userMerge,
}

export const userActions = createActions(Core)

export const userReducer = createReducer(Core)
