// @flow
import moment from "moment"
import type { PredictionModifier } from "rosters/overview/models/cognitive/predictionModifiers"

export type CognitiveCreatorConfiguration = {|
  alias_department_ids: Array<number>,
  consolidate_shift_count: boolean,
  created_at: string,
  department_id: number,
  id: number,
  max_concurrent_start: number,
  max_length: ?number,
  maximum_staff: ?number,
  min_length: ?number,
  minimum_staff: number,
  round_down_head_count: boolean,
  time_to_close: number,
  time_to_open: number,
  undercoverage_penalty_ratio: number,
  updated_at: string,
|}

export type Schema = {|
  cognitive_creator_configurations: Array<CognitiveCreatorConfiguration>,
  cross_department_proficiencys: Array<CrossDepartmentProficiency>,
  demand_config: Array<CognitiveDemandConfigType>,
  prediction_modifiers: Array<PredictionModifier>,
|}

export type CrossDepartmentProficiency = {|
  destination_department_id: number,
  effectiveness: number,
  id: number,
  source_department_id: number,
|}

// Stored per location
export type CognitiveDemandConfigType = {|
  available_dates: ?Array<string>,
  daily_schedule_id: number,
  growth_percentage: number,
  growth_percentages_by_ds_id: { [number]: number },
  id: number,
  location_id: number,
  multiple_dates: ?Array<string>,
  uses_fine_tune: boolean,
|}

export type CognitiveDemandConfigMomentizedType = {|
  available_dates?: Array<moment>,
  daily_schedule_id: number,
  growth_percentage: number,
  growth_percentages_by_ds_id: { [number]: number },
  id: number,
  location_id: number,
  multiple_dates: ?Array<moment>,
  uses_fine_tune: boolean,
|}

export type CognitiveDemandConfigEditableFields = {|
  available_dates?: Array<string>,
  growth_percentage?: number,
  growth_percentages_by_ds_id?: { [number]: number },
  multiple_dates?: Array<string>,
  prediction_modifiers?: Array<PredictionModifier>,
  uses_fine_tune?: boolean,
|}
