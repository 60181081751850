// @flow

import { gql } from "@apollo/client"
import * as TandaGQL from "helpers/gql"

type Props = {|
  name: string,
  recordId: number,
|}

const QUERY = gql`
  query shiftAssociationPreview($id: ID!) {
    platformRecord(id: $id) {
      values
      model {
        primaryField {
          key
        }
      }
    }
  }
`

export default function ShiftAssociationPreview({ name, recordId }: Props): string {
  const { data } = TandaGQL.useQuery<TandaGQL.shiftAssociationPreview, TandaGQL.shiftAssociationPreviewVariables>(
    QUERY,
    {
      variables: {
        id: recordId.toString(),
      },
    }
  )
  if (!data || !data.platformRecord) {
    return name
  }

  const primary = data.platformRecord.model.primaryField?.key
  if (!primary) {
    return name
  }

  const value = data.platformRecord.values[primary]

  if (value) {
    return `${name} (${value})`
  } else {
    return name
  }
}
