// @flow
import * as React from "react"
import Loader from "components/Loader"
import testId from "helpers/testId"
import styles from "./styles.module.scss"

type Props = {|
  color: string,
  size: "s" | "m" | "l" | "xl",
|}

export default function Loading(props: Props): React.Element<"div"> {
  return (
    <div className={styles.loading} data-testid={testId("loading-state")}>
      <Loader color={props.color} size={props.size} />
    </div>
  )
}

Loading.defaultProps = { size: "xl", color: "primary" }
