/* @flow */

import _ from "lodash"
import { run as liveInsightsRun } from "./live_insights"
import { run as addNewWidgetRun } from "./add_new_widget"

export type WidgetActionDelegator = {|
  isWidgetVisible: () => boolean,
  showWidget: () => void,
|}

export const defaultWidgetActionDelegator: () => WidgetActionDelegator = () => ({
  isWidgetVisible: () => true,
  showWidget: _.noop,
})

const liveInsightsDelegator = defaultWidgetActionDelegator()
const delegators = { liveInsights: liveInsightsDelegator }

const run = () => {
  liveInsightsRun(liveInsightsDelegator)
  addNewWidgetRun({ delegators })
}

window.SPA = Object.assign({}, window.SPA, { DashboardWidgets: { run } })
