/* @flow */
import * as React from "react"
import _ from "lodash"
import { t as globalT } from "helpers/i18n"
import Select from "components/Select"
import Icon from "components/Icon"
import type {
  DataFiltersType,
  LiveInsightsGraphEntriesType,
  StaffDataTypes,
  DemandDataTypes,
} from "dashboard/live_insights/main"
import LineDisplayContainer from "dashboard/live_insights/components/LineDisplayContainer"
import styles from "./styles.module.scss"

const t = (key: string) => globalT(`js.dashboard.widgets.live_insights.chart_legend.${key}`)

type PropType = {
  dataFilters: DataFiltersType,
  graphEntries: LiveInsightsGraphEntriesType,
  handleDemandDataFilterSelectedChange: (event: { +target: { value: DemandDataTypes } }) => void,
  handleStaffDataFilterSelectedChange: (event: { +target: { value: StaffDataTypes } }) => void,
  handleTogglePredictedLine: () => () => void,
  handleToggleRosterLine: () => () => void,
}

export default class InsightsChartController extends React.PureComponent<PropType> {
  render(): React.Element<"div"> {
    return (
      this.props.dataFilters.filterOptions && (
        <div className={styles.ChartControllerContainer}>
          <div className={styles.LeftHandWrapper}>
            <div className={styles.LeftHandContainer}>
              <div className={styles.SelectContainer}>
                <Select
                  icon={"solidLine"}
                  iconCustomColor={this.props.graphEntries[this.props.dataFilters.staff_data_selected].actual.color}
                  newStyle
                  onChange={this.props.handleStaffDataFilterSelectedChange}
                  options={this.props.dataFilters.filterOptions.staff_data}
                  placeholder={this.props.graphEntries[this.props.dataFilters.staff_data_selected].actual.name}
                  tabIndex={0}
                  value={this.props.dataFilters.staff_data_selected}
                />
              </div>
              <div
                className={styles.RosterLineToggle}
                onClick={this.props.handleToggleRosterLine()}
                onKeyPress={_.noop}
                role="button"
                style={
                  this.props.dataFilters.staff_data_selected === "staff_cost"
                    ? { paddingLeft: "0.065rem" }
                    : { paddingLeft: "0.125rem" }
                }
                tabIndex={-1}
              >
                <div className={styles.RosterLineTextContainer}>
                  <LineDisplayContainer
                    chartType={this.props.graphEntries[this.props.dataFilters.staff_data_selected].rostered.chartType}
                    color={this.props.graphEntries[this.props.dataFilters.staff_data_selected].rostered.color}
                    isSelected={this.props.dataFilters.toggleRosteredLine}
                    opacity={this.props.dataFilters.toggleRosteredLine ? "1" : "0.7"}
                    padding={""}
                    text={t("rostered")}
                  />
                </div>
                {this.props.dataFilters.toggleRosteredLine ? (
                  <Icon color="grey" type={"visibility-off"} />
                ) : (
                  <a>{t("show")}</a>
                )}
              </div>
            </div>
          </div>
          <div className={styles.RightHandWrapper}>
            <div className={styles.RightHandContainer}>
              <div className={styles.SelectContainer}>
                <Select
                  icon={"solidBar"}
                  iconCustomColor={this.props.graphEntries[this.props.dataFilters.demand_data_selected]?.actual.color}
                  newStyle
                  onChange={this.props.handleDemandDataFilterSelectedChange}
                  options={this.props.dataFilters.filterOptions.demand_data}
                  placeholder={
                    this.props.dataFilters.demand_data_selected !== "none"
                      ? this.props.graphEntries[this.props.dataFilters.demand_data_selected].actual.name
                      : "Select Demand"
                  }
                  tabIndex={0}
                  value={this.props.dataFilters.demand_data_selected}
                />
              </div>
              {this.props.dataFilters.demand_data_selected !== "none" && (
                <div
                  className={styles.RosterLineToggle}
                  onClick={this.props.handleTogglePredictedLine()}
                  onKeyPress={_.noop}
                  role="button"
                  style={
                    this.props.dataFilters.demand_data_selected === "staff_cost"
                      ? { paddingLeft: "0.065rem" }
                      : { paddingLeft: "0.125rem" }
                  }
                  tabIndex={-1}
                >
                  <div className={styles.RosterLineTextContainer}>
                    <LineDisplayContainer
                      chartType={
                        this.props.graphEntries[this.props.dataFilters.demand_data_selected].rostered.chartType
                      }
                      color={this.props.graphEntries[this.props.dataFilters.demand_data_selected].rostered.color}
                      isSelected={this.props.dataFilters.togglePredictedLine}
                      opacity={this.props.dataFilters.togglePredictedLine ? "1" : "0.7"}
                      padding={""}
                      text={t("predicted")}
                    />
                  </div>
                  <div className={styles.EyeShowContainer}>
                    {this.props.dataFilters.togglePredictedLine ? (
                      <Icon color="grey" type={"visibility-off"} />
                    ) : (
                      <a>{t("show")}</a>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )
    )
  }
}
