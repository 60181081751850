// @flow
import * as types from "timesheets/views/types"
import type { AllowanceMap } from "../../views/types"

export const canApproveTimesheet = (u: types.User, t: types.Timesheet): boolean =>
  !!(u.get("id") === t.get("user_id") && u.get("approve_own_timesheet")) ||
  !!(u.get("id") !== t.get("user_id") && u.get("approve_others_timesheet"))

export const canEditConfirmedTimes = (u: types.User): boolean => !!u.get("edit_others_timesheet")

export const canSeeCostsOnTimesheet = (t: types.Timesheet): void | number | string | boolean => t.get("permitted_rate")

export const canSeeExportSummary = (
  u: types.User,
  t: types.Timesheet
): void | number | string | boolean | Array<AllowanceMap> | Array<number> | Array<string> => {
  // if current user is timesheet's user, the see_summary_timesheet flag determines what they can see summary
  // it's set in Permission::Defaults::Timesheets, and is setting-backed for some user levels
  if (t.get("user_id") === u.get("id")) {
    return u.get("see_summary_timesheet")
  } else {
    // if the logged in user is not the user being viewed, check if current user has permission to view rates
    return t.get("permitted_rate")
  }
}

export const canUnlockTimesheet = (u: types.User): boolean => !!u.get("export_timesheet")

export const canExportTimesheet = (u: types.User): boolean => !!u.get("export_timesheet")

export const canDestroyTimesheet = (u: types.User): boolean => !!u.get("destroy_timesheet")

export const canCreateLeave = (u: types.User): boolean => !!u.get("create_leave")

export const canReadNote = (u: types.User): boolean => !!u.get("read_note")

export const canEditAwards = (u: types.User): boolean => !!u.get("edit_awards")

export const canEditAllowances = (u: types.User): boolean => !!u.get("edit_allowances")

export const isTandaUser = (u: types.User): boolean => !!u.get("tanda_user")
