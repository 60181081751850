/* @flow */

import * as React from "react"

export default function ButtonGroup(props: { children: React.Node }): React.Element<"div"> {
  if (props.children && React.Children.toArray(props.children).length <= 1) {
    throw new Error("`ButtonGroup` must contain 2 or more `Button` components. You may not need `ButtonGroup`.")
  }

  return <div className="btn-cluster">{props.children}</div>
}
