// @flow
import _ from "lodash"
import * as HelperFunc from "rosters/overview/helpers/functions"
import type { RawJoinData } from "rosters/WebpackRosters/DemandData"
import type { DateData } from "../demandData/types"
import type { Schema as DataStreamJoinRubyType } from "../dataStream/dataStreamJoin"
import type { Schema as HeadCountMapRubyType } from "../dataStream/headCountMap"
import type { BusinessHours as BusinessHoursType } from "../config"
import type { CognitiveCreatorConfiguration } from "./types"

const STAT_INTERVAL = 15

/**
 * WARNING - This logic has been replicated on the backend - see services/cognitive_calculator
 * Intention is to remove all this code soon, to make cognitive projections dependent on the backend
 * If you can, please do not touch this file.
 *
  Calculates head counts based on a head_count mapping and a roster ratio
 */
export const calc_head_counts = (stat: number, head_count_map: Array<number>, rr: ?number): number => {
  const refined_rr = rr || Infinity
  if (stat < 0) {
    return stat / refined_rr
  }
  const indx = head_count_map.findIndex((val) => stat < val)
  if (indx === -1) {
    if (head_count_map.length > 0) {
      const remainder = (stat - _.last(head_count_map)) / refined_rr
      return head_count_map.length + remainder
    } else {
      return stat / refined_rr
    }
  } else {
    const current = head_count_map[indx]
    const prev = head_count_map[indx - 1] || 0
    const remainder = (stat - prev) / (current - prev)
    return indx + remainder
  }
}

export const get_head_count_map_array = (head_count_map: ?string): ?Array<number> => {
  if (head_count_map == null) {
    return null
  } else {
    const str_arr = head_count_map.split(",")
    const num_arr = str_arr.map(Number)
    return num_arr
      .filter((num) => num >= 0 && !Number.isNaN(num))
      .reduce((a, n) => {
        // Trim numbers that are smaller than the previous
        if ((_.last(a) || 0) > n) {
          return a
        } else {
          return a.concat(n)
        }
      }, [])
  }
}

/**
  Align head count maps with projection stat_by_15. If no head count data for a particular
  day part - we use the default (which is 0,0,0). If no head count data for a particular
  data stream join, then we fallback to the value on the data stream join itself (which
  should apply across the whole day)
 */
export const mapHeadCountTo15Increment = (
  visible_date: string,
  stat_by_15: ?{ [minute: string]: number },
  dsj: DataStreamJoinRubyType | RawJoinData,
  head_count_maps_by_dsj_id: { [dsj_id: string]: { [dow: number]: { [by_15_inc: number]: HeadCountMapRubyType } } }
): { [key: string]: Array<number> } => {
  const day_of_week = HelperFunc.dateTimeToDoW(visible_date)
  const next_day_of_week = day_of_week === 6 ? 0 : day_of_week + 1

  // $FlowFixMe - Keys be broke
  const head_count_maps = _.keys(stat_by_15).reduce((acc, key) => {
    const num = Number(key)
    if (head_count_maps_by_dsj_id[String(dsj.id)] == null) {
      // Use head count team wide setting for whole day if no ratios have been found.
      acc[key] = get_head_count_map_array(dsj.head_count_map)
    } else {
      // As stat_by_15 has the possibility of having keys that are greater than a 24 hour period, a single days head_count_map_array
      // is missing the data. Therefore, if we have the key; we will use the current day of weeks head count value, otherwise we will
      // use the following days.
      if (head_count_maps_by_dsj_id[String(dsj.id)][day_of_week][num] != null) {
        acc[key] = get_head_count_map_array(head_count_maps_by_dsj_id[String(dsj.id)][day_of_week][num][0].value)
      } else {
        // Our passed in headCountMapByDsj is limited to a 24 hour period per day; as it is grouped by day and not
        // by 15min increments we have to do the calculation for the next day (to make sure the calculations in the UI
        // line up with what is expected.
        // To calculate we take the last key in the current days mapping (i.e. 1440 and modulo that against the current key).
        // 1590 % 1440 = 150. And we have a key in the next days data for 150.
        const max_interval =
          Number(_.last(_.keys(head_count_maps_by_dsj_id[String(dsj.id)]?.[day_of_week]))) + STAT_INTERVAL
        // All these ?. checks are to avoid rosters breaking if a head count map didn't upload correctly. The result on the UI
        // should be 0,0,0 which should be easy to pick up as to the reason why the calculation is wrong and reupload.
        acc[key] = get_head_count_map_array(
          head_count_maps_by_dsj_id[String(dsj.id)][next_day_of_week]?.[num % max_interval]?.[0]?.value
        )
      }
    }
    return acc
  }, {})
  return head_count_maps
}

// We are no longer referring to head_count_map as a single field on the data stream join. This is
// now a separate model called HeadCountMap. Currently, we have a mapping of HeadCounts by DSJ which
// allows us right now, to have access to the array of head count maps by index.
export const projectionStaffCount = (
  prediction: { [data_stream_id: string]: { [stat_type: string]: DateData } },
  config: CognitiveCreatorConfiguration,
  business_hours: ?Array<BusinessHoursType>,
  data_stream_joins: Array<DataStreamJoinRubyType>,
  head_count_maps_by_dsj_id: { [dsj_id: string]: { [dow: number]: { [by_15_inc: number]: HeadCountMapRubyType } } },
  all_visible_dates_str: Array<string>
): { [minute: string]: number } => {
  if (data_stream_joins.length === 0) {
    // This team doesnt use cognitive, ignore...
    return {}
  }
  const required_by_15_per_dsj: Array<{ [minute: string]: number }> = data_stream_joins.map((dsj) => {
    const proj_ds: ?DateData = (prediction[String(dsj.data_stream_id)] || {})[dsj.stat_types]
    if (proj_ds == null) {
      return {}
    } else {
      const rr = Number(dsj.rostering_ratio)
      const normalized_rr = rr <= 0 ? Infinity : rr

      let head_count_maps = {}
      // If we don't have any HeadCountMaps (Day Part Ratios) for this Data stream join; then we don't even bother assigning
      // the 15min increments, as they aren't using economis of scale.
      const uses_economies_of_scale = head_count_maps_by_dsj_id[String(dsj.id)] != null || dsj.head_count_map != null

      if (uses_economies_of_scale) {
        head_count_maps = mapHeadCountTo15Increment(
          all_visible_dates_str[0],
          proj_ds.stat_by_15,
          dsj,
          head_count_maps_by_dsj_id
        )
      }

      if (uses_economies_of_scale) {
        return _.mapValues(proj_ds.stat_by_15, (val, key) =>
          calc_head_counts(val, head_count_maps[key] || [], normalized_rr)
        )
      } else {
        // if you aren't using economies of scale, this is where the calculation happens
        // proj_ds.stat_by_15 is the predicted units for the data stream for each point in the day
        // `val / normalized_rr` gives the projected staffing requirement for each point in the day
        return _.mapValues(proj_ds.stat_by_15, (val) => val / normalized_rr)
      }
    }
  })
  const min_to_open = (config.time_to_open || 0) * 60
  const min_to_close = (config.time_to_close || 0) * 60

  // If business_hours_normal is set to null; we disregard business hours in the UI and still calculate ratios
  // for the entire viewable hours.
  const business_hours_normal: ?Array<BusinessHoursType> =
    business_hours != null ? HelperFunc.include_open_and_close_in_bh(business_hours, min_to_open, min_to_close) : null
  const blank_required_by_15: { [minute: string]: number } = _.range(0, 48 * 60, 15).reduce((a, m) => {
    a[m] = 0
    return a
  }, {})
  // required_by_15_per_dsj gave us the staffing requirements from each input. you could have multiple inputs,
  // eg. number of pizzas to make + number of salads to make = number of cooks.
  // so here we add them together to get a final staffing reqiurement + fill in any empty points in the day with zeroes (blank_required_by_15).
  // after this everything is still a float, eg. requirements can be 1.53243423
  const summed_required_by_15: { [minute: string]: number } = required_by_15_per_dsj.reduce(
    (a, o) => HelperFunc.mergeAndAddStats(a, o),
    blank_required_by_15
  )

  // here we remove any staffing requirements outside businss hours if they are configured
  // then we round the head count up or down as required (set in Cognitive Creator Settings > Advanced)
  // finally we apply the minimum and maximum staff settings (Cognitive Creator Settings > Staff)
  // the result is an integer for each point in the day.
  const configed_required_by_15: { [minute: string]: number } = _.mapValues(summed_required_by_15, (val, min) => {
    const inside_hours =
      business_hours_normal == null ? true : HelperFunc.time_inside_bh(business_hours_normal, Number(min))
    if (!inside_hours) {
      return 0
    }
    const rounded = config.round_down_head_count ? Math.floor(val) : Math.ceil(val)
    const capped =
      config.minimum_staff && rounded < config.minimum_staff
        ? config.minimum_staff
        : config.maximum_staff && rounded > config.maximum_staff
        ? config.maximum_staff
        : rounded
    return capped
  })
  // We crop the recommended staff counts just in case the projected amounts over/underflowed.
  const cropped_keys: Array<string> = _.range(0, 48 * 60, 15).map(String)
  const cropped_by_15: { [minute: string]: number } = cropped_keys.reduce((a, m) => {
    a[m] = configed_required_by_15[m] || 0
    return a
  }, {})
  return cropped_by_15
}

export default projectionStaffCount
