// @flow
import _ from "lodash"

export type TeamRubyType = {|
  +colour: ?string,
  +department_group_id: ?number,
  +department_group_name: ?string,
  +id: number,
  +location_id: number,
  +name: string,
  +short_name: string,
  +sort_order: ?number,
|}

export type Schema = TeamRubyType

export const sort = (teams: Array<TeamRubyType>): Array<TeamRubyType> =>
  _.sortBy(
    _.sortBy(teams, (t) => t.name),
    (t) => t.sort_order
  )

export const getFullTeamName = (team: Schema): string =>
  team.department_group_name ? team.department_group_name + " · " + team.name : team.name

export default getFullTeamName
