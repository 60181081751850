/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"

import { t } from "helpers/i18n"
import * as Shift from "timesheets/models/shift"
import * as TimeFormatter from "helpers/time_formatter"
import styles from "./styles.module.scss"

/*
  many US states require the ability to print timesheets, including all clocked times
*/
export default class PrintOnlyBreakTimes extends React.PureComponent {
  isAutoBreak(shiftBreak) {
    return shiftBreak.get("break_type") === Shift.ShiftBreakType.AUTOMATIC_BREAK_RULE
  }

  renderTime = (shiftBreak, field) => {
    if (this.isAutoBreak(shiftBreak)) {
      return t("js.timesheets.shift_card.time_form.break_time_input.placeholder.auto_break")
    }

    const val = shiftBreak.get(field)
    if (val && val.isValid()) {
      return TimeFormatter.shiftTimeInput(val, this.props.currentUser)
    }

    return t(`js.timesheets.shift_card.time_form.break_time_input.placeholder.${field}`)
  }

  render() {
    return (
      <div className={styles.printedBreakTimes}>
        {this.props.shift.get(Shift.BreaksFieldName).map((sb, index) => (
          <div className={styles.breakTimes} key={sb.get("guid")}>
            <div className={styles.time}>{this.renderTime(sb, "start")}</div>
            <div className={styles.time}>{this.renderTime(sb, "finish")}</div>
          </div>
        ))}
      </div>
    )
  }
}

PrintOnlyBreakTimes.propTypes = {
  currentUser: PropTypes.object.isRequired,
  shift: PropTypes.object.isRequired,
}
