// @flow
import moment from "moment"
import * as Redux from "redux"
import type { $AxiosError } from "axios"
import * as CustomEvent from "rosters/overview/models/customEvent/types"
import * as Comments from "rosters/overview/models/comment/types"
import * as DataStream from "rosters/overview/models/dataStream"
import * as DataStreamJoin from "rosters/overview/models/dataStream/dataStreamJoin"
import * as HeadCountMap from "rosters/overview/models/dataStream/headCountMap"
import * as Validation from "rosters/overview/models/validation"
import * as Schedule from "rosters/overview/models/schedule/types"
import * as ScheduleBreak from "rosters/overview/models/scheduleBreak"
import * as PublishedSchedule from "rosters/overview/models/publishedSchedule/types"
import * as Shift from "rosters/overview/models/shift/types"
import * as Leave from "rosters/overview/models/timeOff/leave"
import * as Unavailability from "rosters/overview/models/timeOff/unavailability"
import * as Config from "rosters/overview/models/config"
import { type AllDataStreamData as DD_AllDataStreamData } from "rosters/WebpackRosters/DemandData"
import * as State from "rosters/overview/models"
import * as AIC from "rosters/overview/models/aic/types"
import * as Focus from "rosters/overview/models/focus"
import * as RosterPattern from "rosters/overview/models/rosterPatterns/types"
import * as Loading from "rosters/overview/models/loading"
import * as DemandData from "rosters/overview/models/demandData/types"
import * as Settings from "rosters/overview/models/settings"
import * as Location from "rosters/overview/models/static/location"
import * as User from "rosters/overview/models/static/user/types"
import * as UserQualifications from "rosters/overview/models/static/userQualifications"
import * as DepartmentQualifications from "rosters/overview/models/static/departmentQualifications"
import * as Qualifications from "rosters/overview/models/static/qualifications"
import * as UserTrainingLog from "rosters/overview/models/static/trainingLogs"
import * as Team from "rosters/overview/models/static/team"
import * as TeamGroup from "rosters/overview/models/static/team_group"
import * as Template from "rosters/overview/models/template"
import * as TemplateUserJoin from "rosters/overview/models/templateUserJoins"
import * as ViewOptions from "rosters/overview/models/viewOptions"
import * as ShiftDetail from "rosters/overview/models/static/shiftDetail"
import * as StatTypeClass from "rosters/overview/models/statType"
import * as RDO from "rosters/overview/models/rdo/types"
import * as Cache from "rosters/overview/models/cache"
import * as Rosters from "rosters/overview/models/rosters"
import * as Modal from "rosters/overview/models/modal"
import * as Cognitive from "rosters/overview/models/cognitive/types"
import * as History from "rosters/overview/models/history"
import * as SalesTarget from "rosters/overview/models/salesTarget"
import * as Weather from "rosters/overview/models/weather"
import type { Defaults, LeaveInfo as LeaveInfoExternal } from "time_off/Modal/types"
import * as ScheduleSwapPlan from "rosters/overview/models/scheduleSwapPlan"
import * as OncostConfiguration from "rosters/overview/models/oncostConfiguration"
import * as PredictionModifier from "rosters/overview/models/cognitive/predictionModifiers"
import * as EmploymentConditionSet from "rosters/overview/models/employmentConditionSet"
import type { Position, PositionGroup } from "positions/types"

export type LeaveRequestDefaults = Defaults
export type LeaveInfo = LeaveInfoExternal

export type WeatherData = Weather.WeatherData
export type WeatherType = Weather.Schema
export type RawWeatherData = Weather.RawWeatherData
export type SalesTargetType = SalesTarget.Schema

export type ActionCommand = History.ActionCommand
export type HistoryType = History.Schema

export type StatType = StatTypeClass.Schema

export type Panels = ViewOptions.Panels

export type PublicHolidayType = Config.PublicHolidayType
export type OrganisationType = Config.OrganisationType
export type RosterTemplatePermissions = Config.RosterTemplatePermissions

export type KeyStatTypes = ViewOptions.KeyStatTypes
export type KeyStats = ViewOptions.KeyStats
export type DayViewGraphModeType = ViewOptions.DayViewGraphModeType
export type TemplateRubyType = Template.Schema
export type TemplateUserJoinRubyType = TemplateUserJoin.Schema
export type TemplateEditableParams = Template.TemplateEditableParams
export type TemplateRequestResponseType = Template.TemplateRequestResponseType

export type EmploymentConditionSetRubyType = EmploymentConditionSet.Schema
export type EmploymentConditionSetsRawDataResponseType = EmploymentConditionSet.RawDataResponse
export type EmploymentConditionSetsDataResponseType = EmploymentConditionSet.DataResponse

export type DemandDataCacheKey = Cache.DemandDataCacheKey
export type DemandDataCache = Cache.DemandDataCache
export type ScheduleDataUserCache = Cache.ScheduleDataUserCache
export type ScheduleDataTeamCache = Cache.ScheduleDataTeamCache
export type LocationDataCache = Cache.LocationDataCache

export type APIError = $AxiosError<*>
export type ErrorType = {|
  /* any information about the error that will be useful later
   * this *must* be serializable
   */
  // eslint-disable-next-line flowtype/no-weak-types
  additionalInformation: { +[string]: any },
  message: string,
  title: string,
|}

export type RawStatData = DemandData.RawStatData
export type DateData = DemandData.DateData
export type PredictionResponse = DemandData.PredictionResponse

export type GroupInfoType = {|
  color?: ?string,
  icon?: ?string,
  id: string,
  name: string,
  sort_order: ?number,
|}

export type RowType = {|
  groupId: string,
  id: string,
  type: "groupHeader" | "user" | "createShiftRow" | "groupingHeader",
|}

export type CognitiveDemandConfigType = Cognitive.CognitiveDemandConfigType

export type PredictionModifierType = PredictionModifier.Schema

export type CrossDepartmentProficiency = Cognitive.CrossDepartmentProficiency
export type CognitiveDemandConfigMomentizedType = Cognitive.CognitiveDemandConfigMomentizedType
export type CustomEventRubyType = CustomEvent.Schema

export type CommentRubyType = Comments.Schema

export type CommentEditableParamsType = Comments.EditableParams

export type ScheduleType = Schedule.Schema
export type SimpleScheduleType = Schedule.SimpleScheduleType
export type ScheduleRubyType = Schedule.RubyType

export type ScheduleBreakType = ScheduleBreak.ScheduleBreakType

export type RuleSetType = {|
  applicable_hours_buffer: ?number,
  employee_ids: Array<number>,
  is_default: boolean,
  priority: number,
  roster_with_times: boolean,
  rules: Array<RuleType>,
|}

export type RuleType = {|
  applicable_before_surpassed_hours: ?number,
  break_length: number,
  paid: boolean,
  paid_meal_break: boolean,
  shift_length: number,
|}

export type ScheduleValidationWithExpectedAndActual = {
  actual: number,
  expected: number,
  type: string,
}

export type ScheduleValidationWithBreakLengthAndPaidStatus = {
  missing_break_lengths: Array<number>,
  type: string,
}

export type ScheduleValidationWithBreakStartErrorsType = {
  error: { break_length: number, paid: boolean, paid_meal_break: boolean, start: moment },
  type: string,
}

export type GetExpectedBreaksStartTimesType = {
  break_length: number,
  paid: boolean,
  paid_meal_break: boolean,
  start: moment,
}

export type PublishedScheduleType = PublishedSchedule.Schema
export type PublishedScheduleRubyType = PublishedSchedule.RubyType

export type ShiftType = Shift.Schema
export type ScheduleStats = {|
  avg_cost_per_rostered_hour: number,
  sales_per_labor_hour: number,
  sales_per_labor_hour_vs_budget: number,
  total_hours: number,
  total_hrs_incl_leave: number,
  total_hrs_incl_leave_and_unpaid: number,
  total_sales: number,
  total_schedule_costs: number,
  total_schedule_costs_with_oncosts: number,
  total_schedule_overtime_costs: number,
  total_schedules: number,
  vacant_schedules_count: number,
  wage_percentage_of_revenue: number,
  wage_percentage_of_revenue_vs_budget: number,
  wage_percentage_of_revenue_with_oncosts: number,
|}

export type StatFormatFunc = (value: number | string, stat_type: string, round: ?boolean) => string

export type RDORubyType = RDO.Schema

export type RosterPatternScheduleType = RosterPattern.RosterPatternScheduleType
export type EditableRosterPatternScheduleParamsType = RosterPattern.EditableRosterPatternScheduleParamsType

export type RosterPatternRDOType = RosterPattern.RosterPatternRDOType

export type idToSchedule = {
  [schedule_id: string]: ScheduleType,
}

export type RosterPatternType = RosterPattern.RosterPatternType

export type RosterPatternUserJoinType = RosterPattern.RosterPatternUserJoinType

export type ScheduleOptionsType =
  | "breaks"
  | "costs"
  | "delete"
  | "duplicate"
  | "copy"
  | "create_leave"
  | "request_replacement"
  | "rhw_validation"
  | "publish"

export type ErrorTypes = "cannot_work_team"

export type ValidationSeverityType = Validation.ValidationSeverityType

export type PrintingOptionsType = ViewOptions.PrintingOptionsType

export type ValidationErrorType = Validation.ValidationErrorType

export type ScheduleValidationType = Validation.ScheduleValidationType

export type RosterValidationType = Validation.RosterValidationType

export type ScheduleEditableParamsType = Schedule.ScheduleEditableParamsType

export type ScheduleEditableParamsRubyType = Schedule.ScheduleEditableParamsRubyType

export type RDOCreateParamsType = RDO.RDOCreateParamsType
export type RDOEditableParamsType = RDO.RDOEditableParamsType

export type GroupType = ViewOptions.GroupType
export type SortScheduleOptions = ViewOptions.SortScheduleOptions

export type TeamRubyType = Team.Schema
export type TeamGroupType = TeamGroup.TeamGroup
export type TeamGroupRubyType = TeamGroup.TeamGroupRuby

export type PositionType = Position
export type PositionGroupType = PositionGroup

export type ShiftSlotType = Config.ShiftSlot
export type ShiftDetailRubyType = ShiftDetail.Schema

export type AICRubyType = AIC.Schema

export type BasicAICDataType = AIC.BasicData

export type DataStreamJoinRubyType = DataStreamJoin.Schema

export type HeadCountMapRubyType = HeadCountMap.Schema

export type DataStreamRubyType = DataStream.Schema
export type LocationRubyType = Location.Schema

export type PublishParamsType = {|
  auction_to_secondary_departments: boolean,
  auction_vacant_schedules: boolean,
  daily_schedule_ids: Array<number>,
  finish_date: string,
  location_ids: Array<number>,
  note: ?string,
  schedule_ids_for_shift_claiming: Array<number>,
  schedule_ids_to_publish: Array<number>,
  schedules_to_publish: Array<ScheduleType>,
  send_email: boolean,
  send_sms: boolean,
  shift_acceptance: boolean,
  start_date: string,
  team_ids: Array<number>,
  user_ids: Array<number>,
|}

export type PublishResponseType = {|
  data: {
    deleted_published_schedule_ids: Array<number>,
    offered_schedules: Array<number>,
    published_schedules: Array<PublishedScheduleRubyType>,
    user_daily_schedule_joins: Array<UserDailyScheduleJoinRubyType>,
  },
|}

export type ScheduleReloadResponseType = {|
  error_message: string,
  schedules: Array<ScheduleType>,
|}

export type ScheduleBulkCreateOptionsType = {|
  fill_vacant_shifts?: boolean,
  finish_date?: string,
  prefer_rhw?: boolean,
  set_all_shifts_vacant?: boolean,
  set_vacant_if_validation_error?: boolean,
  start_date?: string,
|}

export type RosterPatternParamsTypes = RosterPattern.RosterPatternParamsTypes

export type BulkRosterPatternsSaveResponse = RosterPattern.BulkRosterPatternsSaveResponse

export type UserType = User.Schema
export type UserRubyType = User.RubyType
export type UserShallowRubyType = User.ShallowRubyType

export type DateRange = UserQualifications.DateRange

export type UserQualificationRubyType = UserQualifications.Schema

export type DepartmentQualificationRubyType = DepartmentQualifications.Schema

export type QualificationRubyType = Qualifications.Schema

export type UserTrainingLogRubyType = UserTrainingLog.Schema

export type RosterPatternResponseParamsTypes = RosterPattern.RosterPatternResponseParamsTypes

export type LeaveRequestRubyType = Leave.Schema
export type LeaveStatus = Leave.Status

export type UnavailabilityRubyType = Unavailability.Schema

export type RosterRubyType = Rosters.RosterRubyType

export type DailyScheduleType = Rosters.DailyScheduleType

export type UserDailyScheduleJoinType = Rosters.UserDailyScheduleJoinType

export type UserDailyScheduleJoinRubyType = Rosters.UserDailyScheduleJoinRubyType

export type ScheduleSwapPlanRubyType = ScheduleSwapPlan.Schema

// Used to keep track of which employees can work in which departments
export type UserToDepartmentMap = Config.UserToDepartmentMap

export type RawScheduleDataResponse = {|
  daily_schedules: Array<DailyScheduleType>,
  leave_requests: Array<LeaveRequestRubyType>,
  published_schedules: Array<PublishedScheduleRubyType>,
  rdos: Array<RDORubyType>,
  rosters: Array<RosterRubyType>,
  rule_sets: [],
  schedule_swap_plans: Array<ScheduleSwapPlanRubyType>,
  schedules: Array<ScheduleRubyType>,
  shifts: Array<ShiftType>,
  unavailability: Array<UnavailabilityRubyType>,
  user_daily_schedule_joins: Array<UserDailyScheduleJoinType>,
|}

export type ScheduleDataResponse = {|
  daily_schedules: Array<DailyScheduleType>,
  finish_date: moment,
  leave_requests: Array<LeaveRequestRubyType>,
  published_schedules: Array<PublishedScheduleType>,
  rdos: Array<RDORubyType>,
  rosters: Array<RosterRubyType>,
  schedule_swap_plans: Array<ScheduleSwapPlanRubyType>,
  schedules: Array<ScheduleType>,
  shifts: Array<ShiftType>,
  start_date: moment,
  unavailability: Array<UnavailabilityRubyType>,
  user_daily_schedule_joins: Array<UserDailyScheduleJoinType>,
  user_ids: Array<number>,
|}

export type LeaveRequestDataResponse = Array<LeaveRequestRubyType>

export type RawTemplatesDataResponse = {|
  daily_schedules: Array<DailyScheduleType>,
  rdos: Array<RDORubyType>,
  rosters: Array<RosterRubyType>,
  schedules: Array<ScheduleRubyType>,
|}

export type TemplatesDataResponse = {|
  daily_schedules: Array<DailyScheduleType>,
  rdos: Array<RDORubyType>,
  rosters: Array<RosterRubyType>,
  schedules: Array<ScheduleType>,
  templates: Array<TemplateRubyType>,
|}

export type RawShiftDataResponse = {|
  shifts: Array<ShiftType>,
|}

export type ShiftDataResponse = {|
  finish_date: moment,
  shifts: Array<ShiftType>,
  start_date: moment,
  team_ids: Array<number>,
  user_ids: Array<number>,
|}

export type RawLocationDataResponse = {|
  comments: Array<Comments.Schema>,
  custom_events: Array<CustomEvent.Schema>,
  demand_config: Array<CognitiveDemandConfigType>,
  prediction_modifiers: Array<PredictionModifierType>,
  public_holidays: PublicHolidayType,
  sales_targets: Array<SalesTargetType>,
|}

export type LocationDataResponse = {|
  comments: Array<Comments.Schema>,
  custom_events: Array<CustomEvent.Schema>,
  demand_config: Array<CognitiveDemandConfigType>,
  location_ids: Array<number>,
  prediction_modifiers: Array<PredictionModifierType>,
  public_holidays: PublicHolidayType,
  sales_targets: Array<SalesTargetType>,
|}

export type RawUserDataResponse = {|
  users: Array<UserRubyType>,
|}

export type UserDataResponse = {|
  users: Array<UserType>,
|}

export type ScheduleRequestResponseType = Schedule.ScheduleRequestResponseType

export type ScheduleBulkRequestResponseType = Schedule.ScheduleBulkRequestResponseType
export type ScheduleBulkCreateResponseType = Schedule.ScheduleBulkCreateResponseType

export type RDORequestResponseType = {
  error_message: string,
  rdo: RDORubyType,
  success: boolean,
}

export type RDOBulkRequestResponseType = {
  error_message: string,
  rdos: Array<RDORubyType>,
  success: boolean,
}

export type CommentRequestResponseType = {
  comment: CommentRubyType,
  success: boolean,
}

export type UpdateLeaveRequestStatusResponseType = {|
  data?: LeaveInfo,
  status: number,
  statusText: string,
|}

export type OncostConfigurationType = OncostConfiguration.Schema

export type UserCustomValidationMap = Config.UserCustomValidationMap

export type DefaultValidationSettings = Config.DefaultValidationSettings
export type DefaultValidationFieldSettings = Config.DefaultValidationFieldSettings
export type CustomValidationSettings = Config.CustomValidationSettings
export type DefaultValidationObject = Config.DefaultValidationSettingObject
export type CustomValidationObject = Config.CustomValidationSettingObject

export type RosterViewType = ViewOptions.RosterViewType
export type DayViewRosterViewType = ViewOptions.DayViewRosterViewType

export type ReportingViewType = ViewOptions.ReportingViewType

export type AllDataStreamData = DD_AllDataStreamData

export type SelectedScheduleContainerDataType = Focus.SelectedContainerData

export type SelectedPersonDataType = Focus.SelectedPersonDataType

export type SelectOption = {|
  label: string,
  value: number,
|}

export type BusinessHoursType = Config.BusinessHours
export type SpanOfHoursType = Config.SpanOfHours
export type CognitiveCreatorConfigurationType = Cognitive.CognitiveCreatorConfiguration

export type FocusType = Focus.Schema

export type RequestTypes = Loading.RequestTypes

export type RequestsInProgressType = Loading.RequestsInProgressType

export type RubyStateType = State.RubyStateType

export type TransformedRubyStateType = State.TransformedRubyStateType

export type RosterValidationErrorsType = Validation.RosterValidationErrorsType
export type PayPeriodValidationErrorsType = Validation.PayPeriodValidationErrorsType
export type ScheduleValidationErrorsType = Validation.ScheduleValidationErrorsType
export type ViewType = Settings.ViewType

export type ModalType = Modal.Schema

export type ViewOptionsType = {
  group_teams: boolean,
  hide_people_with_no_shifts: boolean,
  highlight_validation_errors: boolean,
  only_show_time_off: boolean,
  show_staff_ratings: boolean,
}

export type LoadingType = Loading.Schema

export type SettingsType = Settings.Schema

export type ValidationsType = Validation.Schema

export type TimeOffType = {
  leave_requests: Array<LeaveRequestRubyType>,
  unavailability: Array<UnavailabilityRubyType>,
}

export type ExtractReturn<Fn> = $Call<<T>((...Iterable<any>) => T) => T, Fn> // eslint-disable-line flowtype/no-weak-types

export type StateMapProps<M> = $ReadOnly<{|
  ...ExtractReturn<M>,
|}>

export type VisibleHoursType = Settings.VisibleHoursType

export type AwardRubyType = Config.AwardRuby

export type AwardType = Config.Award

export type BasicAwardDataType = Config.BasicAwardData

export type AllowanceRubyType = Config.AllowanceRuby

export type AllowanceType = Config.Allowance

export type AICResponseType = {|
  aics: Array<AICRubyType>,
  schedules_still_in_queue: Array<number>,
|}

export type AwardsAndAllowancesDataRubyType = {|
  allowances: Array<AllowanceRubyType>,
  awards: Array<AwardRubyType>,
|}

export type AwardsAndAllowancesDataType = {|
  allowances: Array<AllowanceType>,
  awards: Array<AwardType>,
|}

export type ScheduleDataRequest = {|
  finish_date: string,
  request: Promise<ScheduleDataResponse | void>,
  start_date: string,
  team_ids: Array<number>,
  template_id: string,
  user_ids: Array<number>,
|}

export type SMSDetails = {|
  sms_pricing_subtitle: string,
  sms_sent_warning: string,
|}

export type SortStaffOptions = ViewOptions.SortStaffOptions

export type EarningsThresholdPeriodType = {|
  finish_date: string,
  start_date: string,
  threshold: number,
  threshold_for_juniors: number,
|}

export type ActionType =
  | {|
      payload: AwardsAndAllowancesDataType,
      type: "@loaders/AWARDS_AND_ALLOWANCES_DATA_LOAD",
    |}
  | {|
      payload: {|
        display_message: {|
          text: string,
        |},
      |},
      type: "SHOW_SNACKBAR_MESSAGE",
    |}
  | {|
      payload: {
        sales_target: SalesTargetType,
      },
      type: "@salesTarget/SALES_TARGET_UPDATE",
    |}
  | {|
      payload: Array<WeatherType>,
      type: "@loaders/LOAD_WEATHER_DATA",
    |}
  | {|
      payload: {
        dates: Array<string>,
        location_id: number,
        req: Promise<mixed>,
      },
      type: "@loaders/START_LOAD_WEATHER_DATA",
    |}
  | {|
      payload: TransformedRubyStateType,
      type: "@loaders/LOAD_INITIAL_DATA",
    |}
  | {|
      payload: { group_ids: Array<string> },
      type: "SET_EXPANDED_GROUPS",
    |}
  | {|
      payload: { date: ?string },
      type: "OPEN_COPY_SCHEDULES_MODAL",
    |}
  | {|
      payload: {},
      type: "OPEN_PREDICT_MODAL",
    |}
  | {|
      payload: { teams: Array<[number, TeamRubyType]> },
      type: "@misc/UPDATE_TEAM_SORT_ORDERS",
    |}
  | {|
      payload: { team_groups: Array<[number, TeamGroupType]> },
      type: "@misc/UPDATE_TEAM_GROUP_SORT_ORDERS",
    |}
  | {|
      payload: { new_order: { [user_id: string]: number } },
      type: "@misc/UPDATE_USER_SORT_ORDERS",
    |}
  | {|
      payload: { new_order: Array<UserType> },
      type: "@misc/UPDATE_LOCAL_USER_SORT_ORDER",
    |}
  | {|
      type: "@misc/DELETE_LOCAL_USER_SORT_ORDER",
    |}
  | {|
      payload: { user_id: number },
      type: "POSTPEND_USER",
    |}
  | {|
      payload: { new_stats: KeyStats },
      type: "KEY_STATS_CHANGE",
    |}
  | {|
      payload: { view: ReportingViewType },
      type: "SET_ACTIVE_REPORTING_VIEW",
    |}
  | {|
      payload: {},
      type: "UNDO",
    |}
  | {|
      payload: {},
      type: "REDO",
    |}
  | {|
      payload: {
        action: ActionCommand,
      },
      type: "ADD_UNDOABLE_ACTION",
    |}
  | {|
      payload: {
        action: ActionCommand,
      },
      type: "REMOVE_UNDOABLE_ACTION",
    |}
  | {|
      payload: {},
      type: "COPY_SCHEDULES_MODAL_EXIT",
    |}
  | {|
      payload: {},
      type: "PREDICT_MODAL_EXIT",
    |}
  | {|
      payload: {},
      type: "HIGHLIGHT_UNPUBLISHED_SHIFTS",
    |}
  | {|
      payload: {},
      type: "UNHIGHLIGHT_UNPUBLISHED_SHIFTS",
    |}
  | {|
      payload: {},
      type: "@cognitive/CHANGE_DEMAND_DATA_SINGLETON_REF",
    |}
  | {|
      payload: {},
      type: "@cognitive/BEGIN_BUILDING_ALL_TEAMS",
    |}
  | {|
      payload: {},
      type: "@cognitive/FINISH_BUILDING_ALL_TEAMS",
    |}
  | {|
      payload: {
        cache_keys: Array<DemandDataCacheKey>,
        demand_data: Array<DateData>,
      },
      type: "@demand_data/LOADED_DEMAND_DATA_FOR_RANGE",
    |}
  | {|
      payload: {},
      type: "@cognitive/RESET_BUILDING_ALL_TEAMS",
    |}
  | {|
      payload: {},
      type: "@cognitive/FAILED_BUILDING_ALL_TEAMS",
    |}
  | {|
      payload: { team_id: number },
      type: "@cognitive/BEGIN_BUILDING_SINGLE_TEAM",
    |}
  | {|
      payload: { team_id: number },
      type: "@cognitive/FINISH_BUILDING_SINGLE_TEAM",
    |}
  | {|
      payload: { day_view_graph_mode: DayViewGraphModeType },
      type: "CHANGE_DAY_VIEW_GRAPH_MODE",
    |}
  | {|
      payload: {
        data_stream_join: DataStreamJoinRubyType,
      },
      type: "@cognitive/DATA_STREAM_JOIN_EDIT",
    |}
  | {|
      payload: {|
        custom_event: CustomEventRubyType,
      |},
      type: "@custom_events/EDIT",
    |}
  | {|
      payload: {|
        comment: ?CommentRubyType,
      |},
      type: "OPEN_COMMENTS_MODAL",
    |}
  | {|
      payload: {|
        custom_event: ?CustomEventRubyType,
      |},
      type: "OPEN_CUSTOM_EVENT_MODAL",
    |}
  | {|
      payload: {||},
      type: "OPEN_CUSTOM_EVENT_IMPORT_MODAL",
    |}
  | {|
      payload: {|
        template_id: ?number,
      |},
      type: "OPEN_TEMPLATE_MODAL",
    |}
  | {|
      payload: {||},
      type: "EXIT_TEMPLATE_MODAL",
    |}
  | {|
      payload: {|
        comment: CommentRubyType,
      |},
      type: "@comment/COMMENT_MODAL_SAVE",
    |}
  | {|
      payload: {|
        comment: CommentRubyType,
      |},
      type: "@comment/COMMENT_MODAL_DELETE",
    |}
  | {|
      payload: {|
        custom_event: CustomEventRubyType,
      |},
      type: "@customEvent/CUSTOM_EVENT_MODAL_SAVE",
    |}
  | {|
      payload: {|
        custom_event: CustomEventRubyType,
      |},
      type: "@customEvent/CUSTOM_EVENT_MODAL_DELETE",
    |}
  | {|
      payload: {||},
      type: "EXIT_COMMENT_MODAL",
    |}
  | {|
      payload: {||},
      type: "EXIT_CUSTOM_EVENT_MODAL",
    |}
  | {|
      payload: {||},
      type: "EXIT_CUSTOM_EVENT_IMPORT_MODAL",
    |}
  | {|
      payload: {|
        business_hours: Array<BusinessHoursType>,
      |},
      type: "@config/BUSINESS_HOURS_EDIT_FOR_LOCATION",
    |}
  | {|
      payload: {|
        demand_configs: Array<CognitiveDemandConfigType>,
      |},
      type: "@cognitive/SOFT_SAVE_COGNITIVE_DEMAND_CONFIGS",
    |}
  | {|
      payload: {|
        cognitive_creator_configuration: CognitiveCreatorConfigurationType,
      |},
      type: "@cognitive/COGNITIVE_CREATOR_CONFIGURATION_EDIT",
    |}
  | {|
      payload: {|
        custom_event: CustomEventRubyType,
      |},
      type: "@custom_events/CREATE",
    |}
  | {|
      payload: {|
        custom_event: CustomEventRubyType,
      |},
      type: "@custom_events/DELETE",
    |}
  | {|
      payload: {|
        key_stat: KeyStatTypes,
      |},
      type: "PERSON_KEY_STAT_CHANGE",
    |}
  | {|
      payload: {|
        key_stat: KeyStatTypes,
      |},
      type: "DAY_KEY_STAT_CHANGE",
    |}
  | {|
      payload: {|
        key_stat: KeyStatTypes,
      |},
      type: "DAY_KEY_STAT_CHANGE_TWO",
    |}
  | {|
      payload: {|
        key_stat: KeyStatTypes,
      |},
      type: "DAY_KEY_STAT_CHANGE_THREE",
    |}
  | {|
      payload: {|
        demand_config: CognitiveDemandConfigType,
      |},
      type: "@cognitive/COGNITIVE_DEMAND_CONFIG_EDIT",
    |}
  | {|
      payload: {|
        data: Array<DateData>,
        data_stream_ids: Array<number>,
        dates: Array<string>,
        predicted: boolean,
        req: Promise<mixed>,
      |},
      type: "@load/DEMAND_DATA_FINISH",
    |}
  | {|
      payload: {|
        data: Array<DateData>,
        predicted: boolean,
      |},
      type: "@demandData/SAVE_NEW_DEMAND_DATA",
    |}
  | {|
      payload: {|
        data_stream_ids: Array<number>,
        dates: Array<string>,
        predicted: boolean,
        req: Promise<mixed>,
      |},
      type: "@load/DEMAND_DATA_START",
    |}
  | {|
      payload: {|
        data_stream_ids: Array<number>,
        dates: Array<string>,
        predicted: boolean,
      |},
      type: "@load/BUST_CACHE_FOR_DATA",
    |}
  | {|
      payload: {|
        prev_schedule_sort: SortScheduleOptions,
        schedule_sort: SortScheduleOptions,
        schedule_sort_lock: boolean,
      |},
      type: "SET_SCHEDULE_SORT",
    |}
  | {|
      payload: {|
        schedules: Array<ScheduleType>,
      |},
      type: "@schedule/SCHEDULE_BULK_EDIT_START",
    |}
  | {|
      payload: {|
        req: Promise<mixed>,
      |},
      type: "@aic/BEGIN_GET_AICS",
    |}
  | {|
      payload: {|
        schedule: ScheduleType,
      |},
      type: "@schedule/SCHEDULE_EDIT_START",
    |}
  | {|
      payload: {||},
      type: "BLUR",
    |}
  | {|
      payload: {||},
      type: "BULK_EDIT_MODAL_EXIT",
    |}
  | {|
      payload: {||},
      type: "AUTO_ASSIGN_MODAL_EXIT",
    |}
  | {|
      payload: {|
        display_message: {|
          text: string,
        |},
      |},
      type: "@rosterPatterns/BULK_SAVE_ROSTER_PATTERNS_FAILED",
    |}
  | {|
      payload: {||},
      type: "@rosterPatterns/BULK_SAVE_ROSTER_PATTERNS_START",
    |}
  | {|
      payload: RosterPatternParamsTypes,
      type: "@rosterPatterns/BULK_SAVE_ROSTER_PATTERNS_SUCCESS",
    |}
  | {|
      payload: {|
        changes?: ScheduleEditableParamsType,
        schedule_id: number,
      |},
      type: "COPY_SCHEDULE",
    |}
  | {|
      payload: {|
        patterns: Array<RosterPatternType>,
      |},
      type: "CREATE_PATTERN_SHIFTS",
    |}
  | {|
      payload: {|
        date_obj: {
          endDate: moment,
          startDate: moment,
        },
      |},
      type: "DATES_CHANGE",
    |}
  | {|
      payload: {|
        visible_hours: VisibleHoursType,
      |},
      type: "@settings/UPDATE_VISIBLE_HOURS",
    |}
  | {|
      payload: {|
        finish_date: moment,
        new_range: number | "monthly",
        start_date: moment,
      |},
      type: "DAY_RANGE_CHANGE",
    |}
  | {|
      payload: {|
        rdos: Array<RDORubyType>,
        schedules: Array<ScheduleType>,
      |},
      type: "DELETE_MULTIPLE_SHIFTS",
    |}
  | {|
      payload: {||},
      type: "@schedule/DELETE_MULTIPLE_SHIFTS_START",
    |}
  | {|
      payload: {||},
      type: "@schedule/DELETE_MULTIPLE_SHIFTS_SUCCESS",
    |}
  | {|
      payload: {|
        schedule: ScheduleType,
      |},
      type: "@schedule/SCHEDULE_DELETE_START",
    |}
  | {|
      payload: {|
        schedule: ScheduleType,
        schedules_to_recost: Array<number>,
      |},
      type: "@schedule/SCHEDULE_DELETE_SUCCESS",
    |}
  | {|
      payload: {||},
      type: "DELETE_VISIBLE_SHIFTS",
    |}
  | {|
      payload: {||},
      type: "@schedule/DELETE_VISIBLE_SHIFTS_START",
    |}
  | {|
      payload: {||},
      type: "@schedule/DELETE_VISIBLE_SHIFTS_SUCCESS",
    |}
  | {|
      payload: {|
        schedule: ScheduleType,
      |},
      type: "@schedule/SCHEDULE_EDIT_FAILED",
    |}
  | {|
      payload: {
        err: ErrorType,
        finish_date: moment,
        start_date: moment,
        user_ids: Array<number>,
      },
      type: "@load/SCHEDULE_DATA_LOAD_FAILED",
    |}
  | {|
      payload: {
        err: ErrorType,
        finish_date: moment,
        start_date: moment,
        user_ids: Array<number>,
      },
      type: "@load/TEMPLATES_DATA_LOAD_FAILED",
    |}
  | {|
      payload: {
        err: ErrorType,
        finish_date: moment,
        start_date: moment,
        user_ids: Array<number>,
      },
      type: "@load/SHIFT_DATA_LOAD_FAILED",
    |}
  | {|
      payload: {
        err: ErrorType,
        finish_date: moment,
        location_ids: Array<number>,
        start_date: moment,
      },
      type: "@load/LOCATION_DATA_LOAD_FAILED",
    |}
  | {|
      payload: {
        err: ErrorType,
        user_ids: Array<number>,
      },
      type: "@load/USER_DATA_LOAD_FAILED",
    |}
  | {|
      payload: {|
        req: Promise<mixed>,
        schedule_ids: Array<number>,
        updated_aics: Array<AICRubyType>,
      |},
      type: "@aic/GET_AICS",
    |}
  | {|
      payload: {|
        req: Promise<mixed>,
        schedule_ids: Array<number>,
      |},
      type: "@aic/FAILED_TO_GET_AICS",
    |}
  | {|
      payload: {||},
      type: "HIGHLIGHT_ERRORS",
    |}
  | {|
      payload: {||},
      type: "STOP_HIGHLIGHT_ERRORS",
    |}
  | {|
      payload: {||},
      type: "HIGHLIGHT_OVERTIME",
    |}
  | {|
      payload: {||},
      type: "STOP_HIGHLIGHT_OVERTIME",
    |}
  | {|
      payload: {||},
      type: "HIGHLIGHT_PENDING_ACCEPTANCE",
    |}
  | {|
      payload: {||},
      type: "STOP_HIGHLIGHT_PENDING_ACCEPTANCE",
    |}
  | {|
      payload: {||},
      type: "HIGHLIGHT_VACANT_SHIFTS",
    |}
  | {|
      payload: {||},
      type: "STOP_HIGHLIGHT_VACANT_SHIFTS",
    |}
  | {|
      payload: {||},
      type: "STOP_HIGHLIGHTING",
    |}
  | {|
      payload: ScheduleDataResponse,
      type: "@load/SCHEDULE_DATA_LOAD_SUCCESS",
    |}
  | {|
      payload: LeaveRequestDataResponse,
      type: "@load/LEAVE_REQUEST_DATA_LOAD_SUCCESS",
    |}
  | {|
      payload: {
        data: TemplatesDataResponse,
        new_team_cache: ScheduleDataTeamCache,
        new_user_cache: ScheduleDataUserCache,
      },
      type: "@load/TEMPLATES_DATA_LOAD_SUCCESS",
    |}
  | {|
      payload: ShiftDataResponse,
      type: "@load/SHIFT_DATA_LOAD_SUCCESS",
    |}
  | {|
      payload: LocationDataResponse,
      type: "@load/LOCATION_DATA_LOAD_SUCCESS",
    |}
  | {|
      payload: { [ds_id: string]: Array<PredictionModifierType> },
      type: "@prediction/SOFT_SAVE_PREDICTION_MODIFIERS",
    |}
  | {|
      payload: UserDataResponse,
      type: "@load/USER_DATA_LOAD_SUCCESS",
    |}
  | {|
      payload: {|
        request: Promise<mixed>,
        user_ids: Array<number>,
      |},
      type: "@load/USER_DATA_LOAD_START",
    |}
  | {|
      payload: {||},
      type: "@load/EMPLOYMENT_CONDITION_SETS_DATA_LOAD_START",
    |}
  | {|
      payload: { data: EmploymentConditionSetsRawDataResponseType },
      type: "@load/EMPLOYMENT_CONDITION_SETS_DATA_LOAD_SUCCESS",
    |}
  | {|
      payload: {
        err: ErrorType,
      },
      type: "@load/EMPLOYMENT_CONDITION_SETS_DATA_LOAD_FAILED",
    |}
  | {|
      payload: {|
        new_award_tags: Array<string>,
        new_location_ids: Array<number>,
        new_shift_details: Array<number>,
        new_staff_types: Array<string>,
        new_team_ids: Array<number>,
      |},
      type: "FILTERS_CHANGE",
    |}
  | {|
      payload: {|
        schedules: Array<ScheduleType>,
      |},
      type: "OPEN_BULK_EDIT_MODAL",
    |}
  | {|
      payload: {|
        schedules: Array<ScheduleType>,
      |},
      type: "OPEN_AUTO_ASSIGN_MODAL",
    |}
  | {|
      payload: {},
      type: "AUTO_BUILD_SUCCESS_MODAL_EXIT",
    |}
  | {|
      payload: {
        show_modal: boolean,
      },
      type: "AUTO_BUILD_SUCCESS_MODAL_OPEN",
    |}
  | {|
      payload: {|
        user_ids?: Array<number>,
      |},
      type: "OPEN_PUBLISH_MODAL",
    |}
  | {|
      payload: {||},
      type: "OPEN_ROSTER_PATTERNS_BUILD_MODAL",
    |}
  | {|
      payload: {||},
      type: "OPEN_ROSTER_PATTERNS_MODAL",
    |}
  | {|
      payload: {},
      type: "PUBLISH_MODAL_EXIT",
    |}
  | {|
      payload: {},
      type: "RECOST_MODAL_EXIT",
    |}
  | {|
      payload: {},
      type: "RECOST_MODAL_OPEN",
    |}
  | {|
      payload: {|
        schedule_ids: Array<number>,
      |},
      type: "@aics/QUEUE_COST_UPDATE_FOR_SCHEDULES",
    |}
  | {|
      payload: {|
        rdo: RDORubyType,
      |},
      type: "@rdo/RDO_UPDATE_START",
    |}
  | {|
      payload: {|
        rdo: RDORubyType,
      |},
      type: "@rdo/RDO_UPDATE_FAILED",
    |}
  | {|
      payload: {|
        rdo: RDORubyType,
      |},
      type: "@rdo/RDO_UPDATE_SUCCESS",
    |}
  | {|
      payload: {|
        rdos: Array<RDORubyType>,
      |},
      type: "@rdo/RDO_BULK_DELETE_FAILED",
    |}
  | {|
      payload: {|
        rdos: Array<RDORubyType>,
      |},
      type: "@rdo/RDO_BULK_DELETE_START",
    |}
  | {|
      payload: {|
        rdos: Array<RDORubyType>,
      |},
      type: "@rdo/RDO_BULK_DELETE_SUCCESS",
    |}
  | {|
      payload: {|
        display_message: {|
          text: string,
        |},
      |},
      type: "@rdo/RDO_CHANGE_ERROR",
    |}
  | {|
      payload: {|
        rdo: RDORubyType,
      |},
      type: "@rdo/RDO_CREATE_FAILED",
    |}
  | {|
      payload: {|
        rdo: RDORubyType,
      |},
      type: "@rdo/RDO_CREATE_START",
    |}
  | {|
      payload: {|
        mocked_rdo: RDORubyType,
        rdo: RDORubyType,
      |},
      type: "@rdo/RDO_CREATE_SUCCESS",
    |}
  | {|
      payload: {|
        rdo: RDORubyType,
      |},
      type: "@rdo/RDO_DELETE_FAILED",
    |}
  | {|
      payload: {|
        rdo: RDORubyType,
      |},
      type: "@rdo/RDO_DELETE_START",
    |}
  | {|
      payload: {|
        rdo: RDORubyType,
      |},
      type: "@rdo/RDO_DELETE_SUCCESS",
    |}
  | {|
      payload: {|
        promise: Promise<mixed>,
      |},
      type: "@load/REQUEST_RECIEVED",
    |}
  | {|
      payload: {},
      type: "ROSTER_PATTERNS_BUILD_MODAL_EXIT",
    |}
  | {|
      payload: {},
      type: "ROSTER_PATTERNS_MODAL_EXIT",
    |}
  | {|
      payload: {|
        schedules: Array<ScheduleType>,
      |},
      type: "@schedule/SCHEDULE_BULK_CREATE_FAILED",
    |}
  | {|
      payload: {|
        schedules: Array<ScheduleType>,
      |},
      type: "@schedule/SCHEDULE_BULK_CREATE_START",
    |}
  | {|
      payload: {|
        leave_requests: Array<LeaveRequestRubyType>,
        mocked_schedules: Array<ScheduleType>,
        rdos: Array<RDORubyType>,
        schedules: Array<ScheduleType>,
      |},
      type: "@schedule/SCHEDULE_BULK_CREATE_SUCCESS",
    |}
  | {|
      payload: {|
        schedules: Array<ScheduleType>,
      |},
      type: "@schedule/SCHEDULE_BULK_DELETE_FAILED",
    |}
  | {|
      payload: {|
        schedules: Array<ScheduleType>,
      |},
      type: "@schedule/SCHEDULE_BULK_DELETE_START",
    |}
  | {|
      payload: {|
        schedules: Array<ScheduleType>,
      |},
      type: "@schedule/SCHEDULE_BULK_DELETE_SUCCESS",
    |}
  | {|
      payload: {|
        schedule: ScheduleType,
      |},
      type: "@schedule/SCHEDULE_CREATE_START",
    |}
  | {|
      payload: {|
        schedule: ScheduleType,
      |},
      type: "@schedule/SCHEDULE_CREATE_FAILED",
    |}
  | {|
      payload: {|
        mocked_schedule: ScheduleType,
        schedule: ScheduleType,
      |},
      type: "@schedule/SCHEDULE_CREATE_SUCCESS",
    |}
  | {|
      payload: {|
        display_message: {|
          text: string,
        |},
      |},
      type: "@schedule/SCHEDULE_CHANGE_ERROR",
    |}
  | {|
      payload: {|
        date: string,
      |},
      type: "SELECTED_DATE_CHANGE",
    |}
  | {|
      payload: {|
        fixed_cost_daily_salary: number,
        group_id: string,
        has_fixed_cost_salary_allocation: boolean,
        user_id: number,
      |},
      type: "SELECTED_PERSON_CHANGE",
    |}
  | {|
      payload: {|
        date: string,
        group_id: string,
        schedule_id?: number,
        user_id: number,
      |},
      type: "SELECTED_SCHEDULE_CONTAINER_CHANGE",
    |}
  | {|
      payload: {|
        promise: Promise<mixed>,
        type: RequestTypes,
      |},
      type: "@load/SENDING_NEW_REQUEST",
    |}
  | {|
      payload: ScheduleDataRequest,
      type: "@load/SCHEDULE_DATA_LOAD_START",
    |}
  | {|
      payload: {|
        finish_date: string,
        request: Promise<mixed>,
        start_date: string,
        team_ids: Array<number>,
        user_ids: Array<number>,
      |},
      type: "@load/SHIFT_DATA_LOAD_START",
    |}
  | {|
      payload: {|
        finish_date: string,
        location_ids: Array<number>,
        request: Promise<mixed>,
        start_date: string,
      |},
      type: "@load/LOCATION_DATA_LOAD_START",
    |}
  | {|
      payload: {|
        schedule: ScheduleType,
      |},
      type: "@schedule/SCHEDULE_EDIT_SUCCESS",
    |}
  | {|
      payload: {|
        group: GroupType,
      |},
      type: "SET_GROUP",
    |}
  | {|
      payload: {|
        previous_finish_date: moment,
        previous_start_date: moment,
        template_id: ?number,
      |},
      type: "SET_TEMPLATE",
    |}
  | {|
      payload: {|
        template_id: ?number,
      |},
      type: "STAGE_TEMPLATE",
    |}
  | {|
      payload: {|
        template_id: ?number,
      |},
      type: "EXIT_TEMPLATE_MODE",
    |}
  | {|
      payload: {|
        template: TemplateRubyType,
      |},
      type: "@template/TEMPLATE_DELETE",
    |}
  | {|
      payload: {|
        template: TemplateRubyType,
      |},
      type: "@template/TEMPLATE_CREATE",
    |}
  | {|
      payload: {|
        template: TemplateRubyType,
      |},
      type: "@template/TEMPLATE_EDIT_START",
    |}
  | {|
      payload: {
        roster_view: RosterViewType,
      },
      type: "CHANGE_ROSTER_VIEW",
    |}
  | {|
      payload: {
        roster_view: DayViewRosterViewType,
      },
      type: "CHANGE_DAY_VIEW_ROSTER_VIEW",
    |}
  | {|
      payload: {||},
      type: "TOGGLE_HIDE_USERS_WITH_NO_SHIFTS",
    |}
  | {|
      payload: {||},
      type: "TOGGLE_SHOW_TEAMS_ON_SMALL_CARDS",
    |}
  | {|
      payload: {||},
      type: "TOGGLE_ONLY_SHOW_TIME_OFF",
    |}
  | {|
      payload: {||},
      type: "TOGGLE_SHOW_STAFF_RATINGS",
    |}
  | {|
      payload: {||},
      type: "TOGGLE_COLLAPSE_MULTIPLE_CARDS",
    |}
  | {|
      payload: {|
        schedule: ScheduleType,
      |},
      type: "@schedule/SCHEDULE_DELETE_FAILED",
    |}
  | {|
      payload: {||},
      type: "STOP_HIGHLIGHTING",
    |}
  | {|
      payload: {|
        pay_period_validation_errors: PayPeriodValidationErrorsType,
        roster_validation_errors: RosterValidationErrorsType,
        schedule_validation_errors: ScheduleValidationErrorsType,
      |},
      type: "UPDATE_VALIDATION_ERRORS",
    |}
  | {|
      payload: { view: ViewType },
      type: "VIEW_CHANGE",
    |}
  | {|
      payload: boolean,
      type: "SET_SIDEBAR_COLLAPSE",
    |}
  | {|
      payload: {||},
      type: "@rosterPatterns/BUILD_SCHEDULES_START",
    |}
  | {|
      payload: {||},
      type: "@rosterPatterns/BUILD_SCHEDULES_SUCCESS",
    |}
  | {|
      payload: {|
        roster: RosterRubyType,
      |},
      type: "@roster/UPDATE_ROSTER",
    |}
  | {|
      payload: { staged: LeaveRequestDefaults },
      type: "OPEN_LEAVE_MODAL",
    |}
  | {|
      payload: {},
      type: "EXIT_LEAVE_MODAL",
    |}
  | {|
      payload: { schedule: ScheduleType },
      type: "OPEN_REPLACEMENT_MODAL",
    |}
  | {|
      payload: {},
      type: "EXIT_REPLACEMENT_MODAL",
    |}
  | {|
      payload: {
        deleted_published_schedule_ids: Array<number>,
        offered_schedules: Array<number>,
        published_schedules: Array<PublishedScheduleType>,
        user_daily_schedule_joins: Array<UserDailyScheduleJoinRubyType>,
      },
      type: "@schedule/PUBLISH",
    |}
  | {|
      payload: {
        error_message: string,
        schedules: Array<ScheduleType>,
      },
      type: "@schedule/RELOAD",
    |}
  | {|
      payload: {
        value: boolean,
      },
      type: "SET_PRINT_MODE",
    |}
  | {|
      payload: $Shape<PrintingOptionsType>,
      type: "SET_PRINTING_OPTIONS",
    |}
  | {|
      payload: Panels,
      type: "ACTIVE_PANEL_CHANGE",
    |}
  | {|
      payload: SortStaffOptions,
      type: "SET_SORT_STAFF",
    |}
  | {|
      payload: boolean,
      type: "SET_EDITING_STAFF_SORT",
    |}

export type GetState = () => GlobalState
export type GlobalState = State.GlobalState
export type GlobalReducer = Redux.Reducer<GlobalState, ActionType>
export type GlobalStore = Redux.Store<GlobalState, ActionType>
type Thunkable<A> = (dispatch: GlobalDispatch, state: GetState) => ?Promise<A>
type Thunk<A> = (Thunkable<A>) => any // eslint-disable-line flowtype/no-weak-types
export type Dispatchable = ActionType | Thunkable<mixed>
export type GlobalDispatch = Redux.Dispatch<ActionType> & Thunk<mixed>
