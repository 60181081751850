// @flow

import "react-dates/initialize"
import _ from "lodash"
import moment from "moment"
import * as React from "react"
import cn from "classnames"
import { DayPickerRangeController } from "react-dates"
import Icon from "components/Icon"
import Text from "components/Text"
import styles from "./styles.module.scss"

/**
 * rest of api here
 * https://github.com/airbnb/react-dates#daterangepicker
 */
type Props = {
  dayRange: number,
  disabled: boolean,
  +endDate: ?moment,
  forceDayRange: boolean,
  isMonthly: boolean,
  isOutsideRange?: (day: moment) => boolean,
  numberOfMonths?: number,
  onClearDates?: () => void,
  onDatesChange: (new_dates: { endDate: moment, startDate: moment }) => void,
  // eslint-disable-next-line flowtype/no-weak-types
  renderClearDatesLabel?: React.Element<any>,
  showClearDates?: boolean,
  showWeekends: boolean,
  +startDate: ?moment,
  startDayOffset?: number,
}

type State = {
  endDate: ?moment,
  focused_input: "startDate" | "endDate",
  isFocused: boolean,
  node: mixed,
  open: boolean,
  startDate: ?moment,
}

export default class TandaWeekRangePicker extends React.PureComponent<Props, State> {
  static defaultProps: {|
    dayRange: number,
    disabled: boolean,
    disableStartDaySnap: boolean,
    forceDayRange: boolean,
    isCustom: boolean,
    isMonthly: boolean,
    isOutsideRange: void,
    numberOfMonths: number,
    onClearDates: void,
    renderClearDatesLabel: void,
    showClearDates: boolean,
    showWeekends: boolean,
    startDayOffset: number,
  |} = {
    disabled: false,
    forceDayRange: false,
    isCustom: false,
    isMonthly: false,
    startDayOffset: 0, // Default to weeks starting sunday
    dayRange: 7, // Default to 1 week
    disableStartDaySnap: false,
    numberOfMonths: 1,
    onClearDates: undefined,
    renderClearDatesLabel: undefined,
    isOutsideRange: undefined,
    showClearDates: false,
    showWeekends: true,
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      isFocused: false,
      open: false,
      startDate: null,
      endDate: null,
      focused_input: "startDate",
      node: null,
    }
  }

  componentDidMount: () => void = () => {
    document.addEventListener("click", this.handleOutsideClick)
  }

  componentWillUnmount: () => void = () => {
    document.addEventListener("click", this.handleOutsideClick)
  }

  setWrapperRef: () => void = (node) => {
    this.setState({ node: node })
  }

  handleOutsideClick: (e: Event) => void = (e: Event) => {
    // $FlowFixMe
    if (this.state.open && this.state.node && !this.state.node.contains(e.target)) {
      this.setState({ open: false })
    }
  }

  /*
   * todo: the range will always start on the first day of week `date` belongs
   * to. it should line up with timesheet periods instead. for weekly
   * timesheets that's the same thing, but for fortnightly it isn't.
   */
  togglePicker: () => void = () => {
    if (this.state.open && this.state.startDate != null && this.state.endDate != null) {
      this.props.onDatesChange({ endDate: this.state.endDate, startDate: this.state.startDate })
      this.setState({ startDate: null, endDate: null, focused_input: "startDate" })
    }
    if (!this.state.open) {
      this.setState({ startDate: this.props.startDate, endDate: this.props.endDate, focused_input: "startDate" })
    }
    this.setState({ open: !this.state.open })
  }

  handlePreviousButtonClick: () => void = () => {
    const startDate = this.props.startDate
    const endDate = this.props.endDate
    if (startDate == null || endDate == null) {
      return
    }

    const dateRange = endDate.diff(startDate, "day") + 1
    if (!this.props.showWeekends && dateRange === 1 && startDate.isoWeekday() === 1) {
      // We are on monday going back one day to previous friday
      const previousFriday = startDate.clone().subtract(3, "days")
      return this.props.onDatesChange({ startDate: previousFriday, endDate: previousFriday.clone() })
    }
    const newStart = this.props.isMonthly
      ? startDate.clone().subtract(1, "month").startOf("month")
      : startDate.clone().subtract(dateRange, "days")
    const newEnd = this.props.isMonthly ? newStart.clone().endOf("month") : endDate.clone().subtract(dateRange, "days")
    this.props.onDatesChange({ startDate: newStart, endDate: newEnd })
  }

  handleNextButtonClick: () => void = () => {
    const startDate = this.props.startDate
    const endDate = this.props.endDate
    if (startDate == null || endDate == null) {
      return
    }

    const dateRange = endDate.diff(startDate, "day") + 1
    if (!this.props.showWeekends && dateRange === 1 && startDate.isoWeekday() === 5) {
      // We are on friday going forward one day to next monday
      const nextMonday = startDate.clone().add(3, "days")
      return this.props.onDatesChange({ startDate: nextMonday, endDate: nextMonday.clone() })
    }
    const newStart = this.props.isMonthly
      ? startDate.clone().add(1, "month").startOf("month")
      : startDate.clone().add(dateRange, "days")
    const newEnd = this.props.isMonthly ? newStart.clone().endOf("month") : endDate.clone().add(dateRange, "days")
    this.props.onDatesChange({ startDate: newStart, endDate: newEnd })
  }

  handleDatesChange: (date_obj: { endDate: ?moment$Moment, startDate: ?moment$Moment, ... }) => void = (date_obj: {
    endDate: ?moment,
    startDate: ?moment,
  }) => {
    const startDate = date_obj.startDate
    const endDate = date_obj.endDate
    if (endDate && this.state.focused_input === "endDate") {
      this.setState({ focused_input: "startDate", startDate, endDate }, this.togglePicker)
    } else if (this.props.forceDayRange && startDate) {
      this.setState(
        { focused_input: "endDate", startDate, endDate: startDate.clone().add(this.props.dayRange - 1, "days") },
        this.togglePicker
      )
    } else {
      this.setState({ focused_input: "endDate", startDate, endDate: null })
    }
  }

  handleClearDates: () => void = () => {
    this.setState({ startDate: null, endDate: null })
    this.props.onClearDates && this.props.onClearDates()
  }

  renderSelectedDates: (startDate: moment, endDate: moment) => React.Node = (startDate: moment, endDate: moment) => {
    const just_show_start_date = startDate.isSame(endDate, "day")
    return (
      <React.Fragment>
        {just_show_start_date && (
          <Text
            bold
            color={this.state.open && this.state.focused_input === "startDate" ? "primary" : "light"}
            padding="0 0 1px 0"
            type="small"
          >
            {startDate.format("ddd Do MMM")}
          </Text>
        )}
        {!just_show_start_date && (
          <React.Fragment>
            <Text
              bold
              color={this.state.open && this.state.focused_input === "startDate" ? "primary" : "light"}
              type="small"
            >
              {startDate.format("D MMM")}
            </Text>
            <Text bold color="light" padding="0 0.25rem" type="small">
              {"-"}
            </Text>
            <Text
              bold
              color={this.state.open && this.state.focused_input === "endDate" ? "primary" : "light"}
              type="small"
            >
              {endDate.format("D MMM")}
            </Text>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  render(): React.Element<"div"> {
    const startDate = this.state.startDate || this.props.startDate
    const endDate = this.state.endDate || this.props.endDate
    const isCleared = this.props.showClearDates && (startDate == null || endDate == null)
    return (
      <div className={cn(styles.container)} ref={this.setWrapperRef}>
        <div
          className={cn(styles.nextPreviousButton, styles.previous, {
            [styles.disabled]: this.props.disabled || isCleared,
          })}
          onClick={this.handlePreviousButtonClick}
          onKeyUp={_.noop}
          role="button"
          tabIndex={0}
        >
          <Icon type={"chevron-left"} />
        </div>
        <div
          className={cn(styles.dateButton, { [styles.disabled]: this.props.disabled })}
          onClick={this.togglePicker}
          onKeyUp={_.noop}
          ref={this.setWrapperRef}
          role="button"
          tabIndex={0}
        >
          {/* $FlowFixMe - Flow can't infer that startDate and endDate won't be null */}
          {isCleared ? this.props.renderClearDatesLabel : this.renderSelectedDates(startDate, endDate)}
        </div>
        <div
          className={cn(styles.nextPreviousButton, styles.next, {
            [styles.disabled]: this.props.disabled || isCleared,
          })}
          onClick={this.handleNextButtonClick}
          onKeyUp={_.noop}
          role="button"
          tabIndex={0}
        >
          <Icon type={"chevron-right"} />
        </div>
        {this.props.showClearDates && (
          <div
            className={cn(styles.nextPreviousButton, styles.next, { [styles.disabled]: this.props.disabled })}
            onClick={this.handleClearDates}
            onKeyUp={_.noop}
            role="button"
            tabIndex={0}
          >
            <Icon type={"clear"} />
          </div>
        )}
        {this.state.open && (
          <div className={styles.pickerWrapper}>
            <DayPickerRangeController
              disabled={this.props.disabled}
              endDate={this.state.endDate}
              firstDayOfWeek={this.props.startDayOffset}
              focusedInput={this.state.focused_input}
              hideKeyboardShortcutsPanel
              isOutsideRange={this.props.isOutsideRange}
              minimumNights={0}
              numberOfMonths={this.props.numberOfMonths}
              onDatesChange={this.handleDatesChange}
              onFocusChange={_.noop}
              startDate={this.state.startDate}
            />
          </div>
        )}
      </div>
    )
  }
}
