// @flow
import * as React from "react"
import { noop } from "lodash"
import cn from "classnames"
import Icon from "components/Icon"

import type { Option, SelectEvent } from "components/Select/index"
import { handleOnClick, handleMouseEnter, handleMouseLeave } from "../helpers/handlers"

import styles from "./styles.module.scss"

export type SimpleOptionType = $ReadOnly<{
  ...Option,
  data?: {},
  icon?: string,
}>

export type OptionType = {
  ...SimpleOptionType,
  options?: Array<SimpleOptionType>,
}

export type RequiredProps = {|
  label: string,
|}

export type Props = {|
  ...RequiredProps,
  // eslint-disable-next-line react/no-unused-prop-types
  onClick: (e: SelectEvent) => void,
  // eslint-disable-next-line react/no-unused-prop-types
  onMouseEnter: (e: SelectEvent) => void,
  // eslint-disable-next-line react/no-unused-prop-types
  onMouseLeave: (e: SelectEvent) => void,
  // eslint-disable-next-line react/no-unused-prop-types, flowtype/sort-keys, flowtype/no-weak-types
  data?: ?{ [prop: string]: any },
  hovered: boolean,
  icon: ?string,
  iconCustomColor: ?string,
  newStyle: boolean,
  noOverflow: boolean,
  selected: boolean,
  // eslint-disable-next-line react/no-unused-prop-types
  selectedTop: ?boolean,
  size: "s" | "m" | "l",
  updateRef: () => mixed,
|}

type State = {|
  isMouseOver: boolean,
|}

export const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  hovered: false,
  onClick: noop,
  newStyle: false,
  iconCustomColor: null,
  onMouseEnter: noop,
  size: "m",
  onMouseLeave: noop,
  icon: null,
  data: null,
  selectedTop: null,
  noOverflow: false,
  updateRef: noop,
  selected: false,
}

// eslint-disable-next-line flowtype/no-weak-types
export type OptionNodeType = React.ComponentType<any>

export default class DefaultOption extends React.Component<Props, State> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  constructor(props: Props) {
    super(props)
    this.state = { isMouseOver: false }
  }
  handleMouseEnter: (e: SyntheticInputEvent<HTMLElement>) => void = handleMouseEnter.bind(this)
  handleMouseLeave: (e: SyntheticInputEvent<HTMLElement>) => void = handleMouseLeave.bind(this)
  handleOnClick: (event: SyntheticEvent<HTMLDivElement>) => void = handleOnClick.bind(this)

  render(): React.Element<"div"> {
    return (
      <div
        className={cn(styles.Option, styles[this.props.size], {
          [styles.noOverflow]: this.props.noOverflow,
          [styles.hovered]: this.props.hovered,
        })}
        onClick={this.handleOnClick}
        onKeyPress={noop}
        onMouseLeave={this.handleMouseLeave}
        onMouseMove={this.props.hovered ? noop : this.handleMouseEnter}
        ref={this.props.updateRef}
        role="button"
        tabIndex={-1}
      >
        <div className={cn(styles.optionLabel, { [styles.newStyle]: this.props.newStyle })}>
          {this.props.icon && (
            <Icon
              color={this.props.hovered ? "white" : "grey"}
              customColor={this.props.iconCustomColor}
              padding="0 0.25rem 0 0"
              size="s"
              type={this.props.icon}
            />
          )}
          {this.props.label}
          {this.props.selected && <Icon color="primary" padding="0 0 0 0.25rem" size="s" type={"check"} />}
        </div>
      </div>
    )
  }
}
