/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import "react-dates/initialize"
import _ from "underscore" // eslint-disable-line underscore-to-lodash/prefer-import-lodash
import * as React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { DayPickerRangeController } from "react-dates"
import Icon from "components/Icon"
import styles from "./styles.module.scss"

export default class TandaWeekRangePicker extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { isFocused: false, open: false }
  }

  // day of the week a given date falls on, adjusted for startDayOffset.
  // e.g. if startDayOffset is 1 (Monday) then adjusted(Sunday) === 6.
  //      if startDayOffset is 1 (Monday) then adjusted(Wednesday) === 2.
  //      if startDayOffset is 3 (Wednesday) then adjusted(Wednesday) === 0.
  //      if startDayOffset is 3 (Wednesday) then adjusted(Monday) === 5.
  //      if startDayOffset is 0 (Sunday) then adjusted(Monday) === 1.
  adjustedDayOfWeek = (date) =>
    date.day() >= this.props.startDayOffset
      ? date.day() - this.props.startDayOffset
      : date.day() - this.props.startDayOffset + 7

  /*
   * todo: the range will always start on the first day of week `date` belongs
   * to. it should line up with timesheet periods instead. for weekly
   * timesheets that's the same thing, but for fortnightly it isn't.
   */
  rangeStartDate = (date) =>
    this.props.isMonthly
      ? date.clone().startOf("month")
      : this.props.disableStartDaySnap
      ? date
      : date.clone().subtract(this.adjustedDayOfWeek(date), "days")

  rangeEndDate = (date) =>
    this.props.isMonthly
      ? this.rangeStartDate(date).endOf("month")
      : this.rangeStartDate(date).add(this.props.dayRange - 1, "days")

  togglePicker = () => {
    this.setState({ open: !this.state.open })
  }

  handlePreviousButtonClick = () => {
    const newStart = this.props.isMonthly
      ? this.props.startDate.clone().subtract(1, "month").startOf("month")
      : this.props.startDate.clone().subtract(this.props.dayRange, "days")
    const newEnd = this.props.isMonthly
      ? newStart.clone().endOf("month")
      : this.props.endDate.clone().subtract(this.props.dayRange, "days")
    this.props.onDatesChange({ startDate: newStart, endDate: newEnd })
  }

  handleNextButtonClick = () => {
    const newStart = this.props.isMonthly
      ? this.props.startDate.clone().add(1, "month").startOf("month")
      : this.props.startDate.clone().add(this.props.dayRange, "days")
    const newEnd = this.props.isMonthly
      ? newStart.clone().endOf("month")
      : this.props.endDate.clone().add(this.props.dayRange, "days")
    this.props.onDatesChange({ startDate: newStart, endDate: newEnd })
  }

  render() {
    return (
      <div
        className={cn(
          styles.container,
          styles[this.props.color],
          styles[this.props.size],
          styles[this.props.textAlign],
          { [styles.focus]: this.state.isFocused }
        )}
      >
        <div
          className={cn(styles.nextPreviousButton, styles.previous, { [styles.disabled]: this.props.disabled })}
          onClick={this.handlePreviousButtonClick}
          onKeyUp={_.noop}
          role="button"
          tabIndex={0}
        >
          <Icon type={"chevron-left"} />
        </div>
        <div
          className={cn(styles.dateButton, { [styles.disabled]: this.props.disabled })}
          onClick={this.togglePicker}
          onKeyUp={_.noop}
          role="button"
          tabIndex={0}
        >
          {this.props.startDate.format("D MMM") + " – " + this.props.endDate.format("D MMM")}
        </div>
        <div
          className={cn(styles.nextPreviousButton, styles.next, { [styles.disabled]: this.props.disabled })}
          onClick={this.handleNextButtonClick}
          onKeyUp={_.noop}
          role="button"
          tabIndex={0}
        >
          <Icon type={"chevron-right"} />
        </div>
        {this.state.open && (
          <div className={styles.pickerWrapper}>
            <DayPickerRangeController
              disabled={this.props.disabled}
              enableOutsideDays
              endDate={this.props.endDate}
              endDateOffset={this.rangeEndDate}
              firstDayOfWeek={this.props.startDayOffset}
              focusedInput="startDate"
              hideKeyboardShortcutsPanel
              isOutsideRange={this.props.isOutsideRange}
              numberOfMonths={this.props.numberOfMonths}
              onDatesChange={this.props.onDatesChange}
              onFocusChange={_.noop}
              onOutsideClick={this.togglePicker}
              startDate={this.props.startDate}
              startDateOffset={this.rangeStartDate}
            />
          </div>
        )}
      </div>
    )
  }
}

TandaWeekRangePicker.defaultProps = {
  textAlign: "start",
  color: "default",
  size: "s",
  disabled: false,
  isMonthly: false,
  startDayOffset: 0, // Default to weeks starting sunday
  dayRange: 7, // Default to 1 week
  disableStartDaySnap: false,
  numberOfMonths: 1,
  isOutsideRange: () => {},
}

/**
 * rest of api here
 * https://github.com/airbnb/react-dates#daterangepicker
 */
TandaWeekRangePicker.propTypes = {
  numberOfMonths: PropTypes.number,
  disabled: PropTypes.bool,
  isMonthly: PropTypes.bool, // Turns this into a "month" picker.
  disableStartDaySnap: PropTypes.bool,
  textAlign: PropTypes.oneOf(["center", "start"]),
  color: PropTypes.oneOf(["success", "error", "warning", "default", "overnight"]),
  size: PropTypes.oneOf(["s", "m", "l"]),
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  onDatesChange: PropTypes.func.isRequired,
  isOutsideRange: PropTypes.func,
  startDayOffset: PropTypes.number, // Week start day for org, offset from sunday (eg, 1 == Monday)
  dayRange: PropTypes.number, // eg: 7 for a week picker, 14 for a fortnight picker, 5 for a 5 day org
}
