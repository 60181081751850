// @flow
import * as React from "react"
import Label from "components/Label"
import FieldInput from "platform/view/fieldInput"
import AssocInput from "../assocInput"
import LinkFieldInput from "../linkFieldInput"
import * as Model from "../../models/model"
import * as Association from "../../models/association"
import * as Store from "../../store"
import * as State from "../../models/state"
import * as Field from "../../models/field"
import * as Record from "../../models/record"
import styles from "./styles.module.scss"

type Props = {|
  +form: Record.Schema,
  +model: Model.Schema,
  +onEdit: (SyntheticInputEvent<HTMLElement>) => mixed,
  +onSave: () => mixed,
|}

export default function Form({ form, model, onEdit, onSave }: Props): React.Element<"div"> {
  const [state] = Store.useContext()
  const allVisibleFields = State.fieldList(state).map((f) => f.id)
  const visibleFields = React.useMemo(
    () => model.fields.filter((f) => !f.native && f.type !== "linked_field"),
    [model.fields]
  )
  const visibleLinkFields = React.useMemo(
    () => model.fields.filter((f) => !f.native && f.type === "linked_field"),
    [model.fields]
  )
  const [fieldState, setFieldState] = React.useState(() =>
    model.fields.map((i) => i.key).reduce((o, key) => Object.assign(o, { [key]: false }), {})
  )

  const [assocState, setAssocState] = React.useState(() =>
    model.associations
      .filter((a) => !a.native)
      .map((i) => i.key)
      .reduce((o, key) => Object.assign(o, { [key]: false }), {})
  )
  const fieldInvalid = (field) =>
    Field.validateField(Record.valByKey(form, field.key)) && fieldState[field.key] === true && field.required
  const assocInvalid = (assoc) =>
    Association.isRequired(assoc, Record.refByKey(form, assoc.key)) && assocState[assoc.key] === true && assoc.required

  const [loading, setLoading] = React.useState(false)
  const handleUploadStart = () => setLoading(true)
  const handleUploadFinish = () => setLoading(false)

  return (
    <div className={styles.fields}>
      {model.associations
        .filter((a) => !a.native)
        .map((assoc) => (
          <div className={styles.row} key={assoc.id}>
            <div className={styles.name}>
              <Label text={assoc.name} />
              <div className={assoc.required ? styles.requiredMark : null} />
            </div>
            <div className={assocInvalid(assoc) ? styles.requiredAssoc : styles.input}>
              <AssocInput
                assoc={assoc}
                loading={loading}
                model={model}
                onEdit={(event) => {
                  onEdit(event)
                  setAssocState((prevState) => ({ ...prevState, [assoc.key]: true }))
                }}
                onSave={onSave}
                onUploadFinish={handleUploadFinish}
                onUploadStart={handleUploadStart}
                record={form}
                value={Record.refByKey(form, assoc.key)}
              />
            </div>
          </div>
        ))}
      {visibleFields.map((field) => (
        <div className={styles.row} key={field.id}>
          <div className={styles.name}>
            <Label text={field.name} />
            <div className={field.required ? styles.requiredMark : null} />
          </div>
          <div className={fieldInvalid(field) ? styles.requiredInput : styles.input}>
            <FieldInput
              field={field}
              onEdit={(event) => {
                onEdit(event)
                setFieldState((prevState) => ({ ...prevState, [field.key]: true }))
              }}
              onSave={onSave}
              value={Record.valByKey(form, field.key)}
            />
          </div>
        </div>
      ))}
      {form.id !== "" &&
        visibleLinkFields.map((field) => (
          <div className={styles.row} key={field.id}>
            <div
              className={
                field.config && allVisibleFields.includes(field.config.subjectFieldId) ? styles.name : styles.hiddenName
              }
              data-testid={"link-field-name-" + field.key.split("_").join("-")}
            >
              <Label text={field.name} />
            </div>
            <div className={styles.input}>
              <LinkFieldInput
                field={field}
                onEdit={(event) => {
                  onEdit(event)
                  setFieldState((prevState) => ({ ...prevState, [field.key]: true }))
                }}
                onSave={onSave}
                value={Record.valByKey(form, field.key)}
              />
            </div>
          </div>
        ))}
    </div>
  )
}
