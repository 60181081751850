/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import { isFunction, defer } from "lodash"
import { createElement } from "react"
import ReactDOM from "react-dom"
import Loader from "components/Loader"
import { GUID } from "./function"

const targets = {}

export function start(element, props = {}) {
  if (props.onlyOne && targets[keyFor(element)] && targets[keyFor(element)].count === 1) {
    return null
  }
  const container = getOrBuildMountPoint(element)
  ReactDOM.render(createElement(Loader, { size: props.size || "xl", color: "primary" }), container)
  return container
}

export function until(promiseOrCallback, element) {
  if (isFunction(promiseOrCallback)) {
    start(element)
    promiseOrCallback()
    defer(() => stop(element))
  } else if (promiseOrCallback instanceof Promise) {
    start(element)

    return promiseOrCallback.then(() => stop(element))
  } else {
    start(element)
    return promiseOrCallback.done(() => stop(element))
  }
}

export function stop(element) {
  if (cache.remove(element) === 0) {
    ReactDOM.unmountComponentAtNode(cache.get(element))
  }
}

export function startWithOverlay(element, props = {}) {
  const container = getOrBuildMountPoint(element)
  ReactDOM.render(createElement(Loader, { size: props.size || "xl", color: "primary" }), container)

  const parent = container.parentElement
  if (parent.querySelector(".overlay") == null) {
    const overlay = document.createElement("div")
    overlay.setAttribute("class", "overlay")
    parent.insertBefore(overlay, parent.firstChild)
  }

  return container
}

export function stopWithOverlay(element) {
  if (cache.remove(element) === 0) {
    document.getElementsByClassName("overlay")[0].remove()
    ReactDOM.unmountComponentAtNode(cache.get(element))
  }
}

const cache = {
  get: (element) => targets[keyFor(element)] && targets[keyFor(element)].target,
  increment: (element) => {
    targets[keyFor(element)].count += 1
    return targets[keyFor(element)].count
  },
  set: (element, value) => {
    const key = keyFor(element)
    if (targets[key]) {
      cache.increment(element)
    } else {
      targets[key] = { target: value, count: 1 }
    }
    return targets[key]
  },
  remove: (element) => {
    const key = keyFor(element)
    if (targets[key] && targets[key].count) {
      targets[key].count = Math.max(targets[key].count - 1, 0)
      return targets[key].count
    }
  },
}

const keyFor = (element) => {
  element = element || defaultElement()
  if (!element.getAttribute("data-guid")) {
    element.setAttribute("data-guid", GUID.new())
  }

  return element.getAttribute("data-guid")
}

const buildTarget = (element) => {
  let target
  if (element) {
    target = document.createElement("div")
    target.setAttribute("class", "pos-c")
    element.appendChild(target)
  } else {
    target = defaultElement()
  }

  return cache.set(element, target).target
}

const getOrBuildMountPoint = (element) => {
  if (cache.get(element)) {
    cache.increment(element)
    return cache.get(element)
  } else {
    return buildTarget(element)
  }
}

const spinnerTarget = () => {
  const spinnertarget = document.createElement("div")
  spinnertarget.setAttribute("id", "spinnertarget")
  spinnertarget.setAttribute("class", "pos-c popup")
  if (document.body) {
    document.body.appendChild(spinnertarget)
  }
  return spinnertarget
}

const defaultElement = () => document.getElementById("spinnertarget") || spinnerTarget()
