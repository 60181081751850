/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

export const getAllFilteredDepartments = (departmentIds, departments) =>
  departments.toList().filter((d) => departmentIds.includes(d.get("id")))

export const getAllSelectedLocationIds = (departments) => [
  ...new Set(departments.map((d) => d.getIn(["location", "id"])).toArray()),
]

export const getUnloadedLocationId = (selectedLocationIds, loadedLocationIds) =>
  selectedLocationIds.find(
    (locationId) => !loadedLocationIds.some((loadedLocationId) => loadedLocationId === locationId)
  )
