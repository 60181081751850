// @flow
import type {
  Weather_location_weather as RawGQLWeatherData,
  Weather_location_weather_hourly,
  Weather_location_weather_daily_data,
} from "helpers/gql/types"

export type RawWeatherData = RawGQLWeatherData

export type Schema = {|
  data: WeatherData,
  date: string,
  location_id: number,
  raw_data: RawWeatherData,
|}
export const refineRawData = (raw_data: RawWeatherData, date: string, location_id: number): Schema => ({
  location_id,
  date,
  data: {
    today: {
      data: raw_data.daily.data[0],
      icon: raw_data.hourly.icon,
      summary: raw_data.hourly.summary,
    },
    hourly: raw_data.hourly,
    latitude: raw_data.latitude,
    longitude: raw_data.longitude,
    timezone: raw_data.timezone,
    referenceTime: raw_data.daily.data[0]?.time,
  },
  raw_data,
})

export default refineRawData

export type WeatherData = {|
  hourly: Weather_location_weather_hourly,
  latitude: number,
  longitude: number,
  referenceTime: number,
  timezone: string,
  today: {|
    data: Weather_location_weather_daily_data,
    icon: ?string,
    summary: ?string,
  |},
|}
