/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { t } from "helpers/i18n"
import { limberWorker } from "timesheets/models/shift"
import * as Timesheet from "timesheets/models/timesheet"
import ControlButton from "timesheets/components/ControlButton"

export default class LeaveButton extends React.PureComponent {
  openLeaveModal = () => {
    const finish_m = this.finishTime()
    const start_m = this.startTime()
    const shiftLength = this.shiftLength()
    const leaveParams = {
      departmentId:
        this.props.shift.get("department_id") == null ? null : this.props.shift.get("department_id").toString(),
      endDate: moment(this.props.shift.get("date", "YYYY-MM-DD")),
      leaveLength: shiftLength.toString(),
      startDate: moment(this.props.shift.get("date", "YYYY-MM-DD")),
      userId: this.props.shift.get("user_id").toString(),
      endTime: finish_m && {
        hour: finish_m.hour(),
        minute: finish_m.minute(),
      },
      startTime: start_m && {
        hour: start_m.hour(),
        minute: start_m.minute(),
      },
    }
    this.props.actions.appOpenLeaveModal(leaveParams)
  }

  startTime() {
    const start = this.props.shift.get("start")
    if (start?.isValid()) {
      return start
    }
    return this.props.shift.get("rostered_start")
  }

  finishTime() {
    const finish = this.props.shift.get("finish")
    if (finish?.isValid()) {
      return finish
    }
    return this.props.shift.get("rostered_end")
  }

  shiftLength() {
    return this.props.shift.get("shift_length") || this.props.shift.get("rostered_length") || 0
  }

  isDisabled() {
    return Timesheet.isExported(this.props.timesheet) || limberWorker(this.props.shift)
  }

  render() {
    return (
      <ControlButton
        disabled={this.isDisabled()}
        icon="leave"
        onClick={this.openLeaveModal}
        tip={t("js.timesheets.shift_card.controls.add_leave")}
        tipPosition={this.props.tipPosition}
      />
    )
  }
}

LeaveButton.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  shift: PropTypes.object.isRequired,
  timesheet: PropTypes.object.isRequired,
  tipPosition: PropTypes.string.isRequired,
}
