/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import { t as globalT } from "helpers/i18n"
import * as Routes from "helpers/routes"
import Button from "components/Button"
import * as Timesheet from "timesheets/models/timesheet"
import Description, { Types } from "./Description"
import styles from "./styles.module.scss"

const t = (key) => globalT(`js.timesheets.allowance_modal.empty_state.${key}`)

export default class EmptyState extends React.PureComponent {
  openAllowancesPage() {
    open(Routes.new_allowance_path(), "_blank")
  }

  render() {
    return (
      <div className={styles.EmptyState}>
        <svg className={styles.diagram} version="1.1" xmlns="http://www.w3.org/2000/svg">
          <rect className={styles.topNote} height="72.1" width="139.9" x="20.2" y="56" />
          <rect className={styles.notes} height="72.1" width="139.9" x="32.2" y="43" />
          <rect className={styles.notes} height="72.1" width="139.9" x="44.2" y="29.9" />
          <text className={styles.dollars} transform="matrix(1 0 0 1 97.735 87)">
            $
          </text>
        </svg>

        <Description timesheet={this.props.timesheet} type={this.props.type} />

        {this.props.timesheet && !Timesheet.isExported(this.props.timesheet) && (
          <Button label={t("create_allowances")} onClick={this.openAllowancesPage} type="theme" />
        )}
      </div>
    )
  }
}

EmptyState.defaultProps = { timesheet: undefined }

EmptyState.propTypes = {
  type: PropTypes.oneOf([Types.INFO, Types.EMPTY]).isRequired,
  timesheet: PropTypes.object,
}
