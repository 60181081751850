/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import _ from "lodash"

import cn from "classnames"
import { t as globalT } from "helpers/i18n"
import HolidayIcon from "components/HolidayIcon"
import Icon from "components/Icon"
import Text from "components/Text"
import { AutoHoverBubble } from "components/Bubble"
import * as Routes from "helpers/routes"
import * as TimeFormatter from "helpers/time_formatter"
import styles from "./styles.module.scss"

const t = (key, opts) => globalT(`js.timesheets.shift_card.info.regular_hours.${key}`, opts)
const NO_AGENCY = "no_agency"

export default class Info extends React.PureComponent {
  state = { hovered: 0 }
  url() {
    return this.props.shift.get("timesheet_id")
      ? Routes.timesheet_path(this.props.shift.get("timesheet_id"))
      : Routes.timesheets_for_user_path(this.props.shift.get("user_id"))
  }

  convert_to_user_time(time) {
    const momentised = moment(time, "HH:mm")
    const formatter = window.time_formatter
    if (formatter) {
      return formatter.short_time({ hour: momentised.get("hour"), minute: momentised.get("minute") })
    }
    return ""
  }

  get_acceptance_colour(acceptanceProp) {
    switch (acceptanceProp) {
      case "accepted":
        return "success"
      case "declined":
        return "danger"
      case "contracted":
        return "contracted"
      default:
        return "warning"
    }
  }

  show_schedule_acceptance() {
    const schedAcceptance = this.props.shift.get("schedule_acceptance")
    const rosteredStart = this.convert_to_user_time(this.props.shift.get("rostered_start"))
    const rosteredBreaks = this.format_breaks(this.props.shift.get("rostered_breaks"))
    const rosteredFinish = this.convert_to_user_time(this.props.shift.get("rostered_end"))

    // Accepted = Green, Not Accepted/Declined = Red, Contracted = Yellow, Anything else = Grey
    const colour = this.get_acceptance_colour(schedAcceptance)

    return (
      schedAcceptance != null && (
        <div className={styles.individualIcons}>
          <Icon
            color={colour === "contracted" ? "transparent_black" : colour}
            padding="0 0.075rem"
            size="s"
            type="check-circle"
          />
          <AutoHoverBubble position="bottom">
            <span className={cn(styles.tip, styles.tipLeft)}>
              {t(`acceptance_text.${schedAcceptance}`)}
              <div className={styles.innerRHW}>
                {`${t("start")}: ${rosteredStart}`} <br />
                {`${t("breaks")}: ${rosteredBreaks}`} <br />
                {`${t("end")}: ${rosteredFinish}`} <br />
              </div>
            </span>
          </AutoHoverBubble>
        </div>
      )
    )
  }

  format_breaks(breaks) {
    if (breaks === "" || breaks == null) {
      return "-"
    }

    return breaks
      .split(",")
      .map((time) =>
        time
          .split("-")
          .map((t) => this.convert_to_user_time(t))
          .join("-")
      )
      .join(", ")
  }

  show_regular_hours() {
    const regular_hours = this.props.shift.get("regular_hours")

    return (
      <div className={styles.individualIcons}>
        <Icon color={"transparent_black"} padding="0 0.075rem" size="s" type="assignment" />
        <AutoHoverBubble position="bottom">
          <span className={cn(styles.tip, styles.tipLeft)}>
            {t("title")} <br />
            {regular_hours != null
              ? _.map([...regular_hours], (rhw) => (
                  <div className={styles.innerRHW}>
                    {`${t("start")}: ${this.convert_to_user_time(rhw.get("start"))}`} <br />
                    {`${t("breaks")}: ${this.format_breaks(rhw.get("breaks"))}`} <br />
                    {`${t("end")}: ${this.convert_to_user_time(rhw.get("finish"))}`} <br />
                  </div>
                ))
              : t("no_regular_hours")}
          </span>
        </AutoHoverBubble>
      </div>
    )
  }

  show_temporary_employee_type() {
    const hasTemporaryEmployeeType = !!this.props.shift.get("user")?.toJS().temporary_employee_type

    if (!hasTemporaryEmployeeType) {
      return null
    }

    return (
      <Text bold color="primary" padding="0 0.25rem" type="small">
        {this.props.shift.get("user").toJS().temporary_employee_type === NO_AGENCY
          ? t("temporary_employee")
          : t("limber_temporary_employee")}
      </Text>
    )
  }

  render_ppt_components() {
    // Only render if prop exists
    if (this.props.shift.get("display_regular_hours")) {
      return (
        <React.Fragment>
          {/* Regular Hours */}
          {this.show_regular_hours()}

          {/* Shift Acceptance */}
          {this.show_schedule_acceptance()}
        </React.Fragment>
      )
    }
  }

  render_unpublished_roster_components() {
    const unpublishedRoster = this.props.shift.get("unpublished_roster")
    const unpublishedStart = TimeFormatter.shiftTimeInput(
      this.props.shift.get("unpublished_start"),
      this.props.currentUser
    )
    const unpublishedBreak = this.format_breaks(this.props.shift.get("unpublished_breaks"))
    const unpublishedEnd = TimeFormatter.shiftTimeInput(this.props.shift.get("unpublished_end"), this.props.currentUser)

    return (
      unpublishedRoster && (
        <React.Fragment>
          <div className={styles.individualIcons}>
            <Icon color={"transparent_black"} size="s" type="warning" />
            <AutoHoverBubble position="bottom" width="medium">
              <span className={cn(styles.tip, styles.tipLeft)}>
                {t("unpublished_roster")}
                <div className={styles.innerRHW}>
                  {`${t("start")}: ${unpublishedStart}`} <br />
                  {`${t("breaks")} ${unpublishedBreak}`} <br />
                  {`${t("end")}: ${unpublishedEnd}`}
                </div>
              </span>
            </AutoHoverBubble>
          </div>
        </React.Fragment>
      )
    )
  }

  render() {
    return (
      <React.Fragment>
        <div className={styles.Info}>
          <span className={styles.text}>
            {this.props.type === "USER_CARD" ? (
              <a className={styles.nameLink} href={this.url()} rel="noopener noreferrer" target="_blank">
                {this.props.shift.get("user_name")}
              </a>
            ) : (
              <div className={styles.dateArea}>
                <span className={styles.dateText}>{moment(this.props.shift.get("date")).format("ddd, D MMM")}</span>
                <span className={styles.icons}>
                  {/* Public Holiday */}
                  <HolidayIcon
                    date={moment(this.props.shift.get("date"))}
                    margin="-4px 0 0 0"
                    padding="0 0.15rem"
                    size={"s"}
                    userId={this.props.shift.get("user_id")}
                  />
                </span>
              </div>
            )}
          </span>
          <span className={styles.icons}>
            {this.render_ppt_components()}
            {this.render_unpublished_roster_components()}
          </span>
        </div>
        {this.props.type === "USER_CARD" && this.show_temporary_employee_type()}
      </React.Fragment>
    )
  }
}

Info.propTypes = {
  shift: PropTypes.object.isRequired,
  type: PropTypes.oneOf(["DATE_CARD", "USER_CARD"]).isRequired,
  currentUser: PropTypes.object.isRequired,
}
