/* @flow */

import * as React from "react"
import cn from "classnames"
import moment from "moment"
import { s3ImagePath } from "helpers/image"
import * as Routes from "helpers/routes"
import styles from "./styles.module.scss"

type Props = {|
  createRosterText: string,
  messageText: string,
  viewTimesheetsText: string,
|}

export default class EmptyState extends React.PureComponent<Props> {
  render(): React.Element<"div"> {
    return (
      <div className={styles.State}>
        <div className={styles.EmptyState}>
          <div className={styles.StateContainer}>
            <img alt="empty" className={styles.Empty} src={s3ImagePath("dash_liveinsights_empty.png")} />
          </div>
          <div className={cn(styles.MessageContainer, "m1")}>
            <span className={styles.EmptyMessage}>{this.props.messageText}</span>
          </div>
          <div className={styles.ButtonContainer}>
            <a
              className={cn(styles.TimesheetButton, "btn-theme-secondary")}
              href={Routes.timesheets_daily_path({ date: moment().format("YYYY-MM-DD") })}
            >
              <strong>{this.props.viewTimesheetsText}</strong>
            </a>
            <a className={cn(styles.Button, "btn-theme-primary")} href={Routes.rosters_overview_path()}>
              <strong>{this.props.createRosterText}</strong>
            </a>
          </div>
        </div>
      </div>
    )
  }
}
