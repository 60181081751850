// @flow

import * as React from "react"
import { t } from "helpers/i18n"

import Loader from "components/Loader"

import styles from "./styles.module.scss"

const FetchState = (): React.Element<"div"> => (
  <div className={styles.FetchState}>
    <Loader color="primary" size="xl" />
    <h3 className={styles.message}>{t("js.timesheets.shift_list.fetch_state_message")}</h3>
  </div>
)

export default FetchState
