// @flow

export type MixedArray = $ReadOnlyArray<mixed>

export type MixedObject = $ReadOnly<{ [key: string]: mixed }>

export function object(value: mixed): MixedObject {
  if (value == null || typeof value !== "object" || Array.isArray(value)) {
    throw new Error("Error marshalling object")
  }

  return value
}

export function string(value: mixed): string {
  if (typeof value !== "string") {
    throw new Error("Error marshalling string")
  }

  return value
}

export function number(value: mixed): number {
  if (typeof value !== "number") {
    throw new Error("Error marshalling number")
  }

  return value
}

export function numberOrString(value: mixed): number | string {
  if (typeof value === "string") {
    return value
  }
  if (typeof value === "number") {
    return value
  }

  throw new Error("Error marshalling number or string!")
}

export function boolean(value: mixed): boolean {
  if (typeof value !== "boolean") {
    throw new Error("Error marshalling boolean")
  }

  return value
}

export function array(value: mixed): MixedArray {
  if (!Array.isArray(value)) {
    throw new Error("Error marshalling array")
  }

  return value
}

export function enumerated<T>(types: Array<T>, value: string): T {
  const type = types.find((t) => t === value)

  if (type == null) {
    throw new Error("Error marshalling enumerated")
  }

  return type
}

export function maybeObject(value: mixed): ?MixedObject {
  return value == null ? null : object(value)
}

export function maybeNumber(value: mixed): ?number {
  return value == null ? null : number(value)
}

export function maybeString(value: mixed): ?string {
  return value == null ? null : string(value)
}

export function maybeNumberOrString(value: mixed): ?(number | string) {
  return value == null ? null : numberOrString(value)
}

export function maybeBoolean(value: mixed): ?boolean {
  return value == null ? null : boolean(value)
}

export function maybeArray(value: mixed): ?MixedArray {
  return value == null ? null : array(value)
}

export function withDefault<F, R>(fallback: F, marshall: () => R): F | R {
  if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
    return marshall()
  }

  try {
    return marshall()
  } catch (_) {
    return fallback
  }
}
