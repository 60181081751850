// @flow
import * as React from "react"
import cn from "classnames"
import TextareaAutosize from "react-autosize-textarea"
import styles from "./styles.module.scss"

type Ev = SyntheticInputEvent<HTMLInputElement>

type Props = {|
  className: ?string,
  color: "success" | "error" | "warning" | "default",
  disabled: boolean,
  focusOnMount: boolean,
  htmlName: ?string,
  maxLength?: ?number,
  maxRows?: ?number,
  minRows: ?number,
  onBlur: ?(Ev) => mixed,
  onChange: (Ev) => mixed,
  placeholder: ?string,
  testId: ?string,
  value: string,
|}

export default function TextArea(props: Props): React.Node {
  return (
    <TextareaAutosize
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={props.focusOnMount}
      className={cn(props.className, styles.input, styles[props.color], { [styles.disabled]: props.disabled })}
      data-testid={props.testId}
      disabled={props.disabled}
      maxLength={props.maxLength}
      maxRows={props.maxRows}
      name={props.htmlName}
      onBlur={props.onBlur}
      onChange={props.onChange}
      placeholder={props.placeholder}
      rows={props.minRows}
      value={props.value}
    />
  )
}

TextArea.defaultProps = {
  className: null,
  onBlur: null,
  htmlName: null,
  focusOnMount: false,
  color: "default",
  disabled: false,
  testId: null,
  placeholder: null,
  maxLength: null,
  maxRows: null,
  minRows: 2,
}
