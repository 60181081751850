/* @flow */

import * as React from "react"
import cn from "classnames"

import Icon from "components/Icon"

import * as I18n from "helpers/i18n"

import styles from "./styles.module.scss"

type ReplaceData = {
  replace: boolean,
}

type LabelData = {
  color: string,
  name: string,
}

type Option = {
  data?: LabelData | ReplaceData,
  label: string,
  options: Array<Option>, // it will have an array of itself if it's a group
  value: string | number | Array<number>,
}

interface Event {
  target: {
    data: LabelData | ReplaceData | void,
    value: string | number | Array<string> | Array<number>,
  };
}

type RequiredProps = {|
  onClick: (event: Event) => void,
|}

type Props = {|
  ...RequiredProps,
  disabled: boolean,
  isOpen: boolean,
  numberOfBadges: number,
  options: $ReadOnlyArray<Option>,
  selected: Array<string | number>,
  size: string, // size of filter (for css purposes)
|}

const t = (key: string, values: mixed): string => I18n.t(`js.settings.leave_smart_fill.form.${key}`, values)

MultiFilterBadges.defaultProps = {
  selected: [],
  options: [],
}

export default function MultiFilterBadges({
  disabled,
  selected,
  onClick,
  options,
  numberOfBadges,
  isOpen,
  size,
}: Props): React.Element<"div"> {
  const handleClick = (event: SyntheticEvent<HTMLDivElement>, option: Option) => {
    if (disabled) {
      return
    }
    onClick({
      ...event,
      target: {
        value: option.value,
        data: option.data,
      },
    })
  }

  const renderSelected = (dropdownOption: Option) => {
    const { label, value } = dropdownOption
    // if group check if any of its options is selected
    if (dropdownOption.options) {
      return selected.slice(0, numberOfBadges).map((value) => {
        const isSelected = dropdownOption.options.find((op) => op.value === value)
        if (isSelected) {
          return (
            <div
              className={styles.Badge}
              key={dropdownOption.label}
              onClick={(e) => handleClick(e, isSelected)}
              onKeyPress={null}
              role="button"
              tabIndex={0}
            >
              <span>{isSelected.label}</span>
              <Icon color="primary_dark" size="s" type="close" />
            </div>
          )
        }
      })
    } else {
      // single option render if selected
      if (selected.slice(0, numberOfBadges).includes(value)) {
        return (
          <div
            className={styles.Badge}
            key={dropdownOption.label}
            onClick={(e) => handleClick(e, dropdownOption)}
            onKeyPress={null}
            role="button"
            tabIndex={0}
            value={value}
          >
            <span>{label}</span>
            <Icon color="primary_dark" size="xs" type="close" />
          </div>
        )
      }
    }
  }
  return (
    <div className={cn(styles.badgeContainer, { [styles[size]]: size, [styles.Container]: isOpen })}>
      {options.map((option) => renderSelected(option))}
      {selected.length > numberOfBadges && (
        <p className={styles.SubText}>{t(`excess_tag_counter`, { excess: selected.length - numberOfBadges })}</p>
      )}
    </div>
  )
}
