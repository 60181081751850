/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import { t } from "helpers/i18n"
import * as Shift from "timesheets/models/shift"
import * as Timesheet from "timesheets/models/timesheet"

import ControlButton from "timesheets/components/ControlButton"

export default class ClearButton extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleClearShift = this.handleClearShift.bind(this)
  }

  handleClearShift() {
    this.props.actions.shiftsClear({ shift: this.props.shift })
  }

  isDisabled() {
    return (
      Timesheet.isExported(this.props.timesheet) ||
      Shift.isApproved(this.props.shift) ||
      this.props.shift.get("state") === "SYNCING" ||
      Shift.limberWorker(this.props.shift)
    )
  }

  render() {
    return (
      <ControlButton
        disabled={this.isDisabled()}
        icon="delete"
        onClick={this.handleClearShift}
        tip={t("js.timesheets.shift_card.controls.clear_shift")}
        tipPosition={this.props.tipPosition}
      />
    )
  }
}

ClearButton.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  shift: PropTypes.object.isRequired,
  timesheet: PropTypes.object.isRequired,
  tipPosition: PropTypes.string.isRequired,
}
