/* @flow */

import _ from "lodash"

/**
 * Award graph color palette
 */
export const AwardColors = {
  hex: [
    "#79A2F3",
    "#F39179",
    "#F3C279",
    "#79F381",
    "#F379F3",
    "#797DF3",
    "#79D6F3",
    "#F379A2",
    "#C2F379",
    "#F37979",
    "#79B6F3",
    "#F379F3",
    "#79F3E5",
    "#F3F379",
    "#A279F3",
    "#F3B679",
    "#F37979",
    "#79F3B6",
    "#79A8F3",
    "#797DF3",
  ],
  string: [
    "rgb(121, 162, 243)",
    "rgb(243, 145, 121)",
    "rgb(243, 194, 121)",
    "rgb(121, 243, 129)",
    "rgb(243, 121, 243)",
    "rgb(121, 125, 243)",
    "rgb(121, 214, 243)",
    "rgb(243, 121, 162)",
    "rgb(194, 243, 121)",
    "rgb(243, 121, 121)",
    "rgb(121, 182, 243)",
    "rgb(243, 121, 243)",
    "rgb(121, 243, 229)",
    "rgb(243, 243, 121)",
    "rgb(162, 121, 243)",
    "rgb(243, 182, 121)",
    "rgb(243, 121, 121)",
    "rgb(121, 243, 182)",
    "rgb(121, 168, 243)",
    "rgb(121, 125, 243)",
  ],
}

// should sort before non-ordinary hours.
// non-leave should sort before leave
// eslint-disable-next-line flowtype/no-weak-types
export const awardHash = (d: Object): string =>
  [d.is_ord_hours ? 0 : 1, d.multiplier, d.has_min_length ? 0 : 1, d.is_leave ? 1 : 0].join()

export const awardColors = (count: number, format: "string" | "hex" = "string"): Array<string> => {
  let colors: Array<string> = Array.from(AwardColors[format])
  if (count > colors.length) {
    _.times(Math.ceil(count / colors.length) - 1, () => {
      colors = colors.concat(AwardColors[format])
    })
  }

  return colors
}
