// @flow
import _ from "lodash"

export type PositionType = "top" | "bottom" | "left" | "right"
export const POSSIBLE_POSITIONS: Array<PositionType> = ["bottom", "top", "left", "right"]
export const POPOVER_CONTAINER_CLASS = "POPOVER_CONTAINER"
export type Bounds = {
  bottom: number,
  left: number,
  right: number,
  top: number,
}

// This will get an approximate new bounds for a popover that is rendered with the given position
// target_bounds: The Hover Target where the popover is rendering onto
// content_bounds: The Contents of the popover, we just use this to get its width/height
export const getNewBounds = (content_bounds: ClientRect, target_bounds: ClientRect, position: PositionType): Bounds => {
  const target_mid_x = target_bounds.left + target_bounds.width / 2
  switch (position) {
    case "top":
      return {
        bottom: target_bounds.top,
        left: target_mid_x - content_bounds.width / 2,
        right: target_mid_x + content_bounds.width / 2,
        top: target_bounds.top - content_bounds.height,
      }
    case "left":
      return {
        bottom: target_bounds.top + content_bounds.height,
        left: target_bounds.left - content_bounds.width,
        right: target_bounds.left,
        top: target_bounds.top,
      }
    case "right":
      return {
        bottom: target_bounds.top + content_bounds.height,
        left: target_bounds.right,
        right: target_bounds.right + content_bounds.width,
        top: target_bounds.top,
      }
    case "bottom":
      return {
        bottom: target_bounds.bottom + content_bounds.height,
        left: target_mid_x - content_bounds.width / 2,
        right: target_mid_x + content_bounds.width / 2,
        top: target_bounds.bottom,
      }
    default:
      return {
        bottom: content_bounds.bottom,
        left: content_bounds.left,
        right: content_bounds.right,
        top: content_bounds.top,
      }
  }
}

export const getNewBoundsWithPadding = (
  content_bounds: ClientRect,
  target_bounds: ClientRect,
  position: PositionType,
  padding: number
): Bounds => {
  const b = getNewBounds(content_bounds, target_bounds, position)
  return {
    bottom: b.bottom + padding,
    left: b.left - padding,
    right: b.right + padding,
    top: b.top - padding,
  }
}

const getDefaultViewport = (): Bounds => ({
  top: 0,
  left: 0,
  right: window.innerWidth || document.body?.clientWidth || 0,
  bottom: window.innerHeight || document.body?.clientHeight || 0,
})

// Checks if a given rectangle (bounds) is outside the viewport.
// this will return true if any part of the rectangle is out of bounds.
export const outOfViewport = (bounds: Bounds, viewPort: Bounds = getDefaultViewport()): boolean =>
  bounds.top < viewPort.top ||
  bounds.left < viewPort.left ||
  bounds.right > viewPort.right ||
  bounds.bottom > viewPort.bottom

export const getDefaultPosition = (target_bounds: ClientRect): string => {
  const viewport_size = {
    width: window.innerWidth || document.body?.clientWidth || 0,
    height: window.innerHeight || document.body?.clientHeight || 0,
  }
  // If none pass, then we will attempt to find the most reasonable position by just seeing what part of the screen we are one
  // eg, if we near the bottom, we will show the popover to the top.
  const ref_x = target_bounds.left + target_bounds.width / 2
  const ref_y = target_bounds.top + target_bounds.height / 2
  const viewport_mid_x = viewport_size.width / 2
  const viewport_mid_y = viewport_size.height / 2
  const from_center_x = Math.abs(ref_x - viewport_mid_x) / viewport_mid_x
  const from_center_y = Math.abs(ref_y - viewport_mid_y) / viewport_mid_y
  if (from_center_x > from_center_y && ref_x > viewport_mid_x) {
    return "left"
  } else if (from_center_x > from_center_y && ref_x <= viewport_mid_x) {
    return "right"
  } else if (from_center_x <= from_center_y && ref_y > viewport_mid_y) {
    return "top"
  } else {
    return "bottom"
  }
}

export const getViewPort = (target_element: ?HTMLDivElement): Bounds => {
  const default_viewport = getDefaultViewport()
  if (target_element != null) {
    const container_el = target_element.closest("." + POPOVER_CONTAINER_CLASS)
    if (container_el != null) {
      const container_bounds = container_el.getBoundingClientRect()
      return {
        top: _.max([container_bounds.top, 0]),
        left: _.max([container_bounds.left, 0]),
        bottom: _.min([container_bounds.bottom, default_viewport.bottom]),
        right: _.min([container_bounds.right, default_viewport.right]),
      }
    }
  }
  return default_viewport
}

export const getNewPosition = (
  content_bounds: ClientRect,
  target_bounds: ClientRect,
  target_element: ?HTMLDivElement,
  exclude_positions: Array<PositionType> = []
): PositionType | string => {
  // We run all possible positions through our outOfViewport check, we use the first one that passes
  const possible_positions = POSSIBLE_POSITIONS.filter((p) => !exclude_positions.includes(p))
  const maybe_default_pos = getDefaultPosition(target_bounds)
  const default_pos = possible_positions.includes(maybe_default_pos)
    ? maybe_default_pos
    : possible_positions[0] || maybe_default_pos
  const container_viewport = getViewPort(target_element)
  const valid_positions: Array<PositionType> = possible_positions.filter((p) => {
    const new_bounds = getNewBoundsWithPadding(content_bounds, target_bounds, p, 4)
    return !outOfViewport(new_bounds, container_viewport)
  })
  return valid_positions.includes(default_pos) ? default_pos : valid_positions[0] || default_pos
}
