// @flow

import * as React from "react"
import cn from "classnames"
import { noop } from "lodash"
import Label from "../Label"
import styles from "./styles.module.scss"
import Checkbox from "./Checkbox"

type RequiredProps = {|
  checked: boolean,
  label: React.Node,
  onClick: (e: { target: { value: boolean } }) => mixed,
|}

type Props = {|
  ...RequiredProps,
  autoMargin: boolean,
  compressed: boolean,
  disabled: boolean,
  htmlName: ?string,
  inverted: boolean,
  labelPos: "left" | "right",
  size: "l" | "m",
  tabIndex: number,
  testId?: ?string,
|}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  disabled: false,
  inverted: false,
  labelPos: "right",
  size: "m",
  tabIndex: 0,
  autoMargin: true,
  compressed: false,
  htmlName: null,
  testId: undefined,
}

type State = {|
  hover: boolean,
|}

export default class CheckboxLabelled extends React.PureComponent<Props, State> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  constructor(props: Props) {
    super(props)
    this.onClick = this.onClick.bind(this)
    this.onKeyUp = this.onKeyUp.bind(this)
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.state = { hover: false }
  }

  onClick: (e: { target: { name: ?string, value: boolean, ... }, ... }) => void = (e: {
    target: { name: ?string, value: boolean },
  }) => {
    // don't do anything if the component is disabled
    if (this.props.disabled) {
      return
    }
    e.target.value = !this.props.checked
    e.target.name = this.props.htmlName
    // invert the current checked status, because that's the 'going to' status
    this.props.onClick({
      ...e,
      target: {
        ...e.target,
      },
    })
  }

  onKeyUp: (e: SyntheticKeyboardEvent<HTMLDivElement>) => void = (e: SyntheticKeyboardEvent<HTMLDivElement>) => {
    if (e.key === " " || e.key === "Enter") {
      this.onClick({ target: { name: null, value: false } })
    }
  }

  onMouseEnter: () => void = () => {
    this.setState({ hover: true })
  }

  onMouseLeave: () => void = () => {
    this.setState({ hover: false })
  }

  wrapperClasses: () => string = () =>
    cn(styles.wrapper, {
      [styles.compressed]: this.props.compressed,
      [styles.hover]: this.state.hover && !this.props.disabled,
      [styles.disabled]: this.props.disabled,
      [styles.leftLabel]: this.props.labelPos === "left",
      [styles.inverted]: this.props.inverted && !this.props.disabled,
    })

  getLabel(type: "left" | "right"): void | React.Node {
    if (this.props.labelPos === type && this.props.label) {
      if (typeof this.props.label === "string") {
        const labelColor = this.state.hover && this.props.inverted ? "white" : "black"

        const className = cn(styles.labelText, {
          [styles.large]: this.props.size === "l",
          [styles.labelMarginLeft]: this.props.labelPos === "left" && !this.props.autoMargin, // if there's no automargin, add a lil margin near the label
          [styles.labelMarginRight]: this.props.labelPos === "right" && !this.props.autoMargin, // if there's no automargin, add a lil margin near the label
        })

        return (
          <div className={className}>
            <Label color={labelColor} disabled={this.props.disabled} text={this.props.label} />
          </div>
        )
      } else {
        return this.props.label
      }
    }
  }

  render(): React.Element<"div"> {
    return (
      <div
        aria-pressed={this.props.checked}
        className={this.wrapperClasses()}
        onClick={this.onClick}
        onKeyUp={this.onKeyUp}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        role="button"
        tabIndex={this.props.tabIndex}
      >
        {this.getLabel("left")}
        <Checkbox
          autoMargin={this.props.autoMargin}
          checked={this.props.checked}
          disabled={this.props.disabled}
          hovered={this.state.hover}
          inverted={this.props.inverted}
          onClick={noop}
          size={this.props.size}
          tabIndex={this.props.tabIndex}
          testId={this.props.testId}
        />
        {this.getLabel("right")}
      </div>
    )
  }
}
