// @flow
import { compact } from "lodash"
import * as React from "react"
import absurd from "helpers/absurd"
import * as M from "helpers/marshall"
import * as Assoc from "platform/models/association"
import * as Model from "platform/models/model"
import * as Record from "platform/models/record"
import * as State from "platform/models/state"
import * as Store from "platform/store"

type Props = {|
  assoc: Assoc.Schema,
  value: mixed,
|}

export default function AssocValue({ assoc, value }: Props): React.Node {
  if (value == null) {
    return null
  }

  const [state] = Store.useContext()
  const model = State.modelById(state, assoc.subjectId)
  if (model == null) {
    return null
  }

  const pkey = Model.primaryKey(model)
  if (pkey == null) {
    return null
  }

  switch (assoc.type) {
    case "has_one": {
      const record = State.recordById(state, M.number(value).toString())

      return record != null ? Record.labelByKey(record, pkey) : null
    }
    case "many_to_many": {
      const records = compact(
        M.array(value)
          .map((v) => M.number(v))
          .map((n) => State.recordById(state, n.toString()))
      )
      const labels = records.map((r) => Record.labelByKey(r, pkey))

      return labels.sort().join(", ")
    }
    default:
      // $FlowFixMe type checking this route is a waste of time
      return absurd(assoc.type)
  }
}
