/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import styles from "./styles.module.scss"

export default class SimpleLayout extends React.PureComponent {
  render() {
    return <div className={styles.simpleLayout}>{this.props.children}</div>
  }
}

SimpleLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired,
}
