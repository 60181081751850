/* @flow */

import * as React from "react"
import Loader from "components/Loader"
import styles from "./styles.module.scss"

const LoadingState = (): React.Element<"div"> => (
  <div className={styles.loaderContainer}>
    <Loader size="l" />
  </div>
)

export default LoadingState
