// @flow

import * as M from "helpers/marshall"

export type InProgressFormValues = {
  +[customKeys: string]: mixed,
}

export type Schema = {|
  +formTemplateId: string,
  +id: string,
|}

const DEFAULTS = {
  id: "",
  formTemplateId: "",
}

export function create(r: $Shape<Schema> = {}): Schema {
  return {
    ...DEFAULTS,
    ...r,
  }
}

export function fromJson(data: mixed): Schema {
  const m = M.object(data)

  return {
    id: M.string(m.id),
    formTemplateId: M.number(m.form_template_id).toString(),
  }
}
