/* @flow */
import * as React from "react"
import _ from "lodash"
import cn from "classnames"
import RadioButton from "components/Radio/RadioButton"
import Text from "components/Text"
import Icon from "components/Icon"
import styles from "./styles.module.scss"

type PropType = {
  featureItems: Array<string>,
  imageSrc: string,
  onClick: (event: { +target: { value: boolean } }) => void,
  selected: boolean,
  subtitle: string,
  title: string,
  value: boolean,
}

export default class ModalSelectOption extends React.PureComponent<PropType> {
  onClick: (event: { +target: { value: boolean, ... }, ... }) => void = (event: { +target: { value: boolean } }) => {
    event.target.value = this.props.value
    this.props.onClick(event)
  }

  getFeatureItem(item: string): React.Element<"div"> {
    return (
      <div className={styles.ModalSelectOptionFeatureItem} key={item}>
        <Icon color="success" size="s" type="check-circle-outline" />
        <span className={styles.ModalSelectOptionFeatureItemText}>
          <Text align="center" color="lightest" type="tiny">
            {item}
          </Text>
        </span>
      </div>
    )
  }

  render(): React.Element<"div"> {
    return (
      <div
        className={cn(styles.ModalSelectBox, { [styles.selected]: this.props.selected })}
        onClick={this.onClick}
        onKeyUp={_.noop}
        role="button"
        tabIndex={0}
      >
        <div className={styles.ModalSelectOptionBody}>
          <div className={styles.ModalSelectOptionImageWrapper}>
            <img alt={this.props.imageSrc} src={this.props.imageSrc} />
          </div>
          <div className={styles.ModalSelectOptionTitle}>
            <Text align="center" color="default" type="formHeader">
              {this.props.title}
            </Text>
          </div>
          <div className={styles.ModalSelectOptionSubtitle}>
            <Text align="center" color="lightest" type="small">
              {this.props.subtitle}
            </Text>
          </div>
          <hr className={styles.ModalSelectOptionHr} />
          <div className={styles.ModalSelectOptionFeatureList}>
            {this.props.featureItems.map((item) => this.getFeatureItem(item))}
          </div>
          <div className={styles.ModalSelectOptionCheckbox}>
            <RadioButton selected={this.props.selected} value={this.props.value} />
          </div>
        </div>
      </div>
    )
  }
}
