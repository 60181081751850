/* @flow */

import * as React from "react"
import cn from "classnames"
import styles from "./styles.module.scss"

export type MarginType =
  | null
  | "paragraph"
  | "left"
  | "right"
  | "l025"
  | "r025"
  | "lr025"
  | "lr05"
  | "l05"
  | "r05"
  | "l1"
  | "r1"
  | "lr1"
export type AlignType = null | "left" | "center" | "right"

type RequiredProps = {||}
export type Props = {|
  ...RequiredProps,
  align: AlignType,
  animateColor: boolean,
  bold: boolean,
  children: ?React.Node,
  color:
    | "default"
    | "danger"
    | "warning"
    | "overnight"
    | "success"
    | "text"
    | "light"
    | "lighter"
    | "lightest"
    | "white"
    | "black"
    | "primary"
    | "invisible"
    | "charcoal"
    | "primary_dark"
    | string,
  customColor: ?string,
  decoration: null | "line-through" | "underline",
  italicised: boolean,
  margin: MarginType,
  noWrap: boolean,
  padding: ?string,
  textOverflow: null | "ellipsis",
  tightLineHeight: boolean,
  type:
    | "inherit"
    | "groupHeader"
    | "pageHeader"
    | "formHeader"
    | "modalHeader"
    | "large"
    | "body"
    | "small"
    | "tiny"
    | "smallest"
    | "displaySmallest"
    | "printSmallest"
    | "labelTiny"
    | "labelHeader"
    | "statusLabel",
  uppercase: boolean,
|}

export const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  customColor: null,
  type: "body",
  color: "default",
  textOverflow: null,
  noWrap: false,
  tightLineHeight: false,
  margin: null,
  align: null,
  decoration: null,
  bold: false,
  italicised: false,
  uppercase: false,
  padding: null,
  children: null,
  animateColor: false,
}

export default class Text extends React.PureComponent<Props> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  render(): React.Element<"div"> {
    const child_is_string = typeof this.props.children === "string" || this.props.children instanceof String
    return (
      <div
        className={cn(
          styles[this.props.type],
          styles[this.props.color],
          this.props.margin ? styles[`margin-${this.props.margin}`] : null,
          {
            [styles.uppercase]: this.props.uppercase,
            [styles.bold]: this.props.bold,
            [styles.italicised]: this.props.italicised,
            [styles[(this.props.align || "") + "Align"]]: this.props.align != null,
            [styles.ellipsis]: this.props.textOverflow === "ellipsis",
            [styles.animateColor]: this.props.animateColor,
            [styles.tightLineHeight]: this.props.tightLineHeight,
          }
        )}
        style={{
          color: this.props.customColor ? this.props.customColor : null,
          padding: this.props.padding ? this.props.padding : null,
          whiteSpace: this.props.noWrap ? "nowrap" : null,
          textDecoration: this.props.decoration,
        }}
        title={child_is_string && this.props.textOverflow === "ellipsis" ? this.props.children : null}
      >
        {this.props.children}
      </div>
    )
  }
}
