/**
 * @flow
 */

/**
 * takes a number and rounds to the nearest whole number half away from zero
 * @param {number} value of the currency figure
 * @param {number} precision i.e. number of decimal places
 *
 * note: this function exists because Math.round rounds half towards +∞:
 * Math.round(-1.5) === -1
 *
 * this is what we want:
 * round(-1.5) === -2
 */
const round = (n: number) => Math.sign(n) * Math.round(Math.abs(n))

/**
 * takes a number and rounds according to the given precision
 * @param {number} value of the figure
 * @param {number} precision i.e. number of decimal places
 *
 * note: this function only exists because toFixed straight up does not work:
 * 1.555.toFixed(2) === "1.55"
 *
 * this function lets us do this:
 * roundWithDecimals(1.555, 2).toFixed(2) === 1.56.toFixed(2) === "1.56"
 */

export const roundWithDecimals = (value: number, precision: number): number => {
  const factor = Math.pow(10, precision)
  return round(value * factor) / factor
}

/**
 * takes a number and rounds UP to the NEXT given decimal
 * @param {number} value of the figure
 * @param {number} decimal i.e. decimal to round up to
 *
 * this function lets us do this:
 * roundToNextDecimal(1.555, 0.5) === 2
 * roundToNextDecimal(21.2, 0.5) === 21.5
 */

export const roundToNextDecimal = (value: number, decimal: number): number => {
  const remainder = value % decimal
  if (remainder > 0) {
    value = value - remainder + decimal
  }
  return value
}

/**
 * takes an array and sums the numbers in it
 */
export const sum = (arr: Array<number>): number => arr.reduce((a, b) => a + (b || 0), 0)

type FormatOptions = {|
  +decimals: number | void,
  +delimiter: string,
  +fixed_decimals: boolean,
  +prefix: string,
  +separator: string,
  +suffix: string,
|}

export function toFormattedNumber(number: number, options: $Shape<FormatOptions>): string {
  const defaultOptions: FormatOptions = {
    prefix: "",
    suffix: "",
    separator: ".",
    delimiter: ",",
    decimals: undefined,
    fixed_decimals: false,
  }

  const actualOptions = Object.assign({}, defaultOptions, options || {})

  // Separates the components of the number
  let numberString
  if (isNaN(actualOptions.decimals)) {
    // Parse to remove insignificant zeros
    numberString = number.toString()
  } else if (actualOptions.fixed_decimals) {
    // Convert to fixed
    numberString = number.toFixed(actualOptions.decimals)
  } else {
    // Convert to fixed, then parse it to remove insignificant zeros
    numberString = parseFloat(number.toFixed(actualOptions.decimals)).toString()
  }

  const numberParts = numberString.split(".")

  // Delimits the first part
  numberParts[0] = numberParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, actualOptions.delimiter)
  // Combines the two sections
  return actualOptions.prefix + numberParts.join(actualOptions.separator) + actualOptions.suffix
}

export function toHrsAndMins(number: number, hrsText?: ?string, minsText?: ?string, delimiter?: ?string): string {
  hrsText = hrsText || "hrs"
  minsText = minsText || "mins"
  delimiter = delimiter || ", "

  const hrs = Math.floor(Math.round(number) / 60)
  const mins = Math.round(number) % 60

  // Strip trailing 's' off hrs/mins text if the value is exactly 1
  if (hrs === 1) {
    hrsText = hrsText.replace(/s+$/, "")
  }
  if (mins === 1) {
    minsText = minsText.replace(/s+$/, "")
  }

  hrsText = hrs ? hrs + hrsText : ""
  minsText = mins ? mins + minsText : ""

  // If hours and minutes are > 0 return both joined by delimiter
  // Otherwise return hours or minutes text as appropriate
  if (hrsText && minsText) {
    return hrsText + delimiter + minsText
  }

  return hrsText || minsText
}
