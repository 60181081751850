/* @flow */

import * as React from "react"

import styles from "./styles.module.scss"
import Label from "./index"

type Props = {|
  +htmlFor?: string,
  +text: React.Node,
|}

export default function InputLabel(props: Props): React.Node {
  return (
    <div className={styles.smallInputLabel}>
      <Label htmlFor={props.htmlFor} text={props.text} />
    </div>
  )
}

InputLabel.defaultProps = { htmlFor: undefined }
