// @flow

import * as React from "react"

// based on https://usehooks-ts.com/react-hook/use-local-storage
export default function useLocalStorage<T>(key: string, initialValue: T): [T, (T | ((T) => T)) => void] {
  const [state, setState] = React.useState(() => {
    try {
      const raw = localStorage.getItem(key)
      return raw ? JSON.parse(raw) : initialValue
    } catch (_) {
      return initialValue
    }
  })

  const setValue = (update: T | ((T) => T)): void => {
    try {
      // $FlowFixMe https://github.com/facebook/flow/issues/7702
      const value = typeof update === "function" ? update(state) : update
      const raw = JSON.stringify(value)
      setState(value)
      if (raw) {
        localStorage.setItem(key, raw)
      } else {
        localStorage.removeItem(key)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return [state, setValue]
}
