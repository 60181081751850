/* @flow */

import * as React from "react"
import ReactDOM from "react-dom"
import Main, { type Props } from "./main"

export function run(initialData: Props) {
  const mount = document.getElementById("WidgetAddNewWidgetMount")
  if (mount) {
    ReactDOM.unmountComponentAtNode(mount)
    ReactDOM.render(<Main {...initialData} />, mount)
  }
}
