/* @flow */
import { t as globalT } from "helpers/i18n"
import type { chartTypes } from "dashboard/live_insights/main"
import type { DataStreamData } from "../../rosters/WebpackRosters/DemandData"
import type { DepartmentWithStatsType, DepartmentType } from "../types"

const t = (key, ...args) => globalT(`js.rosters.predictive_workforce_graph.${key}`, ...args)

type EntryType = {
  chart_type: chartTypes,
  color: string,
  default: boolean,
  key: string,
  name: string,
  tip: string,
}

type GraphEntriesType = {
  actual_sales: EntryType,
  expected_sales: EntryType,
  total: EntryType,
  total_actual: EntryType,
  total_predicted: EntryType,
}

const GRAPH_ENTRIES: GraphEntriesType = {
  total: {
    name: "lines.label.total",
    key: "total",
    color: "rgba(0, 51, 163, 0.8)",
    tip: "",
    default: true,
    chart_type: "solidLine",
  },
  total_predicted: {
    name: "lines.label.total_predicted",
    key: "total_predicted",
    color: "rgba(0, 51, 163, 0.6)",
    tip: "",
    default: true,
    chart_type: "dottedLine",
  },
  total_actual: {
    name: "lines.label.total_actual",
    key: "total_actual",
    color: "rgba(255, 51, 51, 0.6)",
    tip: "",
    default: true,
    chart_type: "dottedLine",
  },
  expected_sales: {
    name: "lines.label.expected_sales",
    key: "expected_sales",
    color: "rgba(54, 183, 244, 0.4)",
    tip: "",
    default: false,
    chart_type: "solidBar",
  },
  actual_sales: {
    name: "lines.label.actual_sales",
    key: "actual_sales",
    color: "rgba(234, 14, 14, 0.5)",
    tip: "",
    default: false,
    chart_type: "solidBar",
  },
}

const TOTAL_TEAM_ID: string = "-1"
const DEFAULT_STAT_TYPE: string = "sales"
const GRAPH_INTERVAL: number = 15
const BUCKET_INTERVAL: number = 15
// Elando creates a datastream when enabled on the org. By default with how datastreams are configured, this can create confusing/redundant info in the UI.
// This stat type is used to filter/differentiate the redudant UI created by Elando.
const ELANDO_STAT_TYPE: string = "elando"

const DEFAULT_ACTIVE_DATA_STREAM: DataStreamData = {
  data_interval: "",
  data_stream_id: "",
  data_stream_name: "",
  original_stat_by_15: {},
  source: "",
  stat_by_15: {},
  stat_type: "",
}

const TOTAL_TEAM_OPTION = (): {|
  data: ?{
    color: string,
    hasProjData: boolean,
    name: string,
    short_name: string,
    team_colour?: ?string,
    ...
  },
  label: string,
  value: string,
|} => ({
  label: t(GRAPH_ENTRIES.total.name),
  value: TOTAL_TEAM_ID,
  data: {
    color: GRAPH_ENTRIES.total.color,
    name: t(GRAPH_ENTRIES.total.name),
    hasProjData: true,
    short_name: t(GRAPH_ENTRIES.total.name),
  },
})

const TOTAL_TEAM_DATA = (): DepartmentType => ({
  color: GRAPH_ENTRIES.total.color,
  id: TOTAL_TEAM_ID,
  name: t(GRAPH_ENTRIES.total.name),
  name_and_location_short_name: t(GRAPH_ENTRIES.total.name),
  short_name: t(GRAPH_ENTRIES.total.name),
  assisting_team_ids: [],
})

const DEFAULT_PROCESSED_TOTAL_TEAM_DATA: DepartmentWithStatsType = {
  color: GRAPH_ENTRIES.total.color,
  id: TOTAL_TEAM_ID,
  name: GRAPH_ENTRIES.total.name,
  name_and_location_short_name: GRAPH_ENTRIES.total.name,
  short_name: GRAPH_ENTRIES.total.name,
  stat_by_15: {},
  stat_by_15_with_assisted: {},
  assisting_team_ids: [],
}

export default {
  GRAPH_ENTRIES,
  GRAPH_INTERVAL,
  BUCKET_INTERVAL,
  TOTAL_TEAM_ID,
  TOTAL_TEAM_OPTION,
  TOTAL_TEAM_DATA,
  DEFAULT_STAT_TYPE,
  DEFAULT_PROCESSED_TOTAL_TEAM_DATA,
  DEFAULT_ACTIVE_DATA_STREAM,
  ELANDO_STAT_TYPE,
}
