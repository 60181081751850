/* @flow */
import * as React from "react"
import _ from "lodash"
import FilterSelect from "components/Select/FilterSelect"
import type { Props as FilterSelectProps } from "components/Select/FilterSelect/FilterSelect"
import { sortOptions } from "helpers/filter"
import { t as globalT } from "helpers/i18n"
import TeamGroup from "./TeamGroup"
import TeamOption from "./TeamOption"

const t = (key) => globalT(`js.generic_components.team_select.${key}`)

type LocationType = {
  address: ?string,
  id: number,
  location_abn: ?string,
  location_legal_name: ?string,
  name: string,
}

type TeamType = {
  colour: ?string,
  id: number,
  location_id: number,
  location_short_code?: string,
  name: string,
  team_short_code?: string,
}

export type RequiredProps = {|
  handleTeamFilterOnChange: (event: { +target: { value: ?number } }) => mixed,
  locations: Array<LocationType>,
  teams: Array<TeamType>,
  value: ?number,
|}

type Props = {|
  ...RequiredProps,
  color: $PropertyType<FilterSelectProps, "color">,
  disabled?: boolean,
  extra_options?: Array<FilterTeamOptionsType>,
  locationsSelectable: boolean,
  newStyle: boolean,
  only_show_these_teams?: ?Array<number>,
  placeholder?: string,
  size?: "s" | "m" | "l",
  sortOptions: (opts: Array<FilterOptionsType>) => Array<FilterOptionsType>,
  startOpen?: boolean,
|}

type StateType = {
  options: Array<FilterOptionsType>,
}

type FilterOptionsType = {
  id: number,
  label: string,
  options: ?Array<FilterTeamOptionsType>,
  value: null,
}

type FilterTeamOptionsType = {
  data: {
    color: string,
    name: string,
  },
  label: string,
  options?: ?Array<FilterTeamOptionsType>,
  value: ?number,
}

const DEFAULT_TEAM_COLOR = "#ccc"

// $FlowFixMe
const refinedSortOptions: (opts: Array<FilterOptionsType>) => Array<FilterOptionsType> = sortOptions

export default class TeamsFilter extends React.PureComponent<Props, StateType> {
  static defaultProps: $Diff<Props, RequiredProps> = {
    extra_options: undefined,
    startOpen: false,
    disabled: false,
    color: "default",
    newStyle: false,
    locationsSelectable: true,
    sortOptions: refinedSortOptions,
    only_show_these_teams: undefined,
    placeholder: undefined,
    size: "s",
  }

  mapOptions: () => Array<FilterOptionsType> = (): Array<FilterOptionsType> =>
    _.compact(
      this.props.locations.map((location: LocationType): ?FilterOptionsType => {
        const teams = this.props.teams.filter((t) => t.location_id === location.id)
        if (teams.length === 0) {
          return null
        }
        return {
          id: location.id,
          label: location.name,
          data: { selectable: this.props.locationsSelectable },
          options: teams.map((team: TeamType): FilterTeamOptionsType => this.mapToOption(team)),
          value: null,
        }
      })
    )

  mapToOption: (team: TeamType) => {|
    data: { color: string, name: string, ... },
    label: string,
    options?: ?Array<FilterTeamOptionsType>,
    value: ?number,
  |} = (team: TeamType) => ({
    data: {
      color: team.colour || DEFAULT_TEAM_COLOR,
      name: team.name,
      team_short_code: team.team_short_code,
      location_short_code: team.location_short_code,
    },
    label: team.name,
    value: team.id,
  })

  render(): React.Node {
    const only_show_these_teams = this.props.only_show_these_teams
    const options =
      only_show_these_teams == null
        ? this.props.sortOptions(this.mapOptions())
        : this.props
            .sortOptions(this.mapOptions())
            .map((fo) => ({
              ...fo,
              options:
                fo.options &&
                fo.options.filter(
                  (o: FilterTeamOptionsType) => o.value == null || _.includes(only_show_these_teams, o.value)
                ),
            }))
            .filter((fo) => fo.options != null && fo.options.length > 0)
    const all_options = [...(this.props.extra_options || []), ...options]
    return (
      <FilterSelect
        color={this.props.color}
        disabled={this.props.disabled}
        Group={TeamGroup}
        newStyle={this.props.newStyle}
        onChange={this.props.handleTeamFilterOnChange}
        Option={TeamOption}
        // $FlowFixMe this is way too messy, not easy to fix
        options={all_options}
        placeholder={this.props.placeholder == null ? t("select_a_team") : this.props.placeholder}
        size={this.props.size}
        startOpen={this.props.startOpen || false}
        tabIndex={0}
        value={this.props.value}
      />
    )
  }
}
