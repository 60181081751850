// @flow
import * as React from "react"
import Currency from "helpers/currency"
import FloatInput from "./floatInput"
import styles from "./styles.module.scss"

type Ev = SyntheticInputEvent<HTMLInputElement>

type Props = {|
  disabled: boolean,
  htmlName: string,
  onEdit: (Ev) => mixed,
  onSave: () => mixed,
  value: ?number,
|}

export default function CurrencyInput(props: Props): React.Element<"div"> {
  return (
    <div className={styles.currencyInput}>
      <span>{Currency.localSymbol()}</span>
      <FloatInput
        disabled={props.disabled}
        htmlName={props.htmlName}
        onEdit={props.onEdit}
        onSave={props.onSave}
        value={props.value}
      />
    </div>
  )
}
