// @flow
import * as React from "react"
import Icon from "components/Icon"
import testId from "helpers/testId"
import styles from "./styles.module.scss"

export default function Failure(): React.Node {
  return (
    <div className={styles.failure} data-testid={testId("failure-state")}>
      <Icon color="danger" size="xl" type="highlight-off" />
    </div>
  )
}
