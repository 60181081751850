/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import { t } from "helpers/i18n"
import * as Shift from "timesheets/models/shift"
import * as User from "timesheets/models/user"

import ControlButton from "timesheets/components/ControlButton"
import styles from "./styles.module.scss"

export default class NotesButton extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleOpenNotes = this.handleOpenNotes.bind(this)
    this.handleCloseNotes = this.handleCloseNotes.bind(this)
  }

  handleCloseNotes(count) {
    this.props.actions.shiftsMergeIn({
      id: this.props.shift.get("id"),
      shift: { notes_count: count },
    })
  }

  handleOpenNotes() {
    return new window.NoteController({
      id: this.props.shift.get("id"),
      noBind: true,
      model: "Shift",
    }).render_modal({
      onClose: this.handleCloseNotes,
    })
  }

  isActive() {
    return this.props.shift.get("notes_count") > 0
  }

  isDisabled() {
    const { shift, currentUser } = this.props
    const shiftId = shift.get("id")

    const hasMockedId = Shift.hasMockedId(shiftId)
    const cannotReadNote = !User.canReadNote(currentUser)

    return hasMockedId || cannotReadNote
  }

  render() {
    return (
      <div className={styles.button}>
        <ControlButton
          color={this.isActive() ? "primary" : "grey"}
          disabled={this.isDisabled()}
          icon={this.isActive() ? "sms" : "chat-bubble-outline"}
          onClick={this.handleOpenNotes}
          tip={t("js.timesheets.shift_card.controls.comments")}
          tipPosition={this.props.tipPosition}
        />
        {this.props.shift.get("notes_count") > 0 && (
          <div className={styles.count}>{this.props.shift.get("notes_count")}</div>
        )}
      </div>
    )
  }
}

NotesButton.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  shift: PropTypes.object.isRequired,
  tipPosition: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
}
