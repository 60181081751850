// @flow

/* eslint flowtype/no-weak-types: 0 */

import moment from "moment"
import { extend } from "lodash"

/**
 * Public API
 *
 * each helper merges the options onto a set of defaults and then
 * calls the core formatter.
 */

/**
 * creates a formatter bound to a specific format (12hr or 24hr)
 */
export const build = function (mode: "12" | "24"): {|
  business_slider: (time: string, options: Options) => any | string,
  live_feed_time: (time: string, options: Options) => any | string,
  live_insights_date_time: (time: string, options: Options) => any | string,
  roster_validation_field: (time: string, options: Options) => any | string,
  rosters_header: (time: string, options: Options) => any | string,
  rosters_time: (time: string, options: Options) => any | string,
  rosters_unavailability_popover: (time: string, options: Options) => any | string,
  short_time: (time: string, options: Options) => any | string,
  timesheets_shift_textbox: (time: string, options: Options) => any | string,
  twelve_hour_time: () => boolean,
|} {
  if (mode !== "12" && mode !== "24") {
    throw new Error("TimeFormatter mode must either be `12` or `24` hour time!")
  }

  const format = parseInt(mode, 10)

  if (format !== 12 && format !== 24) {
    throw new Error("TimeFormatter mode must either be `12` or `24` hour time!")
  }

  return {
    twelve_hour_time: function () {
      return format === 12
    },
    rosters_time: function (time: string, options: Options) {
      return scheduleTimeField(time, format, options)
    },
    rosters_header: function (time: string, options: Options) {
      return rosterHeading(time, format, options)
    },
    timesheets_shift_textbox: function (time: string, options: Options) {
      return shiftTimeField(time, format, options)
    },
    rosters_unavailability_popover: function (time: string, options: Options) {
      return scheduleUnavailabilityPopover(time, format, options)
    },
    live_feed_time: function (time: string, options: Options) {
      return liveFeedTime(time, format, options)
    },
    live_insights_date_time: function (time: string, options: Options) {
      return liveInsightsDateTime(time, format, options)
    },
    roster_validation_field: function (time: string, options: Options) {
      return rosterValidationField(time, format, options)
    },
    business_slider: function (time: string, options: Options) {
      return businessSlider(time, format, options)
    },
    short_time: function (time: string, options: Options) {
      return shortTime(time, format, options)
    },
  }
}

/**
 * formats a time for a shift on timesheets
 * @param {moment} momentTime time to format
 * @param {Immutable.Map} currentUser of the app
 * @param {Object} options any options to forward to the formatter (just in case)
 */
export const shiftTimeInput = (momentTime: moment, currentUser: any, options: Options): any | string =>
  shiftTimeField(momentTime, currentUser.get("time_format"), options)

/**
 * formats a time for a shift on the roster validation settings page
 * @param {moment} momentTime time to format
 * @param {Immutable.Map} currentUser of the app
 * @param {Object} options any options to forward to the formatter (just in case)
 */
export const rosterValidationInput = (momentTime: moment, currentUser: any, options: Options): any | string =>
  rosterValidationField(momentTime, currentUser.get("time_format"), options)

/**
 * Core Formatter
 *
 * verifies the value is a time and applies the correct formatting
 * depending on the options
 */

const scheduleTimeField = (time: TimeInput, format: 12 | 24, options: Options = {}): any | string =>
  formatTime(time, format, extend(scheduleTimeFieldDefaults(format), options))

const shiftTimeField = (time: TimeInput, format: 12 | 24, options: Options = {}): any | string =>
  formatTime(time, format, extend(shiftTimeFieldDefaults(time, format, options.overnight), options))

const rosterHeading = (time: TimeInput, format: 12 | 24, options: Options = {}): any | string =>
  formatTime(time, format, extend(rosterHeadingDefaults(), options))

const scheduleUnavailabilityPopover = (time: TimeInput, format: 12 | 24, options: Options = {}): any | string =>
  formatTime(time, format, extend(scheduleUnavailabilityPopoverDefaults(), options))

const liveFeedTime = (time: TimeInput, format: 12 | 24, options: Options = {}): any | string =>
  formatTime(time, format, extend(liveFeedTimeDefaults(), options))

const liveInsightsDateTime = (time: TimeInput, format: 12 | 24, options: Options = {}) =>
  formatTime(time, format, extend(liveInsightsDateTimeDefaults(), options))

const businessSlider = (time: TimeInput, format: 12 | 24, options: Options = {}) =>
  formatTime(time, format, extend(sliderTimeDefaults(), options))

export const shortTime = (time: TimeInput, format: 12 | 24, options: Options = {}): any | string =>
  formatTime(time, format, extend(shortTimeDefaults(), options))

const rosterValidationField = (time: TimeInput, format: 12 | 24, options: Options = {}) =>
  formatTime(time, format, extend(rosterValidationSettingDefaults(), options))

type Options = {
  callback?: (string) => any,
  format_12?: string,
  format_24?: string,
  if_null?: string,
  overnight?: boolean,
}
type TimeInput = string | Date | moment

const formatTime = (date: TimeInput, format: 12 | 24, { if_null, format_12, format_24, callback }) => {
  // `date` could be anything, doesn't have to be something that returns a valid moment
  // therefore we turn off Deprecation Warnings here, as we don't wait noisy logs if
  // someone calls `moment("asdf")` - the call to `isValid()` will handle it.
  // ps. flow isn't aware of moment.suppressDeprecationWarnings
  // $FlowFixMe
  moment.suppressDeprecationWarnings = true
  if (!date || !moment(date).isValid()) {
    return if_null || ""
  }
  // $FlowFixMe
  moment.suppressDeprecationWarnings = false

  const output = moment(date).format(format === 12 ? format_12 : format_24)

  return callback ? callback(output) : output
}

export const Core = {
  liveFeedTime,
  scheduleUnavailabilityPopover,
  rosterHeading,
  shiftTimeField,
  scheduleTimeField,
  shortTime,
}

/**
 * Formatter Defaults
 *
 * these functions return a default set of options for there corresponding
 * time formatters
 */

const scheduleTimeFieldDefaults = (format) => ({
  format_12: "h:mma",
  format_24: "HH:mm",
  callback: (output) => (format === 12 ? output.replace(/m$/, "") : output),
})

const rosterHeadingDefaults = () => ({
  format_12: "ha",
  format_24: "HH:00",
})

const shiftTimeFieldDefaults = (time, format, overnight) => ({
  format_12: "h:mma",
  format_24: "HH:mm",
  overnight: false,
  callback: (output) => {
    if (overnight && format !== 12 && moment(time).hour() < 12) {
      // add AM prefix for times that are AM and overnight
      return output + " AM" // for PMs we do nothing https://github.com/TandaHQ/payaus/pull/1151#issuecomment-298790792
    }
    if (format === 12) {
      return output.replace(/m$/, "") // drop trailing 'm'
    }

    return output
  },
})

const scheduleUnavailabilityPopoverDefaults = () => ({
  format_12: "h:mm A",
  format_24: "HH:mm",
})

const liveFeedTimeDefaults = (format) => ({
  format_12: "h:mm A",
  format_24: "HH:mm",
})

const liveInsightsDateTimeDefaults = (format) => ({
  format_12: "YYYY-MM-DD h:mm A",
  format_24: "YYYY-MM-DD HH:mm",
})

const rosterValidationSettingDefaults = () => ({
  format_12: "h:mm a",
  format_24: "HH:mm",
})

const sliderTimeDefaults = () => ({
  format_12: "h a",
  format_24: "H:00",
})

const shortTimeDefaults = () => ({
  format_12: "h:mma",
  format_24: "HH:mm",
})
