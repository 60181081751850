// @flow

import * as React from "react"
import cn from "classnames"
import _ from "lodash"
import Icon, { type IconConfig, type Props as IconProps } from "components/Icon"
import styles from "./styles.module.scss"

type RequiredProps = {|
  onClick: () => mixed,
  type: $PropertyType<IconProps, "type">,
|}

type Props = {|
  ...RequiredProps,
  ...IconConfig,
  hoveredColor: $PropertyType<IconProps, "color">,
  wrapperClass?: string,
|}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = { hoveredColor: "primary", wrapperClass: "" }

export default function ClickableIcon(props: Props): React.Element<"div"> {
  const [hovered, setHovered] = React.useState(false)
  const iconProps = {
    children: props.children,
    color: props.color,
    padding: props.padding,
    size: props.size,
    testId: props.testId,
    type: props.type,
  }
  return (
    <div
      className={cn(styles.wrapper, props.wrapperClass)}
      onClick={props.onClick}
      onFocus={_.noop}
      onKeyPress={_.noop}
      onMouseLeave={() => setHovered(false)}
      onMouseOver={() => setHovered(true)}
      role="button"
      tabIndex={0}
    >
      <Icon {...iconProps} color={hovered ? props.hoveredColor : props.color} />
    </div>
  )
}

ClickableIcon.defaultProps = DEFAULT_PROPS
