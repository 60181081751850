/* @flow */

import * as React from "react"
import _ from "lodash"
import cn from "classnames"
import Icon, { type IconSize } from "components/Icon"
import { t } from "helpers/i18n"
import styles from "./styles.module.scss"

type RequiredProps = {|
  +onChange: (event: SyntheticInputEvent<HTMLInputElement>) => mixed,
  +size: "l" | "m" | "s",
  +value: string,
|}
type Props = {|
  ...RequiredProps,
  +borderStyle?: boolean,
  +contractsStyle?: boolean,
  +disabled: boolean,
  +htmlName?: ?string,
  +onBlur?: (event: SyntheticInputEvent<HTMLInputElement>) => mixed,
  +onKeyUp?: (event: SyntheticKeyboardEvent<HTMLInputElement>) => mixed,
  +placeholder: ?string,
  +setInputRef?: ?(element: HTMLInputElement | null) => void,
|}

export const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  htmlName: null,
  onBlur: _.noop,
  onKeyUp: _.noop,
  borderStyle: false,
  contractsStyle: false,
  placeholder: null,
  disabled: false,
  setInputRef: _.noop,
}

export default class Search extends React.PureComponent<Props> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  static iconSizeMap: {| [key: string]: IconSize |} = {
    l: "s",
    m: "s",
    s: "xs",
  }

  render(): React.Element<"div"> {
    return (
      <div
        className={cn(
          styles.filter,
          styles[this.props.size],
          { [styles.borderStyle]: this.props.borderStyle },
          { [styles.contractsStyle]: this.props.contractsStyle }
        )}
      >
        <div className={styles.filterIcon}>
          <Icon color="grey" size={this.constructor.iconSizeMap[this.props.size]} type="search" />
        </div>
        <input
          className={styles.filterInput}
          disabled={this.props.disabled}
          name={this.props.htmlName}
          onBlur={this.props.onBlur}
          onChange={this.props.onChange}
          onKeyUp={this.props.onKeyUp}
          placeholder={this.props.placeholder || t("js.generic_components.search")}
          ref={this.props.setInputRef}
          value={this.props.value}
        />
      </div>
    )
  }
}
