/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import _ from "underscore" // eslint-disable-line underscore-to-lodash/prefer-import-lodash

import * as Grapher from "helpers/grapher"

export const colorsForAICs = (aics) => {
  const colors = _.chain(aics).map(Grapher.awardHash).uniq().sort().object(Grapher.awardColors()).value()

  return aics.reduce(
    (aics, aic) => ({
      ...aics,
      [aic.name]: aics[aic.name] || colors[Grapher.awardHash(aic)],
    }),
    {}
  )
}
