/* @flow */

import PagedModal, { ModalPage } from "./PagedModal"
import Modal from "./Modal"
import Header from "./Header"
import SmallText from "./SmallText"
import Footer, { FooterLeft, FooterRight, FooterCenter, FooterWhole } from "./Footer"
import ModalBack from "./ModalBack"
import ContextHeader from "./ContextHeader"

export default Modal

export {
  PagedModal,
  ModalPage,
  Header,
  SmallText,
  Footer,
  FooterCenter,
  FooterRight,
  FooterLeft,
  FooterWhole,
  ModalBack,
  ContextHeader,
}
