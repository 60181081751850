// @flow

import * as React from "react"
import _ from "lodash"
import InputLabel from "components/Label/InputLabel"
import DefaultGroup from "components/DropdownList/DefaultGroup/index"
import DefaultOption from "components/DropdownList/DefaultOption/index"
import * as OptionHelpers from "components/DropdownList/helpers/option"
import styles from "./base.scss"

import BaseSelect from "./BaseSelect"

// eslint-disable-next-line flowtype/no-weak-types
export type Value = any

type InputEvent = SyntheticInputEvent<HTMLElement>

export type SelectEvent = {
  ...InputEvent,
  target: {
    ...$PropertyType<InputEvent, "target">,
    name: ?string,
    value: Value,
  },
}

export type SimpleOptionType = $ReadOnly<{
  // eslint-disable-next-line flowtype/no-weak-types
  data?: any,
  icon?: ?string,
  label: string,
  value: Value,
  ...
}>

export type Option = $ReadOnly<{
  ...SimpleOptionType,
  options?: $ReadOnlyArray<SimpleOptionType>,
}>

export type Options = $ReadOnlyArray<Option>

export type RequiredProps = {|
  // I was getting the weirdest error message from using mixed here, could not solve it, so now its any
  // eslint-disable-next-line flowtype/no-weak-types
  onChange: (e: SelectEvent) => any,
  options: Options,
|}

export type Props = {|
  ...RequiredProps,
  // I was getting the weirdest error message from using mixed here, could not solve it, so now its any
  // eslint-disable-next-line flowtype/no-weak-types
  handleClose: () => any,
  // eslint-disable-next-line flowtype/sort-keys
  color: string,
  customButton?: React.Node,
  disabled: boolean,
  forceOpen: ?boolean,
  // eslint-disable-next-line flowtype/no-weak-types
  Group: React.ComponentType<any>,
  htmlName: ?string,
  icon: ?string,
  iconCustomColor: ?string,
  label: ?string,
  listAutoWidth: boolean,
  newStyle: boolean,
  // eslint-disable-next-line flowtype/no-weak-types
  Option: React.ComponentType<any>,
  padding: ?string,
  placeholder: ?string,
  size: "s" | "m" | "l",
  tabIndex: number,
  testId: ?string,
  value: Value,
|}

export const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  color: "default",
  customButton: null,
  disabled: false,
  Group: DefaultGroup,
  htmlName: undefined,
  padding: null,
  label: undefined,
  Option: DefaultOption,
  size: "s",
  iconCustomColor: null,
  value: null,
  placeholder: null,
  tabIndex: -1,
  testId: null,
  forceOpen: null,
  icon: null,
  listAutoWidth: false,
  newStyle: false,
  handleClose: _.noop,
}

const INITIAL_STATE = {
  focusedValue: undefined,
  isOpen: false,
}

type State = {|
  focusedValue: ?Value,
  isOpen: boolean,
|}

export default class Select extends React.PureComponent<Props, State> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  constructor(props: Props) {
    super(props)
    this.state = INITIAL_STATE
  }

  // eslint-disable-next-line flowtype/no-weak-types
  handleFocusNextOption: any = OptionHelpers.offsetFocusedValue(1)
  // eslint-disable-next-line flowtype/no-weak-types
  handleFocusPrevOption: any = OptionHelpers.offsetFocusedValue(-1)

  offsetFocusedValue: (offset: number) => void = (offset: number) => {
    const focusables = OptionHelpers.flatten(
      // since the list may contain groups and
      this.props.options // these are not focusable we
    ) // should flatten to purely options.

    const i = offset + focusables.findIndex((option) => option.value === this.state.focusedValue)

    const safeIndex = i < 0 ? focusables.length - 1 : i >= focusables.length ? 0 : i

    this.setState({ focusedValue: focusables[safeIndex].value })
  }

  handleKeyDown: (event: SyntheticKeyboardEvent<HTMLInputElement>) => void = (
    event: SyntheticKeyboardEvent<HTMLInputElement>
  ) => {
    switch (event.keyCode) {
      case 13: // enter
        event.preventDefault()
        if (this.state.isOpen) {
          this.setState(INITIAL_STATE)
          // $FlowFixMe onChange expects a InputEvent not a KeyboardEvent, but it should be fine
          this.props.onChange({
            ...event,
            // $FlowFixMe onChange expects a InputEvent not a KeyboardEvent, but it should be fine
            target: {
              ...event.target,
              name: this.props.htmlName || "",
              value: this.state.focusedValue,
            },
          })
        }
        break
      case 38: // up
        event.preventDefault()
        return this.setState({
          focusedValue: this.handleFocusPrevOption(this.props.options, this.state.focusedValue),
        })
      case 40: // down
        event.preventDefault()
        return this.setState({
          focusedValue: this.handleFocusNextOption(this.props.options, this.state.focusedValue),
        })
    }
  }

  handleOptionClick: (event: SelectEvent) => void = (event: SelectEvent) => {
    if (this.state.isOpen) {
      this.setState(INITIAL_STATE)
      const newEvent: SelectEvent = {
        ...event,
        target: {
          ...event.target,
          value: event.target.value,
          name: this.props.htmlName || event.target.name,
        },
      }
      this.props.onChange(newEvent)
    }
  }

  handleClose: () => void = () => {
    this.props.handleClose()
    if (this.state.isOpen) {
      this.setState(INITIAL_STATE)
    }
  }

  handleClick: () => void = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleOptionEnter: (event: SelectEvent) => void = (event: SelectEvent) => {
    this.setState({ focusedValue: event.target.value })
  }

  handleOptionLeave: (event: SelectEvent) => void = (event: SelectEvent) => {
    this.setState({ focusedValue: undefined })
  }

  renderInput: () => React.Node = () => {
    const { Option, options, placeholder, value } = this.props

    const option = OptionHelpers.find(options, value)
    const selected = option || { label: placeholder }
    return (
      <Option
        data={selected.data}
        icon={selected.icon || this.props.icon}
        iconCustomColor={this.props.iconCustomColor}
        label={selected.label}
        newStyle={this.props.newStyle}
        noOverflow
        size={this.props.size}
      />
    )
  }

  render(): React.Element<"div"> {
    return (
      <div className={styles.labelContainer} style={{ padding: this.props.padding }}>
        {this.props.label != null && <InputLabel text={this.props.label} />}
        <BaseSelect
          color={this.props.color}
          customButton={this.props.customButton}
          disabled={this.props.disabled}
          focusedValue={this.state.focusedValue}
          Group={this.props.Group}
          input={this.renderInput()}
          listAutoWidth={this.props.listAutoWidth}
          newStyle={this.props.newStyle}
          onClick={this.handleClick}
          onClose={this.handleClose}
          onKeyDown={this.handleKeyDown}
          onOptionClick={this.handleOptionClick}
          onOptionEnter={this.handleOptionEnter}
          onOptionLeave={this.handleOptionLeave}
          open={this.props.forceOpen == null ? this.state.isOpen : this.props.forceOpen}
          Option={this.props.Option}
          options={this.props.options}
          selectedValues={[this.props.value]}
          size={this.props.size}
          tabIndex={this.props.tabIndex}
          testId={this.props.testId}
        />
      </div>
    )
  }
}
