// @flow
/*
 * Want to add a custom icon?
 * Get one from https://materialdesignicons.com/
 * OR
 * Make your own based on https://material.io/design/iconography/system-icons.html#grid-keyline-shapes
 */

import * as React from "react"
import cn from "classnames"
import styles from "../styles.module.scss"
import Collapse from "./Collapse"
import Percent from "./Percent"
import MarkerError from "./MarkerError"
import CreditCard from "./CreditCard"
import EmptyStateInvoices from "./EmptyStateInvoices"
import CheckCircle from "./CheckCircle"

type RequiredProps = {|
  type: string,
|}

type Props = {|
  ...RequiredProps,
  customColor?: ?string,
|}

const SVGIcon = ({ type, customColor }: Props): null | React.Element<"div"> | React.Node => {
  switch (type) {
    case "collapse":
      return <Collapse />
    case "percent":
      return <Percent />
    case "marker-error":
      return <MarkerError />
    case "credit-card":
      return <CreditCard />
    case "empty-state-invoices":
      return <EmptyStateInvoices />
    case "check-circle":
      return <CheckCircle />
    case "solidBar":
      return (
        <div className={cn(styles.customIcon, styles.barLegendContainer)}>
          <div className={styles.barLegendIconThin} style={{ backgroundColor: customColor }} />
          <div className={styles.barLegendIconThicc} style={{ backgroundColor: customColor }} />
        </div>
      )
    case "departmentSwatch":
      return (
        <div className={cn(styles.customIcon, styles.departmentSwatchIcon)} style={{ backgroundColor: customColor }} />
      )
    case "solidLine":
      return (
        <div className={cn(styles.customIcon, styles.lineWrapper)}>
          <div className={styles.solidLine} style={{ backgroundColor: customColor }} />
        </div>
      )
    case "dottedLine":
      return (
        <div className={cn(styles.customIcon, styles.lineWrapper)}>
          <div className={styles.dottedLine} style={{ backgroundColor: customColor }} />
          <div className={styles.dottedLine} style={{ backgroundColor: customColor }} />
        </div>
      )
    default:
      return null
  }
}

SVGIcon.defaultProps = {
  customColor: null,
}

export default SVGIcon
