/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { noop } from "lodash"
import { AutoHoverBubble } from "components/Bubble"
import Icon from "components/Icon"

import styles from "./styles.module.scss"

export default class ControlButton extends React.PureComponent {
  render() {
    return (
      <div
        className={cn(styles.ControlButton, styles[this.props.color], { [styles.disabled]: this.props.disabled })}
        onClick={this.props.onClick}
        onKeyPress={noop}
        role="button"
        tabIndex="-1"
      >
        <Icon color={this.props.color} type={this.props.icon} />
        {this.props.disabled ? null : (
          <AutoHoverBubble position={this.props.tipPosition}>
            <span className={styles.tip}>{this.props.tip}</span>
          </AutoHoverBubble>
        )}
      </div>
    )
  }
}

ControlButton.defaultProps = {
  color: "grey",
  disabled: false,
}

ControlButton.propTypes = {
  color: PropTypes.oneOf(["grey", "primary"]),
  disabled: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  tip: PropTypes.string.isRequired,
  tipPosition: PropTypes.oneOf(["top", "bottom", "left", "right", "auto"]).isRequired,
}
