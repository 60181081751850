// @flow

import * as React from "react"
import cn from "classnames"
import styles from "./styles.module.scss"

type Props = {
  checked?: boolean,
  defaultChecked: boolean,
  disabled?: boolean,
  name?: ?string,
  onUpdate: (e: { currentTarget: { checked: boolean, name: string } }) => mixed,
  testId?: ?string,
}

function CheckboxSwitch(props: Props): React.Element<"label"> {
  return (
    <label className={cn(styles.switch, { [styles.disabled]: props.disabled })} htmlFor={props.name}>
      <input
        checked={props.checked}
        data-testid={props.testId}
        defaultChecked={props.defaultChecked}
        disabled={props.disabled}
        id={props.name}
        name={props.name}
        onChange={props.onUpdate}
        type="checkbox"
      />
      <div className={`${styles.slider} ${styles.round}`} />
    </label>
  )
}

CheckboxSwitch.defaultProps = { checked: undefined, disabled: false, name: null, testId: null }

export default CheckboxSwitch
