/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import { fromJS, Map } from "immutable"
import { createReducer, createActions } from "redux-em"

const leaveRequestInit = () => new Map()

const leaveRequestSet = (state, data) => fromJS(data)

const leaveRequestMerge = (state, leaveRequest) => state.merge(leaveRequest)

/**
 * After we action a fallback leave actionable, remove it from
 * the store.
 * @param {Immutable.Map} unactionedFallbackLeaves
 * @param {number} $0.id of the leave request to remove
 * @param {Object} $0.newBalances updated leave balances for this user
 * @return {Immutable.Map} next state of fallback leave store
 */
const leaveRequestSplit = (state, { id, userId, newBalances }) => {
  // Remove any fallback leaves that have a new balance which is non-negative and are
  // owned by the same user who just had their fallback leave split.
  const idsToDelete = state.filter((lr, key) => lr.get("user_id") === userId && newBalances[lr.get("leave_type")] >= 0)

  Object.keys(idsToDelete.toObject()).forEach((key) => {
    state = state.delete(key)
  })

  return state
}

export const Core = {
  leaveRequestInit,
  leaveRequestSet,
  leaveRequestMerge,
  leaveRequestSplit,
}

export const leaveRequestActions = createActions(Core)

export const leaveRequestReducer = createReducer(Core)
