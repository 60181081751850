/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import cn from "classnames"
import PropTypes from "prop-types"
import I18n, { t } from "helpers/i18n"
import { s3ImagePath } from "helpers/image"
import Icon from "components/Icon"
import SuccessTick from "components/SuccessTick"
import Currency from "helpers/currency"
import { trimWhite } from "helpers/string"
import Button from "components/Button"
import { computePriceToCharge } from "../../../helpers/logic"
import styles from "./styles.module.scss"

export default class PlanDetail extends React.PureComponent {
  renderReceiptText() {
    if (!this.props.usesChargebee && this.props.annualBilling) {
      return <p>{t("js.billing.plan_detail.confirmation.receipt_invoice")}</p>
    }
    return <p>{t("js.billing.plan_detail.confirmation.receipt_cc")}</p>
  }

  isInteger(value) {
    if (Number.isInteger) {
      return Number.isInteger(value)
    } else {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isInteger
      return typeof value === "number" && Number.isFinite(value) && Math.floor(value) === value
    }
  }

  formatPrice(price) {
    const decimals = this.isInteger(price) ? 0 : 2
    return Currency.from(price, decimals, this.props.prices.currencySymbol)
  }

  formattedEmployeePrice() {
    return this.formatPrice(this.props.annualBilling ? this.props.prices.annual : this.props.prices.period)
  }

  formattedTotalPrice() {
    return this.formatPrice(
      computePriceToCharge({
        annualBilling: this.props.annualBilling,
        employeeCount: this.props.employeeCount,
        prices: this.props.prices,
        country: this.props.country,
      })
    )
  }

  minimumAuMonthlyPrice() {
    const minEmployeeCount = this.props.country === "Australia" ? this.props.prices.minMonthlyEmployeeCount : 0
    return this.props.prices.period * Math.max(this.props.employeeCount, minEmployeeCount)
  }

  minimumAuAnnualPrice() {
    const minEmployeeCount = this.props.country === "Australia" ? this.props.prices.minAnnualEmployeeCount : 0
    return this.props.prices.annual * Math.max(this.props.employeeCount, minEmployeeCount)
  }

  formattedAnnualSavings() {
    const minMonthlyPrice = this.minimumAuMonthlyPrice()
    const minAnnualPrice = this.minimumAuAnnualPrice()
    const diff = minMonthlyPrice - minAnnualPrice
    return this.formatPrice(diff * 12)
  }

  tipText() {
    return t("js.billing.plan_detail.swith_to_annual_tip", {
      annual: this.formatPrice(this.minimumAuAnnualPrice() * 12),
      monthly: this.formatPrice(this.minimumAuMonthlyPrice() * 12),
      saving: this.formattedAnnualSavings(),
    })
  }

  renderPlanType() {
    return (
      <div className={styles.planType}>
        <div className={styles.heading}>
          <div className={styles.headingText}>{t("js.billing.plan_detail.heading.plan")}</div>
        </div>
        <div className={styles.amount}>
          <div className={styles.price}>
            <div className={styles.number}>{this.formattedEmployeePrice()}</div>
            {this.props.prices.currencySuffix ? (
              <div className={styles.currency}>{this.props.prices.currencySuffix}</div>
            ) : null}
          </div>
          <div className={styles.rate}>
            <div className="perMonth">{t("js.billing.plan_detail.per_month")}</div>
            <div className="perEmployee">{t("js.billing.plan_detail.per_employee")}</div>
          </div>
        </div>
      </div>
    )
  }

  renderSavingsSection() {
    // don't show anything for Aus customers under min billing who choose annual
    if (
      this.props.country === "Australia" &&
      this.props.annualBilling &&
      this.props.employeeCount < this.props.prices.minMonthlyEmployeeCount
    ) {
      return null
    } else {
      // show the savings container for everyone else
      return (
        <div className={styles.savings}>
          <div className={styles.heading}>
            <div className={styles.headingText}>{t("js.billing.plan_detail.heading.savings")}</div>
          </div>
          {this.renderSavings()}
        </div>
      )
    }
  }

  renderSavings() {
    if (!this.props.annualBilling && this.props.enableAnnualBilling) {
      return (
        <Button
          label={t("js.billing.plan_detail.switch_to_annual", { saving: this.formattedAnnualSavings() })}
          onClick={this.props.enableAnnualBilling}
          tooltip={{
            content: this.tipText(),
            position: "top",
            width: "large",
          }}
          type="theme"
        />
      )
    } else {
      return (
        <div className={styles.amount}>
          <div className={styles.price}>
            <div className={styles.number} id={"annualSaving"}>
              {this.formattedAnnualSavings()}
            </div>
          </div>
          <div className={styles.rate}>{t("js.billing.plan_detail.per_year")}</div>
        </div>
      )
    }
  }
  // per_month_or_quarter is used below to separate it from per_month used above
  // So we can change the translations for one without the other
  renderTotalBilled() {
    return (
      <div className={styles.totalBilled}>
        <div className={styles.heading}>
          <div className={styles.headingText}>
            <div className={styles.total}>{t("js.billing.plan_detail.heading.total")}</div>
            <div className={styles.employeeCount}>
              {t("js.billing.plan_detail.heading.employee_count", { count: this.props.employeeCount })}
            </div>
          </div>
        </div>
        <div className={styles.amount}>
          <div className={styles.price}>
            <div className={styles.number}>{this.formattedTotalPrice()}</div>
            {this.props.prices.currencySuffix ? (
              <div className={styles.currency}>{this.props.prices.currencySuffix}</div>
            ) : null}
          </div>
          <div className={styles.rate}>
            {this.props.annualBilling
              ? t("js.billing.plan_detail.per_year")
              : t("js.billing.plan_detail.per_month_or_quarter")}
          </div>
        </div>
        {this.renderDisclaimer()}
        {this.renderContactUs()}
      </div>
    )
  }

  renderDisclaimer() {
    if (this.props.country === "Australia") {
      return (
        <div className={styles.disclaimerContainer}>
          {!this.props.onConfirmationPage &&
            !this.props.annualBilling &&
            this.props.employeeCount <= this.props.prices.minMonthlyEmployeeCount && (
              <div className="disclaimer">{t("js.billing.plan_detail.switch_to_annual_note")}</div>
            )}
          <div className="disclaimer">{t("js.billing.plan_detail.gst")}</div>
        </div>
      )
    }
    return null
  }

  termsAndConditionsLink() {
    if (I18n.useWorkforce) {
      return "https://workforce.com/terms/"
    } else {
      return "https://www.tanda.co/terms-conditions/"
    }
  }

  privacyPolicyLink() {
    if (I18n.useWorkforce) {
      return "https://workforce.com/privacy-policy/"
    } else {
      return "https://www.tanda.co/privacy/"
    }
  }

  renderContactUs() {
    return (
      <div className={styles.questions}>
        <div className={styles.contact}>
          <Icon color="grey" type="mail-outline" />
          <p>{t("tanda.support_email")}</p>
        </div>
        {this.canDisplayPhoneNumber() && (
          <div className={styles.contact}>
            <Icon color="grey" type="phone" />
            <p>{t("tanda.phone_number")}</p>
          </div>
        )}
        <div className={styles.contact}>
          <p>
            <a href={this.termsAndConditionsLink()} target="_blank noopener noreferrer">
              {t("js.billing.plan_detail.terms")}
            </a>{" "}
            {t("js.billing.plan_detail.and")}{" "}
            <a href={this.privacyPolicyLink()} target="_blank noopener noreferrer">
              {t("js.billing.plan_detail.privacy")}
            </a>
          </p>
        </div>
      </div>
    )
  }

  canDisplayPhoneNumber() {
    return !!trimWhite(t("tanda.phone_number"))
  }

  renderContactEnterprise() {
    // this only gets rendered if their number of staff is an absurd amount
    // amounts TBC. for v1 this doesn't need to be rendered - moreso useful for when the slider is implemented
    return (
      <div className={styles.contactUsContainer}>
        <div className={styles.imageContainer}>
          <img alt="person" src={s3ImagePath("support_person.png")} />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.header}>
            <h3>{t("js.billing.plan_detail.contact_us")}</h3>
            <div className={styles.contactDetails}>
              <div className={styles.contactType}>
                <Icon color="grey" size="m" type="mail-outline" />
                <div className={styles.contactItem}>{t("js.billing.plan_detail.support_email")}</div>
              </div>
              <div className={styles.contactType}>
                <Icon color="grey" size="m" type="phone" />
                <div className={styles.contactItem}>{t("js.billing.plan_detail.phone")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderSuccessfuNotification() {
    // only renders on /billing/confirmation
    return (
      <div className={styles.successfulNotification}>
        <SuccessTick delay={600} size="l" />
        {/* <img className={styles.check} alt="successful check" src={s3ImagePath("email-check-128px.png")} /> */}
        <h4>{t("js.billing.plan_detail.confirmation.upgraded")}</h4>
      </div>
    )
  }

  renderReceipt() {
    // only renders on /billing/confirmation
    return (
      <div className={styles.receipt}>
        <Icon color="grey" size="l" type="mail-outline" />
        <div className={styles.receiptText}>{this.renderReceiptText()}</div>
      </div>
    )
  }

  render() {
    if (!this.props.newCustomer) {
      return null
    }

    return (
      <div className={cn(styles.planDetail, { [styles.confirmation]: this.props.onConfirmationPage })}>
        {this.props.onConfirmationPage ? this.renderSuccessfuNotification() : null}

        <div className={styles.container}>{this.renderPlanType()}</div>

        {!this.props.onConfirmationPage ? <div className={styles.container}>{this.renderSavingsSection()}</div> : null}

        <div className={styles.container}>{this.renderTotalBilled()}</div>

        {/* {this.renderContactEnterprise()} */}

        {this.props.onConfirmationPage ? this.renderReceipt() : null}
      </div>
    )
  }
}

PlanDetail.propTypes = {
  newCustomer: PropTypes.bool.isRequired,
  annualBilling: PropTypes.bool.isRequired,
  usesChargebee: PropTypes.bool,
  country: PropTypes.string.isRequired,
  employeeCount: PropTypes.number.isRequired,
  prices: PropTypes.shape({
    monthly: PropTypes.number.isRequired,
    period: PropTypes.number.isRequired,
    annual: PropTypes.number.isRequired,
    currencySuffix: PropTypes.string,
    currencySymbol: PropTypes.string.isRequired,
    minMonthlyEmployeeCount: PropTypes.number.isRequired,
    minAnnualEmployeeCount: PropTypes.number.isRequired,
  }).isRequired,
  enableAnnualBilling: PropTypes.func,
  onConfirmationPage: PropTypes.bool.isRequired,
}

PlanDetail.defaultProps = { usesChargebee: true, enableAnnualBilling: null }
