/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import Loader from "components/Loader"
import styles from "./styles.module.scss"

export default class LoadingState extends React.PureComponent {
  renderMessage() {
    if (!this.props.messageText) {
      return null
    }
    return this.props.messageText
  }

  render() {
    return (
      <div className={styles.State}>
        <div className={styles.LoadingState}>
          <div className={styles.StateContainer}>
            <Loader size="xl" />
          </div>
          <div className={styles.MessageContainer}>
            {<span className={styles.Message}>{this.renderMessage()}</span>}
          </div>
        </div>
      </div>
    )
  }
}

LoadingState.propTypes = { messageText: PropTypes.string }
LoadingState.defaultProps = { messageText: "" }
