/* @flow */

import * as React from "react"
import { t as globalT } from "helpers/i18n"
import Icon from "../Icon"
import styles from "./styles.module.scss"

const t = (key, props) => globalT(`js.generic_components.modal.${key}`, props)

export default function ModalClose(props: { onClick: () => void }): React.Element<"button"> {
  return (
    <button
      aria-label={t("exit_aria")}
      className={styles.modal_exit}
      onClick={props.onClick}
      tabIndex={0}
      type="button"
    >
      <Icon color="grey" type="close" />
    </button>
  )
}
