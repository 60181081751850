// @flow
import * as React from "react"
import { noop } from "lodash"

import BaseSelect from "components/Select/BaseSelect"

import DefaultGroup from "components/DropdownList/DefaultGroup/index"
import DefaultOption from "components/DropdownList/DefaultOption/index"

import FilterInput from "components/Select/FilterInput"

import * as OptionHelpers from "components/DropdownList/helpers/option"
import type { Options, SelectEvent } from "components/Select"
import { type Props as DropdownListProps } from "components/DropdownList"
import defaultFilter, { type FilterOptionFunction } from "helpers/filter"

export type RequiredProps = {|
  onChange: (e: SelectEvent) => mixed,
  options: $PropertyType<DropdownListProps, "options">,
|}

export type Props = {|
  ...RequiredProps,
  color: "success" | "error" | "warning" | "default" | "white",
  disabled: boolean,
  filter: FilterOptionFunction,
  forceShowTip: boolean,
  Group: $PropertyType<DropdownListProps, "Group">,
  handleLargeDataset: boolean,
  highlightColour?: ?"success" | "error" | "warning" | "default" | "white" | "primary",
  htmlName: ?string,
  icon: ?string,
  keepOpen?: boolean,
  listAutoWidth: boolean,
  newStyle: boolean,
  onClose: () => mixed,
  onInput: (e: { +target: { value: string } }) => mixed,
  Option: $PropertyType<DropdownListProps, "Option">,
  placeholder: ?(string | { label: string }),
  size: "s" | "m" | "l",
  startOpen: boolean,
  tabIndex: number,
  testId: ?string,
  tipText: ?string,
  value: ?number | string,
  width: string,
|}

export const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  tipText: null,
  forceShowTip: false,
  keepOpen: false,
  Group: DefaultGroup,
  Option: DefaultOption,
  filter: defaultFilter,
  onInput: noop,
  width: "auto",
  color: "default",
  tabIndex: 0,
  value: null,
  newStyle: false,
  handleLargeDataset: false,
  size: "s",
  icon: null,
  disabled: false,
  startOpen: false,
  onClose: noop,
  placeholder: null,
  highlightColour: null,
  htmlName: undefined,
  testId: null,
  listAutoWidth: false,
}

type State = {|
  filter: string,
  filteredOptions: Options,
  focusedValue: ?(number | string),
  hovered: boolean,
  isOpen: boolean,
|}

const DEFAULT_STATE: State = {
  isOpen: false,
  filter: "",
  focusedValue: undefined,
  hovered: false,
  filteredOptions: [],
}

export default class BaseFilterSelect extends React.Component<Props, State> {
  input: ?HTMLInputElement

  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  constructor(props: Props) {
    super(props)

    this.state = {
      ...DEFAULT_STATE,
      isOpen: props.startOpen,
      filteredOptions: props.options,
    }
  }

  componentDidMount: () => void = () => {
    if (this.props.startOpen) {
      this.focusInput()
    }
  }

  UNSAFE_componentWillReceiveProps: (Props) => void = ({ options }: Props) => {
    if (options === this.props.options) {
      return
    }

    if (this.state.filter) {
      this.filterUpdate({ target: { value: this.state.filter } })
    } else {
      this.setState({ filteredOptions: options })
    }
  }

  // eslint-disable-next-line flowtype/no-weak-types
  handleFocusNextOption: any = OptionHelpers.offsetFocusedValue(1)
  // eslint-disable-next-line flowtype/no-weak-types
  handleFocusPrevOption: any = OptionHelpers.offsetFocusedValue(-1)

  focusInput: () => void = () => {
    // We wait a little moment as sometimes the input won't focus.
    setTimeout(() => {
      this.input && this.input.focus()
    }, 120)

    // We attempt to focus a second time as sometimes 120ms is too little
    setTimeout(() => {
      this.input && this.input.focus()
    }, 350)
  }

  handleChange: (event: SelectEvent) => void = (event: SelectEvent) => {
    this.props.onChange({
      ...event,
      target: {
        ...event.target,
        name: this.props.htmlName,
      },
    })
    !(this.props.keepOpen && event.target.value != null) && this.handleClose()
  }

  handleClick: () => void = () => {
    // ignore the open if the filter is a stub (used inside reports#index filters)
    if (this.props.options[0] !== undefined && this.props.options[0].value === "fs-loading") {
      return
    }
    if (!this.props.disabled && !this.state.isOpen) {
      this.setState({ isOpen: true, focusedValue: this.props.value }, () => this.focusInput())
    }
  }

  handleClose: () => void = () => {
    this.setState({
      ...DEFAULT_STATE,
      filteredOptions: this.props.options,
    })
    this.props.onClose()
  }

  handleKeyDown: (event: SyntheticKeyboardEvent<HTMLInputElement>) => void = (
    event: SyntheticKeyboardEvent<HTMLInputElement>
  ) => {
    switch (event.keyCode) {
      case 13: // enter
        event.preventDefault()
        if (this.state.focusedValue !== undefined) {
          // $FlowFixMe coersion between keyboard event and input event but it should be okay
          const newEvent: SelectEvent = {
            ...event,
            // $FlowFixMe coersion between keyboard event and input event but it should be okay
            target: {
              ...event.target,
              value: this.state.focusedValue,
            },
          }
          this.handleChange(newEvent)
        }
        break
      case 38: // up
        event.preventDefault()
        return this.setState({
          focusedValue: this.handleFocusPrevOption(this.state.filteredOptions, this.state.focusedValue),
        })
      case 40: // down
        event.preventDefault()
        return this.setState({
          focusedValue: this.handleFocusNextOption(this.state.filteredOptions, this.state.focusedValue),
        })
    }
  }

  handleOptionLeave: () => void = () => {
    this.setState({ focusedValue: undefined })
  }

  handleOptionEnter: (event: SelectEvent) => void = (event: SelectEvent) => {
    this.setState({ focusedValue: event.target.value })
  }

  handleInputChange: (event: { +target: { value: string, ... }, ... }) => void = (event: {
    +target: { value: string },
  }) => {
    this.filterUpdate(event)
    this.props.onInput(event)
  }

  handleNewOptions: (filteredOptions: Options) => void = (filteredOptions: Options) => {
    const focusables = OptionHelpers.flatten(
      // since the list may contain groups and
      filteredOptions // these are not focusable we should
    ) // flatten to purely just options.

    if (!focusables.length) {
      return this.setState({
        focusedValue: undefined,
        filteredOptions: [{ label: "No Matches", value: null }],
      })
    }

    if (this.state.focusedValue && focusables.find((f) => f.value === this.state.focusedValue)) {
      return this.setState({ focusedValue: this.state.focusedValue, filteredOptions })
    }

    this.setState({ focusedValue: focusables[0].value, filteredOptions })
  }

  handleFilterPromiseRejected: () => void = () => {
    if (this.state.filteredOptions.length > 0) {
      // Previously we had results, so now we should blank them.
      this.handleNewOptions([])
    } else {
      // no-op. Currently have no results, let's keep it that way.
    }
  }

  filterUpdate: (event: { +target: { value: string, ... }, ... }) => void | Promise<void> = (event: {
    +target: { value: string },
  }) => {
    const newFilter = event.target.value
    this.setState({ filter: newFilter })
    const newVisibleOpts = this.props.filter(this.props.options, newFilter)
    if (newVisibleOpts instanceof Promise) {
      return newVisibleOpts.then((opts) => this.handleNewOptions(opts)).catch(() => this.handleFilterPromiseRejected())
    }
    this.handleNewOptions(newVisibleOpts)
  }

  setInputRef: (element: HTMLInputElement | null) => void = (element: HTMLInputElement | null) => {
    this.input = element
  }

  renderInput: () => React.Node = () => (
    <FilterInput
      disabled={this.props.disabled}
      onChange={this.handleInputChange}
      searchInput={this.state.filter}
      setInputRef={this.setInputRef}
      size={this.props.size}
    />
  )

  renderSelected: () => React.Node = () => {
    const { Option, options, placeholder, value } = this.props

    const option = OptionHelpers.find(options, value)
    const selected =
      option ||
      (typeof placeholder !== "string" ? (placeholder == null ? { label: "" } : placeholder) : { label: placeholder })

    return (
      <Option
        data={selected.data}
        icon={this.props.icon}
        label={selected.label}
        noOverflow
        selectedTop={option != null}
        size={this.props.size}
      />
    )
  }

  renderInputContent: () => React.Node = () => (this.state.isOpen ? this.renderInput() : this.renderSelected())

  handleLotsOfOptions(options: Options): Options {
    if (!this.props.handleLargeDataset) {
      return options
    }
    if (this.state.filter.length === 0) {
      return [{ label: "Start typing to search", value: undefined }]
    }
    if (options.length > 100 && this.state.filter.length > 0) {
      return [{ label: "Too many options to show", value: undefined }]
    }
    return options
  }

  render: () => React.Node = () => (
    <BaseSelect
      color={this.props.color}
      disabled={this.props.disabled}
      focusedValue={this.state.focusedValue}
      forceShowTip={this.props.forceShowTip}
      Group={this.props.Group}
      highlightColour={this.props.highlightColour}
      input={this.renderInputContent()}
      listAutoWidth={this.props.listAutoWidth}
      newStyle={this.props.newStyle}
      onClick={this.handleClick}
      onClose={this.handleClose}
      onKeyDown={this.handleKeyDown}
      onOptionClick={this.handleChange}
      onOptionEnter={this.handleOptionEnter}
      onOptionLeave={this.handleOptionLeave}
      open={this.state.isOpen}
      Option={this.props.Option}
      options={this.handleLotsOfOptions(this.state.filteredOptions)}
      selectedValues={[this.props.value]}
      size={this.props.size}
      tabIndex={this.props.tabIndex}
      testId={this.props.testId}
      tipText={this.props.tipText}
      width={this.props.width}
    />
  )
}
