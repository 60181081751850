// @flow

export type Schema = {|
  daily_schedules: Array<DailyScheduleType>,
  rosters: Array<RosterRubyType>,
  user_daily_schedule_joins: Array<UserDailyScheduleJoinRubyType>,
|}

export const hashUDSJoin = (udsj: UserDailyScheduleJoinType | UserDailyScheduleJoinRubyType): string =>
  `${udsj.user_id}~${udsj.daily_schedule_id}`

export type RosterRubyType = {|
  created_at: string,
  end: string,
  fixed_costs: number,
  id: number,
  lockdown_time: ?string,
  lockdown_user_id: ?number,
  organisation_id: number,
  roster_template_id: ?number,
  start: string,
  template: boolean,
  updated_at: string,
|}

export type DailyScheduleType = {|
  created_at: string,
  date: string,
  id: number,
  roster_id: number,
  updated_at: string,
|}

export type UserDailyScheduleJoinRubyType = {|
  daily_schedule_id: number,
  id: number,
  last_published_at: string,
  user_id: number,
|}

export type UserDailyScheduleJoinType = {|
  daily_schedule_id: number,
  date: string,
  id: number,
  last_published_at: ?string,
  user_id: number,
|}
