// @flow
import * as React from "react"
import styles from "./styles.module.scss"

type Props = {|
  text: string,
|}

export default function SectionHeading(props: Props): React.Element<"div"> {
  return <div className={styles.heading}>{props.text}</div>
}
