// @flow
import { default as Failure } from "./failure"
import { default as Loading } from "./loading"
import { default as Noop } from "./noop"

export default {
  Failure,
  Loading,
  Noop,
}
